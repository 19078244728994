import styled from 'styled-components';

interface IProps {
  children?: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
}

const ModalContentText = styled.div<IProps>`
  color: #969ea6;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
`;

export default ModalContentText;
