import * as React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, RouteComponentProps } from 'react-router-dom';

import { bindAction } from 'src/actions';
import { getFirstSudoSlug, getIsConnected } from './selectors';
import { IDispatcher } from 'src/store';
import { IRootState } from 'src/state';
import * as Actions from '../../actions';
import AppRedirect from 'src/features/Redirect';
import ConnectionPage from 'src/pages/Connection';
import ConnectionUnavailableModal from './ConnectionUnavailable';
import ErrorTrapModal from 'src/containers/error-trap-modal';
import FeedbackMenu from 'src/components/FeedbackMenu';
import Header from '../Header';
import Pages from './Pages';
import SudoSettingsSelectors from 'src/entities/sudo-settings/selectors';
import { WindowFocusDetector } from './WindowFocusDetector';

import 'emoji-mart/css/emoji-mart.css';
import 'src/containers/app/reset.scss';
import 'src/containers/app/global.scss';
import 'src/common.scss';

interface IOwnProps {
  sudoSlug: string;
}

export interface IStateFromProps {
  connected: boolean;
  isValidCurrentSudo: boolean;
  firstSudoSlug: string;
  connectionUnavailable: boolean;
}

export interface IDispatchFromProps {
  onMount(): void;
  onToggleSudoSidebar: (isOpen: boolean) => void;
  onLogout(): void;
  onRetryConnection(): void;
}

interface IProps
  extends IOwnProps,
    IStateFromProps,
    IDispatchFromProps,
    RouteComponentProps<any> {}

class AppComponent extends React.Component<IProps> {
  public componentDidMount() {
    this.props.onMount();
  }

  public render() {
    return (
      <AppRedirect>
        {this.props.connected && this.props.isValidCurrentSudo && (
          <Header
            isConnected={this.props.connected && this.props.isValidCurrentSudo}
            onToggleSudoSidebar={this.props.onToggleSudoSidebar}
          />
        )}
        {!this.props.connected && <ConnectionPage />}
        {this.props.connected && !this.props.isValidCurrentSudo && (
          <Redirect to={`/${this.props.firstSudoSlug}`} />
        )}
        {this.props.connected && this.props.isValidCurrentSudo && (
          <Route path="/:sudoSlug" component={Pages} />
        )}
        <ConnectionUnavailableModal
          isOpen={this.props.connectionUnavailable}
          reconnect={this.props.onRetryConnection}
          onLogout={this.props.onLogout}
          zIndex={1000}
        />
        <ErrorTrapModal />
        <FeedbackMenu />
        <WindowFocusDetector />
      </AppRedirect>
    );
  }
}

export const mapStateToProps = (
  state: IRootState,
  props: IOwnProps & RouteComponentProps<any>,
): IStateFromProps => {
  const { params } = props.match;
  const isValidCurrentSudo = params.sudoSlug
    ? !!SudoSettingsSelectors.getIdBySlug(state, { slug: params.sudoSlug })
    : false;

  return {
    connected: getIsConnected(state),
    connectionUnavailable: state.app.apiStatus === 'connection-unavailable',
    isValidCurrentSudo,
    firstSudoSlug: getFirstSudoSlug(state),
  };
};

const mapDispatchToProps = (dispatch: IDispatcher): IDispatchFromProps => ({
  onMount: () => {
    dispatch(bindAction());
  },
  onToggleSudoSidebar: (isOpen: boolean) => {
    dispatch(Actions.createSidePanelAction('sudoSelect', isOpen));
  },
  onLogout: () => {
    dispatch(Actions.unpairAction());
  },
  onRetryConnection: () => {
    dispatch(Actions.retryConnection());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AppComponent) as React.ComponentClass<IOwnProps & RouteComponentProps<any>>;
