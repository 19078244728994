/* tslint:disable max-line-length */
import * as React from 'react';

export default function ContactsEmpty(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="89"
      height="161"
      viewBox="0 0 89 161"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <g transform="rotate(17 -322.43 98.564)">
          <g fill="#AFB6BD">
            <path d="M20.945 24.217c-6.146 0-11.127-4.981-11.127-11.127 0-6.145 4.981-11.126 11.127-11.126 6.145 0 11.126 4.981 11.126 11.126 0 6.146-4.981 11.127-11.126 11.127zm18.727 28.145H2.217c-.863 0-1.562-.7-1.562-1.563v-1.71c0-10.86 9.1-19.636 20.29-19.636s20.29 8.775 20.29 19.636v1.71c0 .863-.7 1.563-1.563 1.563z" />
          </g>
        </g>
        <g transform="matrix(-1 0 0 1 89.056 57.873)">
          <g fill="#AFB6BD">
            <path d="M18.918 21.874c-5.55 0-10.05-4.5-10.05-10.05 0-5.55 4.5-10.05 10.05-10.05 5.55 0 10.05 4.5 10.05 10.05 0 5.55-4.5 10.05-10.05 10.05zm16.764 25.42H2.154c-.863 0-1.563-.7-1.563-1.562v-1.394c0-9.81 8.22-17.735 18.327-17.735 10.107 0 18.326 7.926 18.326 17.735v1.394c0 .863-.7 1.562-1.562 1.562z" />
          </g>
        </g>
        <g transform="rotate(15 -69.553 82.272)">
          <g fill="#AFB6BD">
            <path d="M12.5 14.452a6.64 6.64 0 1 1 0-13.28 6.64 6.64 0 0 1 0 13.28zm10.545 16.796H1.953c-.863 0-1.562-.7-1.562-1.562v-.391c0-6.481 5.43-11.718 12.108-11.718 6.678 0 12.109 5.237 12.109 11.718v.39c0 .863-.7 1.563-1.563 1.563z" />
          </g>
        </g>
        <g transform="matrix(-1 0 0 1 79.75 -.6)">
          <g fill="#AFB6BD">
            <path d="M9.459 10.937a5.025 5.025 0 1 1 0-10.05 5.025 5.025 0 0 1 0 10.05zm7.685 12.71H1.774A1.478 1.478 0 0 1 .296 22.17c0-4.905 4.11-8.867 9.163-8.867 5.054 0 9.163 3.962 9.163 8.867 0 .816-.662 1.478-1.478 1.478z" />
          </g>
        </g>
      </g>
    </svg>
  );
}
