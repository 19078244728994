import { createSelector } from 'reselect';
import { groupBy, keys } from 'lodash';

import AvatarEmptyIconBase64 from '../../icons/avatar-empty-new-base64';
import ContactSelectors from '../../entities/contact/selectors';
import { IContact, IGlobalSettings } from '../../api';
import ContactAvatarSelectors, {
  IEntitiesToContactGuidMap as IAvatarsToContactGuidMap,
} from '../../entities/contact-avatar/selectors';
import { formatNumberForDisplay } from '../../utils';
import GlobalSettingsSelectors from '../../entities/global-settings/selectors';
import { IEntityBySudoIdProps } from '../../entities/common/createEntitySelectors';
import { getList, IRootState } from 'src/state';

export interface IContactItem {
  id: string;
  namePrimary: string;
  nameSecondary: string;
  info?: string;
  avatarImage: string;
  avatarText: string;
  isSelected: boolean;
  modified: number;
  isSudoContact: boolean;
}

export interface IContactsRow {
  type: 'group' | 'contact';
}

export interface IContactsRowGroup extends IContactsRow {
  type: 'group';
  name: string;
}

export interface IContactsRowContact extends IContactsRow {
  type: 'contact';
  contact: IContactItem;
}

export interface INameParts {
  first?: string;
  last?: string;
}

interface IContactNameOrData {
  primary?: string;
  secondary?: string;
}

export const getContactName = (nameParts: INameParts) => {
  if (nameParts && (nameParts.first || nameParts.last)) {
    return [nameParts.first, nameParts.last]
      .filter((part?: string) => part)
      .join(' ');
  }
};

export const getContactNameOrData = (
  countryCode: string,
  contact: IContact,
): IContactNameOrData => {
  if (contact.last) {
    return { primary: contact.last, secondary: contact.first };
  }
  if (contact.first) {
    return { primary: contact.first };
  }

  const phoneNumber = (contact.phoneNumbers || []).find(pn => !!pn.number);
  if (phoneNumber) {
    return { primary: formatNumberForDisplay(countryCode, phoneNumber.number) };
  }

  const emailAddress = (contact.emailAddresses || []).find(ea => !!ea.email);
  if (emailAddress) {
    return { primary: emailAddress.email };
  }

  if (contact.company) {
    return { primary: contact.company };
  }

  return { secondary: 'No Name' };
};

export const getAvatarText = (nameParts: INameParts) => {
  if (!nameParts) {
    return '';
  }
  return [nameParts.first, nameParts.last]
    .filter((part?) => part)
    .map(part => part.charAt(0).toLocaleUpperCase())
    .join('');
};

const getGroupName = (contactItem: IContactItem) => {
  if (contactItem.namePrimary) {
    const letter = contactItem.namePrimary[0].toLocaleUpperCase();
    if (letter.charCodeAt(0) >= 65 && letter.charCodeAt(0) <= 90) {
      return letter;
    }
  }
  return '#';
};

const getContactsSearchList = (
  state: IRootState,
  { sudoGuid }: IEntityBySudoIdProps,
) => getList(state.lists, `search:contacts:${sudoGuid}`);

export interface IContactBySudoIdProps extends IEntityBySudoIdProps {
  group: 'all' | 'sudo';
}

export const getContactItemList = createSelector(
  GlobalSettingsSelectors.getSettings,
  getContactsSearchList,
  ContactSelectors.getEntitiesArray,
  ContactAvatarSelectors.getEntities,
  (_, props: IContactBySudoIdProps) => props,
  (
    settings: IGlobalSettings,
    searchList,
    contacts: IContact[],
    mappedAvatars: IAvatarsToContactGuidMap,
    { sudoGuid, group },
  ): IContactItem[] => {
    return contacts
      .filter(contact => {
        const isInGroup =
          contact &&
          contact.parent &&
          contact.parent.id === sudoGuid &&
          contact.parent.type === 'Sudo';

        if (group === 'sudo' && !isInGroup) {
          return false;
        }

        if (searchList && searchList.searchCriteria) {
          return searchList.itemIds.includes(contact.id);
        }

        return true;
      })
      .map(contact => {
        const displayName = getContactNameOrData(settings.countryCode, contact);
        const isSudoContact = contact.isSudoContact;
        const avatar = mappedAvatars[contact.id];
        let avatarImage = avatar && avatar.avatar;
        const avatarText = avatarImage ? null : getAvatarText(contact);
        if (!avatarImage && !avatarText) {
          avatarImage = AvatarEmptyIconBase64;
        }
        return {
          id: contact.id,
          nameSecondary: displayName.secondary,
          namePrimary: displayName.primary,
          info: contact.company,
          avatarImage,
          avatarText,
          isSelected: false,
          modified: contact.modified,
          isSudoContact,
        };
      });
  },
);

const getContactSortName = (contactItem: IContactItem): string => {
  return `${contactItem.namePrimary} ${contactItem.nameSecondary}`.trim();
};

const compareContacts = (a: IContactItem, b: IContactItem) => {
  const sortNameA = getContactSortName(a).toLocaleUpperCase();
  const sortNameB = getContactSortName(b).toLocaleUpperCase();
  return sortNameA.localeCompare(sortNameB);
};

const compareGroups = (a: string, b: string) => a.localeCompare(b);

export const getGroupedContacts = createSelector(
  getContactItemList,
  (contactList: IContactItem[]): IContactsRow[] => {
    const groups = groupBy(contactList, contact => getGroupName(contact));
    const groupNames = keys(groups).sort(compareGroups);
    return groupNames.reduce(
      (acc, name) => {
        acc.push({
          type: 'group',
          name,
        } as IContactsRowGroup);

        const contactRows = groups[name].sort(compareContacts).map(
          (contact): IContactsRowContact => ({
            type: 'contact',
            contact,
          }),
        );

        return acc.concat(contactRows);
      },
      [] as IContactsRow[],
    );
  },
);

export const getIsContactListEmpty = createSelector(
  getContactItemList,
  (contactList: IContactItem[]) => contactList.length <= 0,
);
