import * as React from 'react';
import cn from 'classnames';
import styled from 'styled-components';

import OnClickOutside from '../OnClickOutside';

const Container = styled.div`
  &.selected {
    box-shadow: 0 0 5px 1px #40a3d7;
  }
`;

interface IProps {
  children: React.ReactNode;
  onKeyDown: (e: any) => void;
  className?: string;
}

interface IState {
  isSelected: boolean;
}

class HandleKeyOnSelection extends React.Component<IProps, IState> {
  public state: IState = { isSelected: false };

  private handleClickOutside = () => {
    if (this.state.isSelected) {
      this.setState({ isSelected: false });
    }
  };
  private handleClick = () => {
    if (!this.state.isSelected) {
      this.setState({ isSelected: true });
    }
  };
  private handleKeydown = (e: any) => {
    if (this.state.isSelected) {
      this.props.onKeyDown(e);
    }
  };
  public componentDidMount() {
    document.addEventListener('keydown', this.handleKeydown);
  }
  public componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeydown);
  }
  public render() {
    return (
      <OnClickOutside onClickOutside={this.handleClickOutside}>
        <Container
          onClick={this.handleClick}
          className={cn(this.props.className, {
            selected: this.state.isSelected,
          })}
        >
          {this.props.children}
        </Container>
      </OnClickOutside>
    );
  }
}

export default HandleKeyOnSelection;
