import keyBy from 'lodash/keyBy';
import { IAction, nullAction, AVATAR_IMAGES_FETCHED } from 'src/actions';
import { IAvatarImages } from 'src/state/avatarImages';

export function reducer(
  state: IAvatarImages = {} as IAvatarImages,
  action: IAction = nullAction,
): IAvatarImages {
  switch (action.type) {
    case AVATAR_IMAGES_FETCHED:
      return {
        ...state,
        ...keyBy(action.payload, 'id'),
      };
    default:
      return state;
  }
}
