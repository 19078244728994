import styled from 'styled-components';
import { gridContentChildHack } from 'src/utils/grid';

export const LayoutToolbarTop = styled.div`
  border-bottom: var(--theme-divider);
  display: flex;
  height: 55px;

  > div {
    flex: 1;
  }
`;

export const LayoutConversationView = styled.div`
  display: flex;
  overflow: hidden;
  flex-grow: 1;
`;

export const Layout = styled.div`
  ${gridContentChildHack};
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const ConversationViewWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  min-width: 0;
`;
