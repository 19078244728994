import * as React from 'react';
import styled from 'styled-components';
import { Button } from 'src/components';

import { IProps, IEmptyStates } from './types';

const Bold = styled.b`
  font-weight: 800;
`;

const Content = styled.div`
  display: flex;
  align-items: center;
`;

const StyledButton = styled(Button)`
  span {
    font-size: 32px;
  }
`;

const emptyState = (folder: string) => ({
  title: (props: IProps) => (
    <React.Fragment>
      <Bold>
        {props.selectedCount} email{props.selectedCount > 1 && 's'}
      </Bold>{' '}
      selected
    </React.Fragment>
  ),
  content: (props: IProps) => (
    <Content>
      <StyledButton
        asLink
        onClick={() => props.changeSelection(!props.isAllSelected)}
      >
        {props.isAllSelected ? 'Deselect' : 'Select'} all
      </StyledButton>
      &nbsp;in {folder}
    </Content>
  ),
});

const EMPTY_STATES: IEmptyStates = {
  inbox: emptyState('Inbox'),
  sent: emptyState('Sent'),
  drafts: emptyState('Drafts'),
  trash: emptyState('Trash'),
};

export default EMPTY_STATES;
