import React, { HTMLAttributes } from 'react';
import styled, { css } from 'styled-components';

import Icon from 'src/components/Icon';
import InputTagsList from '../InputTagsList';
import InputElement from '../InputElement';
import InputTagSeparator from '../InputTagSeparator';
import Button from '../../../Button';

export interface IProps extends HTMLAttributes<HTMLInputElement> {
  color?: string;
  iconColor?: string;
  iconName?: string;
  inputRef?: React.Ref<HTMLInputElement>;
  maxLength?: number;
  onClear?: (event: React.SyntheticEvent) => void;
  onChange?: (event: React.SyntheticEvent) => void;
  onFocus?: (event: React.SyntheticEvent) => void;
  placeholder?: string;
  placeholderColor?: string;
  tagSeparator?: JSX.Element;
  tags?: Array<{
    id: number | string;
    tag: JSX.Element;
  }>;
  value?: string;
}

const InputWrapper = styled.div<{ hasIcon: boolean }>`
  height: calc(var(--theme-spacing) * 7);
  padding: 0 calc(var(--theme-spacing) * 2);
  flex-grow: 1;
  display: flex;
  align-items: center;
  ${props =>
    props.hasIcon &&
    css`
      padding: 0 var(--theme-spacing);
    `}
`;

const StyledIcon = styled(Icon)`
  min-width: 24px;
  margin-right: calc(var(--theme-spacing) / 2);
  color: ${props => props.color};
`;

export const ClearButton = styled(Button)`
  margin: 0 0 0 8px;

  & > svg {
    height: 16px;
    width: 16px;
  }
`;

interface IInputIconProps {
  name?: string;
  color?: string;
}

const InputIcon = (props: IInputIconProps) =>
  props.name ? <StyledIcon {...props} /> : null;

interface IInputTagsProps {
  tags?: Array<{
    id: number | string;
    tag: JSX.Element;
  }>;
  tagSeparator?: JSX.Element;
}

const InputTags = (props: IInputTagsProps) =>
  props.tags ? <InputTagsList {...props} /> : null;

const InputSingleLine = (props: IProps) => {
  const {
    color,
    iconColor,
    iconName,
    inputRef,
    onChange,
    onClear,
    onFocus,
    placeholder,
    placeholderColor,
    tags,
    tagSeparator,
    value,
    ...rest
  } = props;

  return (
    <InputWrapper hasIcon={!!iconName}>
      <InputIcon color={iconColor || placeholderColor} name={iconName} />
      <InputTags tags={tags} tagSeparator={tagSeparator} />
      <InputElement
        color={color}
        onChange={props.onChange}
        onFocus={onFocus}
        placeholder={placeholder}
        placeholderColor={placeholderColor}
        ref={inputRef}
        type="text"
        value={value}
        {...rest}
      />
      {onClear && value && (
        <ClearButton
          iconColor="var(--gray-1)"
          iconName="ClearFill"
          onClick={onClear}
          round
        />
      )}
    </InputWrapper>
  );
};

InputSingleLine.defaultProps = {
  tags: null,
  color: 'var(--gray-1)',
  iconColor: '',
  iconName: '',
  placeholder: '',
  placeholderColor: 'var(--gray-4)',
  tagSeparator: <InputTagSeparator>,&nbsp;</InputTagSeparator>,
};

export default InputSingleLine;
