import * as React from 'react';
import styled from 'styled-components';
import { List, Popover } from 'src/components';

import ListItem from 'src/components/List/deps/ListItem';
import DropdownButton from '../../../../../../features/Dropdown/DropdownButton/index';
import withPopoverState from '../../../../../../features/Popover/utils/withPopoverState';

interface IProps extends IOwnProps, IWithStateHandlersProps {}

interface IOwnProps {
  label: number;
  labelOptions: ILabelOption[];
  onSelectLabel: (label: number) => void;
}

export interface ILabelOption {
  label: number;
  description: string;
}

interface IWithStateHandlersProps {
  isPopoverOpen: boolean;
  onTogglePopover: () => void;
}

const LabelDropdownButton = styled(DropdownButton)`
  font-size: 17px;
  line-height: 21px;
  color: #303030;
`;

const LabelText = styled.span`
  width: 64px;
  text-align: left;
`;

const StyledPopover = styled(Popover)`
  width: 260px;
  z-index: 999999;
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgba(16, 16, 16, 0.24);
  ul li {
    height: 40px;
    padding-left: 24px;
  }
  div {
    font-size: 15px;
  }

  ul li:hover {
    background-color: var(--gray-8);
  }

  ul li.isActive {
    background-color: var(--gray-8);
  }
`;

export const getLabelDescription = (
  label: number,
  labelOptions: ILabelOption[],
) => {
  const filtered = labelOptions.filter(
    (option: ILabelOption) => option.label === label,
  );
  return filtered.length > 0 ? filtered[0].description : '';
};

export const LabelDropdownComponent = (props: IProps) => {
  const { isPopoverOpen, label, labelOptions } = props;
  return (
    <StyledPopover
      isOpen={isPopoverOpen}
      anchor={
        <LabelDropdownButton onClick={props.onTogglePopover}>
          <LabelText>{getLabelDescription(label, labelOptions)}</LabelText>
        </LabelDropdownButton>
      }
      onClose={props.onTogglePopover}
      positionHorizontal="start"
      positionVertical="end"
      originHorizontal="start"
      originVertical="end"
    >
      <List>
        {labelOptions.map((option: any) => (
          <ListItem
            key={option.label}
            primaryText={option.description}
            onClick={() => {
              props.onTogglePopover();
              props.onSelectLabel(option.label);
            }}
            isActive={props.label === option.label}
          />
        ))}
      </List>
    </StyledPopover>
  );
};

const LabelDropdown = (withPopoverState(
  LabelDropdownComponent as any,
) as any) as React.ComponentClass<IOwnProps>;

export default LabelDropdown;
