import * as React from 'react';
import SessionMessage, {
  Title,
  List,
  ListItem,
  Toolbar,
  ToolbarButton,
} from '../SessionMessage';

interface IProps {
  logout: () => void;
  reconnect: () => void;
}

export default (props: IProps) => (
  <SessionMessage>
    <Title>Lost connection to device</Title>
    <p>It looks like your MySudo app is not responding. Please check that:</p>
    <List>
      <ListItem>Your mobile device has Wifi or cellular service,</ListItem>
      <ListItem>Airplane mode is not enabled,</ListItem>
      <ListItem>
        Your session is still active in the web sessions list (See "MySudo ->
        Menu -> Pair with Browser").
      </ListItem>
    </List>
    <Toolbar>
      <ToolbarButton secondary onClick={() => props.logout()}>
        Log out and re-pair
      </ToolbarButton>
      <ToolbarButton primary onClick={() => props.reconnect()}>
        Retry
      </ToolbarButton>
    </Toolbar>
  </SessionMessage>
);
