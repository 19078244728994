import { connect } from 'react-redux';
import { get } from 'lodash';
import moment from 'moment';

import { IRootState } from '../../../state';
import ContactSelectors, {
  IContactAndAvatar,
} from '../../../entities/contact/selectors';
import { getAvatarText, getContactName } from '../selectors';
import AvatarEmptyIconBase64 from '../../../icons/avatar-empty-new-base64';
import SudoSettingsSelectors from '../../../entities/sudo-settings/selectors';
import {
  DEFAULT_SUDO_COLOR,
  IContactAddress,
  IContactEmailAddress,
  IMessageConversationMember,
  IPhoneNumber,
} from '../../../api';
import { formatNumberForDisplay, formatNumberForStorage } from '../../../utils';
import PhoneAccountSelectors from '../../../entities/phone-account/selectors';
import EmailAccountSelectors from '../../../entities/email-account/selectors';
import { IDispatcher } from '../../../store';
import { actions as conversationCreationActions } from '../../Messages/ConversationCreation/modules';
import { actions as emailComposeActions } from '../../Emails/modules';
import { displayNameFormatter } from '../../../features/Contacts/ContactAutocomplete/createWithContactAutocomplete';
import { NEW_MESSAGE, validateParticipants } from '../../../actions';
import SudoAvatarEmpty from '../../../icons/sudo-avatar-empty';

import { actions as Redirect } from '../../../features/Redirect/modules';

const { redirect } = Redirect;

export interface IStateProps {
  sudo: string;
  tab: string;
  contactGuid: string;
}

export interface IPhonesSectionData {
  label: string;
  phone: string;
  formattedPhone: string;
}

export interface IEmailsSectionData {
  label: string;
  email: string;
}

export interface IStateFromProps {
  isContactValid: boolean;
  sudoGuid: string;
  isEditable: boolean;
  avatarImage: string;
  fullName: string;
  company: string;
  hasSudo: boolean;
  sudoLabel: string;
  sudoAvatarColor: string;
  sudoAvatarText: string;
  sudoAvatarImage: string;
  phonesSection: IPhonesSectionData[];
  emailsSection: IEmailsSectionData[];
  address: IContactAddress;
  notes: string;
  birthday: string;
  canSendEmail: boolean;
  canSendMessage: boolean;
  contactAndAvatar: IContactAndAvatar;
  phoneAccountGuid: string;
  phoneRegion: string;
}

export interface IDispatchFromProps {
  createConversation: (
    sudoSlug: string,
    phoneAccountGuid: string,
    phoneRegion: string,
    contactAndAvatar: IContactAndAvatar,
    phone: string,
  ) => void;
  createEmail: (
    sudoGuid: string,
    sudoSlug: string,
    phoneRegion: string,
    contactAndAvatar: IContactAndAvatar,
    email: string,
  ) => void;
}

const formatPhonesSection = (
  phones: IPhoneNumber[],
  phoneRegion: string,
): IPhonesSectionData[] => {
  return phones.map(
    (phone): IPhonesSectionData => ({
      label: phone.type,
      phone: formatNumberForStorage(phoneRegion, phone.number),
      formattedPhone: formatNumberForDisplay(phoneRegion, phone.number),
    }),
  );
};

const formatEmailsSection = (
  emails: IContactEmailAddress[],
): IEmailsSectionData[] => {
  return emails.map(
    (email): IEmailsSectionData => ({
      label: email.type,
      email: email.email,
    }),
  );
};

const formatBirthday = (birthday: number): string => {
  return Number.isFinite(birthday)
    ? moment.utc(birthday).format('MMMM D, Y')
    : '';
};

export const mapState = (
  state: IRootState,
  props: IStateProps,
): IStateFromProps => {
  const contactAndAvatar = ContactSelectors.getEntityWithAvatarById(state, {
    id: props.contactGuid,
  });
  const { contact, avatar } = contactAndAvatar;
  const sudoGuid = SudoSettingsSelectors.getIdBySlug(state, {
    slug: props.sudo,
  });
  const isEditable = contact && !contact.localIdentifier;

  let sudo = null;
  let sudoAvatar = null;

  if (
    contact &&
    contact.parent &&
    contact.parent.type === 'Sudo' &&
    contact.parent.id
  ) {
    const sudoAndAvatar = SudoSettingsSelectors.getEntityWithAvatarBySudoId(
      state,
      { sudoGuid: contact.parent.id },
    );
    if (sudoAndAvatar) {
      sudo = sudoAndAvatar.sudo;
      sudoAvatar = sudoAndAvatar.avatar;
    }
  }

  const phoneAccount = PhoneAccountSelectors.getDefaultOrFirstAccountBySudoId(
    state,
    { sudoGuid },
  );
  const emailAccounts = EmailAccountSelectors.getEntitiesBySudoId(state, {
    sudoGuid,
  });

  const phoneAccountGuid = phoneAccount ? phoneAccount.id : null;

  const phoneRegion = SudoSettingsSelectors.getPhoneRegionById(state, {
    sudoGuid,
  });
  const phones = get(contact, 'phoneNumbers', []) as IPhoneNumber[];
  const emails = get(contact, 'emailAddresses', []) as IContactEmailAddress[];
  const address = get(contact, 'streetAddresses[0]', null) as IContactAddress;
  const birthday = contact && contact.birthday;
  const notes = get(contact, 'notes', '') as string;

  return {
    isContactValid: !!contact,
    sudoGuid,
    isEditable,
    avatarImage: (avatar && avatar.avatar) || AvatarEmptyIconBase64,
    fullName: getContactName(contact),
    company: get(contact, 'company', '') as string,
    hasSudo: !!sudo,
    sudoLabel: sudo && sudo.role,
    sudoAvatarColor: DEFAULT_SUDO_COLOR,
    sudoAvatarText:
      sudo && getAvatarText({ first: sudo.firstName, last: sudo.lastName }),
    sudoAvatarImage: sudo
      ? sudoAvatar
        ? sudoAvatar.avatarImage
        : SudoAvatarEmpty
      : AvatarEmptyIconBase64,
    phonesSection: formatPhonesSection(phones, phoneRegion),
    emailsSection: formatEmailsSection(emails),
    address,
    birthday: formatBirthday(birthday),
    notes,
    canSendMessage: phoneAccount && phones.length > 0,
    canSendEmail: emailAccounts.length > 0 && emails.length > 0,
    contactAndAvatar,
    phoneAccountGuid,
    phoneRegion,
  };
};

export const mapDispatch = (dispatch: IDispatcher): IDispatchFromProps => ({
  createConversation: (
    sudoSlug: string,
    phoneAccountGuid: string,
    phoneRegion: string,
    contactAndAvatar: IContactAndAvatar,
    phone: string,
  ) => {
    if (!phoneAccountGuid) {
      dispatch(redirect(`/${sudoSlug}/messages`));
      return;
    }

    dispatch({ type: NEW_MESSAGE, payload: {} });
    const participants: IMessageConversationMember[] = [
      { aliasType: 'phone', alias: phone },
    ];

    dispatch(conversationCreationActions.createNewConversation(participants));
    dispatch(validateParticipants(phoneAccountGuid, participants));
    dispatch(redirect(`/${sudoSlug}/messages/new`));
  },

  createEmail: (
    sudoGuid: string,
    sudoSlug: string,
    phoneRegion: string,
    contactAndAvatar: IContactAndAvatar,
    email: string,
  ) => {
    const displayName = displayNameFormatter(
      phoneRegion,
      contactAndAvatar.contact,
    );

    dispatch(
      emailComposeActions.openComposer(sudoGuid, null, null, {
        to: [
          {
            address: email,
            displayName,
          },
        ],
      }),
    );
    dispatch(redirect(`/${sudoSlug}/emails`));
  },
});

export default connect(
  mapState,
  mapDispatch,
);
