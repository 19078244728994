import React from 'react';

export default function Call(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      {...props}
    >
      <g fill="currentColor" fillRule="evenodd" transform="translate(2 2)">
        <path d="M16.718 17.68l.573-.484.746.882c-.171.165-.357.332-.556.501l-.62.432c-1.35.76-3.06.643-5.032-.24-1.046-.467-2.16-1.146-3.335-2.018a30.337 30.337 0 0 1-3.075-2.627c-.266-.261-.745-.776-1.195-1.294a27.265 27.265 0 0 1-2.256-2.954C-.177 6.592-.648 3.93.95 2.04 2.652.028 4.156-.549 5.43.55c.074.064.217.202.345.339A24.716 24.716 0 0 1 8.515 5.12l.065.194c.138.607-.014 1.093-.517 2.028l-.032.059-.032.059c-.119.218-.208.389-.27.515.096.125.276.37.478.618.39.48.875 1.018 1.29 1.433.411.412.95.897 1.43 1.288.248.203.494.383.619.48.126-.061.297-.15.515-.27l.059-.031.06-.032c.932-.502 1.416-.654 2.114-.49l.19.075a24.527 24.527 0 0 1 4.214 2.765l.1.098c-.003-.005-.003-.005.08.084.053.058.077.084.103.115.976 1.15.605 2.475-.944 3.969l-.556.501c-.198.167-.404.31-.62.432l-.715-.846.572-.484-.207-.246c1.459-1.235 1.715-1.898 1.326-2.356a2.555 2.555 0 0 0-.124-.135 23.642 23.642 0 0 0-3.84-2.536c-.139-.031-.354.036-.982.374l-.057.03-.056.031c-.886.482-1.064.564-1.397.509-.519-.085-1.875-1.193-2.946-2.265-1.076-1.078-2.178-2.429-2.263-2.948-.054-.333.028-.51.509-1.395l.03-.057.031-.056c.34-.63.407-.845.403-.906A23.495 23.495 0 0 0 4.651 1.88a5.199 5.199 0 0 0-.203-.196c-.453-.39-1.118-.136-2.354 1.326-1.05 1.242-.694 3.254 1.13 6.048a25.783 25.783 0 0 0 2.132 2.79c.426.49.878.976 1.117 1.21a27.528 27.528 0 0 0 .857.803 28.842 28.842 0 0 0 2.058 1.687c1.093.812 2.116 1.435 3.054 1.854 1.813.811 3.153.808 4.069.033l.207.246z" />
      </g>
    </svg>
  );
}
