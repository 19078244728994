import * as React from 'react';
import cn from 'classnames';

interface IComponentProps {
  value: string;
}

interface IProps {
  contacts: string[];
  focusIndex: number;

  onContactMouseDown: (index: number) => void;
  onContactMouseOver: (index: number) => void;

  renderContact: (value: string, index: number) => React.ReactNode;
}

class ContactsList extends React.Component<IProps> {
  private list: HTMLUListElement = null;
  private getListRef = (ref: HTMLUListElement) => (this.list = ref);

  public componentDidUpdate() {
    const index = this.props.focusIndex;
    if (index < 0) {
      return;
    }
    const list = this.list as any;
    if (!list) {
      return;
    }
    const child = list.children[index] as any;
    if (!child) {
      return;
    }

    if (
      child.offsetTop + child.offsetHeight >
      list.scrollTop + list.offsetHeight
    ) {
      list.scrollTop = child.offsetTop + child.offsetHeight - list.offsetHeight;
    }

    if (list.scrollTop > child.offsetTop) {
      list.scrollTop = child.offsetTop;
    }
  }
  private onContactMouseDown = (e: any) => {
    e.preventDefault();
    const { index } = e.currentTarget.dataset;
    this.props.onContactMouseDown(index);
  };
  private onContactMouseEnter = (e: any) => {
    this.props.onContactMouseOver(+e.currentTarget.dataset.index);
  };
  public render() {
    const { contacts, focusIndex, renderContact } = this.props;
    return (
      <ul className="contacts-input-contacts-list" ref={this.getListRef}>
        {contacts.map((contact: string, index: number) => {
          return (
            <li
              key={`${index}:${contact}`}
              onMouseDown={this.onContactMouseDown}
              data-index={index}
              className={cn({ focused: index === focusIndex })}
              onMouseEnter={this.onContactMouseEnter}
            >
              {renderContact(contact, index)}
            </li>
          );
        })}
      </ul>
    );
  }
}

export default ContactsList;
