import * as React from 'react';
import { List } from 'src/components';
import styled from 'styled-components';

import { Spinner } from '../../../components/spinner';
import NavListItem from '../../../features/List/NavListItem';
import withConversationsListData, {
  IDispatchFromProps,
  IStateFromProps,
  IStateProps,
} from './withConversationsListData';
import { withRouter } from 'react-router';
import { IConversationListData } from '../selectors';
import { AvatarType } from '../../../state';

const SpinnerContainer = styled.div`
  margin-top: 70px;
`;

export interface IProps
  extends IOwnProps,
    IStateFromProps,
    IStateProps,
    IDispatchFromProps {}

export interface IOwnProps {
  onLoadMore: (callback: () => void) => void;
}

export const getUri = (sudo: string, id?: string) =>
  `/${[sudo, 'messages', encodeURIComponent(id)]
    .filter((part?: string) => part)
    .join('/')}`;

export class ConversationsList extends React.Component<IProps> {
  public componentDidMount() {
    this.props.onLoadMore(this.loadMoreConversations);
    this.loadMoreConversations();
  }

  public componentDidUpdate(prevProps: IProps) {
    if (this.props.sudoSlug !== prevProps.sudoSlug) {
      this.loadMoreConversations(prevProps);
    }
  }

  public loadMoreConversations = (prevProps?: IProps) => {
    if (!this.props.noMoreConversations && !this.props.isFetching) {
      this.props.loadMoreConversations(this.props.sudoGuid);
    }

    const ids = this.props.contacts.reduce(
      (accumulator, contact) => {
        if (prevProps && prevProps.contacts.includes(contact)) {
          return accumulator;
        }
        return [...accumulator, contact.id];
      },
      [] as string[],
    );
    this.props.fetchAvatarsById(AvatarType.ContactAvatar, ids);
  };

  public render() {
    const { conversations, isFetching, isEmpty, sudoSlug } = this.props;
    return (
      <React.Fragment>
        <List
          isLoading={isEmpty && !isFetching}
          isWithAvatar
          isWithSecondaryText
          loadingItemsCount={10}
        >
          {conversations.map((item: IConversationListData) => (
            <NavListItem
              key={item.id}
              primaryText={item.title}
              secondaryText={item.lastMessageText}
              isNew={item.isUnread}
              avatarImage={item.avatarImage}
              isAvatarLocked={item.avatarLocked}
              avatarText={item.avatarText}
              activeClassName="isActive"
              timeLabel={item.timestamp}
              to={getUri(sudoSlug, item.id)}
            />
          ))}
        </List>

        {isFetching && (
          <SpinnerContainer>
            <Spinner />
          </SpinnerContainer>
        )}
      </React.Fragment>
    );
  }
}

// withRouter() is used so that NavLinks in NavListItem are updated when route is changed
export default (withRouter(withConversationsListData(
  ConversationsList,
) as any) as any) as React.ComponentClass<IOwnProps & IStateProps>;
