import { IMessageAttachment } from '../api';

import { fetchFullsizeAttachment } from './messages-fetch-fullsize-attachment';
import { createModalAction } from './toggle-modal-action';
import { actions as messagesErrorsActions } from '../pages/Messages/MessagesErrors/modules';
import { IThunk } from './actions-base';

export function showAttachment(
  attachment: IMessageAttachment,
  messageId?: string,
): IThunk<void> {
  return async (dispatch, getState, app) => {
    if (!attachment) {
      // Close
      dispatch(
        createModalAction('messagesViewAttachment', {
          attachment,
          isOpen: false,
        }),
      );
    } else {
      // Dispatch the modal, set the loading indicator to true to show a spinner
      dispatch(
        createModalAction('messagesViewAttachment', {
          attachment,
          isOpen: true,
          loading: true, // VCARD's are already downloaded so no need for loading
        }),
      );

      const fullSizeAttachment = await dispatch(
        fetchFullsizeAttachment(attachment.id, messageId),
      );

      if (fullSizeAttachment) {
        dispatch(
          createModalAction('messagesViewAttachment', {
            isOpen: true,
            loading: false,
            attachment: fullSizeAttachment,
          }),
        );
      } else {
        // Stop the loader from spinner and display a toast message
        // stating the the attachment could no be retrieved
        dispatch(
          createModalAction('messagesViewAttachment', {
            attachment,
            isOpen: true,
            loading: false,
          }),
        );
        dispatch(
          messagesErrorsActions.addError({
            type: 'ATTACHMENT_DOWNLOAD_FAIL',
            message:
              'We were unable to download the attachment. You can try again by selecting the message ' +
              'a second time.',
          }),
        );
      }
    }
  };
}
