import { IAction, nullAction } from '../actions';
import { TOGGLE_MODAL } from '../actions';
import { IModal, IModalEntry, Modal } from '../state/modal';

export function modalReducer(
  state: IModal = Modal(),
  action: IAction = nullAction,
) {
  if (action.type === TOGGLE_MODAL) {
    const payload: { name: string; data: IModalEntry } = action.payload;
    return state.set(payload.name, { ...payload.data });
  }

  return state;
}
