import * as React from 'react';
import styled, { css } from 'styled-components';
import { pure } from 'recompose';
import { Button } from 'src/components';
import { Status } from 'src/components/MessageBubble/deps/MessageBubbleWithStatus';
import {
  AttachmentThumbnail,
  AttachmentWrapper,
  IWithOpenAttachmentHandlerData,
  MessageRowWrapper,
  MessageSentRow,
  RowOptions,
  TimerBadge,
} from '../BaseMessageElements';
import { MessageStatusType } from '../SentMessage';

export interface IProps extends IWithOpenAttachmentHandlerData {
  attachmentType: string;
  data: string;
  onDeleteMessage: (id: string) => void;
  onOpenAttachment: (id: string, messageGuid: string) => void;
  thumbnail: string;
  status: MessageStatusType;
  timeout?: number;
}

interface IStatusWrapperProps {
  status: MessageStatusType;
}

const StatusWrapper = styled.div<IStatusWrapperProps>`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 10px;
  right: 10px;
  color: var(--white);
  width: 26px;
  height: 16px;
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.7);
  ${props =>
    props.status !== 'not_delivered' &&
    css`
      svg {
        position: absolute;
        top: 1px;
      }
      svg:nth-of-type(1) {
        right: 5px;
      }
      svg:nth-of-type(2) {
        right: 8px;
      }
    `}
`;

export const SentAttachment = (props: IProps) => (
  <MessageRowWrapper>
    <MessageSentRow>
      <AttachmentWrapper>
        <RowOptions
          isFlipped
          onDeleteMessage={() => props.onDeleteMessage(props.messageGuid)}
        />
        <Button
          onClick={() =>
            props.onOpenAttachment(props.realId, props.messageGuid)
          }
        >
          <AttachmentThumbnail
            thumbnail={props.thumbnail}
            data={props.data}
            type={props.attachmentType}
          />
        </Button>
        {props.timeout ? <TimerBadge value={props.timeout} paused /> : null}
        {props.status !== 'sending' && (
          <StatusWrapper status={props.status}>
            <Status status={props.status} />
          </StatusWrapper>
        )}
      </AttachmentWrapper>
    </MessageSentRow>
  </MessageRowWrapper>
);

export default pure(SentAttachment);
