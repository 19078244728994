import { withStateHandlers } from 'recompose';

export interface IWithPopoverState {
  isPopoverOpen: boolean;
  onTogglePopover: () => void;
}

const withPopoverState = withStateHandlers(
  {
    isPopoverOpen: false,
  },
  {
    onTogglePopover: (state: any) => () => ({
      isPopoverOpen: !state.isPopoverOpen,
    }),
  },
);

export default withPopoverState;
