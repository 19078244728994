import { ISudoAvatar } from '../../api';
import createEntitySelectors from '../common/createEntitySelectors';
import ENTITY_TYPE from './entityType';

export const createSudoAvatarSelectors = () => {
  const commonSelectors = createEntitySelectors<ISudoAvatar>(ENTITY_TYPE);

  return {
    ...commonSelectors,
  };
};

const SudoAvatarSelectors = createSudoAvatarSelectors();

export default SudoAvatarSelectors;
