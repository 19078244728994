import * as React from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { mapProps } from 'recompose';

import {
  ConversationViewWrapper,
  Layout,
  LayoutConversationView,
  LayoutToolbarTop,
} from '../Conversation/ConversationLayout';
import ParticipantsViewToolbar from '../Conversation/ParticipantsViewToolbar';
import MessagesList from '../Conversation/MessagesList';
import InputToolbar, { IInputToolbarValue } from '../Conversation/InputToolbar';
import withConversationContentData, {
  IDispatchFromProps,
  IStateFromProps,
  IStateProps,
} from './withConversationContentData';
import { ChildrenParams } from '../../../features/List/utils/ScrollLoadMore';
import AttachmentViewModal from '../../../components/AttachmentViewModal';

export interface IProps
  extends IStateProps,
    IStateFromProps,
    IDispatchFromProps {}

export interface IMatch {
  sudo: string;
  id: string;
}

export interface IRawProps
  extends RouteComponentProps<IMatch>,
    ChildrenParams {}

export class ConversationContent extends React.Component<IProps, {}, any> {
  private readonly listScrollRef: any;

  constructor(props: IProps) {
    super(props);
    this.listScrollRef = (React as any).createRef();
  }

  public scrollToBottom = () => {
    const el = this.listScrollRef && this.listScrollRef.current;
    if (el) {
      el.scrollTop = el.scrollHeight - el.clientHeight;
    }
  };

  public componentDidMount() {
    this.scrollToBottom();
    if (!this.props.redirectToAll) {
      this.props.onLoadMore(this.loadMoreMessages);
      this.props.openConversation(
        this.props.phoneAccountGuid,
        this.props.conversationGuid,
      );
      this.loadMoreMessages();
      this.props.markMessagesAsRead(this.props.conversationGuid);
    }
  }

  public getSnapshotBeforeUpdate(prevProps: IProps) {
    // Save scroll position
    const oldData = prevProps.messageData;
    const newData = this.props.messageData;
    const oldComposition = prevProps.composition;
    const newComposition = this.props.composition;
    if (
      (oldData && newData && oldData.length !== newData.length) ||
      oldComposition !== newComposition
    ) {
      const {
        scrollHeight: totalMessageListHeight,
        clientHeight: visibleMessageListHeight,
        scrollTop: distanceFromTop,
      } = this.listScrollRef.current;
      const distanceFromBottom =
        totalMessageListHeight - visibleMessageListHeight - distanceFromTop;

      return distanceFromBottom;
    }
    return null;
  }

  public componentDidUpdate(
    prevProps: IProps,
    _prevState: any,
    snapshot: number | null,
  ) {
    const el = this.listScrollRef.current;
    if (!el) {
      return;
    }

    const wasAtBottom = snapshot === 0;
    const wasAtTop = el.scrollTop === 0;
    if (this.props.conversationGuid !== prevProps.conversationGuid) {
      this.scrollToBottom();
      this.props.closeConversation(prevProps.phoneAccountGuid);
      if (!this.props.redirectToAll) {
        this.props.openConversation(
          this.props.phoneAccountGuid,
          this.props.conversationGuid,
        );
        this.loadMoreMessages();
      }
    } else if (snapshot !== null && (wasAtBottom || wasAtTop)) {
      // Restore previous scroll position offset from bottom to keep at bottom,
      // otherwise leave it in the same position
      const {
        scrollHeight: totalMessageListHeight,
        clientHeight: visibleMessageListHeight,
      } = el;
      el.scrollTop =
        totalMessageListHeight - visibleMessageListHeight - snapshot;
    }

    if (this.props.isWindowFocused) {
      this.props.markMessagesAsRead(this.props.conversationGuid);
    }
  }

  public componentWillUnmount() {
    this.props.closeConversation(this.props.phoneAccountGuid);
  }

  public loadMoreMessages = () => {
    if (!this.props.noMoreMessages && !this.props.isFetching) {
      this.props.loadMoreMessages(
        this.props.conversationGuid,
        this.props.oldestMessage,
      );
    }
  };

  public handleInputOnChange = (value: IInputToolbarValue) => {
    this.props.onChange(this.props.conversationGuid, value);
  };

  public render() {
    return this.props.redirectToAll ? (
      <Redirect to={`/${this.props.sudoSlug}/messages`} />
    ) : (
      <Layout>
        <LayoutToolbarTop>
          <ParticipantsViewToolbar
            title={this.props.title}
            avatarImage={this.props.participantsAvatarImage}
            avatarText={this.props.participantsAvatarText}
            avatarLocked={this.props.participantsAvatarLocked}
            participants={this.props.participants}
            isGroupConversation={this.props.isGroupConversation}
            activeMembersWithAvatars={this.props.activeMembersWithAvatars}
            sudoSlug={this.props.sudoSlug}
            sudoGuid={this.props.sudoGuid}
            conversationGuid={this.props.conversationGuid}
            phoneAccountGuid={this.props.phoneAccountGuid}
          />
        </LayoutToolbarTop>
        <LayoutConversationView>
          <ConversationViewWrapper>
            <MessagesList
              onScroll={this.props.onScroll}
              data={this.props.messageData}
              isFetching={this.props.isFetching}
              onOpenAttachment={this.props.openAttachment}
              listScrollRef={this.listScrollRef}
              onEditMessage={this.props.editMessage}
              onDeleteMessage={this.props.deleteMessage}
            />
            <AttachmentViewModal
              modal={this.props.viewAttachmentModal}
              onClose={this.props.closeAttachment}
            />
            <InputToolbar
              autoFocus
              key={this.props.conversationGuid}
              isCanWrite={this.props.isCanWrite}
              inputPlaceholder={`${
                this.props.participantsAvatarLocked ? 'Encrypted' : 'Text'
              } Message`}
              onSubmit={this.props.onSubmit}
              onChange={this.handleInputOnChange}
              isNoEmptySubmit={true}
              composition={this.props.composition}
            />
          </ConversationViewWrapper>
        </LayoutConversationView>
      </Layout>
    );
  }
}

export const propsMapper = (props: IRawProps) => ({
  sudoSlug: props.match.params.sudo,
  conversationGuid: decodeURIComponent(props.match.params.id),
  onLoadMore: props.onLoadMore,
  onScroll: props.onScroll,
});
const mapRouterProps = mapProps(propsMapper);

export default mapRouterProps(
  withConversationContentData(
    (ConversationContent as any) as React.ComponentClass<IProps>,
  ),
) as React.ComponentClass<IRawProps>;
