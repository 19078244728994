import { ISudoCounts } from '../../../api';

interface IGroupedCounts {
  total: {
    [folderName: string]: number;
  };
  unread: {
    [folderName: string]: number;
  };
}

const groupCountsByFolderName = (counts: ISudoCounts): IGroupedCounts => {
  return {
    total: {
      inbox: (counts && counts.emailInboxTotal) || 0,
      sent: (counts && counts.emailSentTotal) || 0,
      drafts: (counts && counts.emailDraftsTotal) || 0,
      trash: (counts && counts.emailTrashTotal) || 0,
    },
    unread: {
      inbox: (counts && counts.emailInboxUnread) || 0,
      trash: (counts && counts.emailTrashUnread) || 0,
    },
  };
};

export default groupCountsByFolderName;
