import styled from 'styled-components';
import { Button } from 'src/components';

export const SectionWrapper = styled.div`
  margin-top: 16px;
  color: var(--black);
  font-size: 17px;
  line-height: 20px;
  padding: 16px;
  background-color: var(--white);
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const Section = styled.div`
  width: 380px;
`;

export const AddItemButton = styled(Button)`
  span {
    color: var(--blue);
    font-size: 17px;
  }
`;

export const RemovableFieldRowWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
`;

export const FieldRowLabel = styled.div`
  width: 80px;
`;

export const FieldRowValue = styled.div`
  margin-left: calc(var(--theme-spacing) * 3);
  flex-grow: 1;
`;
