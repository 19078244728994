import { combineReducers, ReducersMapObject } from 'redux';
import { reducer as formReducer } from 'redux-form';

import conversationCreation from '../pages/Messages/ConversationCreation/modules';
import emails from '../pages/Emails/modules';
import messagesErrors from '../pages/Messages/MessagesErrors/modules';
import redirect from '../features/Redirect/modules';
import { appReducer } from './app-reducer';
import { contactsReducer } from './contacts-reducer';
import { entitiesReducer } from './entities-reducer';
import { errorTrapReducer } from '../containers/error-trap-modal/modules';
import { listsReducer } from './lists-reducer';
import { messagesReducer } from './messages-reducer';
import { modalReducer } from './modal-reducer';
import { reducer as avatarImagesReducer } from './avatar-images-reducer';
import { sidePanelReducer } from './side-panel-reducer';

// top-level root reducer state is a plain object, not immutable.
// the reason for this is that 3rd party enhancers (redux-router)
// expects the use of the standard combineReducers() function

export const rootReducer = combineReducers({
  app: appReducer,
  avatarImages: avatarImagesReducer,
  contacts: contactsReducer,
  conversationCreation,
  emails,
  entities: entitiesReducer,
  errorTrap: errorTrapReducer,
  form: formReducer,
  lists: listsReducer,
  messages: messagesReducer,
  messagesErrors,
  modal: modalReducer,
  redirect,
  sidePanel: sidePanelReducer,
} as ReducersMapObject);
