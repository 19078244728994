import { IBindingInfo, EventMap } from 'src/api';
import { config } from 'src/config';
import { writeConnectionInfo, IConnectionInfo } from 'src/connection-info';
import { readEnvironmentPreference, environments } from 'src/environments';
import { pair } from 'src/lib/sudo-client/pair';
import { delay } from 'src/utils';
import { IThunk } from './actions-base';
import { bindAction } from './api-bind-action';
import { pairingTokenAction } from './api-pairing-token-action';

export const BEGIN_PAIRING = 'BEGIN_PAIRING_ACTION';

const defaultRefreshMs = 45000;

export const beginPairingAction = config.newApi
  ? beginPairingActionNew
  : beginPairingActionOld;

/**
 * Starts pairing process on via api and dispatches
 * status notifications. After a successful pairing,
 * will immediatately dispatch a bind action.
 * This action is intended to be called only once.
 */
export function beginPairingActionOld(
  refreshMs: number = defaultRefreshMs,
): IThunk<void> {
  return async (dispatch, getState, { api }) => {
    const envName = readEnvironmentPreference();
    const env = environments[envName];

    // Listen for QR codes emitted during pairing process
    const qrCodeHandler = (ev: EventMap['qrCode']) => {
      dispatch(pairingTokenAction(ev.qrCode));
    };
    api.addListener('qrCode', qrCodeHandler);

    // Attempt to pair, refreshing pairing code every `timeoutMs`
    let bindingInfo: IBindingInfo;
    while (!bindingInfo) {
      const timeoutPromise = delay(refreshMs);
      const pairPromise = api.pair(env.pairingHost, env.appName);
      bindingInfo = await Promise.race([pairPromise, timeoutPromise]);

      await dispatch(pairingTokenAction(null));
      await api.disconnect();
    }

    api.removeListener('qrCode', qrCodeHandler);

    const connectionInfo: IConnectionInfo = {
      bindingInfo,
      environment: envName,
      staySignedIn: getState().app.stayLoggedIn,
      subscriptions: [],
    };
    writeConnectionInfo(connectionInfo);

    return dispatch(bindAction());
  };
}

/**
 * Starts pairing process on and dispatches
 * qr code notifications. After a successful pairing,
 * will immediatately dispatch a bind action.
 * This action is intended to be called only once.
 */
export function beginPairingActionNew(
  refreshMs: number = defaultRefreshMs,
): IThunk<void> {
  return async (dispatch, getState) => {
    const envName = readEnvironmentPreference();
    const env = environments[envName];
    const protocolVersion = config.webRtc ? 3 : 2;
    const nameTag = config.webRtc ? ' WEB_RTC' : '';

    const bindingInfo = await pair({
      appName: env.appName,
      clientName: config.appName + nameTag,
      clientVersion: config.version,
      pairingHost: env.pairingHost,
      protocolVersion,
      refreshMs,
      onQrCode: qrCode => {
        dispatch(pairingTokenAction(qrCode));
      },
    });

    const connectionInfo: IConnectionInfo = {
      bindingInfo,
      environment: envName,
      staySignedIn: getState().app.stayLoggedIn,
      subscriptions: [],
    };
    writeConnectionInfo(connectionInfo);

    return dispatch(bindAction());
  };
}
