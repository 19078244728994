export const uuid = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
    /[xy]/g,
    (c: string) => {
      const r = (Math.random() * 16) | 0; // tslint:disable-line:no-bitwise
      const v = c === 'x' ? r : (r & 0x3) | 0x8; // tslint:disable-line:no-bitwise
      return v.toString(16);
    },
  );
};

/** Regexp to identify a uuid */
export const regex = new RegExp(
  /\b[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}\b/,
);
