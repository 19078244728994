import { IAmplifyConfig } from 'src/amplify';
import { BrowserStorageKey } from 'src/browser-storage';
import { config } from 'src/config';
import { readConnectionInfo } from 'src/connection-info';

// Configuration

export interface IEnvironments {
  [name: string]: IEnvironment;
}

export const productionEnvironment = 'prod';

export const environments: IEnvironments = config.anonyomeEnvironments;

export interface IEnvironment {
  appName: string;
  pairingHost: string;
  amplifyConfig?: IAmplifyConfig;
}

export const environmentNames = Object.keys(environments);

/** Environment preference is always written to local storage */
export function writeEnvironmentPreference(name: string) {
  if (environmentNames.indexOf(name) === -1) {
    throw new Error('Invalid environment name');
  }

  localStorage.setItem(BrowserStorageKey.SudoEnvironmentPreference, name);
}

export function readEnvironmentPreference() {
  const name = localStorage.getItem(
    BrowserStorageKey.SudoEnvironmentPreference,
  );
  if (environmentNames.includes(name)) {
    return name;
  } else {
    return productionEnvironment;
  }
}

export function getCurrentEnvironmentName() {
  const connectionInfo = readConnectionInfo();
  if (connectionInfo) {
    return connectionInfo.environment;
  } else {
    return readEnvironmentPreference();
  }
}

export function getCurrentEnvironmentConfig() {
  return environments[getCurrentEnvironmentName()];
}
