import uuid from 'uuid';
import { ProtectedTypedEvents } from 'src/lib/events';
import { Logger, createLog } from 'src/lib/logging';
import { chunkify, ChunkAssembler } from './chunking';
import {
  IDataChannelOpts,
  IDataChannel,
  DataChannelEventMap,
  Data,
} from './data-channel';
import {
  stringToArrayBuffer_utf8,
  arrayBufferToString_utf8,
} from './data-converters';
import { ReadyState } from './ready-state';
import {
  SessionServiceClient,
  EventMap as SessionServiceEventMap,
} from './session-service-client';
import {
  IWebRtcCandidateMessage,
  ISessionClosedMessage,
} from './session-service-messages';
import { IPeerDescription } from './peer-description';
import { delay } from '../async';

const defaultChunkSize = 16384;

export interface IWebRtcDataMessage {
  type: 'message';
  messageId: string;
  inResponseTo?: string;
  message: any;
  sequence?: string;
}

interface IWebRtcDataChannelOpts extends IDataChannelOpts {
  messagePrefix: string;

  /** Alternative SessionServiceClient implementation */
  SessionServiceClient?: ConstructorOf<typeof SessionServiceClient>;
}

export class WebRtcDataChannel extends ProtectedTypedEvents<DataChannelEventMap>
  implements IDataChannel {
  /** IDataChannel.ready */
  public readonly ready: Promise<IPeerDescription>;

  /** Signalling client */
  private sessionServiceClient: SessionServiceClient | null = null;

  /** Current WebRTC Connection */
  private rtcPeerConnection: RTCPeerConnection | null = null;

  /** Current WebRTC Data Channel */
  private rtcDataChannel: RTCDataChannel | null = null;

  /** Log */
  private logger: Logger;

  /** Max chunk size for chunking */
  private chunkSize = defaultChunkSize;

  /** Helper to assemble chunked messages */
  private chunkAssembler = new ChunkAssembler();

  /** Ctor */
  public constructor(private opts: IWebRtcDataChannelOpts) {
    super();
    this.logger = createLog('WebRtcDataChannel', opts.logLevel);
    this.ready = this.asyncInit();
  }

  /** Async init and connection setup */
  private async asyncInit() {
    const bindInfo = await this.initSessionServiceClient();

    const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if (isFirefox || isSafari) {
      try {
        await navigator.mediaDevices.getUserMedia({ audio: true });
      } catch {
        //
      }
    }

    this.initRtcPeerConnection(bindInfo.iceConfig);
    this.initRtcDataChannel();

    this.logger.debug('Waiting for data channel');
    await new Promise(resolve => {
      this.rtcDataChannel.onopen = () => resolve();
    });
    this.logger.debug('DC OPEN!', this.rtcDataChannel.readyState);
    await delay(1000);

    return bindInfo.peerDescription;
  }

  /**
   * Negotiates a WebRtc peer connection and creates a data channel
   */
  private async negotiateConnection() {
    // Create local offer and send it to remote
    this.logger.debug('sending SDP offer');
    const localOffer = await this.rtcPeerConnection.createOffer();
    this.rtcPeerConnection.setLocalDescription(localOffer);

    // Send SDP offer and wait for answer
    const sdpResponse = await this.sessionServiceClient.sendRequest({
      type: 'sdp',
      messageId: this.sessionServiceClient.generateMessageId(),
      message: JSON.stringify(localOffer),
    });

    // Process answer
    this.logger.debug('got SDP answer');
    const remoteAnswer: RTCSessionDescriptionInit = JSON.parse(
      sdpResponse.message,
    );
    await this.rtcPeerConnection.setRemoteDescription(remoteAnswer);
    this.logger.debug('REMOTE ANSWER', remoteAnswer);
  }

  /** IDataChannel.readyState */
  public get readyState() {
    return ReadyState.Closed;
  }

  /** IDataChannel.generateMessageId */
  public generateMessageId(): string {
    return this.opts.messagePrefix + uuid.v4();
  }

  /** IDataChannel.sendData */
  public async sendData(messageId: string, data: Data) {
    const { chunks } = chunkify(data, this.chunkSize);

    const messagesToSend = chunks.map(chunk => ({
      type: 'message',
      messageId,
      sequence: chunk.sequence,
      message: chunk.data,
    }));

    messagesToSend.forEach(message => {
      const encoded = encodeMessage(message);
      this.rtcDataChannel.send(encoded);
    });
  }

  /** Processes an incoming message */
  private async receivePeerMessage(dataMessage: IWebRtcDataMessage) {
    this.logger.debug('RECEIVING:', dataMessage);
    let fullMessage: IWebRtcDataMessage;
    if (dataMessage.sequence) {
      let payload: any;
      try {
        payload = this.chunkAssembler.receive(
          dataMessage.message,
          dataMessage.sequence,
          dataMessage.messageId,
        );
      } catch {
        this.logger.warn(
          `Error assembling message chunk ${dataMessage.messageId}`,
        );
        return;
      }

      if (!payload) {
        return;
      }

      fullMessage = {
        type: 'message',
        messageId: dataMessage.messageId,
        inResponseTo: dataMessage.inResponseTo,
        message: payload,
      };
    } else {
      fullMessage = dataMessage;
    }

    this.emit('message', {
      data: fullMessage.message,
      inResponseTo: fullMessage.inResponseTo,
      status: 200,
    });
  }

  /** IDataChannel.close */
  public close(): void {
    this.emit('readyState', { readyState: ReadyState.Closed });
    this.closeSessionService();
    this.closeRtcPeerConnection();
    this.closeRtcDataChannel();
  }

  // --------------------------------------------------------------------------
  // Session Service Client

  private async initSessionServiceClient() {
    const { bindingInfo, messagePrefix } = this.opts;
    const { connectionUrl, bindingToken } = bindingInfo;

    this.sessionServiceClient = new SessionServiceClient(
      `${connectionUrl}?bearer=${bindingToken}`,
      {
        messagePrefix,
        logLevel: 'info',
      },
    );
    this.sessionServiceClient.addListener(
      'message',
      this.sessionServiceClient_onMessage,
    );

    return this.sessionServiceClient.waitForNotificationMessage(
      'bindingNotification',
    );
  }

  // tslint:disable-next-line:variable-name
  private sessionServiceClient_onMessage = (
    event: SessionServiceEventMap['message'],
  ) => {
    const { message } = event;

    switch (message.type) {
      case 'candidate':
        return this.receiveRemoteCandidateMessage(
          message as IWebRtcCandidateMessage,
        );
      case 'sessionClosed':
        return this.receiveSessionClosedMessage(
          message as ISessionClosedMessage,
        );
      default:
    }
  };

  private receiveRemoteCandidateMessage(message: IWebRtcCandidateMessage) {
    if (this.rtcPeerConnection.iceGatheringState !== 'gathering') {
      return;
    }

    const candidateMessage = message as IWebRtcCandidateMessage;
    const candidate: RTCIceCandidate | RTCIceCandidateInit = JSON.parse(
      candidateMessage.candidate,
    );
    this.rtcPeerConnection.addIceCandidate(candidate);
  }

  private receiveSessionClosedMessage(message: ISessionClosedMessage) {
    this.emit('sessionClosed', {});
  }

  private closeSessionService() {
    if (!this.sessionServiceClient) {
      return;
    }

    this.sessionServiceClient.removeAllListeners();
    this.sessionServiceClient.close();
    this.sessionServiceClient = null;
  }

  // --------------------------------------------------------------------------
  // RTC Peer Connection

  private initRtcPeerConnection(iceServers: RTCIceServer[]) {
    // TODO: still needed in Safari??
    // const transformedIceServers = iceServers.map(server => {
    //   if ((server as any).url && !server.urls) {
    //     const { url, ...otherServerProps } = server as any;
    //     return {
    //       ...otherServerProps,
    //       urls: url,
    //     };
    //   }
    //   return server;
    // });

    this.logger.debug(iceServers);

    this.rtcPeerConnection = new RTCPeerConnection({
      bundlePolicy: 'balanced',
      iceServers,
    });
    this.rtcPeerConnection.onicecandidate = this.rtcPeerConnection_onIceCandidate;
    this.rtcPeerConnection.oniceconnectionstatechange = this.rtcPeerConnection_onStateChange;
    this.rtcPeerConnection.onicegatheringstatechange = this.rtcPeerConnection_onStateChange;
    this.rtcPeerConnection.onnegotiationneeded = this.rtcPeerConnection_onNegotiationNeeded;
    (window as any).rtcPeerConnection = this.rtcPeerConnection;
  }

  // tslint:disable-next-line:variable-name
  private rtcPeerConnection_onIceCandidate = (
    event: RTCPeerConnectionIceEvent,
  ) => {
    this.logger.debug('ICE Candidate', event);
    if (event.candidate) {
      this.sessionServiceClient.send({
        type: 'candidate',
        messageId: this.sessionServiceClient.generateMessageId(),
        candidate: JSON.stringify(event.candidate),
      });
    }
  };

  // tslint:disable-next-line:variable-name
  private rtcPeerConnection_onStateChange = (event: Event) => {
    this.logger.debug(
      'State change',
      'connection: ' + this.rtcPeerConnection.iceConnectionState,
      'gathering: ' + this.rtcPeerConnection.iceGatheringState,
      'signalling: ' + this.rtcPeerConnection.signalingState,
    );

    if (this.rtcPeerConnection.iceConnectionState === 'failed') {
      this.logger.error('FAILED');
      throw new Error('CONNECTION FAILED');
    }
  };

  // tslint:disable-next-line:variable-name
  private rtcPeerConnection_onNegotiationNeeded = (event: Event) => {
    this.logger.debug('Negotiation Needed');
    this.negotiateConnection();
  };

  private closeRtcPeerConnection() {
    if (!this.rtcPeerConnection) {
      return;
    }

    this.rtcPeerConnection.onicecandidate = null;
    this.rtcPeerConnection.oniceconnectionstatechange = null;
    this.rtcPeerConnection.onicegatheringstatechange = null;
    this.rtcPeerConnection.onnegotiationneeded = null;
    this.rtcPeerConnection = null;
  }

  // --------------------------------------------------------------------------
  // RTC Data Channel

  private initRtcDataChannel() {
    this.rtcDataChannel = this.rtcPeerConnection.createDataChannel(
      'MainDataChannel',
    );
    this.rtcDataChannel.binaryType = 'arraybuffer';
    this.rtcDataChannel.onclose = this.rtcDataChannel_onClose;
    this.rtcDataChannel.onerror = this.rtcDataChannel_onError;
    this.rtcDataChannel.onmessage = this.rtcDataChannel_onMessage;
    (window as any).rtcDataChannel = this.rtcDataChannel;
  }

  // tslint:disable-next-line:variable-name
  private rtcDataChannel_onClose = (event: Event) => {
    this.logger.debug('RTCDataChannel: Remote close detected', event);
  };

  // tslint:disable-next-line:variable-name
  private rtcDataChannel_onError = (event: Event) => {
    this.logger.debug('RTCDataChannel: Error', event);
  };

  // tslint:disable-next-line:variable-name
  private rtcDataChannel_onMessage = (event: MessageEvent) => {
    const decoded = decodeMessage(event.data);
    this.receivePeerMessage(decoded);
  };

  private closeRtcDataChannel() {
    if (!this.rtcDataChannel) {
      return;
    }

    this.rtcDataChannel.onclose = null;
    this.rtcDataChannel.onerror = null;
    this.rtcDataChannel.onmessage = null;
    this.rtcDataChannel = null;
  }

  /** IDataChannel.wakeUp() */
  public async wakeUp() {
    // Dummy implementation

    this.emit('message', {
      status: 200,
      data: {
        type: 'STATUS',
        isAwake: true,
      },
    });
  }
}

/**
 * Encodes an JS object for sending via WebRTC data channel
 */
export function encodeMessage(message: any): ArrayBuffer {
  const json = JSON.stringify(message);
  return stringToArrayBuffer_utf8(json);
}

/**
 * Decodes WebRTC data channel message into JS object
 */
export function decodeMessage(binary: ArrayBuffer): any {
  const json = arrayBufferToString_utf8(binary);
  return JSON.parse(json);
}
