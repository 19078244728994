import React from 'react';

export default function ReplyAll(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      {...props}
    >
      <g fill="currentColor" fillRule="evenodd" transform="translate(0 4)">
        <path d="M13.316 6.074h-.745V1.897L5.676 7.993l6.895 6.096V9.798h.745c4.126 0 6.415.226 8.821 2.81-.848-3.831-3.356-6.534-8.82-6.534zm10.677 8.882c.04.516-.088.842-.384.976-.444.201-.98-.082-1.153-.36-2.045-3.277-4.152-3.936-8.394-4.114v3.158c0 .281-.078.555-.225.786-.393.62-1.168.77-1.73.337L4.53 9.115a1.314 1.314 0 0 1-.305-.337c-.394-.62-.257-1.474.305-1.908L12.107.248c.209-.161.458-.248.712-.248.687 0 1.243.614 1.243 1.37v3.075c6.59.271 9.4 4.27 9.93 10.511zm-14.946-.559c.32.28.365.785.102 1.126a.718.718 0 0 1-1.056.109L.455 8.92a1.295 1.295 0 0 1-.172-.184c-.437-.57-.36-1.41.174-1.876L8.094.18A.718.718 0 0 1 9.15.293a.837.837 0 0 1-.105 1.126L2.073 7.517a.5.5 0 0 0 0 .752l6.974 6.128z" />
      </g>
    </svg>
  );
}
