import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';

import { IRootState } from '../../state';
import { IDispatcher } from '../../store';

import { actions } from './modules';

type IActionProps = typeof actions;

interface IStateProps {
  to: string;
}

interface IProps extends IStateProps, IActionProps, IOwnProps {}

interface IOwnProps {
  children?: any;
}

class AppRedirect extends React.Component<IProps> {
  public componentDidUpdate() {
    const props = this.props;
    if (props.to) {
      props.setRedirectSuccess();
    }
  }

  public render() {
    const props = this.props;
    return !!props.to ? <Redirect to={props.to} /> : props.children;
  }
}

export default connect(
  (state: IRootState): IStateProps => state.redirect,
  (dispatch: IDispatcher): IActionProps =>
    bindActionCreators(actions, dispatch),
)(AppRedirect);
