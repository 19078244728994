import ButtonBase from '../ButtonBase';
import styled from 'styled-components';

const ButtonSecondary = styled(ButtonBase)`
  color: var(--blue);
  border: solid 1px var(--blue);
  border-radius: 16px;
  padding: 8px 24px;
`;

ButtonSecondary.displayName = 'ButtonSecondary';

export default ButtonSecondary;
