import * as React from 'react';
import { Spinner } from '../../../../components/spinner';
import styled from 'styled-components';

const Title = styled.div`
  font-size: 24px;
  color: var(--black);
  line-height: 28px;
  margin-top: 16px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: -32px;
`;

interface IProps {
  title: React.ReactNode;
}
export default (props: IProps) => (
  <Content>
    <Spinner />
    <Title>{props.title}</Title>
  </Content>
);
