import * as React from 'react';
import styled from 'styled-components';
import { Badge } from 'src/components';

export interface IProps {
  children: any;
}

const InfoBadgeRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: calc(var(--theme-spacing) * 3) 0;
`;

const StyledBadge = styled(Badge)`
  border-radius: 12px;
  line-height: 14px;
`;

export const InfoBadge = (props: IProps) => (
  <InfoBadgeRow>
    <StyledBadge>{props.children}</StyledBadge>
  </InfoBadgeRow>
);

export default InfoBadge;
