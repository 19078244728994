import React from 'react';

export default function Close(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <g
        fill="currentColor"
        fillRule="evenodd"
        transform="rotate(45 8.586 13.414)"
      >
        <path d="M10.75 9.25h8.5a.75.75 0 1 1 0 1.5h-8.5v8.5a.75.75 0 1 1-1.5 0v-8.5H.75a.75.75 0 1 1 0-1.5h8.5V.75a.75.75 0 0 1 1.5 0v8.5z" />
      </g>
    </svg>
  );
}
