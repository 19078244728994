import * as FileSaver from 'file-saver';

const base64toBlob = (attachment: any) => {
  const byteString = atob(attachment.data);
  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; ++i) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ia], { type: 'application/octet-stream' });
};

const downloadAttachment = (attachment: any) => {
  return FileSaver.saveAs(base64toBlob(attachment), attachment.filename);
};

export default downloadAttachment;
