import * as LegacyApi from 'src/api/api-interfaces';
import * as LegacyMsg from 'src/api/message-interfaces';
import * as LegacyEntity from 'src/api/entities';
import { ProtectedTypedEvents } from 'src/lib/events';
import {
  ISudoClient,
  UnavailableEvent,
  ChangesEvent,
  ResubscribeEvent,
  SessionClosedEvent,
} from 'src/lib/sudo-client/sudo-client';
import { createRequestProcessor } from 'src/api/request-processor';
import {
  SudoRequest,
  IResponseMessage,
} from 'src/lib/sudo-client/sudo-messages';
import { ReadyState, ReadyStateEvent } from 'src/lib/sudo-client/ready-state';
import {
  SudoClientError,
  SudoClientErrorCode,
} from 'src/lib/sudo-client/sudo-client-error';
import { IBindingInfo } from 'src/lib/sudo-client/binding-info';

const requestFormatVersion = 2;
const requestProcessor = createRequestProcessor();

interface IOpts {
  sudoClientFactory: (bindingInfo: IBindingInfo) => ISudoClient;
}

/**
 * Wrapper for new SudoClient that supports legacy interface.
 * Also responsible for massaging data into expected forms.
 */
export class MySudoClient extends ProtectedTypedEvents<LegacyApi.EventMap>
  implements LegacyApi.IApi {
  /** Main client instance */
  private sudoClient: ISudoClient | null = null;

  /** Connection status */
  public get status() {
    return this._status;
  }
  private setStatus(status: LegacyApi.ApiStatus) {
    this._status = status;
    this.emit('status', { status });
  }
  private _status: LegacyApi.ApiStatus = 'disconnected';

  constructor(private opts: IOpts) {
    super();
  }

  /** Connects Sudo client */
  public async connect(bindingInfo: LegacyApi.IBindingInfo) {
    if (this.sudoClient) {
      throw new Error('Already connected');
    }

    this.setStatus('binding');
    this.sudoClient = this.opts.sudoClientFactory(bindingInfo);
    this.sudoClient.addListener('readyState', this.sudoClientReadyState);
    this.sudoClient.addListener('unavailable', this.sudoClientUnavailable);
    this.sudoClient.addListener('changes', this.sudoClientChanges);
    this.sudoClient.addListener('resubscribe', this.sudoClientResubscribe);
    this.sudoClient.addListener('sessionClosed', this.sudoClientSessionClosed);

    try {
      await this.sudoClient.ready;
    } catch (error) {
      this.setStatus('binding-error');

      if (!(error instanceof SudoClientError)) {
        throw error;
      }

      switch (error.code) {
        case SudoClientErrorCode.ConnectionError:
          throw new LegacyApi.ApiError('BIND_CONNECT_ERROR', undefined, error);
        case SudoClientErrorCode.InitError:
          throw new LegacyApi.ApiError('BIND_TIMEOUT', undefined, error);
        default:
          throw error;
      }
    }
    this.setStatus('connected');
  }

  /** SudoClient `readyState` event handler */
  private sudoClientReadyState = (event: ReadyStateEvent) => {
    if (event.readyState === ReadyState.Closed) {
      this.disconnect();
    }
  };

  /** SudoClient `unavailable` event handler */
  private sudoClientUnavailable = (_event: UnavailableEvent) => {
    this.setStatus('connection-unavailable');
  };

  /** SudoClient `sessionClosed` event handler */
  private sudoClientSessionClosed = (_event: SessionClosedEvent) => {
    this.setStatus('closing-unpaired');
  };

  /** SudoClient `changes` event handler */
  private sudoClientChanges = (event: ChangesEvent) => {
    const { changes } = event;
    this.emit('entityChanges', {
      changes: requestProcessor.postSubscribeEntities(
        changes as LegacyEntity.EntityChange[],
      ),
    });
  };

  /** SudoClient `resubscribe` event handler */
  private sudoClientResubscribe = (event: ResubscribeEvent) => {
    this.emit('subscriptionsExpired', {
      resubscribeIds: event.ids,
    });
  };

  /**
   * Attempts to resume an unavailable connection by
   * retransmitting all unanswered requests.
   */
  public async resumeConnection() {
    this.setStatus('connected');
    this.sudoClient.resendAllPendingRequests();
  }

  /** Disconnects Sudo client */
  public async disconnect() {
    if (!this.sudoClient) {
      return;
    }

    this.setStatus('disconnected');
    this.sudoClient.removeListener('readyState', this.sudoClientReadyState);
    this.sudoClient.close();
    this.sudoClient = null;
  }

  /**
   * Sends a request via Sudo Client API and wraps
   * any error in an ApiError.
   */
  public async sendRequest<T extends SudoRequest>(id: string, request: T) {
    if (!this.sudoClient) {
      throw new Error('Not connected');
    }

    try {
      return await this.sudoClient.sendRequest(id, request);
    } catch (error) {
      if (error instanceof SudoClientError) {
        if (
          error.message ===
          'generalError(causedBy: SudoSyncKit.OutgoingChangeError.paymentRequired)'
        ) {
          throw new LegacyApi.ApiError('PAYMENT_REQUIRED', undefined, error);
        }
      }

      throw new LegacyApi.ApiError('SEND_FAILED', {}, error);
    }
  }

  /** Generates a valid message ID */
  public generateMessageId() {
    return this.sudoClient.generateMessageId();
  }

  /**
   * Subscribes to entity change notifications from peer
   */
  public async subscribe(
    params: LegacyEntity.IQueryParams,
    _meta?: LegacyMsg.IRequestMeta,
    _since?: number, // deprecated
  ): Promise<LegacyApi.ISubscription> {
    const requestId = this.generateMessageId();
    const entities = requestProcessor.preSubscribeParams(params);

    await this.sudoClient.sendRequest(requestId, {
      version: requestFormatVersion,
      type: 'REQUEST',
      operation: 'SUBSCRIBE',
      entities,
    });

    return {
      requestId,
      entityTypes: params,
    };
  }

  /**
   * Unsubscribes from entity change notifications
   */
  public async unsubscribe(
    subscription: LegacyApi.ISubscription,
  ): Promise<void> {
    const entities = requestProcessor.preSubscribeParams(
      subscription.entityTypes,
    );

    await this.sudoClient.sendRequest(this.sudoClient.generateMessageId(), {
      version: requestFormatVersion,
      type: 'REQUEST',
      operation: 'UNSUBSCRIBE',
      entities,
    });
  }

  /**
   * Queries peer for data entities
   */
  public async search(
    params: LegacyEntity.IQueryParams,
    pagination?: LegacyMsg.ISearchPagination,
    meta: LegacyMsg.IRequestMeta = {},
    _since?: number, // deprecated
    _until?: number, // deprecated
    _timeoutMs?: number, // deprecated
    messageId?: string,
  ) {
    const entities = requestProcessor.preSearchParams(params);

    const response = await this.sendRequest(
      messageId || this.sudoClient.generateMessageId(),
      {
        version: requestFormatVersion,
        type: 'REQUEST',
        operation: 'SEARCH',
        entities,
        pagination: pagination || undefined,
        meta: meta || undefined,
      },
    );
    return requestProcessor.postSearchEntities(
      response.entities as LegacyEntity.IEntity[],
    );
  }

  /**
   * Inserts entities
   */
  public async add(
    entities: Array<Partial<LegacyEntity.IEntity>>,
    meta: LegacyMsg.IRequestMeta = {},
    _timeoutMs?: number, // deprecated
  ) {
    const addEntities = requestProcessor.preAddEntities(entities);

    const response = await this.sendRequest(
      this.sudoClient.generateMessageId(),
      {
        version: requestFormatVersion,
        type: 'REQUEST',
        operation: 'INSERT',
        entities: addEntities,
        meta: meta || undefined,
      },
    );

    return requestProcessor.postAddEntities(
      response.entities as LegacyEntity.IEntity[],
      meta,
    );
  }

  /**
   * Updates entities
   */
  public async update(
    entities: Array<Partial<LegacyEntity.IEntity>>,
    meta: LegacyMsg.IRequestMeta = {},
    _timeoutMs?: number, // deprecated
  ): Promise<LegacyEntity.IEntity[]> {
    const updateEntities = requestProcessor.preUpdateEntities(entities);

    const response = await this.sendRequest(
      this.sudoClient.generateMessageId(),
      {
        version: requestFormatVersion,
        type: 'REQUEST',
        operation: 'UPDATE',
        meta: meta || undefined,
        entities: updateEntities,
      },
    );

    return requestProcessor.postUpdateEntities(
      response.entities as LegacyEntity.IEntity[],
      meta,
    );
  }

  /**
   * Deletes entities
   */
  public async delete(
    entities: LegacyEntity.IQueryParams,
    meta: LegacyMsg.IRequestMeta = {},
    _timeoutMs?: number, // deprecated
  ): Promise<void> {
    const deleteEntities = requestProcessor.preDeleteParams(entities);

    await this.sendRequest(this.sudoClient.generateMessageId(), {
      version: requestFormatVersion,
      type: 'REQUEST',
      operation: 'DELETE',
      meta: meta || undefined,
      entities: deleteEntities,
    });
  }

  /**
   * Cancels a request
   */
  public async cancel(requestId: string): Promise<void> {
    await this.sendRequest(this.sudoClient.generateMessageId(), {
      version: requestFormatVersion,
      type: 'REQUEST',
      operation: 'CANCEL',
      cancelMessageId: requestId,
    });
  }

  /** Pair function (deprecated) */
  public pair(): Promise<LegacyApi.IBindingInfo> {
    throw new Error('Not implemented');
  }

  /** Logout function (deprecated) */
  public logout(): Promise<void> {
    throw new Error('Not implemented');
  }
}
