import React from 'react';

export default function Emoji(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <g fill="currentColor" fillRule="evenodd" transform="translate(1.5 1)">
        <path d="M10.5 21.5C4.701 21.5 0 16.799 0 11S4.701.5 10.5.5 21 5.201 21 11s-4.701 10.5-10.5 10.5zm0-1.5a9 9 0 1 0 0-18 9 9 0 0 0 0 18zm3.817-12.736c-.886 0-1.627.368-1.844.93a.938.938 0 0 1-.064-.338c0-1.17.853-2.038 1.908-2.038s1.91.964 1.91 2.038c0 .12-.025.238-.07.349-.217-.569-.958-.941-1.84-.941zm-7.636 0c-.886 0-1.627.368-1.845.93a.938.938 0 0 1-.063-.338c0-1.17.853-2.038 1.908-2.038s1.91.964 1.91 2.038c0 .12-.025.238-.07.349-.218-.569-.959-.941-1.84-.941zm3.819 9.054c-3.784 0-6.682-2.69-6.682-5.727 0 0 2.992.687 6.682.687 3.69 0 6.682-.687 6.682-.687 0 3.036-2.898 5.727-6.682 5.727z" />
      </g>
    </svg>
  );
}
