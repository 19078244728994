import { connect } from 'react-redux';
import moment from 'moment';

import { IRootState } from '../../../../state';
import SudoSettingsSelectors from '../../../../entities/sudo-settings/selectors';
import { IContactEmailAddress, IPhoneNumber } from '../../../../api';
import ContactSelectors from '../../../../entities/contact/selectors';
import { ILabelledValue } from '../withContactEditorData';
import { formatNumberForStorage } from '../../../../utils';
import { getContactName } from '../../selectors';
import { IContactAddressDetails } from '../../../../api';

export interface IStateProps {
  contactGuid?: string;
}

export interface IStateFromProps {
  initialValues: any;
  hasInitialData: IHasInitialData;
  contactName: string;
  isContactValid: boolean;
  isSudoContact: boolean;
}

export interface IContactFormValues {
  id: string;
  first: string;
  last: string;
  company: string;
  phones: ILabelledValue[];
  emails: ILabelledValue[];
  address: IContactAddressDetails;
  birthday: string;
  notes: string;
  avatar: string;
}

export interface IHasInitialData {
  address?: boolean;
  birthday?: boolean;
  notes?: boolean;
}

const getBaseInitialValues = (): IContactFormValues => ({
  id: null,
  first: '',
  last: '',
  company: '',
  phones: null,
  emails: null,
  address: {
    addressLine1: '',
    addressLine2: '',
    suburb: '',
    state: '',
    postcode: '',
    country: '',
  },
  birthday: null,
  notes: '',
  avatar: null,
});

export const mapState = (
  state: IRootState,
  props: IStateProps,
): IStateFromProps => {
  const initialValues = getBaseInitialValues();
  const hasInitialData: IHasInitialData = {};
  let contactName = null;
  let isContactValid = false;
  let isSudoContact = false;

  if (props.contactGuid) {
    const { contact, avatar } = ContactSelectors.getEntityWithAvatarById(
      state,
      { id: props.contactGuid },
    );

    if (contact) {
      isContactValid = true;
      isSudoContact = contact.isSudoContact;

      initialValues.id = contact.id;
      initialValues.first = contact.first;
      initialValues.last = contact.last;
      initialValues.company = contact.company;

      let phoneRegion: string = null;
      if (contact.parent && contact.parent.type === 'Sudo') {
        const sudoGuid = contact.parent.id;
        phoneRegion = SudoSettingsSelectors.getPhoneRegionById(state, {
          sudoGuid,
        });
      }

      initialValues.phones = contact.phoneNumbers.map(
        (phone: IPhoneNumber): ILabelledValue => ({
          label: phone.type,
          value: formatNumberForStorage(phoneRegion, phone.number),
        }),
      );

      initialValues.emails = contact.emailAddresses.map(
        (email: IContactEmailAddress): ILabelledValue => ({
          label: email.type,
          value: email.email,
        }),
      );

      const contactAddress = contact.streetAddresses[0];
      if (contactAddress && contactAddress.address) {
        initialValues.address = contactAddress.address;
      }

      hasInitialData.address = !!(contactAddress && contactAddress.address);
      hasInitialData.birthday = Number.isFinite(contact.birthday);

      if (hasInitialData.birthday) {
        initialValues.birthday = moment.utc(contact.birthday).format('Y-MM-DD');
      }

      if (contact.notes) {
        hasInitialData.notes = !!contact.notes;
        initialValues.notes = contact.notes;
      }

      if (avatar && avatar.avatar) {
        initialValues.avatar = avatar && avatar.avatar;
      }

      contactName = getContactName(contact);
    }
  }

  return {
    initialValues,
    hasInitialData,
    contactName,
    isContactValid,
    isSudoContact,
  };
};

export default connect(mapState);
