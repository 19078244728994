import * as Actions from '../actions';
import { IEntity } from '../api';
import { App, IApp } from '../state';
import {
  IProcessEntitiesAction,
  IUpdateSessionActivation,
  IUpdateStayLoggedInAction,
  IWindowFocusAction,
} from '../actions';

export function appReducer(
  state: IApp = App(),
  action: Actions.IAction = Actions.nullAction,
) {
  switch (action.type) {
    case Actions.APP_DATA_LOADED:
      return state.set('isInitialDataLoaded', action.payload);

    case Actions.API_STATUS:
      return state.set('apiStatus', action.payload);

    case Actions.API_PAIRING_TOKEN:
      return state.set('apiPairingToken', action.payload);

    case Actions.UPDATE_STAY_LOGGED_IN: {
      const tAction = action as IUpdateStayLoggedInAction;
      return state.withMutations(mState => {
        const { stayLoggedIn } = tAction.payload;
        mState.stayLoggedIn = stayLoggedIn;
      });
    }

    case Actions.RECEIVE_MESSAGE_ACTION:
      return state.set('notification', action.payload);

    case Actions.SAVING_ENTITIES:
      return state.set(
        'entitiesInFlight',
        state.entitiesInFlight.withMutations(mState => {
          (action.payload as IEntity[]).forEach(
            e => (mState = mState.set(e.id, e)),
          );
          return mState;
        }),
      );

    case Actions.ENTITIES_SAVED:
      return state.set(
        'entitiesInFlight',
        state.entitiesInFlight.withMutations(mState => {
          (action.payload as IEntity[]).forEach(
            e => (mState = mState.delete(e.id)),
          );
          return mState;
        }),
      );

    case Actions.SELECT_SUDO:
      return state.set('id', action.payload.sudoGuid);

    case Actions.PROCESS_ENTITIES: {
      const tAction = action as IProcessEntitiesAction;
      let last = state.lastEntityModified;
      tAction.payload.entities.forEach(entityBase => {
        const entity = entityBase as IEntity;
        if (!entity.modified || entity.modified <= last) {
          return;
        }
        last = entity.modified;
      });
      return state.withMutations(mState => {
        mState.lastEntityModified = last;
      });
    }

    case Actions.UPDATE_SESSION_ACTIVATION: {
      const tAction = action as IUpdateSessionActivation;
      return state.withMutations(mState => {
        mState.sessionActivated = tAction.payload.activated;
      });
    }

    case Actions.WINDOW_FOCUS: {
      const tAction = action as IWindowFocusAction;
      return state.withMutations(mState => {
        mState.isWindowFocused = tAction.payload.isWindowFocused;
      });
    }

    default:
      return state;
  }
}
