import * as React from 'react';
import { Icon } from 'src/components';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

const FoldersListHeader = styled.div`
  height: calc(var(--theme-spacing) * 7);
  min-height: calc(var(--theme-spacing) * 7);
  border-bottom: solid 1px var(--theme-divider-color);
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 18px;
  padding: 16px;
  box-sizing: border-box;
  color: var(--black);
  font-weight: bold;
`;

const List = styled.ul`
  width: 100%;
`;

const Label = styled.div`
  margin-left: 10px;
`;

const ListItem = styled(NavLink)`
  color: var(--gray-1);
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  list-style: none;
  text-decoration: none;
  padding: 12px 16px;
  box-sizing: border-box;

  > .filled {
    display: none;
  }

  &:hover {
    background: var(--gray-6);
  }

  &.isActive {
    background: var(--gray-6);

    > .filled {
      display: inline;
    }

    > .outline {
      display: none;
    }
  }
`;

interface IProps {
  baseUrl: string;
}

const FOLDERS = [
  {
    url: '/inbox',
    icon: {
      filled: 'EmailFill' as string,
      outline: 'EmailOutline' as string,
    },
    label: 'Inbox',
  },
  {
    url: '/sent',
    icon: { filled: 'SentFill' as string, outline: 'SentOutline' as string },
    label: 'Sent',
  },
  {
    url: '/drafts',
    icon: {
      filled: 'DraftsFill' as string,
      outline: 'DraftsOutline' as string,
    },
    label: 'Drafts',
  },
  {
    url: '/trash',
    icon: {
      filled: 'DeleteFill' as string,
      outline: 'DeleteOutline' as string,
    },
    label: 'Trash',
  },
];

const EmailsFolderList = ({ baseUrl }: IProps) => (
  <React.Fragment>
    <FoldersListHeader id="folders-list-header">Mailboxes</FoldersListHeader>
    <List>
      {FOLDERS.map((folder: any) => (
        <ListItem
          key={folder.label}
          to={`${baseUrl}${folder.url}`}
          activeClassName="isActive"
        >
          <Icon className="filled" name={folder.icon.filled} />
          <Icon className="outline" name={folder.icon.outline} />
          <Label>{folder.label}</Label>
        </ListItem>
      ))}
    </List>
  </React.Fragment>
);

export default EmailsFolderList;
