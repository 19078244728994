import * as React from 'react';
import styled from 'styled-components';
import { InputSingleLine } from 'src/components';

export interface IProps {
  onChange: (value: string) => void | null;
  placeholder: string;
  value: string;
}

export interface IState {
  value: string;
}

const Wrapper = styled.div`
  margin: 16px 0;
`;

export const StyledInput = styled(InputSingleLine)`
  background-color: var(--gray-8);
`;

export default class GroupNameInput extends React.Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props);

    this.state = {
      value: props.value || '',
    };
  }

  private static defaultProps: Partial<IProps> = {
    onChange: () => undefined,
    value: '',
  };

  public componentDidUpdate(prevProps: IProps) {
    if (prevProps.value !== this.props.value) {
      this.setState({ value: this.props.value });
    }
  }

  public handleChange = (event: React.SyntheticEvent<HTMLInputElement>) => {
    this.setState({
      value: event.currentTarget.value,
    });
  };

  public handleBlur = (event: React.SyntheticEvent<HTMLInputElement>) => {
    const value = this.state.value ? this.state.value : null;
    if (value !== this.props.value) {
      this.props.onChange(value);
    }
  };

  public handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      const value = this.state.value ? this.state.value : null;
      if (value !== this.props.value) {
        this.props.onChange(value);
      }
    }
  };

  public render() {
    return (
      <Wrapper>
        <StyledInput
          onKeyDown={this.handleKeyDown}
          onBlur={this.handleBlur}
          onChange={this.handleChange}
          placeholder={this.props.placeholder}
          value={this.state.value}
        />
      </Wrapper>
    );
  }
}
