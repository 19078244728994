import classNames from 'classnames';
import * as React from 'react';
import ReactModal from 'react-modal';
import { Spinner } from '../spinner';
import './modal.scss';

interface IProps {
  isOpen: boolean;
  className?: string;
  children?: React.ReactNode;
  contentLabel?: string;
  onClose?: () => any;
}

interface IState {
  isWorking?: boolean;
}

export class Modal extends React.Component<IProps, IState> {
  private _element: HTMLElement;

  public constructor(props: IProps) {
    super(props);
    this.state = { isWorking: false };
    this._element = document.querySelector('.app') as HTMLElement;
  }

  public render() {
    const { isOpen, className, contentLabel } = this.props;
    const { isWorking } = this.state;

    return (
      <ReactModal
        appElement={this._element}
        isOpen={isOpen}
        contentLabel={contentLabel || 'Modal'}
        className={classNames('modal-content', className)}
        portalClassName={classNames(
          'modal-portal',
          'modal-portal__' + className,
        )}
        overlayClassName="modal-overlay"
      >
        {isWorking ? this.renderWorking() : this.props.children}
      </ReactModal>
    );
  }

  public setIsWorking(isWorking: boolean) {
    this.setState({ ...this.state, isWorking });
  }

  public componentDidMount() {
    document.addEventListener('click', this.handleDocumentClick);
    document.addEventListener('keydown', this.handleEscapeKeyPressed);
  }

  public componentWillUnMount() {
    document.removeEventListener('click', this.handleDocumentClick);
    document.removeEventListener('keydown', this.handleEscapeKeyPressed);
  }

  private handleDocumentClick = (event: MouseEvent) => {
    const { isOpen, onClose } = this.props;
    const target = event.target as HTMLElement;
    const isOverlay = target.classList.contains('modal-overlay');

    if (isOverlay && isOpen && onClose) {
      onClose();
    }
  };

  private handleEscapeKeyPressed = (event: KeyboardEvent) => {
    const { isOpen, onClose } = this.props;

    if (event.keyCode === 27 && isOpen && onClose) {
      onClose();
    }
  };

  private renderWorking() {
    return (
      <div className="modal__working">
        {'Please wait...'}
        <Spinner />
      </div>
    );
  }
}

interface IModalButtonProps {
  type: 'cancel' | 'confirm';
  label: string | React.ReactElement<any>;
  handler: React.EventHandler<React.MouseEvent<HTMLElement>>;
}

export const ModalButton = ({ type, label, handler }: IModalButtonProps) => {
  const classes = classNames({
    [`modal__${type}`]: true,
    'sudo-border-color': true,
    'sudo-color': type === 'cancel',
    'sudo-background-color': type === 'confirm',
  });
  return (
    <button className={classes} onClick={handler}>
      {label}
    </button>
  );
};
