import * as React from 'react';
import styled from 'styled-components';

import SudoAvatar from '../../../../features/Sudo/SudoAvatar';

interface IProps {
  hasSudo: boolean;
  sudoLabel: string;
  avatarColor: string;
  avatarText: string;
  avatarImage?: string;
}

const ToolbarWrapper = styled.div`
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #303030;
`;

const AssignedText = styled.div`
  font-size: 16px;
  margin-right: 10px;
`;

const AvatarBorder = styled.div`
  width: 28px;
  height: 28px;
  border: solid 1px #40a3d7;
  border-radius: 50%;
`;

const StyledSudoAvatar = styled(SudoAvatar)`
  width: 26px;
  height: 26px;
  min-width: 26px;
  min-height: 26px;
  font-size: 11px;
  border: solid 1px #fff;
`;

const SudoLabel = styled.span`
  font-size: 17px;
  font-weight: bold;
  margin-left: 10px;
`;

const AssignedToolbar = (props: IProps) => (
  <React.Fragment>
    <ToolbarWrapper>
      <AssignedText>Assigned to</AssignedText>
      <AvatarBorder>
        <StyledSudoAvatar
          avatarColor={props.avatarColor}
          avatarText={props.avatarText}
          avatarImage={props.avatarImage}
        />
      </AvatarBorder>
      <SudoLabel>{props.hasSudo ? props.sudoLabel : 'Unassigned'}</SudoLabel>
    </ToolbarWrapper>
  </React.Fragment>
);

export default AssignedToolbar;
