import * as React from 'react';
import styled from 'styled-components';
import {
  environmentNames,
  writeEnvironmentPreference,
  readEnvironmentPreference,
} from 'src/environments';

const Container = styled.div`
  margin: 10px 0;
  text-align: center;
`;

export class EnvironmentSelector extends React.Component {
  public render() {
    const show = environmentNames.length > 1;
    if (!show) {
      return null;
    }

    return (
      <Container>
        Environment:{' '}
        <select onChange={this.onChange} value={readEnvironmentPreference()}>
          {environmentNames.map(name => (
            <option key={name} value={name}>
              {name}
            </option>
          ))}
        </select>
      </Container>
    );
  }

  private onChange = (ev: React.ChangeEvent<HTMLSelectElement>) => {
    const name = ev.target.value;
    writeEnvironmentPreference(name);
    location.reload();
  };
}
