import React from 'react';
import styled from 'styled-components';

interface IProps {
  children?: React.ReactNode | string;
}

export const StyledSecondaryText = styled.div<IProps>`
  font-size: 15px;
  color: var(--gray-3);
  line-height: 20px;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const ListItemSecondaryText = (props: IProps) =>
  props.children && <StyledSecondaryText {...props} />;

ListItemSecondaryText.displayName = 'ListItemSecondaryText';

export default ListItemSecondaryText;
