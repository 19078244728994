import { ISudoSettings, IContact } from 'src/api';
import { isNumberMatch } from './phone-numbers';

interface IInitialsData {
  firstName?: string;
  lastName?: string;
}

const _getInitials = (data: IInitialsData) => {
  let initials = '';
  if (data.firstName) {
    initials += data.firstName[0].toLocaleUpperCase();
  }
  if (data.lastName) {
    initials += data.lastName[0].toLocaleUpperCase();
  }
  return initials;
};

export const getSelfInitials = (sudo: ISudoSettings) => _getInitials(sudo);

export function isNameMatch(contact: IContact, search: string): boolean {
  const criteria = search
    .trim()
    .toLowerCase()
    .replace(/, /g, ' ');

  const name = [contact.first, contact.last, contact.first]
    .filter((i: any) => i)
    .join(' ')
    .toLowerCase();

  return name.indexOf(criteria) >= 0;
}

export function isEmailMatch(contact: IContact, search: string): boolean {
  const criteria = search.trim().toLocaleLowerCase();

  const emails = contact.emailAddresses
    .map(emailAddress => emailAddress.email)
    .join(',')
    .toLowerCase();

  return emails.indexOf(criteria) >= 0;
}

export function isPhoneMatch(
  contact: IContact,
  search: string,
  region: string,
): boolean {
  for (const phoneNumber of contact.phoneNumbers) {
    if (isNumberMatch(region, phoneNumber.number, search)) {
      return true;
    }
  }
  return false;
}

export function isDeviceContact(contact: IContact): boolean {
  return !contact.parent;
}
