import { compose, withHandlers, withStateHandlers, withProps } from 'recompose';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { uniqWith } from 'lodash';
import { Props } from './index';
import { TEMP_GUID_PREFIX } from '../../../config';
import { uuid } from '../../../utils';
import { IContact, IContactAvatar } from '../../../api';
import { IRootState } from '../../../state';
import { IContactAndAvatar } from '../../../entities/contact/selectors';
import { createGetContactsSelector } from './selectors';
import {
  getAvatarText,
  getContactNameOrData,
} from '../../../pages/Contacts/selectors';
import AvatarEmptyIconBase64 from '../../../icons/avatar-empty-new-base64';
import GlobalSettingsSelectors from '../../../entities/global-settings/selectors';

const ARROW_UP = 38;
const ARROW_DOWN = 40;
const ENTER = 13;
const TAB = 9;
const BACKSPACE = 8;
const COMMA = 188;
const SPACE = 32;

export type InputValidatorType = (
  value: string,
  countryCode?: string,
) => boolean;
export type InputFormatterType = (
  value: string,
  countryCode?: string,
) => string;
export type ContactFormatterType = (
  contactData: IContactAndAvatar,
  countryCode: string,
) => any;

export const search = (input: any[], searchTerm: string) =>
  input.filter(
    (item: any) =>
      item.secondary.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0 ||
      item.name.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0,
  );

export const formatAddedContact = (
  inputValue: string,
  formatInputValue: InputFormatterType,
  countryCode: string,
) => {
  const id = TEMP_GUID_PREFIX + uuid();
  const formattedValue = formatInputValue(countryCode, inputValue) || '';
  return {
    id,
    key: id + formattedValue,
    secondary: formattedValue,
    name: formattedValue,
  };
};

export const displayNameFormatter = (
  countryCode: string,
  contact: IContact,
) => {
  const displayNameParts = getContactNameOrData(countryCode, contact);
  return [displayNameParts.secondary, displayNameParts.primary]
    .filter((part: string) => part)
    .join(' ')
    .trim();
};

export const avatarDataFormatter = (
  avatar: IContactAvatar,
  contact: IContact,
) => {
  let avatarImage = avatar && avatar.avatar;
  const avatarText = avatarImage ? null : getAvatarText(contact);
  if (!avatarImage && !avatarText) {
    avatarImage = AvatarEmptyIconBase64;
  }
  return {
    avatarText,
    avatarImage,
  };
};

const createWithContactAutocomplete = (
  isValidInput: InputValidatorType,
  formatInputValue: InputFormatterType,
  contactFormatter: ContactFormatterType,
) => {
  const getContacts = createGetContactsSelector(contactFormatter);

  const getCountryCode = createSelector(
    GlobalSettingsSelectors.getSettings,
    settings => settings.countryCode,
  );

  const getUniqueContacts = createSelector(
    getContacts,
    (contacts: any) => {
      return uniqWith(
        contacts,
        (leftContact: any, rightContact: any) =>
          leftContact.key === rightContact.key,
      );
    },
  );

  const getAddedContacts = ((_: any, props: Props) =>
    props.addedContacts) as any;

  const getFilteredContacts = createSelector(
    getUniqueContacts,
    getAddedContacts,
    (all: any[], added) => {
      const addedKeys = (added as any[]).map((contact: any) => contact.key);
      return all.filter((contact: any) => addedKeys.indexOf(contact.key) < 0);
    },
  );

  const mapState = (state: IRootState, props: Props) => ({
    contacts: getFilteredContacts(state, props),
    countryCode: getCountryCode(state),
  });

  return compose(
    connect(mapState),
    withStateHandlers(
      {
        isPopoverOpen: false,
        inputValue: '',
        activeItem: 0,
        inputRef: null,
        isInputError: false,
      },
      {
        clearInput: () => () => ({ inputValue: '' }),
        onInputChange: () => e => {
          e.preventDefault();
          return {
            inputValue: e.target.value,
            isPopoverOpen: true,
            isInputError: false,
          };
        },
        onInputKeyDown: (
          { inputValue, activeItem, inputRef }: any,
          {
            contacts,
            onAddContact,
            onRemoveLastContact,
            onError,
            countryCode,
          }: Props,
        ) => event => {
          const { keyCode } = event;
          const { selectionStart, selectionEnd } = event.target;
          const contactsLength = contacts.length;
          if ([ARROW_DOWN, ARROW_UP].indexOf(keyCode) >= 0) {
            event.preventDefault();
            let nextActiveItem =
              ((keyCode === ARROW_UP ? -1 : 1) + activeItem) % contactsLength;
            nextActiveItem =
              nextActiveItem < 0 ? contactsLength - 1 : nextActiveItem;
            return { activeItem: nextActiveItem, isPopoverOpen: true };
          }
          if ([ENTER, TAB, COMMA, SPACE].indexOf(keyCode) >= 0) {
            event.preventDefault();
            let isProceed = false;
            if (
              search(contacts, inputValue).length &&
              [COMMA, SPACE].indexOf(keyCode) < 0
            ) {
              isProceed = true;
              onAddContact(contacts[activeItem]);
            } else if (inputValue.length > 0) {
              if (isValidInput(inputValue, countryCode)) {
                isProceed = true;
                onAddContact(
                  formatAddedContact(inputValue, formatInputValue, countryCode),
                );
              } else {
                event.preventDefault();
                if (onError) {
                  onError(inputValue);
                }
                return { isInputError: true };
              }
            }
            if (isProceed) {
              return { inputValue: '', activeItem: 0 };
            }
          }
          if (
            keyCode === BACKSPACE &&
            selectionStart === selectionEnd &&
            !selectionStart
          ) {
            onRemoveLastContact();
          }
        },
        onTogglePopover: (
          { inputValue }: any,
          { countryCode, onAddContact, onError }: Props,
        ) => isPopoverOpen => {
          let additionalParams = {};
          let isInputError = false;
          if (!isPopoverOpen && inputValue.length > 0) {
            if (isValidInput(inputValue, countryCode)) {
              onAddContact(
                formatAddedContact(inputValue, formatInputValue, countryCode),
              );
              additionalParams = { inputValue: '' };
            } else {
              if (onError) {
                onError(inputValue);
              }
              isInputError = true;
            }
          }
          return {
            ...additionalParams,
            isPopoverOpen,
            activeItem: 0,
            isInputError,
          };
        },
        setActiveItem: () => activeItem => ({ activeItem }),
        setInputRef: (_: any, props: any) => inputRef => {
          if (props.inputRef) {
            props.inputRef(inputRef);
          }
          return { inputRef };
        },
      },
    ),
    withHandlers({
      onAddContact: (props: Props) => () => {
        const { onAddContact, clearInput, activeItem } = props;
        const searchableContacts = search(props.contacts, props.inputValue);
        onAddContact(searchableContacts[activeItem]);
        clearInput();
        requestAnimationFrame(() => {
          props.inputRef.focus();
        });
      },
    }),
    withProps((props: Props) => {
      return {
        contacts: search(props.contacts, props.inputValue),
      };
    }),
  );
};

export default createWithContactAutocomplete;
