import React from 'react';

export default function Attachment(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <g fill="currentColor" fillRule="evenodd" transform="rotate(45 12 12)">
        <path d="M16.167 17.5c0 2.21-1.79 4-4 4s-4-1.79-4-4V5a2.5 2.5 0 0 1 5 0v10.5c0 .55-.45 1-1 1s-1-.45-1-1V6.75a.75.75 0 1 0-1.5 0v8.75a2.5 2.5 0 0 0 5 0V5c0-2.21-1.79-4-4-4s-4 1.79-4 4v12.5c0 3.04 2.46 5.5 5.5 5.5s5.5-2.46 5.5-5.5V6.75a.75.75 0 1 0-1.5 0V17.5z" />
      </g>
    </svg>
  );
}
