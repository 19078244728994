import * as Analytics from 'src/analytics';
import { config } from 'src/config';
import { sendMessageToAllTabs, BrowserMessageId } from 'src/browser-message';
import {
  clearOwnConnectionInfo,
  clearAllConnectionInfo,
  readConnectionInfo,
} from 'src/connection-info';
import { IThunk } from './actions-base';
import { unpair } from 'src/lib/sudo-client/unpair';
import { isWebRtcConnectionUrl } from 'src/lib/sudo-client/binding-info';

export interface IUnpairOpts {
  unpairOtherTabs?: boolean;
}

const defaultOpts: Required<IUnpairOpts> = {
  unpairOtherTabs: false,
};

export const unpairAction = config.newApi ? unpairActionNew : unpairActionOld;

/**
 * Returns user back to pairing screen. Optionally attempts
 * to notifiy session service of logout so that the session
 * can be cleaned up remotely.
 */
export function unpairActionOld(opts?: IUnpairOpts): IThunk {
  const allOpts = { ...defaultOpts, ...opts };

  return async (_dispatch, _getState, { api }) => {
    await Analytics.stopSession();

    if (allOpts.unpairOtherTabs) {
      clearAllConnectionInfo();
      sendMessageToAllTabs(BrowserMessageId.UnpairAll);
    } else {
      clearOwnConnectionInfo();
    }

    try {
      await api.logout();
    } catch (e) {
      // but ignore errors
    }

    location.href = '/';
  };
}

/**
 * Returns user back to pairing screen. Optionally attempts
 * to notifiy session service of logout so that the session
 * can be cleaned up remotely.
 */
export function unpairActionNew(opts?: IUnpairOpts): IThunk {
  const allOpts = { ...defaultOpts, ...opts };

  return async (_dispatch, _getState, { api }) => {
    await api.disconnect();
    await Analytics.stopSession();
    const connectionInfo = readConnectionInfo();

    if (allOpts.unpairOtherTabs) {
      clearAllConnectionInfo();
      sendMessageToAllTabs(BrowserMessageId.UnpairAll);
    } else {
      clearOwnConnectionInfo();
    }

    if (connectionInfo) {
      try {
        await unpair(connectionInfo.bindingInfo, {
          messagePrefix: 'MSW',
        });
      } catch (error) {
        // ignore errors
      }
    }

    const isWebRtc =
      connectionInfo && isWebRtcConnectionUrl(connectionInfo.bindingInfo);
    const redirectUrl = isWebRtc ? '/?webrtc' : '/';
    location.href = redirectUrl;
  };
}
