import React from 'react';

export default function AddContact(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <g fill="currentColor" fillRule="evenodd">
        <path d="M19.39 5.75v1.5a.75.75 0 1 1-1.5 0v-1.5h-1.5a.75.75 0 1 1 0-1.5h1.5v-1.5a.75.75 0 1 1 1.5 0v1.5h1.5a.75.75 0 1 1 0 1.5h-1.5zm-9.953 5.87c-2.687 0-4.865-2.153-4.865-4.81C4.573 4.154 6.751 2 9.438 2c2.688 0 4.866 2.154 4.866 4.81 0 2.657-2.178 4.81-4.866 4.81zm0-1.519c1.839 0 3.33-1.473 3.33-3.29 0-1.819-1.491-3.292-3.33-3.292C7.6 3.519 6.11 4.992 6.11 6.81c0 1.818 1.49 3.291 3.33 3.291zM17.377 22H15.84v-1.266c0-3.35-2.86-6.076-6.402-6.076-3.541 0-6.402 2.727-6.402 6.076V22H1.5v-1.266c0-4.2 3.56-7.595 7.938-7.595 4.379 0 7.939 3.394 7.939 7.595V22z" />
      </g>
    </svg>
  );
}
