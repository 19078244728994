import { ToastNotificationType } from '../state';
import { IAction } from './';

export const RECEIVE_MESSAGE_ACTION = 'RECEIVE_MESSAGE';

export function resetNotification() {
  return {
    type: RECEIVE_MESSAGE_ACTION,
    payload: Object.assign(
      {},
      {
        title: '',
        message: '',
        type: null,
      },
    ),
  };
}

export function displayNotification(
  title: string,
  message: string,
  type: ToastNotificationType = 'info',
): IAction {
  return {
    type: RECEIVE_MESSAGE_ACTION,
    payload: { title, message, type },
  };
}
