import { IEntity } from '../api';
import { TEMP_GUID_PREFIX } from '../config';
import { IAction, IThunk } from './actions-base';
import { createModalAction } from './toggle-modal-action';

export const SAVING_ENTITIES = 'SAVING_ENTITIES_ACTION';
export interface ISavingEntitiesAction extends IAction {
  type: typeof SAVING_ENTITIES;
  payload: IEntity[];
}

export const ENTITIES_SAVED = 'ENTITIES_SAVED_ACTION';

const saveOptions = {
  sendTimeout: 60000,
  showSaveModal: false,
  saveModalText: 'Saving...',
  errorHandler: (error: Error): void => {
    throw error;
  },
};
type SaveOptions = Partial<typeof saveOptions>;

export function saveEntity<T extends IEntity>(
  entity: T,
  options?: SaveOptions,
): IThunk<T> {
  return async (dispatch, getState, app) => {
    const savedEntities = await dispatch(saveEntities([entity], options));
    const [savedEntity] = (savedEntities || []) as T[];
    return savedEntity;
  };
}

export function saveEntities<T extends IEntity[]>(
  entities: T,
  options?: SaveOptions,
): IThunk<T> {
  return async (dispatch, getState, app) => {
    options = { ...saveOptions, ...options };

    const isAdd = entities.some(
      entity => !entity.id || entity.id.startsWith(TEMP_GUID_PREFIX),
    );
    const isUpdate = entities.some(
      entity => !!entity.id && !entity.id.startsWith(TEMP_GUID_PREFIX),
    );
    if (isAdd && isUpdate) {
      throw new Error("Can't add and update entities at the same time");
    }

    dispatch({
      type: SAVING_ENTITIES,
      payload: entities,
    } as ISavingEntitiesAction);

    if (options.showSaveModal) {
      dispatch(
        createModalAction('saving', {
          isOpen: true,
          savingText: options.saveModalText,
        }),
      );
    }

    let savedEntities: T;
    try {
      const results = isUpdate
        ? await app.api.update(entities, null, options.sendTimeout)
        : await app.api.add(entities, null, options.sendTimeout);
      savedEntities = results as T;
    } catch (error) {
      options.errorHandler(error);
    } finally {
      if (options.showSaveModal) {
        dispatch(createModalAction('saving', { isOpen: false }));
      }
      dispatch({ type: ENTITIES_SAVED, payload: entities });
    }

    if (isAdd) {
      return savedEntities;
    } else if (isUpdate) {
      return (savedEntities || entities).map(entity => ({
        type: entity.type,
        id: entity.id,
      })) as T;
    }
  };
}
