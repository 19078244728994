import React from 'react';
import styled from 'styled-components';
import HeaderLogo from '../../components/HeaderLogo';
import Navigation from './Navigation';
import { withRouter, RouteComponentProps } from 'react-router';

interface IProps extends RouteComponentProps<{ sudoSlug: string }> {
  isConnected: boolean;
  onToggleSudoSidebar(value: boolean): void;
}

const HeaderWrapper = styled.header`
  height: calc(var(--theme-spacing) * 8);
  min-height: calc(var(--theme-spacing) * 8);
  background: var(--gray-9);
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
  z-index: 2;
  display: flex;
  flex: none;
  align-items: center;
  padding: 0 calc(var(--theme-spacing) * 2);
`;

export const AppName = styled.span`
  margin-left: 24px;
  color: var(--black);
`;

export function Header(props: IProps) {
  const handleOpenSidebar = () => {
    props.onToggleSudoSidebar(true);
  };

  return (
    <HeaderWrapper>
      <HeaderLogo id="sudo-beta-logo" />
      {props.isConnected ? (
        <Navigation
          sudoSlug={props.match.params.sudoSlug}
          onOpenSudoSidebar={handleOpenSidebar}
        />
      ) : (
        <AppName>MySudo</AppName>
      )}
    </HeaderWrapper>
  );
}

export default withRouter(Header);
