import * as React from 'react';
import styled from 'styled-components';
import { getDataUrlFromFile } from '../../../../../utils';

export interface IProps {
  avatarRef: (instance: any) => void;
}

const AvatarInput = styled.input`
  display: none;
`;

const AvatarSection = (props: any) => (
  <AvatarInput
    type="file"
    accept="image/png, image/jpeg, image/gif"
    ref={props.avatarRef}
    onChange={e => {
      const files = (e.currentTarget as any).files;
      if (!files || !files.length) {
        return;
      }
      const file = files[0];

      getDataUrlFromFile(file).then(props.input.onChange);
    }}
  />
);

export default AvatarSection;
