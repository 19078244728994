import React from 'react';

export default function RemoveItem(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <g fill="currentColor" fillRule="evenodd" transform="translate(1 1)">
        <path d="M11 22C4.925 22 0 17.075 0 11S4.925 0 11 0s11 4.925 11 11-4.925 11-11 11zM6 10.25a.5.5 0 0 0-.5.5v.5a.5.5 0 0 0 .5.5h10a.5.5 0 0 0 .5-.5v-.5a.5.5 0 0 0-.5-.5H6z" />
      </g>
    </svg>
  );
}
