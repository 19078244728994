import { connect } from 'react-redux';

import { IRootState } from '../../../state';
import { getIsMessageListEmptyBySudoId } from '../selectors';
import SudoSettingsSelectors from '../../../entities/sudo-settings/selectors';
import { getConversationList } from '../../../selectors';
import PhoneAccountSelectors from '../../../entities/phone-account/selectors';
import SudoCountsSelectors from 'src/entities/sudo-counts/selectors';

export interface IStateFromProps {
  messagesUnread: number;
  isListEmpty: boolean;
  isListFetching: boolean;
  noPhoneAccounts: boolean;
  sudoLabel: string;
}

export interface IStateProps {
  sudoSlug: string;
}

export const mapState = (
  state: IRootState,
  props: IStateProps,
): IStateFromProps => {
  const sudoSettings = SudoSettingsSelectors.getEntityBySlug(state, {
    slug: props.sudoSlug,
  });
  const sudoGuid = sudoSettings.parent.id;
  const isListEmpty = getIsMessageListEmptyBySudoId(state, { sudoGuid });
  const phoneAccount = PhoneAccountSelectors.getDefaultOrFirstAccountBySudoId(
    state,
    { sudoGuid },
  );
  const listState = getConversationList(state.lists, phoneAccount);
  const counts = SudoCountsSelectors.getEntityById(state, { id: sudoGuid });

  return {
    messagesUnread: (counts && counts.messagesUnread) || 0,
    isListEmpty,
    isListFetching: !!listState.fetchRef,
    noPhoneAccounts: !phoneAccount,
    sudoLabel: sudoSettings.role,
  };
};

export default connect(mapState);
