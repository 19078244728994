import React from 'react';
import styled from 'styled-components';

import Avatar from 'src/components/Avatar';

interface IProps {
  children?: React.ReactNode;
  image?: string | string[];
  isSudo?: boolean;
}

const Wrapper = styled.div`
  margin-right: calc(var(--theme-spacing) * 2);
`;

const ListItemAvatar = (props: IProps) => (
  <Wrapper>
    <Avatar {...props} />
  </Wrapper>
);

ListItemAvatar.displayName = 'ListItemAvatar';

export default ListItemAvatar;
