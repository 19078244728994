import * as React from 'react';
import styled from 'styled-components';

const GraphicSectionWrapper = styled.div`
  width: 100%;
  background-image: var(--theme-background-image-gradient);
  display: flex;
  justify-content: center;
  min-height: 448px;
  position: relative;
  margin: 180px 0 34px;
`;

const LaptopPhoneImage = styled.img`
  width: 100%;
  height: auto;
  max-width: 1094px;
  position: absolute;
  top: -200px;
`;

const FooterNavWrapper = styled.div`
  align-self: flex-end;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-basis: 1094px;
  margin: 0 16px;
`;

const BrandAndCopyright = styled.img`
  width: auto;
  height: 34px;
`;

const ListItemWrapper = styled.div`
  display: flex;
  padding: 8px 0;
  @media only screen and (max-width: 930px) {
    flex-direction: column;
  }
`;

const FooterLinksList = styled.ul`
  display: flex;
  padding: 24px 0;
  flex-wrap: wrap;
  li {
    margin: 0 12px;
    a {
      color: var(--white);
      font-size: 16px;
      font-weight: 500;
      text-decoration: none;
    }
  }
  @media only screen and (max-width: 930px) {
    li {
      margin: 12px 12px;
    }
  }
`;

const FooterNavigation = () => (
  <FooterNavWrapper>
    <BrandAndCopyright
      src="../../../../../static/anonyome-labs@2x.png"
      alt="Anonyome Labs copyright 2019"
    />
    <nav>
      <FooterLinksList>
        <ListItemWrapper>
          <li>
            <a href="https://anonyome.com/privacy-policy/">Privacy Policy</a>
          </li>
          <li>
            <a href="https://anonyome.com/terms-of-service/">
              Terms of Service
            </a>
          </li>
        </ListItemWrapper>
        <ListItemWrapper>
          <li>
            <a href="https://anonyome.com/report-a-vulnerability/">
              Submit a Vulnerability
            </a>
          </li>
          <li>
            <a href="https://anonyome.com/government-requests/">
              Government Request
            </a>
          </li>
        </ListItemWrapper>
      </FooterLinksList>
    </nav>
  </FooterNavWrapper>
);

const GraphicSection = () => (
  <GraphicSectionWrapper>
    <LaptopPhoneImage
      src="/static/mac-book-i-phone.png"
      srcSet="/static/mac-book-i-phone.png,
              /static/mac-book-i-phone@2x.png 2x,
              /static/mac-book-i-phone@3x.png 3x"
    />
    <FooterNavigation />
  </GraphicSectionWrapper>
);

export default GraphicSection;
