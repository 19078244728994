import { ISudo } from '../api';
import { IAction } from './';

export const SELECT_SUDO = 'SELECT_SUDO_ACTION';

export interface ISelectSudoAction extends IAction {
  type: typeof SELECT_SUDO;
  payload: {
    sudoGuid: string;
  };
}

/**
 * Dispatched to select a new active Sudo.
 */
export function selectSudo(sudoGuid: string) {
  return {
    type: SELECT_SUDO,
    payload: { sudoGuid },
  } as ISelectSudoAction;
}
