import { IAction, IThunk } from './actions-base';
export const UPDATE_STAY_LOGGED_IN = 'TOGGLE_STAY_LOGGED_IN_ACTION';

export interface IUpdateStayLoggedInAction extends IAction {
  type: typeof UPDATE_STAY_LOGGED_IN;
  payload: {
    stayLoggedIn: boolean;
  };
}

export const toggleStayLoggedIn = (): IThunk => {
  return async (dispatch, getState) => {
    const state = getState();
    dispatch(setStayLoggedIn(!state.app.stayLoggedIn));
  };
};

export const setStayLoggedIn = (stayLoggedIn: boolean): IThunk => {
  return async dispatch => {
    const stayLoggedInAction: IUpdateStayLoggedInAction = {
      type: UPDATE_STAY_LOGGED_IN,
      payload: { stayLoggedIn },
    };
    dispatch(stayLoggedInAction);
  };
};
