import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';

const baseLoadingColor = '#F2F3F4';

interface IProps {
  checkBoxPosition: string;
  isWithAvatar: boolean;
  isWithCheckBox: boolean;
  isWithSecondaryText: boolean;
}

const ListItemLoadingPrimaryText = styled.div`
  width: 105px;
  height: var(--theme-spacing);
  background: ${baseLoadingColor};
`;

const ListItemLoadingSecondaryText = styled.div`
  width: 195px;
  height: var(--theme-spacing);
  background: ${baseLoadingColor};
  margin-top: var(--theme-spacing);
`;

const StyledListItemLoadingAvatar = styled.div`
  width: calc(var(--theme-spacing) * 5);
  height: calc(var(--theme-spacing) * 5);
  min-width: calc(var(--theme-spacing) * 5);
  min-height: calc(var(--theme-spacing) * 5);
  border-radius: 50%;
  background-color: ${baseLoadingColor};
  margin-right: calc(var(--theme-spacing) * 2);
  &:after {
    content: '';
  }
`;

const StyledListItemLoading = styled.li`
  padding: calc(var(--theme-spacing) * 2);
  &.isWithCheckBox {
    padding-left: calc(var(--theme-spacing) * 4);
  }
  display: flex;
`;

const ListItemLoadingContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ListItemLoading = (props: IProps) => (
  <StyledListItemLoading
    className={cn(
      props.isWithCheckBox &&
        props.checkBoxPosition === 'left' &&
        'isWithCheckBox',
    )}
  >
    {props.isWithAvatar && <StyledListItemLoadingAvatar />}
    <ListItemLoadingContent>
      <ListItemLoadingPrimaryText />
      {props.isWithSecondaryText && <ListItemLoadingSecondaryText />}
    </ListItemLoadingContent>
  </StyledListItemLoading>
);

ListItemLoading.displayName = 'ListItemLoading';

export default ListItemLoading;
