import * as React from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { Button } from 'src/components';

export interface IProps {
  children?: React.ReactNode;
  contentVisible?: boolean;
  icon?: string;
  id?: string;
}

interface IState {
  contentVisible: boolean;
}

const Wrapper = styled.div`
  align-content: flex-end;
  bottom: 0;
  display: flex;
  flex-flow: column nowrap;
  left: 0;
  margin: 16px;
  position: absolute;
`;

const Content = styled.div<{ visible: boolean }>`
  background-color: var(--white);
  border-radius: 4px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.3);
  display: ${props => (props.visible ? 'flex' : 'none')};
  margin: 8px 0;
`;

const StyledButton = styled(Button)`
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
`;

export default class FloatingActionButton extends React.Component<
  IProps,
  IState
> {
  public constructor(props: IProps) {
    super(props);

    this.state = {
      contentVisible: !!props.contentVisible,
    };
  }

  public static defaultProps = {
    icon: 'Help',
    id: 'floating-action-button',
  };

  public componentDidMount() {
    document.addEventListener('mousedown', this.handleMouseDown);
  }

  public componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleMouseDown);
  }

  public componentDidUpdate(prevProps: IProps) {
    if (prevProps.contentVisible !== this.props.contentVisible) {
      this.setState({ contentVisible: this.props.contentVisible });
    }
  }

  public handleMouseDown = (event: MouseEvent) => {
    const wrapper = ReactDOM.findDOMNode(this);

    if (
      !wrapper ||
      !wrapper.contains(event.target as Node) ||
      wrapper === event.target
    ) {
      this.setState({ contentVisible: false });
    }
  };

  public handleClick = () => {
    this.setState({ contentVisible: !this.state.contentVisible });
  };

  public render() {
    const { children, icon, id } = this.props;
    const { contentVisible } = this.state;

    return (
      <Wrapper id={id}>
        <Content visible={contentVisible}>{children}</Content>
        <StyledButton
          iconColor="var(--white)"
          iconName={contentVisible ? 'Close' : icon}
          onClick={this.handleClick}
          primary
          round
        />
      </Wrapper>
    );
  }
}
