import React from 'react';

export default function Feed(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      {...props}
    >
      <g fill="currentColor" fillRule="evenodd" transform="translate(2 1)">
        <path d="M4.5 1.5A2.5 2.5 0 0 0 2 4v14a2.5 2.5 0 0 0 2.5 2.5h11A2.5 2.5 0 0 0 18 18V4a2.5 2.5 0 0 0-2.5-2.5h-11zm0-1.5h11a4 4 0 0 1 4 4v14a4 4 0 0 1-4 4h-11a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4zm-.25 3h4.5a.75.75 0 0 1 0 1.5h-4.5a.75.75 0 0 1 0-1.5zm1.571 3h8.358A2.321 2.321 0 0 1 16.5 8.321v8.358A2.321 2.321 0 0 1 14.179 19H5.82A2.321 2.321 0 0 1 3.5 16.679V8.32A2.321 2.321 0 0 1 5.821 6z" />
      </g>
    </svg>
  );
}
