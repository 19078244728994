import { List } from 'immutable';
import { makeTypedFactory, TypedRecord } from 'typed-immutable-record';

export interface IContactsProps {
  selectedContactKeys: List<string>;
  search: string;
  showDeleteModal: boolean;
}

export interface IContacts extends TypedRecord<IContacts>, IContactsProps {}

export const Contacts = makeTypedFactory<IContactsProps, IContacts>({
  selectedContactKeys: List<string>(),
  search: '',
  showDeleteModal: false,
});
