import React from 'react';
import styled from 'styled-components';
import CloseIcon from '../../../icons/close.svg';

const ErrorSnackbarContainer = styled.div`
  position: fixed;
  z-index: 1000;
  bottom: 87px;
  left: 50%;
  width: 343px;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.24);
  background-color: #e70000;
  border: solid 1px rgba(151, 151, 151, 0.14);
  word-break: break-word;
  font-size: 16px;
  font-weight: normal;
  line-height: 21px;
  text-align: left;
  color: #fff;
  cursor: pointer;
  transform: scale(1);
  will-change: transform;
  transition: transform 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  display: flex;
  align-items: center;
`;

const CloseButton = styled.button`
  display: block;
  flex: 0 0 11px;
  height: 11px;
  line-height: 1;
  margin-left: auto;
  opacity: 0.7;
  width: 11px;

  ${ErrorSnackbarContainer}:hover & {
    opacity: 1;
  }
`;

const WhiteCloseIcon = styled(CloseIcon)`
  display: block;
  path {
    fill: #fff;
  }
`;

interface IProps {
  children: React.ReactNode;
  dismiss: () => void;
}

const ErrorSnackbar = ({ dismiss, children, ...props }: IProps) => (
  <ErrorSnackbarContainer onClick={dismiss} {...props}>
    {children}
    <CloseButton>
      <WhiteCloseIcon />
    </CloseButton>
  </ErrorSnackbarContainer>
);

export default ErrorSnackbar;
