import { EntityChange, IEntity, IQueryParams } from './entities';
import { IRequestMeta, ISearchPagination } from './message-interfaces';
import { ITypedEvents } from './typed-events';

export type ApiStatus =
  | 'binding' // in the process of binding
  | 'binding-error' // binding did not succeed
  | 'closing-session-taken' // closed due to another connection established with same binding token
  | 'closing-unpaired' // connection is being closed due to session unpair
  | 'connected' // connected to server
  | 'connection-error' // connection is in error state and may be reconnected
  | 'connection-unavailable' // cannot reach peer
  | 'disconnected' // not connected yet or has been gracefully disconnected
  | 'pairing' // in the process of pairing
  | 'pairing-error' // pairing did not succeed
  | 'unpairing'; // currently attempting to unpair session and log out remotely

export type ApiErrorCode =
  | 'API_UNEXPECTED' // an unexpected error happened at the api layer
  | 'BAD_REQUEST' // a payload request failed
  | 'BIND_TIMEOUT' // binding notification
  | 'CANNOT_LOGOUT' // api client is not able to perform logout
  | 'BIND_CONNECT_ERROR' // could not connect to binding service
  | 'INVALID_BINDING_TOKEN' // binding token has expired or been revoked
  | 'NO_PAIRING_TOKEN' // could not negotiate a pairing token with host
  | 'NO_SESSION' // no connection
  | 'PAYMENT_REQUIRED' // Api failure when a payment is required
  | 'REQUEST_CANCELLED' // Request was cancelled
  | 'SERVICE_ERROR' // an error occurred at the service layer
  | 'SEND_FAILED'; // Message send failed;

export class ApiError extends Error {
  public name = 'ApiError';

  constructor(
    public message: ApiErrorCode,
    public info?: any,
    public innerError?: any,
  ) {
    super(message);

    // Set the prototype explicitly.
    Object.setPrototypeOf(this, ApiError.prototype);

    if (innerError && innerError.stack) {
      this.stack = innerError.stack + '\n' + this.stack;
    }
  }

  public toString() {
    return this.name + ': ' + this.message;
  }
}

/**
 * Binding information used to connect to main api endpoint.
 * These objects are intended to be serialized and retained for
 * future use (for example in browser localStorage). The reason
 * for having a version field is to support changes to this interface.
 */
export interface IBindingInfo {
  version: '1';
  bindingToken: string;
  connectionUrl: string;
  symmetricKey: string;
  iceConfig: RTCIceServer[];
}

/**
 * Represents a notification subscription
 */
export interface ISubscription {
  requestId: string;
  entityTypes: IQueryParams;
}

/**
 * Events emitted by IApi
 */
export interface EventMap {
  /** Entity data changed */
  entityChanges: { changes: EntityChange[] };

  /** QR code for pairing is ready to display */
  qrCode: { qrCode: string | null };

  /** Refresh token for binding token that will expire soon */
  refreshToken: { newToken: string };

  /** Api status changed */
  status: { status: ApiStatus };

  /** IDs of subscriptions that the host will no longer honor */
  subscriptionsExpired: { resubscribeIds: string[] };
}

/**
 * IApi defines the pairing and connection functionality as well
 * as the application level functionality serviced by the device.
 */
export interface IApi extends ITypedEvents<EventMap> {
  /**
   * Gets current status
   */
  readonly status: ApiStatus;

  /**
   * Initiates the pairing sequence.
   * @return {Promise<string>} - Emits binding token after successful pairing.
   */
  pair(
    pairingHost: string,
    appName: string,
    v3?: boolean,
  ): Promise<IBindingInfo>;

  /**
   * Connects to main endpoint.
   * @return {Promise<void>} - Emits on success or any connection related errors.
   */
  connect(bindingInfo: IBindingInfo): Promise<void>;

  /**
   * Attempts to resume sending requests after connection
   * was detected to be unavailable.
   */
  resumeConnection(): Promise<void>;

  /**
   * Disconnects any active connection.
   * @return {Promise<void>} - Emits on completion.
   */
  disconnect(logout?: boolean): Promise<void>;

  /**
   * Sends logout request to end session and invalidate binding token
   */
  logout(): Promise<void>;

  /**
   * Creates a messageId to use when sending a message
   */
  generateMessageId(): string;

  /**
   * Performs an entity search.
   * @param {IQueryParams} queryParams - Entities to search for
   * @param {ISearchPagination} pagination - Pagination data.
   * @param {IRequestMeta} meta - Additional data for the API.
   * @param {number} since - Milliseconds since epoch.
   * @param {number} until - Milliseconds since epoch.
   * @param {number} responseTimeout - Optional response timeout override in ms.
   * @returns {Observable<IEntity[]>} - Cold. Emits search results as array of entity objects.
   */
  search(
    queryParams: IQueryParams,
    pagination?: ISearchPagination,
    meta?: IRequestMeta,
    since?: number,
    until?: number,
    responseTimeout?: number,
    messageId?: string,
  ): Promise<IEntity[]>;

  /**
   * Creates a new entity notification subscription to certain entity changes.
   * @param {IQueryParams} queryParams - Entities to subscribe to
   * @param {IRequestMeta} meta - Additional data for the API.
   * @param {number} since - Milliseconds since epoch.
   * @returns {Observable<IUnsubscribeData>} - Cold. Emits a subscription info object upon success. This
   *                                           should be retained and used for unsubscribing.
   */
  subscribe(
    queryParams: IQueryParams,
    meta?: IRequestMeta,
    since?: number,
  ): Promise<ISubscription>;

  /**
   * Cancels a previous entity notification subscription.
   * @param {ISubscription} subscription - Info received from prior subscription.
   * @returns {Observable<void>} - Cold. Emits on success.
   */
  unsubscribe(subscription: ISubscription): Promise<void>;

  /**
   * Creates entities.
   * @param {Array<Partial<IEntity>>} entities - Entities to store back on the device.
   * @param {IRequestMeta} meta - Additional data for the API.
   * @param {number} responseTimeout - Optional response timeout override in ms.
   * @returns {Observable<IEntity[]>} - Cold. Emits added entities (device may have altered data, e.g. ID)
   */
  add(
    entities: Array<Partial<IEntity>>,
    meta?: IRequestMeta,
    responseTimeout?: number,
  ): Promise<IEntity[]>;

  /**
   * Updates entities
   * @param {Array<Partial<IEntity>>} entities - Entities to update on the device.
   * @param {IRequestMeta} meta - Additional data for the API.
   * @param {number} responseTimeout - Optional response timeout override in ms.
   * @returns {Observable<IEntity[]>} - Cold. Emits updated entities (device may have altered data)
   */
  update(
    entities: Array<Partial<IEntity>>,
    meta?: IRequestMeta,
    responseTimeout?: number,
  ): Promise<IEntity[]>;

  /**
   * Deletes entities
   * @param {IQueryParams} queryParams - Search for entities to delete on the device.
   * @param {IRequestMeta} meta - Additional data for the API.
   * @returns {Observable<void>} - Cold. Emits if successful.
   */
  delete(queryParams: IQueryParams, meta?: IRequestMeta): Promise<void>;

  /**
   * Cancels a request to prevent the master from sending any (more) of the response.
   *
   * @param requestId Id of request to cancel
   */
  cancel(requestId: string): Promise<void>;
}
