import { IEntityBase } from '../api';
import { IAction } from './actions-base';

export const PROCESS_ENTITIES = 'PROCESS_ENTITIES_ACTION';

export interface IProcessEntitiesAction extends IAction {
  type: typeof PROCESS_ENTITIES;
  payload: {
    entities: IEntityBase[];
    remove: boolean;
  };
}

/**
 * Dispatched for any entities that need to be retained in or removed from the main entity store
 */
export function processEntity(entity: IEntityBase, remove: boolean = false) {
  return processEntities([entity], remove);
}

export function processEntities(
  entities: IEntityBase[],
  remove: boolean = false,
) {
  return {
    type: PROCESS_ENTITIES,
    payload: { entities, remove },
  } as IProcessEntitiesAction;
}
