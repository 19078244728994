import React from 'react';
import styled from 'styled-components';
import AvatarBase from './deps/AvatarBase';
import SudoState from './deps/AvatarSudoState';
import AvatarGroup from './deps/AvatarGroup';

export interface IAvatar {
  image?: string | string[];
  backgroundColor?: string;
  isSudo?: boolean;
  children?: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
  isLocked?: boolean;
}

const AvatarFullSize = styled(AvatarBase)`
  border-radius: 50%;
  width: calc(var(--theme-spacing) * 5);
  height: calc(var(--theme-spacing) * 5);
`;

const AvatarWithSudoStateWrapper = styled.div`
  font-size: 17px;
  color: var(--blue);
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(var(--theme-spacing) * 5);
  height: calc(var(--theme-spacing) * 5);
  position: relative;
`;

const AvatarMain = (props: IAvatar) => {
  const { image, ...rest } = props;
  return props.image && Array.isArray(props.image) && props.image.length > 1 ? (
    <AvatarGroup image={image as string[]} {...rest} />
  ) : (
    <AvatarFullSize {...props} />
  );
};

const AvatarWithSudoState = (props: IAvatar) => (
  <AvatarWithSudoStateWrapper {...props}>
    <AvatarMain {...props} />
    <SudoState />
  </AvatarWithSudoStateWrapper>
);

const Avatar = (props: IAvatar) => {
  return props.isSudo ? (
    <AvatarWithSudoState {...props} />
  ) : (
    <AvatarMain {...props} />
  );
};

Avatar.defaultProps = {
  backgroundColor: 'var(--gray-7)',
  isSudo: false,
};

Avatar.displayName = 'Avatar';

export default Avatar;
