export function uuid() {
  // tslint:disable-next-line:only-arrow-functions
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(
    c: string,
  ) {
    const r = (Math.random() * 16) | 0; // tslint:disable-line:no-bitwise
    const v = c === 'x' ? r : (r & 0x3) | 0x8; // tslint:disable-line:no-bitwise
    return v.toString(16);
  });
}
