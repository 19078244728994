/* tslint:disable max-line-length */
import * as React from 'react';

export default function Menu(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g fill="currentColor" fillRule="evenodd" transform="translate(2 2)">
        <path
          id="a"
          d="M10 20C4.477 20 0 15.523 0 10S4.477 0 10 0s10 4.477 10 10-4.477 10-10 10zm0-1.5a8.5 8.5 0 1 0 0-17 8.5 8.5 0 0 0 0 17zM7 7.878C7.034 6.176 8.224 5 10.322 5c1.955 0 3.247 1.087 3.247 2.666 0 1.025-.499 1.743-1.463 2.31-.909.527-1.162.862-1.162 1.518v.362H9.133l-.014-.376c-.089-1.093.287-1.709 1.23-2.262.882-.527 1.149-.855 1.149-1.49 0-.636-.52-1.087-1.299-1.087-.786 0-1.305.485-1.346 1.237H7zm3.11 7.28c-.765 0-1.244-.444-1.244-1.162 0-.725.479-1.169 1.244-1.169.766 0 1.238.444 1.238 1.17 0 .717-.472 1.161-1.238 1.161z"
        />
      </g>
    </svg>
  );
}
