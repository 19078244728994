import { connect } from 'react-redux';
import { IRootState, MessageComposition } from '../../../state';
import SudoSettingsSelectors from '../../../entities/sudo-settings/selectors';
import { IDispatcher } from '../../../store';
import { IMessageConversation, IMessageConversationMember } from '../../../api';
import { formatNumberForStorage } from '../../../utils';
import { validateParticipants } from '../../../actions';
import PhoneAccountSelectors from '../../../entities/phone-account/selectors';
import {
  getConversationCreationParticipants,
  getConversationCreationRedirectGuid,
  getContacts,
} from '../selectors';
import { actions } from './modules';
import { messageStateSelector } from '../../../selectors';
import * as Actions from '../../../actions';
import { IInputToolbarValue } from '../Conversation/InputToolbar';
import { actions as messagesErrorsActions } from '../MessagesErrors/modules';

export interface IStateFromProps {
  contacts: any[];
  conversationGuid: string;
  countryCode: string;
  defaultPhoneAccountGuid: string;
  isConversationProtocolSms: boolean;
  redirectToConversationGuid: string;
  sudoGuid: string;
  to: any[];
  sendRequested: boolean;
}

export interface IStateProps {
  sudoSlug: string;
}

export interface IDispatchFromProps extends ISendingDispatchFromProps {
  validateParticipants: (phoneAccountGuid: string, participants: any[]) => void;
  showAutocompleteError: (value: string) => void;
}

export interface ISendingDispatchFromProps {
  onChange: (conversationGuid: string, value: IInputToolbarValue) => void;
  onSubmit: () => void;
}

export const mapState = (
  state: IRootState,
  props: IStateProps,
): IStateFromProps => {
  const redirectToConversationGuid = getConversationCreationRedirectGuid(state);
  const sudoGuid = SudoSettingsSelectors.getIdBySlug(state, {
    slug: props.sudoSlug,
  });
  const phoneAccount = PhoneAccountSelectors.getDefaultOrFirstAccountBySudoId(
    state,
    { sudoGuid },
  );
  const contacts = getContacts(state, { sudoGuid });
  const to = getConversationCreationParticipants(state);
  const conversation = messageStateSelector(state)
    .conversation as IMessageConversation;
  const conversationGuid = conversation && conversation.id;
  const isConversationProtocolSms = !conversation || !conversation.encrypted;
  const countryCode = SudoSettingsSelectors.getDeviceRegion(state);
  const sendRequested = state.messages && state.messages.sendRequested;

  return {
    contacts,
    conversationGuid,
    countryCode,
    defaultPhoneAccountGuid: phoneAccount && phoneAccount.id,
    isConversationProtocolSms,
    redirectToConversationGuid,
    sendRequested,
    sudoGuid,
    to,
  };
};

export const transformToComposition = (value: IInputToolbarValue) => {
  return MessageComposition()
    .set('body', value.message)
    .set('message', value.editedMessage)
    .withMutations(mComposition => {
      mComposition.attachmentData = mComposition.attachmentData
        .concat(value.attachments)
        .toIndexedSeq();
    });
};

export const mapDispatchSending = (
  dispatch: IDispatcher,
): ISendingDispatchFromProps => ({
  onChange: (conversationGuid: string, value: IInputToolbarValue) => {
    const composition = transformToComposition(value);
    dispatch(Actions.messageCompositionChanged(conversationGuid, composition));
  },
  onSubmit: () => {
    dispatch(Actions.sendMessageComposition());
  },
});

export const mapDispatch = (dispatch: IDispatcher): IDispatchFromProps => {
  const sendingActions = mapDispatchSending(dispatch);
  return {
    validateParticipants: (
      phoneAccountGuid,
      participants: IMessageConversationMember[],
    ) => {
      dispatch(actions.updateNewConversationParticipants(participants));
      dispatch(validateParticipants(phoneAccountGuid, participants));
    },
    showAutocompleteError: (value: string) => {
      const limitedValue =
        value.length > 16 ? value.substr(0, 16) + '...' : value;
      dispatch(
        messagesErrorsActions.addError({
          type: 'AUTOCOMPLETE',
          message: `Sorry, "${limitedValue}" is not a valid phone number. Please check number and try again.`,
        }),
      );
    },
    ...sendingActions,
  };
};

export default connect(
  mapState,
  mapDispatch,
);
