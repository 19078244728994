import * as React from 'react';
import { config } from 'src/config';
import { environmentNames, getCurrentEnvironmentName } from 'src/environments';
import { readConnectionInfo } from 'src/connection-info';
import { isWebRtcConnectionUrl } from 'src/lib/sudo-client/binding-info';

interface IProps {
  className?: string;
}

export default (props: IProps) => {
  const connectionInfo = readConnectionInfo();

  const isWebRtcConnected =
    connectionInfo && isWebRtcConnectionUrl(connectionInfo.bindingInfo);
  const isWebRtc = isWebRtcConnected || config.webRtc;
  const isNewApi = config.newApi;

  // prettier-ignore
  const apiType =
    isWebRtc ? 'WebRTC' :
    isNewApi ? 'New API' :
    'Legacy API';

  return (
    <span className={props.className}>
      v{config.version} [{apiType}]
      {environmentNames.length > 1 && (
        <>
          <br />
          Environment: {getCurrentEnvironmentName()}
        </>
      )}
      {config.branch && (
        <>
          <br />
          Branch: {config.branch}
        </>
      )}
    </span>
  );
};
