import React from 'react';
import styled from 'styled-components';

import Icon from 'src/components/Icon';

interface IProps {
  iconName?: string;
  iconSet?: IconSetProps;
}

interface IconSetProps {
  fill: string;
  outline: string;
}

const StyledIcon = styled(Icon)`
  margin-right: calc(var(--theme-spacing) * 0.5);
`;

export const IconSetWrapper = styled.div`
  & .iconFill {
    display: none;
  }
`;

const IconSet = (props: { iconSet: IconSetProps }) => (
  <IconSetWrapper>
    <StyledIcon className="iconFill" name={props.iconSet.fill} />
    <StyledIcon className="iconOutline" name={props.iconSet.outline} />
  </IconSetWrapper>
);

IconSet.displayName = 'TabsIconSet';

// prettier-ignore
const TabIcon = (props: IProps) =>
  !props.iconSet && !props.iconName
    ? null
    : !!props.iconSet
    ? <IconSet iconSet={props.iconSet} />
    : <StyledIcon name={props.iconName} />;

TabIcon.displayName = 'TabIcon';

export default TabIcon;
