import { IContact, IContactAvatar } from '../../api';
import createEntitySelectors, {
  EntitiesArraySelector,
  EntitiesMapSelector,
  EntitySelector,
  IEntitiesMap,
  IEntityBySudoIdProps,
} from '../common/createEntitySelectors';
import ENTITY_TYPE from './entityType';
import ContactAvatarSelectors from '../contact-avatar/selectors';
import { createSelector } from 'reselect';

export interface IContactAndAvatar {
  contact: IContact;
  avatar: IContactAvatar;
}

export const getContactAndAvatar = (
  contact: IContact,
  avatars: IEntitiesMap<IContactAvatar>,
): IContactAndAvatar => {
  return {
    contact: contact || null,
    avatar: (contact && avatars && avatars[contact.id]) || null,
  };
};

const createGetEntityWithAvatarById = (
  getEntityByIdSelector: EntitySelector<IContact>,
  getAvatarsSelector: EntitiesMapSelector<IContactAvatar>,
) =>
  createSelector(
    getEntityByIdSelector,
    getAvatarsSelector,
    getContactAndAvatar,
  );

const createGetEntitiesWithAvatarBySudoIdAndShared = (
  getEntitiesArraySelector: EntitiesArraySelector<IContact>,
  getAvatarsSelector: EntitiesMapSelector<IContactAvatar>,
) =>
  createSelector(
    getEntitiesArraySelector,
    getAvatarsSelector,
    (_, { sudoGuid }: IEntityBySudoIdProps) => sudoGuid,
    (contacts, avatars, sudoGuid): IContactAndAvatar[] => {
      return contacts
        .filter(contact => {
          return (
            contact.localIdentifier ||
            (contact.parent &&
              (contact.parent.type === 'User' ||
                (contact.parent.type === 'Sudo' &&
                  contact.parent.id === sudoGuid)))
          );
        })
        .map(contact => getContactAndAvatar(contact, avatars));
    },
  );

export const createContactSelectors = () => {
  const commonSelectors = createEntitySelectors<IContact>(ENTITY_TYPE);
  const { getEntityById, getEntitiesArray } = commonSelectors;
  const { getEntities: getAvatars } = ContactAvatarSelectors;

  return {
    ...commonSelectors,
    getEntityWithAvatarById: createGetEntityWithAvatarById(
      getEntityById,
      getAvatars,
    ),
    getEntitiesWithAvatarBySudoIdAndShared: createGetEntitiesWithAvatarBySudoIdAndShared(
      getEntitiesArray,
      getAvatars,
    ),
  };
};

const ContactSelectors = createContactSelectors();

export default ContactSelectors;
