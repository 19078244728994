import { Button, Popover, List } from 'src/components';
import * as React from 'react';
import styled, { css } from 'styled-components';
import { branch, renderComponent } from 'recompose';
import {
  IEmailsSectionData,
  IPhonesSectionData,
} from '../withContactContentData';
import withPopoverState, {
  IWithPopoverState,
} from '../../../../features/Popover/utils/withPopoverState';
import DetailsRow from '../DetailsRow';

interface IProps {
  canSendEmail: boolean;
  canSendMessage: boolean;
  contactGuid: string;
  phonesSection: IPhonesSectionData[];
  emailsSection: IEmailsSectionData[];
  onCreateConversation: (phone: string) => void;
  onCreateEmail: (email: string) => void;
}

interface IStyledButtonProps {
  isActive: boolean;
}

interface IButtonOrPopover {
  isActive: boolean;
  section: ISectionData[];
  onAction: (rawValue: string) => void;
  iconName: string;
}

interface ISectionData {
  label: string;
  value: React.ReactNode;
  rawValue: string;
}

const ToolbarWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 21px;
`;

const ButtonOrPopoverWrapper = styled.div`
  margin-left: calc(var(--theme-spacing) * 3);
  &:first-child {
    margin-left: initial;
  }
`;

const StyledPopover = styled(Popover)`
  width: 375px;
  z-index: 999999;
  margin-top: 8px;
`;

const StyledButton = styled(Button).attrs<IStyledButtonProps>({})`
  background-color: var(--blue);
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.26);
  ${(props: IStyledButtonProps) =>
    !props.isActive &&
    css`
      background-color: var(--gray-5);
      box-shadow: none;
      cursor: default;
    `};
`;

const DetailsButton = styled(Button)`
  font-size: 17px;
  line-height: 20px;
`;

const StyledDetailsRow = styled(DetailsRow)`
  width: auto;
  padding: 16px;
  margin: 0;
`;

const StyledList = styled(List)`
  max-height: 286px;
  overflow: auto;
`;

const formatPhones = (
  phonesSection: IPhonesSectionData[],
  onCreateConversation: (phone: string) => void,
) => {
  return phonesSection.map(
    (section): any => ({
      label: section.label,
      value: (
        <DetailsButton
          asLink
          onClick={() => onCreateConversation(section.phone)}
        >
          {section.formattedPhone}
        </DetailsButton>
      ),
      rawValue: section.phone,
    }),
  );
};

const formatEmails = (
  emailsSection: IEmailsSectionData[],
  onCreateEmail: (email: string) => void,
) => {
  return emailsSection.map(
    (section): any => ({
      label: section.label,
      value: (
        <DetailsButton asLink onClick={() => onCreateEmail(section.email)}>
          {section.email}
        </DetailsButton>
      ),
      rawValue: section.email,
    }),
  );
};

export const ToolbarPopoverComponent = (
  props: IButtonOrPopover & IWithPopoverState,
) => (
  <ButtonOrPopoverWrapper>
    <StyledPopover
      isOpen={props.isPopoverOpen}
      anchor={
        <StyledButton
          onClick={props.onTogglePopover}
          primary
          round
          iconColor="var(--white)"
          iconName={props.iconName}
          isActive
        />
      }
      onClose={props.onTogglePopover}
      positionHorizontal="center"
      positionVertical="end"
      originHorizontal="center"
      originVertical="start"
    >
      <StyledList>
        {props.section.map((row, i) => (
          <StyledDetailsRow key={i} label={row.label} value={row.value} />
        ))}
      </StyledList>
    </StyledPopover>
  </ButtonOrPopoverWrapper>
);

export const ToolbarPopover = withPopoverState(ToolbarPopoverComponent as any);

export const ToolbarButton = (props: IButtonOrPopover) => (
  <ButtonOrPopoverWrapper>
    <StyledButton
      primary
      round
      iconColor="var(--white)"
      iconName={props.iconName}
      isActive={props.isActive}
      onClick={
        props.isActive ? () => props.onAction(props.section[0].rawValue) : null
      }
    />
  </ButtonOrPopoverWrapper>
);

const ButtonOrPopover = branch(
  (props: IButtonOrPopover) => props.isActive && props.section.length > 1,
  renderComponent(ToolbarPopover),
)(ToolbarButton);

const ActionsToolbar = (props: IProps) => (
  <ToolbarWrapper>
    <ButtonOrPopover
      isActive={props.canSendMessage}
      section={formatPhones(props.phonesSection, props.onCreateConversation)}
      onAction={props.onCreateConversation}
      iconName="MessagesFill"
    />
    <ButtonOrPopover
      isActive={props.canSendEmail}
      section={formatEmails(props.emailsSection, props.onCreateEmail)}
      onAction={props.onCreateEmail}
      iconName="EmailFill"
    />
  </ToolbarWrapper>
);

export default ActionsToolbar;
