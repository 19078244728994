import React from 'react';

export default function Forward(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      {...props}
    >
      <g
        fill="currentColor"
        fillRule="evenodd"
        transform="matrix(-1 0 0 1 22.5 4)"
      >
        <path d="M10.316 5.695h-.745V1.779L1.676 7.494l7.895 5.715V9.185H19.246a.25.25 0 0 0 .25-.25v-2.99a.25.25 0 0 0-.25-.25h-8.93zM20 10.742h-8.938v2.96c0 .264-.078.521-.225.737a1.217 1.217 0 0 1-1.73.316L.53 8.546a1.265 1.265 0 0 1-.305-.316A1.313 1.313 0 0 1 .53 6.44L9.107.233C9.316.082 9.565 0 9.819 0c.687 0 1.243.575 1.243 1.285v2.882H20a1 1 0 0 1 1 1v4.575a1 1 0 0 1-1 1z" />
      </g>
    </svg>
  );
}
