import * as React from 'react';
import ForwardArrowIcon from '../../../../icons/ForwardArrow';
import MenuIcon from '../../../../icons/Menu';
import styled from 'styled-components';

const ManualItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 14px;
  font-size: 18px;
  & svg {
    margin-left: 16px;
    & ~ svg {
      margin-right: 8px;
    }
  }
`;

const BoldNumber = styled.span`
  font-weight: 600;
`;

export default () => (
  <div>
    <ManualItem>
      <BoldNumber>1.</BoldNumber>&nbsp;Open MySudo on your phone
    </ManualItem>
    <ManualItem>
      <BoldNumber>2.</BoldNumber>&nbsp;Go to menu <MenuIcon />
      <ForwardArrowIcon />
      &nbsp;Pair with Browser
    </ManualItem>
    <ManualItem>
      <BoldNumber>3.</BoldNumber>&nbsp;Scan the code with your phone
    </ManualItem>
  </div>
);
