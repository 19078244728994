import { cloneDeep } from 'lodash';

import {
  ApiError,
  IMessageConversation,
  IMessageConversationMember,
  IPhoneAccount,
} from '../api';
import { formatNumberForStorage } from '../utils';
import { saveEntity } from './save-entities-action';
import { actions as messagesErrorsActions } from '../pages/Messages/MessagesErrors/modules';
import { IThunk } from './actions-base';

export function addParticipantToConversation(
  phoneAccount: IPhoneAccount,
  conversation: IMessageConversation,
  phone: string,
): IThunk<void> {
  return async (dispatch, getState, app) => {
    const newMember = {
      alias: formatNumberForStorage(phoneAccount.countryCode, phone),
      aliasType: 'phone',
      active: true,
    } as IMessageConversationMember;

    const duplicateParticipant = conversation.remoteParticipants.find(p => {
      return p.aliasType === 'phone' && p.alias === newMember.alias;
    });

    let remoteParticipants = conversation.remoteParticipants;
    if (duplicateParticipant) {
      // If we want to re-add inactive participant
      if (!duplicateParticipant.active) {
        remoteParticipants = remoteParticipants.filter(
          p => p !== duplicateParticipant,
        );
      } else {
        dispatch(
          messagesErrorsActions.addError({
            type: 'GROUP_CONVERSATION_ADD',
            message: 'The recipient is already a member of the thread.',
          }),
        );
        return;
      }
    }

    const updatedMembers = remoteParticipants.concat(newMember);
    const updatedConversation = Object.assign(cloneDeep(conversation), {
      remoteParticipants: updatedMembers,
    });

    try {
      const [validatedConversation] = (await app.api.update(
        [updatedConversation],
        {
          validation: true,
        },
      )) as IMessageConversation[];
      if (validatedConversation.encrypted) {
        await dispatch(
          saveEntity(updatedConversation, { showSaveModal: true }),
        );
      } else {
        dispatch(
          messagesErrorsActions.addError({
            type: 'GROUP_CONVERSATION_ADD',
            message:
              'The recipient cannot be added to the group because it is not a Sudo user.',
          }),
        );
        return;
      }
    } catch (err) {
      if (err instanceof ApiError) {
        dispatch(
          messagesErrorsActions.addError({
            type: 'GROUP_CONVERSATION_ADD',
            message:
              "Looks like we couldn't add contact to the conversation. Please try again.",
          }),
        );
        return;
      }

      throw err;
    }
  };
}
