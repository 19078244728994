import { omit } from 'lodash/fp';
import Button, { IProps as ButtonProps } from 'src/components/Button';
import * as React from 'react';
import styled from 'styled-components';

import ArrowDown from '../../../icons/arrow-down';

const omitChildren = omit(['children']);

const ButtonContent = styled.div`
  display: flex;
  align-items: center;
`;

const ArrowDownIcon = styled(ArrowDown)`
  color: var(--gray-1);
  margin-left: 3px;
`;

const DropdownButton = (props: ButtonProps) => (
  <Button {...omitChildren(props)}>
    <ButtonContent>
      {props.children}
      <ArrowDownIcon />
    </ButtonContent>
  </Button>
);

export default DropdownButton;
