import * as React from 'react';
import { Button } from 'src/components';
import styled, { css } from 'styled-components';

interface IProps {
  tags: any[];
  color?: string;
}

interface ITagProps {
  name: string;
  color?: string;
}

interface ITagButtonProps {
  tagColor?: string;
}

const StyledButton = styled(Button).attrs<ITagButtonProps>({})`
  font-size: 16px;
  ${(props: ITagButtonProps) =>
    props.tagColor &&
    css`
      span {
        color: ${props.tagColor};
      }
    `};
`;

const TagWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Tag = ({ name, color }: ITagProps) => (
  <TagWrapper>
    <StyledButton asLink tagColor={color}>
      {name}
    </StyledButton>
    ,&nbsp;
  </TagWrapper>
);

export default ({ tags, color }: IProps) => (
  <React.Fragment>
    {tags.map((item: any) => (
      <Tag key={item.key} name={item.name} color={color} />
    ))}
  </React.Fragment>
);
