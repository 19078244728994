import React from 'react';

export default function Minus(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={20}
      height={20}
      viewBox="0 0 20 20"
      {...props}
    >
      <g fill="currentColor" fillRule="evenodd">
        <path d="M 3 9 H 17 V 11 H 3 V 9" />
      </g>
    </svg>
  );
}
