import * as React from 'react';
import styled from 'styled-components';
import FieldInput from '../Base/FieldInput';

const ErrorMessage = styled.div`
  color: #e70000;
  font-size: 12px;
  font-weight: normal;
  position: absolute;
  top: 27px;
  left: 5px;
`;

const FieldInputWithErrorMessageWrapper = styled.div`
  position: relative;
`;

const FieldInputWithErrorMessage = (props: any) => {
  const { field, placeholder } = props;
  const { touched, error } = field.meta;
  const hasError = touched && error;

  return (
    <FieldInputWithErrorMessageWrapper>
      <FieldInput
        {...field.input}
        placeholder={placeholder}
        hasError={hasError}
        autoFocus
      />
      {hasError && <ErrorMessage>{error}</ErrorMessage>}
    </FieldInputWithErrorMessageWrapper>
  );
};

export default FieldInputWithErrorMessage;
