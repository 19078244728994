import {
  SearchPredicate,
  ISimplePredicate,
  SimplePredicateMode,
  IAndPredicate,
} from '../../api/message-interfaces';

export const simple = (
  mode: SimplePredicateMode,
  key: string,
  value: any,
): ISimplePredicate => {
  return { type: 'simple', key, mode, value };
};

export const equals = (key: string, value: any): ISimplePredicate => {
  return simple('equals', key, value);
};

export const contains = (key: string, value: any): ISimplePredicate => {
  return simple('contains', key, value);
};

export const and = (children: SearchPredicate[]): IAndPredicate => {
  return { type: 'and', children };
};
