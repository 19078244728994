import { IThunk } from './actions-base';
import ContactSelectors from 'src/entities/contact/selectors';
import { isEmailMatch, isPhoneMatch, isNameMatch } from 'src/utils/contacts';
import GlobalSettingsSelectors from 'src/entities/global-settings/selectors';

export function contactsSearch(
  _sudoGuid: string,
  searchCriteria: string,
): IThunk<string[]> {
  return async (_dispatch, getState) => {
    const state = getState();
    const { countryCode } = GlobalSettingsSelectors.getSettings(state);
    const contacts = ContactSelectors.getEntitiesArray(state);

    return contacts.reduce(
      (accumulator, contact) => {
        const { notes = '' } = contact;

        if (
          isNameMatch(contact, searchCriteria) ||
          isEmailMatch(contact, searchCriteria) ||
          isPhoneMatch(contact, searchCriteria, countryCode) ||
          notes.toLowerCase().indexOf(searchCriteria.toLowerCase()) >= 0
        ) {
          return [contact.id, ...accumulator];
        }

        return accumulator;
      },
      [] as string[],
    );
  };
}
