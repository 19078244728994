import {
  PhoneNumberFormat as PNF,
  PhoneNumberUtil,
} from 'google-libphonenumber';

const pnUtil = PhoneNumberUtil.getInstance();

export function formatNumberForDisplay(region: string, phone: string): string {
  try {
    const pn = pnUtil.parse(phone, region);
    return pnUtil.formatOutOfCountryCallingNumber(pn);
  } catch (e) {
    return phone;
  }
}

export function formatNumberForStorage(region: string, phone: string): string {
  try {
    const pn = pnUtil.parse(phone, region);
    return pnUtil.format(pn, PNF.E164);
  } catch (e) {
    return phone;
  }
}

export function isValidPhoneNumber(region: string, phone: string): boolean {
  try {
    const pn = pnUtil.parse(phone, region);
    return pnUtil.isPossibleNumber(pn);
  } catch (e) {
    return false;
  }
}

export function isNumberMatch(
  region: string,
  phone: string,
  search: string,
): boolean {
  const criteria = search.trim().toLocaleLowerCase();
  if (!criteria || /[a-z]/g.test(criteria)) {
    return false;
  }

  if (phone.toLowerCase().indexOf(criteria) >= 0) {
    return true;
  }

  const phoneNumber = formatNumberForStorage(region, phone) || '';
  if (phoneNumber.indexOf(criteria) >= 0) {
    return true;
  }

  const criteriaAsPhone = formatNumberForStorage(region, criteria);
  if (phoneNumber.indexOf(criteriaAsPhone) >= 0) {
    return true;
  }

  const extractedPhoneNumber = phoneNumber.replace(/[^\d]/g, '');
  const extractedCriteria = criteriaAsPhone.replace(/[^\d]/g, '');

  if (!extractedPhoneNumber || !extractedCriteria) {
    return false;
  }

  if (extractedPhoneNumber.indexOf(extractedCriteria) >= 0) {
    return true;
  }

  return false;
}
