import Events from 'events';

/** Map of event names to event objects */
interface EventMap {
  [key: string]: any;
}

export interface ITypedEvents<TEventMap extends EventMap> {
  addListener<TEvent extends keyof TEventMap>(
    name: TEvent,
    listener: (event: TEventMap[TEvent]) => void,
  ): void;

  removeListener<TEvent extends keyof TEventMap>(
    name: TEvent,
    listener: (event: TEventMap[TEvent]) => void,
  ): void;

  removeAllListeners(): void;
}

/** Typed wrapper for Events class, suitable from inheriting from */
export class ProtectedTypedEvents<TEventMap extends EventMap>
  implements ITypedEvents<TEventMap> {
  private events = new Events();

  /** protected emit */
  protected emit<TEvent extends keyof TEventMap>(
    name: TEvent,
    event: TEventMap[TEvent],
  ) {
    this.events.emit(name, event);
  }

  public addListener<TEvent extends keyof TEventMap>(
    name: TEvent,
    listener: (event: TEventMap[TEvent]) => void,
  ) {
    this.events.addListener(name, listener);
  }

  public removeListener<TEvent extends keyof TEventMap>(
    name: TEvent,
    listener: (event: TEventMap[TEvent]) => void,
  ) {
    this.events.removeListener(name, listener);
  }

  public removeAllListeners() {
    this.events.removeAllListeners();
  }
}

/** Typed wrapper for Events class, suitable for composition */
export class TypedEvents<
  TEventMap extends EventMap
> extends ProtectedTypedEvents<TEventMap> {
  public emit<TEvent extends keyof TEventMap>(
    name: TEvent,
    event: TEventMap[TEvent],
  ) {
    super.emit(name, event);
  }
}
