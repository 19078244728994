import { ApiStatus } from '../api';
import { IThunk } from './actions-base';
import { unpairAction } from './api-unpair-action';

export const API_STATUS = 'API_STATUS_ACTION';

export function statusAction(status: ApiStatus): IThunk<void> {
  return async dispatch => {
    if (status === 'closing-unpaired') {
      // `closing-unpaired` happens when the API receives a logout message
      // from Session Service.
      return dispatch(unpairAction());
    } else {
      dispatch({
        type: API_STATUS,
        payload: status,
      });
    }
  };
}
