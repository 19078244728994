import { createSelector } from 'reselect';
import { flatten } from 'lodash';
import { ContactFormatterType } from './createWithContactAutocomplete';
import ContactSelectors from '../../../entities/contact/selectors';
import GlobalSettingsSelectors from '../../../entities/global-settings/selectors';

export const createGetContactsSelector = (
  contactFormatter: ContactFormatterType,
) =>
  createSelector(
    ContactSelectors.getEntitiesWithAvatarBySudoIdAndShared,
    GlobalSettingsSelectors.getSettings,
    (contactsAndAvatars, globalSettings) => {
      return flatten(
        contactsAndAvatars.map(contactData =>
          contactFormatter(contactData, globalSettings.countryCode),
        ),
      );
    },
  );
