import styled from 'styled-components';
import { gridContentParentHack } from 'src/utils/grid';

export const LayoutListToolbar = styled.div`
  grid-area: list-toolbar;
  border-right: 1px solid var(--theme-divider-color);
  align-items: center;
  background-color: var(--gray-9);
  align-content: stretch;
  padding: 0 16px;
  flex-flow: column wrap;
`;

export const LayoutListSearch = styled.div`
  background-color: var(--gray-9);
  border-bottom: 1px solid var(--theme-divider-color);
  border-right: solid 1px var(--theme-divider-color);
  display: flex;
  flex-direction: column;
  grid-area: list-search;
  overflow: hidden;
  padding: 8px;
`;

export const LayoutList = styled.div`
  background-color: var(--gray-9);
  border-right: solid 1px var(--theme-divider-color);
  display: flex;
  flex-direction: column;
  max-height: 100%;
  overflow: hidden;
`;

export const LayoutContent = styled.div`
  ${gridContentParentHack};
  grid-area: content;
  display: flex;
  min-width: 0;
  max-height: 100%;
`;

export const Layout = styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
  background: var(--white);
  display: grid;
  grid-template-columns: 375px 1fr;
  grid-template-rows: 56px 48px 1fr;
  grid-template-areas:
    'list-toolbar content'
    'list-search content'
    'list content';
`;
