import { IMessageConversationMember, IPhoneAccount } from '../api';
import { getList, IListsState, IRootState } from '../state';

export function getAliasKey(type: string, alias?: string): string;
export function getAliasKey(member: IMessageConversationMember): string;
export function getAliasKey(
  typeOrMember: string | IMessageConversationMember,
  alias?: string,
) {
  if (typeof typeOrMember === 'string') {
    return `${typeOrMember}:${alias}`;
  } else {
    return typeOrMember
      ? `${typeOrMember.aliasType}:${typeOrMember.alias}`
      : null;
  }
}

export const messageStateSelector = (state: IRootState) => state.messages;
export const listsStateSelector = (state: IRootState) => state.lists;

/**
 * Returns a conversation list from state
 */
export function getConversationList(
  listsState: IListsState,
  phoneAccount: IPhoneAccount,
) {
  const key = phoneAccount
    ? 'conversation-filtered-by-phone:' + phoneAccount.id
    : null;
  return getList(listsState, key);
}

/**
 * Returns a message list from state
 */
export function getMessageList(
  listsState: IListsState,
  conversationGuid: string,
) {
  return getList(listsState, conversationGuid);
}
