import { pick, noop } from 'lodash';
import React from 'react';

import ButtonDanger from './deps/ButtonDanger';
import ButtonLink from './deps/ButtonLink';
import ButtonPrimary from './deps/ButtonPrimary';
import ButtonPrimaryRound from './deps/ButtonPrimaryRound';
import ButtonSecondary from './deps/ButtonSecondary';
import ButtonBase from './deps/ButtonBase';
import ButtonContentWithTextAndIcon from './deps/ButtonContentWithTextAndIcon';
import ButtonIcon from './deps/ButtonIcon';
import ButtonText from './deps/ButtonText';

export interface IProps {
  asLink?: boolean;
  children?: any;
  danger?: boolean;
  disabled?: boolean;
  autoFocus?: boolean;
  iconColor?: string;
  iconName?: string;
  primary?: boolean;
  round?: boolean;
  secondary?: boolean;
  style?: React.CSSProperties;
  className?: string;
  href?: string;
  tabIndex?: number;
  target?: string;
  onClick?: (event: React.SyntheticEvent) => void;
  onKeyDown?: (event: React.KeyboardEvent<any>) => void;
}

export default class Button extends React.Component<IProps> {
  private buttonRef = React.createRef<HTMLButtonElement & HTMLAnchorElement>();

  public static defaultProps: IProps = {
    iconColor: 'var(--gray-1)',
    onClick: noop,
  };

  constructor(props: IProps) {
    super(props);

    this.handleOnKeyDown = this.handleOnKeyDown.bind(this);
    this.handleOnClick = this.handleOnClick.bind(this);
  }

  public componentDidMount() {
    // Use setTimeout to allow other events on the queue to
    // complete before attempting to focus the element.
    setTimeout(() => {
      if (this.props.autoFocus) {
        this.handleFocus();
      }
    });
  }

  private handleFocus() {
    if (this.buttonRef.current) {
      this.buttonRef.current.focus();
    }
  }

  private handleOnKeyDown(event: React.KeyboardEvent) {
    const { onClick, onKeyDown } = this.props;

    if (event.key === 'Enter') {
      if (onClick) {
        event.preventDefault();
        onClick(event);
        return;
      }
    }

    if (onKeyDown) {
      onKeyDown(event);
    }
  }

  private handleOnClick(event: React.SyntheticEvent) {
    const { asLink, onClick } = this.props;

    if (!asLink) {
      event.preventDefault();
    }

    onClick(event);
  }

  public render() {
    const Wrapper = this.selectWrapperComponent();
    const wrapperProps = pick(
      this.props,
      'className',
      'onClick',
      'onKeyDown',
      'autoFocus',
      'tabIndex',
      'href',
      'target',
    );

    const Content = this.selectContentComponent();
    const contentProps = pick(
      this.props,
      'className',
      'style',
      'children',
      'iconColor',
      'iconName',
    );

    return (
      <Wrapper
        {...wrapperProps}
        onKeyDown={this.handleOnKeyDown}
        onClick={this.handleOnClick}
        ref={this.buttonRef}
      >
        <Content {...contentProps} />
      </Wrapper>
    );
  }

  private selectWrapperComponent() {
    const { primary, secondary, danger, asLink, round } = this.props;

    if (primary && round) {
      return ButtonPrimaryRound;
    } else if (primary) {
      return ButtonPrimary;
    } else if (secondary) {
      return ButtonSecondary;
    } else if (danger) {
      return ButtonDanger;
    } else if (asLink) {
      return ButtonLink;
    } else {
      return ButtonBase;
    }
  }

  private selectContentComponent() {
    const { iconName, children } = this.props;

    if (iconName && children) {
      return ButtonContentWithTextAndIcon;
    } else if (children) {
      return ButtonContentWithTextAndIcon;
    } else if (iconName) {
      return ButtonIcon;
    } else {
      return ButtonText;
    }
  }
}
