import { IBindingInfo, ISubscription } from './api';
import { BrowserStorageKey } from './browser-storage';

export type ConnectionInfoStorageType = 'session' | 'local';

export interface IConnectionInfo {
  bindingInfo: IBindingInfo;
  environment: string;
  staySignedIn: boolean;
  subscriptions: ISubscription[];
}

export function writeConnectionInfo(connectionInfo: IConnectionInfo) {
  if (connectionInfo.staySignedIn) {
    localStorage.setItem(
      BrowserStorageKey.CurrentConnection,
      JSON.stringify(connectionInfo),
    );
  } else {
    sessionStorage.setItem(
      BrowserStorageKey.CurrentConnection,
      JSON.stringify(connectionInfo),
    );
  }
}

export function readConnectionInfo(): IConnectionInfo | undefined {
  if (sessionStorage.getItem(BrowserStorageKey.CurrentConnection)) {
    return JSON.parse(
      sessionStorage.getItem(BrowserStorageKey.CurrentConnection),
    );
  }

  if (localStorage.getItem(BrowserStorageKey.CurrentConnection)) {
    return JSON.parse(
      localStorage.getItem(BrowserStorageKey.CurrentConnection),
    );
  }

  return undefined;
}

export function readConnectionInfoOrThrow(): IConnectionInfo {
  const connectionInfo = readConnectionInfo();
  if (!connectionInfo) {
    throw new Error('No connection info');
  }
  return connectionInfo;
}

export function clearOwnConnectionInfo() {
  const connectionInfo = readConnectionInfo();
  if (!connectionInfo) {
    return;
  }

  if (connectionInfo.staySignedIn) {
    localStorage.removeItem(BrowserStorageKey.CurrentConnection);
  } else {
    sessionStorage.removeItem(BrowserStorageKey.CurrentConnection);
  }
}

export function clearAllConnectionInfo() {
  localStorage.removeItem(BrowserStorageKey.CurrentConnection);
  sessionStorage.removeItem(BrowserStorageKey.CurrentConnection);
}
