import { uuid } from './utils';
import { BrowserStorageKey } from './browser-storage';

export const tabId = uuid();

export enum BrowserMessageId {
  /** Tab has activated a session */
  SessionActivated = 'sessionActivated',

  /** Tab is requesting that all tabs unpair immediately */
  UnpairAll = 'unpairAll',
}

export interface IBrowserMessage {
  sender: string;
  id: string;
  type: BrowserMessageId;
  data: any;
}

export function sendMessageToAllTabs(msgId: BrowserMessageId, data?: any) {
  const message: IBrowserMessage = {
    id: uuid(),
    type: msgId,
    sender: tabId,
    data,
  };

  localStorage.setItem(
    BrowserStorageKey.BroadcastMessageKey,
    JSON.stringify(message),
  );
}

export function subscribeToBrowserMessages(
  handler: (msg: IBrowserMessage) => void,
) {
  window.addEventListener('storage', ev => {
    if (ev.key !== BrowserStorageKey.BroadcastMessageKey) {
      return;
    }

    const msg = JSON.parse(ev.newValue);

    const isSelf = msg.sender === tabId;
    if (isSelf) {
      return;
    }

    handler(msg);
  });
}
