import * as React from 'react';
import styled from 'styled-components';
import { branch, renderComponent } from 'recompose';
import { IAttachmentData } from '../withConversationInput';
import { IAttachment } from '../index';
import { Icon, Image } from 'src/components';
import { StyledVideoPlayIcon } from '../../MessagesList/BaseMessageElements';

export interface IProps {
  attachments: IAttachmentData[];
  removeAttachment: (index: number) => void;
}

export interface IVideoThumbnailProps {
  src: string;
  type: string;
  onRemove: () => void;
}

const AttachmentToolbarWrapper = styled.div`
  padding: calc(var(--theme-spacing) * 2) calc(var(--theme-spacing) * 3) 0;
  white-space: nowrap;
  overflow-x: auto;
`;

const AttachmentScroll = styled.div`
  white-space: nowrap;
  overflow-x: auto;
`;

const AttachmentWrapper = styled.div`
  display: inline-block;
  margin-right: 16px;
  :last-child {
    margin-right: 0;
  }
`;

const StyledImage = styled(Image)`
  width: auto;
  height: 300px;
  border-radius: 8px;
  background-color: #d8d8d8;
`;

export const VideoThumbnailWrapper = styled.div`
  position: relative;
  user-select: none;
  display: inline-block;
`;

const Video = styled.video`
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: 300px;
  border-radius: 8px;
  background-color: #d8d8d8;
`;

export const RemoveIcon = styled(Icon)`
  position: absolute;
  cursor: pointer;
  right: calc(var(--theme-spacing) * 2);
  top: calc(var(--theme-spacing) * 2);
`;

const VideoAttachment = (props: IAttachment) => (
  <AttachmentWrapper>
    <VideoThumbnail
      src={props.data}
      type={props.mediaType}
      onRemove={props.onRemove}
    />
  </AttachmentWrapper>
);

const ImageAttachment = (props: IAttachment) => (
  <AttachmentWrapper>
    <StyledImage src={props.data} isRemovable onRemove={props.onRemove} />
  </AttachmentWrapper>
);

const Attachment = branch(
  (props: IAttachment) => props.type === 'VIDEO',
  renderComponent(VideoAttachment),
)(ImageAttachment);

const VideoThumbnail = (props: IVideoThumbnailProps) => (
  <VideoThumbnailWrapper>
    <Video ref={(el: any) => el && (el.currentTime = 0)}>
      <source src={props.src} type={props.type} />
    </Video>
    <RemoveIcon onClick={props.onRemove} name="Remove" color="#969EA6" />
    <StyledVideoPlayIcon />
  </VideoThumbnailWrapper>
);

export const AttachmentToolbar = (props: IProps) => (
  <AttachmentToolbarWrapper>
    <AttachmentScroll>
      {props.attachments.map((attachment, i) => (
        <Attachment
          key={i}
          {...attachment}
          onRemove={() => props.removeAttachment(i)}
        />
      ))}
    </AttachmentScroll>
  </AttachmentToolbarWrapper>
);
