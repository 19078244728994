import * as React from 'react';
import { Icon } from 'src/components';

import { IEmptyStates } from './types';

const EMPTY_STATES: IEmptyStates = {
  inbox: {
    title: 'Your inbox is currently very sad.',
    content: [
      'To send a new email, click "',
      <Icon key={1} name="Compose" />,
      '" above.',
    ],
  },
  sent: {
    title: "You haven't sent any emails.",
    content: [
      'To send a new email, click "',
      <Icon key={1} name="Compose" />,
      '" above.',
    ],
  },
  drafts: {
    title: 'Nothing but crickets in here.',
    content: "Maybe it's time to send someone an email?",
  },
  trash: {
    title: 'Your trash is empty.',
    content: 'You should be proud.',
  },
};

export default EMPTY_STATES;
