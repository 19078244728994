import * as React from 'react';
import styled from 'styled-components';
import { pure } from 'recompose';
import InfoBadge from '../InfoBadge';

export interface IProps {
  label: string;
}

const Bold = styled.span`
  font-weight: bold;
`;

export const TimestampBadge = (props: IProps) => (
  <InfoBadge>
    <Bold>{props.label}</Bold>
  </InfoBadge>
);

export default pure(TimestampBadge);
