import React from 'react';

export default function Drafts(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      {...props}
    >
      <g fill="currentColor" fillRule="evenodd" transform="translate(3 .5)">
        <path d="M16.5 8.25H9.75V2H3.844C2.549 2 1.5 3.013 1.5 4.262v14.476C1.5 19.988 2.55 21 3.844 21h10.312c1.295 0 2.344-1.013 2.344-2.262V8.25zm-.429-1.5L11.25 2.097V6.75h4.821zM0 19.152V3.848C0 1.998 1.567.5 3.5.5h8.207L18 6.52v12.632c0 1.85-1.567 3.348-3.5 3.348h-11c-1.933 0-3.5-1.499-3.5-3.348z" />
      </g>
    </svg>
  );
}
