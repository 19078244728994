import * as React from 'react';
import styled from 'styled-components';
import ButtonLink from 'src/components/Button/deps/ButtonLink';

const Container = styled.div`
  background: #e1f0f8;
  padding: 12px 16px;
  font-size: 12px;
  line-height: 1.5;
  display: flex;
`;

const Message = styled.div`
  flex: 1;
`;

const ToggleLink = styled(ButtonLink)`
  padding-left: 30px;
`;

interface IProps {
  toggleSetting: () => void;
}
export const MixedContentBanner = (props: IProps) => (
  <Container>
    <Message>This email contains content from an unsecured source.</Message>
    <ToggleLink onClick={props.toggleSetting} id="toggle-insecure-content">
      Show Unsecured Content
    </ToggleLink>
  </Container>
);
