import { reduxForm } from 'redux-form';
import { get } from 'lodash';
import { ILabelledValue } from '../withContactEditorData';

export const CONTACT_FORM_NAME = 'edit-contact';

export const PHONE_NUMBER_REGEX = /^[\+\*#0-9\-\(\)\s]*$/;

export const validateForm = (values: any) => {
  const errors = {} as any;

  const firstName = get(values, 'first', '');
  const lastName = get(values, 'last', '');
  if (firstName.trim().length === 0 && lastName.trim().length === 0) {
    errors.last = 'Please enter a first and/or last name';
  }

  (values.phones || []).forEach((phone: ILabelledValue, i: number) => {
    const valid = (phone.value || '').match(PHONE_NUMBER_REGEX);
    if (!valid) {
      errors.phones = errors.phones || [];
      errors.phones[i] = { value: 'Please enter a valid phone number' };
    }
  });

  return errors;
};

export default reduxForm({
  form: CONTACT_FORM_NAME,
  validate: validateForm,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
});
