import * as DOMPurify from 'dompurify';

// No context needed
// tslint:disable-next-line: no-empty-interface
interface IContext {}

export const hook = (opts?: Partial<IContext>) => {
  const context: IContext = {};

  DOMPurify.addHook('uponSanitizeElement', parent => {
    if (!parent || !parent.children) {
      return;
    }
    for (const child of Array.from(parent.children)) {
      if (child.tagName === 'IMG') {
        const replacement = document.createElement('div');
        replacement.textContent = '-IMAGE REMOVED-';

        parent.insertBefore(replacement, child);
        child.remove();
      }
    }

    return parent;
  });

  return context;
};
