import * as Actions from '../actions';
import { EntitiesState, IEntities, PersistedEntityType } from '../state';
import { createReducer } from './create-reducer';

export const entitiesReducer = createReducer(EntitiesState(), {
  [Actions.PROCESS_ENTITIES]: (
    mState: IEntities,
    { payload }: Actions.IProcessEntitiesAction,
  ) => {
    payload.entities.forEach(entity => {
      if (!mState.has(entity.type)) {
        return;
      }

      const entityMap = mState[entity.type as PersistedEntityType];

      if (payload.remove) {
        mState.set(entity.type, entityMap.remove(entity.id));
      } else {
        const frozenEntity = Object.freeze(entity);
        mState.setIn([entity.type, entity.id], frozenEntity);
      }
    });
  },

  [Actions.PROCESS_ENTITIES_UPDATE]: (
    mState: IEntities,
    { payload }: Actions.IProcessEntitiesAction,
  ) => {
    payload.entities.forEach(entity => {
      if (!mState.has(entity.type)) {
        return;
      }

      const oldEntity = mState.getIn([entity.type, entity.id]);

      // TODO: probably don't skip missing Entity that is to be updated, just set other fields to default ones?
      if (oldEntity) {
        const updatedFrozenEntity = Object.freeze({
          ...oldEntity,
          ...entity,
        });
        mState.setIn([entity.type, entity.id], updatedFrozenEntity);
      }
    });
  },
});
