/* tslint:disable max-line-length */
import * as React from 'react';

export default function ForwardArrow(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      {...props}
    >
      <g fill="#303030" fillRule="evenodd" transform="matrix(-1 0 0 1 24 4)">
        <path d="M4.232 7.097l4.344-4.344a1.003 1.003 0 0 0-.012-1.415l.15.15a.999.999 0 0 0-1.42-.007L.774 8l6.52 6.52c.39.39 1.028.383 1.42-.008l-.15.15a1.002 1.002 0 0 0 .012-1.415L4.232 8.903h9.998c.55 0 .995-.456.995-1.009v.212c0-.558-.444-1.01-.995-1.01H4.232z" />
      </g>
    </svg>
  );
}
