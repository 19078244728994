import * as React from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import QRCode from 'qrcode.react';
import styled from 'styled-components';
import { config } from 'src/config';

interface IProps {
  value: string;
  size: number;
}

interface ICopyableFieldProps {
  size: number;
  value: string;
  onClick: () => void;
}

interface ICopyableCardState {
  copied: boolean;
}

export const Wrapper = styled.div`
  position: relative;
  padding: 24px;
  border-radius: 6px;
  background-image: linear-gradient(220deg, #24a6ff, #8321e1);
`;

interface IWhiteBorder {
  height: number;
}

const WhiteBorder = styled.div<IWhiteBorder>`
  border: 10px solid var(--white);
  border-radius: 6px;
  padding: 0;
  margin: 0;
  height: ${props =>
    props.height - 1}px; /* makes up for the funky space the canvas has */
`;

export const Overlay = styled.div`
  text-align: center;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.3);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

export class QRCodeDoubleClick extends React.Component<
  ICopyableFieldProps,
  ICopyableCardState
> {
  public state = { copied: false };

  private handleCopied = () => {
    if (!config.exposePairingCode) {
      return;
    }

    this.props.onClick();
    this.setState({ copied: true });
    setTimeout(() => this.setState({ copied: false }), 400);
  };

  public render() {
    const { copied } = this.state;

    return (
      <Wrapper
        data-qrcode={config.exposePairingCode && this.props.value}
        id="pairing-code"
        onDoubleClick={this.handleCopied}
      >
        <WhiteBorder height={this.props.size}>
          <QRCode value={this.props.value} size={this.props.size} />
        </WhiteBorder>
        {copied && <Overlay />}
      </Wrapper>
    );
  }
}

export default (props: IProps) => (
  <CopyToClipboard text={props.value}>
    <QRCodeDoubleClick
      value={props.value}
      onClick={() => null}
      size={props.size}
    />
  </CopyToClipboard>
);
