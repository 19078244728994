import { connect } from 'react-redux';
import { IDispatcher } from '../../store';
import { actions } from './ConversationCreation/modules';
import { NEW_MESSAGE } from '../../actions';
import { IRootState } from '../../state';
import { RouteComponentProps } from 'react-router-dom';
import PhoneAccountSelectors from '../../entities/phone-account/selectors';
import SudoSettingsSelectors from '../../entities/sudo-settings/selectors';

export interface IStateProps extends RouteComponentProps<any> {}

export interface IStateFromProps {
  messagingEnabled: boolean;
}

export interface IDispatchFromProps {
  clearConversationComposition: () => void;
}

export const mapStateToProps = (
  state: IRootState,
  props: IStateProps,
): IStateFromProps => {
  const sudoGuid = SudoSettingsSelectors.getIdBySlug(state, {
    slug: props.match.params.sudo,
  });
  const phoneAccounts = PhoneAccountSelectors.getEntitiesBySudoId(state, {
    sudoGuid,
  });

  return {
    messagingEnabled: phoneAccounts.length > 0,
  };
};

export const mapDispatchToProps = (
  dispatch: IDispatcher,
): IDispatchFromProps => ({
  clearConversationComposition: () => {
    dispatch({
      type: NEW_MESSAGE,
      payload: {},
    });
    dispatch(actions.createNewConversation());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
);
