import * as React from 'react';
import styled from 'styled-components';
import { Icon } from 'src/components';
import { mapProps } from 'recompose';
import { RouteComponentProps } from 'react-router-dom';

import Empty from '../../../features/Empty';
import ContactsEmptyIcon from '../../../icons/ContactsEmpty';
import withEmptyContentData, { IStateFromProps } from './withEmptyContentData';

export interface IMatch {
  sudo: string;
}

const EMPTY_TEXT_CONTENT = [
  'To add a new contact, click "',
  <Icon key={0} name="Add" />,
  '" above.',
];

const EmptyWrapper = styled.div`
  width: 100%;
`;

export const EmptyContent = (props: IStateFromProps) => (
  <EmptyWrapper>
    {props.isListEmpty ? (
      <Empty
        icon={<ContactsEmptyIcon width={130} height={173} />}
        title="It's not fun to Sudo alone..."
        content={EMPTY_TEXT_CONTENT}
      />
    ) : (
      <Empty
        icon={<ContactsEmptyIcon width={130} height={173} />}
        title="Well aren't you popular"
        content="Select a contact to view their details"
      />
    )}
  </EmptyWrapper>
);

export const propsMapper = (props: RouteComponentProps<IMatch>) => ({
  sudoSlug: props.match.params.sudo,
});
const mapRouterProps = mapProps(propsMapper);

export default mapRouterProps(withEmptyContentData(EmptyContent));
