import styled from 'styled-components';
import ButtonBase from '../ButtonBase';

const ButtonDanger = styled(ButtonBase)`
  color: var(--white);
  background: var(--red);
  border-radius: 16px;
  padding: 8px 24px;
`;

ButtonDanger.displayName = 'ButtonDanger';

export default ButtonDanger;
