import { IEmailMessage } from '../../api';
import createEntitySelectors from '../common/createEntitySelectors';
import ENTITY_TYPE from './entityType';

export const createEmailMessageSelectors = () => {
  const commonSelectors = createEntitySelectors<IEmailMessage>(ENTITY_TYPE);

  return {
    ...commonSelectors,
  };
};

const EmailMessageSelectors = createEmailMessageSelectors();

export default EmailMessageSelectors;
