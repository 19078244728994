import { groupBy, mapValues } from 'lodash';
import { IThunk, notifyEntity } from '../actions';
import { IEntity, IQueryParams } from '../api';
import { createModalAction } from './toggle-modal-action';
import { IOrPredicate, ISimplePredicate } from '../api/message-interfaces';

export const DELETING_ACTION = 'DELETING_ACTION';

const deleteOptions = {
  showDeleteModal: false,
  deleteModalText: 'Deleting...',
};

type DeleteOptions = Partial<typeof deleteOptions>;

const transformToSimplePredicate = (entity: IEntity): ISimplePredicate => {
  return {
    type: 'simple',
    key: 'id',
    mode: 'equals',
    value: entity.id,
  };
};

const transformToCombinedPredicate = (entities: IEntity[]): IOrPredicate => {
  return {
    type: 'or',
    children: entities.map(transformToSimplePredicate),
  };
};

export const transformToDeleteParams = (entities: IEntity[]): IQueryParams => {
  const groups = groupBy(entities, entity => entity.type);
  return mapValues(
    groups,
    (e: IEntity[]): IOrPredicate | ISimplePredicate => {
      return e.length <= 1
        ? transformToSimplePredicate(e[0])
        : transformToCombinedPredicate(e);
    },
  );
};

export function deleteEntities(
  entities: IEntity[],
  options?: DeleteOptions,
): IThunk<void> {
  return async (dispatch, getState, app) => {
    options = { ...deleteOptions, ...options };

    dispatch({
      type: DELETING_ACTION,
    });

    if (options.showDeleteModal) {
      dispatch(
        createModalAction('saving', {
          isOpen: true,
          savingText: options.deleteModalText,
        }),
      );
    }

    try {
      await app.api.delete(transformToDeleteParams(entities));
    } finally {
      if (options.showDeleteModal) {
        dispatch(createModalAction('saving', { isOpen: false }));
      }
    }

    // We should get NOTIFY messages any time we delete anything, but it doesn't
    // hurt to do this manually as well.
    entities.forEach(entity =>
      dispatch(
        notifyEntity({
          change: 'DELETE',
          entity: { type: entity.type, id: entity.id },
        }),
      ),
    );
  };
}

export function deleteEntity(entity: IEntity) {
  return deleteEntities([entity]);
}
