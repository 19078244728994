// ----------------------------------------------------------------------------
// Entities

import { SearchPredicate } from './message-interfaces';

export type EntityType =
  | 'AccountInfo'
  | 'Analytics'
  | 'Contact'
  | 'ContactAvatar'
  | 'Counts'
  | 'DeviceInfo'
  | 'EmailAddress'
  | 'EmailAccount'
  | 'EmailMessage'
  | 'EmailMessageBody'
  | 'GLOBAL_SETTINGS'
  | 'Message'
  | 'MessageMedia'
  | 'MessagingThread'
  | 'Sudo'
  | 'SudoAvatarImage'
  | 'SudoSettings'
  | 'Telephone';

export type IQueryParams = { [k in EntityType]?: SearchPredicate | {} };

export interface IEntityBase {
  id: string;
  type: EntityType;
  path?: string;
}

export interface IEntity extends IEntityBase {
  created: number;
  modified: number;
  version?: number;
}

export type EntityChange =
  | { change: 'INSERT' | 'UPDATE'; entity: IEntity }
  | { change: 'DELETE'; entity: IEntityBase };

export interface IChildEntity {
  parent: {
    id: string;
    type: EntityType | 'User';
    path?: string;
  };
}

// ----------------------------------------------------------------------------
// Global Settings
// TODO: Removeme

export interface IGlobalSettings extends IEntity {
  type: 'GLOBAL_SETTINGS';
  countryCode: string;
  emailStorage?: {
    totalBytes: number;
    usedBytes: number;
  };
}

// ----------------------------------------------------------------------------
// DeviceInfo

export interface IDeviceInfo extends IEntity {
  type: 'DeviceInfo';
  locale: string;
  environment: string;
  appName: string;
  standardToken: string;
  voipToken: string;
  appVersion: string;
  buildType: string;
}

// ----------------------------------------------------------------------------
// Sudo

/**
 * Sudo color property was removed in MySudo. This value
 * is used in place of a per-sudo color prop.
 */
export const DEFAULT_SUDO_COLOR = 'var(--gray-7)';

export interface ISudo extends IEntity {
  type: 'Sudo';
}

export interface ISudoSettings extends IEntity, IChildEntity {
  type: 'SudoSettings';
  role: string;
  firstName: string;
  primary: boolean;
  lastName: string;
}

export interface ISudoAvatar extends IEntity, IChildEntity {
  type: 'SudoAvatarImage';
  avatarImage: string;
}

// ----------------------------------------------------------------------------
// Email Account (Each sudo has exactly one)

export interface IEmailAccount extends IEntity, IChildEntity {
  type: 'EmailAccount';
  address: string;
}

// ----------------------------------------------------------------------------
// Email Address lookup

/**
 * Search these entities to determine whether email addresses are
 * in-network or not.
 */
export interface IEmailAddressLookup extends IEntity, IChildEntity {
  type: 'EmailAddress';
  id: string; // email address
}

// ----------------------------------------------------------------------------
// Phone Account (Each sudo has zero or one)

export interface IPhoneAccount extends IEntity, IChildEntity {
  type: 'Telephone';
  number: string;
  countryCode: string;
}

// ----------------------------------------------------------------------------
// Contact

// TODO: 'isSudoContact' attribute is obsolete and should be removed
export interface IContact extends IEntity, IChildEntity {
  type: 'Contact';
  isSudoContact: boolean;
  first?: string;
  last?: string;
  company?: string;
  streetAddresses?: IContactAddress[];
  birthday?: number;
  phoneNumbers: IPhoneNumber[];
  emailAddresses: IContactEmailAddress[];
  notes?: string;
  localIdentifier?: string;
  avatar?: string;
}

export interface IContactAvatar extends IEntity, IChildEntity {
  type: 'ContactAvatar';
  avatar: string;
  localIdentifier?: string;
}

export interface IContactAddress {
  type: string;
  address: IContactAddressDetails;
}

export interface IContactAddressDetails {
  addressLine1: string;
  addressLine2: string;
  suburb: string;
  state: string;
  postcode: string;
  country: string;
}

export interface IPhoneNumber {
  type: PhoneNumberType;
  number: string;
}

export type PhoneNumberType =
  | 'Sudo'
  | 'Main'
  | 'Home'
  | 'Work'
  | 'Mobile'
  | 'iPhone'
  | 'Other';

export interface IContactEmailAddress {
  type: EmailAddressType;
  email: string;
}

export type EmailAddressType = 'Sudo' | 'Home' | 'Work' | 'Other';

// ----------------------------------------------------------------------------
// Email Message

export interface IEmailAddress {
  address: string; // Actual email address (eg. username@anonyome.com)
  displayName?: string; // Display name (eg. Emailholder Name)
}

export enum EmailMessageFolder {
  Inbox = 0,
  Drafts = 1,
  Sent = 2,
  Spam = 3,
}

export enum EmailEncryptionType {
  Unencrypted = 0,
  Anonyome = 1,
  SMIME = 2,
}

export interface IEmailMessage extends IEntity, IChildEntity {
  bcc: IEmailAddress[];
  cc: IEmailAddress[];
  date: number;
  encryptionType?: EmailEncryptionType;
  folder: EmailMessageFolder;
  forwarded: boolean;
  from: IEmailAddress[];
  replied: boolean;
  seen: boolean;
  subject: string;
  to: IEmailAddress[];
  trash: boolean;
  type: 'EmailMessage';
}

export interface IEmailAttachment {
  mimeType: string;
  data: string;
  filename: string;
  contentId: string;
  inlineAttachment: boolean;
}

export interface IEmailMessageBody extends IEntity, IChildEntity {
  type: 'EmailMessageBody';
  body: string;
  isHtml: boolean;
  attachments: IEmailAttachment[];
}

export interface IEmailMessageAndBody extends IEmailMessage {
  body?: Partial<IEmailMessageBody>;
}

// ----------------------------------------------------------------------------
// Message Conversation

export type MessageConversationType = 'individual' | 'group' | 'unknown';

/**
 * 'encrypted' can be treated as sudo-in / sudo-out indicator
 */
export interface IMessageConversation extends IEntity, IChildEntity {
  type: 'MessagingThread';
  threadType: MessageConversationType;
  subject: IMessageConversationSubject;
  title?: string;
  localParticipant: IMessageConversationMember;
  remoteParticipants: IMessageConversationMember[];
  encrypted?: boolean;
  muted?: boolean;
  hasUnread?: boolean;
  lastReceived?: number;
}

export interface IMessageConversationSubject {
  sender?: string;
  expiring?: boolean;
  body?: string;
  media?: IMessageAttachment[];
}

export interface IMessageConversationMember {
  alias: string;
  aliasType: 'phone' | 'group' | 'user' | 'unknown';
  active?: boolean;
}

export const supportedMultimediaExtensions = [
  'image/png',
  'image/gif',
  'image/jpeg',
  'video/mp4',
  'video/x-m4v',
  'video/quicktime', // Should be read as 'video/mp4': https://www.pivotaltracker.com/story/show/142359785
];

export const supportedInlineExtensions = ['image/png', 'image/jpeg'];

export const supportedMultimediaFilter = supportedMultimediaExtensions.join(
  ',',
);
export const supportedInlineMultimediaFilter = supportedInlineExtensions.join(
  ',',
);

// ----------------------------------------------------------------------------
// Message

/**
 * In individual conversations 'from' === 'sender'
 * In group conversations 'from' represents group thread in INCOMING messages:
 * { from: 'group:F3C461BA-TEST-TEST-TEST-2102D20C4457' }
 *
 * 'sender' represents the real sender in the thread who sent the message
 */
export interface IMessage extends IEntity, IChildEntity {
  type: 'Message';
  messageType: MessageProtocol;
  messageStatus: MessageStatus;
  to: IMessageConversationMember;
  from: IMessageConversationMember;
  sender: IMessageConversationMember;
  direction: MessageDirection;
  body: string;
  media: IMessageAttachment[];
  read: boolean;
  edited: boolean;
  timeout: number;
  expiration?: number;
  localRefId?: string;
}

export interface IMessageAttachment {
  id: string;
  mimeType: string;
  thumbnail?: string;
  data?: string;
}

/**
 * MessageMedia represents a part of Message which contains full MessageAttachment data
 * It has the same id / parent / created / modified fields
 */
export interface IMessageMedia extends IEntity, IChildEntity {
  type: 'MessageMedia';
  media: IMessageAttachmentFull[];
}

export interface IMessageAttachmentFull extends IMessageAttachment {
  data: string;
}

export enum MessageProtocol {
  Basic = 0, // SMS/MMS
  Advanced = 1, // Sudo-In
  Unknown = 2,
}

export enum MessageDirection {
  Outgoing = 0,
  Incoming = 1,
  Unknown = 2,
}

export enum MessageStatus {
  Stored = 0, // It is stored in db
  Queued = 1,
  Sending = 2,
  Sent = 3,
  Delivered = 4,
  Viewed = 5,
  Undelivered = 6,
  Failed = 7,
  Received = 8,
  PaymentRequired = 9,
  Unknown = 10,
}

// ----------------------------------------------------------------------------
// Sudo Count Info

export interface ISudoCounts extends IEntity {
  type: 'Counts';
  emailInboxTotal: number;
  emailInboxUnread: number;
  emailTrashTotal: number;
  emailTrashUnread: number;
  emailSentTotal: number;
  emailDraftsTotal: number;
  messagesTotal: number;
  messagesUnread: number;
}
