import * as React from 'react';
import styled from 'styled-components';
import cn from 'classnames';

export interface IDetailsRowProps {
  label?: any;
  value?: any;
  className?: any;
}

const RowWrapper = styled.div`
  width: 380px;
  margin-bottom: calc(var(--theme-spacing) * 3);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--black);
  font-size: 17px;
  line-height: 20px;
  & > :last-child {
    flex-grow: 1;
  }
`;

const Value = styled.div`
  text-align: right;
  word-break: break-word;
  margin-left: calc(var(--theme-spacing) * 2);
`;

const DetailsRow = (props: IDetailsRowProps) => (
  <RowWrapper className={cn(props.className)}>
    {props.label && <div>{props.label}</div>}
    {props.value && <Value>{props.value}</Value>}
  </RowWrapper>
);

export default DetailsRow;
