import * as React from 'react';
import * as ReactDOM from 'react-dom';
import styled from 'styled-components';
import cn from 'classnames';

export interface IProps {
  isOpen: boolean;
  onClose: () => void;
  children?: any;
  className?: any;
}

interface IOverlayProps {
  isOpen: boolean;
  onClick: () => void;
}

const StyledOverlay = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.8);
  opacity: 0;
  pointer-events: none;
  &.isOpen {
    pointer-events: all;
    opacity: 1;
  }
`;

const ModalWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;
  > * {
    pointer-events: initial;
  }
`;

const Portal = (props: any) =>
  ReactDOM.createPortal(props.children, document.body) as any;

const Overlay = (props: IOverlayProps) => (
  <StyledOverlay
    className={cn(props.isOpen && 'isOpen')}
    onClick={props.onClick}
  />
);

export const AttachmentViewModal = (props: IProps) => (
  <Portal>
    <div>
      <Overlay isOpen={props.isOpen} onClick={props.onClose} />
      {props.isOpen && (
        <ModalWrapper>
          <div className={props.className}>{props.children}</div>
        </ModalWrapper>
      )}
    </div>
  </Portal>
);

export default AttachmentViewModal;
