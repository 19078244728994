import React from 'react';
import { connect } from 'react-redux';
import { appWindowFocusAction } from 'src/actions';

interface IProps {
  onFocusChanged: (focused: boolean) => void;
}

export const WindowFocusDetector = (props: IProps) => {
  React.useEffect(() => {
    function onFocus() {
      props.onFocusChanged(true);
    }
    function onBlur() {
      props.onFocusChanged(false);
    }

    window.addEventListener('focus', onFocus);
    window.addEventListener('blur', onBlur);

    return () => {
      window.removeEventListener('focus', onFocus);
      window.removeEventListener('blur', onBlur);
    };
  });
  return <></>;
};

export default connect(
  null,
  { onFocusChanged: appWindowFocusAction },
)(WindowFocusDetector);
