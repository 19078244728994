const humanizeBytes = (size: number, afterComma = 2): string => {
  const i = size === 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
  return (
    (size / Math.pow(1024, i)).toFixed(afterComma) +
    ' ' +
    ['B', 'KB', 'MB', 'GB', 'TB'][i]
  );
};

export default humanizeBytes;
