import { Button } from 'src/components';
import * as React from 'react';
import styled from 'styled-components';
import SudoAvatar, { ISudoAvatarProps } from '../../Sudo/SudoAvatar';

interface IProps extends ISelectSudoButtonProps {
  onClick: () => void;
}

interface ISelectSudoButtonProps extends ISudoAvatarProps {
  sudoLabel: string;
}

const ButtonWrapper = styled.div`
  height: 100%;
  color: #40a3d7;
  padding-right: calc(var(--theme-spacing) * 3);
`;

const ButtonContent = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
`;

const SudoLabel = styled.div`
  margin-left: calc(var(--theme-spacing));
  font-size: 15px;
  font-weight: 500;
  color: var(--black);
  line-height: 17px;
`;

const NavBarSelectSudoButton = (props: IProps) => (
  <ButtonWrapper>
    <Button onClick={props.onClick}>
      <ButtonContent>
        <SudoAvatar
          avatarColor={props.avatarColor}
          avatarImage={props.avatarImage}
          avatarText={props.avatarText}
          isSudo={true}
        />
        <SudoLabel id="NavBarSelectSudoButton_SudoLabel">
          {props.sudoLabel}
        </SudoLabel>
      </ButtonContent>
    </Button>
  </ButtonWrapper>
);

export default NavBarSelectSudoButton;
