import * as React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: top;
  padding-top: 197px;
  box-sizing: border-box;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: var(--white);
`;

const StyledText = styled.div`
  font-size: 32px;
  color: var(--gray-4);
  line-height: 43px;
  text-align: center;
  display: inline-block;
`;

const StyledTitle = styled(StyledText)`
  line-height: 54px;
  font-size: 40px;
  font-weight: bold;
`;

const IconWrapper = styled.div`
  margin-bottom: 18px;
`;

interface Props {
  icon: React.ReactNode;
  title: React.ReactNode;
  content: React.ReactNode;
}

const Empty = (props: Props) => (
  <Wrapper>
    <IconWrapper>{props.icon}</IconWrapper>
    <StyledTitle>{props.title}</StyledTitle>
    <StyledText>{props.content}</StyledText>
  </Wrapper>
);

export default Empty;
