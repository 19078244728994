import * as React from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { isEmail } from 'validator';
import { get, map, keyBy } from 'lodash';
import memoizeOne from 'memoize-one';
import { Button, InputSingleLine, SideBar } from 'src/components';

import ContactsInput from '../../../components/ContactsInput';
import Confirm from '../../../components/Confirm';

import { IEmailMessageBody, IEmailAttachment, IContact } from '../../../api';

import EmailEditor from '../EmailEditor';
import AttachmentsButton from './AttachmentsButton';
import CotnactListItem from './ContactListItem';
import Portal from 'src/components/utils/Portal';

const StyledSideBar = styled(SideBar)`
  --current-width: 600px;
  width: calc(100% - var(--current-width));
  max-width: calc(100% - var(--current-width));
  right: -100%;
  z-index: 1;
`;

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--white);
`;

const Header = styled.div`
  padding: 12px 16px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: var(--theme-divider);
`;

export const CancelButton = styled(Button)`
  span {
    font-size: 17px;
    color: var(--blue);
  }
  margin-right: auto;
`;

const AttachmentsButtonContainer = styled.div`
  margin-right: calc(var(--theme-spacing) * 2);
`;

export const SendButton = styled(Button)``;

const Field = styled.div`
  display: flex;
  box-sizing: border-box;
  padding: 16px;
  font-size: 16px;
  align-items: center;
  width: 100%;
  min-height: calc(var(--theme-spacing) * 7);
  border-bottom: var(--theme-divider);
`;

const Label = styled.div`
  color: var(--black);
  margin-right: 16px;
`;

const LabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
`;

export const CcBccToggle = styled(Button)`
  font-size: 17px;
  color: var(--gray-3);
`;

const InputWrapper = styled.div`
  width: 100%;
  & > div {
    height: auto;
    padding: 0;
  }
`;

interface IFocusable {
  focus: () => void;
}

export interface IEmailActionProps {
  confirmStage: string;
  emailMessageBody: IEmailMessageBody;
}
export interface Props {
  email: any;
  emailMessageBody: IEmailMessageBody;
  contacts: IContact[];

  isOpen: boolean;
  confirm: string;

  onChange: (update: any) => any;
  onAttachmentsAdd: (files: FileList) => void;
  onAttachmentToggleInline: (attachment: IEmailAttachment) => void;
  onAttachmentRemove: (index: number) => void;
  onConfirmHide: () => any;

  onClose: (props: IEmailActionProps) => any;
  onSend: (props: IEmailActionProps) => void;
  onDraftSave: (props: IEmailActionProps) => any;
}
interface IState {
  isCcBccOpen: boolean;
}

class Composer extends React.Component<Props, IState> {
  public state: IState = { isCcBccOpen: false };
  public componentDidUpdate(prevProps: Props) {
    const { isOpen, email } = this.props;
    if (!prevProps.isOpen && isOpen) {
      // Focus on contacts input for new email
      if (this.contactsInputs.to && !get(email, 'to').length) {
        this.contactsInputs.to.focus();
      } else if (this.emailEditor) {
        this.emailEditor.focus();
      }

      const cc = get(email, 'cc') || [];
      const bcc = get(email, 'bcc') || [];
      if (cc.length || bcc.length) {
        this.setState({ isCcBccOpen: true });
      } else {
        this.setState({ isCcBccOpen: false });
      }
    }
  }

  private emailEditor: EmailEditor = null;
  private getEmailEditorRef = (ref: EmailEditor) => {
    this.emailEditor = ref;
  };

  private contactsInputs: { [key: string]: ContactsInput } = {};

  private ccToggle: HTMLElement = null;
  private attachmentButton: HTMLElement = null;
  private cancelButton: HTMLElement = null;
  private sendButton: HTMLElement = null;

  private handleSend = (
    e: React.SyntheticEvent<HTMLElement>,
    maybeStage?: string,
  ) => {
    const { emailMessageBody, onSend } = this.props;
    const confirmStage = maybeStage || null;
    onSend({
      confirmStage,
      emailMessageBody: {
        ...emailMessageBody,
        ...this.emailEditor.getContent(),
      },
    });
  };
  private handleClose = (
    e: React.SyntheticEvent<HTMLElement>,
    maybeStage?: string,
  ) => {
    const { emailMessageBody, onClose } = this.props;
    onClose({
      confirmStage: maybeStage || null,
      emailMessageBody: {
        ...emailMessageBody,
        ...this.emailEditor.getContent(),
      },
    });
  };
  private handleDraftSave = (
    e: React.SyntheticEvent<HTMLElement>,
    maybeStage?: string,
  ) => {
    const { emailMessageBody, onDraftSave } = this.props;
    const confirmStage = maybeStage || null;
    onDraftSave({
      confirmStage,
      emailMessageBody: {
        ...emailMessageBody,
        ...this.emailEditor.getContent(),
      },
    });
  };

  private confirms = [
    {
      key: 'composer-no-subject',
      title: 'This message has no subject',
      content: 'Do you want to send this message anyway?',
      acceptText: 'Send',
      cancelText: 'Cancel',
      acceptData: 'no-subject-ok',
      onAccept: this.handleSend,
      onCancel: this.props.onConfirmHide,
    },
    {
      key: 'composer-no-body',
      title: 'This message is empty',
      content: 'Send this message without text in body?',
      acceptText: 'Send',
      cancelText: 'Cancel',
      acceptData: 'no-body-ok',
      onAccept: this.handleSend,
      onCancel: this.props.onConfirmHide,
    },
    {
      key: 'composer-draft-save',
      title: 'Save draft?',
      content:
        "This message hasn't been sent and has unsaved changes. You can save a draft and work on it later.",
      acceptText: 'Save',
      cancelText: 'Cancel',
      rejectText: 'Discard',
      rejectData: 'no-draft-save-ok',
      onAccept: this.handleDraftSave,
      onReject: this.handleClose,
      onCancel: this.props.onConfirmHide,
    },
  ];

  private toggleCcBcc = () => {
    this.setState(
      {
        isCcBccOpen: !this.state.isCcBccOpen,
      },
      () => {
        if (this.state.isCcBccOpen) {
          this.contactsInputs.cc.focus();
        }
      },
    );
  };

  private handleAddAttachments = async (files: FileList) => {
    const { onAttachmentsAdd } = this.props;
    onAttachmentsAdd(files);
  };
  private handleEmailAddressesChange = (type: string, newValues: string[]) => {
    const contactsMap = this.getContactsMap(this.props.contacts);

    const emailAddresses = newValues.map((value: string) => {
      const contact = contactsMap[value];
      return {
        displayName: contact ? contact.displayName : value,
        address: value,
      };
    });
    this.props.onChange({ [type]: emailAddresses });
  };

  private getContactsMap = memoizeOne((contacts: any) => {
    return keyBy(contacts, 'address');
  });
  private contactsSearchFn = (value: string, search: string) => {
    const { contacts } = this.props;
    const contactsMap = this.getContactsMap(contacts);
    const contact = contactsMap[value];
    return (
      value.toLowerCase().indexOf(search) >= 0 ||
      [contact.first, contact.last]
        .filter((i: any) => i)
        .join(' ')
        .toLowerCase()
        .indexOf(search) >= 0
    );
  };
  // TODO: replace bind with another prettier mechanism.
  private renderEmailAddressesRow = (
    type: string,
    inputLabel: string,
    onTab?: (e: React.KeyboardEvent<HTMLElement>) => void,
  ) => {
    const { email, contacts } = this.props;
    const values = map(get(email, type, []), 'address');
    const valuesMap = keyBy(get(email, type, []), 'address');
    const contactValues = map(contacts, 'address');
    const contactsMap = this.getContactsMap(contacts);

    return (
      <ContactsInput
        ref={(ref: ContactsInput) => {
          this.contactsInputs[type] = ref;
        }}
        label={`${inputLabel}:`}
        value={values}
        onChange={this.handleEmailAddressesChange.bind(this, type)}
        key={`${get(email, 'id', '')}-${type}`}
        contacts={contactValues}
        tabIndex={0}
        isValid={isEmail}
        searchFn={this.contactsSearchFn}
        renderTag={(value: string, index: number) => {
          const contact = contactsMap[value];
          const { first, last } = contact || { first: null, last: null };
          const { displayName } = valuesMap[value] || { displayName: null };
          return first || last ? (
            <span>
              {first}&nbsp;{last}
            </span>
          ) : displayName ? (
            <span>{displayName}</span>
          ) : (
            <span>{value}</span>
          );
        }}
        renderContact={(value: string, index: number) => (
          <CotnactListItem value={value} contact={contactsMap[value]} />
        )}
      />
    );
  };
  public render() {
    const {
      isOpen,
      email,
      emailMessageBody,
      confirm,
      onAttachmentToggleInline,
      onAttachmentRemove,
    } = this.props;
    const { isCcBccOpen } = this.state;

    const emailMessageBodyId = get(emailMessageBody, 'id', '') as string;

    return (
      <Portal>
        <StyledSideBar isOpen={isOpen} onClose={this.handleClose}>
          <Wrapper>
            <Header>
              <CancelButton
                ref={(ref: React.Component) => {
                  this.cancelButton = ReactDOM.findDOMNode(ref) as HTMLElement;
                }}
                onClick={this.handleClose}
              >
                Cancel
              </CancelButton>
              <AttachmentsButtonContainer>
                <AttachmentsButton
                  ref={(ref: React.Component) => {
                    this.attachmentButton = ReactDOM.findDOMNode(
                      ref,
                    ) as HTMLElement;
                  }}
                  onFilesSelected={this.handleAddAttachments}
                />
              </AttachmentsButtonContainer>
              <Button
                ref={(ref: React.Component) => {
                  this.sendButton = ReactDOM.findDOMNode(ref) as HTMLElement;
                }}
                onClick={this.handleSend}
                iconColor="var(--blue)"
                iconName="SentFill"
              />
            </Header>
            {this.renderEmailAddressesRow('to', 'To')}
            {isCcBccOpen && (
              <React.Fragment>
                {this.renderEmailAddressesRow('cc', 'Cc')}
                {this.renderEmailAddressesRow('bcc', 'Bcc')}
              </React.Fragment>
            )}

            <Field>
              <Label>From:</Label>
              <LabelWrapper>
                <Label id="contacts-field-from">
                  {get(email, 'from.displayName', '')}
                </Label>
                <CcBccToggle
                  onClick={this.toggleCcBcc}
                  ref={(ref: React.Component) => {
                    this.ccToggle = ReactDOM.findDOMNode(ref) as HTMLElement;
                  }}
                >
                  Cc Bcc
                </CcBccToggle>
              </LabelWrapper>
            </Field>

            <Field>
              <Label>Subject:</Label>
              <InputWrapper>
                <InputSingleLine
                  id="contacts-field-subject"
                  value={get(email, 'subject', '')}
                  onChange={(e: any) =>
                    this.props.onChange({ subject: e.target.value })
                  }
                />
              </InputWrapper>
            </Field>

            <EmailEditor
              key={emailMessageBodyId}
              ref={this.getEmailEditorRef}
              value={get(emailMessageBody, 'body', '') as string}
              attachments={
                get(emailMessageBody, 'attachments', '') as IEmailAttachment[]
              }
              isLoading={!emailMessageBodyId}
              onAttachmentToggleInline={onAttachmentToggleInline}
              onAttachmentRemove={onAttachmentRemove}
            />
          </Wrapper>
          {this.confirms.map(
            (c: any) => confirm === c.key && <Confirm key={c.key} {...c} />,
          )}
        </StyledSideBar>
      </Portal>
    );
  }
}

export default Composer;
