import { find } from 'lodash';
import { createSelector } from 'reselect';
import { IEmailMessageBody, IEmailMessage } from '../../api';
import createEntitySelectors, {
  EntitiesArraySelector,
  EntitySelector,
} from '../common/createEntitySelectors';
import ENTITY_TYPE from './entityType';
import EmailMessageSelectors from '../email-message/selectors';

const createGetEntityByEmailId = (
  getEmailByIdSelector: EntitySelector<IEmailMessage>,
  getEntitiesArraySelector: EntitiesArraySelector<IEmailMessageBody>,
) =>
  createSelector(
    getEmailByIdSelector,
    getEntitiesArraySelector,
    (email, bodies) => {
      return find(bodies, { path: email.path });
    },
  );

export const createEmailMessageBodySelectors = () => {
  const commonSelectors = createEntitySelectors<IEmailMessageBody>(ENTITY_TYPE);

  return {
    ...commonSelectors,
    getEntityByEmailId: createGetEntityByEmailId(
      EmailMessageSelectors.getEntityById,
      commonSelectors.getEntitiesArray,
    ),
  };
};

const EmailMessageBodySelectors = createEmailMessageBodySelectors();

export default EmailMessageBodySelectors;
