import React from 'react';
import styled from 'styled-components';

import { Modal, Button } from 'src/components';

const ModalSection = styled.div`
  padding: 24px;
`;

const StyledModal = styled(Modal)`
  box-sizing: border-box;
  padding: 0;
  width: 383px;
`;

const Heading = styled.h3`
  color: var(--black);
  font-size: 17px;
  font-weight: bold;
  margin-bottom: 24px;
  text-align: center;
`;

const Details = styled.p`
  color: var(--gray-3);
  font-size: 17px;
  line-height: 1.24;
  text-align: center;
`;

const StyledScanQRCodeButton = styled(Button)`
  display: inline-block;
  span {
    font-size: 17px;
    color: var(--blue);
  }
`;

const StyledWhyButtonLink = styled(StyledScanQRCodeButton)`
  display: block;
  margin-top: 24px;
  text-align: center;
  text-decoration: none;
  width: 100%;
`;

const StyledRetryButton = styled(Button)`
  background-color: var(--blue);
  border-radius: 34px;
  color: var(--white);
  height: 44px;
  justify-content: center;
  margin: 24px auto;
  width: 247px;
  span {
    font-size: 17px;
  }
`;

const StyledImage = styled.img`
  display: block;
  width: 100%;
  height: auto;
`;

interface IProps {
  isOpen?: boolean;
  zIndex?: number;
  reconnect: () => void;
  onLogout: () => void;
}

const ConnectionUnavailableModal = (props: IProps) => (
  <StyledModal isOpen={props.isOpen} zIndex={props.zIndex}>
    <ModalSection>
      <Heading>Connection Lost</Heading>
      <Details>
        To reconnect, please make sure MySudo is active on your device and click
        Retry below.
      </Details>
      <StyledRetryButton onClick={props.reconnect}>Retry</StyledRetryButton>
      <Details>
        Still having trouble?{' '}
        <StyledScanQRCodeButton onClick={props.onLogout}>
          Scan QR Code
        </StyledScanQRCodeButton>
      </Details>
    </ModalSection>
    <StyledImage
      alt="Keep My Sudo alive image"
      src="/static/image-connection-lost.jpg"
      srcSet="/static/image-connection-lost.jpg,
              /static/image-connection-lost@2x.jpg 2x,
              /static/image-connection-lost@3x.jpg 3x"
    />
    <ModalSection>
      <Details>
        Turn on the "Keep MySudo Active" toggle on your device to prevent it
        from sleeping and loosing the connection.
      </Details>
      <StyledWhyButtonLink
        asLink
        href="https://support.mysudo.com/hc/en-us/articles/360026497753-What-is-Keep-My-Sudo-Active"
        target="_blank"
      >
        Why Is This Happening?
      </StyledWhyButtonLink>
    </ModalSection>
  </StyledModal>
);

ConnectionUnavailableModal.defaultProps = {
  isOpen: false,
  reconnect: () => location.reload(),
};

export default ConnectionUnavailableModal;
