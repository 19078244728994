import { IMessageMedia } from '../../api';
import createEntitySelectors from '../common/createEntitySelectors';
import ENTITY_TYPE from './entityType';

export const createMessageMediaSelectors = () => {
  const commonSelectors = createEntitySelectors<IMessageMedia>(ENTITY_TYPE);
  return { ...commonSelectors };
};

const MessageMediaSelectors = createMessageMediaSelectors();

export default MessageMediaSelectors;
