import { get, uniqBy } from 'lodash';
import { isEmail } from 'validator';
import { fetchAvatarsById } from 'src/actions';
import { config } from 'src/config';
import {
  createModalAction,
  IThunk,
  saveEntities,
  deleteEntities,
  processEntities,
} from 'src/actions';
import {
  IEmailMessage,
  IEmailMessageBody,
  IEmailAttachment,
  EmailMessageFolder,
  ApiError,
} from 'src/api';
import { SearchPredicate } from 'src/api/message-interfaces';
import EmailAccountSelectors from 'src/entities/email-account/selectors';
import SudoSettingsSelectors from 'src/entities/sudo-settings/selectors';
import EmailMessageSelectors from 'src/entities/email-message/selectors';
import EmailMessageBodySelectors from 'src/entities/email-message-body/selectors';
import {
  fileToAttachment,
  getEmptyEmail,
  getReplyEmail,
  getReplyAllEmail,
  getForwardEmail,
  getDraftEmail,
  getEmptyBody,
  getReplyBody,
  getForwardBody,
  humanizeBytes,
  isEmailMessageBodyEmpty,
  getAttachmentSize,
} from './utils';
import { actions as Redirect } from 'src/features/Redirect/modules';
import { State, Action, Reducer, reduceActions } from 'src/reduce-actions';
import * as query from './query';
import * as Predicate from './predicates';
import {
  currentEmailsSelector,
  Email,
  ISelectedEmails,
  currentRawEmailsSelector,
  selectedEmailsSelector,
  currentEmailMessageBodyInfoSelector,
} from './selectors';

export const emailPageSize = 50;

const { redirect } = Redirect;

const QUERY_EMAILS_START = 'Emails/QUERY_EMAILS_START';
const QUERY_EMAILS_FINISH = 'Emails/QUERY_EMAILS_FINISH';
export const FETCH_EMAIL_BODY_START = 'Emails/FETCH_EMAIL_BODY_START';
export const FETCH_EMAIL_BODY_END = 'Emails/FETCH_EMAIL_BODY_END';

const CHANGE_STATE = 'Emails/CHANGE_STATE';

const OPEN_COMPOSER = 'Emails/OPEN_COMPOSER';
const CLOSE_COMPOSER = 'Emails/CLOSE_COMPOSER';
const CHANGE_COMPOSER_EMAIL = 'Emails/CHANGE_COMPOSER_EMAIL';
const ADD_COMPOSER_BODY_ATTACHMENTS = 'Emails/ADD_COMPOSER_BODY_ATTACHMENTS';
const TOGGLE_COMPOSER_BODY_ATTACHMENT_INLINE =
  'Emails/TOGGLE_COMPOSER_BODY_ATTACHMENT_INLINE';
const REMOVE_COMPOSER_BODY_ATTACHMENT =
  'Emails/REMOVE_COMPOSER_BODY_ATTACHMENT';

const SHOW_CONFIRM = 'Emails/SHOW_CONFIRM';
const HIDE_CONFIRMS = 'Emails/HIDE_CONFIRMS';

const SELECT_EMAILS = 'Emails/SELECT_EMAILS';
const SEND_SUCCESS = 'Emails/SEND_SUCCESS';
export const ALLOW_MIXED_CONTENT = 'Emails/ALLOW_MIXED_CONTENT';
export const SHOW_SNACKBAR_ERROR = 'Emails/SHOW_SNACKBAR_ERROR';
const HIDE_SNACKBAR_ERROR = 'Emails/HIDE_SNACKBAR_ERROR';

type IConfirm =
  | 'remove'
  | 'batch-remove'
  | 'composer-no-subject'
  | 'composer-no-body'
  | 'composer-draft-save';
type ISnackbarError = 'total-attachments-size-limit';
export interface IPartialState {
  folderName: 'inbox' | 'sent' | 'drafts' | 'trash';
  composerEmail: IEmailMessage;
  composerBody: any;
  selected: ISelectedEmails;
  isEmailsLoading: boolean;
  confirm: IConfirm;
  snackbarError: ISnackbarError;
  emailBodyDownloadId?: string;
  allowMixedContent: boolean;
}

const initialState: IPartialState = {
  folderName: null,
  composerEmail: null,
  composerBody: null,
  selected: {},
  isEmailsLoading: false,
  confirm: null,
  snackbarError: null,
  allowMixedContent: false,
};

const tempEmailMessageBodyFix = (
  emailMessageBody: IEmailMessageBody,
): IEmailMessageBody => {
  emailMessageBody.parent = undefined;
  emailMessageBody.type = undefined;
  emailMessageBody.id = undefined;
  emailMessageBody.path = undefined;
  emailMessageBody.created = undefined;
  emailMessageBody.modified = undefined;
  return emailMessageBody;
};

const tempEmailMessageFix = (emailMessage: IEmailMessage): IEmailMessage => {
  emailMessage.path = undefined;
  emailMessage.created = undefined;
  emailMessage.modified = undefined;
  return emailMessage;
};

export const folderNameToFolder: Record<string, EmailMessageFolder> = {
  drafts: EmailMessageFolder.Drafts,
  inbox: EmailMessageFolder.Inbox,
  sent: EmailMessageFolder.Sent,
  spam: EmailMessageFolder.Spam,
};

const actions = {
  fetchAvatarsById,
  changeState: (update: any) => {
    return { type: CHANGE_STATE, payload: update };
  },
  mailto: (address: string, sudoGuid: string): IThunk => {
    return async dispatch => {
      dispatch(
        actions.openComposer(sudoGuid, null, 'new', {
          to: [{ address }],
        }),
      );
    };
  },
  queryEmails: (sudoGuid: string, folderName: string): IThunk<void> => {
    return async (dispatch, getState, { api }) => {
      dispatch(actions.changeState({ folderName }));

      const state = getState();
      const emailAccount = EmailAccountSelectors.getEntitiesBySudoId(state, {
        sudoGuid,
      })[0];
      const currentEmails = currentEmailsSelector(state, { sudoGuid });

      dispatch({ type: QUERY_EMAILS_START });
      try {
        const predicates: SearchPredicate[] = [
          Predicate.contains('path', emailAccount.path),
          Predicate.equals('trash', folderName === 'trash'),
        ];
        if (folderName !== 'trash') {
          const folder = folderNameToFolder[folderName];
          if (folder === undefined) {
            throw new Error(`unknown folder '${folderName}'`);
          }
          predicates.push(Predicate.equals('folder', folder));
        }

        const results = await api.search(
          {
            EmailMessage: Predicate.and(predicates),
          },
          {
            pageSize: emailPageSize,
            offset: currentEmails.length,
          },
        );

        dispatch(processEntities(results));
      } finally {
        dispatch({ type: QUERY_EMAILS_FINISH });
      }
    };
  },
  loadEmailMessage: (emailMessageId: string): IThunk<void> => {
    return async (dispatch, getState, { api }) => {
      const state = getState();

      if (!emailMessageId) {
        return;
      }

      let emailMessage = EmailMessageSelectors.getEntityById(state, {
        id: emailMessageId,
      });
      if (!emailMessage) {
        emailMessage = await query.findById<IEmailMessage>(
          api,
          'EmailMessage',
          emailMessageId,
        );
        if (!emailMessage) {
          throw new Error(
            `cannot find email message by given id = ${emailMessageId}`,
          );
        }
        dispatch(processEntities([emailMessage]));
      }
    };
  },
  loadEmailMessageBody: (emailMessageId: string): IThunk<void> => {
    return async (dispatch, getState, { api }) => {
      const state = getState();

      if (!emailMessageId) {
        return;
      }

      dispatch({ type: ALLOW_MIXED_CONTENT, payload: false });
      dispatch(actions.cancelLoadEmailMessageBody('loadEmailMessageBody'));

      let emailMessageBody = EmailMessageBodySelectors.getEntityById(state, {
        id: emailMessageId,
      });

      if (!emailMessageBody) {
        const messageId = api.generateMessageId();
        dispatch({
          type: FETCH_EMAIL_BODY_START,
          payload: { messageId },
        });

        try {
          const results = await api.search(
            {
              EmailMessageBody: {
                type: 'simple',
                key: 'id',
                mode: 'equals',
                value: emailMessageId,
              },
            },
            undefined,
            undefined,
            undefined,
            undefined,
            180000,
            messageId,
          );
          emailMessageBody = results[0] as IEmailMessageBody;
        } catch (e) {
          if (e instanceof ApiError && e.message === 'REQUEST_CANCELLED') {
            return;
          }
          throw e;
        } finally {
          dispatch({
            type: FETCH_EMAIL_BODY_END,
            payload: { messageId },
          });
        }

        dispatch(processEntities([emailMessageBody]));
      }
    };
  },
  cancelLoadEmailMessageBody: (_reason?: string): IThunk<void> => {
    return async (dispatch, getState, { api }) => {
      const state = getState();
      const messageId = state.emails.emailBodyDownloadId;
      if (!messageId) {
        return;
      }

      dispatch({
        type: FETCH_EMAIL_BODY_END,
        payload: { messageId },
      });

      await api.cancel(messageId);
    };
  },
  getEmail: (emailMessageId: string, backToFolderUrl: string): IThunk<void> => {
    return async (dispatch, getState) => {
      try {
        await dispatch(actions.loadEmailMessage(emailMessageId));
      } catch (e) {
        dispatch(redirect(backToFolderUrl));
        return;
      }
      const state = getState();
      const emailMessage = EmailMessageSelectors.getEntityById(state, {
        id: emailMessageId,
      });
      if (!emailMessage.seen) {
        await dispatch(saveEntities([{ ...emailMessage, seen: true }]));
      }
      dispatch(actions.loadEmailMessageBody(emailMessageId));
    };
  },
  sendComposerEmail: ({
    confirmStage,
    emailMessageBody,
  }: any): IThunk<void> => {
    return async (dispatch, getState) => {
      const state = getState();
      const { composerEmail } = state.emails;

      if (
        !composerEmail.to.length &&
        !composerEmail.cc.length &&
        !composerEmail.bcc.length
      ) {
        dispatch(
          actions.showSnackbarError(
            'invalid-contact',
            'No valid email addresses were provided.',
          ),
        );
        return;
      }

      const allContacts = [
        ...composerEmail.to,
        ...composerEmail.cc,
        ...composerEmail.bcc,
      ];
      const invalidContacts: any[] = [];

      allContacts.forEach((contact: any) => {
        if (!isEmail(contact.address)) {
          invalidContacts.push(contact);
        }
      });

      if (invalidContacts.length) {
        const addressString = invalidContacts
          .map(contact => `'${contact.address}'`)
          .join(', ');

        let errorMessage;
        if (invalidContacts.length === 1) {
          errorMessage = `The email address ${addressString} is invalid.`;
        } else {
          errorMessage = `The email addresses ${addressString} are invalid.`;
        }

        dispatch(actions.showSnackbarError('invalid-contact', errorMessage));
        return;
      }

      dispatch(actions.hideConfirms());

      const hasNoBody = isEmailMessageBodyEmpty(emailMessageBody);
      const hasNoSubject = !composerEmail.subject;

      if (hasNoSubject && confirmStage === null) {
        dispatch(actions.showConfirm('composer-no-subject'));
        return;
      }
      if (
        hasNoBody &&
        (confirmStage === 'no-subject-ok' || confirmStage === null)
      ) {
        dispatch(actions.showConfirm('composer-no-body'));
        return;
      }

      const emailMessage = composerEmail;
      const prevFolder = emailMessage.folder;
      emailMessage.folder = EmailMessageFolder.Sent;
      emailMessage.trash = false;
      emailMessage.date = Date.now();
      tempEmailMessageFix(emailMessage);

      tempEmailMessageBodyFix(emailMessageBody);

      const sendEntity: any = {
        ...emailMessage,
        id:
          prevFolder === EmailMessageFolder.Drafts
            ? undefined
            : emailMessage.id,
        body: emailMessageBody,
      };

      await dispatch(
        saveEntities([sendEntity], {
          showSaveModal: true,
          saveModalText: 'Sending...',
          sendTimeout: 180000,
        }),
      );
      if (prevFolder === EmailMessageFolder.Drafts) {
        await dispatch(deleteEntities([emailMessage]));
      }

      if (emailMessage.id) {
        dispatch({ type: SEND_SUCCESS, payload: composerEmail });
      }

      dispatch(actions.closeComposer('send-success'));
    };
  },
  saveComposerEmailDraft: ({
    emailMessageBody,
    backToFolderUrl,
  }: any): IThunk<void> => {
    return async (dispatch, getState, app) => {
      const state = getState();
      const { composerEmail } = state.emails;

      const emailMessage = composerEmail;
      emailMessage.folder = EmailMessageFolder.Drafts;
      emailMessage.trash = false;
      emailMessage.date = Date.now();
      tempEmailMessageFix(emailMessage);

      tempEmailMessageBodyFix(emailMessageBody);

      const sendEntity: any = {
        ...emailMessage,
        id: undefined,
        body: emailMessageBody,
      };

      await dispatch(
        saveEntities([sendEntity], {
          showSaveModal: true,
          saveModalText: 'Saving...',
          sendTimeout: 180000,
        }),
      );
      if (emailMessage.id) {
        await dispatch(deleteEntities([emailMessage]));
      }

      dispatch(actions.closeComposer('save-successful'));
      dispatch(redirect(backToFolderUrl));
    };
  },
  remove: (
    emailMessageId: string,
    folderName: string,
    stage: any,
    backToFolderUrl: string,
  ): IThunk<void> => {
    return async (dispatch, getState, app) => {
      const state = getState();
      const isTrashOrDrafts = folderName === 'trash' || folderName === 'drafts';

      if (stage === null && isTrashOrDrafts) {
        dispatch(actions.showConfirm('remove'));
        return;
      }
      dispatch(actions.hideConfirms());

      const emailMessage: IEmailMessage = {
        ...EmailMessageSelectors.getEntityById(state, { id: emailMessageId }),
      };
      dispatch(
        createModalAction('saving', {
          isOpen: true,
          savingText: 'Deleting...',
        }),
      );
      if (isTrashOrDrafts) {
        await dispatch(deleteEntities([emailMessage]));
      } else {
        emailMessage.trash = true;
        await dispatch(saveEntities([emailMessage]));
      }
      dispatch({
        type: SELECT_EMAILS,
        payload: { emails: [emailMessage], value: false },
      });
      dispatch(createModalAction('saving', { isOpen: false }));
      // TODO: add nextGuid calculation here
      dispatch(redirect(backToFolderUrl));
    };
  },
  openComposer: (
    sudoGuid: string,
    emailMessageId: string,
    composerType: string,
    extra?: any,
  ): IThunk<void> => {
    return async (dispatch, getState, { api }) => {
      const state = getState();

      const sudoSettings = SudoSettingsSelectors.getEntitiesBySudoId(state, {
        sudoGuid,
      })[0];
      const emailAccount = EmailAccountSelectors.getEntitiesBySudoId(state, {
        sudoGuid,
      })[0];

      if (!emailMessageId) {
        const newBody: IEmailMessageBody = getEmptyBody();
        dispatch({
          type: OPEN_COMPOSER,
          payload: {
            email: {
              ...getEmptyEmail(sudoSettings, emailAccount),
              ...extra,
            },
            body: newBody,
          },
        });
        return;
      }

      await dispatch(actions.loadEmailMessage(emailMessageId));
      // await dispatch(actions.loadEmailMessageBody(emailMessageId));

      const emailMessage = EmailMessageSelectors.getEntityById(state, {
        id: emailMessageId,
      });

      const {
        currentEmailMessageBody: emailMessageBody,
      } = currentEmailMessageBodyInfoSelector(state, {
        emailMessageId,
        scrubInlineImages:
          composerType === 'reply' || composerType === 'reply-all',
      });

      let composerEmail: IEmailMessage;
      switch (composerType) {
        case 'draft':
          composerEmail = getDraftEmail(emailMessage);
          break;
        case 'reply':
          composerEmail = getReplyEmail(
            emailMessage,
            sudoSettings,
            emailAccount,
          );
          break;
        case 'reply-all':
          composerEmail = getReplyAllEmail(
            emailMessage,
            sudoSettings,
            emailAccount,
          );
          break;
        case 'forward':
          composerEmail = getForwardEmail(
            emailMessage,
            sudoSettings,
            emailAccount,
          );
          break;
        default:
          break;
      }

      let composerBody: any;
      switch (composerType) {
        case 'draft':
          composerBody = { ...emailMessageBody };
          break;
        case 'reply':
          composerBody = getReplyBody(emailMessage, emailMessageBody);
          break;
        case 'reply-all':
          composerBody = getReplyBody(emailMessage, emailMessageBody);
          break;
        case 'forward':
          composerBody = getForwardBody(emailMessage, emailMessageBody);
          break;
        default:
          break;
      }
      dispatch({
        type: OPEN_COMPOSER,
        payload: { email: composerEmail, body: composerBody },
      });
    };
  },
  closeComposer: ({ confirmStage, emailMessageBody }: any): IThunk<void> => {
    return async (dispatch, getState, app) => {
      const state = getState();
      const { composerEmail } = state.emails;
      if (
        confirmStage === null &&
        ((composerEmail.to && composerEmail.to.length) ||
          (composerEmail.cc && composerEmail.cc.length) ||
          (composerEmail.bcc && composerEmail.bcc.length) ||
          composerEmail.subject ||
          !isEmailMessageBodyEmpty(emailMessageBody))
      ) {
        dispatch(actions.showConfirm('composer-draft-save'));
        return;
      }
      dispatch(actions.hideConfirms());
      dispatch({ type: CLOSE_COMPOSER });
    };
  },
  changeComposerEmail: (update: any): Action => {
    return { type: CHANGE_COMPOSER_EMAIL, payload: update };
  },
  addComposerBodyAttachments: (files: FileList): IThunk<void> => {
    return async (dispatch, getState) => {
      const state = getState();
      const filesArray = Array.from(files);
      const filesSize = filesArray.reduce((size: number, file: File) => {
        size += file.size || 0;
        return size;
      }, 0);
      const existingAttachments: IEmailAttachment[] =
        get(state, 'emails.composerBody.attachments', []) || [];
      const attachmentsSize = existingAttachments.reduce(
        (size, attachment: IEmailAttachment) => {
          size += getAttachmentSize(attachment);
          return size;
        },
        0,
      );
      if (
        attachmentsSize + filesSize >=
        config.emailAttachmentsTotalSizeLimit
      ) {
        const totalAttachmentsSizeLimitError = `
          Attachments total size should not exceed the
          ${humanizeBytes(config.emailAttachmentsTotalSizeLimit, 0)} limit.
        `;
        dispatch(
          actions.showSnackbarError(
            'total-attachments-size-limit',
            totalAttachmentsSizeLimitError,
          ),
        );
        return;
      }
      const attachments: IEmailAttachment[] = await Promise.all(
        filesArray.map(fileToAttachment),
      );
      dispatch({ type: ADD_COMPOSER_BODY_ATTACHMENTS, payload: attachments });
    };
  },
  toggleComposerBodyAttachmentInline: (
    attachment: IEmailAttachment,
  ): Action => {
    return {
      type: TOGGLE_COMPOSER_BODY_ATTACHMENT_INLINE,
      payload: attachment,
    };
  },
  removeComposerBodyAttachment: (index: number): Action => {
    return { type: REMOVE_COMPOSER_BODY_ATTACHMENT, payload: index };
  },
  showConfirm: (confirm: IConfirm): Action => {
    return { type: SHOW_CONFIRM, payload: confirm };
  },
  hideConfirms: (): Action => {
    return { type: HIDE_CONFIRMS };
  },
  selectEmails: (
    emails: Email[],
    value: boolean,
    currentEmailGuid: string,
    backToFolderUrl: string,
  ): IThunk<void> => {
    return async dispatch => {
      if (currentEmailGuid) {
        dispatch(redirect(backToFolderUrl));
      }
      dispatch({ type: SELECT_EMAILS, payload: { emails, value } });
    };
  },
  markReadStatus: (sudoGuid: string, isSeen: boolean): IThunk => {
    return async (dispatch, getState) => {
      const state = getState();
      const currentEmails = currentRawEmailsSelector(state, {
        sudoGuid,
      });

      const selectedEmails = selectedEmailsSelector(state);
      const emails = currentEmails
        .filter(email => selectedEmails[email.id])
        .filter(email => email.seen !== isSeen)
        .map(email => ({
          ...email,
          seen: isSeen,
        }));

      if (emails.length > 0) {
        await dispatch(processEntities(emails));
        await dispatch(saveEntities(emails));
      }
    };
  },
  batchDelete: (
    sudoGuid: string,
    folderName: string,
    stage: string,
  ): IThunk<void> => {
    return async (dispatch, getState, app) => {
      const state = getState();
      const currentEmails = currentRawEmailsSelector(state, {
        sudoGuid,
        folderName,
      });
      const selectedEmails = selectedEmailsSelector(state);
      const emails = currentEmails.filter(email => selectedEmails[email.id]);

      if (emails.length > 0) {
        const isTrashOrDrafts =
          folderName === 'trash' || folderName === 'drafts';

        if (stage === null && isTrashOrDrafts) {
          dispatch(actions.showConfirm('batch-remove'));
          return;
        }
        dispatch(actions.hideConfirms());

        dispatch(
          createModalAction('saving', {
            isOpen: true,
            savingText: 'Deleting...',
          }),
        );

        await dispatch(deleteEntities(emails));

        dispatch(createModalAction('saving', { isOpen: false }));

        await dispatch(actions.queryEmails(sudoGuid, folderName));

        dispatch({ type: SELECT_EMAILS, payload: { emails, value: false } });
      }
    };
  },
  batchRestore: (sudoGuid: string, folderName: string): IThunk<void> => {
    return async (dispatch, getState, app) => {
      const state = getState();
      const currentEmails = currentRawEmailsSelector(state, {
        sudoGuid,
        folderName,
      });
      const selectedEmails = selectedEmailsSelector(state);
      const emails = currentEmails.filter(email => selectedEmails[email.id]);

      if (emails.length > 0 && folderName === 'trash') {
        dispatch(
          createModalAction('saving', {
            isOpen: true,
            savingText: 'Restoring...',
          }),
        );
        const emailsToRestore = emails.map((email: IEmailMessage) => {
          return { ...email, trash: false };
        });
        await dispatch(saveEntities(emailsToRestore));
        dispatch(createModalAction('saving', { isOpen: false }));
        dispatch({ type: SELECT_EMAILS, payload: { emails, value: false } });
      }
    };
  },
  batchMoveToTrash: (sudoGuid: string, folderName: string): IThunk<void> => {
    return async (dispatch, getState, app) => {
      const state = getState();
      const currentEmails = currentRawEmailsSelector(state, {
        sudoGuid,
        folderName,
      });
      const selectedEmails = selectedEmailsSelector(state);
      const emails = currentEmails
        .filter(email => selectedEmails[email.id])
        .map(email => ({
          ...email,
          trash: true,
        }));

      if (emails.length > 0) {
        await dispatch(saveEntities(emails));
        dispatch({ type: SELECT_EMAILS, payload: { emails, value: false } });
      }
    };
  },
  showSnackbarError: (errorType: string, message: string): Action => {
    return { type: SHOW_SNACKBAR_ERROR, payload: { errorType, message } };
  },
  hideSnackbarError: (errorType: string): Action => {
    return { type: HIDE_SNACKBAR_ERROR, payload: errorType };
  },
  toggleMixedContent: (): IThunk => {
    return async (dispatch, getState) => {
      const newValue = !getState().emails.allowMixedContent;
      dispatch({
        type: ALLOW_MIXED_CONTENT,
        payload: newValue,
      });
    };
  },
};

// TODO: assign correct interfaces to reducers
const reducer: Reducer = reduceActions(
  {
    [CHANGE_STATE]: (state: State, { payload }: Action): State => ({
      ...state,
      ...payload,
    }),
    [QUERY_EMAILS_START]: (state: State): State => ({
      ...state,
      isEmailsLoading: true,
    }),
    [QUERY_EMAILS_FINISH]: (state: State, { payload }: Action): State => ({
      ...state,
      isEmailsLoading: false,
    }),
    [FETCH_EMAIL_BODY_START]: (state: State, { payload }: Action): State => ({
      ...state,
      emailBodyDownloadId: payload.messageId,
    }),
    [FETCH_EMAIL_BODY_END]: (state: State, { payload }: Action): State => ({
      ...state,
      emailBodyDownloadId:
        payload.messageId === state.emailBodyDownloadId
          ? undefined
          : state.emailBodyDownloadId,
    }),
    [OPEN_COMPOSER]: (state: State, { payload }: Action): State => ({
      ...state,
      composerEmail: payload.email,
      composerBody: payload.body,
    }),
    [CLOSE_COMPOSER]: (state: State): State => ({
      ...state,
      composerEmail: null,
      composerBody: null,
    }),
    [CHANGE_COMPOSER_EMAIL]: (state: State, { payload }: Action): State => ({
      ...state,
      composerEmail: {
        ...state.composerEmail,
        ...payload,
      },
    }),
    [ADD_COMPOSER_BODY_ATTACHMENTS]: (
      state: State,
      { payload }: Action,
    ): State => ({
      ...state,
      composerBody: {
        ...state.composerBody,
        attachments: uniqBy(
          [...state.composerBody.attachments, ...payload],
          'filename',
        ),
      },
    }),
    [TOGGLE_COMPOSER_BODY_ATTACHMENT_INLINE]: (
      state: State,
      { payload }: Action,
    ): State => ({
      ...state,
      composerBody: {
        ...state.composerBody,
        attachments: [
          ...state.composerBody.attachments.map(
            (attachment: IEmailAttachment) => {
              if (attachment === payload) {
                return {
                  ...attachment,
                  inlineAttachment: !attachment.inlineAttachment,
                };
              }
              return attachment;
            },
          ),
        ],
      },
    }),
    [REMOVE_COMPOSER_BODY_ATTACHMENT]: (
      state: State,
      { payload }: Action,
    ): State => ({
      ...state,
      composerBody: {
        ...state.composerBody,
        attachments: [
          ...state.composerBody.attachments.slice(0, payload),
          ...state.composerBody.attachments.slice(payload + 1),
        ],
      },
    }),
    [SHOW_CONFIRM]: (state: State, { payload }: Action): State => ({
      ...state,
      confirm: payload,
    }),
    [HIDE_CONFIRMS]: (state: State): State => ({
      ...state,
      confirm: null,
    }),
    [SELECT_EMAILS]: (
      state: State,
      { payload: { emails, value } }: Action,
    ): State => ({
      ...state,
      selected: {
        ...state.selected,
        ...emails.reduce((list: any, email: IEmailMessage) => {
          list[email.id] = value;
          return list;
        }, {}),
      },
    }),
    [SEND_SUCCESS]: (state: State, { payload }: Action): State => ({
      ...state,
      selected: {
        ...state.selected,
        [payload.guid]: false,
      },
    }),
    [SHOW_SNACKBAR_ERROR]: (state: State, { payload }: Action): State => ({
      ...state,
      snackbarError: payload,
    }),
    [HIDE_SNACKBAR_ERROR]: (state: State): State => ({
      ...state,
      snackbarError: null,
    }),
    [ALLOW_MIXED_CONTENT]: (state: State, { payload }: Action): State => ({
      ...state,
      allowMixedContent: payload,
    }),
  },
  initialState,
); // tslint:disable-line:align

export default reducer;
export { actions };
