import * as React from 'react';
import { noop } from 'lodash';
import styled from 'styled-components';

import Confirm from 'src/components/Confirm';
import { Button, SideBar, List, Modal } from 'src/components';
import { ContactAutocompletePhone } from 'src/features/Contacts/ContactAutocomplete';

import ConversationDetailsMember from './conversationDetailsMember';
import GroupNameInput from './groupNameInput';
import withConversationDetailsData, {
  IComposedProps,
  IDispatchFromProps,
  IStateFromProps,
  IStateProps,
} from './withConversationDetailsData';

const SideBarStyled = styled(SideBar)`
  &.isOpen {
    box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.2);
  }
`;

export const ConversationMembers = styled.div`
  background-color: var(--gray-9);
`;

export const ConversationMemberActionButtons = styled.div`
  border-bottom: var(--theme-divider);
  padding: 0 16px;
  display: flex;
  height: 56px;
`;

export const ConversationActionButtons = styled.div`
  background-color: var(--gray-9);
  border-bottom: var(--theme-divider);
  display: flex;
  height: 44px;
  margin-top: 32px;
  padding: 0 16px;
`;

export const ConversationActionButton = styled(Button)`
  span {
    font-size: 17px;
    line-height: 21px;
    color: var(--blue);
  }
`;

export const LeaveConversationButton = styled(ConversationActionButton)`
  span {
    color: var(--red);
  }
`;

export interface IProps
  extends IOwnProps,
    IStateFromProps,
    IStateProps,
    IDispatchFromProps,
    IComposedProps {}

export interface IOwnProps {
  activeMembersWithAvatars: any[];
  avatarLocked: boolean;
  conversationGuid: string;
  isGroupConversation: boolean;
  isOpen: boolean;
  onClose: () => void;
  phoneAccountGuid: string;
  sudoGuid: string;
  sudoSlug: string;
  title: string | null;
}

export const ConversationDetails = (props: IProps) => (
  <SideBarStyled isOpen={props.isOpen} onClose={props.onClose}>
    {props.isGroupConversation && (
      <GroupNameInput
        value={props.title}
        placeholder="Group Name..."
        onChange={(title: string | null) =>
          props.onSaveTitle(props.conversationGuid, title, props.onClose)
        }
      />
    )}

    <ConversationMembers>
      <List isWithAvatar isWithSecondaryText>
        {props.activeMembersWithAvatars.map(member => (
          <ConversationDetailsMember
            avatarLocked={props.avatarLocked}
            key={member.alias}
            member={member}
            onCreateConversation={
              props.isGroupConversation &&
              ((memberData: any) =>
                props.createConversation(
                  props.sudoSlug,
                  props.phoneAccountGuid,
                  memberData,
                ))
            }
          />
        ))}
      </List>

      {props.isGroupConversation && (
        <ConversationMemberActionButtons>
          <ConversationActionButton
            iconName="AddContact"
            iconColor="var(--blue)"
            onClick={() => props.setIsAddContactModalOpen(true)}
          >
            Add a Contact
          </ConversationActionButton>
        </ConversationMemberActionButtons>
      )}
    </ConversationMembers>

    {props.isGroupConversation && (
      <ConversationActionButtons>
        <LeaveConversationButton
          onClick={() =>
            props.onLeaveConversation(props.conversationGuid, props.onClose)
          }
        >
          Leave Chat
        </LeaveConversationButton>
      </ConversationActionButtons>
    )}

    <ConversationActionButtons>
      <LeaveConversationButton
        onClick={() => props.setIsDeleteConfirmModalOpen(true)}
      >
        Delete Chat
      </LeaveConversationButton>
    </ConversationActionButtons>

    {props.isAddContactModalOpen && (
      <Modal
        isOpen={props.isAddContactModalOpen}
        onClose={() => props.setIsAddContactModalOpen(false)}
      >
        <ContactAutocompletePhone
          sudoGuid={props.sudoGuid}
          addedContacts={props.activeMembers}
          onAddContact={props.onAddTag}
          onRemoveLastContact={noop}
          placeholder="Type name or phone number"
          isHighlightError
          isHideTags
        />
      </Modal>
    )}

    {props.isDeleteConfirmModalOpen && (
      <Confirm
        isFullScreen
        title="Delete chat?"
        content="This will remove all history of this chat from your messages thread."
        acceptText="Delete"
        cancelText="Cancel"
        acceptBackgroundColor="#e70000"
        onAccept={() =>
          props.onDeleteConversation(props.sudoSlug, props.conversationGuid)
        }
        onCancel={() => props.setIsDeleteConfirmModalOpen(false)}
      />
    )}
  </SideBarStyled>
);

export default withConversationDetailsData(
  ConversationDetails as any,
) as React.ComponentClass<IOwnProps>;
