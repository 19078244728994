import React from 'react';
// tslint:disable:max-line-length

export default function Refresh(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <g fill="currentColor" fillRule="evenodd" transform="rotate(-90 12 11)">
        <path
          id="a"
          d="M18.35 16.85a9.454 9.454 0 1 0-4.946 3.238.75.75 0 1 1 .387 1.449C7.948 23.102 1.941 19.635.376 13.79-1.19 7.948 2.278 1.941 8.121.376c5.844-1.566 11.85 1.902 13.416 7.745a10.94 10.94 0 0 1-2.138 9.816l1.58-.48a.75.75 0 0 1 .436 1.435l-3.598 1.093a.75.75 0 0 1-.968-.718v-3.811a.75.75 0 0 1 1.5 0v1.394z"
        />
      </g>
    </svg>
  );
}
