import React from 'react';

export default function Reply(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      {...props}
    >
      <g fill="currentColor" fillRule="evenodd" transform="translate(1 4)">
        <path d="M10.316 5.695h-.745V1.779L1.676 7.494l7.895 5.715V9.185h.745c4.126 0 7.415 1.213 9.821 3.636-1.132-4.795-4.356-7.126-9.82-7.126zm11.677 9.326c.04.484-.088.79-.384.915-.444.19-.98-.077-1.153-.338-2.045-3.072-5.152-4.689-9.394-4.856v2.96c0 .264-.078.521-.225.737a1.217 1.217 0 0 1-1.73.316L.53 8.546a1.265 1.265 0 0 1-.305-.316A1.313 1.313 0 0 1 .53 6.44L9.107.233C9.316.082 9.565 0 9.819 0c.687 0 1.243.575 1.243 1.285v2.882c6.59.254 10.302 3.933 10.93 10.854z" />
      </g>
    </svg>
  );
}
