import ButtonPrimary from '../ButtonPrimary';
import styled from 'styled-components';

const ButtonPrimaryRound = styled(ButtonPrimary)`
  padding: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  justify-content: center;
`;

export default ButtonPrimaryRound;
