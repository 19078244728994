import * as React from 'react';

import { config } from '../../config';
import { Modal, ModalButton } from '../../components/OModal';
import { CopyableField } from '../../components/copyable-field';
import withErrorTrapModalData, { IProps } from './withErrorTrapModalData';
import './styles.scss';

const ErrorTrapModal = (props: IProps) => {
  const { error, clearTrappedError } = props;
  const isOpen = !!error;
  const errorDetails = JSON.stringify(error, stringifyReplacer, 2);

  return (
    <Modal isOpen={isOpen} className="error-trap-modal">
      <React.Fragment>
        <h2>Sorry! An error occurred, and we can't recover from it.</h2>
        <p>Please refresh your browser to continue.</p>
        {config.showErrors && (
          <div className="error-trap-modal__dev">
            <p>Details: {error ? error.message : ''}</p>
            <CopyableField
              text={errorDetails}
              className="copyable-field--plain"
            >
              Copy to clipboard
            </CopyableField>
            <pre>{errorDetails}</pre>
          </div>
        )}
        <div className="error-trap-modal-buttons">
          {config.allowContinueAfterError ? (
            <ModalButton
              type="cancel"
              label="Continue"
              handler={clearTrappedError}
            />
          ) : null}
          <ModalButton
            type="confirm"
            label="Reload"
            handler={props.unpairAction}
          />
        </div>
      </React.Fragment>
    </Modal>
  );
};

function stringifyReplacer(_key: string, value: any): any {
  const maxStrLength = 500;

  if (value instanceof Error) {
    return Object.getOwnPropertyNames(value).reduce(
      (accumulator, propName) => ({
        ...accumulator,
        [propName]: (value as any)[propName],
      }),
      {},
    );
  }

  if (typeof value === 'string' && value.length > maxStrLength) {
    return value.substring(0, maxStrLength) + '...';
  }

  return value;
}

export default withErrorTrapModalData(ErrorTrapModal);
