import styled from 'styled-components';

interface IProps {
  children?: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
}

const ModalTitle = styled.div<IProps>`
  color: #303030;
  font-weight: 900;
  text-align: center;
`;

export default ModalTitle;
