import { Reducer, Action, reduceActions } from '../../reduce-actions';

export interface IRedirectState {
  to: string;
}

const REDIRECT = 'Redirect/REDIRECT';
const REDIRECT_SUCCESS = 'Redirect/REDIRECT_SUCCESS';

export const actions = {
  redirect: (to: string): Action => {
    return { type: REDIRECT, payload: { to } };
  },
  setRedirectSuccess: (): Action => {
    return { type: REDIRECT_SUCCESS };
  },
};

const initialState: IRedirectState = {
  to: null,
};
const reducer: Reducer = reduceActions(
  {
    [REDIRECT]: (
      state: IRedirectState,
      { payload }: Action,
    ): IRedirectState => ({
      ...state,
      ...payload,
    }),
    [REDIRECT_SUCCESS]: (state: IRedirectState): IRedirectState => ({
      ...state,
      to: null,
    }),
  },
  initialState,
); // tslint:disable-line:align

export default reducer;
