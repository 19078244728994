import React from 'react';
import get from 'lodash/get';
import omit from 'lodash/fp/omit';
import PropTypes from 'prop-types';
import icons from 'src/icons';
import keys from 'lodash/keys';

interface IconProps {
  name?: string;
  style?: React.CSSProperties;
  className?: string;
}

const getIcon = (iconName: string) => get(icons, iconName);
const omitIconProps = omit(['name']);

const Icon = (props: IconProps & React.SVGAttributes<any>) => {
  const IconComponent = getIcon(props.name);
  return <IconComponent {...omitIconProps(props)} />;
};

Icon.propTypes = {
  name: PropTypes.oneOf(keys(icons)).isRequired,
};

Icon.displayName = 'Icon';

export default React.memo(Icon);
