import omit from 'lodash/fp/omit';
import React from 'react';
import styled from 'styled-components';
import Icon from 'src/components/Icon';
import { ImageProps } from '../..';

export const ImageWrapper = styled.div`
  position: relative;
  user-select: none;
  display: inline-block;
`;

const Image = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

export const RemoveIcon = styled(Icon)`
  position: absolute;
  cursor: pointer;
  right: calc(var(--theme-spacing) * 2);
  top: calc(var(--theme-spacing) * 2);
`;

const omitRemoveIconProps = omit(['onRemove']);

const ImageBase = (props: ImageProps) => (
  <ImageWrapper>
    <Image {...omitRemoveIconProps(props)} />
    {props.isRemovable && (
      <RemoveIcon
        onClick={event => {
          event.stopPropagation();
          props.onRemove();
        }}
        name="Remove"
        color="#969EA6"
      />
    )}
  </ImageWrapper>
);

export default ImageBase;
