import * as React from 'react';
import { Button } from 'src/components';

import './styles.scss';

interface IProps {
  title: React.ReactNode;
  content: React.ReactNode;

  acceptText: React.ReactNode;
  cancelText: React.ReactNode;
  rejectText?: React.ReactNode;

  acceptData?: any;
  cancelData?: any;
  rejectData?: any;

  acceptBackgroundColor?: string;
  cancelBackgroundColor?: string;
  rejectBackgroundColor?: string;

  onAccept: (
    e: React.SyntheticEvent<HTMLButtonElement>,
    acceptData: any,
  ) => void;
  onCancel: (
    e: React.SyntheticEvent<HTMLButtonElement>,
    cancelData: any,
  ) => void;
  onReject?: (
    e: React.SyntheticEvent<HTMLButtonElement>,
    rejectData: any,
  ) => void;

  isFullScreen?: boolean;
}

class EmailConfirm extends React.PureComponent<IProps> {
  private handleAcceptClick = (e: React.SyntheticEvent<HTMLButtonElement>) => {
    const { onAccept, acceptData } = this.props;
    onAccept(e, acceptData);
  };
  private handleCancelClick = (e: React.SyntheticEvent<HTMLButtonElement>) => {
    const { onCancel, cancelData } = this.props;
    onCancel(e, cancelData);
  };
  private handleRejectClick = (e: React.SyntheticEvent<HTMLButtonElement>) => {
    const { onReject, rejectData } = this.props;
    if (onReject) {
      onReject(e, rejectData);
    }
  };
  public render() {
    const {
      title,
      content,
      acceptText,
      cancelText,
      rejectText,
      acceptBackgroundColor,
      cancelBackgroundColor,
      rejectBackgroundColor,
      isFullScreen,
    } = this.props;

    const acceptStyle = { backgroundColor: acceptBackgroundColor };
    const cancelStyle = { backgroundColor: cancelBackgroundColor };
    const rejectStyle = { backgroundColor: rejectBackgroundColor };
    const confirmStyle = {
      position: isFullScreen ? ('fixed' as 'fixed') : undefined,
    };

    return (
      <React.Fragment>
        <div className="overlay" />
        <div className="confirm" style={confirmStyle}>
          <div>
            <h1>{title}</h1>
            <p>{content}</p>
            <div className="buttons">
              <Button
                secondary
                onClick={this.handleCancelClick}
                style={cancelStyle}
              >
                {cancelText}
              </Button>
              {rejectText && (
                <Button
                  danger
                  onClick={this.handleRejectClick}
                  style={rejectStyle}
                >
                  {rejectText}
                </Button>
              )}
              <Button
                autoFocus
                primary
                onClick={this.handleAcceptClick}
                style={acceptStyle}
              >
                {acceptText}
              </Button>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default EmailConfirm;
