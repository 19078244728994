import React from 'react';

export default function Location(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <g fill="currentColor" fillRule="evenodd" transform="translate(4 1)">
        <path d="M15.75 8.349c0 2.542-2.16 6.651-6.354 12.197a1.75 1.75 0 0 1-2.792 0C2.41 15 .25 10.891.25 8.35.25 4.149 3.722.75 8 .75c4.278 0 7.75 3.4 7.75 7.599zm-1.5 0c0-3.364-2.793-6.099-6.25-6.099S1.75 4.985 1.75 8.349c0 2.103 2.05 6.002 6.05 11.293a.25.25 0 0 0 .4 0c4-5.291 6.05-9.19 6.05-11.293zM8 10.217c-1.52 0-2.75-1.249-2.75-2.785S6.48 4.648 8 4.648s2.75 1.248 2.75 2.784c0 1.536-1.23 2.785-2.75 2.785zm0-1.5c.687 0 1.25-.572 1.25-1.285S8.687 6.148 8 6.148s-1.25.571-1.25 1.284c0 .713.563 1.285 1.25 1.285z" />
      </g>
    </svg>
  );
}
