import * as React from 'react';

import {
  FieldRowLabel,
  FieldRowValue,
  RemovableFieldRowWrapper,
} from '../Base/BaseSectionElements';
import FieldInput from '../Base/FieldInput';
import SectionWithRemovableField from '../Base/SectionWithRemovableField';

const NotesSection = (props: any) => {
  const { input, hasInitialData } = props;
  return (
    <SectionWithRemovableField
      addItemButtonText="Add Notes"
      hasInitialData={hasInitialData}
    >
      <RemovableFieldRowWrapper>
        <FieldRowLabel>Notes</FieldRowLabel>
        <FieldRowValue>
          <FieldInput {...input} placeholder={props.placeholder} />
        </FieldRowValue>
      </RemovableFieldRowWrapper>
    </SectionWithRemovableField>
  );
};

export default NotesSection;
