import React from 'react';

export default function Contact(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <g fill="currentColor" fillRule="evenodd" transform="translate(4 2)">
        <path d="M8 9.75a4.75 4.75 0 1 1 0-9.5 4.75 4.75 0 0 1 0 9.5zm0-1.5a3.25 3.25 0 1 0 0-6.5 3.25 3.25 0 0 0 0 6.5zM15.75 20h-1.5v-1.25c0-3.307-2.792-6-6.25-6s-6.25 2.693-6.25 6V20H.25v-1.25c0-4.148 3.476-7.5 7.75-7.5s7.75 3.352 7.75 7.5V20z" />
      </g>
    </svg>
  );
}
