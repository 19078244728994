import * as React from 'react';
import SessionMessage, {
  Title,
  Toolbar,
  ToolbarButton,
  SubHead,
} from '../SessionMessage';
import { IUnpairOpts } from 'src/actions';

interface IProps {
  logout: (opts?: IUnpairOpts) => void;
  reconnect: () => void;
}

export default (props: IProps) => (
  <SessionMessage>
    <Title>This session was opened somewhere else</Title>
    <p>MySudo for web is only supported in one browser tab at a time.</p>

    <SubHead>I didn't do that</SubHead>
    <p>
      If you did not attempt to open MySudo for web in a different browser tab,
      or you don't know why you are seeing this message, please click "Unpair"
      to ensure the safety of your session.
    </p>

    <SubHead>That was me</SubHead>
    <p>
      If you did attempt to open MySudo for web in a different browser tab, you
      can open it here again or safely close this tab.
    </p>

    <Toolbar>
      <ToolbarButton secondary onClick={() => props.reconnect()}>
        Open here
      </ToolbarButton>
      <ToolbarButton
        primary
        onClick={() => props.logout({ unpairOtherTabs: true })}
      >
        Unpair
      </ToolbarButton>
    </Toolbar>
  </SessionMessage>
);
