import * as DOMPurify from 'dompurify';
import * as MixedContentHook from './mixed-content-sanitizer-hook';
import * as ScrubImageHook from './scrub-images-hook';
import * as RemoveTitleTagHook from './remove-title-hook';

export const tags = [
  'a',
  'b',
  'blockquote',
  'body',
  'br',
  'caption',
  'center',
  'code',
  'div',
  'em',
  'font',
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'hr',
  'html',
  'i',
  'img',
  'li',
  'nl',
  'ol',
  'p',
  'pre',
  's',
  'span',
  'strike',
  'strong',
  'style',
  'table',
  'tbody',
  'td',
  'th',
  'thead',
  'tr',
  'u',
  'ul',
];

export const attr = [
  'align',
  'alt',
  'bgcolor',
  'border',
  'cellpadding',
  'cellspacing',
  'class',
  'color',
  'colspan',
  'face',
  'height',
  'href',
  'id',
  'rel',
  'src',
  'style',
  'target',
  'type',
  'valign',
  'width',
];

export interface ISanitizerContext {
  clean: string;
}

export type SanitizerHook<T = {}> = (defaultContext?: Partial<T>) => T;

/**
 * Sanitizer function that can accept hook functions to provide additional processing functionality
 */
export function sanitize<T = {}>(
  dirty: string,
  hooks: SanitizerHook[] = [],
  defaultContext: Partial<T & ISanitizerContext> = {},
): T & ISanitizerContext {
  // Configure hooks
  DOMPurify.removeAllHooks();
  const allHookResults = hooks.map(hook => hook(defaultContext));

  // Run Sanitizer
  const clean = DOMPurify.sanitize(dirty, {
    ALLOWED_TAGS: tags,
    ALLOWED_ATTR: attr,
    WHOLE_DOCUMENT: true,
  });

  // Clean up hooks
  DOMPurify.removeAllHooks();

  // Return an object containing the cleaned html along with all hook context data
  const resultData = [...allHookResults, { clean }];
  return Object.assign(defaultContext, ...resultData);
}

/** Simple sanitizer for email HTML */
export function sanitizeHtml(dirty: string) {
  return sanitize(dirty).clean;
}

/** Sanitizer used for processing an inbound email */
type IInboundSanitizerContext = MixedContentHook.IContext;
export function inboundEmailSanitizer(
  dirty: string,
  defaultContext?: Partial<IInboundSanitizerContext>,
  scrubInlineImages?: boolean,
) {
  return sanitize<IInboundSanitizerContext>(
    dirty,
    scrubInlineImages
      ? [MixedContentHook.hook, RemoveTitleTagHook.hook, ScrubImageHook.hook]
      : [MixedContentHook.hook, RemoveTitleTagHook.hook],
    defaultContext,
  );
}
