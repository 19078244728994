import Events from 'events';

/** Map of event names to event objects */
interface EventMap {
  [key: string]: any;
}

/** Function used to clean up unresolved promises */
type CleanupFunction = () => void;

/** Combination of a promise and a cleanup function */
interface PromiseWithCleanup<T> {
  promise: Promise<T>;
  cleanUp: CleanupFunction;
}

/**
 * The properly typed PromiseWithCleanup returned
 * by `waitForEvent()` and `throwOnEvent`.
 */
type WaitResult<
  TEventMap extends EventMap,
  TEvent extends keyof TEventMap = keyof TEventMap
> = PromiseWithCleanup<TEventMap[TEvent]>;

/** Types of values that could be resolved by an array of WaitResults */
type WaitResultTypes<
  TEventMap,
  T extends Array<WaitResult<TEventMap>>
> = T extends Array<{ promise: Promise<infer P> }> ? P : never;

/**
 * Typed wrapper for Events class, suitable from inheriting from.
 * Includes some async convenience functions.
 */
export class ProtectedTypedEvents<TEventMap extends EventMap> {
  private events = new Events();

  /** protected emit */
  protected emit<TEvent extends keyof TEventMap>(
    name: TEvent,
    event: TEventMap[TEvent],
  ) {
    this.events.emit(name, event);
  }

  public listenersCount<TEvent extends keyof TEventMap>(name: TEvent) {
    return this.events.listenerCount(name);
  }

  public addListener<TEvent extends keyof TEventMap>(
    name: TEvent,
    listener: (event: TEventMap[TEvent]) => void,
  ) {
    this.events.addListener(name, listener);
    return listener;
  }

  public removeListener<TEvent extends keyof TEventMap>(
    name: TEvent,
    listener: (event: TEventMap[TEvent]) => void,
  ) {
    this.events.removeListener(name, listener);
  }

  public removeAllListeners() {
    this.events.removeAllListeners();
  }
}

/**
 * Typed wrapper for Events class. Exposes `emit()` function.
 * Suitable for composition
 */
export class TypedEvents<
  TEventMap extends EventMap
> extends ProtectedTypedEvents<TEventMap> {
  public emit<TEvent extends keyof TEventMap>(
    name: TEvent,
    event: TEventMap[TEvent],
  ) {
    super.emit(name, event);
  }
}
