import React from 'react';
import styled from 'styled-components';

const TagsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

interface IInputTagsListProps {
  tags?: Array<{
    id: number | string;
    tag: JSX.Element;
  }>;
  tagSeparator?: JSX.Element;
}

const InputTagsList = (props: IInputTagsListProps) => (
  <TagsWrapper>
    {props.tags.map(item => (
      <React.Fragment key={item.id}>
        {item.tag}
        {props.tagSeparator}
      </React.Fragment>
    ))}
  </TagsWrapper>
);

export default InputTagsList;
