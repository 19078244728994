import styled, { css } from 'styled-components';

interface IProps {
  color: string;
  count: number;
}

const CardCounter = styled.div<IProps>`
  color: var(--white);
  height: 18px;
  border-radius: 20px;
  background: ${props => props.color};
  padding: 0 6px 1px;
  font-size: 12px;
  position: absolute;
  font-weight: 400;
  right: -9px;
  top: -9px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transform: scale(0);
  ${props =>
    props.count &&
    css`
    &:before {
      text-align: center;
      padding-left: 1px;
      content: "${props.count >= 9999 ? 9999 : props.count}";
    }
    opacity: 1;
    transform: scale(1);
  `}
`;

export default CardCounter;
