import React from 'react';

export default function MessageStatusCheckmark(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="12"
      viewBox="0 0 14 12"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeWidth="1.5"
          d="M4 8.465L6.535 11l6.392-6"
        />
      </g>
    </svg>
  );
}
