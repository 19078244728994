import styled, { css } from 'styled-components';

interface IProps {
  children?: React.ReactNode;
  isBlurred?: boolean;
}

const MessageBubbleText = styled.div<IProps>`
  ${props =>
    props.isBlurred &&
    css`
      filter: blur(4px);
    `}
`;

export default MessageBubbleText;
