import { entries, isEmpty, defaultTo, compact } from 'lodash';
import {
  IAddRequestEntity,
  IRequestMeta,
  ISearchParams,
  IUpdateRequestEntity,
  SearchPredicate,
} from './message-interfaces';
import {
  EntityChange,
  EntityType,
  IChildEntity,
  IContact,
  IContactAvatar,
  IEntity,
  IMessage,
  IMessageConversation,
  IQueryParams,
  ISudoAvatar,
  IEmailMessage,
  IMessageAttachment,
} from './entities';
import { IMessageConversationMember } from './entities';

const ENTITIES_WITH_PARENT_REQUIRED: EntityType[] = [
  'SudoSettings',
  'Telephone',
  'MessagingThread',
  'Message',
  'EmailAccount',
  'EmailMessage',
];

export interface IRequestProcessor {
  preSubscribeParams: (params: IQueryParams) => ISearchParams[];
  postSubscribeEntities: (entities: EntityChange[]) => EntityChange[];

  preSearchParams: (params: IQueryParams) => ISearchParams[];
  postSearchEntities: (entities: Array<Partial<IEntity>>) => IEntity[];

  preAddEntities: (entities: Array<Partial<IEntity>>) => IAddRequestEntity[];
  postAddEntities: (
    entities: Array<Partial<IEntity>>,
    meta?: IRequestMeta,
  ) => IEntity[];

  preUpdateEntities: (
    entities: Array<Partial<IEntity>>,
  ) => IUpdateRequestEntity[];
  postUpdateEntities: (
    entities: Array<Partial<IEntity>>,
    meta?: IRequestMeta,
  ) => IEntity[];

  preDeleteParams: (params: IQueryParams) => ISearchParams[];
}

export type CreateProcessorType = () => IRequestProcessor;

// TODO: improve logging
const warn = (type: string, message: string, ...optional: any[]) => {
  (console as any).group(
    '%c(!) %cRequest Processor: %c' + type,
    'font-weight: normal; color: #991840;',
    '',
    'font-weight: normal; color: #991840;',
  );
  console.warn(message, ...optional);
  console.groupEnd();
};

const deserialize = (value: any, defaultValue: any = []) => {
  if (value) {
    if (typeof value === 'string') {
      try {
        return JSON.parse(value);
      } catch (e) {
        warn('Deserialization FAIL', 'Value is not a valid JSON', value);
        return defaultValue;
      }
    } else {
      warn('Deserialization FAIL', 'Value is not a string', value);
      return value;
    }
  }
  return defaultValue;
};

const participantToConversationMember = (
  participant: IMessageConversationMember,
) => {
  if (!participant || !participant.alias) {
    warn(
      'Conversation member transformation FAIL',
      'Participant is invalid',
      participant,
    );
    return {
      alias: '',
      aliasType: 'unknown',
      active: false,
    } as IMessageConversationMember;
  }

  if (participant.alias.indexOf(':') < 0) {
    warn(
      'Conversation member transformation FAIL',
      'Participant alias is invalid',
      participant,
    );
  }

  const [aliasType, alias = ''] = participant.alias.split(':');
  return {
    ...participant,
    alias,
    aliasType,
    active: (participant.active as any) === 'true',
  } as IMessageConversationMember;
};

const conversationMemberToParticipant = (
  participant: IMessageConversationMember,
) => {
  const { aliasType, alias, active, ...rest } = participant;
  return {
    ...rest,
    alias: `${aliasType}:${alias}`,
    active: active ? 'true' : ('false' as any),
  } as IMessageConversationMember;
};

const transformEnterExitAttachment = (attachment: IMessageAttachment): any => {
  if (
    (attachment.mimeType.startsWith('enter') ||
      attachment.mimeType.startsWith('exit')) &&
    attachment.data
  ) {
    try {
      const decodedData = atob(attachment.data);
      const newAttachment = { ...attachment, data: decodedData };
      if (
        !newAttachment ||
        !newAttachment.data ||
        newAttachment.data.indexOf(':') < 0
      ) {
        return newAttachment;
      }
      const [aliasType, alias] = newAttachment.data.split(':');
      return {
        ...newAttachment,
        aliasType,
        alias,
      };
    } catch (e) {
      warn(
        'Enter/Exit message attachment transformation FAIL',
        'data is not a valid base64 value',
        attachment,
      );
    }
  }
  return attachment;
};

/**
 * Transform old search / subscribe params to protocol version 2
 */
const transformSearchQueryParams = (
  queryParams: IQueryParams,
): ISearchParams[] => {
  return entries(queryParams).map(pair => {
    const query: ISearchParams = {
      type: pair[0] as EntityType,
    };
    if (!isEmpty(pair[1])) {
      query.predicate = pair[1] as SearchPredicate;

      // FIXME(mysudo): Set a better way of providing sorting to requests
      if (['MessagingThread', 'Message', 'EmailMessage'].includes(query.type)) {
        query.sort = [
          {
            key: 'created',
            order: 'DESC',
          },
        ];
      }
    }
    return query;
  });
};

/**
 * Transform & validate entities returned from search requests
 */
const transformApiEntities = (
  entities: Array<Partial<IEntity>>,
  meta?: IRequestMeta,
): IEntity[] => {
  return (
    entities &&
    entities

      // Skip invalid entities
      .filter(entity => {
        if (!entity.type) {
          warn('Skipping API entity', 'Entity type missing', entity);
          return false;
        }

        // All entities except Contact & ContactAvatar should have an 'id' property
        // They might have localIdentifier instead, checked during processing
        if (
          !entity.id &&
          entity.type !== 'Contact' &&
          entity.type !== 'ContactAvatar' &&
          entity.type !== 'Analytics'
        ) {
          warn('Skipping API entity', 'Entity id missing', entity, meta);
          return false;
        }

        if (ENTITIES_WITH_PARENT_REQUIRED.includes(entity.type)) {
          const childEntity = entity as IChildEntity;
          if (
            !childEntity.parent ||
            !childEntity.parent.type ||
            !childEntity.parent.id
          ) {
            warn(
              'Skipping API entity',
              'Entity parent missing or incorrect',
              entity,
              meta,
            );
            return false;
          }
        }

        return true;
      })

      // Deserialize entities
      .map(entity => {
        switch (entity.type) {
          case 'Contact':
            const contact = { ...entity } as IContact;
            if (!contact.id) {
              if (contact.localIdentifier) {
                contact.id = contact.localIdentifier;
              } else {
                warn(
                  'Skipping API entity',
                  'Contact fields missing: id & localIdentifier',
                  entity,
                );
              }
            }

            // Check if actual Contact parent is not Sudo
            const parent = contact.parent;
            if (
              parent &&
              parent.type === 'Sudo' &&
              contact.path &&
              RegExp(/^\/user/).test(contact.path)
            ) {
              parent.type = 'User';
            }

            contact.phoneNumbers = defaultTo(contact.phoneNumbers, []);
            contact.emailAddresses = defaultTo(contact.emailAddresses, []);
            contact.streetAddresses = defaultTo(contact.streetAddresses, []);
            return contact;

          // FIXME(mysudo): ContactAvatar entities contain only base64 data. Check mime type
          case 'ContactAvatar':
            const contactAvatar = { ...entity } as IContactAvatar;
            if (!contactAvatar.id) {
              if (contactAvatar.localIdentifier) {
                contactAvatar.id = contactAvatar.localIdentifier;
              } else {
                warn(
                  'Skipping API entity',
                  'ContactAvatar fields missing: id & localIdentifier',
                  entity,
                );
              }
            }
            contactAvatar.avatar =
              contactAvatar.avatar &&
              `data:image/jpeg;base64,${contactAvatar.avatar}`;
            return contactAvatar;

          // FIXME(mysudo): SudoAvatarImage entities contain only base64 data. Check mime type
          case 'SudoAvatarImage':
            const sudoAvatar = { ...entity } as ISudoAvatar;
            const avatar =
              sudoAvatar.avatarImage &&
              sudoAvatar.avatarImage.replace(/(?:\r\n|\r|\n)/g, '');
            sudoAvatar.avatarImage =
              avatar && `data:image/png;base64,${avatar}`;
            return sudoAvatar;

          case 'MessagingThread':
            const thread = { ...entity } as IMessageConversation;

            // These are temporary and can't be used later on
            if (meta && meta.validation) {
              delete thread.id;
              delete thread.path;
            }

            thread.remoteParticipants = deserialize(thread.remoteParticipants);
            thread.localParticipant = deserialize(
              thread.localParticipant,
              null,
            );
            if (thread.subject && thread.subject.media) {
              thread.subject.media = thread.subject.media.map(
                transformEnterExitAttachment,
              );
            }

            thread.remoteParticipants = thread.remoteParticipants.map(
              participantToConversationMember,
            );
            thread.localParticipant = participantToConversationMember(
              thread.localParticipant,
            );
            return thread;

          case 'Message':
            const message = { ...entity } as IMessage;
            message.media = (message.media || []).map(
              transformEnterExitAttachment,
            );

            message.from = participantToConversationMember({
              alias: message.from,
            } as any);
            message.to = participantToConversationMember({
              alias: message.to,
            } as any);
            message.sender = participantToConversationMember({
              alias: message.sender,
            } as any);
            return message;

          case 'EmailMessage':
            const emailMessage = entity as IEmailMessage;

            emailMessage.from = compact(emailMessage.from || []);
            emailMessage.to = compact(emailMessage.to || []);
            emailMessage.cc = compact(emailMessage.cc || []);
            emailMessage.bcc = compact(emailMessage.bcc || []);

            return emailMessage;

          default:
            return entity as IEntity;
        }
      })
  );
};

const transformNotificationEntities = (
  entityChanges: EntityChange[],
): EntityChange[] => {
  return entityChanges
    .map(entityChange => {
      const mappedChange = { ...entityChange };
      if (mappedChange.change !== 'DELETE') {
        const entities = transformApiEntities([mappedChange.entity]);
        if (entities.length === 1) {
          mappedChange.entity = entities[0];
        } else {
          return null;
        }
      }
      return mappedChange;
    })
    .filter(entityChange => entityChange !== null);
};

/**
 * Transform old insert / update params to protocol version 2
 */
type RequestEntity = IAddRequestEntity | IUpdateRequestEntity;
const transformModifyQueryParams = (
  isAdd: boolean,
  entities: Array<Partial<IEntity>>,
): RequestEntity[] => {
  return (
    entities &&
    entities
      .map(entity => {
        switch (entity.type) {
          case 'Contact':
            const contact = entity as IContact;
            let avatar;
            if (contact.avatar) {
              const searchBy = 'base64,';
              const searchPos = contact.avatar.indexOf(searchBy);
              if (searchPos > 0) {
                avatar = contact.avatar.substring(searchPos + searchBy.length);
              } else {
                avatar = contact.avatar;
              }
            }
            return {
              ...contact,
              ...(avatar ? { avatar } : null),
            };

          case 'MessagingThread':
            // We don't need to update subject
            const { subject, ...thread } = entity as IMessageConversation;
            const transformedThread: Partial<IMessageConversation> = {};

            if (thread.remoteParticipants) {
              transformedThread.remoteParticipants = JSON.stringify(
                thread.remoteParticipants.map(conversationMemberToParticipant),
              ) as any;
            }

            if (thread.localParticipant) {
              transformedThread.localParticipant = JSON.stringify(
                conversationMemberToParticipant(thread.localParticipant),
              ) as any;
            }

            return {
              ...thread,
              ...transformedThread,
            };

          case 'Message':
            const message = entity as IMessage;

            const transformedMessage: Partial<IMessage> = {};

            const participantKeys = ['from', 'to', 'sender'] as const;
            participantKeys.forEach(key => {
              if (message[key]) {
                transformedMessage[key] = conversationMemberToParticipant(
                  message[key] as any,
                ).alias as any;
              }
            });

            return {
              ...message,
              ...transformedMessage,
            };

          default:
            return entity;
        }
      })
      .map(entity => {
        const { id, type, parent, ...rest } = entity as IEntity & IChildEntity;
        const query: any = {
          type,
          properties: { ...rest },
        };
        if (!isAdd && id) {
          query.id = id;
        }
        if (isAdd && parent) {
          query.parent = parent;
        }
        return query as RequestEntity;
      })
  );
};

export const createRequestProcessor: CreateProcessorType = () => {
  return {
    preSubscribeParams: transformSearchQueryParams,
    postSubscribeEntities: transformNotificationEntities,

    preSearchParams: transformSearchQueryParams,
    postSearchEntities: transformApiEntities,

    preAddEntities: (entities: Array<Partial<IEntity>>) =>
      transformModifyQueryParams(true, entities) as any,
    postAddEntities: transformApiEntities,

    preUpdateEntities: (entities: Array<Partial<IEntity>>) =>
      transformModifyQueryParams(false, entities) as any,
    postUpdateEntities: transformApiEntities,

    preDeleteParams: transformSearchQueryParams,
  };
};
