import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { IRootState } from '../../state';
import { IDispatcher } from '../../store';

import { clearTrappedError, IPartialState } from './modules';
import { unpairAction } from '../../actions';

type IStateProps = IPartialState;
interface IActionProps {
  clearTrappedError: () => any;
  unpairAction: () => any;
}

const mapState = (state: IRootState): IStateProps => state.errorTrap;
const actions = { clearTrappedError, unpairAction };
const mapDispatch = (dispatch: IDispatcher): IActionProps =>
  bindActionCreators(actions, dispatch);

export default connect(
  mapState,
  mapDispatch,
);
export interface IProps extends IStateProps, IActionProps {}
