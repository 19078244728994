/* tslint:disable max-line-length */
import * as React from 'react';

export default function Drafts(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <g fill="currentColor" fillRule="evenodd" transform="translate(3 1)">
        <path d="M11 0v6.5h7v12.152C18 20.502 16.433 22 14.5 22h-11C1.567 22 0 20.501 0 18.652V3.348C0 1.498 1.567 0 3.5 0H11zm1 .28l5.456 5.22H12V.28z" />
      </g>
    </svg>
  );
}
