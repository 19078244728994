import SudoSettingsSelectors from '../../../entities/sudo-settings/selectors';
import { IDispatcher } from 'src/store';
import { IMatch } from './index';
import { IRootState, AvatarType, getList } from '../../../state';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { fetchAvatarsById } from 'src/actions';
import { getGroupedContacts, IContactsRow } from '../selectors';
import { getListId } from 'src/features/SearchListInput/container';

export interface IStateFromProps {
  contactRows: IContactsRow[];
  isSearchEmpty: boolean;
}

export interface IDispatchToProps {
  fetchAvatarImages: (ids: string[]) => void;
}

export interface IStateProps extends RouteComponentProps<IMatch> {
  sudoSlug: string;
  tab: string;
}

export const mapState = (
  state: IRootState,
  props: IStateProps,
): IStateFromProps => {
  const sudoGuid = SudoSettingsSelectors.getIdBySlug(state, {
    slug: props.sudoSlug,
  });
  const list = getList(state.lists, getListId('contacts', sudoGuid));
  const contactRows = getGroupedContacts(state, { sudoGuid, group: props.tab });
  const isSearchEmpty = list && list.searchCriteria && !contactRows.length;

  return {
    contactRows,
    isSearchEmpty,
  };
};

export function mapDispatchToProps(dispatch: IDispatcher): IDispatchToProps {
  return {
    fetchAvatarImages: (ids: string[]) =>
      dispatch(fetchAvatarsById(AvatarType.ContactAvatar, ids)),
  };
}

export default connect(
  mapState,
  mapDispatchToProps,
);
