const priorityDefault = 3;

export class PriorityQueue<T> {
  private queue: { [priority: string]: T[] } = {};

  constructor(private defaultPriority: number = priorityDefault) {}

  public enqueue(item: T, priority: number = this.defaultPriority) {
    const priorityKey = priority.toString();

    this.queue[priorityKey]
      ? this.queue[priorityKey].push(item)
      : (this.queue[priorityKey] = [item]);
  }

  public dequeue(): T | null {
    const priorities = Object.keys(this.queue).sort();
    if (!priorities.length) {
      return null;
    }

    const nextPriority = priorities[0];
    const bucket = this.queue[nextPriority];
    const item = bucket.shift();

    if (bucket.length === 0) {
      delete this.queue[nextPriority];
    }

    return item;
  }

  get length(): number {
    return Object.values(this.queue).reduce((acc, cv) => {
      return acc + cv.length;
    }, 0);
  }
}
