/* tslint:disable max-line-length */

import * as React from 'react';

export default function ArrowDown(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      {...props}
    >
      <g fill="currentColor" fillRule="evenodd" transform="translate(2 4)">
        <path d="M4 4L0 0h8z" />
      </g>
    </svg>
  );
}
