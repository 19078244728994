import React from 'react';
import styled, { css } from 'styled-components';

import Icon from 'src/components/Icon';
import MessageBubbleBase from '../MessageBubbleBase';
import MessageBubbleText from '../MessageBubbleText';
import MessageBubbleTimestamp from '../MessageBubbleTimestamp';
import { MessageStatus } from '../..';

interface IProps {
  children?: React.ReactNode;
  isBlurred?: boolean;
  isExternal?: boolean;
  status?: MessageStatus;
  timestamp?: React.ReactNode;
}

const InfoWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

const StyledMessageBubble = styled(MessageBubbleBase)<{
  status: MessageStatus;
}>`
  padding-bottom: 6px;
  ${props =>
    props.status === 'not_delivered' &&
    css`
      background: var(--red);
    `}
`;

const StatusWrapper = styled.div`
  position: relative;
  margin: 8px 0 0;
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;

const StatusDeliveredFirstIcon = styled(Icon)`
  position: absolute;
  right: 3px;
`;

const InvisibleIcon = styled(Icon)`
  visibility: hidden;
`;

const StatusSending = () => <InvisibleIcon name="MessageStatusCheckmark" />;

const StatusSent = () => (
  <Icon name="MessageStatusCheckmark" color="rgba(255,255,255,.4)" />
);

const StatusDelivered = () => <Icon name="MessageStatusCheckmark" />;

const StatusRead = () => (
  <React.Fragment>
    <StatusDeliveredFirstIcon name="MessageStatusCheckmark" />
    <Icon name="MessageStatusCheckmark" />
  </React.Fragment>
);

const StatusNotDelivered = () => (
  <React.Fragment>
    <Icon name="MessageStatusFailed" />
  </React.Fragment>
);

export const Status = ({ status }: { status: MessageStatus }) =>
  status === 'delivered' ? (
    <StatusDelivered />
  ) : status === 'read' ? (
    <StatusRead />
  ) : status === 'not_delivered' ? (
    <StatusNotDelivered />
  ) : status === 'sending' ? (
    <StatusSending />
  ) : (
    <StatusSent />
  );

const MessageBubbleWithStatus = (props: IProps) => (
  <StyledMessageBubble status={props.status} isExternal={props.isExternal}>
    <MessageBubbleText isBlurred={props.isBlurred}>
      {props.children}
    </MessageBubbleText>
    <InfoWrapper>
      <MessageBubbleTimestamp>{props.timestamp}</MessageBubbleTimestamp>
      <StatusWrapper>
        <Status status={props.status} />
      </StatusWrapper>
    </InfoWrapper>
  </StyledMessageBubble>
);

MessageBubbleWithStatus.defaultProps = {
  timestamp: null,
};

export default MessageBubbleWithStatus;
