import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose, branch, withProps, renderComponent } from 'recompose';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import { ApiStatus } from 'src/api';
import BuildVersion from 'src/components/BuildVersion';
import { IRootState } from 'src/state/root';
import { IDispatcher } from 'src/store';
import Negotiating from './deps/Negotiating';
import Connecting from './deps/Connecting';
import ConnectionLost from './deps/ConnectionLost';
import SessionTaken from './deps/SessionTaken';
import Unpairing from './deps/Unpairing';
import Pairing from './deps/Pairing';
import { actions } from './modules';
import GraphicSection from './deps/Pairing/GraphicSection';
import HeaderLogo from 'src/components/HeaderLogo';

type Status =
  | 'negotiating'
  | 'pairing'
  | 'unpairing'
  | 'connecting'
  | 'connectionLost'
  | 'sessionTaken';

export interface Props {
  apiStatus: ApiStatus;
  pairingToken: string;
  sessionActivated: boolean;
  isBindingInfoSet: boolean;
  beginPairing: () => void;
  toggleStayLoggedIn: () => void;
  handleModalButtonClick: () => void;
  location: any;
}

export interface ContentProps extends Props {
  status: Status;
}

export const getStatus = (props: Props): Status => {
  const { apiStatus, pairingToken, sessionActivated } = props;

  switch (apiStatus) {
    case 'disconnected':
    case 'pairing':
      return pairingToken ? 'pairing' : 'negotiating';
    case 'connection-error':
    case 'binding-error':
      return 'connectionLost';
    case 'closing-session-taken':
      return 'sessionTaken';
    case 'unpairing':
      return 'unpairing';
    default:
      return sessionActivated ? 'connecting' : 'sessionTaken';
  }
};

const ConnectionContent = compose(
  withProps((props: Props) => ({ status: getStatus(props) })),
  branch(
    (props: ContentProps) => props.status === 'negotiating',
    renderComponent(Negotiating),
  ),
  branch(
    (props: ContentProps) => props.status === 'pairing',
    renderComponent(Pairing),
  ),
  branch(
    (props: ContentProps) => props.status === 'unpairing',
    renderComponent(Unpairing),
  ),
  branch(
    (props: ContentProps) => props.status === 'connectionLost',
    renderComponent(ConnectionLost),
  ),
  branch(
    (props: ContentProps) => props.status === 'sessionTaken',
    renderComponent(SessionTaken),
  ),
)(Connecting);

const Wrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
`;

const StyledVersion = styled(BuildVersion)`
  align-items: center;
  color: #aaa;
  display: flex;
  font-size: 0.9em;
  padding: 8px 0;
  text-align: center;
  flex: none;
`;

const ConnectionContentWrapper = styled.div`
  min-height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ConnectionHeader = styled.div`
  display: flex;
  width: 100%;
  max-width: 1094px;
  margin: 24px 0;
`;

const HeaderTitle = styled.h1`
  font-size: 24px;
  padding-top: 21px;
  margin-left: 24px;
  color: var(--black);
  font-weight: 600;
`;

export const Connection = (props: Props) => (
  <Wrapper>
    {props.apiStatus !== 'unpairing' && (
      <ConnectionHeader>
        <HeaderLogo id="sudo-beta-logo" />
        <HeaderTitle>MySudo Web Beta</HeaderTitle>
      </ConnectionHeader>
    )}
    <ConnectionContentWrapper>
      <ConnectionContent {...props} />
    </ConnectionContentWrapper>
    {props.apiStatus !== 'unpairing' && <GraphicSection />}
    <StyledVersion />
  </Wrapper>
);

const mapState = (state: IRootState, props: Props) => {
  return {
    apiStatus: state.app.apiStatus,
    pairingToken: state.app.apiPairingToken,
    isStayLoggedIn: state.app.stayLoggedIn,
    sessionActivated: state.app.sessionActivated,
    search: props.location.search
      ? props.location.search
          .slice(1)
          .split('&')
          .reduce((acc: any, pair: any) => {
            const [key, value] = pair.split('=');
            acc[key] = value;
            return acc;
          }, {})
      : {},
  };
};

const mapDispatch = (dispatch: IDispatcher) =>
  bindActionCreators(actions, dispatch);

export default withRouter(connect(
  mapState,
  mapDispatch,
)(Connection) as any) as any;
