import { connect } from 'react-redux';

import SudoSettingsSelectors from 'src/entities/sudo-settings/selectors';
import { IDispatcher } from 'src/store';
import { SearchType, IOwnProps, IStateProps, IActionProps } from './types';
import { IRootState, getList } from 'src/state';
import { listSearch } from 'src/actions/list-search';

export const getListId = (searchType: SearchType, sudoGuid: string) =>
  `search:${searchType}:${sudoGuid}`;

export const mapStateToProps = (
  state: IRootState,
  props: IOwnProps,
): IStateProps => {
  const sudoGuid = SudoSettingsSelectors.getIdBySlug(state, {
    slug: props.sudoSlug,
  });

  const list = getList(state.lists, getListId(props.searchType, sudoGuid));

  return {
    value: (list && list.searchCriteria) || '',
    sudoGuid,
  };
};

export const mapDispatchToProps = (
  dispatch: IDispatcher,
  props: IOwnProps,
): IActionProps => ({
  onChange: (sudoGuid: string, value: string) =>
    dispatch(
      listSearch(
        getListId(props.searchType, sudoGuid),
        value,
        props.searchAction(sudoGuid, value),
      ),
    ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
);
