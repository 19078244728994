import React from 'react';

export default function MessageStatusFailed(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="14"
      height="12"
      viewBox="0 0 14 12"
      {...props}
    >
      <g
        fill="currentColor"
        fillRule="evenodd"
        transform="matrix(1 0 0 -1 2 11)"
      >
        <path d="M5 9.875a4.875 4.875 0 1 1 0-9.75 4.875 4.875 0 0 1 0 9.75zm0-.75a4.125 4.125 0 1 0 0-8.25 4.125 4.125 0 0 0 0 8.25zM5 3a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1zm-.375 1a.375.375 0 0 1 .75 0v3.5a.375.375 0 0 1-.75 0V4z" />
      </g>
    </svg>
  );
}
