import moment from 'moment';
import { MomentInput } from 'moment';

export function formatRelativeDate(date: Date): string {
  const m = moment(date);

  const relativeDate = m.fromNow();
  const time = m.format('LT');

  return `${relativeDate} at ${time}`;
}

export function formatRelativeDateShort(date: Date | number): string {
  return moment(date).calendar(null, {
    sameDay: 'h:mm A',
    nextDay: 'MMM D, YYYY',
    lastDay: '[Yesterday]',
    nextWeek: 'MMM D, YYYY',
    lastWeek: 'dddd',
    sameElse: function sameElse(now: MomentInput) {
      const diffYears = Math.abs(this.diff(now, 'years'));
      if (diffYears > 0) {
        return 'MMM D, YYYY';
      } else {
        return 'MMM D';
      }
    },
  });
}
