import linkifyHtml from 'linkifyjs/html';

/**
 * Helper function that will process the word immediately before
 * the caret and turn it into a link, if it is indeed a link.
 */
export function linkifyAtCaret(editor: CKEDITOR.editor) {
  // Get current selection range
  const selection = editor.getSelection();
  const range = selection.getRanges()[0];
  if (!range) {
    return;
  }

  // If this is a NODE_TEXT element then we can process it for linkification.
  const node = range.startContainer;
  if (node.type !== CKEDITOR.NODE_TEXT) {
    return;
  }

  // We're interested in the word immediately before the caret.
  // So start by getting all the text in the current node that is
  // before the caret.
  const textBeforeCaret = node.getText().substr(0, range.endOffset);

  // Reverse it and then search forwards for the first space-ish character
  // to determine how big the word to linkify is.
  const reversedText = textBeforeCaret
    .split('')
    .reverse()
    .join('');
  const wordBoundaryMatch = reversedText.match(/\s/);
  const wordBoundary =
    (wordBoundaryMatch && wordBoundaryMatch.index) || reversedText.length;

  // Now extract this word and linkify it
  const linkifyStart = textBeforeCaret.length - wordBoundary;
  const linkifyEnd = textBeforeCaret.length;
  const linkifyText = textBeforeCaret.substring(linkifyStart, linkifyEnd);
  const linkified = linkifyHtml(linkifyText, {
    className: '',
    defaultProtocol: 'https',
    target: null,
  });

  // If no change was needed then we are done. The word was not a link.
  if (linkifyText === linkified) {
    return;
  }

  // Select the word
  range.setStart(node, linkifyStart);
  range.setEnd(node, range.endOffset);
  range.select();

  // Insert the linkified replacement, this will contain at least one <a> tag.
  editor.insertHtml(linkified);

  // After insert, the caret might be left inside the <a> tag. In which
  // case we'll select the next node after, so the user can continue
  // typing outside of the link tag.
  const updatedSelection = editor.getSelection();
  const updatedRange = updatedSelection.getRanges()[0];
  if (updatedRange.startContainer.$.nodeName === 'A') {
    const newRange = editor.createRange();
    newRange.setStartAfter(updatedRange.startContainer);
    newRange.setEndAfter(updatedRange.startContainer);
    newRange.select();
  }
}
