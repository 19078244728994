import * as React from 'react';
import { PhoneNumberType } from '../../../../../api';
import LabelledFieldRowsSection from '../Base/LabelledFieldRowsSection';

export const AVAILABLE_PHONE_NUMBER_TYPES: PhoneNumberType[] = [
  'Sudo',
  'Main',
  'Home',
  'Work',
  'Mobile',
  'iPhone',
  'Other',
];

export const PHONE_NUMBER_LABEL_OPTIONS = AVAILABLE_PHONE_NUMBER_TYPES.map(
  t => ({
    label: t,
    description: t,
  }),
);

const PhonesSection = (props: any) => {
  const { fields } = props;
  return (
    <LabelledFieldRowsSection
      fields={fields}
      selectData={(p: any, i: number) => p.phones[i]}
      labelOptions={PHONE_NUMBER_LABEL_OPTIONS}
      placeholder="Enter phone"
      onAddItem={() =>
        fields.push({ label: 'Mobile' as PhoneNumberType, value: '' })
      }
      addItemButtonText="Add Phone"
    />
  );
};

export default PhonesSection;
