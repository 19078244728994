import Analytics from '@aws-amplify/analytics';
import Auth from '@aws-amplify/auth';

import { config } from './config';
import { getCurrentEnvironmentConfig } from './environments';

export interface IAmplifyConfig {
  identityPoolId: string;
  pinPointAppId: string;
  region: string;
}

export async function configure() {
  const { amplifyConfig } = getCurrentEnvironmentConfig();
  if (!amplifyConfig) {
    return;
  }

  const { identityPoolId, pinPointAppId, region } = amplifyConfig;

  Auth.configure({ Auth: { identityPoolId, region } });

  if (config.enableAnalytics) {
    Analytics.configure({
      autoSessionRecord: false,
      AWSPinpoint: {
        appId: pinPointAppId,
        region,
        endpoint: {
          demographic: {
            appVersion: config.version,
          },
        },
      },
    });
  }
}
