import { IContactAvatar } from '../../api';
import createEntitySelectors, {
  EntitiesArraySelector,
} from '../common/createEntitySelectors';
import ENTITY_TYPE from './entityType';
import { createSelector } from 'reselect';

export interface IEntitiesToContactGuidMap {
  [contactGuid: string]: IContactAvatar;
}

const createGetEntitiesToContactGuidMap = (
  getEntitiesArraySelector: EntitiesArraySelector<IContactAvatar>,
) =>
  createSelector(
    getEntitiesArraySelector,
    (entities): IEntitiesToContactGuidMap => {
      const entitiesMap: IEntitiesToContactGuidMap = {};
      if (entities) {
        entities.forEach(avatar => {
          entitiesMap[avatar.id] = avatar;
        });
      }
      return entitiesMap;
    },
  );

export const createContactAvatarSelectors = () => {
  const commonSelectors = createEntitySelectors<IContactAvatar>(ENTITY_TYPE);
  const { getEntitiesArray } = commonSelectors;

  return {
    ...commonSelectors,
    getEntitiesToContactGuidMap: createGetEntitiesToContactGuidMap(
      getEntitiesArray,
    ),
  };
};

const ContactAvatarSelectors = createContactAvatarSelectors();

export default ContactAvatarSelectors;
