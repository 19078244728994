import * as React from 'react';

interface IProps {
  onClickOutside: () => void;
  children: React.ReactNode;
}

class OnClickOutside extends React.Component<IProps> {
  private isTouch: boolean = false;
  private container: HTMLDivElement = null;
  private handleClickOutside = (e: any) => {
    if (e.type === 'touchend') {
      this.isTouch = true;
    }
    if (e.type === 'click' && this.isTouch) {
      return;
    }
    const el = this.container;
    if (el && !el.contains(e.target)) {
      this.props.onClickOutside();
    }
  };
  public componentDidMount() {
    document.addEventListener('touchend', this.handleClickOutside, true);
    document.addEventListener('mousedown', this.handleClickOutside, true);
  }
  public componentWillUnmount() {
    document.removeEventListener('touchend', this.handleClickOutside, true);
    document.removeEventListener('mousedown', this.handleClickOutside, true);
  }
  public render() {
    const { children } = this.props;
    return (
      <div
        ref={(ref: any) => {
          this.container = ref;
        }}
      >
        {children}
      </div>
    );
  }
}

export default OnClickOutside;
