import * as React from 'react';
import styled from 'styled-components';

import { Icon } from '..';

export interface Props {
  id: string;
}

const Logo = styled.div`
  margin-top: 8px;
`;

const HeaderLogo = (props: Props) => (
  <Logo id={props.id}>
    <Icon name="Logo" />
  </Logo>
);

export default HeaderLogo;
