import { ISudoAndSettings } from '../sudo-settings/selectors';

/**
 * Orders primary Sudo on top, followed by the rest ordered by creation date ascending
 */
export const primaryFirstSudoSorter = (
  a: ISudoAndSettings,
  b: ISudoAndSettings,
) => {
  return a.settings.primary !== b.settings.primary
    ? Number(b.settings.primary) - Number(a.settings.primary)
    : a.sudo.created - b.sudo.created;
};
