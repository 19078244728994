import { IGlobalSettings } from '../../api';
import { EntitySelector } from '../common/createEntitySelectors';
import ENTITY_TYPE from './entityType';
import { IRootState } from '../../state';

const createGetSettings = (): EntitySelector<IGlobalSettings> => (
  state: IRootState,
) => {
  return state.entities.get(ENTITY_TYPE).first();
};

const GlobalSettingsSelectors = {
  getSettings: createGetSettings(),
};

export default GlobalSettingsSelectors;
