import React from 'react';

export default function Camera(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <g fill="currentColor" fillRule="evenodd" transform="translate(1 2)">
        <path d="M15.72 2.75H18A3.25 3.25 0 0 1 21.25 6v10A3.25 3.25 0 0 1 18 19.25H4A3.25 3.25 0 0 1 .75 16V6A3.25 3.25 0 0 1 4 2.75h2.28l1.225-1.53A1.25 1.25 0 0 1 8.48.75h5.038c.38 0 .74.173.976.47l1.225 1.53zM11 15.25A4.251 4.251 0 0 0 15.25 11 4.251 4.251 0 0 0 11 6.75 4.251 4.251 0 0 0 6.75 11 4.251 4.251 0 0 0 11 15.25zm0-1.5A2.751 2.751 0 0 1 8.25 11 2.751 2.751 0 0 1 11 8.25 2.751 2.751 0 0 1 13.75 11 2.751 2.751 0 0 1 11 13.75z" />
      </g>
    </svg>
  );
}
