import * as React from 'react';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { SavingModal } from '../../components/saving-modal';
import { ToastNotification } from '../../components/toast-notification';
import SudoSelectSideBar from '../../features/Sudo/SudoSelectSideBar';
import EmailsPage from '../../pages/Emails';
import MessagesPage from '../../pages/Messages';
import ContactsPage from '../../pages/Contacts';
import { IDispatcher } from '../../store';
import * as Actions from '../../actions';

export interface IProps extends RouteComponentProps<{}>, IDispatchFromProps {}

export interface IDispatchFromProps {
  onToggleSudoSidebar: (isOpen: boolean) => void;
}

const Wrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  flex: 1;
`;

class AppPages extends React.Component<IProps> {
  private renderEmailsPage = ({ match }: RouteComponentProps<any>) => {
    return (
      <EmailsPage
        {...match.params}
        baseUrl={`/${match.params.sudoSlug}/emails`}
      />
    );
  };
  private renderSudoSelectSidebar = ({ match }: RouteComponentProps<any>) => {
    return (
      <SudoSelectSideBar
        {...match.params}
        onClick={() => this.props.onToggleSudoSidebar(false)}
      />
    );
  };
  public render() {
    return (
      <Wrapper>
        <ToastNotification />
        <SavingModal />
        <Switch>
          <Route path={`/:sudo/contacts/:tab?`} component={ContactsPage} />
          <Route
            path={`/:sudoSlug/emails/:folderName/:emailMessageId?`}
            render={this.renderEmailsPage}
          />
          <Route path={`/:sudo/messages`} component={MessagesPage} />
          <Redirect from="/:sudo" to="/:sudo/emails/inbox" />
        </Switch>

        <Switch>
          <Route
            path="/:sudoSlug?/:tab?"
            render={this.renderSudoSelectSidebar}
          />
        </Switch>
      </Wrapper>
    );
  }
}

const mapDispatch = (dispatch: IDispatcher): IDispatchFromProps => ({
  onToggleSudoSidebar: (isOpen: boolean) => {
    dispatch(Actions.createSidePanelAction('sudoSelect', isOpen));
  },
});

export default connect(
  null,
  mapDispatch,
)(AppPages);
