import * as React from 'react';
import { pure } from 'recompose';
import MessageBubble from 'src/components/MessageBubble';
import Timer from 'src/components/Timer';
import {
  IWithActionId,
  MessageReceivedRow,
  MessageRowWrapper,
  MessageWrapper,
  ReceivedAvatar,
  RowOptions,
} from '../BaseMessageElements';
import WithLargeEmojiAndLinkify from '../WithLargeEmojiAndLinkify';
import { IAvatarData } from '../../../selectors';

export interface IProps extends IAvatarData, IWithActionId {
  message: string;
  onDeleteMessage: (id: string) => void;
  authorName?: string;
  isExternal?: boolean;
  timeout?: number;
}

export const ReceivedMessage = (props: IProps) => (
  <MessageRowWrapper>
    <MessageReceivedRow>
      <ReceivedAvatar image={props.avatarImage}>
        {props.avatarText}
      </ReceivedAvatar>
      <MessageBubble
        isReceived
        authorName={props.authorName}
        timestamp={
          props.timeout ? (
            <span>
              <Timer value={props.timeout} /> left
            </span>
          ) : null
        }
      >
        {!props.isExternal && (
          <RowOptions
            onDeleteMessage={() => props.onDeleteMessage(props.actionId)}
          />
        )}
        <MessageWrapper>
          <WithLargeEmojiAndLinkify message={props.message} />
        </MessageWrapper>
      </MessageBubble>
    </MessageReceivedRow>
  </MessageRowWrapper>
);

export default pure(ReceivedMessage);
