import { startSession, stopSession } from 'src/analytics';
import { ApiError } from 'src/api';
import { sendMessageToAllTabs, BrowserMessageId } from 'src/browser-message';
import { loadInitialDataAction } from './api-load-initial-data-action';
import { subscribe, unsubscribeAll } from './api-subscribe-action';
import { IThunk } from './actions-base';
import { unpairAction } from './api-unpair-action';
import { activateOwnSession } from './session-activation';
import { readConnectionInfo } from 'src/connection-info';
import { beginPairingAction } from './api-pair-action';

export const KEEPALIVE_TIMEOUT = 60 * 1000 * 4;
export const API_BINDING = 'API_BINDING_ACTION';

export const bindAction = (): IThunk<void> => {
  return async (dispatch, _getState, { api }) => {
    const connectionInfo = readConnectionInfo();
    if (!connectionInfo) {
      dispatch(beginPairingAction());
      return;
    }

    await dispatch(activateOwnSession());

    try {
      // Connect to session service
      await api.connect(connectionInfo.bindingInfo);
    } catch (e) {
      if (e instanceof ApiError) {
        // BIND_CONNECT_ERROR is most likely an "HTTP Authentication failed" errror,
        // we don't get enough info from the websocket to know for sure.
        if (
          e.message === 'BIND_CONNECT_ERROR' ||
          e.message === 'INVALID_BINDING_TOKEN'
        ) {
          // Attempt to re-pair
          return dispatch(unpairAction());
        } else if (e.message === 'BIND_TIMEOUT') {
          // User will be shown the "lost connection" screen
          await stopSession();
          return;
        }
      }

      throw e;
    }

    sendMessageToAllTabs(
      BrowserMessageId.SessionActivated,
      connectionInfo.environment,
    );

    await dispatch(unsubscribeAll());
    await dispatch(subscribe());
    await dispatch(loadInitialDataAction());
    await startSession();
  };
};
