import { Tab, Tabs } from 'src/components';
import * as React from 'react';
import { NavLink, NavLinkProps, RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';

import NavBarSelectSudoButton from '../NavBarSelectSudoButton';
import withNavigationData, {
  IStateFromProps,
  IStateProps,
} from './withNavigationData';
import { LogoutButton } from '../LogoutButton';

interface IProps extends IOwnProps, IStateFromProps, IStateProps {}

export interface IOwnProps {
  onOpenSudoSidebar(): void;
}

const messagesIconSet = {
  outline: 'MessagesOutline',
  fill: 'MessagesFill',
};

const emailsIconSet = {
  outline: 'EmailOutline',
  fill: 'EmailFill',
};

const contactsIconSet = {
  outline: 'ContactsOutline',
  fill: 'ContactsFill',
};

const NavigationWrapper = styled.nav`
  height: calc(var(--theme-spacing) * 8);
  min-height: calc(var(--theme-spacing) * 8);
  display: flex;
  flex-grow: 1;
  align-items: center;
`;

const HStack = styled.div`
  justify-content: flex-end;
  flex-grow: 1;
  display: flex;
  > * {
    padding-left: 16px;
  }
`;

export const Navigation = (props: IProps) => (
  <NavigationWrapper>
    <Tabs>
      <Tab
        iconSet={messagesIconSet}
        activeClassName="isActive"
        count={props.counts.messages}
        component={NavLink}
        to={`/${props.sudoSlug}/messages`}
      >
        Messages
      </Tab>
      <Tab
        iconSet={emailsIconSet}
        activeClassName="isActive"
        count={props.counts.emails}
        component={NavLink}
        to={`/${props.sudoSlug}/emails`}
      >
        Emails
      </Tab>
      <Tab
        iconSet={contactsIconSet}
        activeClassName="isActive"
        component={NavLink}
        to={`/${props.sudoSlug}/contacts`}
      >
        Contacts
      </Tab>
    </Tabs>
    <HStack>
      <NavBarSelectSudoButton
        avatarText={props.sudoAvatarText}
        avatarImage={props.sudoAvatarImage}
        avatarColor={props.sudoAvatarColor}
        sudoLabel={props.sudoLabel}
        onClick={props.onOpenSudoSidebar}
      />
      <LogoutButton />
    </HStack>
  </NavigationWrapper>
);

export default withNavigationData(Navigation);
