import styled, { css } from 'styled-components';

interface IProps {
  isExternal?: boolean;
}

const MessageBubbleBase = styled.div<IProps>`
  background: var(--blue);
  border-radius: 8px;
  border-bottom-right-radius: 0;
  color: var(--white);
  display: inline-block;
  font-size: 17px;
  max-width: 800px;
  padding: 10px;
  position: relative;

  ${props =>
    props.isExternal &&
    css`
      background: var(--green);
    `}
`;

export default MessageBubbleBase;
