const algorithm = 'AES-CBC';

const SawCrypto = {
  /**
   * Converts a buffer to a string by splitting the buffer
   * into 1-byte chunks and converting each byte to a string
   * by applying String.fromCharCode.
   *
   * It seems like we should be able to use TextDecoder here but
   * it doesn't seem to behave the same way as String.fromCharCode.
   *
   */
  bufferToString: (arr: ArrayBuffer): string => {
    // Chunk Size is to prvent a maximum recursion bug
    const CHUNK_SIZE = 0x8000;
    const str = [];
    const buffer = new Uint8Array(arr);
    for (let i = 0; i < buffer.length; i += CHUNK_SIZE) {
      str.push(
        String.fromCharCode.apply(null, buffer.subarray(i, i + CHUNK_SIZE)),
      );
    }
    return str.join('');
  },
  bufferToBase64: (buffer: ArrayBuffer): string => {
    return btoa(SawCrypto.bufferToString(buffer));
  },
  base64ToArrayBuffer: (base64: string): Uint8Array => {
    const decodedData = atob(base64);
    const charCodes = Array.from(decodedData).map(c => c.charCodeAt(0));
    return new Uint8Array(charCodes);
  },
  base64ToKey: (base64: string): Promise<CryptoKey> => {
    return SawCrypto.importKey(SawCrypto.base64ToArrayBuffer(base64));
  },
  generateIv: (): string => {
    const iv = new Uint8Array(16);
    crypto.getRandomValues(iv);
    return SawCrypto.bufferToBase64(iv);
  },

  generateKey: (): Promise<CryptoKey> => {
    return Promise.resolve(
      crypto.subtle.generateKey(
        { name: algorithm, length: 256 },
        true, // extractable
        ['encrypt', 'decrypt'], // allowed operations
      ),
    );
  },
  importKey: (array: Uint8Array): Promise<CryptoKey> => {
    return Promise.resolve(
      crypto.subtle.importKey(
        'raw',
        array,
        {
          name: algorithm,
          length: 256,
        },
        true,
        ['encrypt', 'decrypt'],
      ),
    );
  },
  exportKey: (key: CryptoKey): Promise<string> => {
    return Promise.resolve(
      crypto.subtle
        .exportKey('raw', key)
        .then(exportedKey => SawCrypto.bufferToBase64(exportedKey)),
    );
  },
  encrypt: (
    iv: string,
    symmetricKey: CryptoKey,
    data: string,
  ): Promise<string> => {
    const encodedData = new TextEncoder().encode(data);
    return Promise.resolve(
      crypto.subtle
        .encrypt(
          { name: algorithm, iv: SawCrypto.base64ToArrayBuffer(iv) },
          symmetricKey,
          encodedData,
        )
        .then(encryptedData =>
          SawCrypto.bufferToBase64(new Uint8Array(encryptedData)),
        ),
    );
  },
  decrypt: (
    iv: string,
    symmetricKey: CryptoKey,
    data: string,
  ): Promise<string> => {
    const buffer = SawCrypto.base64ToArrayBuffer(data);
    return Promise.resolve(
      crypto.subtle
        .decrypt(
          { name: algorithm, iv: SawCrypto.base64ToArrayBuffer(iv) },
          symmetricKey,
          buffer,
        )
        .then(decryptedData =>
          new TextDecoder().decode(new Uint8Array(decryptedData)),
        ),
    );
  },
};

export default SawCrypto;
