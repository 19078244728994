import * as React from 'react';
import styled from 'styled-components';
import { environmentNames } from 'src/environments';
import {
  setWebRtcPreference,
  getWebRtcPreference,
} from 'src/client/webrtc-preference';

const Container = styled.div`
  margin: 10px 0;
  text-align: center;
`;

export class WebRtcSelector extends React.Component {
  public render() {
    const show = environmentNames.length > 1;
    if (!show) {
      return null;
    }

    return (
      <Container>
        <label>
          Enable WebRTC:{' '}
          <input
            type="checkbox"
            checked={getWebRtcPreference()}
            onChange={this.inputChanged}
          />
        </label>
      </Container>
    );
  }

  private inputChanged = (ev: React.SyntheticEvent<HTMLInputElement>) => {
    setWebRtcPreference(ev.currentTarget.checked);
    location.href = '/';
  };
}
