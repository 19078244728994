export function parseDataUri(dataUri: string) {
  const preambleEnd = dataUri.indexOf(',');
  if (preambleEnd === -1) {
    return null;
  }

  const preamble = dataUri.substr(0, preambleEnd);
  const data = dataUri.substr(preambleEnd);

  const dataUriRegex = /^(data:)([\-\w\/+]+);(charset=[\w-]+|base64).*/i;
  const match = dataUriRegex.exec(preamble);
  if (!match) {
    return null;
  }

  return {
    scheme: 'data:',
    mediatype: match[2].toLowerCase(),
    data,
  };
}
