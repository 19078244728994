import styled from 'styled-components';
import ModalTitle from '../ModalTitle';
import ModalContent from '../ModalContent';
import ModalActions from '../ModalActions';

const ModalBase = styled.div`
  background: #fff;
  border-radius: 8px;
  width: 375px;
  display: flex;
  align-self: center;
  box-shadow: var(--theme-modal-shadow);
  flex-direction: column;
  padding: calc(var(--theme-spacing) * 3) calc(var(--theme-spacing) * 2);
  & ${ModalTitle} {
    order: 1;
  }
  & ${ModalContent} {
    height: 100%;
    order: 2;
  }
  & ${ModalActions} {
    order: 3;
  }
`;

export default ModalBase;
