import * as Sentry from '@sentry/browser';
import { config } from './config';
import { isObject } from 'lodash';

type RouteScrubDefinition = RouteScrubComponent[];
type RouteScrubComponent = string | undefined;

/**
 * Definitions of what parts of a route are allowed to be preserved after scrubbing.
 */
const routeWhitelist: RouteScrubDefinition[] = [
  [undefined, 'messages', 'new'],
  [undefined, 'messages'],
  [undefined, 'emails'],
  [undefined, 'contacts'],
];

/**
 * Defines which exception fields can be traversed
 */
const extraFieldsWhitelist = [
  'name',
  'message',
  'info', // used in BaseApiError and ApiError
  'innerError', // used in BaseApiError and ApiError
];

/** Replacement for scrubbed data */
export const scrubReplacement = '[SCRUBBED]';

/** Initialize exception reporting */
export function initExceptionReporting() {
  if (config.sentryRavenDsn) {
    Sentry.init({
      dsn: config.sentryRavenDsn,
      release: config.version,
      beforeSend: scrubEventData,
    });
  }
}

/** Scrub event data */
export function scrubEventData<T extends any>(event: T, hint: any): T {
  event.breadcrumbs = [];
  if (event.request && event.request.url) {
    event.request.url = scrubUrl(routeWhitelist, event.request.url);
  }
  if (hint && hint.originalException) {
    event.extra = scrubExtra(hint.originalException);
  } else {
    event.extra = scrubExtra(event.extra);
  }
  return event;
}

/**
 * Returns the url scrubbed by the first suitable scrub definition
 */
function scrubUrl(whiteListRoutes: RouteScrubDefinition[], url: string) {
  const { pathname, origin } = new URL(url);
  const whitelistWithCatchall: RouteScrubDefinition[] = [
    ...whiteListRoutes,
    [], // catchall
  ];

  const routeToTest = pathname.split('/').filter(part => !!part);
  let scrubbedRoute: string[] = [];
  for (const whiteListRoute of whitelistWithCatchall) {
    const result = scrubRoute(whiteListRoute, routeToTest);
    if (result !== null) {
      scrubbedRoute = result;
      break;
    }
  }

  const scrubbedPathname = scrubbedRoute.join('/');
  return origin + '/' + scrubbedPathname;
}

/**
 * Returns `null` if `testRoute` cannot be scrubbed
 * Otherwise returns a the scrubbed route
 */
function scrubRoute(
  scrubDefinition: RouteScrubDefinition,
  testRoute: string[],
): string[] | null {
  if (scrubDefinition.length > testRoute.length) {
    return null;
  }

  const scrubbedRouteComponents: string[] = [];
  for (let i = 0; i < testRoute.length; i++) {
    const routeComponent = testRoute[i];
    const scrubComponent = scrubDefinition[i];

    if (scrubComponent) {
      if (scrubComponent === routeComponent) {
        scrubbedRouteComponents.push(scrubComponent);
      } else {
        return null;
      }
    } else {
      scrubbedRouteComponents.push(scrubReplacement);
    }
  }

  return scrubbedRouteComponents;
}

/**
 * Scrubs a data object so that only objects in the whitelisted
 * fields collection will be mapped.
 */
function scrubExtra(extra: any): any {
  if (isObject(extra)) {
    const mapped: any = {};

    // Object.keys returns empty on Error objects, so ensure we have
    // at least the traversable keys from `extraFeildsWhitelist`.
    const keys = new Set([...Object.keys(extra), ...extraFieldsWhitelist]);
    for (const key of Array.from(keys.values())) {
      const value = (extra as any)[key];

      if (value === undefined) {
        continue;
      }

      if (extraFieldsWhitelist.includes(key)) {
        mapped[key] = scrubExtra(value);
      } else {
        mapped[key] = scrubReplacement;
      }
    }

    return mapped;
  } else {
    return extra && extra.toString();
  }
}
