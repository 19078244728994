import React from 'react';

export default function Delete(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      version="1.1"
      {...props}
    >
      <g fill="currentColor" fillRule="nonzero" transform="translate(3, 1)">
        <path d="M1,2.5 L17,2.5 L16,18.5 C16,20.4329966 14.4329966,22 12.5,22 L5.5,22 C3.56700338,22 2,20.4329966 2,18.5 L1,2.5 Z M6,6 L6,18 L7.5,18 L7.5,6 L6,6 Z M10.5,6 L10.5,18 L12,18 L12,6 L10.5,6 Z M0,2.5 L18,2.5 L18,4 L0,4 L0,2.5 Z M6.5,2.5 L11.5,2.5 L11.5,2 C11.5,1.72385763 11.2761424,1.5 11,1.5 L7,1.5 C6.72385763,1.5 6.5,1.72385763 6.5,2 L6.5,2.5 Z M7,0 L11,0 C12.1045695,-2.22044605e-16 13,0.8954305 13,2 L13,4 L5,4 L5,2 C5,0.8954305 5.8954305,2.22044605e-16 7,0 Z" />
      </g>
    </svg>
  );
}
