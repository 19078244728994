import { IBindingInfo, getBindUrl } from './binding-info';
import {
  SessionServiceClient,
  ISessionServiceClientFactory,
} from './session-service-client';

export interface IUnpairOpts {
  messagePrefix: string;
  sessionServiceClientFactory?: ISessionServiceClientFactory;
}

export async function unpair(bindingInfo: IBindingInfo, opts: IUnpairOpts) {
  opts.sessionServiceClientFactory =
    opts.sessionServiceClientFactory ||
    ((url, clientOpts) => new SessionServiceClient(url, clientOpts));

  const client = opts.sessionServiceClientFactory(getBindUrl(bindingInfo), {
    messagePrefix: opts.messagePrefix,
  });
  try {
    await client.waitForNotificationMessage('bindingNotification');
    await client.sendRequest({
      messageId: client.generateMessageId(),
      type: 'logout',
    });
  } finally {
    client.close();
  }
}
