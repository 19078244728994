import * as React from 'react';
import styled from 'styled-components';
import { SideBar } from 'src/components';

import SudoCard from '../SudoCard';

import withSudoSelectData, { IProps, IOwnProps } from './withSudoSelectData';
import BuildVersion from '../../../components/BuildVersion';

const SideBarStyled = styled(SideBar)`
  top: 0;
`;

export const SideBarContent = styled.div`
  height: 100%;
  overflow-y: auto;
`;

const SudosWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 16px;
`;

const StyledVersion = styled(BuildVersion)`
  position: absolute;
  bottom: 10px;
  left: 10px;
  right: 10px;
  color: #aaa;
  font-size: 0.9em;
  display: block;
  text-align: center;
`;

const SudoSelectSideBar = (props: IProps) => (
  <SideBarStyled isOpen={props.isOpen} onClose={props.onClose}>
    <SideBarContent>
      <SudosWrapper>
        {props.sudoList.map((item, index) => (
          <SudoCard
            key={`${index}${item.sudoSlug}`}
            link={`/${item.sudoSlug}/${props.tab}`}
            onClick={props.onClick}
            isSelected={item.isSelected}
            color={item.sudoColor}
            image={item.sudoAvatar}
            count={item.totalUnread}
            sudoLabel={item.sudoLabel}
            name={item.sudoFullName}
            isPrimary={item.sudoIsPrimary}
            sudoHandle={item.sudoHandle}
            phone={item.phone}
            email={item.email}
          />
        ))}
      </SudosWrapper>
    </SideBarContent>
    <StyledVersion />
  </SideBarStyled>
);

export default withSudoSelectData(SudoSelectSideBar) as React.ComponentClass<
  IOwnProps
>;
