import styled from 'styled-components';

interface IProps {
  children?: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
}

const Badge = styled.div<IProps>`
  backdrop-filter: blur(10px);
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 15.5px;
  color: var(--white);
  display: inline-block;
  font-size: 12px;
  font-weight: 500;
  padding: 4px 12.5px;
`;

export default Badge;
