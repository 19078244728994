import * as React from 'react';
import styled from 'styled-components';

import { config } from 'src/config';
import QRCode from 'src/components/qr-code';
import { Button, Icon } from 'src/components';
import { Modal } from '../../../../components/OModal';
import Manual from './Manual';
import { EnvironmentSelector } from './EnvironmentSelector';
import Toggle from 'src/components/Toggle';
import { WebRtcSelector } from './WebRtcSelector';

export interface Props {
  pairingToken: string;
  isStayLoggedIn: boolean;
  toggleStayLoggedIn: () => void;
  handleModalButtonClick: () => void;
  search: any;
}

const Title = styled.div`
  font-size: 50px;
  font-weight: 600;
  line-height: normal;
  vertical-align: middle;
  margin: 0 0 16px;
  color: #303030;
`;

const Description = styled.p`
  font-size: 19px;
  line-height: 1.47;
  margin-bottom: 24px;
`;

const KeepSignInWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 32px;
`;

const KeepSignInButton = styled(Button)`
  color: #303030;
  margin-left: 16px;
  span {
    font-size: 18px;
  }
`;

const Content = styled.div`
  display: flex;
  padding: 24px 16px;
  justify-content: space-around;
  max-width: 1094px;
  flex-wrap: wrap;
`;

const InstructionContainer = styled.div`
  flex-basis: 435px;
  color: var(--black);
  min-width: 400px;
  margin: 16px;
`;

const StyledModal = styled(Modal)`
  && {
    display: ${props => (props.isOpen ? 'flex' : 'none')};
    flex-direction: column;
    align-items: center;
    max-width: 375px;
    padding: 24px 16px 32px 16px;
    h1 {
      line-height: 22px;
      letter-spacing: -0.2px;
      font-size: 17px;
      font-weight: bold;
      color: #303030;
      margin-bottom: 16px;
    }
    p {
      color: #969ea6;
      font-size: 15px;
      line-height: 21px;
      letter-spacing: -0.2px;
      margin-bottom: 32px;
    }
  }
`;

const KeepSignInLink = styled.a`
  display: inline-block;
  margin-left: 8px;
  color: var(--blue);
  position: relative;
  top: 2px;
`;

const ModalButton = styled(Button)`
  font-size: 15px;
  color: #ffffff;
`;

export default (props: Props) => (
  <Content>
    <div>
      <QRCode value={props.pairingToken} size={353} />
      <EnvironmentSelector />
      {config.newApi && config.enableWebRtc && <WebRtcSelector />}
    </div>
    <InstructionContainer>
      <Title>Stay connected on every device</Title>
      <Description>
        Seamlessly transition from your app to your browser to enjoy the
        convenience of using your Sudos on the web.
      </Description>
      <Manual />
      {config.allowKeepMeSignedIn && (
        <KeepSignInWrapper>
          <Toggle
            checked={props.isStayLoggedIn}
            onChange={props.toggleStayLoggedIn}
          />

          <KeepSignInButton onClick={props.toggleStayLoggedIn}>
            Keep me signed in
          </KeepSignInButton>
          <KeepSignInLink href="https://support.mysudo.com/hc/en-us/articles/360023879854-What-happens-when-I-choose-Keep-Me-Signed-In-">
            <Icon name="Info" />
          </KeepSignInLink>
        </KeepSignInWrapper>
      )}
    </InstructionContainer>
    <StyledModal
      isOpen={props.search && props.search.reason === 'lost-connection'}
    >
      <h1>Lost connection to device</h1>
      <p>
        Looks like MySudo has been closed or your device has been turned off.
        Launch MySudo on your device to reconnect to MySudo for web.
      </p>
      <ModalButton primary onClick={props.handleModalButtonClick}>
        OK
      </ModalButton>
    </StyledModal>
  </Content>
);
