import { delay } from 'src/lib/async';
import { ProtectedTypedEvents } from 'src/lib/events';
import { Logger, createLog, LogLevel } from 'src/lib/logging';
import { IBindingInfo } from './binding-info';
import {
  DataChannelFactory,
  IDataChannel,
  Data,
  DataChannelEventMap,
  ProgressEvent,
  MessageEvent,
} from './data-channel';
import { DispatchQueue, DispatchEvent } from './dispatch-queue';
import { IEntityChange } from './entity-schema';
import { ReadyState, ReadyStateEvent } from './ready-state';
import { SudoClientError, SudoClientErrorCode } from './sudo-client-error';
import {
  SudoRequest,
  SudoResponse,
  IPingRequest,
  ISudoMessage,
  INotifyMessage,
  IAppStatusMessage,
} from './sudo-messages';
import { WakeupHandler } from './wakeup-handler';
import { IPeerDescription } from './peer-description';

/** SudoClient public interface */
export type ISudoClient = PublicOf<SudoClient>;

/** Returns a data channel instance */
export type SudoClientFactory = (opts: IOpts) => ISudoClient;

/** Events raised by SudoClient */
export interface EventMap {
  /** When entities change on the peer. */
  changes: ChangesEvent;

  /** When connection state has changed. */
  readyState: ReadyStateEvent;

  /** When peer has become unavailable. */
  unavailable: UnavailableEvent;

  /** When some change subscriptions must be refreshed.  */
  resubscribe: ResubscribeEvent;

  sessionClosed: SessionClosedEvent;
}

// tslint:disable-next-line:no-empty-interface
export interface UnavailableEvent {}

export interface ChangesEvent {
  date: number;
  changes: IEntityChange[];
}

export interface ResubscribeEvent {
  ids: string[];
}

// tslint:disable-next-line: no-empty-interface
export interface SessionClosedEvent {}

export interface IOpts {
  /** Data Channel factory. Returns an IDataChannel instance.  */
  dataChannelFactory: DataChannelFactory;

  /** Logger level */
  logLevel?: LogLevel;

  /** Maximum amount of requests that can be dispatched at a time. */
  maxPendingRequests?: number;

  /** How often to check for peer availablility */
  peerDetectIntervalMs?: number;

  /** How long to wait for peer ping response */
  peerDetectTimeoutMs?: number;

  /** Automatically send RESET_SEQ request after connect */
  resetSeqOnInit?: boolean;

  /** Detect peer unavailability while requests are pending */
  detectUnavailablePeer?:
    | boolean
    | ((peerDescription: IPeerDescription) => boolean);

  /**
   * Maximum number of keep-awake notifications to send.
   * If Zero, then keepawakes are disabled.
   */
  maxKeepAwakes?: number | ((peerDescription: IPeerDescription) => number);
}

export class SudoClient extends ProtectedTypedEvents<EventMap> {
  /** Data channel used for sending/receiving sudo messages */
  private dataChannel: IDataChannel;

  /** SudoMessage sequence number, increments with each message */
  private seq = 0;

  /** Log */
  private logger: Logger;

  /** Resolves when ready and connected */
  public readonly ready: Promise<void>;

  /** True when peer detection ping is scheduled */
  private isPeerDetectionScheduled = false;

  private detectUnavailablePeerEnabled: boolean;

  private wakeupHandler: WakeupHandler;

  /**
   * Queue of requests that we haven't received responses for yet.
   * This allows us to resend pending requests when resuming
   * connection after it has been unavailable.
   */
  private requestQueue: DispatchQueue<SudoRequest>;

  /** Ready State */
  public get readyState() {
    return this.dataChannel
      ? this.dataChannel.readyState
      : ReadyState.Connecting;
  }

  /** Ctor */
  constructor(bindingInfo: IBindingInfo, private opts: IOpts) {
    super();

    if (opts.detectUnavailablePeer === undefined) {
      opts.detectUnavailablePeer = true;
    }
    if (opts.resetSeqOnInit === undefined) {
      opts.resetSeqOnInit = true;
    }
    if (opts.maxKeepAwakes === undefined) {
      opts.maxKeepAwakes = 20;
    }

    opts.maxPendingRequests = opts.maxPendingRequests || 3;
    opts.peerDetectIntervalMs = opts.peerDetectIntervalMs || 2000;
    opts.peerDetectTimeoutMs = opts.peerDetectTimeoutMs || 2000;

    this.logger = createLog('SudoClient', opts.logLevel);
    this.requestQueue = new DispatchQueue<SudoRequest>(opts.maxPendingRequests);
    this.requestQueue.addListener('dispatch', this.requestQueueDispatch);
    this.ready = this.asyncInit(bindingInfo);
  }

  /** Init routine. Opens a connection to peer. */
  private async asyncInit(bindingInfo: IBindingInfo) {
    let peerDescription: IPeerDescription;

    try {
      this.dataChannel = this.opts.dataChannelFactory({ bindingInfo });
      this.dataChannel.addListener('readyState', this.dataChannelReadyState);
      this.dataChannel.addListener('message', this.dataChannelMessage);
      this.dataChannel.addListener('progress', this.dataChannelProgress);
      this.dataChannel.addListener(
        'sessionClosed',
        this.dataChannelSessionClosed,
      );

      peerDescription = await this.dataChannel.ready;
    } catch (error) {
      throw new SudoClientError(
        SudoClientErrorCode.ConnectionError,
        'Could not connect data channel.',
      );
    }

    this.detectUnavailablePeerEnabled =
      typeof this.opts.detectUnavailablePeer === 'function'
        ? this.opts.detectUnavailablePeer(peerDescription)
        : !!this.opts.detectUnavailablePeer;

    const maxKeepAwakes =
      typeof this.opts.maxKeepAwakes === 'function'
        ? this.opts.maxKeepAwakes(peerDescription)
        : this.opts.maxKeepAwakes;

    if (maxKeepAwakes) {
      console.log('Wakeup handler is enabled'); // tslint:disable-line: no-console

      const { capabilities } = peerDescription;
      this.wakeupHandler = new WakeupHandler({
        keepAwakeMs: capabilities.keepAwakeInterval * 1000,
        maxKeepAwakes,
        simulator: !!peerDescription.environment.find(env =>
          env.name.includes('##simulator'),
        ),
        wakeUp: () => this.dataChannel.wakeUp(),
      });
    }

    if (this.opts.resetSeqOnInit) {
      try {
        await this.sendRequest(this.generateMessageId(), {
          version: 2,
          type: 'REQUEST',
          operation: 'RESET_SEQ',
        });
      } catch (error) {
        throw new SudoClientError(
          SudoClientErrorCode.InitError,
          'Could not reset message sequence.',
        );
      }
    }

    this.emit('readyState', { readyState: ReadyState.Open });
  }

  /** DataChannel `readyState` event handler */
  private dataChannelReadyState = (
    event: DataChannelEventMap['readyState'],
  ) => {
    if (event.readyState === ReadyState.Closed) {
      this.emit('readyState', { readyState: ReadyState.Closed });
    }
  };

  /** DataChannel `message` event handler */
  private dataChannelMessage = (event: MessageEvent) => {
    if (!event.data) {
      return;
    }

    this.logger.debug('MESSAGE', event);

    const message = event.data as ISudoMessage;
    switch (message.type) {
      case 'NOTIFY': {
        const { date, entities } = message as INotifyMessage;
        this.logger.info('<-- NOTIFY', date, entities);
        this.emit('changes', { date, changes: entities });
        break;
      }
      case 'STATUS': {
        const { isAwake, resubscribeIds } = message as IAppStatusMessage;
        if (isAwake !== undefined && this.wakeupHandler) {
          this.wakeupHandler.updateStatus(isAwake);
        }
        if (resubscribeIds) {
          this.emit('resubscribe', { ids: resubscribeIds });
        }
        break;
      }
      default:
    }
  };

  /** DataChannel `progress` event handler */
  private dataChannelProgress = (event: ProgressEvent) => {
    this.logger.debug('PROGRESS', event);
  };

  /** DataChannel `sessionClosed` event */
  private dataChannelSessionClosed = (_event: SessionClosedEvent) => {
    this.emit('sessionClosed', {});
    this.close();
  };

  /** Creates a unique message id */
  public generateMessageId(): string {
    return this.dataChannel.generateMessageId();
  }

  /**
   * Sends a request to peer and returns result.
   * Also ensures that an incrementing `requestSeq` value is assigned
   * to each request.
   * `requestSeq` is used by SessionKit to detect duplicate (retried)
   * requests in order to prevent double-processing.
   */
  public async sendRequest<T extends SudoRequest>(
    id: string,
    request: T,
  ): Promise<SudoResponse<T>> {
    const requestWithSeq = {
      ...request,
      requestSeq: this.seq++,
    };

    let response: SudoResponse<T>;
    try {
      const responsePromise = this.waitForResponse(id);
      this.requestQueue.enqueue(id, requestWithSeq);

      response = await responsePromise;
    } finally {
      this.requestQueue.dequeue(id);
      if (this.wakeupHandler) {
        this.wakeupHandler.endAwakeTasks(id);
      }
    }

    this.logger.info('<-- RECV', response.operation, id, response);

    if (response.status === 'FAIL') {
      throw new SudoClientError(
        SudoClientErrorCode.RequestFail,
        response.failDesc,
        response,
      );
    }

    return response;
  }

  /**
   * Will resend all request that haven't yet been cleared from
   * the request queue. This is needed when trying to resume
   * after 'unavailable' connection has been detected.
   */
  public resendAllPendingRequests() {
    this.requestQueue.retryAll();
  }

  /** RequestQueue readyToSend event handler */
  private requestQueueDispatch = async (event: DispatchEvent<SudoRequest>) => {
    const { payload, id } = event;

    if (this.wakeupHandler) {
      await this.wakeupHandler.beginAwakeTask(id);
    }

    this.logger.info('--> SEND', payload.operation, id, payload);
    this.dataChannel.sendData(id, payload);

    if (this.detectUnavailablePeerEnabled) {
      this.detectUnavailablePeer();
    }
  };

  /**
   * Pings peer while active requests are in the dispatch queue.
   * Once all pending requests have received their response it
   * is okay to stop pinging.
   */
  private async detectUnavailablePeer() {
    if (!this.requestQueue.length || this.isPeerDetectionScheduled) {
      return;
    }

    this.isPeerDetectionScheduled = true;
    const isAlive = await this.pingPeer();

    if (!isAlive) {
      this.isPeerDetectionScheduled = false;
      this.emit('unavailable', {});
    } else {
      await delay(this.opts.peerDetectIntervalMs);
      this.isPeerDetectionScheduled = false;
      this.detectUnavailablePeer();
    }
  }

  /**
   * Attempts to detect whether peer is responding by sending
   * a PING message. If it receives a response from peer,
   * then peer is determined to be alive and will resolve with `true`.
   * Otherwise, if timeout elapses before response is received
   * then resolve with `false`.
   */
  private async pingPeer(): Promise<boolean> {
    const cleanupFns: Array<() => void> = [];

    const id = this.generateMessageId();

    // Ping request does not have `requestSeq` set. This
    // is important because they may arrive in a higher
    // priority than reqular requests.
    const pingRequest: IPingRequest = {
      version: 2,
      type: 'REQUEST',
      operation: 'PING',
    };
    this.dataChannel.sendData(id, pingRequest, {
      priority: 1,
    });

    const detectPromise = new Promise<boolean>(async resolve => {
      this.logger.debug('PINGING');
      await this.waitForResponse(id);
      this.logger.debug('PING OK');
      resolve(true);
    });

    const timeoutPromise = new Promise<boolean>(resolve => {
      const timer = setTimeout(() => {
        this.logger.debug('PING Failed');
        resolve(false);
      }, this.opts.peerDetectTimeoutMs);

      cleanupFns.push(() => {
        clearTimeout(timer);
      });
    });

    try {
      return await Promise.race([detectPromise, timeoutPromise]);
    } finally {
      cleanupFns.forEach(fn => fn());
    }
  }

  /**
   * Resolves with a response message or throws with 'Status Error' or 'Closed'.
   */
  private async waitForResponse(messageId: string) {
    const cleanupFns: Array<() => void> = [];

    // Resolve with a peer message that is in response to the specified messageId
    const responsePromise = new Promise<Data>((resolve, reject) => {
      const listener = this.dataChannel.addListener('message', ev => {
        if (ev.inResponseTo !== messageId) {
          return;
        }

        if (ev.status !== 200) {
          reject(new Error(`Status Error ${ev.status}`));
        } else {
          resolve(ev.data);
        }
      });
      cleanupFns.push(() =>
        this.dataChannel.removeListener('message', listener),
      );
    });

    // Reject if data channel is closed
    const closePromise = new Promise<never>((_resolve, reject) => {
      const listener = this.dataChannel.addListener('readyState', ev => {
        if (ev.readyState === ReadyState.Closed) {
          reject(
            new SudoClientError(SudoClientErrorCode.RequestAborted, 'Closed'),
          );
        }
      });
      cleanupFns.push(() =>
        this.dataChannel.removeListener('readyState', listener),
      );
    });

    try {
      return await Promise.race([responsePromise, closePromise]);
    } finally {
      cleanupFns.forEach(fn => fn());
    }
  }

  /** Closes connection to peer */
  public async close(): Promise<void> {
    this.emit('readyState', { readyState: ReadyState.Closed });
    this.requestQueue.removeListener('dispatch', this.requestQueueDispatch);
    this.dataChannel.removeListener('readyState', this.dataChannelReadyState);
    this.dataChannel.removeListener('message', this.dataChannelMessage);
    this.dataChannel.removeListener('progress', this.dataChannelProgress);
    this.dataChannel.close();
  }
}
