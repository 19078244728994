import uuid from 'uuid';
import { retryOnTimeout } from '../async';
import { getBrowser } from 'src/utils';
import {
  SessionServiceClient,
  ISessionServiceClient,
  ISessionServiceClientFactory,
} from './session-service-client';
import { IRegisterRequest } from './session-service-messages';
import { generateSymmetricKey, exportKey } from './crypto';
import { IBindingInfo } from './binding-info';
import { ReadyState } from './ready-state';

const pairingEndpointPath = '/session/v[VERSION]/slave';
const bindingEndpointPath = '/session/v[VERSION]/bind';
const registerTokenMagicString = 'Sudo';
const registerTokenFormatVersion = 1;

export interface IPairingOpts {
  appName: string;
  clientName: string;
  clientVersion: string;
  refreshMs: number;
  pairingHost: string;
  protocolVersion: 2 | 3;
  sessionServiceClientFactory?: ISessionServiceClientFactory;
  onQrCode: (qrCode: string | null) => void;
}

/**
 * Attempt to pair, refreshing on an interval.
 */
export async function pair(opts: IPairingOpts): Promise<IBindingInfo> {
  opts.sessionServiceClientFactory =
    opts.sessionServiceClientFactory ||
    ((url, clientOpts) => new SessionServiceClient(url, clientOpts));

  let client: ISessionServiceClient | null = null;

  return retryOnTimeout(
    opts.refreshMs,
    async () => {
      client = await openClient(opts);
      return attemptPair(opts, client);
    },
    () => {
      opts.onQrCode(null);
      if (client) {
        client.close();
        client = null;
      }
    },
  );
}

/**
 * Creates a SessionService client to use for a pairing attempt
 */
async function openClient(opts: IPairingOpts) {
  const pairingUrl =
    opts.pairingHost +
    pairingEndpointPath.replace('[VERSION]', opts.protocolVersion.toString());

  const client = opts.sessionServiceClientFactory(pairingUrl, {
    messagePrefix: 'MSW',
  });

  await client.ready;
  return client;
}

/**
 * Attempts to pair.
 */
async function attemptPair(
  opts: IPairingOpts,
  client: ISessionServiceClient,
): Promise<IBindingInfo> {
  const symmetricKey = await generateSymmetricKey();
  const symmetricKeyData = await exportKey(symmetricKey);

  const [connectionHost, bindNotification] = await Promise.all([
    announce(client, symmetricKeyData, opts),
    client.waitForNotificationMessage('bindingNotification'),
  ]);

  const connectionUrl =
    connectionHost +
    bindingEndpointPath.replace('[VERSION]', opts.protocolVersion.toString());

  return {
    bindingToken: bindNotification.token,
    connectionUrl,
    iceConfig: [],
    symmetricKey: symmetricKeyData,
    version: '1',
  };
}

/**
 * Sends a register request to session service to initiate pairing.
 * Then emits the qrCode on `onQrCode` callback.
 */
async function announce(
  client: ISessionServiceClient,
  secret: string,
  opts: IPairingOpts,
) {
  const registrationToken = generateRegistrationToken(
    opts.protocolVersion,
    opts.appName,
  );

  const browserInfo = getBrowser();
  const registerRequest: IRegisterRequest = {
    type: 'register',
    messageId: client.generateMessageId(),
    token: registrationToken,
    description: {
      name: opts.clientName,
      version: opts.clientVersion,
      environment: [
        {
          type: 'browser',
          name: browserInfo.name,
          version: browserInfo.version,
        },
      ],
    },
  };

  const { connectionUrl } = await client.sendRequest(registerRequest);
  const qrCode = generateQrCode(registrationToken, secret, connectionUrl);
  if (client.readyState === ReadyState.Open) {
    opts.onQrCode(qrCode);
  }
  return connectionUrl;
}

/**
 * Generates a registration token
 */
export function generateRegistrationToken(
  version: 2 | 3,
  appName: string,
): string {
  const parts = [
    registerTokenMagicString,
    registerTokenFormatVersion,
    appName,
    version.toString(),
    uuid.v4(),
  ];

  return parts.join(':');
}

/**
 * QrCode contains pairing code + symmetricKey + bindUrl
 */
export function generateQrCode(
  pairingRequestToken: string,
  symmetricKey: string,
  connectionUrl: string,
) {
  return [pairingRequestToken, symmetricKey, connectionUrl].join(':');
}
