import { IAction } from './actions/actions-base';

export type State = any;

// tslint:disable-next-line:no-empty-interface
export interface Action extends IAction {}

export type Reducer = (state: State, action?: Action) => State;

export interface ActionTypeReducers {
  [actionType: string]: Reducer;
}

export interface Actions {
  [actionName: string]: (...args: any[]) => any;
}

export const reduceActions = (
  actionTypeReducers: ActionTypeReducers,
  initialState?: State,
): Reducer => {
  const reduceAction = (state = initialState, action: Action): State => {
    const actionTypeReducer: Reducer = actionTypeReducers[action.type];
    return actionTypeReducer ? actionTypeReducer(state, action) : state;
  };
  return reduceAction;
};
