import { equals } from './predicates';
import { IApi, IEntity } from 'src/api';

const findById = async <ResultType extends IEntity>(
  api: IApi,
  entity: string,
  id: string,
  options: any = {},
) => {
  const entities = await api.search(
    { [entity]: equals('id', id) },
    options.pagination,
    options.meta,
  );
  return entities[0] as ResultType;
};

export { findById };
