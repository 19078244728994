import * as React from 'react';
import styled from 'styled-components';

import { SectionWrapper, Section } from '../Base/BaseSectionElements';
import FieldInput from '../Base/FieldInput';

const FieldLabel = styled.div`
  text-transform: uppercase;
  color: #8c949c;
  font-size: 13px;
  line-height: 20px;
  font-weight: bold;
  padding-left: 5px;
  margin-top: calc(var(--theme-spacing) * 2);
  :first-child {
    margin-top: initial;
  }
`;

const StyledSectionWrapper = styled(SectionWrapper)`
  margin-top: 0;
`;

const NameCompanySection = (props: any) => {
  const { first, last, company } = props;
  return (
    <StyledSectionWrapper>
      <Section>
        <FieldLabel>First Name</FieldLabel>
        <FieldInput {...first.input} placeholder="First Name" />

        <FieldLabel>Last Name</FieldLabel>
        <FieldInput {...last.input} placeholder="Last Name" />

        <FieldLabel>Company</FieldLabel>
        <FieldInput {...company.input} placeholder="Company" />
      </Section>
    </StyledSectionWrapper>
  );
};

export default NameCompanySection;
