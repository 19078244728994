import uuid from 'uuid';
import { ProtectedTypedEvents } from 'src/lib/events';
import * as Data from './data-converters';
import {
  IRelayMessage,
  RequestMap,
  SessionServiceRequest,
  ISessionServiceMessage,
  SessionServiceNotifications,
  IStatusMessage,
  IPingMessage,
} from './session-service-messages';
import { ReadyState } from './ready-state';
import { createLog, Logger, LogLevel } from '../logging';
import { SessionServiceStatusCode } from './session-service-status-code';

/** SessionServiceClient public interface */
export type ISessionServiceClient = PublicOf<SessionServiceClient>;

/** SessionServiceClient constructor interface */
export type ISessionServiceClientFactory = (
  url: string,
  opts: ISessionServiceClientOptions,
) => ISessionServiceClient;

/** SessionServiceClient Event Map */
export interface EventMap {
  readyState: { readyState: ReadyState };
  error: { error: any };
  message: { message: ISessionServiceMessage };
}

export interface ISessionServiceClientOptions {
  /** Prefix used to identify message source */
  messagePrefix: string;

  /** Logging level */
  logLevel?: LogLevel;

  /** Alternative WebSocket implementation */
  WebSocket?: ConstructorOf<typeof WebSocket>;

  /**
   * If > 0, then a ping message will be sent to the session service
   * every <n> ms to prevent it from closing the connection.
   */
  keepAliveIntervalMs?: number;
}

/**
 * SessionServiceClient is used to communicate with the Anonyome Session
 * Service via web socket.
 * https://wiki.tools.anonyome.com/index.php/SudoCard_-_Desktop
 */
export class SessionServiceClient extends ProtectedTypedEvents<EventMap> {
  /** Resolves when connected */
  public readonly ready: Promise<void>;

  /**
   * The websocket used to communicate with Session Service.
   * Only instantiated while a connection is active.
   */
  private webSocket: WebSocket | null = null;

  /**
   * SessionServiceClient status
   */
  public readyState = ReadyState.Closed;

  /**
   * Session Service url
   */
  public readonly url: string;

  /** Log */
  private logger: Logger;

  /** Ping interval timer for keeping the websocket connection alive */
  private pingInterval: number | undefined;

  /**
   * Opens a connection to Session Service
   */
  constructor(url: string, private opts: ISessionServiceClientOptions) {
    super();

    if (opts.keepAliveIntervalMs === undefined) {
      opts.keepAliveIntervalMs = 4 * 60 * 1000; // 4 minutes
    }

    const WebSocketCtor = opts.WebSocket || WebSocket;

    this.logger = createLog('SessionServiceClient', opts.logLevel);
    this.url = url;
    this.webSocket = new WebSocketCtor(url);
    this.webSocket.binaryType = 'arraybuffer';
    this.webSocket.onclose = this.webSocketClose;
    this.webSocket.onerror = this.webSocketError;
    this.webSocket.onmessage = this.webSocketMessage;
    this.webSocket.onopen = this.webSocketOpen;
    this.setReadyState(ReadyState.Connecting);

    this.ready = this.asyncInit();
  }

  /** Async initialization */
  private async asyncInit() {
    await this.waitForEventOrClose(
      'readyState',
      ({ readyState }) => readyState === ReadyState.Open,
    );

    this.refreshPingInterval();
  }

  /** Sets and emits ready state */
  private setReadyState(readyState: ReadyState) {
    this.readyState = readyState;
    this.emit('readyState', { readyState });
  }

  /**
   * Returns a promise that either resolves when an event is
   * observed for the desired condition, or throws if connection
   * is closed before it can resolve.
   */
  private async waitForEventOrClose<TEvent extends keyof EventMap>(
    name: TEvent,
    callback?: (event: EventMap[TEvent]) => boolean,
  ): Promise<EventMap[TEvent]> {
    const cleanupFns: Array<() => void> = [];

    // Resolve with desired event
    const resultPromise = new Promise<EventMap[TEvent]>(resolve => {
      const listener = this.addListener(name, ev => {
        if (callback(ev)) {
          resolve(ev);
        }
      });
      cleanupFns.push(() => this.removeListener(name, listener));
    });

    // Reject if web socket is closed
    const closePromise = new Promise<never>((_resolve, reject) => {
      const listener = this.addListener('readyState', ev => {
        if (ev.readyState === ReadyState.Closed) {
          reject(new Error('Closed'));
        }
      });
      cleanupFns.push(() => this.removeListener('readyState', listener));
    });

    try {
      return await Promise.race([resultPromise, closePromise]);
    } finally {
      cleanupFns.forEach(fn => fn());
    }
  }

  /**
   * Waits for a message of a certain type.
   */
  public async waitForNotificationMessage<
    T extends SessionServiceNotifications,
    K extends T['type']
  >(type: K) {
    const { message } = await this.waitForEventOrClose(
      'message',
      ev => ev.message.type === type,
    );
    return message as DiscriminateUnion<SessionServiceNotifications, 'type', K>;
  }

  /**
   * Generates a message ID
   */
  public generateMessageId() {
    return this.opts.messagePrefix + uuid.v4();
  }

  /**
   * Sends a message to Session Service.
   */
  public send<T extends ISessionServiceMessage>(message: T) {
    if (this.readyState !== ReadyState.Open) {
      throw new Error('Not ready');
    }

    const data = encodeMessage(message);
    this.webSocket.send(data);
    this.logger.debug('--> Sent', message);

    this.refreshPingInterval();
  }

  /**
   * Sends a message to that Session Service should
   * relay to the peer.
   */
  public sendRelayMessage(
    payload: any,
    messageId?: string,
    inResponseTo?: string,
    sequence?: string,
  ) {
    if (this.readyState !== ReadyState.Open) {
      throw new Error('Not ready');
    }

    const relayMessage: IRelayMessage = {
      type: 'message',
      messageId: messageId || this.generateMessageId(),
      inResponseTo,
      sequence,
      message: payload,
    };
    this.send(relayMessage);
  }

  /**
   * Sends a request that the Session Service will respond to
   */
  public async sendRequest<T extends SessionServiceRequest>(message: T) {
    if (this.readyState !== ReadyState.Open) {
      throw new Error('Not ready');
    }

    this.send(message);
    const { message: response } = await this.waitForEventOrClose(
      'message',
      ev => ev.message.inResponseTo === message.messageId,
    );

    if (response.type === 'status') {
      const status = response as IStatusMessage;
      if (status.status !== SessionServiceStatusCode.Success) {
        throw new Error(`Status error: ${status.status}`);
      }
    }

    return response as RequestMap[T['type']];
  }

  /**
   * Clears and resets the ping interval timer used for keeping the service
   * alive. This is called when sending and receiving data so that ping
   * messages should only be sent while the connection is idle.
   */
  private refreshPingInterval() {
    this.logger.debug('Reset Ping');

    clearInterval(this.pingInterval);

    if (this.opts.keepAliveIntervalMs) {
      this.pingInterval = window.setInterval(
        this.onPingIntervalElapsed,
        this.opts.keepAliveIntervalMs,
      );
    }
  }

  /**
   * Ping interval callback.
   * Sends a ping message to session service to keep it awake.
   */
  private onPingIntervalElapsed = () => {
    const pingMessage: IPingMessage = {
      type: 'ping',
      messageId: this.generateMessageId(),
    };

    this.logger.info('Ping');
    this.send(pingMessage);
  };

  /** WebSocket close handler */
  private webSocketClose = (_event: CloseEvent) => {
    this.close();
  };

  /** WebSocket open handler */
  private webSocketOpen = () => {
    this.setReadyState(ReadyState.Open);
  };

  /** WebSocket error handler */
  private webSocketError = (event: ErrorEvent) => {
    if (this.listenersCount('error')) {
      this.emit('error', { error: event.error });
    }

    this.setReadyState(ReadyState.Closed);
    this.close();
  };

  /** WebSocket message handler */
  private webSocketMessage = (event: MessageEvent) => {
    const binaryData: ArrayBuffer = event.data;
    const message = decodeMessage(binaryData);

    this.logger.debug('<-- Received', message);
    this.emit('message', { message });

    this.refreshPingInterval();
  };

  /**
   * Closes the connection to Session Service
   */
  public close() {
    if (this.readyState === ReadyState.Closed) {
      return;
    }

    clearInterval(this.pingInterval);
    this.setReadyState(ReadyState.Closed);
    this.webSocket.onopen = null;
    this.webSocket.onmessage = null;
    this.webSocket.onerror = null;
    this.webSocket.onclose = null;
    this.webSocket.close();
  }
}

/**
 * Encodes an JS object for sending to Session Service
 */
export function encodeMessage(message: ISessionServiceMessage): ArrayBuffer {
  const json = JSON.stringify(message);
  return Data.stringToArrayBuffer_utf8(json);
}

/**
 * Decodes Session Service message into JS object
 */
export function decodeMessage(binary: ArrayBuffer): ISessionServiceMessage {
  const json = Data.arrayBufferToString_utf8(binary);
  return JSON.parse(json);
}
