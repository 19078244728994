import React from 'react';

export default function Email(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      {...props}
    >
      <g fill="currentColor" fillRule="evenodd">
        <path d="M21 7.286v-.589C21 5.506 19.863 4.5 18.417 4.5H5.083C3.637 4.5 2.5 5.506 2.5 6.697v.59l8.244 4.121a2.25 2.25 0 0 0 2.012 0L21 7.286zm0 1.678l-7.573 3.786a3.75 3.75 0 0 1-3.354 0L2.5 8.964v7.839C2.5 17.994 3.637 19 5.083 19h13.334C19.863 19 21 17.994 21 16.803v-7.84zM5.083 3h13.334C20.653 3 22.5 4.633 22.5 6.697v10.106c0 2.064-1.847 3.697-4.083 3.697H5.083C2.847 20.5 1 18.867 1 16.803V6.697C1 4.633 2.847 3 5.083 3z" />
      </g>
    </svg>
  );
}
