import * as React from 'react';
import { find } from 'lodash';
import { Avatar } from 'src/components';

import { getAvatarText } from '../utils';

interface IProps {
  value: string;
  contact: any;
}

const ContactListItem = (props: IProps) => {
  const { value, contact } = props;
  const { avatar, first, last } = contact || {
    avatar: null,
    first: null,
    last: null,
  };
  return (
    <React.Fragment>
      <div className="avatar">
        <Avatar image={avatar}>{!avatar && getAvatarText([contact])}</Avatar>
      </div>
      <div className="name-and-email">
        <div className="name">
          {first || last ? (
            <span>
              {first}&nbsp;<b>{last}</b>
            </span>
          ) : (
            <span>
              <b>{value}</b>
            </span>
          )}
        </div>
        <div className="email">{value}</div>
      </div>
    </React.Fragment>
  );
};

export default ContactListItem;
