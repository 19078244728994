import styled from 'styled-components';
import ButtonBase from '../ButtonBase';

const ButtonPrimary = styled(ButtonBase)`
  color: var(--white);
  background: var(--blue);
  border-radius: 16px;
  padding: 8px 24px;
`;

ButtonPrimary.displayName = 'ButtonPrimary';

export default ButtonPrimary;
