import * as React from 'react';
import styled from 'styled-components';

import { IProps, IEmptyStates } from './types';

const Bold = styled.b`
  font-weight: 800;
`;

const EMPTY_STATES: IEmptyStates = {
  inbox: {
    title: "You're all caught up!",
    content: 'Select a thread to reply or read previous emails.',
  },
  sent: {
    title: 'Look at you being all social.',
    content: 'Select a thread to reply or read previous emails.',
  },
  drafts: {
    title: (props: IProps) => (
      <React.Fragment>
        You have{' '}
        <Bold>
          {props.totalCount} draft email{props.totalCount > 1 && 's'}
        </Bold>
      </React.Fragment>
    ),
    content: 'Select a thread to finish composing your email.',
  },
  trash: {
    title: 'Its getting a little messy.',
    content: 'Go ahead and delete some emails.',
  },
};

export default EMPTY_STATES;
