import React from 'react';
import styled from 'styled-components';
import noop from 'lodash/noop';

import Icon from 'src/components/Icon';

export interface IProps {
  value: boolean;
  onChange: (value: boolean) => void;
  onClick?: (event: React.SyntheticEvent) => void;
  isIndeterminate?: boolean;
  inputRef?:
    | React.RefObject<HTMLInputElement>
    | ((instance: HTMLElement) => void);
}

const Label = styled.label<{ value: boolean }>`
  position: relative;
  width: 14px;
  height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(${props => (props.value ? '--blue' : '--gray-5')});
  cursor: pointer;
  border-radius: 50%;
  color: var(${props => (props.value ? '--white' : '--blue')});
  background-color: var(${props => (props.value ? '--blue' : '--white')});
`;

const Input = styled.input`
  display: none;
`;

const CheckBoxIcon = styled(Icon)`
  border-radius: 50%;
  width: inherit;
  height: inherit;
`;

const CheckBox = ({
  inputRef,
  isIndeterminate,
  onChange,
  value,
  ...rest
}: IProps) => (
  <Label value={value} {...rest}>
    <Input
      type="checkbox"
      checked={value}
      onChange={() => onChange(!value)}
      ref={inputRef}
    />
    {value && <CheckBoxIcon name="CheckMark" />}
    {!value && isIndeterminate && <CheckBoxIcon name="CheckMark" />}
  </Label>
);

CheckBox.defaultProps = {
  inputRef: noop,
  isIndeterminate: false,
};

CheckBox.displayName = 'CheckBox';

export default CheckBox;
