import styled, { css } from 'styled-components';

interface IProps {
  children?: React.ReactNode;
  isVertical?: boolean;
  style?: React.CSSProperties;
  className?: string;
}

const ModalActions = styled.div<IProps>`
  display: flex;
  justify-content: space-around;
  margin-top: calc(var(--theme-spacing) * 4);
  ${props =>
    props.isVertical &&
    css`
      flex-direction: column;
      align-items: center;
      text-align: center;
    `}
`;

ModalActions.defaultProps = {
  isVertical: false,
};

export default ModalActions;
