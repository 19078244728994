import { captureException } from '@sentry/browser';
import { IDispatcher } from '../../store';
import { reduceActions, Action } from '../../reduce-actions';
import { ApiError } from 'src/api';
import { Middleware } from 'redux';
import {
  SudoClientError,
  SudoClientErrorCode,
} from 'src/lib/sudo-client/sudo-client-error';

const ERROR_TRAPPED = 'ERROR_TRAPPED_ACTION';
const CLEAR_TRAPPED_ERROR = 'CLEAR_TRAPPED_ERROR_ACTION';

export const trapError = (error: Error): Action => {
  return { type: ERROR_TRAPPED, payload: error, error: true };
};
export const clearTrappedError = (): Action => {
  return { type: CLEAR_TRAPPED_ERROR };
};

export const errorTrap: Middleware = _api => next => async action => {
  try {
    return await next(action);
  } catch (error) {
    if (error instanceof ApiError) {
      const apiError = error as ApiError;

      // When a session is taken (i.e. SessionService has indicated 552
      // and subsequently terminated the connection) then any pending
      // request will time out. In such a case we want to ignore those errors.
      if (
        apiError.message === 'SEND_FAILED' &&
        (apiError.info || {}).status === 'closing-session-taken'
      ) {
        return;
      }

      // If we receive a request aborted error it will mean we are in the
      // process of shutting down.
      if (
        apiError.message === 'SEND_FAILED' &&
        apiError.innerError instanceof SudoClientError &&
        apiError.innerError.code === SudoClientErrorCode.RequestAborted
      ) {
        return;
      }
    }
    next(trapError(error));
    captureException(error);
    // tslint:disable-next-line:no-console
    console.error('Error captured', error, { ...error, stack: error.stack });
    throw error;
  }
};

export interface IPartialState {
  error: Error;
}
const initialState: IPartialState = {
  error: null,
};
export const errorTrapReducer = reduceActions(
  {
    [ERROR_TRAPPED]: (
      state: IPartialState,
      { payload }: Action,
    ): IPartialState => {
      return { error: payload };
    },
    [CLEAR_TRAPPED_ERROR]: (state: IPartialState): IPartialState => {
      return {
        error: null,
      };
    },
  },
  initialState,
);
