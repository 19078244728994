import { createSelector, Selector } from 'reselect';

import {
  IGlobalSettings,
  IPhoneAccount,
  ISudoSettings,
  ISudoAvatar,
  ISudo,
} from '../../api';
import { IRootState } from '../../state';
import createEntitySelectors, {
  EntitiesArraySelector,
  EntitySelector,
} from '../common/createEntitySelectors';
import ENTITY_TYPE from './entityType';
import SudoAvatarSelectors from '../sudo-avatar/selectors';
import GlobalSettingsSelectors from '../global-settings/selectors';
import PhoneAccountSelectors from '../phone-account/selectors';
import SudoSelectors from '../sudo/selectors';
import { primaryFirstSudoSorter } from './sorters';

export interface ISlugsToIdsMap {
  [slug: string]: string;
}

export interface IWithSlugProp {
  slug: string;
}

export interface ISudoAndAvatar {
  sudo: ISudoSettings;
  avatar: ISudoAvatar;
}

export interface ISudoAndSettings {
  sudo: ISudo;
  settings: ISudoSettings;
}

export const cleanSlug = (slug: string): string => {
  const cleaned = slug
    .toLowerCase()
    .replace(/\s/g, '_') // Replace spaces with underscores
    .replace(/\W/g, '') // Remove any non word character
    .replace(/^\_+|\_+$/g, ''); // Trim underscores

  // A slug might only contain invalid characters.  Return a default if that is the case.
  return cleaned || 'my_sudo';
};

const createGetSlugsToIds = (
  getEntitiesArraySelector: EntitiesArraySelector<ISudoSettings>,
) =>
  createSelector(
    getEntitiesArraySelector,
    (sudosArray: ISudoSettings[]) => {
      const slugMap: ISlugsToIdsMap = {};
      const sorted = sudosArray
        .filter(settings => settings.parent.type === 'Sudo')
        .sort((a, b) => a.created - b.created);

      sorted.forEach((settings: ISudoSettings) => {
        const initialLabel = (settings.role
          ? settings.role
          : 'Unnamed Sudo'
        ).toLowerCase();
        let label = initialLabel;
        let i = 2;
        while (slugMap[cleanSlug(label)] && i < 1000) {
          label = `${initialLabel} ${i++}`;
        }
        slugMap[cleanSlug(label)] = settings.parent.id;
      });

      return slugMap;
    },
  );

const createGetIdBySlug = (
  getSlugsToIdsSelector: Selector<IRootState, ISlugsToIdsMap>,
) =>
  createSelector(
    getSlugsToIdsSelector,
    (_, { slug }: IWithSlugProp) => slug,
    (slugMap: ISlugsToIdsMap, slug: string): string => {
      return (slugMap && slugMap[slug]) || null;
    },
  );

const createGetEntityBySlug = (
  getEntitiesArraySelector: EntitiesArraySelector<ISudoSettings>,
  getIdBySlugSelector: Selector<IRootState, string>,
) =>
  createSelector(
    getEntitiesArraySelector,
    getIdBySlugSelector,
    (sudosArray: ISudoSettings[], sudoId: string) => {
      return (
        (sudoId &&
          sudosArray &&
          sudosArray.find(settings => settings.parent.id === sudoId)) ||
        null
      );
    },
  );

const createGetEntityWithAvatarBySudoId = (
  getEntitiesBySudoId: EntitiesArraySelector<ISudoSettings>,
  getAvatarsArraySelector: EntitiesArraySelector<ISudoAvatar>,
) =>
  createSelector(
    getEntitiesBySudoId,
    getAvatarsArraySelector,
    (foundSettings, avatars): ISudoAndAvatar => {
      const settings = foundSettings[0];
      const filterAvatars = (avatar: ISudoAvatar) => {
        const aParent = avatar.parent;
        const sParent = settings.parent;
        return (
          aParent.id === sParent.id &&
          aParent.type === 'Sudo' &&
          sParent.type === 'Sudo'
        );
      };
      return {
        sudo: settings || null,
        avatar:
          (settings &&
            avatars &&
            avatars
              .sort((a, b) => b.created - a.created)
              .find(filterAvatars)) ||
          null,
      };
    },
  );

const createGetPhoneRegionById = (
  getPhoneAccountsBySudoIdSelector: EntitiesArraySelector<IPhoneAccount>,
  getSettingsSelector: EntitySelector<IGlobalSettings>,
) =>
  createSelector(
    getPhoneAccountsBySudoIdSelector,
    getSettingsSelector,
    (phoneAccounts, settings) => {
      const firstPhoneAccount = phoneAccounts[0];
      return firstPhoneAccount
        ? firstPhoneAccount.countryCode
        : settings.countryCode;
    },
  );

export const createGetDeviceRegion = (
  getSettingsSelector: EntitySelector<IGlobalSettings>,
) =>
  createSelector(
    getSettingsSelector,
    settings => settings.countryCode,
  );

export const createGetSudoSettingsBySudoId = (
  getSudoSettingsSelector: Selector<IRootState, ISudoSettings[]>,
) =>
  createSelector(
    getSudoSettingsSelector,
    sudoSettingsArray => {
      return sudoSettingsArray.reduce(
        (acc, curr) => {
          acc[curr.parent.id] = curr;
          return acc;
        },
        {} as { [key: string]: ISudoSettings },
      );
    },
  );

export const createGetSudoAndSettings = (
  getSudoSettingsBySudoId: Selector<
    IRootState,
    { [key: string]: ISudoSettings }
  >,
): Selector<IRootState, ISudoAndSettings[]> =>
  createSelector(
    SudoSelectors.getEntitiesArray,
    getSudoSettingsBySudoId,
    (sudosArray, sudoSettingsBySudoId) =>
      sudosArray.reduce((accumulator, sudo) => {
        const settings = sudoSettingsBySudoId[sudo.id];
        if (!settings) {
          return accumulator;
        }
        return [...accumulator, { sudo, settings }];
      }, []),
  );

export const createGetSortedSudoAndSettings = (
  getSudoAndSettings: Selector<IRootState, ISudoAndSettings[]>,
): Selector<IRootState, ISudoAndSettings[]> =>
  createSelector(
    getSudoAndSettings,
    sudoAndSettings => {
      return sudoAndSettings.sort(primaryFirstSudoSorter);
    },
  );

export const createSudoSettingsSelectors = () => {
  const commonSelectors = createEntitySelectors<ISudoSettings>(ENTITY_TYPE);
  const { getEntitiesArray, getEntitiesBySudoId } = commonSelectors;
  const { getEntitiesArray: getAvatarsArray } = SudoAvatarSelectors;
  const { getSettings } = GlobalSettingsSelectors;
  const {
    getEntitiesBySudoId: getPhoneAccountsBySudoId,
  } = PhoneAccountSelectors;

  const getSlugsToIds = createGetSlugsToIds(getEntitiesArray);
  const getIdBySlug = createGetIdBySlug(getSlugsToIds);
  const getSudoSettingsBySudoId = createGetSudoSettingsBySudoId(
    getEntitiesArray,
  );
  const getSudoAndSettings = createGetSudoAndSettings(getSudoSettingsBySudoId);
  const getSortedSudoAndSettings = createGetSortedSudoAndSettings(
    getSudoAndSettings,
  );

  return {
    ...commonSelectors,
    getDeviceRegion: createGetDeviceRegion(getSettings),
    getEntityBySlug: createGetEntityBySlug(getEntitiesArray, getIdBySlug),
    getEntityWithAvatarBySudoId: createGetEntityWithAvatarBySudoId(
      getEntitiesBySudoId,
      getAvatarsArray,
    ),
    getIdBySlug,
    getPhoneRegionById: createGetPhoneRegionById(
      getPhoneAccountsBySudoId,
      getSettings,
    ),
    getSlugsToIds,
    getSudoSettingsBySudoId,
    getSortedSudoAndSettings,
  };
};

const SudoSettingsSelectors = createSudoSettingsSelectors();

export default SudoSettingsSelectors;
