import { IAction } from './actions-base';

export const MESSAGES_SEARCH = 'MESSAGES_SEARCH_ACTION';

export interface IMessagesSearchAction extends IAction {
  type: typeof MESSAGES_SEARCH;
  payload: {
    query: string;
  };
}
