import { IAction } from './actions-base';

export const APP_DATA_LOADED = 'APP_DATA_LOADED_ACTION';

export function appDataLoadedAction(): IAction {
  return {
    type: APP_DATA_LOADED,
    payload: true,
  };
}
