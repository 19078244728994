// Shims:
// tslint:disable:no-var-requires
import 'core-js/shim';
if (!crypto.subtle) {
  require('webcrypto-shim');
}
if (typeof (window as any).TextEncoder === 'undefined') {
  (window as any).TextEncoder = require('text-encoding').TextEncoder;
}
if (typeof (window as any).TextDecoder === 'undefined') {
  (window as any).TextDecoder = require('text-encoding').TextDecoder;
}
// tslint:enable:no-var-requires

import * as moment from 'moment';
import * as React from 'react';
import { render } from 'react-dom';
import * as compareVersions from 'compare-versions';
import 'wicg-focus-ring';

import * as Actions from './actions';
import * as Amplify from './amplify';
import Root from './root';
import configureStore from './store';
import { Api, BaseApi, IApi } from './api';
import {
  subscribeToBrowserMessages,
  BrowserMessageId,
} from './browser-message';
import { config } from './config';
import { getBrowser } from './utils';
import { initExceptionReporting } from './exception-reporting';
import { readConnectionInfo, writeConnectionInfo } from './connection-info';
import { MySudoClient } from './client/mysudo-client';

// configure moment with en-* locales
import 'moment/locale/en-au';
import 'moment/locale/en-ca';
import 'moment/locale/en-gb';
import 'moment/locale/en-ie';
import 'moment/locale/en-nz';
import { SudoClient } from './lib/sudo-client/sudo-client';
import { WebRtcDataChannel } from './lib/sudo-client/webrtc-data-channel';
import { DataChannelFactory } from './lib/sudo-client/data-channel';
import { SessionServiceDataChannel } from './lib/sudo-client/session-service-data-channel';
import { isWebRtcConnectionUrl } from './lib/sudo-client/binding-info';
import { IPeerDescription } from './lib/sudo-client/peer-description';

moment.locale(window.navigator.language);
moment.relativeTimeThreshold('s', 0);

// AWS Amplify
Amplify.configure();

// error reporting
initExceptionReporting();

// ----------------------------------------------------------------------------
// Configure core app objects

const mySudoVoipPushDisableVersion = '1.2.0';
const isV3Supported = false; // DetectRTC.isWebRTCSupported;
const browserInfo = getBrowser();

let api: IApi;
let baseApi: BaseApi;

if (config.newApi) {
  const webRtcFactory: DataChannelFactory = opts => {
    return new WebRtcDataChannel({
      logLevel: 'debug',
      ...opts,
      messagePrefix: config.apiMessagePrefix,
    });
  };
  const sessionServiceDataChannelFactory: DataChannelFactory = opts => {
    return new SessionServiceDataChannel({
      ...opts,
      messagePrefix: config.apiMessagePrefix,
    });
  };
  const getMaxKeepAwakes = (peerDescription: IPeerDescription) => {
    const isVoipPushAvailable = compareVersions.compare(
      peerDescription.version,
      mySudoVoipPushDisableVersion,
      '<',
    );
    return isVoipPushAvailable ? 20 : 0;
  };
  const getDetectUnavailablePeer = (peerDescription: IPeerDescription) => {
    const isVoipPushAvailable = compareVersions.compare(
      peerDescription.version,
      mySudoVoipPushDisableVersion,
      '<',
    );
    return !isVoipPushAvailable;
  };

  api = new MySudoClient({
    sudoClientFactory: bindingInfo => {
      const isWebRtc = isWebRtcConnectionUrl(bindingInfo);

      return new SudoClient(bindingInfo, {
        maxKeepAwakes: isWebRtc ? 0 : getMaxKeepAwakes,
        detectUnavailablePeer: getDetectUnavailablePeer,
        logLevel: 'info',
        dataChannelFactory: isWebRtc
          ? webRtcFactory
          : sessionServiceDataChannelFactory,
      });
    },
  });
} else {
  baseApi = new BaseApi({
    loggingEnabled: config.enableApiLogging,
  });
  api = new Api({
    isV3Supported,
    baseApi,
    apiClientDescription: {
      name: 'MySudo for web',
      version: config.version,
      environment: [
        {
          type: 'browser',
          name: browserInfo.name,
          version: browserInfo.version,
        },
      ],
    },
  });
}

const store = configureStore(api);

// Configure api status change handler
api.addListener('status', ev => {
  store.dispatch(Actions.statusAction(ev.status));
});

// Process entity changes
api.addListener('entityChanges', ({ changes }) => {
  changes.forEach(change => {
    if (config.logEntityNotifications) {
      // tslint:disable-next-line:no-console
      console.log(change.change, change.entity.type, change.entity);
    }

    store.dispatch(Actions.notifyEntity(change));
  });
});

// Refresh subscriptions when necessary
api.addListener('subscriptionsExpired', ({ resubscribeIds }) => {
  store.dispatch(Actions.resubscribe(resubscribeIds));
});

// Refresh binding tokens
api.addListener('refreshToken', ({ newToken }) => {
  const connectionInfo = readConnectionInfo();
  if (!connectionInfo) {
    return;
  }
  connectionInfo.bindingInfo.bindingToken = newToken;
  writeConnectionInfo(connectionInfo);
});

if (config.logTransferProgress && baseApi) {
  baseApi.progress$.subscribe(progress => {
    if (progress.current % 25 === 0 || progress.current === progress.total) {
      // tslint:disable-next-line:no-console
      console.log(progress);
    }
  });
}

// Handle session activation messages from other tabs
subscribeToBrowserMessages(msg => {
  switch (msg.type) {
    case BrowserMessageId.SessionActivated:
      store.dispatch(Actions.handleOtherSessionActivated(msg.data));
      break;
    case BrowserMessageId.UnpairAll:
      store.dispatch(Actions.unpairAction());
      break;
    default:
  }
});

window.addEventListener('beforeunload', e => {
  const isSaving = store.getState().app.entitiesInFlight.size > 0 ? true : null;
  if (isSaving) {
    e.returnValue = isSaving;
  }
  return isSaving;
});

// Disable the Backspace button to prevent the browser from navigating back.
window.addEventListener('keydown', e => {
  const target = e.target as HTMLElement;
  if (
    e.key === 'Backspace' &&
    !['INPUT', 'TEXTAREA'].includes(target.tagName)
  ) {
    e.preventDefault();
  }
});

render(<Root store={store} />, document.getElementById('mount'));
