import React from 'react';

export default function UnknownFile(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <g fill="currentColor" fillRule="evenodd" transform="translate(3 1)">
        <path d="M11 0v6.5h7v12.152C18 20.502 16.433 22 14.5 22h-11C1.567 22 0 20.501 0 18.652V3.348C0 1.498 1.567 0 3.5 0H11zm1 .28l5.456 5.22H12V.28zM6.21 11.918h1.588c.035-.662.48-1.09 1.154-1.09.668 0 1.114.404 1.114.961 0 .557-.229.844-.985 1.295-.808.475-1.13 1.002-1.054 1.922l.011.34h1.553v-.31c0-.575.217-.862.996-1.313.826-.487 1.254-1.102 1.254-1.987 0-1.336-1.107-2.267-2.783-2.267-1.799 0-2.818 1.008-2.848 2.449zm2.625 6.228c.533 0 .961-.404.961-.92 0-.515-.428-.92-.96-.92-.528 0-.956.405-.956.92 0 .516.428.92.955.92z" />
      </g>
    </svg>
  );
}
