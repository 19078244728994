import * as React from 'react';

interface ChildrenParams {
  onToggle: () => void;
}

export interface Props {
  id: number | string;
  toggle: (id: number | string) => void;
  children: (childrenParams: ChildrenParams) => React.ReactNode;
}

class WithSelection extends React.Component<Props> {
  public handleToggleSelection = () => {
    const { toggle, id } = this.props;
    toggle(id);
  };

  public render() {
    return this.props.children({
      onToggle: this.handleToggleSelection,
    });
  }
}

export default WithSelection;
