import { IApi } from '../api';
import { IRootState } from '../state';
import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';

export type IThunk<R = void> = ThunkAction<
  Promise<R>,
  IRootState,
  IThunkExtra,
  IAction
>;

export interface IThunkExtra {
  api: IApi;
}

export interface IAction extends AnyAction {
  type: string;
  payload?: any;
  meta?: any;
  error?: boolean;
}

export const nullAction: IAction = {
  type: 'NULL',
};

export function createAction<T>(type: string): (payload: T) => IAction {
  return (payload: T) => ({ type, payload });
}
