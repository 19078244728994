import * as React from 'react';
import styled from 'styled-components';
import { values } from 'lodash';
import { pure } from 'recompose';
import escapeStringRegexp from 'escape-string-regexp';
import allEmojiData from 'emoji-mart/data/all.json';
import { WithLinkify } from '../BaseMessageElements';

interface IProps {
  message: string;
}

const LargeEmojiWrapper = styled.div`
  font-size: 60px;
  line-height: 70px;
  margin-bottom: -8px;
`;

/**
 * Not exported by 'emoji-mart'.
 */
const unifiedToNative = (unified: string): string => {
  const unicodes = unified.split('-');
  const codePoints = unicodes.map(u => `0x${u}`);

  return String.fromCodePoint.apply(null, codePoints);
};

const constructEmojiList = (emojiData: any) => {
  const unifiedKey = emojiData.compressed ? 'b' : 'unified';
  const result: string[] = [];
  values(emojiData.emojis).forEach(data => {
    if (data[unifiedKey]) {
      result.push(unifiedToNative(data[unifiedKey]));
    }
    if (data.skin_variations) {
      const variations = values(data.skin_variations)
        .filter(skinData => skinData.unified)
        .map(skinData => unifiedToNative(skinData.unified));
      result.push(...variations);
    }
  });
  return result;
};

const createEmojiHelper = () => {
  let regexp: RegExp;

  const checkIsOnlyEmoji = (message: string) => {
    if (!regexp) {
      const emojiList = constructEmojiList(allEmojiData);

      /**
       * Some emojis in the list contain additional modifier symbols like skin color
       * and are based on other emojis.
       * They're sorted by length so that regexp alternation construct becomes greedy
       * and tries to find emojis with modifiers first.
       */
      const escapedList = emojiList
        .sort((a, b) => b.length - a.length)
        .map(s => escapeStringRegexp(s))
        .join('|');
      regexp = new RegExp(escapedList, 'gu');
    }
    return message.replace(regexp, '').length === 0;
  };

  return {
    checkIsOnlyEmoji,
  };
};

const EmojiHelper = createEmojiHelper();

const WithLargeEmojiAndLinkify = (props: IProps) => {
  const isOnlyEmoji = EmojiHelper.checkIsOnlyEmoji(props.message);
  return isOnlyEmoji ? (
    <LargeEmojiWrapper>{props.message}</LargeEmojiWrapper>
  ) : (
    <WithLinkify>{props.message}</WithLinkify>
  );
};

export default pure(WithLargeEmojiAndLinkify as any) as React.ComponentClass<
  IProps
>;
