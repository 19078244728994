/* tslint:disable max-line-length */
import * as React from 'react';

export default function Menu(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      {...props}
    >
      <g fill="#303030" fillRule="evenodd" transform="translate(2 5)">
        <path d="M19.5 1.5H.5c-.276 0-.5-.336-.5-.75S.224 0 .5 0h19c.276 0 .5.336.5.75s-.224.75-.5.75zm0 6.25H.5C.224 7.75 0 7.414 0 7s.224-.75.5-.75h19c.276 0 .5.336.5.75s-.224.75-.5.75zm0 6.25H.5c-.276 0-.5-.336-.5-.75s.224-.75.5-.75h19c.276 0 .5.336.5.75s-.224.75-.5.75z" />
      </g>
    </svg>
  );
}
