import { noop } from 'lodash';
import * as React from 'react';
import styled from 'styled-components';
import Attachment from '../Attachment';
import HandleKeyOnSelection from './HandleKeyOnSelection';

const StyledHandleKeyOnSelection = styled(HandleKeyOnSelection)`
  margin-right: 10px;
  border-radius: 3px;
  cursor: pointer;
`;

interface IProps {
  attachment: any;

  onRemove: () => void;
  onDragStart?: (e: any) => void;
  onDoubleClick?: (e?: any) => void;
}

interface IState {
  isSelected: boolean;
}

const AttachmentWrapper = styled.div`
  margin-bottom: 8px;
`;

class EmailAttachment extends React.Component<IProps, IState> {
  private handleDragStart = (e: any) => {
    const { onDragStart } = this.props;
    if (onDragStart) {
      onDragStart(e);
    }
  };
  private handleKeyDown = (e: any) => {
    if (e.key === 'Backspace') {
      this.props.onRemove();
    }
  };
  public render() {
    const { attachment, onDoubleClick } = this.props;
    return (
      <AttachmentWrapper
        draggable
        onDragStart={this.handleDragStart}
        onDoubleClick={onDoubleClick || noop}
      >
        <StyledHandleKeyOnSelection onKeyDown={this.handleKeyDown}>
          <Attachment attachment={attachment} />
        </StyledHandleKeyOnSelection>
      </AttachmentWrapper>
    );
  }
}

export default EmailAttachment;
