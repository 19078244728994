export const WINDOW_FOCUS = 'WINDOW_FOCUS' as const;

export type IWindowFocusAction = ReturnType<typeof appWindowFocusAction>;

export function appWindowFocusAction(isWindowFocused: boolean) {
  return {
    type: WINDOW_FOCUS,
    payload: { isWindowFocused },
  };
}
