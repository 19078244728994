import React from 'react';
// tslint:disable:max-line-length

export default function EmailOpen(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <g fill="currentColor" fillRule="evenodd" transform="translate(1 1)">
        <path
          id="a"
          d="M2.335 5.25L10.763.624a.994.994 0 0 1 .975.01l7.997 4.603a4.033 4.033 0 0 1 2.015 3.497v8.74c0 2.224-1.79 4.027-4 4.027H4.25c-2.21 0-4-1.803-4-4.028V8.787c0-1.474.8-2.83 2.085-3.536zm.718 1.327a2.52 2.52 0 0 0-1.303 2.21v8.685c0 1.39 1.12 2.518 2.5 2.518h13.5c1.38 0 2.5-1.128 2.5-2.518v-8.74a2.52 2.52 0 0 0-1.26-2.185l-7.754-4.463-8.183 4.493zm.218.525l7.95-4.23 7.528 4.24a1 1 0 0 1 .07 1.7l-7.053 4.768a1 1 0 0 1-1.098.014L3.203 8.827a1 1 0 0 1 .068-1.725z"
        />
      </g>
    </svg>
  );
}
