import { makeTypedFactory, TypedRecord } from 'typed-immutable-record';

// ----------------------------------------------------------------------------
// SidePanel

export type SidePanelStoreNames =
  | 'notifications'
  | 'messageDetails'
  | 'sudoSelect';

export interface ISidePanelEntry {
  isOpen: boolean;
}

export interface ISidePanelProps {
  notifications: ISidePanelEntry;
  messageDetails: ISidePanelEntry;
  sudoSelect: ISidePanelEntry;
}

export interface ISidePanel extends TypedRecord<ISidePanel>, ISidePanelProps {}

export const SidePanel = makeTypedFactory<ISidePanelProps, ISidePanel>({
  notifications: { isOpen: false },
  messageDetails: { isOpen: false },
  sudoSelect: { isOpen: false },
});
