import { Map } from 'immutable';
import { makeTypedFactory, TypedRecord } from 'typed-immutable-record';
import { ApiStatus, IEntity } from '../api';

export type ToastNotificationType = 'info' | 'success' | 'warning' | 'error';

export interface IToastNotification {
  message: string;
  title: string;
  type: ToastNotificationType;
}

interface IProps {
  apiIsPendingRetry: boolean;
  apiPairingToken: string;
  apiStatus: ApiStatus;
  entitiesInFlight: Map<string, IEntity>;
  isInitialDataLoaded: boolean;
  isWindowFocused: boolean;
  lastEntityModified: number;
  locationPathname: string;
  locationSearch: string;
  notification: IToastNotification;
  sessionActivated: boolean;
  stayLoggedIn: boolean;
}

export interface IApp extends TypedRecord<IApp>, IProps {}

export const App = makeTypedFactory<Partial<IProps>, IApp>({
  apiStatus: 'disconnected',
  apiIsPendingRetry: false,
  apiPairingToken: null,
  entitiesInFlight: Map<string, IEntity>(),
  isWindowFocused: true,
  sessionActivated: false,
  isInitialDataLoaded: false,
  lastEntityModified: 0,
  locationPathname: '',
  locationSearch: '',
  notification: {
    title: '',
    message: '',
    type: null,
  },
  stayLoggedIn: false,
});
