import React from 'react';

export default function CheckMark(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={20}
      height={20}
      viewBox="0 0 20 20"
      {...props}
    >
      <g fill="currentColor" fillRule="evenodd" transform="translate(3 5)">
        <path d="M2 4.5L.5 6l4 4 9-8.5L12 0 4.5 7z" />
      </g>
    </svg>
  );
}
