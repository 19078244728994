import { get, invert } from 'lodash';
import { createSelector } from 'reselect';

import {
  DEFAULT_SUDO_COLOR,
  IEmailAccount,
  IGlobalSettings,
  IPhoneAccount,
  ISudoSettings,
  ISudoAvatar,
  ISudoCounts,
} from '../../../api';
import { IRootState } from '../../../state';
import { formatNumberForDisplay, getTotalUnreadCount } from '../../../utils';
import SudoCountsSelectors from '../../../entities/sudo-counts/selectors';
import GlobalSettingsSelectors from '../../../entities/global-settings/selectors';
import EmailAccountSelectors from '../../../entities/email-account/selectors';
import SudoAvatarSelectors from '../../../entities/sudo-avatar/selectors';
import SudoSettingsSelectors, {
  ISlugsToIdsMap,
  ISudoAndSettings,
} from '../../../entities/sudo-settings/selectors';
import { primaryFirstSudoSorter } from '../../../entities/sudo-settings/sorters';
import PhoneAccountSelectors from '../../../entities/phone-account/selectors';
import SudoAvatarEmpty from '../../../icons/sudo-avatar-empty';
export const sideBarStateSelector = (state: IRootState): boolean =>
  state.sidePanel.sudoSelect.isOpen;

export interface ISudoList {
  isSelected: boolean;
  totalUnread: number;
  phone: string;
  email: string;
  sudoSlug: string;
  sudoColor: string;
  sudoAvatar: string;
  sudoLabel: string;
  sudoFullName: string;
  sudoIsPrimary: boolean;
  sudoHandle: string;
}

// requires props: { slug: string }
export const sudoListSelector = createSelector(
  SudoSettingsSelectors.getIdBySlug,
  SudoSettingsSelectors.getSortedSudoAndSettings,
  SudoCountsSelectors.getEntitiesArray,
  PhoneAccountSelectors.getEntitiesArray,
  GlobalSettingsSelectors.getSettings,
  EmailAccountSelectors.getEntitiesArray,
  SudoSettingsSelectors.getSlugsToIds,
  SudoAvatarSelectors.getEntitiesArray,
  (
    sudoId: string,
    sudoAndSettingsArray: ISudoAndSettings[],
    sudoCountsArray: ISudoCounts[],
    phoneAccounts: IPhoneAccount[],
    globalSettings: IGlobalSettings,
    emailAccountsArray: IEmailAccount[],
    sudoSlugsMap: ISlugsToIdsMap,
    sudoAvatarsArray: ISudoAvatar[],
  ): ISudoList[] => {
    const sudoIdsToSlugsMap = invert(sudoSlugsMap);

    return sudoAndSettingsArray.map(({ settings }) => {
      const counts = sudoCountsArray.find(
        item => item.id === settings.parent.id,
      );
      const defaultPhoneAccount = phoneAccounts.find(
        item => item.parent.id === settings.parent.id,
      );
      const phone = defaultPhoneAccount
        ? formatNumberForDisplay(
            globalSettings.countryCode,
            defaultPhoneAccount.number,
          )
        : '';
      const emailAccount = emailAccountsArray.find(
        item => item.parent.id === settings.parent.id,
      );
      const sudoSlug = sudoIdsToSlugsMap[settings.parent.id];
      const avatar = sudoAvatarsArray
        .sort((a, b) => b.created - a.created)
        .find(item => item.parent.id === settings.parent.id);

      return {
        isSelected: settings.parent.id === sudoId,
        totalUnread: counts ? getTotalUnreadCount(counts) : 0,
        phone,
        email: get(emailAccount, 'address', '') as string,
        sudoSlug,
        sudoColor: DEFAULT_SUDO_COLOR,
        sudoAvatar: (avatar && avatar.avatarImage) || SudoAvatarEmpty,
        sudoLabel: settings.role,
        sudoFullName: `${settings.firstName} ${settings.lastName}`,
        sudoIsPrimary: settings.primary,
        sudoHandle: '',
      };
    });
  },
);
