import React from 'react';
import styled from 'styled-components';
import range from 'lodash/range';

import ListItemLoading from '../ListItemLoading';

interface IProps {
  checkBoxPosition?: string;
  isWithAvatar?: boolean;
  isWithCheckBox?: boolean;
  isWithSecondaryText?: boolean;
  loadingItemsCount?: number;
}

const StyledListLoading = styled.ul`
  position: relative;
  list-style: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
`;

const StyledListLoadingOverlay = styled.div`
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0), var(--gray-9));
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`;

const ListLoading = (props: IProps) => (
  <StyledListLoading>
    {range(props.loadingItemsCount).map(item => (
      <ListItemLoading
        key={item}
        isWithAvatar={props.isWithAvatar}
        isWithSecondaryText={props.isWithSecondaryText}
        isWithCheckBox={props.isWithCheckBox}
        checkBoxPosition={props.checkBoxPosition}
      />
    ))}
    <StyledListLoadingOverlay />
  </StyledListLoading>
);

ListLoading.defaultProps = {
  checkBoxPosition: 'left',
  loadingItemsCount: 0,
};

ListLoading.displayName = 'ListLoading';

export default ListLoading;
