import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchAvatarsById } from 'src/actions';

import { IListState, IRootState, AvatarType } from '../../../state';
import { getConversationList } from '../../../selectors';
import SudoSettingsSelectors from '../../../entities/sudo-settings/selectors';
import { IContact, IMessageConversation } from '../../../api';
import { IDispatcher } from '../../../store';
import * as Actions from '../../../actions';
import { getContactNameOrData } from '../../Contacts/selectors';
import {
  getConversationListData,
  getMessagesContacts,
  getFirstActiveMember,
  getIsMessageListEmptyBySudoId,
  IConversationListData,
} from '../selectors';
import { formatNumberForDisplay } from '../../../utils';
import { IThunk } from '../../../actions';
import PhoneAccountSelectors from '../../../entities/phone-account/selectors';

export interface IStateFromProps {
  sudoGuid: string;
  conversations: IConversationListData[];
  noMoreConversations: boolean;
  isFetching: boolean;
  isEmpty: boolean;
  contacts: IContact[];
}

export interface IStateProps {
  sudoSlug: string;
}

export interface IDispatchFromProps {
  loadMoreConversations: (sudoId: string) => void;
  fetchAvatarsById: (type: AvatarType, ids: string[]) => void;
}

export const getContactName = (
  conversation: IMessageConversation,
  contact: IContact,
  countryCode: string,
) => {
  if (contact) {
    const displayNameParts = getContactNameOrData(countryCode, contact);
    return [displayNameParts.secondary, displayNameParts.primary]
      .filter((part: string) => part)
      .join(' ')
      .trim();
  } else {
    const member = getFirstActiveMember(conversation);
    if (member) {
      return member.aliasType === 'phone'
        ? formatNumberForDisplay(countryCode, member.alias)
        : member.alias;
    } else {
      return '';
    }
  }
};

export const getParticipants = (
  conversation: IMessageConversation,
  contact: IContact,
  countryCode: string,
) => {
  const displayName = getContactName(conversation, contact, countryCode);
  const activeMembers = conversation.remoteParticipants.filter(m => m.active);
  if (activeMembers.length > 1) {
    const numberOfOtherParticipants = activeMembers.length - 1;
    return `${displayName} +${numberOfOtherParticipants}`;
  } else if (!displayName) {
    return 'Empty Group Chat';
  }
  return displayName;
};

export const getConversationName = (
  conversation: IMessageConversation,
  contact: IContact,
  countryCode: string,
) => {
  if (conversation.title) {
    return conversation.title;
  }
  // If no conversation title, display conversation contact or member alias (with ' +[n]' participants if group chat)
  return getParticipants(conversation, contact, countryCode);
};

export const mapState = (
  state: IRootState,
  props: IStateProps,
): IStateFromProps => {
  const sudoGuid = SudoSettingsSelectors.getIdBySlug(state, {
    slug: props.sudoSlug,
  });
  const conversations = getConversationListData(state, { sudoGuid });
  const isListEmpty = getIsMessageListEmptyBySudoId(state, { sudoGuid });
  const phoneAccount = PhoneAccountSelectors.getEntitiesBySudoId(state, {
    sudoGuid,
  });
  const contacts = getMessagesContacts(state, { sudoGuid });
  let listState = {} as IListState;

  if (phoneAccount[0]) {
    listState = getConversationList(state.lists, phoneAccount[0]);
  }

  return {
    sudoGuid,
    conversations,
    contacts,
    noMoreConversations: listState.noMoreItems,
    isFetching: !!listState.fetchRef,
    isEmpty: isListEmpty,
  };
};

export const loadMoreConversations = (sudoId: string): IThunk<void> => {
  return async (dispatch, getState, app) => {
    const state = getState();
    const phoneAccount = PhoneAccountSelectors.getEntitiesBySudoId(state, {
      sudoGuid: sudoId,
    });
    if (phoneAccount[0]) {
      dispatch(Actions.fetchConversations(phoneAccount[0]));
    }
  };
};

export const mapDispatch = (dispatch: IDispatcher): IDispatchFromProps => ({
  ...bindActionCreators({ loadMoreConversations, fetchAvatarsById }, dispatch),
});

export default connect(
  mapState,
  mapDispatch,
);
