import * as React from 'react';
import styled from 'styled-components';
import { Button, Icon } from 'src/components';
import { Link, RouteComponentProps, Redirect } from 'react-router-dom';

import DetailsHeader, { HeaderAvatar } from '../DetailsHeader';
import ContactForm from './ContactForm';
import withContactEditorData, {
  IDispatchFromProps,
  IHandlerProps,
  IStateFromProps,
} from './withContactEditorData';
import WithFormUpdate from './WithFormUpdate';
import { gridContentChildHack } from 'src/utils/grid';

export interface IMatch {
  sudo: string;
  tab: string;
  id?: string;
}

export interface IProps
  extends IStateFromProps,
    IDispatchFromProps,
    IHandlerProps,
    RouteComponentProps<IMatch> {}

const EditorWrapper = styled.div`
  ${gridContentChildHack};
  flex-grow: 1;
  overflow-y: auto;
  background-color: var(--gray-8);
`;

const HeaderToolbar = styled.div`
  display: flex;
`;

const CancelWrapper = styled.div`
  flex-grow: 1;
`;

const AddPhotoWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 54px;
  color: var(--blue);
  button span div {
    font-size: 17px;
  }
`;

const StyledSaveButton = styled(Button)`
  color: var(--blue);
  font-size: 17px;
  span {
    font-size: 17px;
    font-weight: 400;
  }
  &:disabled span {
    color: var(--gray-5);
    cursor: auto;
  }
`;

const StyledCancelLink = styled(Link)`
  font-size: 17px;
  line-height: 21px;
  text-decoration: none;
  color: var(--blue);
  font-weight: 400;
`;

export const getGuidPathPart = (id: string) => (id ? `/${id}` : '');

export const ContactEditor = (props: IProps) => {
  const {
    redirectToContactGuid,
    handleSaveClick,
    formIsValid,
    avatarImage,
    avatarRef,
    handleSubmit,
    handleFormReset,
    setRedirectToContactGuid,
    setAvatarRef,
    handleDeleteContact,
  } = props;
  const sudo = props.match.params.sudo;
  const tab = props.match.params.tab;
  let id = props.match.params.id;

  const cancelLinkPath = getGuidPathPart(id);
  id = id && decodeURIComponent(id);

  return redirectToContactGuid ? (
    <Redirect
      to={`/${sudo}/contacts/${tab}/${encodeURIComponent(
        redirectToContactGuid,
      )}`}
    />
  ) : (
    <WithFormUpdate contactGuid={id} onFormReset={handleFormReset}>
      <EditorWrapper>
        <DetailsHeader
          overlayToolbar={
            <HeaderToolbar>
              <CancelWrapper>
                <StyledCancelLink
                  to={`/${sudo}/contacts/${tab}${cancelLinkPath}`}
                >
                  Cancel
                </StyledCancelLink>
              </CancelWrapper>
              <StyledSaveButton
                onClick={handleSaveClick}
                disabled={!formIsValid}
              >
                Save
              </StyledSaveButton>
            </HeaderToolbar>
          }
        >
          <HeaderAvatar image={avatarImage} />
          <AddPhotoWrapper>
            <Button onClick={() => avatarRef && avatarRef.click()}>
              <Icon name="Camera" width="32" height="32" />
              <div>Add Photo</div>
            </Button>
          </AddPhotoWrapper>
        </DetailsHeader>

        <ContactForm
          sudo={sudo}
          contactGuid={id}
          onSubmit={(data: any) => {
            handleSubmit(sudo, data, (contactGuid: string) => {
              setRedirectToContactGuid(contactGuid);
            });
          }}
          avatarRef={(el: any) => setAvatarRef(el)}
          handleDeleteContact={handleDeleteContact}
        />
      </EditorWrapper>
    </WithFormUpdate>
  );
};

export default withContactEditorData(
  ContactEditor as any,
) as React.ComponentClass<RouteComponentProps<IMatch>>;
