import * as React from 'react';
import ErrorSnackbar from '../../../features/Error/ErrorSnackbar';
import withMessagesErrorsData, {
  IDispatchFromProps,
  IStateFromProps,
} from './withMessagesErrorsData';

export interface IProps extends IStateFromProps, IDispatchFromProps {}

export const MessagesErrors = (props: IProps) => {
  return (
    !!props.error && (
      <ErrorSnackbar dismiss={props.closeLastError}>
        {props.error}
      </ErrorSnackbar>
    )
  );
};

export default withMessagesErrorsData(MessagesErrors);
