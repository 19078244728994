import { Map } from 'immutable';
import { makeTypedFactory, TypedRecord } from 'typed-immutable-record';
import * as Entities from '../api/entities';

const defaultEntitiesState = {
  Sudo: Map<string, Entities.ISudo>(),
  SudoSettings: Map<string, Entities.ISudoSettings>(),
  SudoAvatarImage: Map<string, Entities.ISudoAvatar>(),
  Counts: Map<string, Entities.ISudoCounts>(),
  EmailAccount: Map<string, Entities.IEmailAccount>(),
  Telephone: Map<string, Entities.IPhoneAccount>(),
  Contact: Map<string, Entities.IContact>(),
  ContactAvatar: Map<string, Entities.IContactAvatar>(),
  MessagingThread: Map<string, Entities.IMessageConversation>(),
  Message: Map<string, Entities.IMessage>(),
  MessageMedia: Map<string, Entities.IMessageMedia>(),
  EmailMessage: Map<string, Entities.IEmailMessage>(),
  EmailMessageBody: Map<string, Entities.IEmailMessageBody>(),

  GLOBAL_SETTINGS: Map<string, Entities.IGlobalSettings>(),
};

type IEntitiesProps = typeof defaultEntitiesState;

export type PersistedEntityType = keyof IEntitiesProps;
export interface IEntities extends TypedRecord<IEntities>, IEntitiesProps {}
export const EntitiesState = makeTypedFactory<IEntitiesProps, IEntities>(
  defaultEntitiesState,
);
