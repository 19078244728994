import React from 'react';

export default function Timer(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <g fill="currentColor" fillRule="evenodd" transform="translate(3 1.5)">
        <path d="M14.652 5.32l.841-.84a.75.75 0 0 1 1.06 1.06l-.843.844a8.75 8.75 0 1 1-1.058-1.063zM6 1.75a.75.75 0 0 1 0-1.5h6a.75.75 0 1 1 0 1.5H6zM8.25 7v6a.75.75 0 1 0 1.5 0V7a.75.75 0 0 0-1.5 0z" />
      </g>
    </svg>
  );
}
