import { IAction, IThunk } from './actions-base';
import { readConnectionInfoOrThrow } from 'src/connection-info';

export const UPDATE_SESSION_ACTIVATION = 'UPDATE_SESSION_ACTIVATION_ACTION';

export interface IUpdateSessionActivation extends IAction {
  type: typeof UPDATE_SESSION_ACTIVATION;
  payload: {
    activated: boolean;
  };
}

/**
 * Used to update own session activation status
 */
export const activateOwnSession = (): IUpdateSessionActivation => {
  return {
    type: UPDATE_SESSION_ACTIVATION,
    payload: {
      activated: true,
    },
  };
};

/**
 * Used when responding to another tab's session becoming active
 */
export const handleOtherSessionActivated = (
  otherSessionEnvironmentName: string,
): IThunk => {
  return async (dispatch, getState) => {
    const state = getState();

    const apiStatus = state.app.apiStatus;
    if (apiStatus !== 'connected' && apiStatus !== 'binding') {
      return;
    }

    const connectionInfo = readConnectionInfoOrThrow();
    if (otherSessionEnvironmentName !== connectionInfo.environment) {
      return;
    }

    const action: IUpdateSessionActivation = {
      type: UPDATE_SESSION_ACTIVATION,
      payload: {
        activated: false,
      },
    };
    dispatch(action);
  };
};
