import { IEmailAttachment } from 'src/api';
import { uuid } from 'src/utils';

/**
 * Extracts data uris from email message body html and creates new inline attachments
 */
export function extractInlineAttachments(
  body: string,
  attachments: IEmailAttachment[],
) {
  const regex = /data:(image\/png|image\/jpg|image\/jpeg);base64,([\d\w\/+=]*)/g;
  const nonInlineAttachments = attachments.filter(
    attachment => !attachment.inlineAttachment,
  );

  const inlineAttachments: IEmailAttachment[] = [];
  const updatedHtml = body.replace(regex, (_, mimeType, data) => {
    const cid = uuid();
    inlineAttachments.push({
      contentId: cid,
      data,
      filename: cid,
      inlineAttachment: true,
      mimeType,
    });
    return `cid:${cid}`;
  });

  return {
    body: updatedHtml,
    attachments: [...inlineAttachments, ...nonInlineAttachments],
  };
}

/**
 * Inserts data uris into email message body for inline attachments
 */
export function injectInlineAttachments(
  body: string,
  attachments: IEmailAttachment[],
) {
  const nonInlineAttachments = attachments.filter(
    attachment => !attachment.inlineAttachment,
  );

  // This should acccept any percent-encoded url as a Content-ID, which is more
  // than what is technically permissable according to RFC2392 url-addr-spec,
  // but I think that is okay for our purposes.
  const rfc3986SpecialChars = `[!*'();:@&=+$,/?#[\\]]`;
  const rfc3986UnreservedChars = `[A-Za-z0-9\\-_.~]`;
  const percent = `\\%`;
  const rfc2392UrlAddrSpec = `(${rfc3986SpecialChars}|${rfc3986UnreservedChars}|${percent})+`;
  const cid = `cid:(${rfc2392UrlAddrSpec})`;

  const updatedBody = body.replace(new RegExp(cid, 'g'), (_, contentId) => {
    const attachment = attachments.find(a => a.contentId === contentId);
    if (attachment) {
      return `data:${attachment.mimeType};base64,${attachment.data}`;
    } else {
      return '';
    }
  });
  return {
    body: updatedBody,
    attachments: nonInlineAttachments,
  };
}
