import { Component } from 'react';
import PropTypes from 'prop-types';

const ESC_KEY_CODE = 27;

class CancelListener extends Component {
  static propTypes = {
    children: PropTypes.node,
    onCancel: PropTypes.func.isRequired,
    shouldDetect: PropTypes.bool,
  };

  static defaultProps = {
    children: null,
    shouldDetect: true,
  };

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown);
  }

  handleKeyDown = e => {
    const { shouldDetect, onCancel } = this.props;
    if (e.keyCode === ESC_KEY_CODE && shouldDetect) {
      onCancel();
    }
  };

  render() {
    return this.props.children;
  }
}

export default CancelListener;
