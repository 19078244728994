import * as React from 'react';
import styled from 'styled-components';

import DetailsRow, { IDetailsRowProps } from '../DetailsRow';

interface IProps {
  sections: IDetailsRowProps[][];
}

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: calc(var(--theme-spacing) * 3);
  margin-top: 16px;
  background-color: var(--white);
  box-shadow: 0 1px 3px -1px var(--gray-5);
`;

const DetailsSections = (props: IProps) => (
  <React.Fragment>
    {props.sections.map((section, i) => (
      <React.Fragment key={i}>
        <SectionWrapper>
          {section.map((row, j) => (
            <DetailsRow key={j} label={row.label} value={row.value} />
          ))}
        </SectionWrapper>
      </React.Fragment>
    ))}
  </React.Fragment>
);

export default DetailsSections;
