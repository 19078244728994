import * as React from 'react';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';

const StyledTooltip = styled(ReactTooltip)`
  background-color: #000000 !important;
  box-shadow: 0 4px 8px 0 rgba(16, 16, 16, 0.24);
  border-radius: 5px !important;
  transition: visibility 0s linear 0.1s, opacity 0.1s ease-out !important;
  &.show {
    transition: opacity 0.3s ease-out !important;
  }
`;

interface IProps {
  id?: string;
}

const ArrowTooltip = (props: IProps) => (
  <StyledTooltip id={props.id} effect="solid" place="bottom" />
);

export default ArrowTooltip;
