import * as React from 'react';

export interface IChildrenParams {
  onDelete: (contactGuid: string) => any;
  onCancelDelete: () => any;
  onConfirmDelete: () => any;
  isAsking: boolean;
}

interface IProps {
  onDeleteContact: (contactGuid: string) => void;
  children: (params: IChildrenParams) => any;
}

export interface IState {
  isAsking: boolean;
  contactGuid: string;
}

export const setIsAsking = (isAsking: boolean, contactGuid: string) => (
  state: IState,
) => ({ isAsking, contactGuid });

export class WithDeleteContact extends React.Component<IProps, IState> {
  public state: IState = {
    isAsking: false,
    contactGuid: null,
  };

  public delete = () => {
    const { onDeleteContact } = this.props;
    const { contactGuid } = this.state;
    onDeleteContact(contactGuid);
  };

  public handleDelete = (contactGuid: string) => {
    this.setState(setIsAsking(true, contactGuid));
  };

  public handleConfirmDelete = () => {
    this.handleCancelDelete();
    this.delete();
  };

  public handleCancelDelete = () => {
    this.setState(setIsAsking(false, null));
  };

  public render() {
    const { isAsking } = this.state;
    return this.props.children({
      isAsking,
      onCancelDelete: this.handleCancelDelete,
      onConfirmDelete: this.handleConfirmDelete,
      onDelete: this.handleDelete,
    });
  }
}

export default WithDeleteContact;
