import { SidePanelStoreNames } from '../state';
import { IAction } from './actions-base';

export const TOGGLE_SIDE_PANEL = 'TOGGLE_SIDE_PANEL_ACTION';

interface IToggleSidePanelAction extends IAction {
  payload: {
    name: string;
    isOpen?: boolean;
  };
}

export const createSidePanelAction = (
  name: SidePanelStoreNames,
  isOpen?: boolean,
): IToggleSidePanelAction => {
  return {
    payload: {
      name,
      isOpen,
    },
    type: TOGGLE_SIDE_PANEL,
  } as IToggleSidePanelAction;
};
