import React, { useState } from 'react';
import styled from 'styled-components';
import Portal from 'src/components/utils/Portal';
import Overlay from 'src/components/utils/Overlay';
import CancelListener from 'src/components/utils/CancelListener';
import ImageBase, { ImageWrapper } from '../ImageBase';
import { ImageProps } from '../..';

interface IStyledImageBaseProps {
  onClick: () => void;
}

export const StyledImageBase = styled(ImageBase)<IStyledImageBaseProps>`
  cursor: zoom-in;
`;

const ImageZoomableWrapper = styled.div`
  cursor: zoom-out;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  & ${ImageWrapper} {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const ImageZoomable = (props: ImageProps) => {
  const [isZoom, setZoom] = useState(false);
  const toggleZoom = () => setZoom(!isZoom);

  return (
    <React.Fragment>
      <StyledImageBase
        onClick={toggleZoom}
        isRemovable={props.isRemovable}
        {...props}
      />
      {isZoom && (
        <Portal>
          <Overlay isOpen onClick={toggleZoom} />
          <CancelListener onCancel={toggleZoom} />
          <ImageZoomableWrapper onClick={toggleZoom}>
            <ImageBase {...props} isRemovable={false} />
          </ImageZoomableWrapper>
        </Portal>
      )}
    </React.Fragment>
  );
};

ImageZoomable.defaultProps = {
  isRemovable: false,
};

export default ImageZoomable;
