import { getConversationList } from '../selectors';
import * as ListActions from './list-actions';
import { IThunk } from './actions-base';
import { IPhoneAccount } from '../api';

export const SELECT_CONVERSATION = 'SELECT_CONVERSATION_ACTION';

export interface ISelectConversationAction {
  type: typeof SELECT_CONVERSATION;
  payload: {
    conversationId: string;
  };
}

export function selectConversation(
  phoneAccount: IPhoneAccount,
  conversationId: string,
): IThunk<void> {
  return async (dispatch, getState, app) => {
    const state = getState();
    const conversationList = getConversationList(state.lists, phoneAccount);
    const selection = conversationId ? [conversationId] : [];
    dispatch({
      type: ListActions.LIST_SELECT,
      payload: {
        listId: conversationList.id,
        itemIds: selection,
      },
    } as ListActions.IListSelectAction);

    dispatch({
      type: SELECT_CONVERSATION,
      payload: {
        conversationId,
      },
    } as ISelectConversationAction);
  };
}
