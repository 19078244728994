import * as React from 'react';
import { Button, ListItem } from 'src/components';
import styled from 'styled-components';

export interface IConversationMemberProps {
  avatarLocked?: boolean;
  member: any;
  onCreateConversation: (memberData: any) => void;
}

const ListWrapper = styled.div`
  display: flex;
  align-items: center;
  background-color: var(--gray-9);

  > :first-child {
    flex-grow: 1;
    cursor: initial;
    :hover {
      background-color: initial;
    }
  }
`;

export const CreateConversationButton = styled(Button)`
  padding: 0 16px;
`;

const ConversationDetailsMember = ({
  member,
  onCreateConversation,
  avatarLocked,
}: IConversationMemberProps) => (
  <ListWrapper key={member.alias}>
    <ListItem
      primaryText={member.displayName}
      secondaryText={
        member.displayName === member.formattedPhone
          ? ''
          : member.formattedPhone
      }
      avatarImage={member.avatarImage}
      avatarText={member.avatarText}
      isAvatarLocked={avatarLocked}
      showLock={false}
    />
    {onCreateConversation && (
      <CreateConversationButton
        iconName="MessagesOutline"
        iconColor="var(--blue)"
        onClick={() => onCreateConversation(member)}
      />
    )}
  </ListWrapper>
);

export default ConversationDetailsMember;
