import * as React from 'react';
import styled from 'styled-components';
import { InputSingleLine } from 'src/components';

interface IFieldInputWrapperProps {
  hasError: boolean;
}

export const FieldInputWrapper = styled.div<IFieldInputWrapperProps>`
  > * {
    padding: 0;
    height: 20px;
    margin-top: calc(var(--theme-spacing) * 0.5);
    margin-bottom: calc(var(--theme-spacing) * 0.5);
    border-bottom: ${props =>
      props.hasError ? '1px solid var(--red)' : '1px solid var(--gray-5)'};
    padding: 5px 5px 3px;
  }
`;

export const StyledInput = styled(InputSingleLine)`
  font-size: 17px;
`;

const FieldInput = (props: any) => (
  <FieldInputWrapper hasError={props.hasError}>
    <StyledInput {...props} color="#000" placeholderColor="#abb5bd" />
  </FieldInputWrapper>
);

export default FieldInput;
