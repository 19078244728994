const encoder = new TextEncoder();
const decoder = new TextDecoder();

/** Converts ArrayBuffer to base64 */
export function arrayBufferToBase64(arrayBuffer: ArrayBuffer) {
  let base64 = '';
  const bytes = new Uint8Array(arrayBuffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    base64 += String.fromCharCode(bytes[i]);
  }
  return btoa(base64);
}

/**
 * Converts base64 to ArrayBuffer.
 * Behavior is undefined for invalid base64 strings.
 */
export function base64ToArrayBuffer(base64: string) {
  const binaryString = atob(base64);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes.buffer;
}

/** Converts string into utf8-encoded ArrayBuffer  */
export function stringToArrayBuffer_utf8(str: string) {
  return encoder.encode(str).buffer;
}

/** Converts utf8-encoded ArrayBuffer into string */
export function arrayBufferToString_utf8(utf8ArrayBuffer: ArrayBuffer) {
  return decoder.decode(utf8ArrayBuffer);
}

/**
 * Converts string into base64.
 * Use this when you want the base64 to contain utf8 representation of the string.
 */
export function base64ToString_utf8(utf8Base64: string) {
  return arrayBufferToString_utf8(base64ToArrayBuffer(utf8Base64));
}

/**
 * Converts base64 with into string.
 * Use this when the base64 string contains utf8 data.
 */
export function stringToBase64_utf8(str: string) {
  return arrayBufferToBase64(stringToArrayBuffer_utf8(str));
}

/**
 * Converts string into base64.
 * Use this when you want the base64 to contain ASCII (single byte) representation
 * of the string.
 */
export function stringToBase64_ascii(str: string) {
  return btoa(str);
}

/**
 * Converts base64 into string
 * Use this when the base64 string contains ASCII / single byte data.
 */
export function base64ToString_ascii(base64: string) {
  return atob(base64);
}
