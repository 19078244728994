import * as React from 'react';
import { pure } from 'recompose';
import { Button } from 'src/components';
import {
  AttachmentThumbnail,
  AttachmentWrapper,
  IWithOpenAttachmentHandlerData,
  MessageReceivedRow,
  MessageRowWrapper,
  ReceivedAvatar,
  RowOptions,
  TimerBadge,
} from '../BaseMessageElements';
import { IAvatarData } from '../../../selectors';

export interface IProps extends IAvatarData, IWithOpenAttachmentHandlerData {
  attachmentType: string;
  data: string;
  onDeleteMessage: (id: string) => void;
  onOpenAttachment: (id: string, messageGuid: string) => void;
  thumbnail: string;
  timeout?: number;
}

export const ReceivedAttachment = (props: IProps) => (
  <MessageRowWrapper>
    <MessageReceivedRow>
      <ReceivedAvatar image={props.avatarImage}>
        {props.avatarText}
      </ReceivedAvatar>
      <AttachmentWrapper>
        <RowOptions
          onDeleteMessage={() => props.onDeleteMessage(props.messageGuid)}
        />
        <Button
          onClick={() => {
            if (props.attachmentType === 'UNKNOWN') {
              return;
            }
            props.onOpenAttachment(props.realId, props.messageGuid);
          }}
        >
          <AttachmentThumbnail
            thumbnail={props.thumbnail}
            data={props.data}
            type={props.attachmentType}
          />
        </Button>
        {props.timeout ? <TimerBadge value={props.timeout} /> : null}
      </AttachmentWrapper>
    </MessageReceivedRow>
  </MessageRowWrapper>
);

export default pure(ReceivedAttachment);
