/* tslint:disable max-line-length */
import React from 'react';

export default function SudoRings(props: object) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42 52" {...props}>
      <defs>
        <linearGradient
          id="Svg_SudoRings_Gradient"
          x1="74.321%"
          x2="17.503%"
          y1="1.42%"
          y2="100%"
        >
          <stop offset="0%" stopColor="#24A6FF" />
          <stop offset="100%" stopColor="#8321E1" />
        </linearGradient>
      </defs>
      <path
        fill="url(#Svg_SudoRings_Gradient)"
        fillRule="nonzero"
        d="M40.674 9.892a1.171 1.171 0 1 1-1.94 1.313c-4.186-6.186-11.125-9.962-18.7-9.962-7.577 0-14.517 3.777-18.703 9.964a1.171 1.171 0 1 1-1.94-1.313C4.007 3.07 11.67-1.1 20.033-1.1c8.363 0 16.025 4.17 20.641 10.992zM-.703 37.956a1.171 1.171 0 1 1 1.95-1.3c4.173 6.259 11.156 10.09 18.786 10.09 7.63 0 14.613-3.83 18.787-10.09a1.171 1.171 0 0 1 1.949 1.3C36.165 44.86 28.455 49.09 20.033 49.09S3.9 44.86-.703 37.956z"
        transform="translate(1 2)"
      />
    </svg>
  );
}
