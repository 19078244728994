import React from 'react';

export default function Edit(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <g
        fill="currentColor"
        fillRule="evenodd"
        transform="rotate(-45 11.973 12)"
      >
        <path d="M20.5 14.75v-5.5h2.711c.47 0 .9.263 1.113.681l.372.728c.431.842.431 1.84 0 2.682l-.372.728a1.25 1.25 0 0 1-1.113.681H20.5zm-1.5 0H1.74L-1.074 12 1.74 9.25H19v5.5z" />
      </g>
    </svg>
  );
}
