import { List, ListItem, Popover } from 'src/components';
import { InputSingleLine, IInputSingleLineProps } from 'src/components/Input';
import * as React from 'react';
import styled from 'styled-components';
import { SyntheticEvent } from 'react';
import WithAutocompleteListItem, {
  ChildrenParams as WithAutocompleteListItemChildrenParams,
} from './WithAutocompleteListItem';
import Tags from './Tags';
import withContactAutocompletePhone from './withContactAutocompletePhone';

export interface Props {
  activeItem: number;
  addedContacts: any[];
  clearInput: () => void;
  contacts: any[];
  countryCode?: string;
  inputRef?: HTMLInputElement;
  inputValue: string;
  isExternal?: boolean;
  isHideTags?: boolean;
  isHighlightError?: boolean;
  isInputError: boolean;
  isPopoverOpen: boolean;
  onAddContact: (contact?: any) => void;
  onError?: (value: string) => void;
  onInputChange: (event: SyntheticEvent<HTMLInputElement>) => void;
  onInputKeyDown: (event: any) => void;
  onRemoveLastContact: () => void;
  onTogglePopover: (isPopoverOpen: boolean) => void;
  placeholder?: string;
  setActiveItem: (activeItem: number) => void;
  setInputRef: (input: HTMLInputElement) => void;
  sudoGuid: string;
  title?: string;
}

interface AutocompleteInputProps extends IInputSingleLineProps {
  value: string;
  onKeyDown: (event: SyntheticEvent<HTMLInputElement>) => void;
}

const AutocompleteInputWrapper = styled.div`
  flex-grow: 1;
  > div {
    min-width: 200px;
    padding: 0;
    height: auto;
  }
`;

const AutocompleteInput = (props: AutocompleteInputProps) => (
  <AutocompleteInputWrapper>
    <InputSingleLine {...props} />
  </AutocompleteInputWrapper>
);

const StyledList = styled(List)`
  max-height: 332px;
  overflow: auto;
`;

const Wrapper = styled.div`
  position: relative;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const StyledPopover = styled(Popover)`
  width: 375px;
  z-index: 999999;
`;

const Title = styled.div`
  font-weight: bold;
  color: #8c949c;
  padding: 16px;
  font-size: 13px;
  text-transform: uppercase;
`;

const LastName = styled.span`
  font-weight: bold;
`;

const Name = (props: any) => (
  <div>
    {props.firstName}
    {props.lastName && <LastName>&nbsp;{props.lastName}</LastName>}
  </div>
);

export const ContactAutocomplete = (props: Props) => {
  const placeholder =
    props.addedContacts.length === 0 || props.isHideTags
      ? props.placeholder
      : '';
  const additionalInputProps = {} as any;
  if (props.isHighlightError && props.isInputError) {
    additionalInputProps.color = '#e70000';
  }
  return (
    <Wrapper>
      <StyledPopover
        isOpen={props.isPopoverOpen && !!props.contacts.length}
        anchor={
          <InputWrapper>
            {!props.isHideTags && <Tags tags={props.addedContacts} />}
            <AutocompleteInput
              value={props.inputValue}
              onFocus={() => props.onTogglePopover(true)}
              onBlur={() => props.onTogglePopover(false)}
              onChange={props.onInputChange}
              onKeyDown={props.onInputKeyDown}
              inputRef={props.setInputRef}
              placeholder={placeholder}
              {...additionalInputProps}
            />
          </InputWrapper>
        }
        positionHorizontal="start"
        positionVertical="end"
        originHorizontal="start"
        originVertical="start"
        isWithCancelDetection={false}
      >
        <div>
          {props.title && <Title>{props.title}</Title>}
          <StyledList>
            {props.contacts.map((contact: any, key: number) => (
              <WithAutocompleteListItem
                key={contact.key}
                id={key}
                currentActiveItem={props.activeItem}
                setActiveItem={props.setActiveItem}
                onAddContact={props.onAddContact}
              >
                {({
                  onMouseEnter,
                  onMouseDown,
                  isActive,
                }: WithAutocompleteListItemChildrenParams) => (
                  <ListItem
                    isActive={isActive}
                    avatarImage={contact.avatarImage}
                    avatarText={contact.avatarImage ? null : contact.avatarText}
                    primaryText={<Name {...contact} />}
                    secondaryText={contact.secondary}
                    onMouseDown={onMouseDown}
                    onMouseEnter={onMouseEnter}
                  />
                )}
              </WithAutocompleteListItem>
            ))}
          </StyledList>
        </div>
      </StyledPopover>
    </Wrapper>
  );
};

export const ContactAutocompletePhone = withContactAutocompletePhone(
  ContactAutocomplete as any,
) as any;
