import * as React from 'react';
import { Icon, Tab, Tabs } from 'src/components';
import SearchListInput from 'src/features/SearchListInput';
import {
  Route,
  Switch,
  Link,
  RouteComponentProps,
  NavLinkProps,
  NavLink,
} from 'react-router-dom';
import styled from 'styled-components';

import ContactContent from './ContactContent';
import {
  Layout,
  LayoutContent,
  LayoutList,
  LayoutListToolbar,
  LayoutListSearch,
} from './ContactsLayout';
import ContactsList from './ContactsList';
import EmptyContent from './EmptyContent';
import ContactEditor from './ContactEditor';
import * as Actions from 'src/actions';

const AddContactLink = styled(Link)`
  text-decoration: none;
  display: flex;
  align-items: center;
  color: var(--blue);
  height: 56px;

  svg {
    margin-right: 8px;
  }

  span {
    line-height: 20px;
    font-size: 17px;
    font-weight: normal;
  }
`;

const StyledTabs = styled(Tabs)`
  height: calc(var(--theme-spacing) * 7);
  flex: none;
`;

const StyledNavTab = styled(Tab)<NavLinkProps>`
  flex-grow: 1;
  display: flex;
  justify-content: center;

  &.isActive {
    font-weight: bold;
  }
`;

const ListWrapper = styled.div`
  overflow: auto;
  flex-grow: 1;
`;

const ContactsPage = (props: RouteComponentProps<any>) => (
  <Layout>
    <LayoutListToolbar>
      <AddContactLink
        to={`/${props.match.params.sudo}/contacts/${props.match.params.tab ||
          'sudo'}/edit`}
      >
        <Icon name="Add" />
        <span>Add Contact</span>
      </AddContactLink>
    </LayoutListToolbar>

    <LayoutListSearch>
      <SearchListInput
        placeholder="Search Contacts"
        searchAction={Actions.contactsSearch}
        searchType="contacts"
        sudoSlug={props.match.params.sudo}
      />
    </LayoutListSearch>

    <LayoutList>
      <StyledTabs>
        <StyledNavTab
          component={NavLink}
          activeClassName="isActive"
          to={`/${props.match.params.sudo}/contacts${
            !props.match.params.tab ? '' : '/sudo'
          }`}
        >
          Sudo
        </StyledNavTab>
        <StyledNavTab
          component={NavLink}
          activeClassName="isActive"
          to={`/${props.match.params.sudo}/contacts/all`}
        >
          All
        </StyledNavTab>
      </StyledTabs>

      <ListWrapper>
        <Switch>
          <Route path="/:sudo/contacts/:tab?" component={ContactsList} />
        </Switch>
      </ListWrapper>
    </LayoutList>

    <LayoutContent>
      <Switch>
        <Route
          path="/:sudo/contacts/:tab/edit/:id?"
          component={ContactEditor}
        />
        <Route path="/:sudo/contacts/:tab/:id" component={ContactContent} />
        <Route path="/:sudo/contacts" component={EmptyContent} />
      </Switch>
    </LayoutContent>
  </Layout>
);

export default ContactsPage;
