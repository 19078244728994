import * as React from 'react';
import moment from 'moment';

export interface IProps {
  onComplete?: () => void;
  onTick?: (value: number) => void;
  value: number;
  paused?: boolean;
}

interface IState {
  value: moment.Duration;
}

export default class Timer extends React.Component<IProps, IState> {
  private interval: NodeJS.Timer;

  public constructor(props: IProps) {
    super(props);

    this.state = {
      value: moment.duration(props.value, 'seconds'),
    };
  }

  public componentDidMount() {
    if (!this.props.paused) {
      this.interval = setInterval(this.handleTick, 1000);
    }
  }

  public handleTick = () => {
    const {
      onTick = (): void => undefined,
      onComplete = (): void => undefined,
    } = this.props;

    const { value } = this.state;
    const tick = this.state.value.subtract(1, 'second');

    this.setState({
      value: tick,
    });

    if (value.asSeconds() < 1) {
      clearInterval(this.interval);
      this.interval = null;
      onComplete();
      return;
    }

    onTick(tick.asSeconds());
  };

  public componentDidUpdate(prevProps: IProps) {
    if (this.props.paused && this.props.paused !== prevProps.paused) {
      clearInterval(this.interval);
      this.interval = null;
    }

    if (!this.interval && !this.props.paused) {
      this.interval = setInterval(this.handleTick, 1000);
    }

    if (prevProps.value !== this.props.value) {
      this.setState({
        value: moment.duration(this.props.value, 'seconds'),
      });
    }
  }

  public componentWillUnmount() {
    clearInterval(this.interval);
    this.interval = null;
  }

  public render() {
    const { value } = this.state;

    return moment
      .utc(value.as('milliseconds'))
      .format(value.get('hour') > 1 ? 'HH:mm:ss' : 'mm:ss');
  }
}
