import * as React from 'react';
import cn from 'classnames';
import styled from 'styled-components';
import { filter } from 'lodash';
import memoizeOne from 'memoize-one';

import { Button } from 'src/components';

import Confirm from '../../components/Confirm';

import ArrowTooltip from '../../features/Tooltip/ArrowTooltip';
import ButtonWithCheckBox from '../../features/Button/ButtonWithCheckBox';

import {
  Layout,
  LayoutContent,
  LayoutContentToolbar,
  LayoutFolders,
  LayoutList,
  LayoutListToolbar,
  LayoutMergedToolbar,
} from './Layout';
import FoldersList from './FolderList';
import EmailsList from './EmailsList';
import EmailContent from './EmailContent';
import Composer, { IEmailActionProps } from './Composer';
import NoContent from './NoContent';
import withEmailsData, { IProps } from './withEmailsData';
import { Email } from './selectors';
import ErrorSnackbar from '../../features/Error/ErrorSnackbar';
import { AvatarType } from 'src/state';

const ActionWithTextButton = styled(Button)`
  margin-right: 16px;
  text-decoration: none;
  display: flex;
  align-items: center;
  color: var(--blue);

  svg {
    margin-right: 8px;
  }

  span {
    line-height: 20px;
    font-size: 17px;
    font-weight: normal;
  }
`;

const StyledButton = styled(Button)`
  &.disabled {
    pointer-events: none;
  }
`;

const StyledEditDraftButton = styled(Button)`
  margin-left: calc(var(--theme-spacing) * 2);
  margin-right: auto;
  align-self: center;
  font-size: 17px;
`;

const StyledButtonWithCheckBox = styled(ButtonWithCheckBox)`
  width: 170px;
`;

export class EmailsComponent extends React.Component<IProps> {
  public componentDidMount() {
    const {
      emailMessageId,
      sudoGuid,
      folderName,
      backToFolderUrl,
      getEmail,
      queryEmails,
    } = this.props;
    queryEmails(sudoGuid, folderName);
    if (emailMessageId) {
      getEmail(emailMessageId, backToFolderUrl);
    }
    this.loadAvatars();
  }

  public componentDidUpdate(prevProps: IProps) {
    const {
      sudoSlug,
      emailMessageId,
      sudoGuid,
      folderName,
      backToFolderUrl,
      getEmail,
      queryEmails,
    } = this.props;
    if (!emailMessageId) {
      this.props.cancelLoadEmailMessageBody('componentDidUpdate');
    }

    if (
      prevProps.folderName !== folderName ||
      prevProps.sudoSlug !== sudoSlug
    ) {
      queryEmails(sudoGuid, folderName);
      return;
    }
    if (emailMessageId && prevProps.emailMessageId !== emailMessageId) {
      getEmail(emailMessageId, backToFolderUrl);
      return;
    }
    this.loadAvatars(prevProps);
  }

  private loadAvatars = (prevProps?: IProps) => {
    const ids = this.props.recipients.reduce(
      (accumulator, recipient) => {
        if (prevProps && prevProps.recipients.includes(recipient)) {
          return accumulator;
        }
        return [...accumulator, recipient.id];
      },
      [] as string[],
    );

    this.props.fetchAvatarsById(AvatarType.ContactAvatar, ids);
  };

  public componentWillUnmount() {
    this.props.cancelLoadEmailMessageBody('componentWillUnmount');
  }

  private handleEmailsListScroll = () => {
    const { queryEmails, sudoGuid, folderName } = this.props;
    queryEmails(sudoGuid, folderName);
  };
  private handleEmailSelect = (email: Email, value: boolean) => {
    const { selectEmails, emailMessageId, backToFolderUrl } = this.props;
    selectEmails([email], value, emailMessageId, backToFolderUrl);
  };

  private handleClose = (props: IEmailActionProps) => {
    const { closeComposer, backToFolderUrl } = this.props;
    closeComposer({ ...props, backToFolderUrl });
  };
  private handleSend = (props: IEmailActionProps) => {
    const { sendComposerEmail, backToFolderUrl } = this.props;
    sendComposerEmail({ ...props, backToFolderUrl });
  };
  private handleDraftSave = (props: IEmailActionProps) => {
    const { saveComposerEmailDraft, backToFolderUrl } = this.props;
    saveComposerEmailDraft({ ...props, backToFolderUrl });
  };

  private handleRemove = (
    e: React.SyntheticEvent<HTMLElement>,
    maybeStage?: string,
  ) => {
    const { remove, emailMessageId, folderName, backToFolderUrl } = this.props;
    remove(emailMessageId, folderName, maybeStage, backToFolderUrl);
  };
  private handleBatchDelete = (
    e: React.SyntheticEvent<HTMLElement>,
    maybeStage?: string,
  ) => {
    const { batchDelete, sudoGuid, folderName } = this.props;
    batchDelete(sudoGuid, folderName, maybeStage);
  };

  private getSelectedEmails = memoizeOne(
    (emails: Email[]): Email[] => {
      return filter(emails, 'isSelected');
    },
  );
  private isAnyEmailSelected = memoizeOne(
    (emails: Email[]): boolean => {
      return this.getSelectedEmails(emails).length > 0;
    },
  );
  private isAllEmailsSelected = memoizeOne(
    (emails: Email[]): boolean => {
      return this.getSelectedEmails(emails).length === emails.length;
    },
  );

  public render() {
    const { props, isAnyEmailSelected, isAllEmailsSelected } = this;

    const isDraftsFolder = props.folderName === 'drafts';
    const isTrashFolder = props.folderName === 'trash';
    const hasCurrentEmail =
      !!props.emailMessageId && !!props.currentEmailMessage;
    const hasCurrentEmailMessageBody =
      hasCurrentEmail && !!props.currentEmailMessageBody;
    const isEditDraftActive = isDraftsFolder && hasCurrentEmailMessageBody;

    const changeSelection = (isSelectAll: boolean) => {
      return props.selectEmails(
        props.emails,
        isSelectAll,
        props.emailMessageId,
        props.backToFolderUrl,
      );
    };

    const isReplyForwardDisabled =
      !props.emailMessageId || !props.currentEmailMessageBody;

    return (
      <React.Fragment>
        <Layout
          className={cn(isAnyEmailSelected(props.emails) && 'isToolbarMerged')}
        >
          <LayoutFolders>
            <FoldersList baseUrl={props.baseUrl} />
          </LayoutFolders>

          <LayoutListToolbar>
            <ActionWithTextButton
              onClick={() => props.openComposer(props.sudoGuid, null, 'new')}
              iconName="Compose"
              iconColor="var(--blue)"
            >
              Send an Email
            </ActionWithTextButton>
          </LayoutListToolbar>

          {hasCurrentEmail && (
            <LayoutContentToolbar>
              {isEditDraftActive && (
                <StyledEditDraftButton
                  asLink
                  onClick={() =>
                    props.currentEmailMessage &&
                    props.currentEmailMessageBody &&
                    props.openComposer(
                      props.sudoGuid,
                      props.emailMessageId,
                      'draft',
                    )
                  }
                >
                  Edit Draft
                </StyledEditDraftButton>
              )}
              <StyledButton
                iconName="ReplyAll"
                onClick={() =>
                  props.openComposer(
                    props.sudoGuid,
                    props.emailMessageId,
                    'reply-all',
                  )
                }
                disabled={isReplyForwardDisabled}
                iconColor="var(--blue)"
                aria-label="Reply all"
                data-tip="Reply all"
                data-for="email-actions"
                data-offset="{'top': 20}"
              />
              <StyledButton
                iconColor="var(--blue)"
                iconName="Reply"
                onClick={() =>
                  props.openComposer(
                    props.sudoGuid,
                    props.emailMessageId,
                    'reply',
                  )
                }
                disabled={isReplyForwardDisabled}
                aria-label="Reply"
                data-tip="Reply"
                data-for="email-actions"
                data-offset="{'top': 20}"
              />
              <StyledButton
                iconColor="var(--blue)"
                iconName="Forward"
                onClick={() =>
                  props.openComposer(
                    props.sudoGuid,
                    props.emailMessageId,
                    'forward',
                  )
                }
                disabled={isReplyForwardDisabled}
                aria-label="Forward"
                data-tip="Forward"
                data-for="email-actions"
                data-offset="{'top': 20}"
              />
              <StyledButton
                iconColor="var(--blue)"
                iconName="DeleteOutline"
                onClick={() =>
                  props.remove(
                    props.emailMessageId,
                    props.folderName,
                    null,
                    props.backToFolderUrl,
                  )
                }
                disabled={isReplyForwardDisabled}
                aria-label="Delete"
                data-tip="Delete"
                data-for="email-actions"
                data-offset="{'top': 20}"
              />
              <ArrowTooltip id="email-actions" />
            </LayoutContentToolbar>
          )}

          <LayoutMergedToolbar>
            <StyledButtonWithCheckBox
              checkbox={{
                value: isAllEmailsSelected(props.emails),
                isIndeterminate: !isAllEmailsSelected(props.emails),
                onChange: (value: boolean) => changeSelection(value),
              }}
            >
              {props.selectedCount} Selected
            </StyledButtonWithCheckBox>
            <ActionWithTextButton
              iconName="EmailOutline"
              onClick={() => props.markReadStatus(props.sudoGuid, true)}
              iconColor="var(--blue)"
            >
              Mark as read
            </ActionWithTextButton>
            <ActionWithTextButton
              iconName="EmailOpen"
              onClick={() => props.markReadStatus(props.sudoGuid, false)}
              iconColor="var(--blue)"
            >
              Mark as unread
            </ActionWithTextButton>

            {isTrashFolder || isDraftsFolder ? (
              <ActionWithTextButton
                iconName="DeleteOutline"
                onClick={() =>
                  props.batchDelete(props.sudoGuid, props.folderName, null)
                }
                iconColor="var(--blue)"
              >
                Delete
              </ActionWithTextButton>
            ) : null}
            {isTrashFolder ? (
              <ActionWithTextButton
                iconName="Refresh"
                onClick={() =>
                  props.batchRestore(props.sudoGuid, props.folderName)
                }
                iconColor="var(--blue)"
              >
                Restore
              </ActionWithTextButton>
            ) : null}
            {!(isTrashFolder || isDraftsFolder) ? (
              <ActionWithTextButton
                iconName="DeleteOutline"
                onClick={() =>
                  props.batchMoveToTrash(props.sudoGuid, props.folderName)
                }
                iconColor="var(--blue)"
              >
                Trash
              </ActionWithTextButton>
            ) : null}
          </LayoutMergedToolbar>

          <LayoutList>
            <EmailsList
              emails={props.emails}
              baseUrl={`${props.baseUrl}/${props.folderName}`}
              currentEmailId={
                props.currentEmailMessage && props.currentEmailMessage.id
              }
              totalCount={props.totalCounts[props.folderName]}
              isLoading={props.isEmailsLoading}
              onEmailSelect={this.handleEmailSelect}
              onScroll={this.handleEmailsListScroll}
            />
          </LayoutList>

          <LayoutContent>
            {props.currentEmailMessage && !isAnyEmailSelected(props.emails) ? (
              <EmailContent
                email={props.currentEmailMessage}
                emailMessageBody={props.currentEmailMessageBody}
                hasMixedContent={props.hasMixedContent}
                mixedContentAllowed={props.mixedContentAllowed}
                toggleMixedContent={props.toggleMixedContent}
                onMailtoClick={address => props.mailto(address, props.sudoGuid)}
              />
            ) : (
              <NoContent
                folder={props.folderName}
                totalCount={props.totalCounts[props.folderName]}
                unreadCount={props.unreadCounts[props.folderName]}
                selectedCount={props.selectedCount}
                isAllSelected={isAllEmailsSelected(props.emails)}
                changeSelection={changeSelection}
              />
            )}
          </LayoutContent>
        </Layout>

        <Composer
          email={props.composerEmail}
          emailMessageBody={props.composerBody}
          contacts={props.contacts}
          isOpen={!!props.composerEmail}
          confirm={props.confirm}
          onChange={props.changeComposerEmail}
          onAttachmentsAdd={props.addComposerBodyAttachments}
          onAttachmentToggleInline={props.toggleComposerBodyAttachmentInline}
          onAttachmentRemove={props.removeComposerBodyAttachment}
          onConfirmHide={props.hideConfirms}
          onClose={this.handleClose}
          onSend={this.handleSend}
          onDraftSave={this.handleDraftSave}
        />
        {props.confirm === 'remove' && (
          <Confirm
            title="Delete Email?"
            content="This email will be permanently deleted. You will not be able to retrieve this."
            acceptText="Delete"
            cancelText="Cancel"
            onAccept={this.handleRemove}
            onCancel={props.hideConfirms}
            acceptData="no-subject-ok"
          />
        )}
        {props.confirm === 'batch-remove' && (
          <Confirm
            title="Delete Emails?"
            content="These emails will be permanently deleted. You will not be able to retrieve them."
            acceptText="Delete"
            cancelText="Cancel"
            onAccept={this.handleBatchDelete}
            onCancel={props.hideConfirms}
            acceptData="permanently-ok"
          />
        )}

        {props.snackbarError && (
          <ErrorSnackbar
            dismiss={() =>
              props.hideSnackbarError(props.snackbarError.errorType)
            }
          >
            {props.snackbarError.message}
          </ErrorSnackbar>
        )}
      </React.Fragment>
    );
  }
}

export default withEmailsData(EmailsComponent);
