export interface IBindingInfo {
  version: '1';
  bindingToken: string;
  connectionUrl: string;
  symmetricKey: string;
  iceConfig: RTCIceServer[];
}

export function getBindUrl(bindingInfo: IBindingInfo) {
  const { connectionUrl, bindingToken } = bindingInfo;
  return `${connectionUrl}?bearer=${bindingToken}`;
}

export function isWebRtcConnectionUrl(bindingInfo: IBindingInfo) {
  return bindingInfo.connectionUrl.endsWith('v3/bind');
}
