import { connect } from 'react-redux';

import { IRootState } from '../../../state';
import { IDispatcher } from '../../../store';
import { actions } from './modules';
import { getMessagesErrors } from '../selectors';

export interface IStateFromProps {
  error: string;
}

export interface IDispatchFromProps {
  closeLastError: () => void;
}

export const mapState = (state: IRootState): IStateFromProps => {
  const errors = getMessagesErrors(state);
  return {
    error: errors.length > 0 ? errors[errors.length - 1].message : null,
  };
};

export const mapDispatch = (dispatch: IDispatcher): IDispatchFromProps => ({
  closeLastError: () => {
    dispatch(actions.removeLastError());
  },
});

export default connect(
  mapState,
  mapDispatch,
);
