import styled from 'styled-components';
import ButtonBase from '../ButtonBase';

const ButtonLink = styled(ButtonBase.withComponent('a'))`
  color: var(--blue);
  display: inline-block;
  user-select: none;
`;

ButtonLink.displayName = 'ButtonLink';

export default ButtonLink;
