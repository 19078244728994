import moment from 'moment';
import linkifyHtml from 'linkifyjs/html';
import * as uuid from 'uuid';

import { Recipient } from '../selectors';
import {
  IEmailAddress,
  IEmailMessage,
  IEmailMessageBody,
  IEmailAccount,
  ISudoSettings,
} from '../../../api';
import { TEMP_GUID_PREFIX } from '../../../config';

const emptyEmail: IEmailMessage = {
  type: 'EmailMessage',

  id: null,
  path: null,
  parent: {
    id: null,
    type: 'EmailAccount',
    path: null,
  },

  from: null,
  to: [],
  cc: [],
  bcc: [],

  subject: '',

  folder: null,

  seen: true,
  forwarded: false,
  replied: false,
  trash: false,

  date: null,
  created: null,
  modified: null,
};

const emptyBody: IEmailMessageBody = {
  type: 'EmailMessageBody',

  id: null,
  path: null,

  parent: {
    id: null,
    type: 'EmailAccount',
    path: null,
  },

  body: '',
  isHtml: true,

  attachments: [],

  created: null,
  modified: null,
};

const getReplySubject = (email: IEmailMessage) => {
  const subject = email.subject || '';
  return subject.match(/^re: /gi) ? subject : `Re: ${subject}`;
};

// TODO: 'from' should always depend on current redux state (e.g. self email address changes during email edit)
const getEmptyEmail = (
  sudo: ISudoSettings,
  emailAccount: IEmailAccount,
): IEmailMessage => ({
  ...emptyEmail,
  id: `${TEMP_GUID_PREFIX}${uuid.v4()}`,
  parent: {
    ...emptyEmail.parent,
    id: emailAccount.id,
    path: emailAccount.path,
  },
  from: getCurrentSudoEmailAddress(sudo, emailAccount),
});

const getReplyEmail = (
  email: IEmailMessage,
  sudo: ISudoSettings,
  emailAccount: IEmailAccount,
): IEmailMessage => ({
  ...emptyEmail,
  parent: {
    ...emptyEmail.parent,
    id: emailAccount.id,
    path: emailAccount.path,
  },
  from: getCurrentSudoEmailAddress(sudo, emailAccount),
  to: email.from,
  subject: getReplySubject(email),
});

const getReplyAllEmail = (
  email: IEmailMessage,
  sudo: ISudoSettings,
  emailAccount: IEmailAccount,
): IEmailMessage => {
  const toWithoutCurrent = email.to.filter(
    (i: IEmailAddress): boolean => i.address !== emailAccount.address,
  );
  const cc = [...toWithoutCurrent, ...email.cc].filter(
    (i: IEmailAddress): boolean => i.address !== email.from[0].address,
  );
  return {
    ...emptyEmail,
    parent: {
      ...emptyEmail.parent,
      id: emailAccount.id,
      path: emailAccount.path,
    },
    from: getCurrentSudoEmailAddress(sudo, emailAccount),
    to: email.from,
    cc,
    subject: getReplySubject(email),
  };
};

const getForwardEmail = (
  email: IEmailMessage,
  sudo: ISudoSettings,
  emailAccount: IEmailAccount,
): IEmailMessage => ({
  ...emptyEmail,
  parent: {
    ...emptyEmail.parent,
    id: emailAccount.id,
    path: emailAccount.path,
  },
  from: getCurrentSudoEmailAddress(sudo, emailAccount),
  subject: `Fwd: ${email.subject || ''}`,
});

const getDraftEmail = (email: IEmailMessage): IEmailMessage => ({
  ...email,
  to: email.to,
  cc: email.cc,
  bcc: email.bcc,
});

const getEmptyBody = (): IEmailMessageBody => ({
  ...emptyBody,
  id: `${TEMP_GUID_PREFIX}${uuid.v4()}`,
});

const getReplyBody = (
  email: IEmailMessage,
  body: IEmailMessageBody,
): IEmailMessageBody => ({
  ...getEmptyBody(),
  body: `<br/><br/>
  <blockquote>
    On ${dateFormat(email.modified)}, ${formatEmailAddress(
    email.from[0],
  )} wrote:<br/>
    ${body.body}
  </blockquote>`,
  attachments: [],
});

const getForwardBody = (
  email: IEmailMessage,
  body: IEmailMessageBody,
): IEmailMessageBody => ({
  ...getEmptyBody(),
  body: `<br/><br/>
  <blockquote>
    ---------- Forwarded message ----------<br/>
    <br/>
    From: ${formatEmailAddress(email.from[0])}<br/>
    Date: ${moment(email.modified).format('MMMM D, YYYY h:mm:ss A')}<br/>
    To: ${email.to.map(formatEmailAddress).join(', ')}<br/>
    ${
      email.cc.length
        ? `Cc: ${email.cc.map(formatEmailAddress).join(', ')}<br/>`
        : ''
    }
    ${
      email.bcc.length
        ? `Bcc: ${email.bcc.map(formatEmailAddress).join(', ')}<br/>`
        : ''
    }
    Subject: ${email.subject || '(No Subject)'}<br/>
    ${body.body}
  </blockquote>`,
  attachments: body.attachments,
});

const dateFormat = (dateNumber: number): string => {
  // ex: Tue, Aug 23, 2016 at 10:32 AM
  return moment(dateNumber).format('ddd, MMM D, YYYY [at] h:mm A');
};

const formatEmailAddress = ({ displayName, address }: IEmailAddress): string =>
  displayName
    ? `${displayName} &lt;<a href="mailto:${address}">${address}</a>&gt;`
    : address;

const getAvatarText = (emailAddresses: IEmailAddress[]): string => {
  const texts: string[] = emailAddresses
    .map((emailAddress: IEmailAddress) => {
      const { displayName, address } = emailAddress;
      return (displayName || address || '')
        .split(/\s/)
        .filter((i: string) => i)
        .slice(0, 2)
        .map((part: string) => part[0].toUpperCase())
        .join('');
    })
    .filter((i: string) => i);
  if (texts.length === 1) {
    return texts[0];
  }
  return texts
    .slice(0, 2)
    .map((t: string) => t[0])
    .join('');
};

const truncateSubject = (subject: string): string => {
  return subject.length > 140 ? `${subject.substring(0, 140)}...` : subject;
};

export const getCurrentSudoEmailAddress = (
  sudo: ISudoSettings,
  emailAccount: IEmailAccount,
): IEmailAddress[] => {
  return [
    {
      displayName: [sudo.firstName, sudo.lastName]
        .filter((e: string) => e)
        .join(' '),
      address: emailAccount.address,
    },
  ];
};

export {
  getEmptyEmail,
  getReplyEmail,
  getReplyAllEmail,
  getForwardEmail,
  getDraftEmail,
  getEmptyBody,
  getReplyBody,
  getForwardBody,
  getAvatarText,
  truncateSubject,
};

export * from './sanitizer';
export {
  default as isEmailMessageBodyEmpty,
} from './is-email-message-body-empty';
export { default as fileToAttachment } from './file-to-attachment';
export { default as attachmentToBase64 } from './attachment-to-base64';
export { default as downloadAttachment } from './download-attachment';
export {
  default as groupCountsByFolderName,
} from './group-counts-by-folder-name';
export { default as getAttachmentSize } from './get-attachment-size';
export { default as humanizeBytes } from './humanize-bytes';
export * from './inline-image-attachments';
