import * as React from 'react';
import { Avatar } from 'src/components';
import { branch, renderNothing } from 'recompose';
import styled from 'styled-components';

import { formatRelativeDate } from '../../../utils';
import { truncateSubject } from '../utils';
import { IAvatarData } from '../../Messages/selectors';

interface IProps extends IAvatarData {
  subject: string;
  timestamp: number;
  from: any;
  to: any;
  cc: any;
  bcc: any;
}

interface IContactsRowProps {
  type: string;
  contacts: any;
}

const Wrapper = styled.div`
  padding: 24px 16px;
  border-bottom: var(--theme-divider);
`;

const SubjectWrapper = styled.div`
  padding-bottom: 12px;
`;

const Subject = styled.div`
  line-height: 22px;
  font-size: 17px;
  font-weight: bold;
  color: var(--black);
`;

const DetailsWrapper = styled.div`
  display: flex;
`;

const EmailAvatar = styled.div`
  width: 40px;
  padding-right: 11px;
`;

const Details = styled.div`
  flex-grow: 1;
`;

const From = styled.div`
  line-height: 22px;
  font-size: 17px;
  color: var(--black);
  margin-bottom: 4px;
`;

const SecondaryText = styled.span`
  line-height: 20px;
  font-size: 15px;
  color: var(--gray-4);
`;

const Timestamp = styled(SecondaryText)`
  display: inline-block;
`;

const ContactsRowComponent = (props: IContactsRowProps) => (
  <div>
    <SecondaryText>{props.type}:&nbsp;</SecondaryText>
    {props.contacts.map((address: any, index: number) => (
      <React.Fragment key={address.address}>
        {index !== 0 && <SecondaryText>, </SecondaryText>}
        <SecondaryText>
          {address.name
            ? `${address.name} <${address.address}>`
            : address.address}
        </SecondaryText>
      </React.Fragment>
    ))}
  </div>
);

const ContactsRow = branch(
  (props: IContactsRowProps) => !props.contacts || props.contacts.length === 0,
  renderNothing,
)(ContactsRowComponent);

const EmailInfo = ({
  subject,
  avatarText,
  avatarImage,
  avatarLocked,
  timestamp,
  from,
  to,
  cc,
  bcc,
}: IProps) => {
  return (
    <Wrapper>
      <SubjectWrapper>
        <Subject>
          <span title={subject || '(No Subject)'}>
            {truncateSubject(subject || '(No Subject)')}
          </span>
        </Subject>
      </SubjectWrapper>
      <DetailsWrapper>
        <EmailAvatar>
          <Avatar image={avatarImage} isSudo={avatarLocked}>
            {avatarText}
          </Avatar>
        </EmailAvatar>
        <Details>
          <From>
            {from.name ? `${from.name} <${from.address}>` : from.address}
          </From>
          <Timestamp>
            {timestamp && formatRelativeDate(new Date(timestamp))}
          </Timestamp>
          <ContactsRow type="To" contacts={to} />
          <ContactsRow type="Cc" contacts={cc} />
          <ContactsRow type="Bcc" contacts={bcc} />
        </Details>
      </DetailsWrapper>
    </Wrapper>
  );
};

export default EmailInfo;
