import { IEmailAttachment } from '../../../api';
import * as uuid from 'uuid';

const fileToAttachment = (file: File): Promise<IEmailAttachment> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e: any) => {
      let data: string = '';
      if (
        e &&
        e.target &&
        e.target.result &&
        e.target.result.length &&
        e.target.result.indexOf(',') > 0
      ) {
        data = e.target.result.split(',')[1] || '';
      }
      const attachment: any = {
        type: null,
        mimeType: file.type,
        data,
        filename: file.name,
        contentId: uuid.v4(),
        inlineAttachment: false,
        createdAt: null,
        updatedAt: null,
        version: 1,
        guid: null,

        size: file.size,
      };
      resolve(attachment);
    };
    // TODO: set proper error listener
    // tslint:disable-next-line:no-console
    reader.onerror = console.error;
    reader.readAsDataURL(file);
  });
};

export default fileToAttachment;
