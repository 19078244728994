import * as React from 'react';
import styled from 'styled-components';
import { get } from 'lodash';

import EmailInfo from '../EmailInfo';
import EmailEditor from '../EmailEditor';

import { Email } from '../selectors';
import { IEmailMessageBody, IEmailAttachment } from '../../../api';
import { MixedContentBanner } from './mixed-content-banner';

const EmailContentWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

interface Props {
  email: Email;
  emailMessageBody: IEmailMessageBody;
  hasMixedContent: boolean;
  mixedContentAllowed: boolean;
  toggleMixedContent: () => void;
  onMailtoClick: (email: string) => void;
}

const EmailContent = ({
  email,
  emailMessageBody,
  hasMixedContent,
  mixedContentAllowed,
  onMailtoClick,
  toggleMixedContent,
}: Props) => {
  const emailMessageBodyId = get(emailMessageBody, 'id') as string;
  return (
    <EmailContentWrapper>
      <EmailInfo
        subject={email.subject || '(No Subject)'}
        avatarText={email.avatarText}
        avatarImage={email.avatarImage}
        avatarLocked={email.avatarLocked}
        timestamp={email.created}
        from={email.from}
        to={email.to}
        cc={email.cc}
        bcc={email.bcc}
      />

      {!mixedContentAllowed && hasMixedContent && (
        <MixedContentBanner toggleSetting={toggleMixedContent} />
      )}

      <EmailEditor
        key={emailMessageBodyId}
        value={get(emailMessageBody, 'body', '') as string}
        attachments={
          get(emailMessageBody, 'attachments', '') as IEmailAttachment[]
        }
        readOnly
        isLoading={!emailMessageBodyId}
        onMailtoClick={onMailtoClick}
      />
    </EmailContentWrapper>
  );
};

export default EmailContent;
