import * as React from 'react';
import styled from 'styled-components';
import { Field, FieldArray, Fields, BaseFieldProps } from 'redux-form';
import { Redirect } from 'react-router-dom';

import withContactFormData, {
  IStateFromProps,
  IStateProps,
} from './withContactFormData';
import withReduxForm from './withReduxForm';
import { IHasInitialDataSectionProps } from './Base/SectionWithRemovableField';
import AvatarSection, { IProps as IAvatarSectionProps } from './AvatarSection';
import NameCompanySection from './NameCompanySection';
import PhonesSection from './PhonesSection';
import EmailsSection from './EmailsSection';
import AddressSection from './AddressSection';
import BirthdaySection from './BirthdaySection';
import NotesSection from './OtherSection';
import DeleteContactSection from './DeleteContactSection';

export interface IProps extends IOwnProps, IStateFromProps, IStateProps {}

export interface IOwnProps extends IAvatarSectionProps {
  sudo: string;
  onSubmit: (data: any) => void;
  handleDeleteContact: (contactGuid: string) => void;
}

const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: calc(var(--theme-spacing) * 4);
`;

const AvatarSectionField = Field as new () => Field<
  IAvatarSectionProps & BaseFieldProps
>;
const AddressSectionFields = Fields as new () => Fields<
  IHasInitialDataSectionProps
>;
const BirthdaySectionField = Field as new () => Field<
  IHasInitialDataSectionProps & BaseFieldProps
>;
const NotesSectionField = Field as new () => Field<
  IHasInitialDataSectionProps & BaseFieldProps
>;

export const ContactForm = (props: IProps) => {
  const {
    isContactValid,
    sudo,
    onSubmit,
    avatarRef,
    hasInitialData,
    contactGuid,
    contactName,
    handleDeleteContact,
    isSudoContact,
  } = props;
  return contactGuid && !isContactValid ? (
    <Redirect to={`/${sudo}/contacts`} />
  ) : (
    <FormWrapper onSubmit={onSubmit}>
      <AvatarSectionField
        name="avatar"
        component={AvatarSection}
        avatarRef={avatarRef}
      />
      <Fields
        names={['first', 'last', 'company']}
        component={NameCompanySection}
      />
      <FieldArray name="phones" component={PhonesSection} />
      <FieldArray name="emails" component={EmailsSection} />
      <AddressSectionFields
        names={[
          'address.addressLine1',
          'address.addressLine2',
          'address.suburb',
          'address.state',
          'address.postcode',
          'address.country',
        ]}
        component={AddressSection}
        hasInitialData={hasInitialData.address}
      />
      <BirthdaySectionField
        name="birthday"
        component={BirthdaySection}
        hasInitialData={hasInitialData.birthday}
      />
      <NotesSectionField
        name="notes"
        component={NotesSection}
        hasInitialData={hasInitialData.notes}
      />
      {contactGuid && !isSudoContact && (
        <DeleteContactSection
          contactGuid={contactGuid}
          contactName={contactName}
          handleDeleteContact={handleDeleteContact}
        />
      )}
    </FormWrapper>
  );
};

const ContactFormWithReduxForm = withReduxForm(ContactForm as any) as any;

export default withContactFormData(ContactFormWithReduxForm as any) as any;
