import * as DOMPurify from 'dompurify';

// URL attributes
const attributes = ['action', 'background', 'href', 'poster', 'src'];

export interface IContext {
  currentLocation: Location;
  allowMixedContent: boolean;
  hasMixedContent: boolean;
}

/**
 * This hook will attempt to detect and optionally scrub out all mixed content from
 * an HTML string. Whether or not a URL is considered mixed is dependent on the location
 * configured in the context. This will be window.location by default.
 *
 * Note that this is a best effort and not guaranteed. In the case that mixed content does
 * get through, we rely on the browser to indicate to the user that there is mixed content.
 *
 * Inspiration for this hook here:
 * https://github.com/cure53/DOMPurify/tree/master/demos#hook-to-proxy-all-http-leaks-including-css-link
 * https://github.com/cure53/HTTPLeaks
 */
export const hook = (opts?: Partial<IContext>) => {
  const context: IContext = {
    // Inputs
    allowMixedContent: false,
    currentLocation: location,
    ...opts,

    // Outputs
    hasMixedContent: false,
  };

  /** DOMPurify hook to detect/scrub URLs from element attributes */
  DOMPurify.addHook('afterSanitizeAttributes', node => {
    // Check attributes that can contain urls
    attributes.forEach(attr => {
      if (node.hasAttribute(attr)) {
        if (node.tagName === 'A' && attr === 'href') {
          // Do not consider link targets as mixed
        } else {
          let attrVal = node.getAttribute(attr);
          attrVal = processUrl(context, attrVal);
          node.setAttribute(attr, attrVal);
        }
      }
    });

    // Check style attributes
    if (node.hasAttribute('style')) {
      const element = node as HTMLElement;
      const output: string[] = [];

      processStyles(context, output, element.style);
      node.setAttribute('style', output.join(''));
    }
  });

  /** DOMPurify hook to detect/scrub URLs from style sheets */
  DOMPurify.addHook('uponSanitizeElement', (node, data) => {
    if (data.tagName === 'style') {
      const styleEl = node as HTMLStyleElement;
      const sheet = styleEl.sheet as CSSStyleSheet;
      if (sheet) {
        const output: string[] = [];
        processCssRules(context, output, sheet.cssRules);
        node.textContent = output.join('\n');
      } else {
        // Disallow unparsable style
        node.textContent = '';
      }
    }
  });

  return context;
};

function processStyles(
  context: IContext,
  output: string[],
  styles: CSSStyleDeclaration,
) {
  const rules = Array.from(styles);
  rules.forEach(styleName => {
    let styleVal = styles[styleName as any];
    if (styleVal) {
      styleVal = replaceCssUrls(styleVal, url => processUrl(context, url));
      output.push(styleName + ':' + styleVal + ';');
    }
  });
}

function processCssRules(
  context: IContext,
  output: string[],
  cssRules: CSSRuleList,
) {
  if (!cssRules) {
    return;
  }

  Array.from(cssRules).forEach(rule => {
    if (rule.type === CSSRule.STYLE_RULE) {
      const styleRule = rule as CSSStyleRule;
      if (styleRule.selectorText) {
        output.push(styleRule.selectorText + '{');
        if (styleRule.style) {
          processStyles(context, output, styleRule.style);
        }
        output.push('}');
      }
    } else if (rule.type === CSSRule.MEDIA_RULE) {
      const mediaRule = rule as CSSMediaRule;
      output.push('@media ' + mediaRule.media.mediaText + '{');
      processCssRules(context, output, mediaRule.cssRules);
      output.push('}');
    } else if (rule.type === CSSRule.FONT_FACE_RULE) {
      const fontFaceRule = rule as CSSFontFaceRule;
      output.push('@font-face {');
      if (fontFaceRule.style) {
        processStyles(context, output, fontFaceRule.style);
      }
      output.push('}');
    } else if (rule.type === CSSRule.KEYFRAMES_RULE) {
      const keyframesRule = rule as CSSKeyframesRule;
      output.push('@keyframes ' + keyframesRule.name + '{');
      Array.from(keyframesRule.cssRules).forEach(keyFramesRule => {
        if (keyFramesRule.type === CSSRule.KEYFRAME_RULE) {
          const keyframeRule = keyFramesRule as CSSKeyframeRule;
          if (keyframeRule.keyText) {
            output.push(keyframeRule.keyText + '{');
            if (keyframeRule.style) {
              processStyles(context, output, keyframeRule.style);
            }
            output.push('}');
          }
        }
      });
      output.push('}');
    }
  });
}

export function replaceCssUrls(
  input: string,
  replacer: (url: string) => string,
) {
  // Regexp matches a prefix consisting of `/url(` and then an optional quote mark.
  // It will then match all content until the next occurance of a quote mark or closing parenthesis.
  // This is intended to be compatible with https://www.w3.org/TR/css-syntax-3/#consume-a-url-token
  // TODO (LIMITATION!): It will not work with CSS escaped codepoints, e.g.
  //   \75 \72 \6C (https://url) or
  //   \000075\000072\00006C(https://url)
  const re = /url\((['"])?([^'")]*)/g;

  return input.replace(re, (_, startQuote, url) => {
    const prefix = 'url(';
    const quote = startQuote || '';
    const replacement = replacer(url);
    return prefix + quote + replacement;
  });
}

export function processUrl(context: IContext, urlStr: string) {
  if (context.currentLocation.protocol === 'http:') {
    return urlStr;
  }

  let url: URL;
  try {
    url = new URL(urlStr, context.currentLocation.href);
  } catch {
    return '';
  }

  if (url.protocol === 'http:') {
    context.hasMixedContent = true;
    return !context.allowMixedContent ? '' : urlStr;
  } else {
    return urlStr;
  }
}
