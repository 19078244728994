import * as React from 'react';
import styled from 'styled-components';
import { Icon } from 'src/components';
import { mapProps } from 'recompose';
import { RouteComponentProps } from 'react-router-dom';

import Empty from '../../../features/Empty';
import MessagesEmptyIcon from '../../../icons/MessagesEmpty';
import withEmptyContentData, { IStateFromProps } from './withEmptyContentData';

export interface IMatch {
  sudo: string;
}

const EMPTY_TEXT_CONTENT = [
  'To create a new message, click "',
  <Icon key={0} name="Compose" />,
  '" above.',
];

const EmptyWrapper = styled.div`
  width: 100%;
`;

const TipContainer = styled.div`
  margin-top: 50px;
`;

const UnreadMessageContent = styled.span`
  & > span {
    font-weight: 600;
  }
`;

export const EmptyContent = (props: IStateFromProps) => {
  return (
    <EmptyWrapper>
      {props.noPhoneAccounts ? (
        <Empty
          icon={<MessagesEmptyIcon />}
          title="Looks like you don't have a Sudo Number"
          content={
            <React.Fragment>
              Use MySudo to add a new phone line and start sending messages
              <TipContainer>
                {props.sudoLabel} > Add a Phone Number
              </TipContainer>
            </React.Fragment>
          }
        />
      ) : props.isListEmpty ? (
        <Empty
          icon={<MessagesEmptyIcon />}
          title="You gotta step it up!"
          content="The average person sends 9 texts per day."
        />
      ) : props.messagesUnread ? (
        <Empty
          icon={<MessagesEmptyIcon />}
          title="It's time to catch up a little bit."
          content={
            <UnreadMessageContent>
              You have <span>{props.messagesUnread} unread messages</span>
            </UnreadMessageContent>
          }
        />
      ) : (
        <Empty
          icon={<MessagesEmptyIcon />}
          title="You're all caught up!"
          content="Select a thread to reply and read previous messages"
        />
      )}
    </EmptyWrapper>
  );
};

export const propsMapper = (props: RouteComponentProps<IMatch>) => ({
  sudoSlug: props.match.params.sudo,
});
const mapRouterProps = mapProps(propsMapper);

export default mapRouterProps(withEmptyContentData(EmptyContent));
