import React from 'react';
import styled from 'styled-components';

interface IProps {
  children?: React.ReactNode;
}

const StyledMessageBubbleTimestamp = styled.div`
  font-size: 10px;
  font-weight: bold;
  margin: 8px 0 0;
`;

const MessageBubbleTimestamp = (props: IProps) =>
  props.children ? (
    <StyledMessageBubbleTimestamp>
      {props.children}
    </StyledMessageBubbleTimestamp>
  ) : null;

export default MessageBubbleTimestamp;
