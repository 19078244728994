import React from 'react';

export default function Messages(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      {...props}
    >
      <g fill="currentColor" fillRule="evenodd" transform="translate(3 3)">
        <path d="M2.392 17.567l.115-3.796C.91 12.268 0 10.267 0 8.137 0 3.645 4.028 0 9 0s9 3.645 9 8.137c0 4.492-4.028 8.136-9 8.136a9.877 9.877 0 0 1-2.714-.376L2.76 17.795a.25.25 0 0 1-.369-.228z" />
      </g>
    </svg>
  );
}
