import { IEmailMessageBody } from '../../../api';

const isEmailMessageBodyEmpty = (
  emailMessageBody: IEmailMessageBody,
): boolean => {
  if (!emailMessageBody) {
    return true;
  }
  const { body, attachments } = emailMessageBody;
  const hasText = body && body !== '<br/>';
  const hasAttachments = !!(attachments && attachments.length);
  return !hasText && !hasAttachments;
};

export default isEmailMessageBodyEmpty;
