import {
  EntityChange,
  EntityType,
  IEntity,
  IMessage,
  IMessageConversation,
  MessageStatus,
} from '../api';
import * as Entities from '../api/entities';
import { TEMP_GUID_PREFIX } from '../config';
import * as Selectors from '../selectors';
import { IEntities, IRootState } from '../state';
import { IAction, IThunk } from './actions-base';
import * as ListActions from './list-actions';
import { handleRemoteTypingChange } from './messages-reset-is-typing';
import { processEntity } from './process-entity-action';
import { actions as messagesErrorsActions } from '../pages/Messages/MessagesErrors/modules';
import { IDispatcher } from '../store';
import SudoSelectors from '../entities/sudo/selectors';
import SudoSettingsSelectors from '../entities/sudo-settings/selectors';
import MessageSelectors from '../entities/message/selectors';
import { processEntityUpdate } from './process-entity-update-action';
import PhoneAccountSelectors from '../entities/phone-account/selectors';

export const NOTIFY_ENTITY = 'NOTIFY_ENTITY_ACTION';

export interface INotifyEntityAction extends IAction {
  type: 'NOTIFY_ENTITY_ACTION';
  payload: INotifyInfo;
}

export interface INotifyInfo {
  change: 'INSERT' | 'UPDATE' | 'DELETE';
  entityType: EntityType;
  entityGuid: string;
  oldEntity: IEntity; // null if not already in store
  newEntity: IEntity; // null if deleting
  allEntities: IEntities;
}

/**
 * Dispatched for any entities that need to be retained in the main entity store
 */
export function notifyEntity(change: EntityChange): IThunk<void> {
  return async (dispatch, getState, app) => {
    const initialState = getState();

    const allEntities = initialState.entities;
    const notifyInfo = {
      change: change.change,
      entityGuid: change.entity.id,
      entityType: change.entity.type,
      newEntity: change.change === 'DELETE' ? null : change.entity,
      oldEntity: allEntities.getIn([change.entity.type, change.entity.id]),
      allEntities,
    };

    // update store with new or modified entities first
    if (change.change !== 'DELETE') {
      dispatch(processEntity(change.entity as IEntity));
    }

    // Dispatch the notify action - this will allow reducers to
    // react to the changes. Don't handle notifications for deleted
    // entities that aren't in our store.
    if (change.change !== 'DELETE' || notifyInfo.oldEntity) {
      dispatch({
        type: 'NOTIFY_ENTITY_ACTION',
        payload: notifyInfo,
      } as INotifyEntityAction);
    }

    // Do entity-specific handling
    const updatedNotifyInfo = {
      ...notifyInfo,
      allEntities: getState().entities,
    };
    onEntityChanged(dispatch, getState(), updatedNotifyInfo, initialState);

    // remove any deleted entities from the store
    if (change.change === 'DELETE') {
      dispatch(processEntity(change.entity, true));
    }
  };
}

// Entity-specific handling
function onEntityChanged(
  dispatch: IDispatcher,
  state: IRootState,
  notifyInfo: INotifyInfo,
  prevState: IRootState,
) {
  switch (notifyInfo.entityType) {
    case 'Sudo': {
      if (notifyInfo.change === 'DELETE') {
        const oldSudo = SudoSelectors.getEntityById(state, {
          id: notifyInfo.entityGuid,
        });
        if (oldSudo) {
          dispatch(processEntity(oldSudo, true));
        }
      }
      return;
    }

    case 'SudoSettings': {
      if (notifyInfo.change === 'DELETE') {
        const oldSudoSettings = SudoSettingsSelectors.getEntityById(state, {
          id: notifyInfo.entityGuid,
        });
        if (oldSudoSettings) {
          dispatch(processEntity(oldSudoSettings, true));
        }
      }
      return;
    }

    case 'MessagingThread': {
      const conversation =
        notifyInfo.change === 'DELETE'
          ? (notifyInfo.oldEntity as IMessageConversation)
          : (notifyInfo.newEntity as IMessageConversation);

      const listIds = [];
      if (conversation) {
        const phoneAccount = PhoneAccountSelectors.getEntityById(state, {
          id: conversation.parent.id,
        });
        if (phoneAccount) {
          listIds.push(
            Selectors.getConversationList(state.lists, phoneAccount).id,
          );
        }
      }

      if (notifyInfo.change === 'INSERT') {
        listIds.forEach(listId => {
          dispatch({
            type: ListActions.LIST_INSERT,
            payload: {
              listId,
              itemIds: [notifyInfo.entityGuid],
            },
          } as ListActions.IListInsertAction);
        });
      } else if (notifyInfo.change === 'DELETE') {
        listIds.forEach(listId => {
          dispatch({
            type: ListActions.LIST_REMOVE,
            payload: {
              listId,
              itemId: notifyInfo.entityGuid,
            },
          } as ListActions.IListRemoveAction);
        });

        // Delete message list for this conversation
        const messageList = Selectors.getMessageList(
          state.lists,
          notifyInfo.entityGuid,
        );
        dispatch({
          type: ListActions.LIST_DISPOSE,
          payload: {
            listId: messageList.id,
          },
        } as ListActions.IListDisposeAction);
      } else if (notifyInfo.change === 'UPDATE') {
        if (
          !notifyInfo.oldEntity ||
          notifyInfo.oldEntity.modified !== notifyInfo.newEntity.modified
        ) {
          listIds.forEach(listId => {
            dispatch({
              type: ListActions.LIST_MOVE_TO_TOP,
              payload: {
                listId,
                itemId: notifyInfo.entityGuid,
              },
            } as ListActions.IListMoveToTopAction);
          });
        }

        dispatch(
          handleRemoteTypingChange(
            notifyInfo.newEntity as Entities.IMessageConversation,
            notifyInfo.oldEntity as Entities.IMessageConversation,
          ),
        );
      }
      return;
    }

    case 'Message': {
      let change = notifyInfo.change;

      // There is a bug in ios app, there could be multiple 'INSERT Message' notifications
      if (change === 'INSERT') {
        const messageToInsert = notifyInfo.newEntity as Entities.IMessage;
        const existingMessage = MessageSelectors.getEntityById(prevState, {
          id: messageToInsert.id,
        });
        if (existingMessage) {
          change = 'UPDATE';
        }
      }

      if (change === 'INSERT') {
        const newVersion = notifyInfo.newEntity as Entities.IMessage;
        const clientRefId = newVersion.localRefId || '';
        if (
          clientRefId.startsWith(TEMP_GUID_PREFIX) &&
          !newVersion.id.startsWith(TEMP_GUID_PREFIX)
        ) {
          // This message was sent by SAW.

          // Update any temporary attachments that we have saved
          // TODO: These changes are still lost when ios app sends DELETE/INSERT request to replace the message
          const oldMessage = MessageSelectors.getEntityById(state, {
            id: clientRefId,
          });
          if (oldMessage && oldMessage.media) {
            const mediaToUpdate = newVersion.media.map((att, i) => {
              const updated = { ...att };
              if (
                !att.data &&
                oldMessage.media[i] &&
                oldMessage.media[i].data
              ) {
                updated.data = oldMessage.media[i].data;
              }
              return updated;
            });
            if (mediaToUpdate.length > 0) {
              const messageToUpdate = {
                type: 'Message',
                id: newVersion.id,
                media: mediaToUpdate,
              } as IMessage;
              dispatch(processEntityUpdate(messageToUpdate));
            }
          }

          // Replace item in list for temp id, otherwise append it
          const list = Selectors.getMessageList(
            state.lists,
            newVersion.parent.id,
          );
          dispatch({
            type: ListActions.LIST_REPLACE_OR_APPEND,
            payload: {
              listId: list.id,
              oldId: newVersion.localRefId,
              newId: notifyInfo.entityGuid,
            },
          } as ListActions.IListReplaceOrAppendAction);

          // Remove this message's temporary counterpart
          dispatch(
            processEntity({ type: 'Message', id: newVersion.localRefId }, true),
          );
        } else {
          // This is a placeholder message, add it to the list. It will be replaced by the
          // actual entity once we are notified about it from the device
          const insertedMessage = notifyInfo.newEntity as Entities.IMessage;
          const list = Selectors.getMessageList(
            state.lists,
            insertedMessage.parent.id,
          );
          dispatch({
            type: ListActions.LIST_INSERT,
            payload: {
              listId: list.id,
              itemIds: [notifyInfo.entityGuid],
            },
          } as ListActions.IListInsertAction);
        }
      } else if (change === 'UPDATE') {
        // Detect if a message that was sent from SAW has failed
        const oldVersion = notifyInfo.oldEntity as Entities.IMessage;
        const newVersion = notifyInfo.newEntity as Entities.IMessage;
        const clientRefId = newVersion.localRefId || '';
        if (
          oldVersion &&
          oldVersion.messageStatus !== newVersion.messageStatus &&
          clientRefId.startsWith(TEMP_GUID_PREFIX) &&
          newVersion.messageStatus === MessageStatus.Failed
        ) {
          dispatch(
            messagesErrorsActions.addError({
              type: 'MESSAGE_SEND_FAIL',
              message:
                'There was a problem sending your message. Please try again.',
            }),
          );
        }
      } else if (change === 'DELETE') {
        const oldMessage = state.entities.Message.get(notifyInfo.entityGuid);
        if (oldMessage && oldMessage.parent) {
          const list = Selectors.getMessageList(
            state.lists,
            oldMessage.parent.id,
          );
          dispatch({
            type: ListActions.LIST_REMOVE,
            payload: {
              listId: list.id,
              itemId: oldMessage.id,
            },
          } as ListActions.IListRemoveAction);
        }
      }

      return;
    }

    default:
  }
}
