import * as React from 'react';
import cn from 'classnames';
import styled from 'styled-components';
import Button, { IProps as ButtonProps } from 'src/components/Button';
import CheckBox, { IProps as CheckBoxProps } from 'src/components/CheckBox';

interface IProps {
  checkbox?: CheckBoxProps;
  button?: ButtonProps;
  className?: string;
  children?: React.ReactNode;
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledCheckBox = styled(CheckBox)`
  width: 14px;
  height: 14px;
  margin: 0 8px;
`;

const StyledButton = styled(Button)`
  font-size: 16px;
  line-height: 19px;
  color: #303030;
`;

class ButtonWithCheckBox extends React.Component<IProps> {
  private readonly checkboxRef: any;

  constructor(props: IProps) {
    super(props);
    this.checkboxRef = (React as any).createRef();
  }

  public render() {
    const props = this.props;
    return (
      <Wrapper className={cn(props.className)}>
        <StyledCheckBox {...props.checkbox} inputRef={this.checkboxRef} />
        <StyledButton
          {...props.button}
          onClick={() => this.checkboxRef.current.click()}
        >
          {props.children}
        </StyledButton>
      </Wrapper>
    );
  }
}

export default ButtonWithCheckBox;
