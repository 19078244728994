import * as React from 'react';
import { Icon } from 'src/components';
import styled from 'styled-components';

import { IProps, IEmptyStates } from './types';

const Bold = styled.b`
  font-weight: 800;
`;

const emptyState = {
  title: (props: IProps) => (
    <React.Fragment>
      You have{' '}
      <Bold>
        {props.unreadCount} unread email{props.unreadCount > 1 && 's'}
      </Bold>
    </React.Fragment>
  ),
  content: 'Select a thread to reply or read previous emails.',
};

const EMPTY_STATES: IEmptyStates = {
  inbox: emptyState,
  sent: emptyState,
  drafts: emptyState,
  trash: emptyState,
};

export default EMPTY_STATES;
