import * as React from 'react';
import styled from 'styled-components';
import { withStateHandlers } from 'recompose';

import { AddItemButton, SectionWrapper, Section } from '../BaseSectionElements';
import RemovableRow from '../RemovableRow';

interface IProps extends IOwnProps, IWithHandlersProps {}

export interface IHasInitialDataSectionProps {
  hasInitialData: boolean;
}

interface IOwnProps extends IHasInitialDataSectionProps {
  addItemButtonText: string;
  children: any;
}

interface IWithHandlersProps {
  isFieldAdded: boolean;
  isTouched: boolean;
  onToggleAddField: (isFieldAdded: boolean) => void;
}

const SingleFieldRemovableRow = styled(RemovableRow)`
  margin-bottom: 0;
`;

export const SectionWithRemovableFieldComponent = (props: IProps) => {
  const {
    isFieldAdded,
    hasInitialData,
    isTouched,
    onToggleAddField,
    children,
    addItemButtonText,
  } = props;
  return (
    <SectionWrapper>
      <Section>
        {isFieldAdded || (hasInitialData && !isTouched) ? (
          <SingleFieldRemovableRow onRemoveRow={() => onToggleAddField(false)}>
            {children}
          </SingleFieldRemovableRow>
        ) : (
          <AddItemButton
            iconName="AddItemOutline"
            iconColor="var(--blue)"
            onClick={() => onToggleAddField(true)}
          >
            {addItemButtonText}
          </AddItemButton>
        )}
      </Section>
    </SectionWrapper>
  );
};

export const withOpenState = withStateHandlers<any, any, IOwnProps>(
  {
    isFieldAdded: false,
    isTouched: false,
  },
  {
    onToggleAddField: () => (isFieldAdded: boolean) => ({
      isFieldAdded,
      isTouched: true,
    }),
  },
);

const SectionWithRemovableField = withOpenState(
  SectionWithRemovableFieldComponent,
);

export default SectionWithRemovableField;
