import * as React from 'react';
import styled from 'styled-components';
import { Button, InputMultiLine, Popover } from 'src/components';
import { Picker } from 'emoji-mart';
import withConversationInput, {
  IAttachmentData,
  IComposedProps,
} from './withConversationInput';
import {
  IMessage,
  supportedMultimediaExtensions,
  supportedMultimediaFilter,
} from '../../../../api';
import { getDataUrlFromFile } from '../../../../utils';
import { AttachmentToolbar } from './AttachmentToolbar';
import { IMessageComposition } from '../../../../state';

export interface IProps extends IOwnProps, IComposedProps {}

export interface IInputToolbarValue {
  message: string;
  attachments: string[];
  editedMessage: IMessage;
}

export interface IOwnProps {
  autoFocus: boolean;
  isCanWrite: boolean;
  inputPlaceholder: string;
  onSubmit: () => void;
  onChange: (value: IInputToolbarValue) => void;
  isNoClearOnSubmit?: boolean;
  isNoEmptySubmit?: boolean;
  composition?: IMessageComposition;
}

export interface IAttachment extends IAttachmentData {
  onRemove: () => void;
}

const ToolbarWrapper = styled.div`
  border-top: 1px solid var(--theme-divider-color);
  box-shadow: inset -0.5px -0.5px 0 0 var(--gray-5);
`;

const AttachmentFileInput = styled.input`
  display: none;
`;

const MessagesToolbar = styled.div`
  display: flex;
  align-items: flex-end;
`;

const MessageInputWrapper = styled.div`
  flex-grow: 1;
`;

const MessagesActionsWrapper = styled.div`
  display: flex;
  margin-bottom: 16px;
  > * {
    margin-right: calc(var(--theme-spacing) * 2);
  }
`;

const EmojiPickerPopover = styled(Popover)`
  width: initial;
  background: initial;
  box-shadow: initial;
  padding: 0;
`;

const PickerWrapper = styled.div`
  > :first-child {
    box-sizing: content-box;
  }
`;

export class InputToolbar extends React.Component<IProps> {
  private readonly attachmentFileInput: any;

  constructor(props: IProps) {
    super(props);
    this.attachmentFileInput = (React as any).createRef();
  }

  public componentDidUpdate(prevProps: IProps) {
    if (
      this.props.composition &&
      prevProps.composition &&
      this.props.composition.message &&
      this.props.composition.message !== prevProps.composition.message
    ) {
      this.props.onSetFromComposition(this.props.composition);
    }
  }

  public openFileInput = () => {
    this.attachmentFileInput.current.click();
  };

  public onFilesReceived = async (files: FileList) => {
    const { addAttachment, inputRef, onError } = this.props;
    const file = files[0];

    if (file.size > 10 * 1024 * 1024) {
      onError(
        'ATTACHMENT_SIZE',
        'The file you are sending is too large. Please try sending a smaller one.',
      );
      return;
    }

    if (!supportedMultimediaExtensions.includes(file.type)) {
      // Invalid file type
      const extension = '.' + file.name.split('.').slice(-1) || 'Unknown';
      onError(
        'ATTACHMENT_EXTENSION',
        `Sorry, "${extension}" file type is not supported`,
      );
      return;
    }

    // Browsers incorrectly work with 'video/quicktime' type
    let confirmedFile = file;
    if (file.type === 'video/quicktime') {
      confirmedFile = new File([file], file.name, { type: 'video/mp4' });
    }

    let data: string;
    try {
      data = await getDataUrlFromFile(confirmedFile);
    } catch (err) {
      onError(
        'ATTACHMENT_PROCESSING',
        'Sorry, an error occurred processing the file.',
      );
      return;
    }

    addAttachment(data);
    inputRef.focus();
  };

  public onFileInputChanged = (
    event: React.SyntheticEvent<HTMLInputElement>,
  ) => {
    const target = event.currentTarget;

    this.onFilesReceived(target.files);

    // Clear the file picker value, otherwise we won't get a change notification
    // if the user tries to pick the same file again.
    if (this.attachmentFileInput) {
      this.attachmentFileInput.current.value = null;
    }
  };

  public render() {
    const props = this.props;
    return (
      <ToolbarWrapper>
        {props.attachments.length > 0 && (
          <AttachmentToolbar
            attachments={props.attachments}
            removeAttachment={props.removeAttachment}
          />
        )}
        <MessagesToolbar>
          <MessageInputWrapper>
            <InputMultiLine
              inputRef={props.setInputRef}
              value={props.inputValue}
              onChange={props.onInputChange}
              onKeyDown={props.onInputKeyDown}
              placeholder={props.inputPlaceholder}
              maxRows={15}
              maxLength={1600}
              disabled={!props.isCanWrite}
            />
          </MessageInputWrapper>
          <MessagesActionsWrapper>
            {!this.props.editedMessage && (
              <React.Fragment>
                <AttachmentFileInput
                  ref={this.attachmentFileInput}
                  type="file"
                  onChange={this.onFileInputChanged}
                  accept={supportedMultimediaFilter}
                />
                <Button
                  iconName="Attachment"
                  iconColor="var(--gray-3)"
                  onClick={this.openFileInput}
                  disabled={!props.isCanWrite}
                />
              </React.Fragment>
            )}
            <EmojiPickerPopover
              isOpen={props.isEmojiPickerOpen}
              onClose={props.toggleEmojiPicker}
              anchor={
                <Button
                  iconName="Emoji"
                  iconColor="var(--gray-3)"
                  onClick={props.toggleEmojiPicker}
                  disabled={!props.isCanWrite}
                />
              }
              positionHorizontal="end"
              positionVertical="end"
              originHorizontal="end"
              originVertical="end"
            >
              {props.isEmojiPickerOpen && (
                <PickerWrapper>
                  <Picker
                    native
                    set="apple"
                    title="Pick your emoji…"
                    emoji="point_up"
                    onSelect={props.onSelectEmoji}
                  />
                </PickerWrapper>
              )}
            </EmojiPickerPopover>
          </MessagesActionsWrapper>
        </MessagesToolbar>
      </ToolbarWrapper>
    );
  }
}

export default (withConversationInput(
  InputToolbar as any,
) as any) as React.ComponentClass<IOwnProps>;
