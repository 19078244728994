import React from 'react';
import styled from 'styled-components';

import MessageBubbleBase from '../MessageBubbleBase';
import MessageBubbleText from '../MessageBubbleText';
import MessageBubbleTimestamp from '../MessageBubbleTimestamp';

interface IProps {
  authorName?: string;
  children?: React.ReactNode;
  isBlurred?: boolean;
  isExternal?: boolean;
  timestamp?: React.ReactNode;
}

const StyledMessageBubble = styled(MessageBubbleBase)`
  color: var(--black);
  background: var(--gray-8);
  border-radius: 8px;
  border-bottom-left-radius: 0;
`;

const MessageAuthor = styled.div`
  padding-left: 10px;
  padding-bottom: 1px;
  font-size: 10px;
  font-weight: bold;
  color: var(--gray-3);
`;

const MessageBubbleReceived = (props: IProps) => (
  <div>
    {props.authorName && <MessageAuthor>{props.authorName}</MessageAuthor>}
    <StyledMessageBubble isExternal={props.isExternal}>
      <MessageBubbleText isBlurred={props.isBlurred}>
        {props.children}
      </MessageBubbleText>
      <MessageBubbleTimestamp>{props.timestamp}</MessageBubbleTimestamp>
    </StyledMessageBubble>
  </div>
);

MessageBubbleReceived.defaultProps = {
  authorName: null,
};

export default MessageBubbleReceived;
