import { IAction, nullAction } from '../actions';
import { TOGGLE_SIDE_PANEL } from '../actions';
import { ISidePanel, ISidePanelEntry, SidePanel } from '../state/side-panel';

export function sidePanelReducer(
  state: ISidePanel = SidePanel(),
  action: IAction = nullAction,
) {
  if (action.type === TOGGLE_SIDE_PANEL) {
    const payload: ISidePanelEntry & { name: string } = action.payload;
    const store = state.get(payload.name) as ISidePanelEntry;

    return state.set(payload.name, {
      isOpen:
        typeof payload.isOpen === 'undefined' ? !store.isOpen : payload.isOpen,
    });
  }

  return state;
}
