import { EntityType, ISudo } from '../api/entities';
import { createAction } from './actions-base';

export const LIST_FETCHING = 'LIST_FETCHING_ACTION';

export interface IListFetchingPayload {
  type: EntityType;
  sudo: ISudo;
  ref: string; // unique identifier associated with this fetch request
  tag?: any; // additional information to pass to reducer
}

export const listFetching = createAction<IListFetchingPayload>(LIST_FETCHING);
