import * as React from 'react';
import styled from 'styled-components';
import { Button } from 'src/components';
import Unpair from 'src/icons/outline/Unpair';
import ConfirmDialogVertical from '../../Modal/ConfirmDialogVertical';
import { connect } from 'react-redux';
import * as Actions from '../../../actions';
import { IDispatcher } from '../../../store';

const StyledUnpairIcon = styled(Unpair)`
  margin-right: 4px;
`;

const ButtonContent = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  font-size: 15px;
  font-weight: bold;
  color: var(--blue);
  line-height: 17px;
`;

// tslint:disable-next-line:no-empty-interface
interface IProps {
  logout: () => void;
}

interface IState {
  showModal: boolean;
}

export class LogoutButtonComponent extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      showModal: false,
    };
  }

  public render() {
    return (
      <React.Fragment>
        <Button onClick={this.showDialog}>
          <ButtonContent>
            <StyledUnpairIcon />
            Unpair
          </ButtonContent>
        </Button>
        <ConfirmDialogVertical
          title="Are you sure?"
          content="Unpairing will end your session."
          acceptText="Yes, unpair"
          cancelText="Cancel"
          onAccept={this.confirm}
          onCancel={this.cancel}
          isOpen={this.state.showModal}
        />
      </React.Fragment>
    );
  }

  private showDialog = () => {
    this.setState({ showModal: true });
  };

  private cancel = () => {
    this.setState({ showModal: false });
  };

  private confirm = () => {
    this.props.logout();
  };
}

export const LogoutButton = connect(
  null,
  (dispatch: IDispatcher) => ({
    logout: () => {
      dispatch(Actions.unpairAction());
    },
  }),
)(LogoutButtonComponent);
