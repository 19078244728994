import { IApp } from './app';
import { IAvatarImages } from './avatarImages';
import { IContacts } from './contacts';
import { IEntities } from './entities';
import { IListsState } from './list';
import { IMessages } from './messages';
import { IModal } from './modal';
import { IPartialState as ConversationCreationState } from '../pages/Messages/ConversationCreation/modules';
import { IPartialState as EmailsState } from '../pages/Emails/modules';
import { IPartialState as IErrorTrapState } from '../containers/error-trap-modal/modules';
import { IPartialState as MessagesErrorsState } from '../pages/Messages/MessagesErrors/modules';
import { IRedirectState } from '../features/Redirect/modules';
import { ISidePanel } from './side-panel';
import { Iterable } from 'immutable';

// TODO: move to root reducer to keep them in sync
export interface IRootState {
  app?: IApp;
  avatarImages?: IAvatarImages;
  contacts?: IContacts;
  conversationCreation?: ConversationCreationState;
  emails?: EmailsState;
  entities?: IEntities;
  errorTrap?: IErrorTrapState;
  lists?: IListsState;
  messages?: IMessages;
  messagesErrors?: MessagesErrorsState;
  modal?: IModal;
  redirect?: IRedirectState;
  sidePanel?: ISidePanel;
}

export function rootStateToJS(state: IRootState): IRootState {
  return Object.keys(state).reduce((newState: any, key: any) => {
    const val = (state as any)[key];

    (newState as any)[key] = Iterable.isIterable(val as any)
      ? (val as Iterable<any, any>).toJS()
      : val;

    return newState;
  }, {});
}
