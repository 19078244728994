import React from 'react';
import styled from 'styled-components';
import AvatarBase from '../AvatarBase';
import { IAvatar } from '../..';

interface IProps extends IAvatar {
  image: string[];
}

interface IAvatarGroupProps {
  backgroundColor: string;
  imagesGroup: string[];
}

const GroupCircle = styled(AvatarBase)`
  position: relative;
  width: calc(var(--theme-spacing) * 5);
  height: calc(var(--theme-spacing) * 5);
  min-width: calc(var(--theme-spacing) * 5);
  min-height: calc(var(--theme-spacing) * 5);
  border-radius: 50%;
  overflow: hidden;
`;

const AvatarBaseAbsolute = styled(AvatarBase)`
  margin: 0;
  position: absolute;
`;

const AvatarQuarter = styled(AvatarBaseAbsolute)`
  width: calc(var(--theme-spacing) * 2.5 - 1px);
  height: calc(var(--theme-spacing) * 2.5 - 1px);
  min-width: calc(var(--theme-spacing) * 2.5 - 1px);
  min-height: calc(var(--theme-spacing) * 2.5 - 1px);
`;

const AvatarHalf = styled(AvatarBaseAbsolute)`
  width: calc(var(--theme-spacing) * 2.5 - 1px);
  height: calc(var(--theme-spacing) * 5);
  min-width: calc(var(--theme-spacing) * 2.5 - 1px);
  min-height: calc(var(--theme-spacing) * 5);
`;

const AvatarLeftTop = styled(AvatarQuarter)`
  top: 0;
  left: 0;
`;

const AvatarRightTop = styled(AvatarQuarter)`
  top: 0;
  right: 0;
`;

const AvatarLeftBottom = styled(AvatarQuarter)`
  bottom: 0;
  left: 0;
`;

const AvatarRightBottom = styled(AvatarQuarter)`
  bottom: 0;
  right: 0;
`;

const AvatarLeft = styled(AvatarHalf)`
  top: 0;
  left: 0;
`;

const AvatarRight = styled(AvatarHalf)`
  top: 0;
  right: 0;
`;

const AvatarGroupOfTwo = (props: IAvatarGroupProps) => (
  <React.Fragment>
    <AvatarLeft
      image={props.imagesGroup[0]}
      backgroundColor={props.backgroundColor}
    />
    <AvatarRight
      image={props.imagesGroup[1]}
      backgroundColor={props.backgroundColor}
    />
  </React.Fragment>
);

const AvatarGroupOfThree = (props: IAvatarGroupProps) => (
  <React.Fragment>
    <AvatarRight
      image={props.imagesGroup[0]}
      backgroundColor={props.backgroundColor}
    />
    <AvatarLeftTop
      image={props.imagesGroup[1]}
      backgroundColor={props.backgroundColor}
    />
    <AvatarLeftBottom
      image={props.imagesGroup[2]}
      backgroundColor={props.backgroundColor}
    />
  </React.Fragment>
);

const AvatarGroupOfFour = (props: IAvatarGroupProps) => (
  <React.Fragment>
    <AvatarLeftTop
      image={props.imagesGroup[0]}
      backgroundColor={props.backgroundColor}
    />
    <AvatarRightTop
      image={props.imagesGroup[1]}
      backgroundColor={props.backgroundColor}
    />
    <AvatarLeftBottom
      image={props.imagesGroup[2]}
      backgroundColor={props.backgroundColor}
    />
    <AvatarRightBottom
      image={props.imagesGroup[3]}
      backgroundColor={props.backgroundColor}
    />
  </React.Fragment>
);

const AvatarGroupImages = (props: IAvatarGroupProps) => {
  return props.imagesGroup.length >= 4 ? (
    <AvatarGroupOfFour {...props} />
  ) : props.imagesGroup.length === 3 ? (
    <AvatarGroupOfThree {...props} />
  ) : props.imagesGroup.length === 2 ? (
    <AvatarGroupOfTwo {...props} />
  ) : null;
};

const AvatarGroup = (props: IProps) => (
  <GroupCircle>
    <AvatarGroupImages
      imagesGroup={props.image}
      backgroundColor={props.backgroundColor}
    />
  </GroupCircle>
);

AvatarGroup.displayName = 'AvatarGroup';

export default AvatarGroup;
