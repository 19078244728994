import React from 'react';

export default function Info(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      {...props}
    >
      <g fill="currentColor" fillRule="evenodd" transform="translate(2 2)">
        <path d="M10 19.75C4.615 19.75.25 15.385.25 10S4.615.25 10 .25s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75zm0-1.5a8.25 8.25 0 1 0 0-16.5 8.25 8.25 0 0 0 0 16.5zM10 6a1 1 0 1 1 0-2 1 1 0 0 1 0 2zm-.75 2a.75.75 0 0 1 1.5 0v7a.75.75 0 1 1-1.5 0V8z" />
      </g>
    </svg>
  );
}
