// tslint:disable:no-console

import { BehaviorSubject, Observable, Subject } from 'rxjs';
import {
  BaseApiError,
  ConnectionStatus,
  IBaseApi,
  ITransferProgress,
} from './base-api-interfaces';
import { BaseApi, IBaseApiOptions } from './base-api';
import { uuid } from './uuid';
import * as Msg from './message-interfaces';
import * as Base64 from './base64';
import { IUnencryptedPayload } from './message-interfaces';

/**
 * Main implementation of WebRTC API.
 * Uses BaseApi to pair and signalling.
 * Proxies BaseApi messages only for pair.
 */
export class RtcApi implements IBaseApi {
  // --------------------------------------------------------------------------
  // Properties

  /** Connection status stream */
  public get status$() {
    return this._statusSubject.asObservable();
  }
  private _statusSubject = new BehaviorSubject<ConnectionStatus>(
    'disconnected',
  );

  /** Current connection status */
  public get status(): ConnectionStatus {
    return this._statusSubject.value;
  }

  /** Stream of errors */
  public get errors$(): Observable<BaseApiError> {
    return this._errorsSubject$.asObservable();
  }
  private _errorsSubject$ = new Subject<BaseApiError>();

  /** Last error that was received */
  public get lastError(): BaseApiError {
    this.log('get lastError()', this._baseApi.lastError);
    return this._baseApi.lastError;
  }

  /** Assembled app-level and session messages */
  public readonly inbound$: Observable<Msg.IMessage>;

  /** IBaseApi.progress$ */
  public readonly progress$: Observable<ITransferProgress>;

  /** Data channel connection status stream */
  private get _rtcChannelStatus$(): Observable<RTCDataChannelState> {
    return this._rtcStatusSubject$.asObservable();
  }
  private _rtcStatusSubject$ = new BehaviorSubject<RTCDataChannelState>(
    'closed',
  );

  /** Data channel incoming message data */
  private get _rtcChannelData$(): Observable<any> {
    return this._rtcDataSubject$.asObservable();
  }
  private _rtcDataSubject$ = new Subject<any>();

  // --------------------------------------------------------------------------
  // Private State

  /** Options for configuring the baseApi instance */
  private _baseOpts?: Partial<IBaseApiOptions>;

  /** BaseApi instance for for pairing and signalling */
  private _baseApi: BaseApi;

  /** Current WebRTC Connection */
  private _connection: RTCPeerConnection | null = null;

  /** Current WebRTC Data Channel */
  private _channel: RTCDataChannel | null = null;

  /**
   * Ctor
   */
  constructor(baseOpts?: Partial<IBaseApiOptions>) {
    this._baseOpts = baseOpts;
    this._baseApi = new BaseApi(baseOpts);
    this.progress$ = this._baseApi.progress$;

    this.log('*** Using WebRTC as communication protocol');

    // Listen for remote ICE candidates
    this._baseApi.inbound$
      .filter(
        (msg: Msg.IMessage) => msg.type === 'candidate' && !msg.inResponseTo,
      )
      .subscribe((msg: Msg.IRtcCandidate) => {
        if (this._connection) {
          const candidate: RTCIceCandidate = JSON.parse(msg.candidate);
          this.log('Adding remote ICE candidate', candidate);
          this._connection.addIceCandidate(candidate);
        }
      });

    // assemble messages
    this.inbound$ = Observable.merge(
      this._baseApi.inbound$,
      this._rtcChannelData$
        .map(arrayBuffer => {
          const json = new TextDecoder().decode(arrayBuffer);
          return JSON.parse(json);
        })
        .flatMap(msg => {
          if (msg.type === 'message') {
            return this._baseApi.processChunk(msg as Msg.IChunkMessage);
          } else {
            const encryptedPayload = JSON.parse(
              Base64.decode(msg.message),
            ) as Msg.IEncryptedMessage['message'];
            return this._baseApi.decryptPayload(encryptedPayload).map(
              payload =>
                ({
                  type: 'message',
                  messageId: msg.messageId,
                  inResponseTo: msg.inResponseTo,
                  message: payload,
                } as Msg.IPayloadMessage),
            );
          }
        })
        .catch(err => Observable.of(null)) // swallow errors (TODO: log these)
        .filter(msg => !!msg), // filter out nulls returned by e() and catch()
    ).share();
  }

  /**
   * Connect to the session service for pairing
   */
  public connect(
    endpoint: string,
    symmetricKey?: string,
    attempts?: number,
  ): Observable<void> {
    return Observable.of(undefined)
      .do(() => this._statusSubject.next('connecting'))
      .flatMap(() =>
        this._baseApi
          .connect(
            endpoint,
            symmetricKey,
            attempts,
          )

          // Merge in any connection errors to this stream
          .merge(
            this._baseApi.errors$
              .filter(err => err !== null)
              .delay(0) // Allow status observable to synchronize
              .map(err => {
                throw err;
              }),
          )

          // Retry the above sequence on CONNECTION_LOST, otherwise rethrow any errors
          .retryWhen(errors$ =>
            errors$.map(err => {
              const shouldRetry =
                err instanceof BaseApiError &&
                err.message === 'CONNECTION_LOST';
              if (!shouldRetry) {
                throw err;
              }
            }),
          ),
      )
      .do(() => this._statusSubject.next('connected'));
  }

  /**
   * Main sequence for establishing a connection with WebRTC
   */
  public connectData(
    endpoint: string,
    symmetricKey?: string,
    attempts?: number,
  ): Observable<Msg.IBindNotification> {
    let bindingNotification: Msg.IBindNotification;

    return Observable.of(undefined)
      .do(() => this._statusSubject.next('connecting'))
      .flatMap(() =>
        this._baseApi.connect(
          endpoint,
          symmetricKey,
          attempts,
        ),
      )
      .flatMap(() => this._baseApi.waitForBindingNotification())
      .do(bn => {
        bindingNotification = bn;
        this.createConnectionAndChannel(bn);
      })
      .flatMap(this.negotiateSdp)
      .flatMap(this.waitForRtcChannelOpen)
      .do(() => this._statusSubject.next('connected'))
      .map(() => bindingNotification);
  }

  /// IBaseApi.generateMessageId
  public generateMessageId() {
    return this._baseApi.generateMessageId();
  }

  /**
   * Create new data channel
   */
  private createConnectionAndChannel = (
    bindingNotification: Msg.IBindNotification,
  ) => {
    // SessionService sends iceConfig in deprecated format
    const transformedIceServers = bindingNotification.iceConfig.map(server => {
      if ((server as any).url && !server.urls) {
        const { url, ...otherServerProps } = server as any;
        return {
          ...otherServerProps,
          urls: url,
        };
      }
      return server;
    });
    this._connection = new RTCPeerConnection({
      iceServers: transformedIceServers,
      bundlePolicy: 'balanced',
    });

    this._channel = this._connection.createDataChannel('MainDataChannel', {
      ordered: true,
    });

    this._channel.binaryType = this._baseApi.opts.binaryEncoding.binaryType;
    this._channel.onopen = () => {
      // Don't start sending messages within first 300 ms of data channel open event
      // Sometimes first messages are not received
      setTimeout(() => {
        this._rtcStatusSubject$.next('open');
      }, 300);
    };
    this._channel.onclose = () => {
      if (this._connection) {
        this._connection.close();
      }
      this._rtcStatusSubject$.next('closed');
    };
    this._channel.onerror = event => {
      this.log('DATA CHANNEL ERROR:', event);
    };
    this._channel.onmessage = event => {
      this.log('DATA CHANNEL ONMESSAGE:', event.data);
      this._rtcDataSubject$.next(event.data);
    };

    // Forward all ICE candidates to remote
    this._connection.onicecandidate = (event: RTCPeerConnectionIceEvent) => {
      if (event.candidate && this._baseApi) {
        this._baseApi
          .send({
            type: 'candidate',
            candidate: JSON.stringify(event.candidate),
          } as any) // TODO: Typings
          .subscribe(); // Fire and forget
      }
    };

    this._connection.oniceconnectionstatechange = e => {
      this.log('ICE CONNECTION STATE CHANGE:', {
        iceConnectionState: this._connection.iceConnectionState,
        iceGatheringState: this._connection.iceGatheringState,
        signalingState: this._connection.signalingState,
      });

      if (this._connection.iceConnectionState === 'failed') {
        this.setError(new BaseApiError('CONNECTION_FAILED', null, null));
      }
    };
  };

  /**
   * Establishes SDP protocol description between local and remote ends.
   */
  private negotiateSdp = (): Observable<void> => {
    return Observable.defer(() => {
      return Observable.from(this._connection.createOffer())
        .do(offer => {
          // Set the offer description locally. Once this is done
          // we will beging to receive ICE candidates.
          // This are handled with `processLocalIceCandidates()`
          return this._connection.setLocalDescription(offer);
        })
        .flatMap(offer => {
          // Send the offer to the remote. The remote should respond
          // with a SDP answer, or a failure message.
          // TODO: Remote is not returning error messages currently
          return this._baseApi.send({
            type: 'sdp',
            message: JSON.stringify(offer),
          } as any);
        })
        .flatMap((response: Msg.IRtcDescription) => {
          // Set the remote session description
          const remoteOfferJson = response.message;
          const remoteOffer: RTCSessionDescriptionInit = JSON.parse(
            remoteOfferJson,
          );
          this.log('Received offer response', remoteOffer);
          return this._connection.setRemoteDescription(remoteOffer);
        });
    });
  };

  /**
   * Emits when RTC channel is opened
   */
  private waitForRtcChannelOpen = (): Observable<void> => {
    return this._rtcChannelStatus$
      .filter(status => status === 'open')
      .do(() => this.log('DATA CHANNEL IS OPENED'))
      .map(() => undefined)
      .take(1);
  };

  /**
   * Disconnects the WebRTC connection and frees any resources
   */
  public disconnect(): Observable<void> {
    return Observable.of(undefined)
      .flatMap(() => {
        // Close RTCDataChannel on disconnect
        if (
          this._channel &&
          (this._channel.readyState === 'connecting' ||
            this._channel.readyState === 'open')
        ) {
          this._channel.close();
        }

        if (
          this._baseApi.status === 'connected' ||
          this._baseApi.status === 'connecting'
        ) {
          return this._baseApi.disconnect();
        } else {
          return Observable.of(undefined);
        }
      })
      .do(() => {
        this._statusSubject.next('disconnected');
      });
  }

  /**
   * Sends a message to the Session Service
   */
  public send(msg: Msg.IMessage, timeout?: number): Observable<Msg.IMessage> {
    return this._baseApi.send(msg, timeout);
  }

  /** Sends a request and emits a single response */
  public sendData(
    msg: Msg.IMessage,
    timeout?: number,
  ): Observable<Msg.IPayloadMessage> {
    const request = {
      ...msg,
      messageId: 'AS' + uuid(),
    };

    this.log('SEND:', request);

    // Wait for and then emit the response
    // Seems like we're currently getting encrypted wrappers
    // TODO: Can probably factor this out into a separate method
    return this.sendMessage(request)
      .flatMapTo(
        this.inbound$
          .filter(response => response.inResponseTo === request.messageId)
          .do(response => this.log('RESPONSE: ', response)),
      )
      .take(1) as Observable<Msg.IPayloadMessage>;
  }

  /**
   * Attempts to send a message.
   * Packaged as a cold observable to allow rxjs retries.
   */
  private sendMessage(msg: Msg.IMessage): Observable<void> {
    return Observable.defer(() => {
      let messageChunks$: Observable<Msg.IChunkMessage[]>;
      if (msg.type === 'message') {
        // Messages with type `message` are "payload messages".
        // They are encrypted and serialized into chunks.
        // The payloads are opaque to the server.
        const payloadMessage = msg as Msg.IPayloadMessage;
        const payload = payloadMessage.message as Msg.IUnencryptedPayload;
        messageChunks$ = this.encryptPayload(payload)
          .map(
            encryptedPayload =>
              ({
                ...(payloadMessage as any),
                message: encryptedPayload,
              } as Msg.IEncryptedMessage),
          )
          .map(encryptedMessage =>
            this._baseApi.chunkifyMessage(encryptedMessage),
          )
          .do(chunkPayload => this.log('chunked payload:', chunkPayload));
      } else {
        // Non "message" type messages are not encrypted and are sent as a single chunk.
        // These messages can have special meaning for the service (register, logout, etc.)
        messageChunks$ = Observable.of([msg] as Msg.IChunkMessage[]);
      }

      return messageChunks$
        .do(messageChunks => {
          messageChunks.forEach(m => {
            const json = JSON.stringify(m);
            this._baseApi.opts.binaryEncoding.encode(
              json,
              (err: any, binary?: any) => {
                this.log('DATA CHANNEL SEND:', binary);
                this._channel.send(binary);
              },
            );
          });
        })
        .mapTo(undefined);
    });
  }

  /**
   * No encryption over WebRTC
   */
  private encryptPayload(
    payload: IUnencryptedPayload,
  ): Observable<IUnencryptedPayload> {
    return Observable.fromPromise(Promise.resolve(payload));
  }

  /**
   * Sets and notifies an error.
   */
  private setError(error: BaseApiError) {
    // Order is important here
    this._errorsSubject$.next(error);
    this._statusSubject.next('error');
  }

  /**
   * Logging helper
   */
  private log(...args: any[]) {
    if (this._baseOpts && this._baseOpts.loggingEnabled) {
      // tslint:disable-next-line:no-console
      console.log.apply(null, ['[RTCAPI]'].concat(args));
    }
  }

  public wakeUpMaster() {
    return this._baseApi.wakeUpMaster();
  }
}
