import { Observable } from 'rxjs';
import {
  IMessage,
  IPayloadMessage,
  IBindNotification,
} from './message-interfaces';

export type ConnectionStatus =
  | 'disconnected'
  | 'connected'
  | 'connecting'
  | 'error';

export const NO_TIMEOUT = -1;

export type BaseApiErrorCode =
  | 'ALREADY_CONNECTED' // connect() failed because not in disconnected or error state
  | 'BAD_MESSAGE' // malformed message received from host
  | 'CONNECTION_FAILED' // connect() was not successful
  | 'CONNECTION_LOST' // connection was lost and could not be re-established
  | 'CONNECTION_TIMEOUT' // connect() failed because host was unresponsive
  | 'INVALID_BINDING_TOKEN' // binding token used for connect() is not valid
  | 'SEND_CONNECTION_TIMEOUT' // a request could not be processed because connection did not come online in time
  | 'SEND_CONNECTION_ERROR' // a request could not be processed because connection is in error state
  | 'SEND_DISCONNECTED' // a request could not be processed because connection was disconnected
  | 'SEND_DELIVERY_ERROR' // a request could not be delivered to device (550)
  | 'MESSAGE_TRUNCATED_ERROR' // a message was received out of sequence or with a different inResponseTo (551)
  | 'SEND_STATUS_ERROR' // a request was not successful
  | 'RESPONSE_TIMEOUT' // a timeout occurred receiving message response (or chunks)
  | 'NOT_CONNECTED' // disconnect() failed because not connected
  | 'UNEXPECTED'; // unexpected service error

export class BaseApiError extends Error {
  public name = 'BaseApiError';

  constructor(
    public message: BaseApiErrorCode,
    public info?: any,
    public innerError?: any,
  ) {
    super(message);

    // Set the prototype explicitly.
    Object.setPrototypeOf(this, BaseApiError.prototype);

    if (innerError && innerError.stack) {
      this.stack = innerError.stack + '\n' + this.stack;
    }
  }

  public toString() {
    return this.name + ': ' + this.message;
  }
}

/**
 * Progress of a transfer
 */
export interface ITransferProgress {
  type: 'upload' | 'download';
  current: number;
  total: number;
  messageId: string;
}

/**
 * Describes the core functionality handled by the service protocol.
 * Impelementors of this interface know only the bare minimum
 * about actual message types and meaning, with the exception of
 * IStatusMessage and IPayloadMessage. Impelementors of this
 * interface are responsible for:
 *  - Keeping the connection alive to the service
 *  - Raising connection-related errors when appropriate
 *  - Handling chunking and re-assembly of IPayloadMessages
 */
export interface IBaseApi {
  /**
   * Emits true when connected, false when disconnected.
   */
  readonly status$: Observable<ConnectionStatus>;

  /**
   * Gets current connection status
   */
  readonly status: ConnectionStatus;

  /**
   * Gets last error
   */
  readonly lastError: BaseApiError;

  /**
   * Error emitter
   */
  readonly errors$: Observable<BaseApiError>;

  /**
   * Emits incoming messages from the server, after any
   * re-assembly of chunks has been performed when necessary.
   */
  readonly inbound$: Observable<IMessage>;

  /**
   * Emits progress info about active transfers
   */
  readonly progress$: Observable<ITransferProgress>;

  /**
   * Connects to the session service for pairing.
   * @param {string} endpoint - Endpoint to connect to.
   * @param {string} symmetricKey
   * @param {number} maxChunkSize - Maximum length of IMessagePayload chunks.
   * @param {number} attempts - Maximum number of attempts.
   * @return {Observable<void>} - Emits on success then completes.
   *                              Emits any connect-related error or timeout.
   */
  connect(
    endpoint: string,
    symmetricKey?: string,
    attempts?: number,
  ): Observable<void>;

  /**
   * Establishes a data connection.
   * Emits the binding notification message
   */
  connectData(
    endpoint: string,
    symmetricKey?: string,
    attempts?: number,
  ): Observable<IBindNotification>;

  /**
   * Creates a message ID
   */
  generateMessageId(): string;

  /**
   * Sends a message to the session service. May chunk message as appropriate.
   * @param {IMessage} msg - Message to send.
   * @param {number} responseTimeout - Optional response timeout override in ms.
   * @return {Observable<IMessage>} - Emits confirmation response if successful.
   *                                  Emits any send-related error or timeout.
   */
  send(msg: IMessage, responseTimeout?: number): Observable<IMessage>;

  /**
   * Sends a message via the data channel. Ensures appropriate encryption.
   */
  sendData(
    msg: IPayloadMessage,
    responseTimout?: number,
  ): Observable<IPayloadMessage>;

  /**
   * Disconnect from the api.
   * @return {Observable<void>} - Emits on success then completes.
   *                              Emites any disconnect-related error.
   */
  disconnect(): Observable<void>;

  /**
   * Wake up the device
   */
  wakeUpMaster(): Observable<void>;
}
