/* tslint:disable max-line-length */
import * as React from 'react';

export default function Clear(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      {...props}
    >
      <g fill="currentColor" fillRule="evenodd">
        <path d="M8 15.429A7.429 7.429 0 1 1 8 .57 7.429 7.429 0 0 1 8 15.43zM9.126 8L11 6.126A.795.795 0 1 0 9.874 5L8 6.874 6.126 5A.795.795 0 1 0 5 6.126L6.874 8 5 9.874A.795.795 0 1 0 6.126 11L8 9.126 9.874 11A.795.795 0 1 0 11 9.874L9.126 8z" />
      </g>
    </svg>
  );
}
