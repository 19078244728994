import * as React from 'react';
import { InputSingleLine } from 'src/components/Input';
import styled from 'styled-components';

export interface IProps {
  placeholder?: string | null;
  value: string;
  onChange: (value: string) => void;
}

const Wrapper = styled.div`
  align-items: center;
  background-color: var(--gray-7);
  border-radius: 4px;
  display: flex;
  height: 32px;
  overflow: hidden;

  svg {
    height: 16px;
    width: 16px;
  }
`;

export default class SearchInput extends React.Component<IProps> {
  public handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (this.props.value !== event.target.value) {
      this.props.onChange(event.target.value);
    }
  };

  public handleClear = (_event: React.MouseEvent<HTMLButtonElement>) => {
    if (this.props.value) {
      this.props.onChange('');
    }
  };

  public render() {
    return (
      <Wrapper>
        <InputSingleLine
          color="var(--black)"
          iconName="Search"
          maxLength={255}
          onChange={this.handleChange}
          onClear={this.handleClear}
          placeholder={this.props.placeholder}
          placeholderColor="var(--gray-3)"
          value={this.props.value}
        />
      </Wrapper>
    );
  }
}
