import React from 'react';
import TextareaAutosize, {
  TextareaAutosizeProps,
} from 'react-textarea-autosize';
import styled from 'styled-components';

const InputWrapper = styled.div`
  padding: calc(var(--theme-spacing) * 2);
`;

const TextareaAutosizeWithProps = (props: StyledTextareaAutosizeProps) => {
  const { color, placeholderColor, ...textAreaAutosizeProps } = props;
  return <TextareaAutosize {...textAreaAutosizeProps} />;
};

interface StyledTextareaAutosizeProps extends TextareaAutosizeProps {
  color?: string;
  placeholderColor?: string;
}

const StyledTextareaAutosize = styled(TextareaAutosizeWithProps)<
  StyledTextareaAutosizeProps
>`
  width: 100%;
  overflow: auto;
  margin: 0;
  padding: 0;
  resize: none;
  border: none;
  outline: none;
  font-size: 16px;
  line-height: 18px;
  background: transparent;
  font-family: inherit;
  color: ${props => props.color};
  caret-color: var(--theme-input-caret-color);
  &::placeholder {
    color: ${props => props.placeholderColor};
  }
`;

// tslint:disable-next-line: no-empty-interface
export interface IProps extends StyledTextareaAutosizeProps {}

const InputMultiLine = (props: IProps) => {
  return (
    <InputWrapper>
      <StyledTextareaAutosize {...props} />
    </InputWrapper>
  );
};

InputMultiLine.defaultProps = {
  color: 'var(--gray-1)',
  maxRows: 5,
  minRows: 1,
  placeholder: '',
  placeholderColor: 'var(--gray-4)',
  useCacheForDOMMeasurements: false,
};

export default InputMultiLine;
