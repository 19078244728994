import { connect } from 'react-redux';
import { IRootState } from '../../../state';
import { IDispatcher } from '../../../store';
import { uuid } from '../../../utils';
import * as Actions from '../../../actions';
import { compose, withHandlers, withStateHandlers } from 'recompose';
import { bindActionCreators } from 'redux';
import { TEMP_GUID_PREFIX } from '../../../config';
import { IOwnProps } from '.';
import { IThunk, NEW_MESSAGE, validateParticipants } from '../../../actions';
import { actions as conversationCreationActions } from '../../Messages/ConversationCreation/modules';
import { IMessageConversation, IMessageConversationMember } from '../../../api';

import { actions as Redirect } from '../../../features/Redirect/modules';
import { saveEntity } from '../../../actions';
import MessageConversationSelectors from '../../../entities/message-conversation/selectors';
import { deleteEntity } from '../../../actions';
import PhoneAccountSelectors from '../../../entities/phone-account/selectors';
const { redirect } = Redirect;

export interface IStateFromProps {
  activeMembers: any[];
}

export interface IStateProps {
  sudoGuid: string;
  conversationGuid: string;
  phoneAccountGuid: string;
  activeMembersWithAvatars: any[];
  title: string | null;
}

export interface IDispatchFromProps {
  createConversation: (
    sudoSlug: string,
    phoneAccountGuid: string,
    memberData: any,
  ) => void;
  onAddParticipantToExistingConversation: (
    phoneAccountGuid: string,
    conversationGuid: string,
    phoneNumber: string,
  ) => void;
  onLeaveConversation: (
    conversationGuid: string,
    callback?: () => void,
  ) => void;
  onDeleteConversation: (sudoSlug: string, conversationGuid: string) => void;
  onSaveTitle: (
    conversationGuid: string,
    title: string | null,
    callback?: () => void,
  ) => void;
}

export interface IComposedProps
  extends IModalHandlersProps,
    ITagsHandlersProps {}

export interface IModalHandlersProps extends IModalHandlersStateProps {
  setIsAddContactModalOpen: (value: boolean) => void;
  leaveConversation: () => void;
  setIsDeleteConfirmModalOpen: (value: boolean) => void;
}

export interface IModalHandlersStateProps {
  isAddContactModalOpen: boolean;
  isDeleteConfirmModalOpen: boolean;
}

export interface ITagsHandlersProps {
  onAddTag: (contact: any) => void;
}

export const transformToTag = (memberData: any): any => {
  const id = memberData.contact
    ? memberData.contact.id
    : TEMP_GUID_PREFIX + uuid();
  return {
    key: id + memberData.alias,
  };
};

export const mapState = (
  _state: IRootState,
  props: IStateProps,
): IStateFromProps => {
  return {
    activeMembers: props.activeMembersWithAvatars.map(transformToTag),
  };
};

const onAddParticipantToExistingConversation = (
  phoneAccountGuid: string,
  conversationGuid: string,
  phoneNumber: string,
): IThunk<void> => {
  return async (dispatch, getState, app) => {
    const state = getState();
    const phoneAccount = PhoneAccountSelectors.getEntityById(state, {
      id: phoneAccountGuid,
    });
    const conversation = MessageConversationSelectors.getEntityById(state, {
      id: conversationGuid,
    });
    dispatch(
      Actions.addParticipantToConversation(
        phoneAccount,
        conversation,
        phoneNumber,
      ),
    );
  };
};

const onLeaveConversation = (
  conversationGuid: string,
  callback?: () => void,
): IThunk<void> => {
  return async (dispatch, getState, app) => {
    const state = getState();
    const conversation = MessageConversationSelectors.getEntityById(state, {
      id: conversationGuid,
    });
    const leaveConversation = {
      type: 'MessagingThread',
      id: conversation.id,
      localParticipant: {
        ...conversation.localParticipant,
        active: false,
      },
    } as IMessageConversation;
    await dispatch(saveEntity(leaveConversation, { showSaveModal: true }));
    if (callback) {
      callback();
    }
  };
};

export const onSaveTitle = (
  conversationGuid: string,
  title: string | null,
  callback?: () => void,
): IThunk<void> => {
  return async (dispatch, getState) => {
    const state = getState();
    const conversation = MessageConversationSelectors.getEntityById(state, {
      id: conversationGuid,
    });
    const conversationData = {
      type: 'MessagingThread',
      id: conversation.id,
      title,
    } as IMessageConversation;
    await dispatch(saveEntity(conversationData, { showSaveModal: true }));
    if (callback) {
      callback();
    }
  };
};

const onDeleteConversation = (
  sudoSlug: string,
  conversationGuid: string,
): IThunk<void> => {
  return async (dispatch, getState, app) => {
    const deleteConversation = {
      type: 'MessagingThread',
      id: conversationGuid,
    } as IMessageConversation;
    await dispatch(deleteEntity(deleteConversation));
    dispatch(redirect(`/${sudoSlug}/messages`));
  };
};

export const mapDispatch = (dispatch: IDispatcher): IDispatchFromProps => ({
  createConversation: (
    sudoSlug: string,
    phoneAccountGuid: string,
    memberData: any,
  ) => {
    const { alias } = memberData;
    dispatch({ type: NEW_MESSAGE, payload: {} });
    const participants: IMessageConversationMember[] = [
      { aliasType: 'phone', alias },
    ];
    dispatch(conversationCreationActions.createNewConversation(participants));
    dispatch(validateParticipants(phoneAccountGuid, participants));
    dispatch(redirect(`/${sudoSlug}/messages/new`));
  },

  ...bindActionCreators(
    {
      onAddParticipantToExistingConversation,
      onLeaveConversation,
      onDeleteConversation,
      onSaveTitle,
    },
    dispatch,
  ),
});

export default compose(
  connect(
    mapState,
    mapDispatch,
  ),

  withStateHandlers(
    {
      isAddContactModalOpen: false,
      isDeleteConfirmModalOpen: false,
    },

    {
      setIsAddContactModalOpen: () => value => ({
        isAddContactModalOpen: value,
      }),
      setIsDeleteConfirmModalOpen: () => value => ({
        isDeleteConfirmModalOpen: value,
      }),
    },
  ),

  withHandlers({
    onAddTag: (
      props: IOwnProps & IStateProps & IDispatchFromProps & IModalHandlersProps,
    ) => (contact: any) => {
      props.onAddParticipantToExistingConversation(
        props.phoneAccountGuid,
        props.conversationGuid,
        contact.secondary,
      );
      props.setIsAddContactModalOpen(false);
      props.onClose();
    },
  }),
);
