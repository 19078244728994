import * as React from 'react';
import styled from 'styled-components';
import { Button } from 'src/components';
import { SectionWrapper, Section } from '../Base/BaseSectionElements';
import WithDeleteContact, { IChildrenParams } from './WithDeleteContact';
import ConfirmDialogVertical from '../../../../../features/Modal/ConfirmDialogVertical';

const DeleteButton = styled(Button)`
  span {
    line-height: 21px;
    font-size: 17px;
    font-weight: normal;
    color: #e70000;
  }
`;

const StyledConfirmDialogVertical = styled(ConfirmDialogVertical)`
  position: absolute;
  // [list-width=375]/2 - [modal-width=375]/2 + 50%
  left: 50%;
`;

const DeleteContactSection = (props: any) => {
  const { contactGuid, contactName, handleDeleteContact } = props;
  return (
    <SectionWrapper>
      <Section>
        <WithDeleteContact onDeleteContact={handleDeleteContact}>
          {({
            onDelete,
            isAsking,
            onCancelDelete,
            onConfirmDelete,
          }: IChildrenParams) => (
            <React.Fragment>
              <DeleteButton onClick={() => onDelete(contactGuid)}>
                Delete Contact
              </DeleteButton>
              <StyledConfirmDialogVertical
                isOpen={isAsking}
                onAccept={onConfirmDelete}
                onCancel={onCancelDelete}
                title="Are you sure?"
                content={`${contactName} will be deleted from your contact list.`}
                cancelText="Cancel"
                acceptText="Yes, delete contact"
              />
            </React.Fragment>
          )}
        </WithDeleteContact>
      </Section>
    </SectionWrapper>
  );
};

export default DeleteContactSection;
