import { isEqual } from 'lodash';
import { IMessageConversationMember } from '../../../api';
import { IAction } from '../../../actions';

const CREATE_CONVERSATION = 'conversationCreation/CREATE_CONVERSATION';
const UPDATE_NEW_CONVERSATION_PARTICIPANTS =
  'conversationCreation/UPDATE_NEW_CONVERSATION_PARTICIPANTS';
const EXCLUDE_NEW_CONVERSATION_PARTICIPANT =
  'conversationCreation/EXCLUDE_NEW_CONVERSATION_PARTICIPANT';
const SET_REDIRECT_TO_CONVERSATION =
  'conversationCreation/SET_REDIRECT_TO_CONVERSATION';

export interface IPartialState {
  participants: any[];
  redirectToConversationGuid: string;
}

const initialState: IPartialState = {
  participants: [],
  redirectToConversationGuid: null,
};

const createNewConversation = (participants?: any[]) => {
  return {
    type: CREATE_CONVERSATION,
    payload: {
      ...initialState,
      ...{
        participants: participants || [],
      },
    },
  };
};

const updateNewConversationParticipants = (participants: any[]) => {
  return {
    type: UPDATE_NEW_CONVERSATION_PARTICIPANTS,
    payload: participants,
  };
};

const excludeNewConversationParticipant = (
  participant: IMessageConversationMember,
) => {
  return {
    type: EXCLUDE_NEW_CONVERSATION_PARTICIPANT,
    payload: participant,
  };
};

const setRedirectToConversation = (conversationGuid: string) => {
  return {
    type: SET_REDIRECT_TO_CONVERSATION,
    payload: conversationGuid,
  };
};

const reducer = (state: IPartialState = initialState, action: IAction) => {
  switch (action.type) {
    case CREATE_CONVERSATION:
      return {
        ...action.payload,
      };
    case UPDATE_NEW_CONVERSATION_PARTICIPANTS:
      return {
        ...state,
        participants: action.payload,
      };
    case EXCLUDE_NEW_CONVERSATION_PARTICIPANT:
      return {
        ...state,
        participants: state.participants.filter((p: any) => {
          return !(
            p.alias === action.payload.alias &&
            p.aliasType === action.payload.aliasType
          );
        }),
      };
    case SET_REDIRECT_TO_CONVERSATION:
      return {
        ...state,
        redirectToConversationGuid: action.payload,
      };
    default:
      return state;
  }
};

export const actions = {
  createNewConversation,
  updateNewConversationParticipants,
  excludeNewConversationParticipant,
  setRedirectToConversation,
};

export default reducer;
