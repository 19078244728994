import * as DOMPurify from 'dompurify';

// No context needed
// tslint:disable-next-line: no-empty-interface
interface IContext {}

export const hook = (opts?: Partial<IContext>) => {
  const context: IContext = {};

  DOMPurify.addHook('uponSanitizeElement', (node, data) => {
    if (data.tagName === 'title') {
      node.textContent = '';
    }
  });

  return context;
};
