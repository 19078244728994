import React from 'react';

export default function Search(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      {...props}
    >
      <g fill="currentColor" fillRule="evenodd" transform="translate(2 2)">
        <path d="M15.695 14.634l3.835 3.836a.75.75 0 0 1-1.06 1.06l-3.836-3.835a8.75 8.75 0 1 1 1.06-1.06zM9 16.25a7.25 7.25 0 1 0 0-14.5 7.25 7.25 0 0 0 0 14.5z" />
      </g>
    </svg>
  );
}
