const logLevels = {
  debug: 0,
  info: 1,
  warn: 2,
  error: 3,
  none: 4,
};

export type LogLevel = keyof typeof logLevels;
export type Logger = ReturnType<typeof createLog>;
type LogType = keyof Omit<typeof logLevels, 'none'>;

export function createLog(componentName: string, logLevel: LogLevel = 'warn') {
  const logLevelNum = logLevels[logLevel];
  return {
    debug: createLogger('debug', logLevelNum <= logLevels.debug, componentName),
    info: createLogger('info', logLevelNum <= logLevels.info, componentName),
    warn: createLogger('warn', logLevelNum <= logLevels.warn, componentName),
    error: createLogger('error', logLevelNum <= logLevels.error, componentName),
  };
}

function createLogger(
  logType: LogType,
  enabled: boolean,
  componentName: string,
) {
  return (...args: any[]) => {
    if (!enabled) {
      return;
    }

    const out = console[logType];
    out(new Date().toLocaleTimeString(), `[${componentName}]`, ...args);
  };
}
