import * as React from 'react';

export interface IProps {
  contactGuid: string;
  onFormReset: () => void;
}

export class WithFormUpdate extends React.Component<IProps> {
  public componentDidUpdate(prevProps: IProps) {
    if (this.props.contactGuid !== prevProps.contactGuid) {
      this.props.onFormReset();
    }
  }

  public render() {
    return this.props.children;
  }
}

export default WithFormUpdate;
