import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';

const StyledOverlay = styled.div`
  transition: opacity var(--theme-transition-function-default) 0.25s;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  opacity: 0;
  pointer-events: none;
  z-index: ${props => props.zIndex};

  &.isOpen {
    pointer-events: all;
    opacity: 1;
  }
`;

const Overlay = props => (
  <StyledOverlay
    className={cn(props.isOpen && 'isOpen', props.className)}
    onClick={props.onClick}
    zIndex={props.zIndex}
  />
);

Overlay.propTypes = {
  className: PropTypes.string,
  isOpen: PropTypes.bool,
  onClick: PropTypes.func,
  zIndex: PropTypes.number,
};

Overlay.defaultProps = {
  className: null,
  isOpen: false,
  onClick: noop,
  zIndex: 1,
};

export default Overlay;
