import { values } from 'lodash';
import { createSelector, Selector } from 'reselect';

import { EntityType, IChildEntity } from '../../api';
import { IRootState } from '../../state';

export interface IEntitiesMap<T> {
  [key: string]: T;
}

export type EntitiesMapSelector<T> = Selector<IRootState, IEntitiesMap<T>>;
export type EntitiesArraySelector<T> = Selector<IRootState, T[]>;
export type EntitySelector<T> = Selector<IRootState, T>;

export interface IEntityByIdProps {
  id: string;
}

export interface IEntityBySudoIdProps {
  sudoGuid: string;
}

export interface IEntitySelectors<T> {
  getEntities: EntitiesMapSelector<T>;
  getEntitiesArray: EntitiesArraySelector<T>;
  getEntityById: EntitySelector<T>;
  getEntitiesBySudoId: EntitiesArraySelector<T>;
}

const createGetEntitiesRaw = <T>(ENTITY_TYPE: EntityType) => (
  state: IRootState,
) => (state.entities && state.entities.get(ENTITY_TYPE)) || null;

const createGetEntities = <T>(
  ENTITY_TYPE: EntityType,
): EntitiesMapSelector<T> =>
  createSelector(
    createGetEntitiesRaw<T>(ENTITY_TYPE),
    (entities: any) => (entities ? entities.toObject() : null),
  );

const createGetEntitiesArray = <T>(getEntities: EntitiesMapSelector<T>) =>
  createSelector(
    getEntities,
    (entities: IEntitiesMap<T>): T[] => (entities ? values(entities) : []),
  );

const createGetEntityById = <T>(getEntities: EntitiesMapSelector<T>) =>
  createSelector(
    getEntities,
    (_, { id }: IEntityByIdProps) => id,
    (entities: IEntitiesMap<T>, id: string): T =>
      entities ? entities[id] : null,
  );

const createGetEntitiesBySudoId = <T>(
  getEntitiesArray: EntitiesArraySelector<T>,
) =>
  createSelector(
    getEntitiesArray,
    (_, { sudoGuid }: IEntityBySudoIdProps) => sudoGuid,
    (entities: T[], sudoGuid: string): T[] => {
      const filterEntities = (entity: T) => {
        if (entity.hasOwnProperty('parent')) {
          const child: IChildEntity = entity as any;
          return child.parent.id === sudoGuid && child.parent.type === 'Sudo';
        }
        return false;
      };
      return (entities && entities.filter(filterEntities)) || null;
    },
  );

const createEntitySelectors = <E>(
  ENTITY_TYPE: EntityType,
): IEntitySelectors<E> => {
  const getEntities = createGetEntities<E>(ENTITY_TYPE);
  const getEntitiesArray = createGetEntitiesArray<E>(getEntities);
  const getEntityById = createGetEntityById<E>(getEntities);
  const getEntitiesBySudoId = createGetEntitiesBySudoId<E>(getEntitiesArray);

  return {
    getEntities,
    getEntitiesArray,
    getEntityById,
    getEntitiesBySudoId,
  };
};

export default createEntitySelectors;
