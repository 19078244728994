import * as React from 'react';
import filter from 'lodash/filter';
import cn from 'classnames';
import memoizeOne from 'memoize-one';

import ContactsList from './ContactsList';

import './styles.scss';

interface Tag {
  label: string;
  value: any;
}
export interface IProps {
  label: string;
  value: string[];
  onChange: (newValues: string[]) => void;
  contacts: string[];
  isValid: (value: string) => boolean;
  tabIndex?: number;
  placeholder?: string;

  searchFn: (value: string, search: string) => boolean;
  renderContact: (value: string, index: number) => React.ReactNode;
  renderTag?: (value: string, index: number) => React.ReactNode;
  onTab?: (e: React.KeyboardEvent<HTMLElement>) => void;
}

interface IState {
  inputText: string;
  focusIndex: number;
  isInvalid: boolean;
}

const getContactsListItems = memoizeOne(
  (
    searchFn: (cli: string, search: string) => boolean,
    contacts: string[],
    value: string[],
    inputText: string,
  ): string[] => {
    let contactsListItems = contacts;
    const search = inputText.trim().toLowerCase();
    if (search) {
      contactsListItems = filter(contactsListItems, (cli: string) =>
        searchFn(cli, search),
      );
    }
    if (value.length) {
      contactsListItems = filter(contactsListItems, (cli: string) => {
        return value.indexOf(cli.toLowerCase()) < 0;
      });
    }
    return contactsListItems;
  },
);

class ContactsInput extends React.Component<IProps, IState> {
  public state: IState = { inputText: '', focusIndex: -1, isInvalid: false };
  private input: HTMLInputElement = null;
  private tags: HTMLDivElement = null;
  private getInputRef = (ref: HTMLInputElement) => {
    this.input = ref;
  };
  private getTagsRef = (ref: HTMLDivElement) => {
    this.tags = ref;
  };
  public focus = () => {
    this.focusInput();
  };
  private onTagKeyDown = (e: any) => {
    const { onChange, value } = this.props;
    let { index } = e.currentTarget.dataset;
    index = +index;

    if (e.key === 'Backspace') {
      onChange([...value.slice(0, index), ...value.slice(index + 1)]);
      this.focusInput();
      return;
    }

    if (e.key === 'ArrowRight') {
      if (index === value.length - 1) {
        this.focusInput();
      } else if (index < value.length - 1) {
        this.focusTag(index + 1);
      }
      return;
    }

    if (e.key === 'ArrowLeft' && index > 0) {
      this.focusTag(index - 1);
    }
  };
  private onInputLabelClick = (e: any) => {
    this.focusInput();
  };
  private focusInput = () => {
    if (this.input) {
      this.input.focus();
    }
  };
  private focusTag = (index: number) => {
    const tags = this.tags.children;
    const lastTag: any = tags[index];
    lastTag.focus();
  };
  private onInputFocus = () => {
    const { inputText, focusIndex } = this.state;
    if (inputText.length && focusIndex === -1) {
      this.setState({ focusIndex: 0 });
    }
  };
  private onInputBlur = () => {
    const { onChange, value, isValid } = this.props;
    const { inputText } = this.state;

    const newValue = inputText.trim();
    const stateUpdate: any = {};
    if (newValue.length) {
      if (!isValid(newValue)) {
        stateUpdate.isInvalid = true;
      } else {
        onChange([...value, newValue]);
        stateUpdate.inputText = '';
      }
    }
    stateUpdate.focusIndex = -1;
    this.setState(stateUpdate);
  };
  private onInputChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
    this.setState({ inputText: e.currentTarget.value });
  };
  private onInputKeyDown = (e: any) => {
    const { value, onChange, contacts, isValid, searchFn, onTab } = this.props;
    const { focusIndex, inputText } = this.state;

    if (e.key === 'Tab' && onTab) {
      onTab(e);
      return;
    }

    const listItems = getContactsListItems(
      searchFn,
      contacts,
      value,
      inputText,
    );

    this.setState({ isInvalid: false });

    const isAtInputStart =
      e.currentTarget.selectionStart === 0 &&
      e.currentTarget.selectionEnd === 0;
    if (
      (e.key === 'Backspace' || e.key === 'ArrowLeft') &&
      isAtInputStart &&
      value.length
    ) {
      e.preventDefault();
      this.focusTag(value.length - 1);
      return;
    }

    if (e.key === 'ArrowDown') {
      e.preventDefault();
      const newFocusIndex = focusIndex + 1;
      if (newFocusIndex >= listItems.length) {
        return;
      }
      this.setState({ focusIndex: newFocusIndex });
      return;
    }

    if (e.key === 'ArrowUp') {
      e.preventDefault();
      const newFocusIndex = focusIndex - 1;
      if (newFocusIndex < -1) {
        return;
      }
      this.setState({ focusIndex: newFocusIndex });
      return;
    }

    if (
      (e.key === 'Enter' || e.key === ',') &&
      focusIndex >= 0 &&
      listItems.length
    ) {
      e.preventDefault();
      const contact = listItems[focusIndex];
      onChange([...value, contact]);
      const stateUpdate: any = {};
      if (focusIndex > 0) {
        stateUpdate.focusIndex = focusIndex - 1;
      }
      stateUpdate.inputText = '';
      this.setState(stateUpdate);
      return;
    }

    if (
      (e.key === 'Enter' || e.key === ',') &&
      (focusIndex === -1 || !listItems.length)
    ) {
      e.preventDefault();
      let nextValue = e.currentTarget.value;
      nextValue = nextValue.trim();
      if (!nextValue) {
        return;
      }
      if (!isValid(nextValue)) {
        this.setState({ isInvalid: true });
        return;
      }
      onChange([...value, nextValue]);
      this.setState({ inputText: '' });
    }
  };
  private onInputKeyUp = (e: any) => {
    const { inputText, focusIndex } = this.state;
    if (inputText.length > 0 && focusIndex === -1 && e.key !== 'ArrowUp') {
      this.setState({ focusIndex: 0 });
      return;
    }
    if (
      inputText.length === 0 &&
      focusIndex !== -1 &&
      (e.key !== 'ArrowDown' && e.key !== 'ArrowUp')
    ) {
      this.setState({ focusIndex: -1 });
      return;
    }
  };
  private onContactMouseDown = (index: number) => {
    const { onChange, value, contacts, searchFn } = this.props;
    const { inputText } = this.state;

    const listItems = getContactsListItems(
      searchFn,
      contacts,
      value,
      inputText,
    );

    const newValue = listItems[index];
    onChange([...value, newValue]);
    this.setState({ inputText: '', focusIndex: -1 });
  };
  private onContactMouseOver = (index: number) => {
    this.setState({ focusIndex: index });
  };
  public render() {
    const {
      value,
      label,
      contacts,
      renderContact,
      renderTag,
      placeholder,
      tabIndex,
      searchFn,
      isValid,
    } = this.props;
    const { inputText, isInvalid, focusIndex } = this.state;

    const contactsListItems = getContactsListItems(
      searchFn,
      contacts,
      value,
      inputText,
    );
    const tags = value && value.length ? value : [];

    return (
      <div className="contacts-input">
        <label
          htmlFor={`contacts-field-${label.toLowerCase()}`}
          className="contacts-input-label"
          onClick={this.onInputLabelClick}
        >
          {label}
        </label>
        <div ref={this.getTagsRef} className="contacts-input-tags">
          {tags.map((tag: string, index: number) => (
            <div
              tabIndex={0}
              key={`${index}:${tag}`}
              data-index={index}
              className={cn({ invalid: !isValid(tag) })}
              onKeyDown={this.onTagKeyDown}
            >
              {renderTag ? renderTag(tag, index) : <span>{tag}</span>}
              {index !== tags.length - 1 && ','}
            </div>
          ))}
          <input
            type="text"
            id={`contacts-field-${label.toLowerCase()}`}
            ref={this.getInputRef}
            value={inputText}
            className={cn({ invalid: isInvalid })}
            tabIndex={tabIndex}
            placeholder={placeholder}
            onFocus={this.onInputFocus}
            onBlur={this.onInputBlur}
            onChange={this.onInputChange}
            onKeyDown={this.onInputKeyDown}
            onKeyUp={this.onInputKeyUp}
          />
        </div>
        {focusIndex >= 0 ? (
          <ContactsList
            focusIndex={focusIndex}
            contacts={contactsListItems}
            onContactMouseDown={this.onContactMouseDown}
            onContactMouseOver={this.onContactMouseOver}
            renderContact={renderContact}
          />
        ) : null}
      </div>
    );
  }
}

export default ContactsInput;
