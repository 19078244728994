import * as React from 'react';
import styled from 'styled-components';

import { Button } from 'src/components';

const Input = styled.input`
  display: none;
`;

interface IProps {
  onFilesSelected: (files: FileList) => void;
}

class AttachmentsButton extends React.Component<IProps> {
  private input = (React as any).createRef();
  private handleClick = () => {
    this.input.current.click();
  };
  private handleChange = (e: any) => {
    this.props.onFilesSelected(e.target.files);
  };

  public render() {
    return (
      <React.Fragment>
        <Button
          onClick={this.handleClick}
          iconName="Attachment"
          iconColor="var(--blue)"
        />
        <Input type="file" ref={this.input} onChange={this.handleChange} />
      </React.Fragment>
    );
  }
}

export default AttachmentsButton;
