import { ISudoCounts } from '../../api';
import createEntitySelectors from '../common/createEntitySelectors';
import ENTITY_TYPE from './entityType';

export const createSudoCountsSelectors = () => {
  const commonSelectors = createEntitySelectors<ISudoCounts>(ENTITY_TYPE);

  return { ...commonSelectors };
};

const SudoCountsSelectors = createSudoCountsSelectors();

export default SudoCountsSelectors;
