import React, { FunctionComponent, SyntheticEvent } from 'react';
import { ComponentClass, ReactNode } from 'react-redux';
import styled, { css } from 'styled-components';
import cn from 'classnames';
import noop from 'lodash/noop';
import omit from 'lodash/fp/omit';

import ListItemPrimaryText, {
  PrimaryTextTimeLabel,
  StyledPrimaryText,
  StyledPrimaryTextWithTimeLabelWrapper,
} from '../ListItemPrimaryText';
import ListItemSecondaryText, {
  StyledSecondaryText,
} from '../ListItemSecondaryText';
import ListItemAvatar from '../ListItemAvatar';
import ListItemCheckBox from '../ListItemCheckBox';
import Icon from '../../../Icon';

export interface IProps {
  avatarImage?: string | string[];
  avatarText?: string;
  checkBoxPosition?: 'left' | 'right';
  checkBoxValue?: boolean;
  checkedClassName?: string;
  className?: string;
  component?: ComponentClass<any> | FunctionComponent | string;
  isActive?: boolean;
  isAvatarLocked?: boolean;
  isCheckBoxVisible?: boolean;
  isNew?: boolean;
  isWithCheckBox?: boolean;
  onCheckBoxChange?: (value: boolean) => void;
  onClick?: (event: SyntheticEvent<any>) => void;
  onKeyDown?: (event: SyntheticEvent<any>) => void;
  onMouseOver?: (event: SyntheticEvent<any>) => void;
  onMouseOut?: (event: SyntheticEvent<any>) => void;
  onMouseDown?: (event: SyntheticEvent<any>) => void;
  onMouseEnter?: (event: SyntheticEvent<any>) => void;
  primaryText?: string | JSX.Element;
  secondaryText?: React.ReactNode | string;
  showLock?: boolean;
  style?: React.CSSProperties;
  timeLabel?: string;
}

const omitListItemProps = omit([
  'primaryText',
  'secondaryText',
  'isActive',
  'timeLabel',
  'avatarImage',
  'avatarText',
  'isAvatarLocked',
  'isNew',
  'showLock',
  'isWithCheckBox',
  'onCheckBoxChange',
  'checkBoxValue',
  'checkBoxPosition',
  'isCheckBoxVisible',
  'checkedClassName',
]);

interface IListItemComponentProps {
  children?: ReactNode;
  className?: string;
  component?: ComponentClass<any> | FunctionComponent | string;
  onClick?: (event: SyntheticEvent<any>) => void;
}

const ListItemComponent = ({ component, ...rest }: IListItemComponentProps) => {
  return React.createElement(component, rest);
};

const ListItemWrapper = styled(ListItemComponent)`
  cursor: pointer;
  height: 72px;
  padding: 0 16px;
  display: flex;
  overflow: hidden;
  white-space: nowrap;
  align-items: center;
  background-color: var(--gray-9);

  &:hover {
    background-color: var(--gray-6);
  }

  &.isActive {
    background-color: var(--gray-6);
  }

  &.isWithCheckBox {
    &:not(.checkBoxRightPosition) {
      padding-left: var(--theme-spacing);
    }
    &.checkBoxRightPosition {
      padding-right: var(--theme-spacing);
      flex-direction: row-reverse;
    }
  }

  .listItemCheckBox {
    visibility: hidden;
  }

  &.checkBoxVisible {
    .listItemCheckBox {
      visibility: visible;
    }
  }

  &.isNew {
    & ${StyledPrimaryText} {
      position: relative;
      font-weight: bold;
      padding-left: 16px;
      &:before {
        left: 0;
        top: 4px;
        border-radius: 50%;
        position: absolute;
        content: '';
        width: 12px;
        height: 12px;
        min-width: 12px;
        min-height: 12px;
        background-color: var(--blue);
      }
    }

    & ${StyledPrimaryTextWithTimeLabelWrapper}, & ${PrimaryTextTimeLabel} {
      color: var(--black);
      font-weight: bold;
    }

    & ${StyledSecondaryText} {
      color: var(--black);
    }
  }
`;

interface IListItemContentProps {
  isWithSecondaryText: boolean;
}

const ListItemContent = styled.div<IListItemContentProps>`
  flex: 1;
  overflow: hidden;
  ${props =>
    !props.isWithSecondaryText &&
    css`
      display: flex;
    `}
`;

const LockIconStyled = styled(Icon)`
  margin-right: 3px;
`;

const ListItem = (props: IProps) => (
  <ListItemWrapper
    {...omitListItemProps(props)}
    onClick={props.onClick}
    component={props.component}
    className={cn(
      props.isNew && 'isNew',
      props.isWithCheckBox && 'isWithCheckBox',
      props.isActive && 'isActive',
      props.checkBoxPosition === 'right' && 'checkBoxRightPosition',
      props.isCheckBoxVisible && 'checkBoxVisible',
      props.isWithCheckBox && props.checkBoxValue && props.checkedClassName,
    )}
  >
    {props.isWithCheckBox && (
      <ListItemCheckBox
        className="listItemCheckBox"
        position={props.checkBoxPosition}
        value={props.checkBoxValue}
        onChange={props.onCheckBoxChange}
      />
    )}
    <ListItemAvatar image={props.avatarImage} isSudo={props.isAvatarLocked}>
      {props.avatarText}
    </ListItemAvatar>
    <ListItemContent isWithSecondaryText={!!props.secondaryText}>
      <ListItemPrimaryText
        timeLabel={props.timeLabel}
        isWithSecondaryText={!!props.secondaryText}
      >
        {props.isAvatarLocked && props.showLock && (
          <LockIconStyled name="LockFill" />
        )}
        {props.primaryText}
      </ListItemPrimaryText>
      <ListItemSecondaryText>{props.secondaryText}</ListItemSecondaryText>
    </ListItemContent>
  </ListItemWrapper>
);

ListItem.defaultProps = {
  avatarImage: null,
  avatarText: null,
  checkBoxPosition: 'left',
  checkBoxValue: false,
  checkedClassName: null,
  component: 'li',
  isActive: false,
  isAvatarLocked: false,
  isCheckBoxVisible: true,
  isNew: false,
  isWithCheckBox: false,
  onCheckBoxChange: noop,
  onClick: noop,
  secondaryText: null,
  showLock: true,
  timeLabel: null,
};

ListItem.displayName = 'ListItem';

export default ListItem;
