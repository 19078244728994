import React from 'react';

export default function Compose(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      {...props}
    >
      <g fill="currentColor" fillRule="evenodd" transform="translate(1.5 1.5)">
        <path d="M14.763 3.861l-6.925 6.925-.625 2.393 2.393-.625 6.925-6.925-1.768-1.768zm1.06-1.06l1.769 1.767 1.297-1.297a1.668 1.668 0 0 0-1.768-1.767L15.824 2.8zM9.75 1.456a.75.75 0 1 1 0 1.5h-6a2.25 2.25 0 0 0-2.25 2.25v12a2.25 2.25 0 0 0 2.25 2.25h12a2.25 2.25 0 0 0 2.25-2.25v-7a.75.75 0 1 1 1.5 0v7a3.75 3.75 0 0 1-3.75 3.75h-12A3.75 3.75 0 0 1 0 17.207v-12a3.75 3.75 0 0 1 3.75-3.75h6zM16.41.093l.288-.049a3.168 3.168 0 0 1 3.638 3.718l-.043.226-9.674 9.674a1.25 1.25 0 0 1-.568.326l-2.871.75a1.25 1.25 0 0 1-1.526-1.526l.75-2.87a1.25 1.25 0 0 1 .326-.569l9.68-9.68z" />
      </g>
    </svg>
  );
}
