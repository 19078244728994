/**
 * Encoder/decoder used to convert string data to binary and back
 */
export interface IBinaryEncoding {
  binaryType: 'blob' | 'arraybuffer';
  encode(data: string, callback: (binaryData: any) => void): void;
  decode(binaryData: any, callback: (data: string) => void): void;
}

/**
 * This is the default encoder/decoder used in the browser.
 */
export const blobEncoding: IBinaryEncoding = {
  binaryType: 'blob',

  encode(data: string, callback: (err: any, binaryData?: any) => void) {
    callback(null, new Blob([data]));
  },

  decode(binaryData: Blob, callback: (err: any, data?: string) => void) {
    const reader = new FileReader();
    reader.addEventListener('error', err => callback(err));
    reader.addEventListener('loadend', () => {
      callback(null, reader.result as string);
    });
    reader.readAsText(binaryData);
  },
};

/**
 * This encoding type is needed when using the API in Node because Blob
 * support isn't available.
 */
export const arrayBufferEncoding = (): IBinaryEncoding => {
  const encoder = new TextEncoder(); // to utf8
  const decoder = new TextDecoder(); // from utf8

  return {
    binaryType: 'arraybuffer',

    encode(data: string, callback: (err: any, binaryData?: any) => void) {
      try {
        const result = encoder.encode(data);
        callback(null, result.buffer);
      } catch (err) {
        callback(err);
      }
    },

    decode(
      binaryData: ArrayBuffer,
      callback: (err: any, data?: string) => void,
    ) {
      try {
        const uint8Array = new Uint8Array(binaryData);
        const data = decoder.decode(uint8Array);
        callback(null, data);
      } catch (err) {
        callback(err);
      }
    },
  };
};
