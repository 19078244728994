import { compose, withStateHandlers, withHandlers, lifecycle } from 'recompose';
import { KeyboardEvent } from 'react';
import { connect } from 'react-redux';
import { IOwnProps as IInputToolbarProps, IOwnProps } from './index';
import { parseDataUri } from '../../../../utils';
import { IDispatcher } from '../../../../store';
import { actions as messagesErrorsActions } from '../../MessagesErrors/modules';
import { IMessageComposition } from '../../../../state';
import { IMessage } from '../../../../api';

const ENTER = 13;

export interface IComposedProps
  extends IDispatchFromProps,
    IInputHandlersProps,
    IRefHandlersProps,
    IEmojiHandlersProps {}

export interface IDispatchFromProps {
  onError: (type: string, message: string) => void;
}

export interface IRefHandlersProps extends IRefHandlersStateProps {
  setInputRef: (inputRef: any) => void;
}

export interface IRefHandlersStateProps {
  inputRef: any;
}

export interface IInputHandlersProps extends IInputHandlersStateProps {
  addAttachment: (attachment: string) => void;
  onInputChange: () => void;
  onInputKeyDown: (event: KeyboardEvent<any>) => void;
  onSetFromComposition: (composition: IMessageComposition) => void;
  removeAttachment: (index: number) => void;
}

export interface IInputHandlersStateProps {
  inputValue: string;
  attachments: IAttachmentData[];
  editedMessage: IMessage;
}

export type AttachmentType = 'IMAGE' | 'VIDEO';

export interface IAttachmentData {
  data: string;
  type: AttachmentType;
  mediaType: string;
}

export interface IEmojiHandlersProps extends IEmojiHandlersStateProps {
  toggleEmojiPicker: () => void;
  onSelectEmoji: (emoji: any) => void;
}

export interface IEmojiHandlersStateProps {
  isEmojiPickerOpen: boolean;
}

export const parseAttachment = (attachment: string): IAttachmentData => {
  const parsedData = parseDataUri(attachment);
  let type: AttachmentType;
  if (parsedData.mediatype.startsWith('video')) {
    type = 'VIDEO';
  } else {
    type = 'IMAGE';
  }
  return {
    data: attachment,
    type,
    mediaType: parsedData.mediatype,
  };
};

export const transformAttachments = (
  attachments: IAttachmentData[],
): string[] => {
  return attachments.map(attachment => attachment.data);
};

export const mapDispatch = (dispatch: IDispatcher): IDispatchFromProps => ({
  onError: (type: string, message: string) => {
    dispatch(
      messagesErrorsActions.addError({
        type,
        message,
      }),
    );
  },
});

export default compose(
  connect(
    null,
    mapDispatch,
  ),
  withStateHandlers(
    {
      inputRef: null,
    },
    {
      setInputRef: (_state: IRefHandlersProps, props: IOwnProps) => (
        inputRef: any,
      ) => {
        if (inputRef && props.autoFocus) {
          inputRef.focus();
        }

        return { inputRef };
      },
    },
  ),
  withStateHandlers(
    {
      inputValue: '',
      attachments: [],
      editedMessage: null,
    },
    {
      onSetFromComposition: (
        _state: IInputHandlersStateProps,
        props: IInputToolbarProps & IRefHandlersProps,
      ) => (composition: IMessageComposition) => {
        const data = {
          message: composition.body,
          attachments: composition.attachmentData.toArray(),
          editedMessage: composition.message,
        };
        props.onChange(data);
        return {
          inputValue: data.message,
          attachments: data.attachments,
          editedMessage: data.editedMessage,
        };
      },
      onInputChange: (
        state: IInputHandlersStateProps,
        props: IInputToolbarProps & IRefHandlersProps,
      ) => () => {
        const value = props.inputRef.value;
        props.onChange({
          message: value,
          attachments: transformAttachments(state.attachments),
          editedMessage: state.editedMessage,
        });
        return {
          inputValue: value,
        };
      },
      onInputKeyDown: (
        state: IInputHandlersStateProps,
        props: IInputToolbarProps & IRefHandlersProps,
      ) => (event: KeyboardEvent<any>) => {
        const { keyCode, shiftKey } = event;

        if ([ENTER].indexOf(keyCode) >= 0) {
          if (!shiftKey) {
            if (props.isNoEmptySubmit) {
              const isEmpty =
                state.inputValue.trim().length === 0 &&
                state.attachments.length === 0;
              if (isEmpty) {
                event.preventDefault();
                return {};
              }
            }
            event.preventDefault();
            props.onSubmit();

            if (!props.isNoClearOnSubmit) {
              // Clear form on submit
              return {
                inputValue: '',
                attachments: [],
                editedMessage: null,
              };
            }
          }
        }

        return {};
      },
      addAttachment: (
        state: IInputHandlersStateProps,
        props: IInputToolbarProps & IRefHandlersProps,
      ) => (attachment: string) => {
        const attachments = state.attachments.concat([
          parseAttachment(attachment),
        ]);
        props.onChange({
          message: state.inputValue,
          attachments: transformAttachments(attachments),
          editedMessage: state.editedMessage,
        });
        return {
          attachments,
        };
      },
      removeAttachment: (
        state: IInputHandlersStateProps,
        props: IInputToolbarProps & IRefHandlersProps,
      ) => (index: number) => {
        const attachments = state.attachments.filter((_, i) => i !== index);
        props.onChange({
          message: state.inputValue,
          attachments: transformAttachments(attachments),
          editedMessage: state.editedMessage,
        });
        return {
          attachments,
        };
      },
    },
  ),
  withStateHandlers(
    {
      isEmojiPickerOpen: false,
    },
    {
      toggleEmojiPicker: (
        state: IEmojiHandlersStateProps,
        props: IInputHandlersProps & IRefHandlersProps,
      ) => () => {
        if (state.isEmojiPickerOpen) {
          props.inputRef.focus();
        }
        return { isEmojiPickerOpen: !state.isEmojiPickerOpen };
      },
      onSelectEmoji: (
        state: IEmojiHandlersStateProps,
        props: IInputHandlersProps & IRefHandlersProps,
      ) => (emoji: any) => {
        const pos = props.inputRef.selectionStart;
        const len = emoji.native.length;
        const oldValue = props.inputRef.value;
        const newValue =
          oldValue.substr(0, pos) + emoji.native + oldValue.substr(pos);

        // Insert emoji and fire onChange after this handler finishes
        setTimeout(() => {
          props.inputRef.value = newValue;
          props.onInputChange();
          // Put cursor back
          props.inputRef.selectionStart = pos + len;
          props.inputRef.selectionEnd = pos + len;
        }, 0);
        return {};
      },
    },
  ),
);
