import styled, { css } from 'styled-components';

interface IProps {
  image?: string | string[];
  backgroundColor?: string;
}

const AvatarBase = styled.div<IProps>`
  font-size: 17px;
  color: var(--blue);
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${props => props.backgroundColor};
  background: ${props => {
    const image =
      props.image && Array.isArray(props.image) ? props.image[0] : props.image;
    return (
      image &&
      css`
      url(${image}) no-repeat center center, ${props.backgroundColor}
    `
    );
  }};
  background-size: cover;
`;

AvatarBase.displayName = 'AvatarBase';

export default AvatarBase;
