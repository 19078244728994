import { connect } from 'react-redux';

import { IRootState } from '../../../state';
import * as Actions from '../../../actions';
import { IDispatcher } from '../../../store';

import { sideBarStateSelector, sudoListSelector, ISudoList } from './selectors';

export interface IOwnProps {
  sudoSlug: string;
  tab: string;
  onClick: () => void;
}
interface IStateProps {
  isOpen: boolean;
  sudoList: ISudoList[];
}
interface IActionProps {
  onClose: () => void;
}

const mapState = (state: IRootState, props: IOwnProps): IStateProps => {
  const { sudoSlug } = props;
  return {
    isOpen: sideBarStateSelector(state),
    sudoList: sudoListSelector(state, { slug: sudoSlug }),
  };
};

const mapDispatch = (dispatch: IDispatcher): IActionProps => ({
  onClose: () => {
    dispatch(Actions.createSidePanelAction('sudoSelect', false));
  },
});

export default connect(
  mapState,
  mapDispatch,
);
export interface IProps extends IOwnProps, IStateProps, IActionProps {}
