import { List } from 'immutable';
import {
  CONTACTS_DELETE_CLOSE,
  CONTACTS_DELETE_SHOW,
  FILTER_CONTEXT_LIST,
  IAction,
  INotifyEntityAction,
  NOTIFY_ENTITY,
  nullAction,
} from '../actions';
import { SELECT_LIST_ROWS } from '../actions/select-list-rows-action';
import { IContact } from '../api/entities';
import { Contacts, IContacts } from '../state/contacts';

export function contactsReducer(
  state: IContacts = Contacts(),
  action: IAction = nullAction,
) {
  switch (action.type) {
    case NOTIFY_ENTITY: {
      const payload = (action as INotifyEntityAction).payload;

      if (payload.entityType === 'Contact') {
        if (payload.change === 'DELETE') {
          const updatedSelection = state.selectedContactKeys.filter(
            key => key !== payload.entityGuid,
          );
          return state.set('selectedContactKeys', updatedSelection);
        }
      }
      return state;
    }

    case FILTER_CONTEXT_LIST: {
      if (action.payload.type === 'CONTACT') {
        return state.set('search', action.payload.search);
      } else {
        return state;
      }
    }

    case SELECT_LIST_ROWS: {
      if (action.payload.type === 'CONTACT') {
        const contacts: IContact[] = action.payload.items;
        return state.set('selectedContactKeys', List(contacts));
      } else {
        return state;
      }
    }

    case CONTACTS_DELETE_SHOW: {
      return state.set('showDeleteModal', true);
    }

    case CONTACTS_DELETE_CLOSE: {
      return state.set('showDeleteModal', false);
    }

    default:
      return state;
  }
}
