import React from 'react';

export default function Contacts(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      {...props}
    >
      <g fill="currentColor" fillRule="evenodd" transform="translate(1 2)">
        <path d="M8.343 9.375C5.898 9.375 3.916 7.416 3.916 5S5.898.625 8.343.625c2.446 0 4.428 1.959 4.428 4.375S10.79 9.375 8.343 9.375zM14.153 0h6.597a.75.75 0 1 1 0 1.5h-6.596a.75.75 0 1 1 0-1.5zm2.025 3h4.572a.75.75 0 1 1 0 1.5h-4.572a.75.75 0 0 1 0-1.5zm-1.012 3h5.584a.75.75 0 1 1 0 1.5h-5.584a.75.75 0 1 1 0-1.5zm.02 14H1.5a1 1 0 0 1-1-1v-.25c0-4.148 3.518-7.5 7.843-7.5 4.326 0 7.844 3.352 7.844 7.5V19a1 1 0 0 1-1 1z" />
      </g>
    </svg>
  );
}
