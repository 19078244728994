import * as React from 'react';
import styled from 'styled-components';
import { pure } from 'recompose';
import InfoBadge from '../InfoBadge';

export interface IProps {
  displayName: string;
  action: string;
}

const Bold = styled.span`
  font-weight: bold;
`;

export const UserActionBadge = (props: IProps) => (
  <InfoBadge>
    <Bold>{props.displayName}</Bold>
    &nbsp;{props.action}
  </InfoBadge>
);

export default pure(UserActionBadge);
