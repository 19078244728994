import * as Data from './data-converters';

const algorithm = 'AES-CBC';

/**
 * Encrypted peer message
 */
export interface IEncryptedPayload {
  /** Random 128bits, base64 encoded */
  iv: string;

  /**
   * Encrypted message payload:
   *
   * encrypted =
   *   base64(
   *     encrypt(iv, key,
   *       utf8encode(
   *         stringify(
   *           Payload
   *         )
   *       )
   *     )
   *   );
   */
  encrypted: string;
}

/**
 * Creates a key used for peer message encryption throughout a session
 */
export async function generateSymmetricKey() {
  return crypto.subtle.generateKey({ name: algorithm, length: 256 }, true, [
    'decrypt',
    'encrypt',
  ]);
}

/**
 * Exports a symmetric key as base64
 */
export async function exportKey(key: CryptoKey) {
  const buffer = await crypto.subtle.exportKey('raw', key);
  return Data.arrayBufferToBase64(buffer);
}

/**
 * Imports a symmetric key from base64
 */
export async function importKey(keyData: string) {
  const buffer = Data.base64ToArrayBuffer(keyData);
  return crypto.subtle.importKey(
    'raw',
    buffer,
    { name: algorithm, length: 256 },
    true,
    ['decrypt', 'encrypt'],
  );
}

export async function encryptPayload(
  key: CryptoKey,
  data: any,
): Promise<IEncryptedPayload> {
  const iv = new Uint8Array(16);
  crypto.getRandomValues(iv);

  const jsonData = JSON.stringify(data);
  const base64Data = Data.stringToBase64_utf8(jsonData);
  const bytes = Data.base64ToArrayBuffer(base64Data);

  const encrypted = await crypto.subtle.encrypt(
    { name: algorithm, iv },
    key,
    bytes,
  );

  return {
    encrypted: Data.arrayBufferToBase64(encrypted),
    iv: Data.arrayBufferToBase64(iv),
  };
}

export async function decryptPayload(
  key: CryptoKey,
  payload: IEncryptedPayload,
) {
  const iv = Data.base64ToArrayBuffer(payload.iv);
  const encrypted = Data.base64ToArrayBuffer(payload.encrypted);
  const bytes = await crypto.subtle.decrypt(
    { name: algorithm, iv },
    key,
    encrypted,
  );
  const base64Data = Data.arrayBufferToBase64(bytes);
  const jsonData = Data.base64ToString_utf8(base64Data);
  return JSON.parse(jsonData);
}
