import { difference, isEqual, map } from 'lodash';
import * as Actions from '../actions';
import { createReducer } from '../reducers/create-reducer';
import { getAliasKey } from '../selectors';
import {
  IDesiredConversationParticipant,
  IMessageComposition,
  MessageComposition,
  Messages,
} from '../state';

export const messagesReducer = createReducer(Messages(), {
  // --------------------------------------------------------------------------

  [Actions.SELECT_CONVERSATION]: (
    mState,
    action: Actions.ISelectConversationAction,
  ) => {
    // select conversation in main state
    mState.conversation = action.payload.conversationId;

    // clear composition
    mState.composition = MessageComposition();
  },

  // --------------------------------------------------------------------------

  [Actions.MESSAGES_SEARCH]: (
    mState,
    action: Actions.IMessagesSearchAction,
  ) => {
    mState.search = action.payload.query;
  },

  // --------------------------------------------------------------------------

  [Actions.NEW_MESSAGE]: (mState, action) => {
    mState.composition = MessageComposition();
    mState.phoneAccountGuid = null;
    mState.conversation = null;
    mState.participants = [];
  },

  // --------------------------------------------------------------------------

  [Actions.CONVERSATION_PARTICIPANTS_CHANGED]: (
    mState,
    action: Actions.IConversationParticipantsChangedAction,
  ) => {
    const { phoneAccountGuid, members } = action.payload;

    // Create map of current participant info for faster lookups
    const pMap: { [key: string]: IDesiredConversationParticipant } = {};

    // Only map participants if the phone account matches
    if (mState.phoneAccountGuid === phoneAccountGuid) {
      mState.participants.reduce((acc, p) => {
        acc[getAliasKey(p.member)] = p;
        return acc;
      }, pMap);
    }

    // Map members to the participant info
    const participants = members.map(member => {
      const existingParticipant = pMap[getAliasKey(member)];
      return (
        existingParticipant || {
          status: 'validating',
          member,
        }
      );
    }) as IDesiredConversationParticipant[];

    // Handle case when calling validate again for same participant list
    const hasChanged = !isEqual(participants, mState.participants);
    if (hasChanged) {
      mState.phoneAccountGuid = phoneAccountGuid;
      mState.participants = participants;
      mState.conversation = null;
    }
  },

  // --------------------------------------------------------------------------

  [Actions.CONVERSATION_VALIDATED]: (
    mState,
    action: Actions.IConversationValidatedAction,
  ) => {
    const validatedConversation = action.payload;

    // Check whether validated conversation is relevant for the current composition context
    if (validatedConversation.parent.id !== mState.phoneAccountGuid) {
      return; // Not relevant
    }

    // The current list of desired participants might be different than the
    // participants that the validatedConversation was validated for.
    const desiredMembers = mState.participants.map(p => p.member);
    const validatedMembers = validatedConversation.remoteParticipants;

    // Check to see if the validated conversation participants exactly match the desired participants
    const validatedMembersValues = map(
      validatedMembers,
      member => `${member.aliasType}:${member.alias}`,
    );
    const desiredMembersValues = map(
      desiredMembers,
      member => `${member.aliasType}:${member.alias}`,
    );
    const validForAllDesiredMembers =
      difference(validatedMembersValues, desiredMembersValues).length === 0;

    mState.conversation = validForAllDesiredMembers
      ? validatedConversation
      : null;

    // Update participant validation statuses
    mState.participants = mState.participants.map(p => {
      const isValid = !!validatedMembers.find(
        m => p.member.alias === m.alias && p.member.aliasType === m.aliasType,
      );
      return isValid ? { ...p, status: 'validated' as 'validated' } : p;
    });
  },

  // --------------------------------------------------------------------------

  [Actions.MESSAGES_COMPOSITION_CHANGED]: (
    mState,
    action: Actions.ICompositionChangedAction,
  ) => {
    const composition: IMessageComposition = action.payload;
    mState.composition = composition;
  },

  // --------------------------------------------------------------------------

  [Actions.MESSAGE_SEND_REQUESTED]: (
    mState,
    action: Actions.IMessageSendRequestAction,
  ) => {
    mState.sendRequested = action.payload;
  },
});
