import React from 'react';

export default function AddItem(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <g fill="currentColor" fillRule="evenodd" transform="translate(1 1)">
        <path d="M11 22C4.925 22 0 17.075 0 11S4.925 0 11 0s11 4.925 11 11-4.925 11-11 11zm-.713-11.713H5.713a.714.714 0 0 0 0 1.426h4.574v4.574a.714.714 0 0 0 1.426 0v-4.574h4.574a.714.714 0 0 0 0-1.426h-4.574V5.713a.714.714 0 0 0-1.426 0v4.574z" />
      </g>
    </svg>
  );
}
