import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { IDispatcher } from '../../store';
import { actions } from './modules';
import { emailsPageSelector, Email } from './selectors';
import { IRootState } from '../../state';
import SudoSettingsSelectors from '../../entities/sudo-settings/selectors';
import { IEmailMessageBody, IContact } from '../../api';

interface IOwnProps {
  baseUrl: string;
  sudoSlug: string;
  folderName: string;
  emailMessageId: string;
}

interface ISnackbarError {
  errorType: string;
  message: string;
}

interface IStateProps {
  backToFolderUrl: string;
  sudoGuid: string;
  folderName: string;

  emails: Email[];
  currentEmailMessage: Email;
  currentEmailMessageBody: IEmailMessageBody;
  hasMixedContent: boolean;
  mixedContentAllowed: boolean;
  composerEmail: Email;
  composerBody: any;
  totalCounts: any;
  unreadCounts: any;
  selectedCount: number;
  contacts: IContact[];
  recipients: IContact[];

  isEmailsLoading: boolean;

  confirm: string;
  snackbarError: ISnackbarError;
}

type IActionProps = typeof actions;

const mapState = (state: IRootState, props: IOwnProps): IStateProps => {
  const { baseUrl, sudoSlug, folderName, emailMessageId } = props;
  const sudoGuid = SudoSettingsSelectors.getIdBySlug(state, { slug: sudoSlug });
  const backToFolderUrl = `${baseUrl}/${folderName}`;
  return {
    ...emailsPageSelector(state, { sudoGuid, emailMessageId }),
    backToFolderUrl,
  };
};

const mapDispatch = (dispatch: IDispatcher): IActionProps =>
  bindActionCreators(actions, dispatch);

export default connect(
  mapState,
  mapDispatch,
);
export interface IProps extends IOwnProps, IStateProps, IActionProps {}
