import { stringToBase64_utf8, base64ToString_utf8 } from './data-converters';

export interface IChunk {
  data: string;
  sequence: string; //
}

export function chunkify(payload: any, maxSize: number) {
  if (!maxSize) {
    throw new Error('Invalid `maxSize`');
  }
  const payloadJson = JSON.stringify(payload);
  const payloadBase64 = stringToBase64_utf8(payloadJson);
  const totalBytes = payloadBase64.length;
  const allChunkData: string[] = [];

  let cursor = 0;
  while (cursor < totalBytes) {
    const chunkData = payloadBase64.substring(cursor, cursor + maxSize);
    allChunkData.push(chunkData);
    cursor += chunkData.length;
  }

  return {
    totalBytes,
    chunks: allChunkData.map((chunkData, i) => ({
      data: chunkData,
      sequence: makeSequenceString(i + 1, allChunkData.length),
    })),
  };
}

export class ChunkAssembler {
  private chunksById: { [id: string]: string[] } = {};

  public receive(data: string, sequence: string, id: string) {
    const [current, total] = getSequenceComponents(sequence);

    const isLast = current === total;

    const chunks = (this.chunksById[id] = this.chunksById[id] || []);
    if (chunks.length !== current - 1) {
      delete this.chunksById[id];
      throw new Error(`Sequence error ${id}@${current}`);
    }

    this.chunksById[id].push(data);
    if (isLast) {
      const payloadBase64 = chunks.join('');
      const payloadJson = base64ToString_utf8(payloadBase64);
      const payload = JSON.parse(payloadJson);

      delete this.chunksById[id];
      return payload;
    } else {
      return null;
    }
  }
}

/** Note `current` starts at 1 */
export function makeSequenceString(current: number, total: number) {
  return `${current}/${total}`;
}

export function getSequenceComponents(sequence: string) {
  return sequence.split('/').map(str => parseInt(str, 10));
}
