export enum BrowserStorageKey {
  /** Current connection info */
  CurrentConnection = 'currentConnection',

  /** User preference for default Sudo Environment */
  SudoEnvironmentPreference = 'sudoEnvironmentPreference',

  /** Used for cross-tab messaging */
  BroadcastMessageKey = 'broadcastMessage',

  /** WebRTC flag enabled */
  WebRtc = 'webRtc',
}
