/* tslint:disable max-line-length */
import * as React from 'react';

export default function MessagesEmpty(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={93}
      height={152}
      viewBox="0 0 93 152"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <g fill="#AFB6BD" transform="rotate(17 -323.658 78.2)">
          <path d="M10.122 50.28a2.618 2.618 0 0 1-3.858-2.384l.275-9.076C2.34 34.567 0 29.062 0 23.266 0 10.371 11.469 0 25.526 0c14.058 0 25.526 10.371 25.526 23.266 0 12.895-11.468 23.266-25.526 23.266-2.335 0-4.639-.286-6.863-.85l-8.541 4.598z" />
        </g>
        <g fill="#AFB6BD" transform="matrix(-1 0 0 1 92.786 58.873)">
          <path d="M9.142 45.414a2.365 2.365 0 0 1-3.484-2.153l.248-8.198C2.114 31.222 0 26.25 0 21.015 0 9.368 10.359 0 23.056 0c12.697 0 23.056 9.368 23.056 21.015S35.753 42.029 23.056 42.029c-2.11 0-4.19-.258-6.199-.768l-7.715 4.153z" />
        </g>
        <g fill="#AFB6BD" transform="rotate(15 -72.288 67.107)">
          <path d="M6.04 30.006a1.562 1.562 0 0 1-2.302-1.423l.164-5.416C1.397 20.629 0 17.343 0 13.885 0 6.189 6.844 0 15.233 0c8.39 0 15.234 6.19 15.234 13.885 0 7.695-6.844 13.884-15.234 13.884-1.393 0-2.768-.17-4.095-.507L6.04 30.006z" />
        </g>
        <g fill="#AFB6BD" transform="matrix(-1 0 0 1 81.115 .4)">
          <path d="M4.571 22.707A1.182 1.182 0 0 1 2.83 21.63l.124-4.098C1.057 15.61 0 13.125 0 10.507 0 4.684 5.18 0 11.528 0c6.349 0 11.528 4.684 11.528 10.507 0 5.824-5.18 10.508-11.528 10.508-1.055 0-2.095-.13-3.1-.385l-3.857 2.077z" />
        </g>
      </g>
    </svg>
  );
}
