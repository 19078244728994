import { Button, Icon } from 'src/components';
import * as React from 'react';
import styled from 'styled-components';
import { mapProps } from 'recompose';
import { RouteComponentProps, Link, Redirect } from 'react-router-dom';

import AssignedToolbar from './AssignedToolbar';
import DetailsSections from './DetailsSections';
import withContactContentData, {
  IDispatchFromProps,
  IStateFromProps,
  IStateProps,
} from './withContactContentData';
import { IDetailsRowProps } from './DetailsRow';
import DetailsHeader, { HeaderAvatar } from '../DetailsHeader';
import ActionsToolbar from './ActionsToolbar';

export interface IMatch {
  sudo: string;
  tab: string;
  id: string;
}

export interface IProps
  extends IStateFromProps,
    IStateProps,
    IDispatchFromProps {}

const ContentWrapper = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  background-color: var(--gray-9);
`;

const HeaderToolbar = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const WrappedLine = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 calc(var(--theme-spacing) * 2);
`;

const Name = styled(WrappedLine)`
  font-size: 36px;
  font-weight: bold;
  line-height: 40px;
  margin-top: 8px;
`;
const SecondaryInfo = styled(WrappedLine)`
  height: 23px;
  font-size: 20px;
  line-height: 23px;
  margin-top: 7px;
`;

const DetailsButton = styled(Button)`
  font-size: 17px;
  line-height: 20px;
`;

const AddressContent = styled.div`
  display: flex;
  max-width: 50%;
`;

const AddressBody = styled.div`
  margin-top: 6px;
  word-break: break-word;
`;

const formatSections = (props: IProps): IDetailsRowProps[][] =>
  [
    props.phonesSection.map(
      (section): IDetailsRowProps => ({
        label: section.label,
        value: (
          <DetailsButton
            asLink
            onClick={() => handleCreateConversation(props, section.phone)}
          >
            {section.formattedPhone}
          </DetailsButton>
        ),
      }),
    ),
    props.emailsSection.map(
      (section): IDetailsRowProps => ({
        label: section.label,
        value: (
          <DetailsButton
            asLink
            onClick={() => handleCreateEmail(props, section.email)}
          >
            {section.email}
          </DetailsButton>
        ),
      }),
    ),
    props.address && [
      {
        label: (
          <AddressContent>
            <div>
              <div>Address</div>
              <AddressBody>
                <div>{props.address.address.addressLine1}</div>
                <div>{props.address.address.addressLine2}</div>
                <div>{props.address.address.suburb}</div>
                <div>
                  {props.address.address.state} {props.address.address.postcode}
                </div>
                <div>{props.address.address.country}</div>
              </AddressBody>
            </div>
          </AddressContent>
        ),
      },
    ],
    props.birthday.length > 0 && [
      {
        label: 'Birthday',
        value: props.birthday,
      },
    ],
    !!props.notes && [
      {
        label: 'Notes',
        value: props.notes,
      },
    ],
  ].filter((part?: IDetailsRowProps[]) => part && part.length > 0);

const handleCreateConversation = (props: IProps, phone: string): void => {
  props.createConversation(
    props.sudo,
    props.phoneAccountGuid,
    props.phoneRegion,
    props.contactAndAvatar,
    phone,
  );
};

const handleCreateEmail = (props: IProps, email: string): void => {
  props.createEmail(
    props.sudoGuid,
    props.sudo,
    props.phoneRegion,
    props.contactAndAvatar,
    email,
  );
};

export const ContactContent = (props: IProps) => {
  return !props.isContactValid ? (
    <Redirect to={`/${props.sudo}/contacts`} />
  ) : (
    <ContentWrapper>
      <DetailsHeader
        overlayToolbar={
          props.isEditable && (
            <HeaderToolbar>
              <Link
                to={`/${props.sudo}/contacts/${
                  props.tab
                }/edit/${encodeURIComponent(props.contactGuid)}`}
              >
                <Icon name="Edit" color="var(--blue)" />
              </Link>
            </HeaderToolbar>
          )
        }
      >
        <HeaderAvatar image={props.avatarImage} />
        <Name>{props.fullName}</Name>
        <SecondaryInfo>{props.company}</SecondaryInfo>

        <ActionsToolbar
          canSendEmail={props.canSendEmail}
          canSendMessage={props.canSendMessage}
          contactGuid={props.contactGuid}
          emailsSection={props.emailsSection}
          phonesSection={props.phonesSection}
          onCreateConversation={(phone: string): void =>
            handleCreateConversation(props, phone)
          }
          onCreateEmail={(email: string): void =>
            handleCreateEmail(props, email)
          }
        />
        <AssignedToolbar
          hasSudo={props.hasSudo}
          sudoLabel={props.sudoLabel}
          avatarColor={props.sudoAvatarColor}
          avatarText={props.sudoAvatarText}
          avatarImage={props.sudoAvatarImage}
        />
      </DetailsHeader>
      <DetailsSections sections={formatSections(props)} />
    </ContentWrapper>
  );
};

export const propsMapper = (props: RouteComponentProps<IMatch>) => {
  const { sudo, tab, id } = props.match.params;
  return {
    sudo,
    tab,
    contactGuid: decodeURIComponent(id),
  };
};
const mapRouterProps = mapProps(propsMapper);

export default mapRouterProps(withContactContentData(ContactContent));
