import { IEmailAttachment } from '../../../api';

const getAttachmentSize = (attachment: IEmailAttachment) => {
  if (!attachment || !attachment.data) {
    return 0;
  }
  const { data } = attachment;
  // More about this calculation here https://stackoverflow.com/a/34109103/1162326
  const paddingMatch = data.match(/=+$/);
  const paddingLength = paddingMatch ? paddingMatch[0].length : 0;
  return (data.length / 4) * 3 + paddingLength;
};

export default getAttachmentSize;
