/* tslint:disable max-line-length */
import * as React from 'react';

export default function Unpair(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <defs>
        <path
          id="Unpair-a"
          d="M14.428 8.25L11.72 5.542l1.06-1.061L17.3 9l-4.52 4.52-1.06-1.062 2.708-2.708H3.5v-1.5h10.928zM16 12.992h1.5V15a2.75 2.75 0 0 1-2.75 2.75h-12A2.75 2.75 0 0 1 0 15V3A2.75 2.75 0 0 1 2.75.25h12A2.75 2.75 0 0 1 17.5 3v2.021H16V3c0-.69-.56-1.25-1.25-1.25h-12c-.69 0-1.25.56-1.25 1.25v12c0 .69.56 1.25 1.25 1.25h12c.69 0 1.25-.56 1.25-1.25v-2.008z"
        />
      </defs>
      <g fill="none" fillRule="evenodd" transform="translate(3.25 3)">
        <mask id="Unpair-b" fill="#fff">
          <use xlinkHref="#Unpair-a" />
        </mask>
        <use fill="#979797" fillRule="nonzero" xlinkHref="#Unpair-a" />
        <g fill="currentColor" mask="url(#Unpair-b)">
          <path d="M-3-3h24v24H-3z" />
        </g>
      </g>
    </svg>
  );
}
