import * as React from 'react';
import cn from 'classnames';
import linkifyHtml from 'linkifyjs/html';
import styled from 'styled-components';
import memoize from 'memoize-one';
import { IEmailAttachment } from 'src/api';
import {
  downloadAttachment,
  extractInlineAttachments,
  injectInlineAttachments,
} from '../utils';
import { Spinner } from 'src/components/spinner';
import Attachment from './Attachment';
import { CustomCkEditor } from './custom-ckeditor';

const injectInlineAttachmentsMemo = memoize(injectInlineAttachments);

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const SpinnerContainer = styled.div`
  margin: 70px;
`;

const AttachmentContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-height: 150px;
  min-height: 150px;
  overflow-y: scroll;
  padding: 8px 16px 0;
  margin: 8px 0;

  :empty {
    padding: 0;
    min-height: 0;
  }
`;

export interface IProps {
  value: string;
  attachments: IEmailAttachment[];
  isLoading?: boolean;
  readOnly?: boolean;
  onAttachmentToggleInline?: (attachment: IEmailAttachment) => void;
  onAttachmentRemove?: (index: number) => void;
  onTab?: (e: React.KeyboardEvent<HTMLElement>) => void;
  onMailtoClick?: (email: string) => void;
}

export default class extends React.Component<IProps> {
  private customCkEditor: CustomCkEditor;

  public render() {
    const { body, attachments } = injectInlineAttachmentsMemo(
      this.props.value || '',
      this.props.attachments || [],
    );

    return this.props.isLoading ? (
      <SpinnerContainer>
        <Spinner />
      </SpinnerContainer>
    ) : (
      <Container id="email-editor-container">
        <CustomCkEditor
          data={body}
          onNavigate={this.navigate}
          readOnly={this.props.readOnly}
          ref={this.setRef}
        />
        <AttachmentContainer
          className={cn('attachments', {
            'has-attachments': !!attachments.length,
          })}
        >
          {attachments.map((attachment: any, index: number) => (
            <Attachment
              attachment={attachment}
              key={`${index}::${attachment.filename}`}
              onDoubleClick={() => downloadAttachment(attachment)}
              onRemove={() => this.props.onAttachmentRemove(index)}
            />
          ))}
        </AttachmentContainer>
      </Container>
    );
  }

  private setRef = (component: CustomCkEditor) => {
    this.customCkEditor = component;
  };

  public focus() {
    this.customCkEditor.focus();
  }

  public getContent() {
    const content = extractInlineAttachments(
      this.customCkEditor.getData(),
      this.props.attachments,
    );

    content.body = linkifyHtml(content.body, {
      className: '',
      defaultProtocol: 'https',
      target: null,
    });

    return content;
  }

  private navigate = (href: string) => {
    if (!href.startsWith('mailto:')) {
      window.open(href);
    } else {
      if (this.props.onMailtoClick) {
        const results = /mailto:(.*)/.exec(href);

        this.props.onMailtoClick(results[1]);
      }
    }
  };
}
