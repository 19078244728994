import React from 'react';
import styled from 'styled-components';
import noop from 'lodash/noop';
import cn from 'classnames';

import TabIcon, { IconSetWrapper } from '../TabIcon';
import TabCounter from '../TabCounter';

export interface TabIconSet {
  fill: string;
  outline: string;
}

interface ITabWrapperProps<T> {
  activeColor?: string;
  className?: string;
  color?: string;
  component?: string | React.FunctionComponent<T> | React.ComponentClass<T>;
  hoverColor?: string;
  indicatorColor?: string;
  style?: React.CSSProperties;
  onClick?: (event: React.SyntheticEvent) => void;
}

const TabWrapper = <P extends ITabWrapperProps<any>>(props: P) => {
  const {
    color,
    indicatorColor,
    activeColor,
    hoverColor,
    component,
    ...componentProps
  } = props;

  return React.createElement(props.component, componentProps);
};

export const StyledTabWrapper = styled(TabWrapper)`
  font-weight: 500;
  transition: color var(--theme-transition-function-default) 0.25s;
  position: relative;
  padding: 0 calc(var(--theme-spacing) * 3);
  cursor: pointer;
  display: flex;
  align-items: center;
  color: ${props => props.color};
  text-decoration: none;
  &:after {
    transition: all var(--theme-transition-function-default) 0.25s;
    content: '';
    position: absolute;
    background: ${props => props.indicatorColor};
    height: 3px;
    width: 100%;
    bottom: 0;
    left: 0;
    opacity: 0;
    transform: scale(0);
  }
  &.isActive {
    color: ${props => props.activeColor};
    &:after {
      opacity: 1;
      transform: scale(1);
    }

    & ${IconSetWrapper} {
      & svg {
        &.iconFill {
          display: inline;
        }
        &.iconOutline {
          display: none;
        }
      }
    }
  }
  &:hover {
    color: ${props => props.hoverColor};
  }
`;

export const TabLabel = styled.span`
  font-size: 15px;
`;

interface ITabProps<T> extends ITabWrapperProps<T> {
  children?: any;
  count?: number;
  counterColor?: string;
  iconName?: string;
  iconSet?: TabIconSet;
  isActive?: boolean;
}
const Tab = <T extends any>(props: ITabProps<T> & T) => {
  const {
    children,
    count,
    counterColor,
    iconName,
    iconSet,
    isActive,
    ...tabWrapperProps
  } = props;

  return (
    <StyledTabWrapper
      className={cn(isActive && 'isActive')}
      {...tabWrapperProps}
    >
      <TabCounter count={count} backgroundColor={counterColor}>
        <TabIcon iconName={iconName} iconSet={iconSet} />
        <TabLabel>{children}</TabLabel>
      </TabCounter>
    </StyledTabWrapper>
  );
};

Tab.defaultProps = {
  activeColor: 'var(--gray-1)',
  color: '#a1a6b0',
  component: 'div',
  count: 0,
  counterColor: 'var(--red)',
  fontWeight: 'bold',
  hoverColor: 'var(--gray-1)',
  iconName: null,
  iconSet: null,
  indicatorColor: 'var(--gray-1)',
  isActive: false,
  onClick: noop,
};

Tab.displayName = 'Tab';

export default Tab;
