import * as React from 'react';
import { Provider } from 'react-redux';
import { Route, BrowserRouter } from 'react-router-dom';

import { IStore } from './store';
import App from './features/App';

interface IProps {
  store: IStore;
}

class Root extends React.Component<IProps> {
  public render() {
    const { store } = this.props;
    return (
      <Provider store={store}>
        <BrowserRouter>
          <Route path="/:sudoSlug?" component={App} />
        </BrowserRouter>
      </Provider>
    );
  }
}

let exportRoot: React.ComponentType<any>;
if (process.env.NODE_ENV === 'development') {
  const rhl = require('react-hot-loader'); // tslint:disable-line no-var-requires
  exportRoot = rhl.hot(module)(Root);
} else {
  exportRoot = Root;
}

export default exportRoot;
