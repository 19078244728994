import * as Entities from '../api/entities';
import { IRootState } from '../state';
import { createSelector } from 'reselect';
import { formatNumberForStorage } from '../utils';
import { getGlobalSettings } from './globalSettings';

export const getContactsFromState = (state: IRootState) =>
  state.entities.Contact;

export const getContactAvatars = (state: IRootState) =>
  state.entities.ContactAvatar;

export const getContacts = createSelector(
  getContactsFromState,
  getContactAvatars,
  getGlobalSettings,
  (
    contactsIm,
    contactAvatarsIm,
    globalSettings: Entities.IGlobalSettings,
  ): Record<string, Entities.IContact> => {
    const contacts = contactsIm.toJS() as Record<string, Entities.IContact>;
    const contactAvatars = contactAvatarsIm.toJS();
    return Object.values(contacts).reduce(
      (accumulator, contact) => {
        const avatar = contactAvatars[contact.id];
        return {
          ...accumulator,
          [contact.id]: {
            ...contact,
            avatar: avatar && avatar.avatar,
            phoneNumbers: contact.phoneNumbers.map(phoneNumber => ({
              ...phoneNumber,
              number: formatNumberForStorage(
                globalSettings.countryCode,
                phoneNumber.number,
              ),
            })),
          },
        };
      },
      {} as Record<string, Entities.IContact>,
    );
  },
);

export const getContactsByAlias = createSelector(
  getContacts,
  (contacts): Record<string, Entities.IContact> =>
    Object.values(contacts).reduce(
      (accumulator, contact) => {
        contact.emailAddresses.forEach(emailAddress => {
          const email = emailAddress.email.toLowerCase();
          accumulator[email] = contact;
        });
        contact.phoneNumbers.forEach(phoneNumber => {
          accumulator[phoneNumber.number] = contact;
        });
        return accumulator;
      },
      {} as Record<string, Entities.IContact>,
    ),
);
