/**
 * Status codes
 */
export enum SessionServiceStatusCode {
  /** Successful operation */
  Success = 200,

  /**
   * Pairings cannot be established across servers.
   * Client may try again for cases where pairing becomes
   * local to a server or reconnection goes to same server.
   */
  ServiceUnavailable = 503,

  /**
   * A second connection has been initiated using the binding
   * token for a session with an existing equivalent connection.
   */
  NewConnection = 552,

  /**
   * Slave sent a wakeup message but master has not registered
   * a means by which it can be notified.
   */
  NotNotifiable = 553,

  /**
   * Data message reveived by session service but can't be forwarded
   * to peer because it's disconnected - sender should retry once
   * after short period of time.
   */
  PeerDisconnected = 550,
}
