import { ProtectedTypedEvents } from '../events';

interface DispatchItem<T> {
  id: string;
  payload: T;
  state: DispatchState;
}

enum DispatchState {
  /** Item is waiting to be dispatched. */
  Queued,

  /** Item has been dispatched and is waiting to be dequeued by consumer. */
  Dispatched,
}

export interface DispatchEvent<T = any> {
  id: string;
  payload: T;
}

/**
 * A FIFO queue for tracking dispatchable items.
 *
 * Only `concurrentDispatchLimit` items can be in Dispatched state at a time.
 *
 * After an item is `Dispatched`, the consumer must explicitly dequeue
 * that item so that the next `Queued` item may be dispatched.
 */
export class DispatchQueue<T = any> extends ProtectedTypedEvents<{
  dispatch: DispatchEvent<T>;
}> {
  private queue: Array<DispatchItem<T>> = [];

  /**
   * Ctor
   * @param concurrentDispatchLimit Number of queue items that can be dispatched simultaneously,
   */
  constructor(private concurrentDispatchLimit: number) {
    super();
  }

  /** Adds an item to the queue for dispatch */
  public enqueue(id: string, payload: any) {
    this.queue.push({
      id,
      payload,
      state: DispatchState.Queued,
    });

    this.dispatchNext();
  }

  /**
   * Removes an item from the queue. Doing this will
   * allow subsequent items to be dispatched if the limit allows.
   */
  public dequeue(id: string): boolean {
    const index = this.queue.findIndex(item => item.id === id);
    if (index === -1) {
      return false;
    }

    this.queue.splice(index, 1);
    this.dispatchNext();
    return true;
  }

  /** Number of items in dispatch queue */
  public get length() {
    return this.queue.length;
  }

  /**
   * Resets all items in the queue to `New` state so that
   * everything can be re-dispatched.
   */
  public retryAll() {
    this.queue.forEach(item => {
      item.state = DispatchState.Queued;
    });

    this.dispatchNext();
  }

  /**
   * Finds the next available item on the queue to dispatch.
   * Will not allow more than `concurrentDispatchLimit` items
   * to be dispatched at once.
   */
  private dispatchNext() {
    const limit = Math.min(this.concurrentDispatchLimit, this.queue.length);
    let nextItem: DispatchItem<T>;
    for (let i = 0; i < limit; i++) {
      const item = this.queue[i];
      if (item.state === DispatchState.Dispatched) {
        continue;
      } else {
        nextItem = item;
        break;
      }
    }

    if (!nextItem) {
      return;
    }

    const { id, payload } = nextItem;
    nextItem.state = DispatchState.Dispatched;
    this.emit('dispatch', { id, payload });
    setImmediate(() => {
      this.dispatchNext();
    });
  }
}
