import React from 'react';
import styled from 'styled-components';
import ListLoading from './deps/ListLoading';

export { default as ListItem } from './deps/ListItem';
export { default as ListEmpty } from './deps/ListEmpty';
interface IProps {
  isLoading?: boolean;
  loadingItemsCount?: number;
  isWithCheckBox?: boolean;
  isWithAvatar?: boolean;
  isWithSecondaryText?: boolean;
  checkBoxPosition?: 'left' | 'right';
  children?: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
}

export const StyledList = styled.ul`
  flex-basis: 0;
  flex-grow: 1;
  list-style: none;
  margin: 0;
  padding: 0;
  overflow: auto;
  width: 100%;
`;

const List = (props: IProps) =>
  props.isLoading ? <ListLoading {...props} /> : <StyledList {...props} />;

List.defaultProps = {
  checkBoxPosition: 'left',
  isLoading: false,
  isWithAvatar: false,
  isWithCheckBox: false,
  isWithSecondaryText: false,
  loadingItemsCount: 10,
};

List.displayName = 'List';

export default List;
