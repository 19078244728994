import * as React from 'react';
import { Fields } from 'redux-form';

import {
  AddItemButton,
  FieldRowValue,
  RemovableFieldRowWrapper,
  SectionWrapper,
  Section,
} from '../BaseSectionElements';
import LabelDropdown, { ILabelOption } from '../LabelDropdown';
import RemovableRow from '../RemovableRow';
import FieldInputWithErrorMessage from '../../FieldInputWithErrorMessage';

interface ILabelledFieldRowsFieldsProps {
  selectData: (props: any, index: number) => any;
  labelOptions: ILabelOption[];
  placeholder: string;
}

const LabelledFieldRowsFields = Fields as new () => Fields<
  ILabelledFieldRowsFieldsProps
>;

const LabelledFieldRow = (props: any) => {
  const { selectData, labelOptions, placeholder } = props;
  const { label, value } = selectData(props);
  return (
    <RemovableFieldRowWrapper>
      <LabelDropdown
        label={label.input.value}
        labelOptions={labelOptions}
        onSelectLabel={(newValue: any) => label.input.onChange(newValue)}
      />
      <FieldRowValue>
        <FieldInputWithErrorMessage field={value} placeholder={placeholder} />
      </FieldRowValue>
    </RemovableFieldRowWrapper>
  );
};

const LabelledFieldRowsSection = (props: any) => (
  <SectionWrapper>
    <Section>
      {props.fields.map((name: string, i: number) => (
        <RemovableRow key={i} onRemoveRow={() => props.fields.remove(i)}>
          <LabelledFieldRowsFields
            names={['label', 'value'].map(field => name + '.' + field)}
            selectData={(p: any) => props.selectData(p, i)}
            labelOptions={props.labelOptions}
            placeholder={props.placeholder}
            component={LabelledFieldRow}
          />
        </RemovableRow>
      ))}
      <AddItemButton
        iconName="AddItemOutline"
        iconColor="var(--blue)"
        onClick={props.onAddItem}
      >
        {props.addItemButtonText}
      </AddItemButton>
    </Section>
  </SectionWrapper>
);

export default LabelledFieldRowsSection;
