import React from 'react';
import ButtonIcon from '../ButtonIcon';
import ButtonText from '../ButtonText';

interface IProps {
  style?: React.CSSProperties;
  className?: string;
  children?: React.ReactNode;
  iconColor?: string;
  iconName?: string;
}

const ButtonWithTextAndIcon = (props: IProps) => (
  <React.Fragment>
    {props.iconName && (
      <ButtonIcon
        isWithText
        iconColor={props.iconColor}
        iconName={props.iconName}
      />
    )}
    <ButtonText>{props.children}</ButtonText>
  </React.Fragment>
);

ButtonWithTextAndIcon.displayName = 'ButtonWithTextAndIcon';

export default ButtonWithTextAndIcon;
