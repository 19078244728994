import { IMessageConversation, IPhoneAccount } from '../../api';
import createEntitySelectors, {
  EntitiesArraySelector,
} from '../common/createEntitySelectors';
import ENTITY_TYPE from './entityType';
import { createSelector } from 'reselect';
import PhoneAccountSelectors from '../phone-account/selectors';

const createGetEntitiesBySudoId = (
  getPhoneAccountsBySudoIdSelector: EntitiesArraySelector<IPhoneAccount>,
  getEntitiesArraySelector: EntitiesArraySelector<IMessageConversation>,
) =>
  createSelector(
    getPhoneAccountsBySudoIdSelector,
    getEntitiesArraySelector,
    (phoneAccounts, messageConversations) => {
      return messageConversations.filter(conversation => {
        return (
          phoneAccounts.filter(account => account.id === conversation.parent.id)
            .length > 0
        );
      });
    },
  );

export const createMessageConversationSelectors = () => {
  const commonSelectors = createEntitySelectors<IMessageConversation>(
    ENTITY_TYPE,
  );
  const { getEntitiesArray } = commonSelectors;
  const {
    getEntitiesBySudoId: getPhoneAccountsBySudoId,
  } = PhoneAccountSelectors;

  return {
    ...commonSelectors,
    getEntitiesBySudoId: createGetEntitiesBySudoId(
      getPhoneAccountsBySudoId,
      getEntitiesArray,
    ),
  };
};

const MessageConversationSelectors = createMessageConversationSelectors();

export default MessageConversationSelectors;
