import * as React from 'react';

import EMPTY_STATES from './empty-states';
import HAS_SELECTED_STATES from './has-selected-states';
import HAS_UNREAD_STATES from './has-unread-states';
import NON_EMPTY_STATES from './non-empty-states';

import EmailsEmptyIcon from '../../../icons/EmailsEmpty';
import Empty from '../../../features/Empty';

import { IProps } from './types';

const NoContent = (props: IProps) => {
  const { totalCount, unreadCount, selectedCount, folder } = props;
  let emptyState;
  if (selectedCount > 0) {
    emptyState = HAS_SELECTED_STATES[folder];
  } else if (totalCount === 0) {
    emptyState = EMPTY_STATES[folder];
  } else if (unreadCount > 0) {
    emptyState = HAS_UNREAD_STATES[folder];
  } else if (totalCount > 0) {
    emptyState = NON_EMPTY_STATES[folder];
  }
  if (!emptyState) {
    return null;
  }
  const title =
    typeof emptyState.title === 'function'
      ? (emptyState as any).title(props)
      : emptyState.title;
  const content =
    typeof emptyState.content === 'function'
      ? (emptyState as any).content(props)
      : emptyState.content;
  return <Empty icon={<EmailsEmptyIcon />} title={title} content={content} />;
};

export default NoContent;
