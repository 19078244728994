export enum SudoClientErrorCode {
  ConnectionError,
  InitError,
  RequestAborted,
  RequestFail,
}

export class SudoClientError extends Error {
  public readonly code: SudoClientErrorCode;
  public readonly info: any;

  constructor(code: SudoClientErrorCode, message: string, info?: any) {
    super(message);
    Object.setPrototypeOf(this, SudoClientError.prototype);
    this.code = code;
    this.info = info;
  }
}
