import * as React from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';
import styled from 'styled-components';
import { ListItem } from 'src/components/List';
import { IProps as ListItemProps } from 'src/components/List/deps/ListItem';

const StyledNavLink = styled(NavLink)`
  text-decoration: none;
`;

type IProps = NavLinkProps & ListItemProps;
interface IState {
  isHover: boolean;
}

class NavListItem extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      isHover: false,
    };
  }
  // TODO: handleMouseOver & handleMouseOut are fired when cursor is moved between ListItem child elements
  // Which leads to a large number of setState()
  private handleMouseOver = () => {
    this.setState({ isHover: true });
  };
  private handleMouseOut = () => {
    this.setState({ isHover: false });
  };
  public render() {
    const props = this.props;
    return (
      <ListItem
        {...props}
        isCheckBoxVisible={props.isCheckBoxVisible || this.state.isHover}
        component={StyledNavLink}
        onMouseOver={this.handleMouseOver}
        onMouseOut={this.handleMouseOut}
      />
    );
  }
}

export default NavListItem;
