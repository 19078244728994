import React from 'react';

import MessageBubbleBase from './deps/MessageBubbleBase';
import MessageBubbleText from './deps/MessageBubbleText';
import MessageBubbleTimestamp from './deps/MessageBubbleTimestamp';
import MessageBubbleWithStatus from './deps/MessageBubbleWithStatus';
import MessageBubbleReceived from './deps/MessageBubbleReceived';

export type MessageStatus =
  | 'sending'
  | 'sent'
  | 'delivered'
  | 'read'
  | 'not_delivered';

interface IProps {
  authorName?: string;
  children?: any;
  className?: string;
  isBlurred?: boolean;
  isExternal?: boolean;
  isReceived?: boolean;
  status?: MessageStatus;
  style?: React.CSSProperties;
  timestamp?: React.ReactNode;
}

const MessageBubble = (props: IProps) =>
  props.isReceived ? (
    <MessageBubbleReceived {...props} />
  ) : props.status ? (
    <MessageBubbleWithStatus {...props} />
  ) : (
    <MessageBubbleBase {...props}>
      <MessageBubbleText isBlurred={props.isBlurred}>
        {props.children}
      </MessageBubbleText>
      <MessageBubbleTimestamp>{props.timestamp}</MessageBubbleTimestamp>
    </MessageBubbleBase>
  );

MessageBubble.defaultProps = {
  authorName: null,
  isBlurred: false,
  isExternal: false,
  isReceived: false,
  status: null,
  timestamp: null,
};

export default MessageBubble;
