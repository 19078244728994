import { debounce, isEqual, memoize } from 'lodash';
import { IMessageConversation } from '../api';
import { assert } from '../assert';
import { processEntity } from './process-entity-action';
import { IThunk } from './actions-base';
import { IDispatcher } from '../store';
import { IRootState } from '../state';

const UNLATCH_TIMEOUT_MS = 15000;

/**
 * Resets a conversation's `isTypingRemote` value after a delay. This only affects
 * our local store, it doesn't perform any API calls.
 */
export function handleRemoteTypingChange(
  newConversation: IMessageConversation,
  oldConversation?: IMessageConversation,
): IThunk<void> {
  assert(newConversation);

  return async (dispatch, getState, app) => {
    // FIXME(mysudo): typing status is not available at the moment
    // // Only trust the new remote typing value is nothing else has changed
    // const trustNewRemoteTypingValue = !oldConversation || isEqual(
    //   { ...newConversation, isTypingRemote: undefined },
    //   { ...oldConversation, isTypingRemote: undefined }
    // );
    //
    // if (!trustNewRemoteTypingValue) {
    //   // `Revert the isTypingRemote value`
    //   dispatch(
    //     processEntity({ ...newConversation, isTypingRemote: oldConversation.isTypingRemote } as IMessageConversation)
    //   );
    // } else if (newConversation.isTypingRemote) {
    //   // Set a debounced timer to properly set isTypingRemote back to false
    //   const debouncedUnlatchFn = unlatchIsTypingDebounceFactory(newConversation.id, dispatch, getState());
    //   debouncedUnlatchFn();
    // }
  };
}

// /**
//  * Factory for creating debounced unlatching functions specific to a conversation id
//  */
// const unlatchIsTypingDebounceFactory = memoize(
//   (conversationGuid: string, dispatch: IDispatcher, state: IRootState) => {
//     return debounce(
//       () => {
//         const conversation = state.entities.MESSAGE_CONVERSATION.get(conversationGuid);
//         if (!conversation || !conversation.isTypingRemote) {
//           return;
//         }
//
//         dispatch(
//           processEntity({
//             ...conversation,
//             isTypingRemote: false,
//           } as IMessageConversation)
//         );
//       },
//       UNLATCH_TIMEOUT_MS
//     );
//   }
// );
