import * as React from 'react';
import noop from 'lodash/noop';
import ImageBase from './deps/ImageBase';
import ImageZoomable from './deps/ImageZoomable';

export interface ImageProps {
  isZoomable?: boolean;
  isRemovable?: boolean;
  onRemove?: () => void;
  style?: React.CSSProperties;
  className?: string;
}

const Image = (props: ImageProps & React.ImgHTMLAttributes<any>) => {
  return props.isZoomable ? (
    <ImageZoomable {...props} />
  ) : (
    <ImageBase {...props} />
  );
};

Image.defaultProps = {
  isRemovable: false,
  isZoomable: false,
  onRemove: noop,
};

export default Image;
