import * as React from 'react';
import memoizeOne from 'memoize-one';
import { List, AutoSizer } from 'react-virtualized';
import { find } from 'lodash';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Icon from 'src/components/Icon';

import { Avatar, CheckBox } from 'src/components';

import { formatRelativeDateShort } from '../../../utils';

import { Spinner } from '../../../components/spinner';

import { Email } from '../selectors';

import './styles.scss';

interface IProps {
  emails: Email[];
  baseUrl: string;
  currentEmailId: string;
  totalCount: number;

  isLoading: boolean;

  onEmailSelect: (email: Email, value: boolean) => void;
  onScroll: () => void;
}

const LockIconStyled = styled(Icon)`
  margin-right: 3px;
`;

class EmailsList extends React.PureComponent<IProps> {
  public static defaultProps = {
    totalCount: 0,
  };

  private static startOpacity: number = 1;
  private static endOpacity: number = 0.1;

  private isAnyEmailSelected = memoizeOne((emails: Email[]) => {
    return !!find(emails, 'isSelected');
  });

  private handleCheckBoxClick = (e: any) => {
    e.stopPropagation();
  };
  private handleLinkKeyDown = (e: any) => {
    const { emails } = this.props;
    if (this.isAnyEmailSelected(emails)) {
      e.preventDefault();
    }
  };
  private handleListScroll = (scrollProps: any) => {
    const { clientHeight, scrollHeight, scrollTop } = scrollProps;
    const { onScroll } = this.props;
    if (scrollHeight <= clientHeight + scrollTop) {
      onScroll();
    }
  };

  private renderRow = (row: any) => {
    const { key, index, style } = row;
    const { emails, onEmailSelect, baseUrl, currentEmailId } = row.parent.props;
    const email = emails[index];
    return (
      <Link
        to={`${baseUrl}/${email.id}`}
        onClick={this.handleLinkKeyDown}
        key={key}
        style={style}
        className={cn('email-list-item', {
          unread: !email.seen,
          selected: email.isSelected,
          current: currentEmailId === email.id,
        })}
      >
        <div className="checkbox" onClick={this.handleCheckBoxClick}>
          <CheckBox
            value={email.isSelected}
            onChange={(value: boolean) => onEmailSelect(email, value)}
          />
        </div>
        <div className="avatar-container">
          <Avatar image={email.avatarImage} isSudo={email.avatarLocked}>
            {!email.avatarImage && email.avatarText}
          </Avatar>
        </div>
        <div className="preview">
          <div className="row">
            <div className="unread-mark" />
            {email.avatarLocked && <LockIconStyled name="LockFill" />}
            <div className="contact">{email.listItemPrimaryText}</div>
            <div className="time">{formatRelativeDateShort(email.created)}</div>
          </div>
          <div className="row">
            <div className="subject">{email.subject || '(No subject)'}</div>
          </div>
        </div>
      </Link>
    );
  };

  private renderPlaceholderRow = (row: any) => {
    const { key, index, style, parent } = row;
    const { startOpacity, endOpacity } = EmailsList;
    const { placeholderCount } = parent.props;
    const opacity =
      startOpacity - ((startOpacity - endOpacity) * index) / placeholderCount;

    return (
      <div
        key={key}
        style={{ ...style, opacity }}
        className="email-placeholder-list-item"
      >
        <div className="checkbox" />
        <div className="avatar" />
        <div className="preview">
          <div className="row">
            <div className="contact" />
          </div>
          <div className="row">
            <div className="subject" />
          </div>
        </div>
      </div>
    );
  };

  public render() {
    const {
      emails,
      baseUrl,
      isLoading,
      onEmailSelect,
      currentEmailId,
      totalCount,
    } = this.props;
    const rowHeight = 72;
    const listWidth = 375;
    const isAnyEmailSelected = this.isAnyEmailSelected(emails);
    const placeholderCount = 5;
    return (
      <React.Fragment>
        <AutoSizer>
          {({ height, width }: any) => (
            <List
              emails={emails}
              baseUrl={baseUrl}
              onEmailSelect={onEmailSelect}
              currentEmailId={currentEmailId}
              className={cn('emails-list', {
                'has-selected': isAnyEmailSelected,
              })}
              width={listWidth}
              height={height}
              rowCount={emails.length}
              rowHeight={rowHeight}
              rowRenderer={this.renderRow}
              onScroll={this.handleListScroll}
            />
          )}
        </AutoSizer>
        {totalCount === 0 && (
          <List
            totalCount={totalCount}
            placeholderCount={placeholderCount}
            className="emails-placeholder-list"
            width={listWidth}
            height={placeholderCount * rowHeight}
            rowCount={placeholderCount}
            rowHeight={rowHeight}
            rowRenderer={this.renderPlaceholderRow}
          />
        )}
        {isLoading && (
          <div className="spinner-container">
            <Spinner />
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default EmailsList;
