import AddItemFill from './fill/AddItem';
import CallFill from './fill/Call';
import ClearFill from './fill/Clear';
import ContactsFill from './fill/Contacts';
import DeleteFill from './fill/Delete';
import DraftsFill from './fill/Drafts';
import EmailFill from './fill/Email';
import FeedFill from './fill/Feed';
import LockFill from './fill/Lock';
import MessagesFill from './fill/Messages';
import RemoveItemFill from './fill/RemoveItem';
import SentFill from './fill/Sent';

import AddItemOutline from './outline/AddItem';
import CallOutline from './outline/Call';
import ContactsOutline from './outline/Contacts';
import DeleteOutline from './outline/Delete';
import DraftsOutline from './outline/Drafts';
import EmailOutline from './outline/Email';
import FeedOutline from './outline/Feed';
import MessagesOutline from './outline/Messages';
import SentOutline from './outline/Sent';
import UnpairOutline from './outline/Unpair';

import Add from './Add';
import AddContact from './AddContact';
import Attachment from './Attachment';
import Camera from './Camera';
import CheckMark from './CheckMark';
import Close from './Close';
import Compose from './Compose';
import Contact from './Contact';
import Edit from './Edit';
import EmailOpen from './EmailOpen';
import Emoji from './Emoji';
import Forward from './Forward';
import Gif from './Gif';
import Help from './Help';
import Info from './Info';
import Location from './Location';
import Logo from './Logo';
import MessageStatusCheckmark from './MessageStatusCheckmark';
import MessageStatusFailed from './MessageStatusFailed';
import Minus from './Minus';
import Primary from './Primary';
import Refresh from './Refresh';
import Remove from './Remove';
import Reply from './Reply';
import ReplyAll from './ReplyAll';
import Search from './Search';
import SudoRings from './sudo-rings';
import ThreeDots from './ThreeDots';
import Timer from './Timer';
import UnknownFile from './UnknownFile';

export default {
  Add,
  AddContact,
  AddItemFill,
  AddItemOutline,
  Attachment,
  CallFill,
  CallOutline,
  Camera,
  CheckMark,
  ClearFill,
  Close,
  Compose,
  Contact,
  ContactsFill,
  ContactsOutline,
  DeleteFill,
  DeleteOutline,
  DraftsFill,
  DraftsOutline,
  Edit,
  EmailFill,
  EmailOpen,
  EmailOutline,
  Emoji,
  FeedFill,
  FeedOutline,
  Forward,
  Gif,
  Help,
  Info,
  Location,
  LockFill,
  Logo,
  MessageStatusCheckmark,
  MessageStatusFailed,
  MessagesFill,
  MessagesOutline,
  Minus,
  Primary,
  Refresh,
  Remove,
  RemoveItemFill,
  Reply,
  ReplyAll,
  Search,
  SentFill,
  SentOutline,
  SudoRings,
  ThreeDots,
  Timer,
  UnknownFile,
  UnpairOutline,
};
