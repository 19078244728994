import { EntityType, IEntity, ISudo } from '../api/entities';
import { createAction } from './actions-base';

export const LIST_APPEND_ITEMS = 'LIST_APPEND_ITEMS_ACTION';

export interface IAppendListPayload {
  type: EntityType;
  sudo: ISudo;
  entities: IEntity[];
  fetchSize: number;
  tag?: any; // additional information to pass to reducer
}

export const appendList = createAction<IAppendListPayload>(LIST_APPEND_ITEMS);
