import { createAction, IAction } from '../../../actions/actions-base';

const ADD_ERROR = 'messagesErrors/ADD_ERROR';
const REMOVE_LAST_ERROR = 'messagesErrors/REMOVE_LAST_ERROR';

export interface IMessagesError {
  type: string;
  message: string;
}

export interface IPartialState {
  errors: IMessagesError[];
}

export interface IAddErrorPayload {
  type: string;
  message: string;
}

const initialState: IPartialState = {
  errors: [],
};

const addError = createAction<IAddErrorPayload>(ADD_ERROR);

const removeLastError = () => ({
  type: REMOVE_LAST_ERROR,
});

const reducer = (state: IPartialState = initialState, action: IAction) => {
  switch (action.type) {
    case ADD_ERROR:
      const payload: IAddErrorPayload = action.payload;
      const errors = state.errors.filter(p => p.type !== payload.type);
      errors.push(payload);
      return {
        ...state,
        errors,
      };
    case REMOVE_LAST_ERROR:
      return {
        ...state,
        errors: state.errors.slice(0, -1),
      };
    default:
      return state;
  }
};

export const actions = {
  addError,
  removeLastError,
};

export default reducer;
