import {
  IMessage,
  IMessageConversation,
  IMessageConversationMember,
  MessageDirection,
  MessageProtocol,
  MessageStatus,
} from '../api';
import { assert } from '../assert';
import { TEMP_GUID_PREFIX } from '../config';
import { IMessageComposition, MessageComposition } from '../state';
import {
  dataUriToMessageAttachment,
  isEmptyOrNotSetString,
  stringToEmojiTranslater,
} from '../utils';
import { notifyEntity } from './api-notify-entity';
import { processEntity } from './process-entity-action';
import { saveEntity } from './save-entities-action';
import { actions } from '../pages/Messages/ConversationCreation/modules';
import { actions as messagesErrorsActions } from '../pages/Messages/MessagesErrors/modules';
import { IThunk } from './actions-base';
import { IDispatcher } from '../store';
import { messageCompositionChanged } from './messages-composition-changed-action';

export const MESSAGE_SEND_REQUESTED = 'MESSAGE_SEND_REQUESTED_ACTION';

export interface IMessageSendRequestAction {
  type: typeof MESSAGE_SEND_REQUESTED;
  payload: boolean;
}

/**
 * Action to dispatch if any pending request to send a message should be canceled
 */
export function cancelMessageSend() {
  return {
    type: MESSAGE_SEND_REQUESTED,
    payload: false,
  } as IMessageSendRequestAction;
}

/**
 * Sends a message based on current composition state
 */
export function sendMessageComposition(): IThunk<void> {
  return async (dispatch, getState, app) => {
    const state = getState();

    const conversation =
      typeof state.messages.conversation === 'object'
        ? state.messages.conversation
        : state.entities.MessagingThread.get(state.messages.conversation);

    const composition = state.messages.composition;

    if (!conversation && !state.messages.participants.length) {
      // Prevent sending a message to an empty conversation
      return;
    } else if (!composition.body && !composition.attachmentData.size) {
      // Prevent sending a message without a body
      return;
    } else if (
      conversation &&
      !conversation.encrypted &&
      conversation.remoteParticipants.length > 1
    ) {
      // TODO: Remove once MFW-480 is complete
      dispatch(
        messagesErrorsActions.addError({
          type: 'MESSAGE_SEND_FAIL',
          message:
            'Sorry! Out of network group messaging is not supported. ' +
            'Please send your message to each recipient individually.',
        }),
      );
      return;
    }

    dispatch({
      type: MESSAGE_SEND_REQUESTED,
      payload: true,
    } as IMessageSendRequestAction);

    if (!conversation) {
      // Conversation is currently being validated.
      return;
    }

    // Create new conversation if it has just been validated and we're sending first message
    let confirmedConversation: IMessageConversation;
    if (!conversation.id) {
      try {
        const [result] = await app.api.add([conversation]);
        confirmedConversation = result as IMessageConversation;
      } catch (err) {
        dispatch(
          messagesErrorsActions.addError({
            type: 'MESSAGE_SEND_FAIL',
            message:
              'There was a problem creating conversation. Please try again.',
          }),
        );
      }
    } else {
      confirmedConversation = conversation;
    }

    if (confirmedConversation) {
      if (confirmedConversation.id) {
        // Put the conversation in the store if it's not already there.
        if (
          !getState().entities.MessagingThread.get(confirmedConversation.id)
        ) {
          dispatch(processEntity(confirmedConversation));
        }
        dispatch(actions.setRedirectToConversation(confirmedConversation.id));
      }

      const previousComposition = state.messages.composition;

      // Send the message now
      try {
        dispatch(
          messageCompositionChanged(
            confirmedConversation.id,
            MessageComposition(),
          ),
        );
        await sendMessage(dispatch, confirmedConversation, previousComposition);
      } catch (err) {
        dispatch(
          messageCompositionChanged(
            confirmedConversation.id,
            previousComposition,
          ),
        );
        dispatch(
          messagesErrorsActions.addError({
            type: 'MESSAGE_SEND_FAIL',
            message:
              'There was a problem sending your message. Please try again.',
          }),
        );
      }
    }

    dispatch({
      type: MESSAGE_SEND_REQUESTED,
      payload: false,
    } as IMessageSendRequestAction);
  };
}

const formatMessageTo = (
  conversation: IMessageConversation,
): IMessageConversationMember => {
  if (conversation.threadType === 'group') {
    return {
      aliasType: 'group',
      alias: conversation.id,
    };
  }
  return conversation.remoteParticipants.find(
    member => member.alias !== conversation.localParticipant.alias,
  );
};

/**
 * Sends a message to a conversation
 */
export const sendMessage = async (
  dispatch: IDispatcher,
  conversation: IMessageConversation,
  composition: IMessageComposition,
): Promise<void> => {
  assert(conversation, 'Should be provided');
  assert(composition, 'Should be provided');
  assert(
    conversation.remoteParticipants.length,
    'Conversation must have members',
  );
  assert(
    composition.body || composition.attachmentData.size,
    'Should have content',
  );
  assert(
    !composition.message || !composition.attachmentData.size,
    `Can't update a message with an attachment`,
  );

  const trimmedCompositionBody = composition.body && composition.body.trim();
  const body = isEmptyOrNotSetString(trimmedCompositionBody)
    ? ''
    : stringToEmojiTranslater(trimmedCompositionBody);
  const isUpdate = !!composition.message;
  const messagesToSend: IMessage[] = [];

  let compositionMessageToUpdate = composition.message;
  if (isUpdate) {
    const updateMessageProperties: Partial<IMessage> = {
      body,
      messageStatus: MessageStatus.Unknown,
    };

    if (body === composition.message.body) {
      return;
    }

    compositionMessageToUpdate = {
      ...composition.message,
      ...updateMessageProperties,
    };

    const messageToUpdate = {
      type: 'Message',
      id: compositionMessageToUpdate.id,
    } as IMessage;

    messagesToSend.push({
      ...messageToUpdate,
      ...updateMessageProperties,
    });
  } else {
    const messageType = conversation.encrypted
      ? MessageProtocol.Advanced
      : MessageProtocol.Basic;
    const id = generateClientRefId();
    const messageAttachments = composition.attachmentData
      .map(attachmentData => dataUriToMessageAttachment(id, attachmentData))
      .toArray();

    messagesToSend.push({
      type: 'Message',
      id,
      parent: {
        type: 'MessagingThread',
        id: conversation.id,
      },
      body,
      media: messageAttachments,
      to: formatMessageTo(conversation),
      sender: conversation.localParticipant,
      from: conversation.localParticipant,
      direction: MessageDirection.Outgoing,
      localRefId: id,
      messageStatus: MessageStatus.Unknown,
      read: true,
      messageType,
    } as IMessage);
  }

  const savePromises: Array<Promise<any>> = [];

  messagesToSend.forEach(message => {
    // Notify allows the message to be optimistically displayed in the list
    // while we wait for confirmation from the save operation.
    const optimisticMessage: IMessage = isUpdate
      ? compositionMessageToUpdate
      : {
          ...message,
          created: message.created || Date.now(),
          modified: message.modified || Date.now(),
        };

    dispatch(
      notifyEntity({
        change: isUpdate ? 'UPDATE' : 'INSERT',
        entity: optimisticMessage,
      }),
    );

    // API doesn't support sending multiple messages at once via `saveEntities`
    savePromises.push(
      dispatch(
        saveEntity(message, {
          sendTimeout: 3600000,
          errorHandler: (error: Error): Error => {
            if (error.message === 'PAYMENT_REQUIRED') {
              dispatch(
                messagesErrorsActions.addError({
                  type: 'MESSAGE_SEND_FAIL',
                  message:
                    'Message could not be sent.  Plan limit has been reached.  View your plan in the iOS app.',
                }),
              );
              return;
            }
            throw error;
          },
        }),
      ),
    );
  });

  await Promise.all(savePromises);
};

/**
 * Returns random base64 string prefixed with the temp id, that is exactly 16 chars long.
 * IMPORTANT: `+` chars in this string cause SudoApp to freak out. No `+` chars in this string.
 */
function generateClientRefId() {
  // ClientRefId should be unique across all sessions, so with a 3 char prefix this will
  // result in 9 bytes worth of random data by using the remaining 13 chars in base64. This
  // should be sufficient for our needs here.
  // To be perfectly unique across sessions, a better approach would be to obtain a short
  // session identifier from the api and combine it with an incrementing number.
  // However this would require additional work on the App side so we'll have to make do
  // for now.
  // TODO: suggestion to use 'uniqid' package

  const desiredLength = 16;
  const buffer = new Uint8Array(desiredLength);
  crypto.getRandomValues(buffer);
  const rndBase64String = btoa(String.fromCharCode.apply(null, buffer));
  const safeRndBase64String = rndBase64String.replace(/\+/g, '-');
  return (
    TEMP_GUID_PREFIX +
    safeRndBase64String.substr(0, desiredLength - TEMP_GUID_PREFIX.length)
  );
}
