import React from 'react';

export default function Call(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      {...props}
    >
      <g fill="currentColor" fillRule="evenodd" transform="translate(3 3)">
        <path d="M16.239 17.244c-1.212 1.026-2.884.964-4.86.08-1.02-.456-2.094-1.12-3.195-1.937a29.623 29.623 0 0 1-2.996-2.56c-.211-.207-.664-.686-1.155-1.25-.8-.921-1.553-1.892-2.193-2.872C-.04 5.827-.623 3.403.765 1.762 2.295-.048 3.358-.358 4.182.353c.061.053.195.183.25.241a24.153 24.153 0 0 1 2.622 4.022l.038.102c.09.396-.055.779-.446 1.505l-.063.116c-.326.6-.438.852-.427.916.049.3 1.17 1.654 2.053 2.539.88.88 2.238 2.005 2.537 2.055.064.01.317-.102.917-.428l.115-.062c.725-.39 1.107-.535 1.503-.446l.102.037a23.776 23.776 0 0 1 4.086 2.68c.006.008.132.14.183.2.703.829.392 1.886-1.413 3.414l-.277-.327.277.327z" />
      </g>
    </svg>
  );
}
