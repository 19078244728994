import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

interface IProps {
  children: React.ReactNode | string;
  isWithSecondaryText?: boolean;
  timeLabel?: string;
}

export const StyledPrimaryText = styled.div<IProps>`
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 17px;
  color: var(--black);
  line-height: 20px;
  ${props =>
    !props.isWithSecondaryText &&
    css`
      align-self: center;
    `}
`;

export const StyledPrimaryTextWithTimeLabelWrapper = styled.div<IProps>`
  display: flex;
  width: 100%;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  color: var(--black);
  ${props =>
    !props.isWithSecondaryText &&
    css`
      align-self: center;
    `}
`;

export const PrimaryTextTimeLabel = styled.div`
  width: 90px;
  font-size: 15px;
  line-height: 20px;
  text-align: right;
  color: var(--gray-3);
`;

const PrimaryTextWithTimeLabel = (props: IProps) => (
  <StyledPrimaryTextWithTimeLabelWrapper
    isWithSecondaryText={props.isWithSecondaryText}
  >
    <StyledPrimaryText>{props.children}</StyledPrimaryText>
    <PrimaryTextTimeLabel>{props.timeLabel}</PrimaryTextTimeLabel>
  </StyledPrimaryTextWithTimeLabelWrapper>
);

PrimaryTextWithTimeLabel.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  isWithSecondaryText: PropTypes.bool,
  timeLabel: PropTypes.string.isRequired,
};

PrimaryTextWithTimeLabel.defaultProps = {
  isWithSecondaryText: false,
};

const ListItemPrimaryText = (props: IProps) =>
  props.timeLabel ? (
    <PrimaryTextWithTimeLabel {...props} />
  ) : (
    <StyledPrimaryText {...props} />
  );

ListItemPrimaryText.defaultProps = {
  isWithSecondaryText: false,
};

ListItemPrimaryText.displayName = 'ListItemPrimaryText';

export default ListItemPrimaryText;
