import { ISudo } from '../../api';
import createEntitySelectors from '../common/createEntitySelectors';
import ENTITY_TYPE from './entityType';

export const createSudoSelectors = () => {
  const commonSelectors = createEntitySelectors<ISudo>(ENTITY_TYPE);

  return {
    ...commonSelectors,
  };
};

const SudoSelectors = createSudoSelectors();

export default SudoSelectors;
