import * as React from 'react';
import { connect } from 'react-redux';
import { ToastContainer, ToastMessageFactory } from 'react-toastr';

import 'animate.css/animate.css';
import 'toastr/build/toastr.css';
import { resetNotification } from '../../actions';
import { IRootState, IToastNotification } from '../../state';
import { IDispatcher } from '../../store';
import './toast-notification.scss';

interface IProps {
  notification: IToastNotification;
  resetNotification(): void;
}

export class ToastNotificationComponent extends React.Component<IProps, {}> {
  private container: any;

  public componentWillReceiveProps(nextProps: IProps) {
    if (
      this.props.notification.message !== nextProps.notification.message &&
      nextProps.notification.message !== ''
    ) {
      this.addNotification(nextProps.notification);
      this.props.resetNotification();
    }
  }

  public render() {
    return (
      <ToastContainer
        toastMessageFactory={ToastMessageFactory}
        ref={r => (this.container = r)}
        className="toast-container toast-top-right"
      />
    );
  }

  public addNotification(notification: IToastNotification) {
    // tslint:disable-next-line:ban-types
    let alertMethod: Function;

    switch (notification.type) {
      case 'info':
        alertMethod = this.container.info.bind(this.container);
        break;
      case 'success':
        alertMethod = this.container.success.bind(this.container);
        break;
      case 'warning':
        alertMethod = this.container.warning.bind(this.container);
        break;
      case 'error':
        alertMethod = this.container.error.bind(this.container);
        break;
      default:
        alertMethod = this.container.info.bind(this.container);
    }

    alertMethod(notification.message, notification.title, {
      timeOut: 10000,
      extendedTimeOut: 60000,
    });
  }
}

function mapStateToProps(state: IRootState) {
  return {
    notification: state.app.notification,
  };
}

const mapDispatchToProps = (dispatch: IDispatcher) => {
  return {
    resetNotification: () => {
      dispatch(resetNotification());
    },
  };
};

export const ToastNotification = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ToastNotificationComponent);
