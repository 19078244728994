import * as React from 'react';
import styled from 'styled-components';
import Icon from 'src/components/Icon';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledIcon = styled(Icon)`
  flex: 1 100%;
  width: 56px;
  height: 56px;
`;

const StyledText = styled.span`
  flex: 1 100%;
  color: var(--gray-4);
  line-height: 23px;
  text-align: center;
  display: inline-block;
  font-size: 20px;
  margin: 0 10px;
`;

export default () => (
  <Wrapper>
    <StyledIcon name="Search" color="var(--gray-4)" />
    <StyledText>
      Could not find any contacts with the provided search criteria.
    </StyledText>
  </Wrapper>
);
