export interface IWakeupProvider {
  maxKeepAwakes: number;
  keepAwakeMs: number;
  simulator?: boolean;
  wakeUp: () => Promise<void>;
}

export class WakeupHandler {
  /**
   * Currently active keep-awake timer (interval)
   */
  private keepAwakeTimer: any;

  /**
   * List of currently executing tasks that require the app
   * to be kept awake for processing.
   */
  private keepAwakeTasks: string[] = [];

  /**
   * Number of keepawake attempts remaining. This gets reset to
   * default (kMaxKeepAwake) with every new keep awake task.
   */
  private keepAwakeRemaining: number = 0;

  /**
   * Wakeup promises that should resolve when we detect that
   * the master is awake.
   */
  private pendingWakeupCallbacks: Array<{
    callback: () => void;
    id: string;
  }> = [];

  /**
   * Initializes the handler with a provider that exposes
   * keepalive functionality of the SessionService and SessionKit
   */
  constructor(private provider: IWakeupProvider) {}

  /**
   * Handles status change events
   */
  public async updateStatus(isAwake: boolean) {
    if (isAwake) {
      // Process this on the next tick to handle a race condition when
      // beginAwakeTask is called immediately after update status.
      Promise.resolve().then(() => {
        for (const cb of this.pendingWakeupCallbacks) {
          // Ensure that the Promise callbacks are invoked in the correct order
          Promise.resolve().then(() => cb.callback());
        }
        this.pendingWakeupCallbacks = [];
      });
    }
  }

  /**
   * Returns true if the wakeup handler is currently active
   */
  public isRunning() {
    return !!this.keepAwakeTimer;
  }

  /**
   * Tracks a new task that requires the app to be kept awake.
   */
  public async beginAwakeTask(id: string): Promise<string> {
    this.keepAwakeRemaining = this.provider.maxKeepAwakes;
    this.keepAwakeTasks.push(id);

    const shouldStart = !this.provider.simulator && !this.isRunning();

    // `wakeupPromise` will resolve when we are sure the service is awake
    // The string value is a status, useful for debugging.
    let wakeupPromise: Promise<string>;

    if (shouldStart || this.pendingWakeupCallbacks.length > 0) {
      wakeupPromise = new Promise<string>(resolve => {
        const status = shouldStart ? 'start' : 'wait';

        this.pendingWakeupCallbacks.push({
          id,
          callback: () => resolve(status),
        });
      });
    } else {
      wakeupPromise = Promise.resolve('no-wait');
    }

    if (shouldStart) {
      this.runWakeupTimer();
      await this.provider.wakeUp();
    }

    return wakeupPromise;
  }

  /**
   * Remove a keep-awake task from the list. This will not immediately
   * stop the keep-awake timer, but if there are still no tasks on the
   * next interval, then the timer will stop.
   */
  public endAwakeTasks(id: string) {
    this.keepAwakeTasks = this.keepAwakeTasks.filter(item => item !== id);
  }

  /**
   * Cleans up
   */
  public reset() {
    this.stopKeepAwakeTimer();
    this.pendingWakeupCallbacks = [];
  }

  /**
   * Starts the keepawake timer
   */
  private runWakeupTimer() {
    this.keepAwakeTimer = setInterval(() => {
      // Keepawake as long as there is something in the task list
      if (this.keepAwakeTasks.length === 0) {
        this.stopKeepAwakeTimer();
        return;
      }

      // Do periodic keepAwake
      this.provider.wakeUp();

      // Enforce a maximum amount of keepAwake attempts
      this.keepAwakeRemaining--;
      if (this.keepAwakeRemaining === 0) {
        this.stopKeepAwakeTimer();
      }
    }, this.provider.keepAwakeMs);
  }

  /**
   * Stops the keepawake timer
   */
  private stopKeepAwakeTimer() {
    clearInterval(this.keepAwakeTimer);
    this.keepAwakeTimer = null;
    this.keepAwakeRemaining = 0;
  }
}
