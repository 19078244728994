import React from 'react';
import styled, { css } from 'styled-components';
import omit from 'lodash/fp/omit';
import CardWrapper from './deps/CardWrapper';
import CardCounter from './deps/CardCounter';

export interface IProps {
  image?: string;
  isWithOverlay?: boolean;
  isSelectable?: boolean;
  isActive?: boolean;
  children?: React.ReactNode;
  count?: number;
  counterColor?: string;
  component?: string | React.ElementType;
  style?: React.CSSProperties;
  className?: string;
}

const withoutCardProps = omit([
  'image',
  'isWithOverlay',
  'isSelectable',
  'isActive',
  'count',
  'counterColor',
]);

const CardComponent = ({ component, ...rest }: IProps) =>
  React.createElement(component, withoutCardProps(rest));

const StyledCard = styled(CardComponent)<IProps>`
  overflow: hidden;
  width: 100%;
  height: 100%;
  background: var(--white);
  border-radius: var(--theme-spacing);
  position: relative;
  display: block;
  & > *:nth-child(2) {
    position: relative;
  }
  ${props =>
    props.isSelectable &&
    css`
      transition: box-shadow var(--theme-transition-function-default) 0.15s;
      cursor: pointer;
      &:hover {
        box-shadow: var(--theme-card-shadow-hover);
      }
    `}
`;

const Card = (props: IProps) => (
  <CardWrapper>
    <StyledCard {...props} />
    <CardCounter color={props.counterColor} count={props.count} />
  </CardWrapper>
);

Card.defaultProps = {
  component: 'div',
  count: 0,
  counterColor: 'var(--red)',
  image: null,
  isActive: false,
  isSelectable: false,
  isWithOverlay: false,
};

export default Card;
