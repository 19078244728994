const DEFAULT_MESSAGE = 'Should be truthy';

export function assert(
  isTruthy: any,
  message: string = DEFAULT_MESSAGE,
  ...rest: any[]
) {
  if (!isTruthy) {
    throw new Error(
      'Assertion failed: ' +
        message +
        '\n' +
        JSON.stringify({ assertArgs: rest }, null, 2),
    );
  }
}
