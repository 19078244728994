import styled from 'styled-components';

interface IProps {
  style?: React.CSSProperties;
  className?: string;
}

const ModalContent = styled.div<IProps>`
  margin-top: calc(var(--theme-spacing) * 2);
`;

export default ModalContent;
