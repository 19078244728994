import * as React from 'react';
import styled from 'styled-components';
import { Icon } from 'src/components';
import { noop } from 'lodash';

import { attachmentToBase64, getAttachmentSize, humanizeBytes } from '../utils';

const Container = styled.div`
  width: 120px;
  height: 120px;
  display: flex;
  flex-direction: column;
  border-radius: 3px;
  border: 1px solid #e6e8ea;
  box-sizing: border-box;
`;

const Preview = styled.div`
  flex: 1;
  background-color: #f2f3f4;
  height: 80px;
  max-height: 80px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: inherit;
    user-select: none;
    pointer-events: none;
  }
`;

const Info = styled.div`
  height: 40px;
  display: flex;
  flex-direction: column;
  padding: 0px 8px;
  justify-content: center;
`;

const Name = styled.div`
  color: #303030;
  font-size: 12px;
  line-height: 16px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const Size = styled.div`
  color: #646c74;
  font-size: 12px;
  line-height: 16px;
`;

const IconContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface IProps {
  attachment: any;
  onDoubleClick?: (e?: any) => void;
  className?: string;
}

const Attachment = (props: IProps) => {
  const { attachment, onDoubleClick, className } = props;
  if (!attachment || attachment.inlineAttachment) {
    return null;
  }
  return (
    <Container onDoubleClick={onDoubleClick || noop} className={className}>
      <Preview>
        {attachment.mimeType && attachment.mimeType.indexOf('image/') === 0 ? (
          <img src={attachmentToBase64(attachment)} />
        ) : (
          <IconContainer>
            <Icon name="UnknownFile" color="#646c74" />
          </IconContainer>
        )}
      </Preview>
      <Info>
        <Name title={attachment.filename}>
          {attachment.filename || 'unnamed_file'}
        </Name>
        <Size>{humanizeBytes(getAttachmentSize(attachment))}</Size>
      </Info>
    </Container>
  );
};

export default Attachment;
