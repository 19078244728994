import * as React from 'react';
import { Avatar, Button } from 'src/components';
import { compose, withStateHandlers } from 'recompose';
import styled from 'styled-components';
import ConversationDetails from '../../ConversationDetails';

interface IProps {
  participants: string;
  avatarImage: string;
  avatarLocked: boolean;
  avatarText: string;
  isGroupConversation: boolean;
  activeMembersWithAvatars: any[];
  sudoSlug: string;
  sudoGuid: string;
  conversationGuid: string;
  phoneAccountGuid: string;
  isConversationDetailsOpen: boolean;
  setIsConversationDetailsOpen: (value: boolean) => void;
  title: string | null;
}

const ToolbarWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  padding: 0 16px;
  height: 56px;
`;

const Participants = styled.div`
  margin-left: calc(var(--theme-spacing));
  color: var(--gray-1);
  font-size: 17px;
  flex-grow: 1;
`;

const AvatarStyled = styled(Avatar)`
  height: 32px;
  width: 32px;
`;

export const ParticipantsViewToolbar = (props: IProps) => {
  return (
    <ToolbarWrapper>
      <AvatarStyled image={props.avatarImage} isSudo={props.avatarLocked}>
        {props.avatarText}
      </AvatarStyled>
      <Participants>{props.title || props.participants}</Participants>
      <Button
        iconName="Info"
        iconColor="var(--blue)"
        onClick={() => props.setIsConversationDetailsOpen(true)}
      />
      <ConversationDetails
        avatarLocked={props.avatarLocked}
        title={props.title}
        isOpen={props.isConversationDetailsOpen}
        onClose={() => props.setIsConversationDetailsOpen(false)}
        isGroupConversation={props.isGroupConversation}
        activeMembersWithAvatars={props.activeMembersWithAvatars}
        sudoSlug={props.sudoSlug}
        sudoGuid={props.sudoGuid}
        conversationGuid={props.conversationGuid}
        phoneAccountGuid={props.phoneAccountGuid}
      />
    </ToolbarWrapper>
  );
};

export default compose(
  withStateHandlers(
    {
      isConversationDetailsOpen: false,
    },

    {
      setIsConversationDetailsOpen: () => value => ({
        isConversationDetailsOpen: value,
      }),
    },
  ),
)(ParticipantsViewToolbar as any) as any;
