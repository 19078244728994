import { EntityType } from '../api';
import { IAction } from './actions-base';

export const FILTER_CONTEXT_LIST = 'FILTER_CONTEXT_LIST_ACTION';

export function filterContextListAction(
  type: EntityType,
  search: string,
): IAction {
  return {
    type: FILTER_CONTEXT_LIST,
    payload: { type, search },
  };
}
