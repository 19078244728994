import * as ListActions from './list-actions';
import { IThunk } from './actions-base';
import { uuid } from 'src/utils';

export function listSearch(
  listId: string,
  searchCriteria: string,
  searchAction: IThunk<string[]>,
): IThunk {
  return async dispatch => {
    const ref = uuid();

    dispatch({
      type: ListActions.LIST_CLEAR,
      payload: {
        listId,
      },
    } as ListActions.IListClearAction);

    dispatch({
      type: ListActions.LIST_ADVISE_FETCHING,
      payload: {
        listId,
        ref,
        searchCriteria,
      },
    } as ListActions.IListAdviseFetchingAction);

    const itemIds = await dispatch(searchAction);

    dispatch({
      type: ListActions.LIST_APPEND_FETCH_RESULT,
      payload: {
        listId,
        itemIds,
        noMoreItems: true,
      },
    } as ListActions.IListAppendFetchResultAction);
  };
}
