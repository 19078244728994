import * as React from 'react';
import FloatingActionButton from '../FloatingActionButton';
import Button from '../Button';
import styled from 'styled-components';

const Wrapper = styled.div`
  flex-flow: row wrap;

  & > * {
    flex: 1 100%;
    display: flex;
    margin: 10px 16px;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export default () => (
  <FloatingActionButton id="floating-FAQ-button">
    <Wrapper>
      <Button
        asLink
        href="https://support.mysudo.com/hc/en-us/categories/360001497514-MySudo-for-Web"
        target="_blank"
      >
        FAQs
      </Button>
      <Button
        asLink
        href="https://support.mysudo.com/hc/en-us/requests/new"
        target="_blank"
      >
        Feedback
      </Button>
    </Wrapper>
  </FloatingActionButton>
);
