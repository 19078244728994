import * as React from 'react';
import {
  Button,
  Modal,
  ModalTitle,
  ModalContent,
  ModalContentText,
  ModalActions,
} from 'src/components';
import styled from 'styled-components';
import cn from 'classnames';

const StyledModal = styled(Modal)`
  max-width: 375px;
  box-sizing: border-box;
`;

const StyledTitle = styled(ModalTitle)`
  font-size: 17px;
  line-height: 22px;
`;

const StyledText = styled(ModalContentText)`
  font-size: 17px;
  line-height: 21px;
`;

const StyledActions = styled(ModalActions)`
  padding: 0 calc(var(--theme-spacing) * 6);

  > button:first-child {
    margin-bottom: calc(var(--theme-spacing) * 2);
    width: 100%;
    padding-top: 12px;
    padding-bottom: 12px;
    border-radius: 34.5px;
    justify-content: center;
  }
`;

const StyledButton = styled(Button)`
  font-weight: normal;
  font-size: 17px;
  span {
    line-height: 21px;
  }
`;

interface IProps {
  isOpen: boolean;
  onAccept: (params: any) => void;
  onCancel: (params: any) => void;
  title: string;
  content: string;
  cancelText: string;
  acceptText: string;
  className?: any;
}

const ConfirmDialogVertical = (props: IProps) => (
  <StyledModal isOpen={props.isOpen} className={cn(props.className)}>
    <StyledTitle>{props.title}</StyledTitle>
    <ModalContent>
      <StyledText>{props.content}</StyledText>
    </ModalContent>
    <StyledActions isVertical>
      <StyledButton primary onClick={props.onAccept}>
        {props.acceptText}
      </StyledButton>
      <StyledButton onClick={props.onCancel} asLink>
        {props.cancelText}
      </StyledButton>
    </StyledActions>
  </StyledModal>
);

export default ConfirmDialogVertical;
