import { get, compact } from 'lodash';
import { IMessageConversation, IMessageConversationMember } from '../api';
import { assert } from '../assert';
import {
  cancelMessageSend,
  sendMessageComposition,
} from './messages-send-action';
import { actions as conversationCreationActions } from '../pages/Messages/ConversationCreation/modules';
import { actions as messagesErrorsActions } from '../pages/Messages/MessagesErrors/modules';
import { IThunk } from './actions-base';
import { IDispatcher } from '../store';
import { IRootState } from '../state';
import PhoneAccountSelectors from '../entities/phone-account/selectors';

// ----------------------------------------------------------------------------

export const CONVERSATION_PARTICIPANTS_CHANGED =
  'COVERSATION_PARTICIPANTS_CHANGED_ACTION';

export interface IConversationParticipantsChangedAction {
  type: typeof CONVERSATION_PARTICIPANTS_CHANGED;
  payload: {
    phoneAccountGuid: string;
    members: IMessageConversationMember[];
  };
}

// ----------------------------------------------------------------------------

export const CONVERSATION_VALIDATED = 'CONVERSATION_VALIDATED_ACTION';

export interface IConversationValidatedAction {
  type: typeof CONVERSATION_VALIDATED;
  payload: IMessageConversation;
}

// ----------------------------------------------------------------------------

/**
 * Propose a list of conversation members for a new conversation.
 */
export function validateParticipants(
  phoneAccountGuid: string,
  members: IMessageConversationMember[],
): IThunk<void> {
  return async (dispatch, getState, app) => {
    let state = getState();

    const phoneAccount = state.entities.Telephone.get(phoneAccountGuid);
    assert(phoneAccount, 'Should exist in store');

    // Let the store know what's being validated
    dispatch({
      type: CONVERSATION_PARTICIPANTS_CHANGED,
      payload: { phoneAccountGuid, members },
    } as IConversationParticipantsChangedAction);

    // Get the updated participant info from the store
    state = getState();
    const participants = state.messages.participants;
    const unvalidatedParticipants = participants.filter(
      p => p.status !== 'validated',
    );

    // Get the number of validated participants from the previous validation
    const lastProposedConversation =
      typeof state.messages.conversation === 'string'
        ? state.entities.MessagingThread.get(state.messages.conversation)
        : state.messages.conversation;
    const lastValidatedParticipantCount =
      (lastProposedConversation &&
        lastProposedConversation.remoteParticipants.length) ||
      0;

    // The API doesn't give us much information when a conversation proposal
    // gets rejected. The best we can do is validate one participant at a time
    // so we at least know which participants are valid or not.
    const participantToValidate = unvalidatedParticipants[0];
    const validatedParticipants = participants.filter(
      p => p.status === 'validated',
    );
    const proposalParticipants = [
      ...validatedParticipants,
      participantToValidate,
    ];
    const proposalMembers = compact(
      proposalParticipants.map(p => (p ? { ...p.member, active: true } : null)),
    );

    try {
      // Propose (create) a virtual conversation object on device. The device
      // will return a conversation object describing the capabilities based
      // on the participants. The returned conversation will not be persisted until
      // at least one message has been sent from it.
      const conversation = {
        type: 'MessagingThread',
        parent: {
          type: 'Telephone',
          id: phoneAccount.id,
        },
        localParticipant: {
          aliasType: 'phone',
          alias: phoneAccount.number,
          active: true,
        },
        remoteParticipants: proposalMembers,
      } as IMessageConversation;
      const [result] = await app.api.add([conversation], {
        validation: true,
      });

      // Notify store of the result
      dispatch({
        type: CONVERSATION_VALIDATED,
        payload: result,
      } as IConversationValidatedAction);

      const localMismatch = participants.filter(
        p =>
          p.member.alias === conversation.localParticipant.alias &&
          p.member.aliasType === conversation.localParticipant.aliasType,
      );
      if (localMismatch.length > 0) {
        // Some of the desired participants happen to be Sudo itself
        alertInvalidMember(
          dispatch,
          getState(),
          conversation.localParticipant,
          null,
        );
        dispatch(
          conversationCreationActions.excludeNewConversationParticipant(
            conversation.localParticipant,
          ),
        );
        dispatch(
          validateParticipants(
            state.messages.phoneAccountGuid,
            state.messages.participants
              .filter(
                p =>
                  !(
                    p.member.alias === conversation.localParticipant.alias &&
                    p.member.aliasType ===
                      conversation.localParticipant.aliasType
                  ),
              )
              .map(p => p.member),
          ),
        );
      } else {
        // Continue with the rest of the participants
        state = getState();
        if (
          state.messages.phoneAccountGuid === phoneAccountGuid &&
          participants.length !== conversation.remoteParticipants.length
        ) {
          dispatch(
            validateParticipants(
              state.messages.phoneAccountGuid,
              state.messages.participants.map(p => p.member),
            ),
          );
        } else if (state.messages.sendRequested && participants.length) {
          // All participants have been validated.
          // Attempt to send the message if the user tried to send it during validation.
          dispatch(sendMessageComposition());
        }
      }
    } catch (err) {
      dispatch(cancelMessageSend());
      state = getState();

      // Message composition context may have changed while we were waiting for the API result
      if (state.messages.phoneAccountGuid !== phoneAccountGuid) {
        return; // No need to proceed
      }

      if (
        state.messages.participants.find(
          p =>
            p.member.alias === participantToValidate.member.alias &&
            p.member.aliasType === participantToValidate.member.aliasType,
        )
      ) {
        alertInvalidMember(
          dispatch,
          getState(),
          participantToValidate.member,
          err,
        );
      }

      // Run the validator again on all participants, excluding the one that just failed
      dispatch(
        conversationCreationActions.excludeNewConversationParticipant(
          participantToValidate.member,
        ),
      );
      dispatch(
        validateParticipants(
          phoneAccountGuid,
          state.messages.participants
            .filter(
              p =>
                !(
                  p.member.alias === participantToValidate.member.alias &&
                  p.member.aliasType === participantToValidate.member.aliasType
                ),
            )
            .map(p => p.member),
        ),
      );
    }
  };
}

function alertInvalidMember(
  dispatch: IDispatcher,
  state: IRootState,
  member: IMessageConversationMember,
  err: Error,
) {
  const allPhoneAccounts = PhoneAccountSelectors.getEntitiesArray(state);
  const allPhoneAccountNumbers = allPhoneAccounts.map(pa => pa.number);

  let reason: string;
  let errType: string;
  if (member.aliasType !== 'phone') {
    errType = 'ALIAS_PHONE';
    reason =
      "Looks like we couldn't add contact to the conversation (invalid alias type). Please try again.";
  } else if (allPhoneAccountNumbers.includes(member.alias)) {
    errType = 'SUDO_PHONE';
    reason =
      'Sorry, sending message to a number on the same device is not currently supported.';
  } else if (err && err.name === 'ApiError') {
    const failDesc = get(err, 'info.response.message.failDesc');

    if (failDesc) {
      const cannotSendSudoOutToParticipantRe = /^cannotSendSudoOutToParticipant\("(.+)"\)$/;
      const cannotSendSudoOutToParticipantMatch = failDesc.match(
        cannotSendSudoOutToParticipantRe,
      );

      if (cannotSendSudoOutToParticipantMatch) {
        errType = 'SUDO_OUT';
        reason =
          'MySudo does not currently support sending SMS messages to phone numbers with this country code.';
      }
    }
  }

  if (!errType && !reason) {
    errType = 'INVALID_PHONE';
    reason = `Sorry, "${
      member.alias
    }" is not a valid phone number. Please check number and try again.`;
  }

  dispatch(
    messagesErrorsActions.addError({
      type: errType,
      message: reason,
    }),
  );
}
