export const stringToEmojiTranslater = (text: string) => {
  return text.replace(emojiRegex, (str, emojiPattern, followCharacter) => {
    return `${strToEmoji[emojiPattern]}${followCharacter}`;
  });
};

const emojiToStr: { [key: string]: string[] } = {
  '🙂': [
    ':-)',
    ':)',
    ':-]',
    ':]',
    ':-3',
    ':3',
    ':->',
    ':>',
    '8-)',
    '8)',
    ':-}',
    ':}',
    ':o)',
    ':c)',
    ':^)',
    '=]',
    '=)',
  ],
  '😃': [':-D', ':D', '8-D', '8D', 'x-D', 'xD', 'X-D', 'XD', '=D', '=3', 'B^D'],
  '🙁': [
    ':-(',
    ':(',
    ':-c',
    ':c',
    ':-<',
    ':<',
    ':-[',
    ':[',
    ':-||',
    '>:[',
    ':{',
    ':@',
    '>:(',
  ],
  '😢': [":'-(", ":'("],
  '😂': [":'-)", ":')"],

  '😧': ["D-':", 'D:<', 'D:', 'D;', 'D='],

  '😮': [':-O', ':O', ':-o', ':o', ':-0', '8-0', '>:O'],

  '😘': [':-*', ':*', ':×'],

  '😉': [';-)', ';)', '*-)', '*)', ';-]', ';]', ';^)', ';D'],

  '😛': [':-P', ':P', ':-p', ':p', ':-b', ':b', 'd:', '=p', '>:P'],

  '😕': [
    ':-/',
    ':/',
    ':-.',
    '>:\\',
    '>:/',
    ':\\',
    '=/',
    '=\\',
    ':L',
    '=L',
    ':S',
  ],

  '😐': [':-|', ':|'],

  '😞': [':$'],

  '🤐': [':-X', ':X', ':-#', ':#', ':-&', ':&'],

  '👼': ['O:-)', 'O:)', '0:-3', '0:3', '0:-)', '0:)', '0;^)'],

  '😈': ['>:-)', '>:)', '}:-)', '}:)', '3:-)', '3:)', '>;)'],

  '😎': ['|;-)', '|-O'],

  '😏': [':-J'],

  '😵': ['%-)', '%)'],
};

// This escapes emoji charecters so that they're regex friendly
const escapeEmojiForRegex = (text: string) => {
  return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
};

const strToEmoji = Object.keys(emojiToStr).reduce(
  (acc, emoji) => {
    const symbols = emojiToStr[emoji];
    symbols.forEach(symbol => (acc[symbol] = emoji));
    return acc;
  },
  {} as { [key: string]: string },
);

const regexGroups = Object.keys(emojiToStr)
  .reduce((acc, emoji) => {
    const symbols = emojiToStr[emoji].map(symbol =>
      escapeEmojiForRegex(symbol),
    );
    return [...acc, ...symbols];
  }, [])
  .join('|');

const emojiRegex = new RegExp(`(${regexGroups})(\\s|\\.|$)`, 'g');

export const isEmptyOrNotSetString = (str: string) =>
  str === null || str === undefined || str === '';
