import { ModalStoreNames } from '../state';
import { IModalEntry } from '../state';
import { IAction } from './actions-base';

export const TOGGLE_MODAL = 'TOGGLE_MODAL_ACTION';

export interface IToggleModalAction<T extends IModalEntry> extends IAction {
  payload: {
    name: string;
    data: T;
  };
}

export const createModalAction = <T extends IModalEntry>(
  name: ModalStoreNames,
  data: T = { isOpen: false } as T,
): IToggleModalAction<T> => {
  return {
    payload: {
      name,
      data,
    },
    type: TOGGLE_MODAL,
  };
};
