import styled from 'styled-components';
import _Tab from './deps/Tab';

interface IProps {
  children?: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
}

const Tabs = styled.div<IProps>`
  display: flex;
  height: 100%;
`;

Tabs.displayName = 'Tabs';

export default Tabs;
export const Tab = _Tab;
