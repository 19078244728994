import * as React from 'react';
import { pure } from 'recompose';
import MessageBubble from 'src/components/MessageBubble';
import Timer from 'src/components/Timer';
import {
  IWithActionId,
  MessageRowWrapper,
  MessageSentRow,
  MessageWrapper,
  RowOptions,
} from '../BaseMessageElements';
import WithLargeEmojiAndLinkify from '../WithLargeEmojiAndLinkify';

export type MessageStatusType =
  | 'sending'
  | 'sent'
  | 'delivered'
  | 'read'
  | 'not_delivered';

export interface IProps extends IWithActionId {
  status: MessageStatusType;
  message: string;
  onEditMessage: (id: string) => void;
  onDeleteMessage: (id: string) => void;
  isExternal?: boolean;
  timeout?: number;
}

export const SentMessage = (props: IProps) => (
  <MessageRowWrapper>
    <MessageSentRow>
      <div>
        <MessageBubble
          status={props.status}
          isExternal={props.isExternal}
          timestamp={
            props.timeout ? <Timer value={props.timeout} paused /> : null
          }
        >
          {!props.isExternal && (
            <RowOptions
              isFlipped
              onDeleteMessage={() => props.onDeleteMessage(props.actionId)}
              onEditMessage={() => props.onEditMessage(props.actionId)}
            />
          )}
          <MessageWrapper>
            <WithLargeEmojiAndLinkify message={props.message} />
          </MessageWrapper>
        </MessageBubble>
      </div>
    </MessageSentRow>
  </MessageRowWrapper>
);

export default pure(SentMessage);
