import * as React from 'react';
import styled from 'styled-components';
import { Icon } from '..';

interface IToggleProps {
  checked: boolean;
  onChange: (checked: boolean) => void;
}

export const Checkbox = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
`;

const ToggleWrapper = styled.label`
  background-color: var(--gray-6);
  display: flex;
  position: relative;
  cursor: pointer;
  border-radius: 12px;
  height: 24px;
  width: 48px;
`;

interface ISlider {
  checked: boolean;
}

const Slider = styled.span<ISlider>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  height: 24px;
  width: 24px;
  position: absolute;
  top: 0;
  left: 0;
  background-color: ${props =>
    props.checked ? 'var(--blue)' : 'var(--gray-4)'};
  transition: background-color ease-in-out 0.25s, transform ease-in-out 0.25s;
  transform: ${props => (props.checked ? 'translateX(24px)' : 'none')};
`;

const StyledIcon = styled(Icon)<ISlider>`
  color: var(--white);
  opacity: ${props => (props.checked ? 1 : 0)};
  transition: opacity ease-in-out 0.25s;
`;

const Toggle = (props: IToggleProps) => (
  <ToggleWrapper>
    <Checkbox
      type="checkbox"
      checked={props.checked}
      onChange={event => props.onChange(event.target.checked)}
    />
    <Slider checked={props.checked}>
      <StyledIcon name="CheckMark" checked={props.checked} />
    </Slider>
  </ToggleWrapper>
);

export default Toggle;
