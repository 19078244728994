import { Icon } from 'src/components';
import * as React from 'react';
import { Route, Switch, Link } from 'react-router-dom';
import styled from 'styled-components';

import {
  Layout,
  LayoutContent,
  LayoutList,
  LayoutListToolbar,
} from './MessagesLayout';
import ConversationsList from './ConversationsList';
import ScrollLoadMore from '../../features/List/utils/ScrollLoadMore';
import ConversationCreation from './ConversationCreation';
import ConversationContent from './ConversationContent';
import EmptyContent from './EmptyContent';
import withMessagesData, {
  IDispatchFromProps,
  IStateFromProps,
  IStateProps,
} from './withMessagesData';
import MessagesErrors from './MessagesErrors';

interface IProps extends IStateProps, IStateFromProps, IDispatchFromProps {}

const AddMessageConversationLink = styled(Link)`
  text-decoration: none;
  display: flex;
  align-items: center;
  color: var(--blue);

  &.disabled {
    color: #8c949c;
    cursor: default;
  }

  svg {
    margin-right: 8px;
  }

  span {
    line-height: 20px;
    font-size: 17px;
    font-weight: normal;
  }
`;

export const MessagesPage = (props: IProps) => (
  <Layout>
    <LayoutListToolbar>
      <AddMessageConversationLink
        className={!props.messagingEnabled && 'disabled'}
        to={`/${props.match.params.sudo}/messages/new`}
        onClick={event =>
          props.messagingEnabled
            ? props.clearConversationComposition()
            : event.preventDefault()
        }
      >
        <Icon name="Compose" />
        <span>Send a message</span>
      </AddMessageConversationLink>
    </LayoutListToolbar>

    <ScrollLoadMore>
      {({ onScroll, onLoadMore }) => (
        <LayoutList onScroll={onScroll}>
          <ConversationsList
            sudoSlug={props.match.params.sudo}
            onLoadMore={onLoadMore}
          />
        </LayoutList>
      )}
    </ScrollLoadMore>

    <LayoutContent>
      <Switch>
        <Route path="/:sudo/messages/new" component={ConversationCreation} />
        <Route
          path="/:sudo/messages/:id"
          render={routeProps => (
            <ScrollLoadMore isScrollUp>
              {({ onScroll, onLoadMore }) => (
                <ConversationContent
                  {...routeProps}
                  onScroll={onScroll}
                  onLoadMore={onLoadMore}
                />
              )}
            </ScrollLoadMore>
          )}
        />
        <Route path="/:sudo/messages" component={EmptyContent} />
      </Switch>
    </LayoutContent>

    <MessagesErrors />
  </Layout>
);

export default withMessagesData(MessagesPage);
