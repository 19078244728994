import { groupBy, sortBy, mapValues, values, flatten } from 'lodash';
import * as Actions from 'src/actions';
import { IGlobalSettings, IEntityBase } from 'src/api';
import { IThunk } from './actions-base';

export const tempGlobalSettings: IGlobalSettings = {
  id: 'TEMP-GLOBAL-SETTINGS',
  countryCode: 'US',
  created: Date.now(),
  modified: Date.now(),
  type: 'GLOBAL_SETTINGS',
  version: 1,
};

export function loadInitialDataAction(): IThunk {
  return async (dispatch, _getState, app) => {
    let initialEntities: IEntityBase[] = await app.api.search({
      Sudo: {},
      SudoSettings: {},
      EmailAccount: {},
      Telephone: {},
      Counts: {},
      Contact: {},
    });

    initialEntities = deDupeSudoSettings(initialEntities);

    // TODO: Remove this GlobalSettings is not a supported entity any more
    initialEntities.push(tempGlobalSettings);

    dispatch(Actions.processEntities(initialEntities));
    dispatch(Actions.appDataLoadedAction());
    dispatch(Actions.fetchSudoAvatars());
  };
}

/**
 * Sometimes there are multiple SudoSettings objects belonging to a single Sudo.
 * This is a bug in MySudo but it was decided to not fix and instead work around it.
 * In such cases we will use only the oldest.
 * This is the same logic used by MySudo-ios.
 */
export function deDupeSudoSettings(entities: IEntityBase[]) {
  const entitiesByType = groupBy(entities, 'type');
  const sudoSettings = entitiesByType.SudoSettings;
  let sudoSettingsById = groupBy(sudoSettings, 'id');
  sudoSettingsById = mapValues(sudoSettingsById, duplicates => {
    const sorted = sortBy(duplicates, 'created');
    return [sorted[0]];
  });
  entitiesByType.SudoSettings = flatten(values(sudoSettingsById));
  return flatten(values(entitiesByType));
}
