import React from 'react';
import styled, { css } from 'styled-components';
import noop from 'lodash/noop';
import cn from 'classnames';

import Overlay from 'src/components/utils/Overlay';
import CancelListener from 'src/components/utils/CancelListener';

const positionMap = {
  center: '50%',
  end: '100%',
  start: '0%',
};

type Position = 'start' | 'center' | 'end';

interface IProps {
  anchor?: React.ReactNode;
  children?: React.ReactNode;
  isOpen?: boolean;
  positionHorizontal?: Position;
  positionVertical?: Position;
  originHorizontal?: Position;
  originVertical?: Position;
  onClose?: () => void;
  isWithCancelDetection?: boolean;
  style?: React.CSSProperties;
  className?: string;
}

const StyledOverlay = styled(Overlay)`
  transition: none;
  background: transparent;
`;

const PopoverWrapper = styled.div`
  position: relative;
`;

const PopoverBase = styled.div<IProps>`
  position: absolute;
  width: 200px;
  background: #fff;
  border-radius: 4px;
  box-shadow: var(--theme-popover-shadow);
  padding: var(--theme-spacing) 0;
  z-index: 2;
  ${props => css`
    display: ${props.isOpen ? 'block' : 'none'};
    transform: translate(
      -${positionMap[props.originHorizontal]},
      -${positionMap[props.originVertical]}
    );
    top: ${positionMap[props.positionVertical]};
    left: ${positionMap[props.positionHorizontal]};
  `}
`;

const Popover = (props: IProps) => (
  <PopoverWrapper>
    {props.anchor}
    {props.isWithCancelDetection && (
      <StyledOverlay isOpen={props.isOpen} onClick={props.onClose} />
    )}
    <PopoverBase
      className={cn(props.className)}
      isOpen={props.isOpen}
      positionHorizontal={props.positionHorizontal}
      positionVertical={props.positionVertical}
      originHorizontal={props.originHorizontal}
      originVertical={props.originVertical}
    >
      {props.isWithCancelDetection && (
        <CancelListener shouldDetect={props.isOpen} onCancel={props.onClose} />
      )}
      {props.children}
    </PopoverBase>
  </PopoverWrapper>
);

Popover.defaultProps = {
  className: null,
  isWithCancelDetection: true,
  onClose: noop,
  originHorizontal: 'start',
  originVertical: 'start',
  positionHorizontal: 'start',
  positionVertical: 'end',
};

export default Popover;
