import { List, Map } from 'immutable';
import { makeTypedFactory, TypedRecord } from 'typed-immutable-record';

// ----------------------------------------------------------------------------
// Lists State

/** Lists by list id */
export interface IListsState extends Map<string, IListState> {}
export const ListsState = () => Map<string, IListState>() as IListsState;

export function getList(state: IListsState, id: string) {
  return state.get(id) || ListState().withMutations(mList => (mList.id = id));
}

// ----------------------------------------------------------------------------
// List State

const defaultListState = {
  fetchRef: null as string,
  id: null as string,
  itemIds: List<string>(),
  noMoreItems: false,
  searchCriteria: null as string,
  selectedItemIds: List<string>(),
};

export type IListStateProps = typeof defaultListState;
export interface IListState extends TypedRecord<IListState>, IListStateProps {}
export const ListState = makeTypedFactory<IListStateProps, IListState>(
  defaultListState,
);

// ----------------------------------------------------------------------------
// Helpers

/**
 * Determines the next item that should come next when selected items are removed
 * from a list
 */
export const determineNextItemInList = (
  selectedItems: List<string>,
  items: List<string>,
) => {
  let nextItemInList: string = null;

  if (selectedItems.size > 0) {
    const lowestSelectedItemInList = selectedItems.max(id => items.indexOf(id));
    nextItemInList = items
      .skipUntil(id => id === lowestSelectedItemInList)
      .skip(1)
      .first();

    if (!nextItemInList) {
      const highestSelectedItemInList = selectedItems.min(id => {
        const index = items.indexOf(id);
        return index === -1 ? Infinity : index;
      });
      nextItemInList = items
        .takeUntil(id => id === highestSelectedItemInList)
        .last();
    }
  }

  return nextItemInList;
};
