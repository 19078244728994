import * as React from 'react';
import styled from 'styled-components';
import { pure } from 'recompose';
import { Button } from 'src/components';

import { IViewAttachmentModalEntry } from '../../state';
import { attachmentToDataUri } from '../../utils';
import { Spinner } from '../spinner';
import Modal from '../../pages/Messages/Conversation/AttachmentViewModal';

import { getContentType } from '../../pages/Messages/selectors';

interface IProps {
  modal: IViewAttachmentModalEntry;
  onClose: () => void;
}

const SpinnerWrapper = styled.div`
  border-radius: 8px;
  width: 150px;
  height: 150px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CloseButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-bottom: 10px;
  > :first-child {
    padding: 2px;
  }
`;

const AttachmentWrapper = styled.div`
  > img,
  video {
    display: block;
    border-radius: 8px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.24);
    border: solid 4px #ffffff;
    max-width: 800px;
    max-height: 600px;
    min-width: 80px;
    min-height: 50px;
  }
`;

const AttachmentViewModal = pure((props: IProps) => {
  const { modal, onClose } = props;
  const { loading, isOpen, attachment } = modal;

  const contentType = getContentType(attachment);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      {loading && (
        <SpinnerWrapper>
          <Spinner />
        </SpinnerWrapper>
      )}
      {!loading && (
        <React.Fragment>
          <CloseButtonWrapper>
            <Button iconName="Close" iconColor="#fff" onClick={onClose} />
          </CloseButtonWrapper>
          <AttachmentWrapper>
            {contentType === 'IMAGE' && (
              <img src={attachmentToDataUri(attachment)} />
            )}
            {contentType === 'VIDEO' && (
              <video src={attachmentToDataUri(attachment)} controls />
            )}
          </AttachmentWrapper>
        </React.Fragment>
      )}
    </Modal>
  );
});

export default AttachmentViewModal;
