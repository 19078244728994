import * as React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { Button } from 'src/components';

export interface IProps {
  onRemoveRow: () => void;
  children: any;
  className?: any;
}

const FieldRow = styled.div`
  margin-bottom: calc(var(--theme-spacing) * 3);
  display: flex;
  align-items: center;
`;

const RemovableRowContent = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
  margin-left: calc(var(--theme-spacing));
`;

const RemovableRow = (props: IProps) => (
  <FieldRow className={cn(props.className)}>
    <Button
      onClick={props.onRemoveRow}
      iconName="RemoveItemFill"
      iconColor="#ff0000"
    />
    <RemovableRowContent>{props.children}</RemovableRowContent>
  </FieldRow>
);
export default RemovableRow;
