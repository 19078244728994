import classNames from 'classnames';
import * as React from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import styled from 'styled-components';

import './copyable-field.scss';

export interface ICopyableFieldProps {
  text: string;
  copyText?: string;
  className?: string;
}

interface ICopyableCardState {
  showCopiedMessage: boolean;
}

const ChildrenWrapper = styled.span`
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
  min-height: 18px;
`;

export class CopyableField extends React.Component<
  ICopyableFieldProps,
  ICopyableCardState
> {
  public state = { showCopiedMessage: false };

  public render() {
    const { className, text, children, copyText } = this.props;
    const { showCopiedMessage } = this.state;
    const classnames = classNames('copyable-field', className);

    return (
      <CopyToClipboard text={text}>
        <div className={classnames} onClick={this.onClick}>
          <ChildrenWrapper>{children}</ChildrenWrapper>
          <span className={classNames({ copied: showCopiedMessage })}>
            {showCopiedMessage && <div>{copyText ? copyText : '✓ Copied'}</div>}
          </span>
        </div>
      </CopyToClipboard>
    );
  }

  private onClick = (event?: React.MouseEvent<HTMLElement>) => {
    const { showCopiedMessage } = this.state;

    event.preventDefault();
    event.stopPropagation();

    if (!showCopiedMessage) {
      this.setState({ showCopiedMessage: true });
      setTimeout(() => this.setState({ showCopiedMessage: false }), 700);
    }
  };
}
