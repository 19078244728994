import {
  IOrPredicate,
  ISimplePredicate,
  SearchPredicate,
} from 'src/api/message-interfaces';
import { IThunk } from './actions-base';
import { processEntities } from './process-entity-action';
import chunk from 'lodash/chunk';
import {
  AvatarType,
  AvatarMode,
  AvatarFileFormat,
  IAvatarImage,
} from 'src/state/avatarImages';
import { IQueryParams } from 'src/api';

export const AVATAR_IMAGES_FETCHED = 'AVATAR_IMAGES_FETCHED';

export interface IOptions {
  fileFormat?: AvatarFileFormat;
  height?: number;
  mode?: AvatarMode;
  searchPredicate?: SearchPredicate;
  width?: number;
}

export const getSearchPredicate = (ids: string[]): SearchPredicate => {
  return {
    type: 'or',
    children: ids.map(
      (id): ISimplePredicate => ({
        type: 'simple',
        mode: 'equals',
        key: 'id',
        value: id,
      }),
    ),
  } as IOrPredicate;
};

export const fetchAvatarsById = (
  type: AvatarType,
  ids: string[],
  {
    fileFormat = AvatarFileFormat.png,
    height = 100,
    mode = AvatarMode.minFit,
    width = 100,
  }: IOptions = {},
): IThunk<void> => {
  return async (dispatch, getState, { api }) => {
    // Hydrate the ids into IAvatarImage types
    const { avatarImages: state = {} } = getState();

    // If avatarImage already exists in store then skip it.
    const avatarImages = ids.reduce(
      (accumulator, id) => {
        if (state[id] || accumulator[id]) {
          return accumulator;
        }

        return {
          ...accumulator,
          [id]: {
            fileFormat,
            height,
            id,
            mode,
            type,
            width,
          },
        };
      },
      {} as Record<string, IAvatarImage>,
    );

    const filteredIds = Object.keys(avatarImages);
    if (!filteredIds.length) {
      return;
    }

    // Dispatch action with ids we are attempting to fetch
    dispatch({
      type: AVATAR_IMAGES_FETCHED,
      payload: Object.values(avatarImages),
    });

    const bactchedIds = chunk(filteredIds, 5);
    for (const batch of bactchedIds) {
      // Create query params.
      const queryParams: IQueryParams = {
        [type]: getSearchPredicate(batch),
      };

      // Call Api with query params and options.
      const entities = await api.search(queryParams, null, {
        imageResize: { fileFormat, height, mode, width },
      });

      // Process the resulting entities
      dispatch(processEntities(entities));
    }
  };
};

export const fetchContactAvatarsById = (ids: string[]): IThunk<void> =>
  fetchAvatarsById(AvatarType.ContactAvatar, ids);

export const fetchSudoAvatars = (): IThunk<void> => {
  return async (dispatch, _getState, { api }) => {
    const results = await api.search({ SudoAvatarImage: {} }, null, {
      imageResize: {
        width: 300,
        height: 300,
        mode: 'minFit',
        fileFormat: 'png',
      },
    });
    dispatch(processEntities(results));
  };
};
