import { filter, find, map, uniqBy, compact, isMatch } from 'lodash';
import { createSelector } from 'reselect';

import {
  getAvatarText,
  getContactName,
  INameParts,
} from '../Contacts/selectors';
import {
  IEmailMessage,
  IEmailMessageBody,
  IEmailAccount,
  IEmailAddress,
  EmailMessageFolder,
  EmailEncryptionType,
} from '../../api';
import EmailAccountSelectors from '../../entities/email-account/selectors';
import EmailMessageSelectors from '../../entities/email-message/selectors';
import EmailMessageBodySelectors from '../../entities/email-message-body/selectors';
import { IRootState } from '../../state';
import SudoCountsSelectors from '../../entities/sudo-counts/selectors';
import ContactSelectors, {
  IContactAndAvatar,
} from '../../entities/contact/selectors';
import AvatarEmptyIconBase64 from '../../icons/avatar-empty-new-base64';
import { IAvatarData } from '../Messages/selectors';
import { groupCountsByFolderName, inboundEmailSanitizer } from './utils';
import { getContactsByAlias } from 'src/selectors';

export interface ISelectedEmails {
  [guid: string]: boolean;
}

export interface Recipient {
  id?: string;
  key?: string;
  name?: string;
  address: string;
  isSelf?: boolean;
}

export interface Email extends IAvatarData {
  id: string;
  path: string;

  seen: boolean;
  from: Recipient;
  to: Recipient[];
  cc: Recipient[];
  bcc: Recipient[];
  subject: string;
  body?: any;
  created: number;
  modified: number;
  encryptionType?: EmailEncryptionType;

  listItemPrimaryText: string;
  isSelected: boolean;
}

export const emailsPageStateSelector = (state: IRootState): any => ({
  isEmailsLoading: state.emails.isEmailsLoading,
  confirm: state.emails.confirm,
  snackbarError: state.emails.snackbarError,
});

const composerEmailEntitySelector = (state: IRootState): IEmailMessage =>
  state.emails.composerEmail;
const composerBodyEntitySelector = (state: IRootState): any =>
  state.emails.composerBody;
export const selectedEmailsSelector = (state: IRootState): ISelectedEmails =>
  state.emails.selected;
const allowMixedContentSelector = (state: IRootState): boolean =>
  state.emails.allowMixedContent;

const currentSudoGuidSelector = (_: any, { sudoGuid }: { sudoGuid: string }) =>
  sudoGuid;
export const currentFolderNameSelector = (state: IRootState) =>
  state.emails.folderName;
const currentEmailMessageIdSelector = (
  _: any,
  { emailMessageId }: { emailMessageId: string },
) => emailMessageId;

const currentEmailAccountSelector = createSelector(
  EmailAccountSelectors.getEntitiesBySudoId,
  sudoEmailAccounts => {
    return sudoEmailAccounts[0];
  },
);

const emailsRelatedContactsDataSelector = createSelector(
  ContactSelectors.getEntitiesWithAvatarBySudoIdAndShared,
  contactsAndAvatars => {
    return contactsAndAvatars.filter(
      contactData => contactData.contact.emailAddresses.length > 0,
    );
  },
);

interface ICurrentRawEmailsFilter {
  trash: boolean;
  folder?: EmailMessageFolder;
}
export const currentRawEmailsSelector = createSelector(
  EmailMessageSelectors.getEntitiesArray,
  currentFolderNameSelector,
  currentEmailAccountSelector,
  (
    emailMessages: IEmailMessage[],
    folderName: string,
    emailAccount: IEmailAccount,
  ) => {
    const match: ICurrentRawEmailsFilter = { trash: false };
    if (folderName === 'trash') {
      match.trash = true;
    } else if (folderName === 'inbox') {
      match.folder = EmailMessageFolder.Inbox;
    } else if (folderName === 'sent') {
      match.folder = EmailMessageFolder.Sent;
    } else if (folderName === 'drafts') {
      match.folder = EmailMessageFolder.Drafts;
    } else if (folderName === 'spam') {
      match.folder = EmailMessageFolder.Spam;
    }

    return filter(
      emailMessages,
      email =>
        email &&
        email.parent &&
        email.parent.id &&
        email.parent.id === emailAccount.id &&
        isMatch(email, match),
    );
  },
);

export const currentEmailsSelector = createSelector(
  currentRawEmailsSelector,
  selectedEmailsSelector,
  emailsRelatedContactsDataSelector,
  (
    emailEntities: IEmailMessage[],
    selectedEmails,
    contactsAndAvatars,
  ): Email[] => {
    return emailEntities
      .sort((a, b) => b.created - a.created)
      .map(
        (entity: IEmailMessage): Email =>
          mapEmailEntity(entity, contactsAndAvatars),
      )
      .map((email: Email) => ({
        ...email,
        isSelected: selectedEmails[email.id] || false,
      }));
  },
);

const currentEmailMessageSelector = createSelector(
  currentEmailMessageIdSelector,
  EmailMessageSelectors.getEntitiesArray,
  (emailMessageId: string, emailMessages: IEmailMessage[]): IEmailMessage => {
    return emailMessageId ? find(emailMessages, { id: emailMessageId }) : null;
  },
);

export const scrubInlineImagesPropSelector = (
  _state: unknown,
  props: { scrubInlineImages: boolean },
) => props.scrubInlineImages;

interface ICurrentEmailMessageBodyInfo {
  currentEmailMessageBody: IEmailMessageBody;
  hasMixedContent: boolean;
  mixedContentAllowed: boolean;
}
export const currentEmailMessageBodyInfoSelector = createSelector(
  currentEmailMessageSelector,
  EmailMessageBodySelectors.getEntitiesArray,
  allowMixedContentSelector,
  scrubInlineImagesPropSelector,
  (
    emailMessage: IEmailMessage,
    emailBodies: IEmailMessageBody[],
    allowMixedContent: boolean,
    scrubInlineImages: boolean,
  ): ICurrentEmailMessageBodyInfo => {
    if (!emailMessage) {
      return null;
    }

    const emailBody = find(emailBodies, { path: emailMessage.path });
    if (!emailBody) {
      return null;
    }

    const sanitizerResult = inboundEmailSanitizer(
      emailBody.body,
      { allowMixedContent },
      scrubInlineImages,
    );

    return {
      currentEmailMessageBody: {
        ...emailBody,
        body: sanitizerResult.clean,
      },
      hasMixedContent: sanitizerResult.hasMixedContent,
      mixedContentAllowed: sanitizerResult.allowMixedContent,
    };
  },
);

const currentEmailSelector = createSelector(
  currentEmailMessageSelector,
  currentEmailsSelector,
  (emailMessage: IEmailMessage, currentEmails: Email[]): Email => {
    return emailMessage
      ? find(currentEmails, { path: emailMessage.path })
      : null;
  },
);

export const composerEmailSelector = createSelector(
  composerEmailEntitySelector,
  emailsRelatedContactsDataSelector,
  (entity, contactsAndAvatars): Email => {
    return entity ? mapEmailEntity(entity, contactsAndAvatars) : undefined;
  },
);

const composerContactsSelector = createSelector(
  ContactSelectors.getEntitiesWithAvatarBySudoIdAndShared,
  contacts => {
    let contactsListItems = filter(contacts, c => {
      return (
        c.contact && c.contact.emailAddresses && c.contact.emailAddresses.length
      );
    });
    contactsListItems = map(contactsListItems, (c: any) => {
      const { id, first, last, emailAddresses } = c.contact;
      return emailAddresses.map((ea: any) => {
        const displayName = [first, last].filter((i: any) => i).join(' ');
        const avatar = c.avatar && c.avatar.avatar;
        return { id, address: ea.email, displayName, first, last, avatar };
      });
    });
    contactsListItems = [].concat(...contactsListItems); // flatten array
    contactsListItems = uniqBy(contactsListItems, 'address');
    return contactsListItems;
  },
);

export const emailsPageSelector = createSelector(
  currentSudoGuidSelector,
  currentEmailsSelector,
  composerContactsSelector,
  emailsPageStateSelector,
  currentEmailSelector,
  currentEmailMessageBodyInfoSelector,
  composerEmailSelector,
  composerBodyEntitySelector,
  (state: IRootState, { sudoGuid }: any) =>
    SudoCountsSelectors.getEntityById(state, { id: sudoGuid }),
  getContactsByAlias,
  (
    sudoGuid,
    emails,
    contacts,
    state,
    currentEmailMessage,
    selectedEmailMessageBodyInfo,
    composerEmail,
    composerBody,
    counts,
    contactsByAlias,
  ) => {
    const groupedCounts = groupCountsByFolderName(counts);
    const emailAddresses = emails.reduce(
      (accumulator, email) => [
        ...accumulator,
        ...email.to.map(emailAddress => emailAddress.address),
        ...email.cc.map(emailAddress => emailAddress.address),
        ...email.bcc.map(emailAddress => emailAddress.address),
      ],
      [] as string[],
    );
    const recipients = emailAddresses.reduce(
      (accumulator, emailAddress) => {
        const contact = contactsByAlias[emailAddress];
        if (contact) {
          return [...accumulator, contact];
        }
        return accumulator;
      },
      [] as string[],
    );

    return {
      sudoGuid,
      emails,
      contacts,
      ...state,
      recipients,
      currentEmailMessage,
      ...selectedEmailMessageBodyInfo,
      composerEmail,
      composerBody,
      totalCounts: groupedCounts.total,
      unreadCounts: groupedCounts.unread,
      selectedCount: filter(emails, 'isSelected').length,
    };
  },
);

const splitToNameParts = (name: string): INameParts => {
  const parts = name.split(' ');
  const head = parts[0];
  const tail = parts.slice(1);
  return {
    first: head ? head.trim() : null,
    last: tail.length > 0 ? tail.join(' ').trim() : null,
  };
};

export const getEmailAddressContactData = (
  emailAddress: IEmailAddress,
  contactsAndAvatars: IContactAndAvatar[],
) => {
  if (!emailAddress) {
    return;
  }

  return contactsAndAvatars.find((contactData: IContactAndAvatar) => {
    const { contact } = contactData;
    const emailAddresses = contact.emailAddresses || [];
    const emails = emailAddresses.map((ea: any) => ea.email);
    return emails.indexOf(emailAddress.address) >= 0;
  });
};

const getEmailAvatarData = (
  recipients: Recipient[],
  contactsAndAvatars: IContactAndAvatar[],
): IAvatarData => {
  let avatarImage: string | string[] = null;
  let avatarText: string = null;

  if (recipients && compact(recipients).length > 0) {
    const recipientsContactData = recipients.map(recipient =>
      getEmailAddressContactData(recipient, contactsAndAvatars),
    );
    const activeAvatars = recipientsContactData.reduce(
      (avatars, recipientData) => {
        if (recipientData && recipientData.avatar) {
          avatars.push(recipientData.avatar.avatar);
        }
        return avatars;
      },
      [],
    );

    /**
     * Try getting a group avatar if there are recipients matched to contacts with avatars.
     * Otherwise, try getting avatar text if there is only one recipient.
     * If there is no data, show default empty avatar.
     */
    if (activeAvatars.length === 0) {
      if (recipients.length === 1) {
        if (recipientsContactData[0] && recipientsContactData[0].contact) {
          avatarText = getAvatarText(recipientsContactData[0].contact);
        } else if (recipients[0].name) {
          const nameParts = splitToNameParts(recipients[0].name);
          avatarText = getAvatarText(nameParts);
        }
      }
    } else {
      avatarImage = activeAvatars;
    }
  }
  if (!avatarText && !avatarImage) {
    avatarImage = AvatarEmptyIconBase64;
  }

  return {
    avatarImage,
    avatarLocked: false,
    avatarText,
  };
};

export const provideContactData = (
  emailAddress: IEmailAddress,
  contactsAndAvatars: IContactAndAvatar[],
): IEmailAddress | undefined => {
  if (!emailAddress) {
    return;
  }

  if (emailAddress.displayName) {
    return emailAddress;
  }

  const foundData = getEmailAddressContactData(
    emailAddress,
    contactsAndAvatars,
  );

  if (!foundData) {
    return emailAddress;
  }

  const contactName = getContactName(foundData.contact);
  const displayName = contactName && emailAddress.displayName;

  return {
    ...emailAddress,
    displayName,
  };
};

export const mapEmailEntity = (
  entity: IEmailMessage,
  contactsAndAvatars: IContactAndAvatar[] = [],
): Email => {
  const from = provideContactData(entity.from[0], contactsAndAvatars);
  const to = entity.to.map(address =>
    provideContactData(address, contactsAndAvatars),
  );
  const cc = entity.cc.map(address =>
    provideContactData(address, contactsAndAvatars),
  );
  const bcc = entity.bcc.map(address =>
    provideContactData(address, contactsAndAvatars),
  );

  const isInbox = entity.folder === EmailMessageFolder.Inbox;

  let avatarData: IAvatarData;
  if (isInbox) {
    avatarData = getEmailAvatarData([from], contactsAndAvatars);
  } else {
    const avatarRecipients = to.length ? to : cc.length ? cc : bcc;
    avatarData = getEmailAvatarData(avatarRecipients, contactsAndAvatars);
  }

  let listItemPrimaryText;
  if (isInbox || !to || !to.length) {
    listItemPrimaryText = from && (from.displayName || from.address);
  } else {
    listItemPrimaryText = to
      .map((i: IEmailAddress) => i.displayName || i.address)
      .join(', ');
  }

  return {
    id: entity.id,
    path: entity.path,
    seen: entity.seen,
    listItemPrimaryText,
    from,
    to,
    cc,
    bcc,
    encryptionType: entity.encryptionType,
    subject: entity.subject,
    ...avatarData,
    created: entity.created,
    modified: entity.modified,
    isSelected: false,
    avatarLocked: !!entity.encryptionType,
  };
};
