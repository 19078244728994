// tslint:disable:max-line-length

import React from 'react';

export default function Lock(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="12"
      height="12"
      viewBox="0 0 24 24"
      {...props}
    >
      <defs>
        <path
          id="Svg_Lock_path"
          d="M3.25 8.25V5.219C3.25 2.483 5.367.25 8 .25s4.75 2.233 4.75 4.969V8.25H13A2.75 2.75 0 0 1 15.75 11v8A2.75 2.75 0 0 1 13 21.75H3A2.75 2.75 0 0 1 .25 19v-8A2.75 2.75 0 0 1 3 8.25h.25zm8 0V5.219C11.25 3.294 9.786 1.75 8 1.75S4.75 3.294 4.75 5.219V8.25h6.5z"
        />
      </defs>
      <g fill="none" fillRule="evenodd" transform="translate(4 1)">
        <mask id="Svg_Lock_mask" fill="#fff">
          <use xlinkHref="#Svg_Lock_path" />
        </mask>
        <g fill="#121516" mask="url(#Svg_Lock_mask)">
          <path d="M-4-1h24v24H-4z" />
        </g>
      </g>
    </svg>
  );
}
