import { throttle } from 'lodash';
import { IMessageComposition, IRootState } from '../state';
import { processEntity } from './process-entity-action';
import { saveEntity } from './save-entities-action';
import { IThunk } from './actions-base';
import { IDispatcher } from '../store';

export const MESSAGES_COMPOSITION_CHANGED =
  'MESSAGES_COMPOSITION_CHANGED_ACTION';
export const IS_TYPING_THROTTLE_MS = 3000;

export interface ICompositionChangedAction {
  type: typeof MESSAGES_COMPOSITION_CHANGED;
  payload: IMessageComposition;
}

export function messageCompositionChanged(
  conversationGuid: string,
  composition: IMessageComposition,
) {
  return {
    type: MESSAGES_COMPOSITION_CHANGED,
    payload: composition,
  } as ICompositionChangedAction;
}
