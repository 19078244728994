import { Map } from 'immutable';
import { IAction, nullAction } from '../actions/actions-base';

type AnyMap = Map<any, any>;

interface IBehaviourMap<T extends AnyMap> {
  [action: string]: IBehavior<T>;
}

interface IBehavior<T extends AnyMap> {
  // tslint:disable-next-line:callable-types
  (newState: T, action: IAction): void;
}

/**
 * Factory function for creating redux reducers that are convenient to use in combination with our state objects.
 */
export function createReducer<T extends AnyMap>(
  defaultState: T,
  behaviourMap: IBehaviourMap<T>,
) {
  return (state = defaultState, action = nullAction) => {
    const behavior = behaviourMap[action.type];

    if (!behavior) {
      return state;
    }

    return state.withMutations(mState => {
      behavior(mState as T, action);
    });
  };
}
