export enum AvatarType {
  ContactAvatar = 'ContactAvatar',
  SudoAvatar = 'SudoAvatarImage',
}

export enum AvatarMode {
  minFit = 'minFit',
}

export enum AvatarFileFormat {
  png = 'png',
}

export interface IAvatarImage {
  fileFormat: AvatarFileFormat;
  height: number;
  id: string;
  mode: AvatarMode;
  type: AvatarType;
  width: number;
}

export interface IAvatarImages {
  [key: string]: IAvatarImage;
}
