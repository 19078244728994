import * as React from 'react';
import styled from 'styled-components';

import FieldInput from '../Base/FieldInput';
import {
  FieldRowLabel,
  RemovableFieldRowWrapper,
} from '../Base/BaseSectionElements';
import SectionWithRemovableField from '../Base/SectionWithRemovableField';
import { WrappedFieldProps } from 'redux-form/lib/Field';

interface IAddressSectionFieldProps {
  placeholder: string;
}

interface IStateAndZipProps {
  state: { input: object };
  postcode: { input: object };
}

const AddressFieldWrapper = styled.div`
  margin-top: calc(var(--theme-spacing) * 1.5);
  :first-child {
    margin-top: initial;
  }
`;

const AddressFields = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const StateAndZipWrapper = styled.div`
  margin-top: calc(var(--theme-spacing) * 1.5);
  display: flex;
`;

const StyledStateFieldInput = styled(FieldInput)`
  max-width: 40px;
`;

const StyledZipFieldInput = styled(FieldInput)`
  padding-left: 16px;
  flex-grow: 1;
`;

const VerticalBorder = styled.div`
  border-right: 1px solid var(--gray-5);
  position: relative;
  top: 8px;
  height: 25px;
`;

const AddressField = (props: WrappedFieldProps & IAddressSectionFieldProps) => (
  <AddressFieldWrapper>
    <FieldInput {...props.input} placeholder={props.placeholder} />
  </AddressFieldWrapper>
);
const StateAndZipFields = (props: IStateAndZipProps) => {
  const { state, postcode } = props;

  return (
    <StateAndZipWrapper>
      <StyledStateFieldInput {...state.input} placeholder="State" />
      <VerticalBorder />
      <StyledZipFieldInput {...postcode.input} placeholder="Zip" />
    </StateAndZipWrapper>
  );
};

const AddressSection = (props: any) => {
  const { address, hasInitialData } = props;
  const {
    addressLine1,
    addressLine2,
    suburb,
    state,
    postcode,
    country,
  } = address;
  return (
    <SectionWithRemovableField
      addItemButtonText="Add Address"
      hasInitialData={hasInitialData}
    >
      <RemovableFieldRowWrapper>
        <FieldRowLabel>Address</FieldRowLabel>
        <AddressFields>
          <AddressField {...addressLine1} placeholder="Address line 1" />
          <AddressField {...addressLine2} placeholder="Address line 2" />
          <AddressField {...suburb} placeholder="City" />
          <StateAndZipFields state={state} postcode={postcode} />
          <AddressField {...country} placeholder="Country" />
        </AddressFields>
      </RemovableFieldRowWrapper>
    </SectionWithRemovableField>
  );
};

export default AddressSection;
