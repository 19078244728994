import Analytics from '@aws-amplify/analytics';

import { config } from './config';

export async function startSession(userId?: string) {
  if (!config.enableAnalytics) {
    return;
  }

  if (userId) {
    await Analytics.updateEndpoint({ userId });
  }
  await Analytics.startSession();
}

export async function stopSession() {
  if (!config.enableAnalytics) {
    return;
  }

  await Analytics.updateEndpoint({ userId: undefined });
  await Analytics.stopSession();
}
