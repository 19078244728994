import React from 'react';
import styled from 'styled-components';

import Icon from 'src/components/Icon';

const StyledButtonIcon = styled(Icon)`
  margin-right: calc(var(--theme-spacing) * 2);
`;

interface IProps {
  style?: React.CSSProperties;
  className?: string;
  children?: any;
  iconColor?: string;
  iconName?: string;
  isWithText?: boolean;
}

const EnhancedButtonIcon = (props: IProps) => {
  const pickButtonIconProps = {
    name: props.iconName,
    color: props.iconColor,
  };

  return props.isWithText ? (
    <StyledButtonIcon {...pickButtonIconProps} />
  ) : (
    <Icon {...pickButtonIconProps} />
  );
};

EnhancedButtonIcon.defaultProps = {
  isWithText: false,
};

EnhancedButtonIcon.displayName = 'ButtonIcon';

export default EnhancedButtonIcon;
