import { compose, withState } from 'recompose';
import { connect } from 'react-redux';
import { submit, reset, isValid, formValueSelector } from 'redux-form';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import { every } from 'lodash';

import { formatNumberForStorage } from '../../../utils';
import { IDispatcher } from '../../../store';
import * as Actions from '../../../actions';
import { CONTACT_FORM_NAME } from './ContactForm/withReduxForm';
import AvatarEmptyIconBase64 from '../../../icons/avatar-empty-new-base64';
import { IRootState } from '../../../state';
import SudoSettingsSelectors from '../../../entities/sudo-settings/selectors';
import {
  EmailAddressType,
  IContact,
  IContactAddress,
  IContactAvatar,
  IContactEmailAddress,
  IPhoneNumber,
} from '../../../api';
import ContactSelectors from '../../../entities/contact/selectors';
import { IThunk } from '../../../actions';

export interface ILabelledValue {
  label: string;
  value: string;
}

export interface IStateFromProps {
  avatarImage: string;
  formIsValid: boolean;
}

export interface IDispatchFromProps {
  handleSaveClick: () => void;
  handleFormReset: () => void;
  handleSubmit: (
    sudoSlug: string,
    data: any,
    onComplete: (contactGuid: string) => void,
  ) => void;
  handleDeleteContact: (contactGuid: string) => void;
}

export interface IHandlerProps {
  redirectToContactGuid: string;
  setRedirectToContactGuid: (id: string) => void;
  avatarRef: any;
  setAvatarRef: (ref: any) => void;
}

export const contactDataToContact = (
  sudoId: string,
  data: any,
  phoneRegion: string,
): Partial<IContact> => {
  const streetAddresses: IContactAddress[] = [];
  const hasAddressData = !every(data.address, value => value === '');
  if (hasAddressData) {
    streetAddresses.push({
      type: 'Home',
      address: data.address,
    });
  }

  return {
    id: data.id || null,
    parent: {
      type: 'Sudo',
      id: sudoId,
    },
    first: data.first,
    last: data.last,
    phoneNumbers: (data.phones || [])
      .map((phone: ILabelledValue) => ({
        type: phone.label,
        number: formatNumberForStorage(phoneRegion, phone.value),
      }))
      .filter((phone: IPhoneNumber) => !!phone.number),
    emailAddresses: (data.emails || [])
      .map(
        (email: ILabelledValue): IContactEmailAddress => ({
          type: email.label as EmailAddressType,
          email: email.value,
        }),
      )
      .filter((email: IContactEmailAddress) => !!email.email),
    company: data.company,
    birthday: data.birthday ? moment(data.birthday).valueOf() : null,
    streetAddresses,
    notes: data.notes || '',
  } as Partial<IContact>;
};

export const contactDataToContactAvatar = (data: any): IContactAvatar => {
  return {
    avatar: data.avatar || null,
  } as IContactAvatar;
};

export const mapState = (state: IRootState): IStateFromProps => {
  const selector = formValueSelector(CONTACT_FORM_NAME);
  const first = selector(state, 'first');
  const last = selector(state, 'last');
  const company = selector(state, 'company');
  const hasAnyName = !!(
    (first && first.length) ||
    (last && last.length) ||
    (company && company.length)
  );
  return {
    avatarImage: selector(state, 'avatar') || AvatarEmptyIconBase64,
    formIsValid: isValid(CONTACT_FORM_NAME)(state) && hasAnyName,
  };
};

export const handleSubmit = (
  sudoSlug: string,
  data: any,
  onComplete: (contactGuid: string) => void,
): IThunk<void> => {
  return async (dispatch, getState, app) => {
    const state = getState();
    const sudoId = SudoSettingsSelectors.getIdBySlug(state, { slug: sudoSlug });
    const phoneRegion = SudoSettingsSelectors.getPhoneRegionById(state, {
      sudoGuid: sudoId,
    });

    let oldContactData = {
      contact: {},
      avatar: {},
    };
    if (data.id) {
      oldContactData = ContactSelectors.getEntityWithAvatarById(state, {
        id: data.id,
      });
    }

    // Preserve old contact data if it is omitted in ContactForm
    const contact = {
      ...oldContactData.contact,
      ...contactDataToContact(sudoId, data, phoneRegion),
    };
    const avatar = {
      ...oldContactData.avatar,
      ...contactDataToContactAvatar(data),
    };

    dispatch(Actions.saveContact(contact, avatar, onComplete));
  };
};

export const handleDeleteContact = (contactGuid: string): IThunk<void> => {
  return async (dispatch, getState, app) => {
    const state = getState();
    const contact = ContactSelectors.getEntityById(state, { id: contactGuid });
    if (contact) {
      await dispatch(
        Actions.deleteEntities([contact], { showDeleteModal: true }),
      );
    }
  };
};

export const mapDispatch = (dispatch: IDispatcher): IDispatchFromProps => ({
  handleSaveClick: () => {
    dispatch(submit(CONTACT_FORM_NAME));
  },
  handleFormReset: () => {
    dispatch(reset(CONTACT_FORM_NAME));
  },
  ...bindActionCreators({ handleSubmit, handleDeleteContact }, dispatch),
});

export default compose(
  withState('redirectToContactGuid', 'setRedirectToContactGuid', null),
  withState('avatarRef', 'setAvatarRef', null),
  connect(
    mapState,
    mapDispatch,
  ),
);
