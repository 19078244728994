import styled from 'styled-components';
import { gridContentParentHack as gridContentHeightParentHack } from 'src/utils/grid';

export const LayoutListToolbar = styled.div`
  grid-area: list-toolbar;
  border-bottom: 1px solid var(--theme-divider-color);
  border-right: 1px solid var(--theme-divider-color);
  display: flex;
  align-items: center;
  padding: 16px;
  color: var(--black);
  background-color: var(--gray-9);
`;

export const LayoutContentToolbar = styled.div`
  grid-area: content-toolbar;
  border-bottom: 1px solid var(--theme-divider-color);
  display: flex;
  justify-content: flex-end;
  & button {
    margin-right: calc(var(--theme-spacing) * 2);
  }
  color: var(--black);
`;

export const LayoutMergedToolbar = styled.div`
  grid-area: merged-toolbar;
  border-bottom: 1px solid var(--theme-divider-color);
  align-items: center;
  color: var(--black);
  background-color: var(--gray-9);
`;

export const Layout = styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
  background: var(--white);
  display: grid;
  grid-template-columns: 225px 375px 1fr;
  grid-template-rows: 56px 1fr;
  grid-template-areas:
    'folders list-toolbar content-toolbar'
    'folders list content';

  & ${LayoutMergedToolbar} {
    display: none;
  }
  &.isToolbarMerged {
    & ${LayoutListToolbar}, & ${LayoutContentToolbar} {
      display: none;
    }
    & ${LayoutMergedToolbar} {
      display: flex;
    }
    grid-template-areas:
      'folders merged-toolbar merged-toolbar'
      'folders list content';
  }
`;

export const LayoutFolders = styled.div`
  ${gridContentHeightParentHack};
  background-color: var(--gray-8);
  grid-area: folders;
  border-right: solid 1px var(--theme-divider-color);
`;

export const LayoutList = styled.div`
  ${gridContentHeightParentHack};
  grid-area: list;
  border-right: solid 1px var(--theme-divider-color);
  background-color: var(--gray-9);
`;

export const LayoutContent = styled.div`
  ${gridContentHeightParentHack};
  grid-area: content;
  display: flex;
  max-height: 100%;
  min-height: 0;
  max-width: 100%;
  min-width: 0;
`;
