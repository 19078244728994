import * as React from 'react';
import styled from 'styled-components';
import { Avatar } from 'src/components';

interface IProps {
  children: React.ReactNode;
  overlayToolbar?: React.ReactNode;
}

const HeaderWrapper = styled.div`
  height: 440px;
  background-color: var(--white);
  position: relative;
  overflow: visible;
  box-shadow: 0 1px 3px -1px var(--gray-5);
`;

const HeaderContent = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  color: var(--black);
  text-align: center;
`;

const OverlayToolbarWrapper = styled.div`
  position: absolute;
  top: calc(var(--theme-spacing) * 3);
  left: calc(var(--theme-spacing) * 3);
  right: calc(var(--theme-spacing) * 3);
  color: #fff;
`;

export const HeaderAvatar = styled(Avatar)`
  width: 158px;
  height: 158px;
  margin: calc(var(--theme-spacing) * 4) auto 0 auto;
  border: 4px solid #fff;
  box-shadow: 0 4px 8px 0 rgba(16, 16, 16, 0.08);
`;

const DetailsHeader = (props: IProps) => (
  <HeaderWrapper>
    <HeaderContent>{props.children}</HeaderContent>
    <OverlayToolbarWrapper>{props.overlayToolbar}</OverlayToolbarWrapper>
  </HeaderWrapper>
);

export default DetailsHeader;
