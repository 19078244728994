import { delay } from './delay';

/**
 * Continues to try a callback every `timeoutMs` until it completes successfully.
 * Returns result of first callback that resolves in time.
 * If previous attempt(s) resolve after retry, then those results are ignored.
 * Optional cleanUp function can be provided to perform processing after each attempt.
 */
export async function retryOnTimeout<T>(
  timeoutMs: number,
  callback: () => Promise<T>,
  cleanUp?: () => void,
): Promise<T> {
  const notComplete = Symbol();

  let callbackResult: T | symbol = notComplete;
  while (callbackResult === notComplete) {
    callbackResult = await Promise.race([
      delay(timeoutMs).then(() => notComplete),
      callback(),
    ]);

    if (cleanUp) {
      cleanUp();
    }
  }

  return callbackResult as T;
}
