import * as React from 'react';
import { Button } from 'src/components';
import styled from 'styled-components';

interface IProps {
  children: React.ReactNode;
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.div`
  max-width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 24px 16px 32px 16px;

  color: var(--gray-1);
  font-size: 16px;
  line-height: 1.5em;
  margin-bottom: 32px;

  p {
    margin: 10px 0;
  }
`;

export const Title = styled.h3`
  font-size: 24px;
  font-weight: bold;
  line-height: 22px;
  letter-spacing: -0.2px;
  color: var(--black);
  margin-bottom: 16px;
`;

export const SubHead = styled.h4`
  font-size: 20px;
  font-weight: bold;
  color: var(--black);
`;

export const List = styled.ul`
  margin: 10px 5px 20px 5px;
`;

export const ListItem = styled.li`
  list-style-type: disc;
  margin: 0 4px 0 20px;
`;

export const ToolbarButton = styled(Button)``;

export const Toolbar = styled.div`
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  margin-top: 20px;

  > ${ToolbarButton} {
    margin-left: 20px;
  }

  > ${ToolbarButton}:first-child {
    margin-left: 0;
  }
`;

export default (props: IProps) => (
  <Wrapper>
    <Content>{props.children}</Content>
  </Wrapper>
);
