import React from 'react';

export default function Gif(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <g fill="currentColor" fillRule="evenodd" transform="translate(0 2)">
        <path d="M5 0h14a5 5 0 0 1 5 5v10a5 5 0 0 1-5 5H5a5 5 0 0 1-5-5V5a5 5 0 0 1 5-5zm0 1.5A3.5 3.5 0 0 0 1.5 5v10A3.5 3.5 0 0 0 5 18.5h14a3.5 3.5 0 0 0 3.5-3.5V5A3.5 3.5 0 0 0 19 1.5H5zm5.69 9.199v-.97H7.648v1.197h1.572l-.005.137c-.03.917-.718 1.534-1.713 1.534-1.195 0-1.949-.991-1.949-2.62 0-1.599.718-2.574 1.889-2.574.844 0 1.452.448 1.678 1.218h1.507C10.415 7.039 9.154 6 7.44 6 5.33 6 4 7.54 4 9.992 4 12.482 5.321 14 7.476 14c1.98 0 3.215-1.266 3.215-3.301zm2.887 3.106v-7.61H12.06v7.61h1.517zm3.193 0V10.84h2.949V9.54H16.77V7.556H20v-1.36h-4.747v7.609h1.517z" />
      </g>
    </svg>
  );
}
