import { connect } from 'react-redux';

import SudoSettingsSelectors from '../../../entities/sudo-settings/selectors';
import { getSelfInitials } from '../../../utils';
import { IRootState } from '../../../state';
import SudoCountsSelectors from '../../../entities/sudo-counts/selectors';
import { DEFAULT_SUDO_COLOR } from '../../../api';
import SudoAvatarEmpty from '../../../icons/sudo-avatar-empty';

export interface ICounts {
  messages: number;
  emails: number;
}

export interface IStateFromProps {
  counts: ICounts;
  sudoLabel: string;
  sudoAvatarColor: string;
  sudoAvatarText: string;
  sudoAvatarImage: string;
}

export interface IStateProps {
  sudoSlug: string;
}

export const mapState = <P extends IStateProps>(
  state: IRootState,
  props: P,
): IStateFromProps => {
  const sudoGuid = SudoSettingsSelectors.getIdBySlug(state, {
    slug: props.sudoSlug,
  });
  const { sudo, avatar } = SudoSettingsSelectors.getEntityWithAvatarBySudoId(
    state,
    { sudoGuid },
  );
  const counts = SudoCountsSelectors.getEntityById(state, { id: sudoGuid });

  return {
    counts: {
      messages: (counts && counts.messagesUnread) || 0,
      emails: (counts && counts.emailInboxUnread) || 0,
    },
    sudoLabel: sudo.role,
    sudoAvatarColor: DEFAULT_SUDO_COLOR,
    sudoAvatarText: getSelfInitials(sudo),
    sudoAvatarImage: avatar ? avatar.avatarImage : SudoAvatarEmpty,
  };
};

export default connect(mapState);
