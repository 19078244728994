import { Observable, Subscriber } from 'rxjs';
import {
  ApiError,
  ApiStatus,
  IApi,
  IBindingInfo,
  ISubscription,
  EventMap,
} from './api-interfaces';
import { BaseApi } from './base-api';
import { BaseApiError, IBaseApi } from './base-api-interfaces';
import SawCrypto from './crypto';
import { EntityChange, IEntity, IQueryParams } from './entities';
import * as Msg from './message-interfaces';
import { uuid } from './uuid';
import { createRequestProcessor, IRequestProcessor } from './request-processor';
import { ISearchPagination } from './message-interfaces';
import { IRequestMeta } from './message-interfaces';
import { WakeupHandler } from './wakeup-handler';
import { TypedEvents } from './typed-events';

// request message protocol version
const kRequestVersion = 2;

// connection establishment endpoint
const kPairingEndpointPathV2 = '/session/v2/slave';
const kBindingEndpointPathV2 = '/session/v2/bind';
const kPairingEndpointPathV3 = '/session/v3/slave';
const kBindingEndpointPathV3 = '/session/v3/bind';

type Capabilities = Msg.IBindNotification['peerDescription']['capabilities'];

/**
 * Default configuration options
 */
const kDefaultOpts = {
  // client information passed to device during registration
  apiClientDescription: {
    name: 'Sudo API Client',
    version: '0.0.0',
    environment: [
      {
        type: 'browser',
        name: 'unknown',
        version: 'unknown',
      },
    ],
  },

  // function used to generate a shared secret for pairing
  pairingSecretGenerator: uuid,

  // max number of attempts when negotiating a pairing secret
  tokenNegotiationAttempts: 5,

  // connection timeout when binding
  bindTimeout: 10000,

  // function for creating the lower level api component
  // if null, then a default instace of BaseApi will be created
  baseApi: null as IBaseApi,

  // if v3 is supported, RtcApi will be used instead of BaseApi
  isV3Supported: false,

  // maximum keepawake attempts (reset with each new operation)
  maxKeepAwake: 20,

  // default capabilities for backwards compatibility
  defaultCapabilities: {
    keepAwakeInterval: 15,
  } as Capabilities,
};

export type IApiOptions = typeof kDefaultOpts;

/**
 * Pairing information shared between client and app during pairing process.
 */
interface IPairingInfo {
  pairingToken: string;
  connectionUrl: string;
  symmetricKey: string;
}

/**
 * Active session state
 */
interface ISession {
  bindNotification: Msg.IBindNotification;
  wakeupHandler: WakeupHandler;
}

/**
 * High level API operations
 */
export class Api extends TypedEvents<EventMap> implements IApi {
  // --------------------------------------------------------------------------
  // Properties

  public get status(): ApiStatus {
    return this._status;
  }
  private setStatus(status: ApiStatus) {
    this._status = status;
    this.emit('status', { status });
  }
  private _status: ApiStatus = 'disconnected';

  private status$ = Observable.fromEvent<EventMap['status']>(
    this,
    'status',
  ).map(event => event.status);

  /** Entities received via NOTIFY */
  public get entities$() {
    return this._entities$;
  }
  private readonly _entities$: Observable<EntityChange>;

  /** Configuration options */
  public get opts() {
    return this._opts;
  }
  private readonly _opts: IApiOptions;

  /** Base api instance */
  public get baseApi() {
    return this._baseApi;
  }
  private readonly _baseApi: IBaseApi;

  /** Last binding url, including binding token, used for reconnect */
  private lastBindUrl: string;

  /** Adapter for massaging out quirks in API data formats */
  private requestProcessor: IRequestProcessor;

  /**
   * App-level message sequence ID.
   * This counter is used by the app to identify duplicate REQUEST messages.
   */
  private nextSeqId = 0;

  /**
   * State for active session
   */
  private session: ISession | null = null;

  /**
   * Creates an api instance.
   * @param {Partial<IApiOptions>} opts - Overrides for default options.
   */
  constructor(opts: Partial<IApiOptions> = {}) {
    super();

    this._opts = Object.assign({}, kDefaultOpts, opts);

    // configure baseApi instance
    this._baseApi = this.opts.baseApi || new BaseApi();

    this.requestProcessor = createRequestProcessor();

    // handle baseApi status changes
    this.baseApi.status$.subscribe(status => {
      if (status === 'disconnected') {
        this.setStatus('disconnected');
        this.session = null;
      } else if (status === 'error') {
        if (this.status === 'connected') {
          this.setStatus('connection-error');
          this.session = null;
        }
      }
    });

    // handle logout confirmation from server
    this.baseApi.inbound$
      .filter(msg => msg.type === 'sessionClosed')
      .subscribe(() => {
        this.setStatus('closing-unpaired');
      });

    // handle 552 status
    this.baseApi.inbound$
      .filter(
        msg =>
          msg.type === 'status' && (msg as Msg.IStatusMessage).status === 552,
      )
      .subscribe(() => {
        this.setStatus('closing-session-taken');
      });

    // entity notifications
    this.baseApi.inbound$
      .filter(res => res.type === 'message')
      .filter((res: Msg.IPayloadMessage) => res.message.type === 'NOTIFY')
      .map((res: Msg.IEntityNotification) =>
        this.requestProcessor.postSubscribeEntities(res.message.entities),
      )
      .subscribe(changes => this.emit('entityChanges', { changes }));

    // Emit subscription invalidations
    this._baseApi.inbound$
      .filter(msg => msg.type === 'message')
      .filter((msg: Msg.IPayloadMessage) => msg.message.type === 'STATUS')
      .map((msg: Msg.IAppStatusMessage) => msg.message.resubscribeIds)
      .filter(resubscribeIds => !!resubscribeIds)
      .subscribe(resubscribeIds =>
        this.emit('subscriptionsExpired', { resubscribeIds }),
      );

    // Emit refresh tokens
    this._baseApi.inbound$
      .filter(msg => msg.type === 'refreshNotification')
      .do(() => this.baseApi.wakeUpMaster().toPromise())
      .map((msg: Msg.IRefreshNotification) => msg.token)
      .subscribe(newToken => this.emit('refreshToken', { newToken }));

    // Handle APP_STATUS messages
    this.baseApi.inbound$
      .filter(
        msg =>
          msg.type === 'message' &&
          (msg as Msg.IPayloadMessage).message.type === 'STATUS',
      )
      .subscribe((msg: Msg.IAppStatusMessage) => {
        if (typeof msg.message.isAwake !== 'undefined') {
          const { wakeupHandler } = this.getSession();
          wakeupHandler.updateStatus(msg.message.isAwake);
        }

        // TODO: https://anonyome.atlassian.net/browse/MSW-934
        // if (msg.message.stopKeepAlive) {
        //   this.wakeupHandler.endAwakeTasks('waitForSync');
        // }
      });
  }

  /**
   * Initiates the pairing process against the /slave endpoint.
   * PairingTokens will be emitted on this.pairingToken$
   * After successful pairing, pair() will emit the binding token and then complete.
   */
  public pair(
    pairingHost: string,
    appName: string,
    v3: boolean = false,
  ): Promise<IBindingInfo> {
    const pairingEndpoint = v3
      ? pairingHost + kPairingEndpointPathV3
      : pairingHost + kPairingEndpointPathV2;

    return Observable.create((observer: Subscriber<IBindingInfo>) => {
      this.setStatus('pairing');

      // Start by connecting to pairing service
      this.baseApi
        .connect(pairingEndpoint)

        // Merge in any connection errors to this stream
        .merge(
          this.baseApi.errors$
            .filter(err => err !== null)
            .delay(0) // Allow status observable to synchronize
            .map(err => {
              throw err;
            }),
        )

        // Attempt to negotiate a pairing token
        .flatMap(() =>
          this.negotiatePairingToken(
            appName,
            this.opts.tokenNegotiationAttempts,
          ),
        )

        // Emit qr code
        .do(pairingInfo => {
          const qrCode = this.formatQrCodeV1(
            appName,
            pairingInfo.pairingToken,
            pairingInfo.symmetricKey,
            pairingInfo.connectionUrl,
          );
          this.emit('qrCode', { qrCode });
        })

        // Receive status response and emit bindingInfo once device has scanned QR code
        .flatMap(pairingInfo =>
          this.baseApi.inbound$
            // Ignore responses to polling messages
            .filter(
              msg =>
                msg.type !== 'status' ||
                (msg as Msg.IStatusMessage).status !== 422,
            )
            .take(1)
            .filter(msg => msg.type === 'bindingNotification')
            .map((res: Msg.IBindNotification) => {
              const connectionUrlBase = pairingInfo.connectionUrl.replace(
                /\/$/,
                '',
              ); // trim trailing `/`
              return {
                version: '1',
                bindingToken: res.token,
                connectionUrl:
                  connectionUrlBase +
                  (this.opts.isV3Supported
                    ? kBindingEndpointPathV3
                    : kBindingEndpointPathV2),
                symmetricKey: pairingInfo.symmetricKey,
                iceConfig: res.iceConfig,
              } as IBindingInfo;
            }),
        )

        // Retry the above sequence on CONNECTION_LOST, otherwise rethrow any errors
        .retryWhen(errors$ =>
          errors$.map(err => {
            const shouldRetry =
              err instanceof BaseApiError && err.message === 'CONNECTION_LOST';
            if (!shouldRetry) {
              throw err;
            }
          }),
        )

        // Keep going until connection is gracefully disconnected
        .takeUntil(
          this.baseApi.status$
            .skip(1) // initial disconnected state
            .filter(status => status === 'disconnected'),
        )

        // Disconnect once we're done. (Do this after the "take" operation.)
        .flatMap(bindingInfo => this.baseApi.disconnect().mapTo(bindingInfo))

        // Clear QR code when done
        .finally(() => {
          this.emit('qrCode', { qrCode: null });
        })

        // Update observer
        .subscribe(
          bindingInfo => {
            observer.next(bindingInfo);
          },
          err => {
            this.setStatus('pairing-error');

            if (err instanceof ApiError) {
              observer.error(err);
            } else {
              observer.error(new ApiError('SERVICE_ERROR', null, err));
            }
          },
          () => {
            // This sequence can complete by pairing successfully or by being gracefully disconnected

            // Give binding token a chance to be processed by subscriber
            // before stopping the stream.
            setTimeout(() => {
              observer.complete();
            });
          },
        );
    }).toPromise();
  }

  /**
   * Connects to the main service backend (/bind endpoint).
   */
  public connect(bindingInfo?: IBindingInfo): Promise<void> {
    this.lastBindUrl = bindingInfo
      ? bindingInfo.connectionUrl + '?bearer=' + bindingInfo.bindingToken
      : this.lastBindUrl;

    this.session = null;

    return Observable.create((observer: Subscriber<void>) => {
      if (!this.lastBindUrl) {
        return observer.error(
          new ApiError('INVALID_BINDING_TOKEN', 'No binding info available'),
        );
      }

      this.setStatus('binding');

      return (
        // Start by connecting to bind endpoint
        this.baseApi
          .connectData(this.lastBindUrl, bindingInfo.symmetricKey)

          // Configure session
          .do(bindNotification => {
            const capabilities = {
              ...this.opts.defaultCapabilities,
              ...bindNotification.peerDescription.capabilities,
            };

            this.session = {
              bindNotification,
              wakeupHandler: new WakeupHandler({
                keepAwakeMs: capabilities.keepAwakeInterval * 1000,
                maxKeepAwakes: this.opts.maxKeepAwake,
                simulator: !!bindNotification.peerDescription.environment.find(
                  env => env.name.includes('##simulator'),
                ),
                wakeUp: () => this.baseApi.wakeUpMaster().toPromise(),
              }),
            };
          })

          // Reset request sequence so that we are in sync with session
          .flatMap(() => this.resetRequestSeq())

          // Update api status
          .do(() => {
            this.setStatus('connected');
          })

          // Abort the sequence if the status becomes disconnected
          .takeUntil(this.status$.filter(status => status === 'disconnected'))

          // Timeout
          .timeoutWith(
            this.opts.bindTimeout,
            Observable.throw(new ApiError('BIND_TIMEOUT')),
          )

          // Handle errors
          .catch(
            (e): Observable<void> => {
              this.setStatus('binding-error');

              if (e instanceof ApiError) {
                throw e;
              } else if (e instanceof BaseApiError) {
                if (e.message === 'INVALID_BINDING_TOKEN') {
                  throw new ApiError('INVALID_BINDING_TOKEN', null, e);
                } else if (e.message === 'CONNECTION_FAILED') {
                  throw new ApiError('BIND_CONNECT_ERROR', null, e);
                }
              }

              throw new ApiError('SERVICE_ERROR', null, e);
            },
          )

          // Update observer
          .subscribe(observer)
      );
    }).toPromise();
  }

  /** IApi.resumeConnection() */
  public async resumeConnection() {
    throw new Error('Not implemented');
  }

  /**
   * Disconnects from the websocket.
   */
  public disconnect() {
    return Observable.defer(() => {
      this.lastBindUrl = null;

      return this.baseApi.disconnect().catch(
        (e: BaseApiError): Observable<void> => {
          throw new ApiError('SERVICE_ERROR', null, e);
        },
      );
    }).toPromise();
  }

  /**
   * Sends a request to logout from session service. This will
   * result in a sessionClosed message being returned and then
   * the peer will close the connection. We'll also disconnect on our side.
   */
  public logout(): Promise<void> {
    return Observable.defer(() => {
      let ensureConnected$: Observable<void>;
      if (this.status === 'closing-session-taken' && this.lastBindUrl) {
        ensureConnected$ = this.baseApi.connect(this.lastBindUrl);
      } else if (this.status === 'connected') {
        ensureConnected$ = Observable.of(undefined);
      } else {
        return Observable.throw(
          new ApiError(
            'CANNOT_LOGOUT',
            'Cannot establish connection to master',
          ),
        );
      }

      const logoutMsg = {
        type: 'logout',
      } as Msg.ILogoutRequest;

      // logout message receipt is confirmed with a normal status message
      this.setStatus('unpairing');
      return ensureConnected$
        .flatMap(() => this.baseApi.send(logoutMsg))
        .flatMap(() => {
          this.setStatus('closing-unpaired');
          return this.disconnect();
        })
        .mapTo(undefined);
    }).toPromise();
  }

  /**
   * Returns a message ID
   */
  public generateMessageId() {
    return this.baseApi.generateMessageId();
  }

  /**
   * Searches for entities.
   */
  public async search(
    queryParams: IQueryParams,
    pagination?: ISearchPagination,
    meta?: IRequestMeta,
    since?: number,
    until?: number,
    responseTimeout?: number,
    messageId?: string,
  ): Promise<IEntity[]> {
    const msg: Msg.ISearchRequest = {
      type: 'message',
      messageId,
      message: {
        version: kRequestVersion,
        type: 'REQUEST',
        operation: 'SEARCH',
        requestSeq: this.nextSeqId++,
        ...(meta ? { meta } : null),
        ...(since ? { since } : null),
        ...(until ? { until } : null),
        ...(pagination ? { pagination } : null),
        entities: this.requestProcessor.preSearchParams(queryParams),
      },
    };

    const res = (await this.sendAppMessage(
      msg,
      responseTimeout,
    )) as Msg.ISearchResponse;
    return this.requestProcessor.postSearchEntities(res.message.entities);
  }

  /**
   * Subscribes to entity notifications
   */
  public async subscribe(
    queryParams: IQueryParams,
    meta?: IRequestMeta,
    since?: number,
  ): Promise<ISubscription> {
    const msg: Msg.ISubscribeRequest = {
      type: 'message',
      message: {
        version: kRequestVersion,
        type: 'REQUEST',
        operation: 'SUBSCRIBE',
        requestSeq: this.nextSeqId++,
        entities: this.requestProcessor.preSubscribeParams(queryParams),
        ...(since ? { since } : null),
      },
    };

    const response = await this.sendAppMessage(msg);
    return {
      requestId: response.inResponseTo,
      entityTypes: queryParams,
    };
  }

  /**
   * Unsubscribes from entity notifications
   */
  public async unsubscribe(subscription: ISubscription): Promise<void> {
    const msg: Msg.IUnsubscribeRequest = {
      type: 'message',
      messageId: subscription.requestId,
      message: {
        version: kRequestVersion,
        type: 'REQUEST',
        operation: 'UNSUBSCRIBE',
        requestSeq: this.nextSeqId++,
        entities: this.requestProcessor.preSubscribeParams(
          subscription.entityTypes,
        ),
      },
    };

    await this.sendAppMessage(msg);
  }

  /**
   * Send ADD request to device and emit newly added entities.
   */
  public async add(
    entities: Array<Partial<IEntity>>,
    meta?: IRequestMeta,
    responseTimeout?: number,
  ): Promise<IEntity[]> {
    const msg: Msg.IAddRequest = {
      type: 'message',
      message: {
        version: kRequestVersion,
        type: 'REQUEST',
        operation: 'INSERT',
        requestSeq: this.nextSeqId++,
        ...(meta ? { meta } : null),
        entities: this.requestProcessor.preAddEntities(entities),
      },
    };

    const res = (await this.sendAppMessage(
      msg,
      responseTimeout,
    )) as Msg.IAddResponse;
    return this.requestProcessor.postAddEntities(res.message.entities, meta);
  }

  /**
   * Send UPDATE request to device and emit updated entities data.
   */
  public async update(
    entities: Array<Partial<IEntity>>,
    meta?: IRequestMeta,
    responseTimeout?: number,
  ): Promise<IEntity[]> {
    const msg: Msg.IUpdateRequest = {
      type: 'message',
      message: {
        version: kRequestVersion,
        type: 'REQUEST',
        operation: 'UPDATE',
        requestSeq: this.nextSeqId++,
        ...(meta ? { meta } : null),
        entities: this.requestProcessor.preUpdateEntities(entities),
      },
    };

    const res = (await this.sendAppMessage(
      msg,
      responseTimeout,
    )) as Msg.IUpdateResponse;
    return this.requestProcessor.postUpdateEntities(res.message.entities, meta);
  }

  /**
   * Send DELETE request to device and emit if successful.
   */
  public async delete(
    queryParams: IQueryParams,
    meta?: IRequestMeta,
  ): Promise<void> {
    const msg: Msg.IDeleteRequest = {
      type: 'message',
      message: {
        version: kRequestVersion,
        type: 'REQUEST',
        operation: 'DELETE',
        requestSeq: this.nextSeqId++,
        ...(meta ? { meta } : null),
        entities: this.requestProcessor.preDeleteParams(queryParams),
      },
    };

    await this.sendAppMessage(msg);
  }

  /**
   * Cancels a request
   */
  public async cancel(requestId: string): Promise<void> {
    const msg: Msg.ICancelRequest = {
      messageId: this.generateMessageId(),
      type: 'message',
      message: {
        version: 2,
        type: 'REQUEST',
        operation: 'CANCEL',
        cancelMessageId: requestId,
      },
    };
    await this.sendAppMessage(msg);
  }

  /**
   * Sends an app request
   */
  public sendAppMessage(msg: Msg.IRequestMessage, responseTimeout?: number) {
    const { wakeupHandler } = this.getSession();

    if (!msg.messageId) {
      msg.messageId = this.baseApi.generateMessageId();
    }

    const cleanUpTask = () => {
      // TODO: https://anonyome.atlassian.net/browse/MSW-934
      // this.wakeupHandler.startAwakeTask('waitForSync');
      wakeupHandler.endAwakeTasks(msg.messageId);
    };

    // Sequence that will throw if a cancellation is detected for this request
    const throwOnCancel$ = this.baseApi.inbound$
      .filter(
        m =>
          m.type === 'message' &&
          (m as Msg.IPayloadMessage).message.type === 'RESPONSE' &&
          (m as Msg.IResponseMessage).message.operation === 'CANCEL' &&
          (m as Msg.ICancelResponse).message.cancelMessageId === msg.messageId,
      )
      .take(1)
      .flatMapTo(Observable.throw(new ApiError('REQUEST_CANCELLED')));

    // Full Sequence
    return Observable.fromPromise(wakeupHandler.beginAwakeTask(msg.messageId))
      .merge(throwOnCancel$)
      .flatMapTo(this.baseApi.sendData(msg, responseTimeout))
      .do(res => {
        cleanUpTask();

        if (res.message.status !== 'FAIL') {
          return;
        }

        const errorData = { message: msg, response: res };
        const { failDesc } = res.message;
        if (
          failDesc ===
          'generalError(causedBy: SudoSyncKit.OutgoingChangeError.paymentRequired)'
        ) {
          throw new ApiError('PAYMENT_REQUIRED', errorData);
        }

        throw new ApiError('BAD_REQUEST', errorData);
      })
      .take(1)
      .catch(
        (e: any): Observable<Msg.IMessage> => {
          cleanUpTask();

          if (e instanceof ApiError) {
            throw e;
          } else {
            throw new ApiError('SEND_FAILED', { status: this.status, msg }, e);
          }
        },
      )
      .toPromise();
  }

  /**
   * Returns active session or throws error
   */
  private getSession() {
    if (!this.session) {
      throw new ApiError('NO_SESSION');
    }

    return this.session;
  }

  /**
   * Resets the app message sequence counter for this session (local and remote).
   */
  private async resetRequestSeq() {
    const msg: Msg.IResetSeqOperation = {
      type: 'message',
      message: {
        version: kRequestVersion,
        requestSeq: 0,
        type: 'REQUEST',
        operation: 'RESET_SEQ',
      },
    };

    await this.sendAppMessage(msg);
    this.nextSeqId = 1;
  }

  /**
   * Registers a pairing token for use with the API.
   * @param {number} retries - Number of attempts
   */
  private negotiatePairingToken(
    appName: string,
    retries: number,
  ): Observable<IPairingInfo> {
    const secret = this.opts.pairingSecretGenerator();

    // Note: It's required to supply the pairing secret as a F1-formatted qrCode
    // even though it's not currently described this way in the API doc. (1/17/16)
    const msg: Msg.IRegisterRequest = {
      token: this.formatQrCodeV1(appName, secret, null),
      type: 'register',
      description: this.opts.apiClientDescription,
    } as Msg.IRegisterRequest;

    // Send Message
    return this.baseApi
      .send(msg)
      .flatMap((response: Msg.IRegisterResponse) =>
        Observable.fromPromise(SawCrypto.generateKey())
          .flatMap(key => SawCrypto.exportKey(key))
          .map(exportedKey => ({ response, exportedKey })),
      )
      .map(
        ({ response, exportedKey }) =>
          ({
            pairingToken: secret,
            connectionUrl: response.connectionUrl,
            symmetricKey: exportedKey,
          } as IPairingInfo),
      )
      .catch((e: BaseApiError) => {
        if (e.info && e.info.status === 409) {
          if (retries === 1) {
            throw new ApiError('NO_PAIRING_TOKEN', null, e);
          } else {
            return this.negotiatePairingToken(appName, retries--);
          }
        } else {
          throw new ApiError('SERVICE_ERROR', null, e);
        }
      });
  }

  /**
   * Combines a pairing secret along with metadata, used when negotiating the pairing secret
   * with the slave endpoint, and for the QR code used when performing pairing with the device.
   * Returns string in format: "Sudo:1:<AppName>:2:<PairingSecret>(:<symmetricKey>)(:<ConnectionUrl>)"
   */
  private formatQrCodeV1(
    appName: string,
    pairingSecret: string,
    symmetricKey?: string,
    connectionUrl?: string,
  ): string {
    const MAGIC_STRING = 'Sudo'; // constant

    // QR code Format Version
    // https://wiki.tools.anonyome.com/index.php/SudoCard_-_Desktop#Format_versions_overview
    const FORMAT_VERSION = 1;

    // Supported connection establishment flow versions
    // https://wiki.tools.anonyome.com/index.php/SudoCard_-_Desktop#Session_Initiation_Protocol_Version_2
    const SUPPORTED_PROTOCOL_VERSIONS = [this.opts.isV3Supported ? 3 : 2];

    const parts = [
      MAGIC_STRING,
      FORMAT_VERSION,
      appName,
      SUPPORTED_PROTOCOL_VERSIONS.join(','),
      pairingSecret,
    ];

    if (symmetricKey) {
      parts.push(symmetricKey);
    }

    if (connectionUrl) {
      parts.push(connectionUrl);
    }

    return parts.join(':');
  }
}
