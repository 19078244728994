import { fromPairs } from 'lodash';
import { EntityType, IQueryParams } from 'src/api';
import { IThunk } from './actions-base';
import { processEntities } from './process-entity-action';
import {
  readConnectionInfoOrThrow,
  writeConnectionInfo,
} from 'src/connection-info';

const subscribedTypes: EntityType[] = [
  'Sudo',
  'SudoSettings',
  'SudoAvatarImage',
  'Counts',
  'EmailAccount',
  'Telephone',
  'Contact',
  'ContactAvatar',
  'MessagingThread',
  'Message',
  'EmailMessage',
];

export const subscriptionQuery: IQueryParams = fromPairs(
  subscribedTypes.map(key => [key, null]),
);

export const searchMissingQuery = (last: number) => ({
  EmailMessage: {
    type: 'simple',
    key: 'modified',
    mode: 'greaterThan',
    value: last,
  },
  Message: {
    type: 'simple',
    key: 'modified',
    mode: 'greaterThan',
    value: last,
  },
  MessagingThread: {
    type: 'simple',
    key: 'lastReceived',
    mode: 'greaterThan',
    value: last,
  },
  Counts: {},
});

export function subscribe(params: IQueryParams = subscriptionQuery): IThunk {
  return async (_dispatch, _getState, { api }) => {
    const connectionInfo = readConnectionInfoOrThrow();
    const newSubscription = await api.subscribe(params);
    writeConnectionInfo({
      ...connectionInfo,
      subscriptions: [...connectionInfo.subscriptions, newSubscription],
    });
  };
}

export function unsubscribe(id: string): IThunk {
  return async (_dispatch, _getState, { api }) => {
    const connectionInfo = readConnectionInfoOrThrow();

    const sub = connectionInfo.subscriptions.find(s => s.requestId === id);
    if (!sub) {
      // tslint:disable-next-line:no-console
      console.error('Cannot unsubscribe ' + id);
      return;
    }

    writeConnectionInfo({
      ...connectionInfo,
      subscriptions: connectionInfo.subscriptions.filter(
        s => s.requestId !== id,
      ),
    });

    try {
      await api.unsubscribe(sub);
    } catch (err) {
      // tslint:disable-next-line:no-console
      console.error('Error unsubscribing ' + id, err);
    }
  };
}

export function unsubscribeAll(): IThunk {
  return async dispatch => {
    const connectionInfo = readConnectionInfoOrThrow();

    connectionInfo.subscriptions.forEach(sub => {
      dispatch(unsubscribe(sub.requestId));
    });

    writeConnectionInfo({
      ...connectionInfo,
      subscriptions: [],
    });
  };
}

export function resubscribe(ids: string[]): IThunk {
  return async (dispatch, getState, { api }) => {
    const connectionInfo = readConnectionInfoOrThrow();

    // Clean out invalid subscription ids
    writeConnectionInfo({
      ...connectionInfo,
      subscriptions: connectionInfo.subscriptions.filter(
        sub => !ids.includes(sub.requestId),
      ),
    });

    // Only resubscribe if we have an active connection.
    if (api.status !== 'connected') {
      return;
    }
    await dispatch(subscribe());

    // Fetch any missed email or message entities.
    const last = getState().app.lastEntityModified;
    if (last) {
      const messageSyncParams = searchMissingQuery(last);
      const entities = await api.search(messageSyncParams);
      dispatch(processEntities(entities));
    }
  };
}
