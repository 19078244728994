export enum ReadyState {
  Closed,
  Closing,
  Connecting,
  Open,
}

export interface ReadyStateEvent {
  readyState: ReadyState;
}
