import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { differenceWith, debounce, memoize } from 'lodash';

import {
  IMessageComposition,
  IRootState,
  MessageComposition,
} from '../../../state';
import { IDispatcher } from '../../../store';
import * as Actions from '../../../actions';
import { ChildrenParams } from '../../../features/List/utils/ScrollLoadMore';
import { getMessageList, listsStateSelector } from '../../../selectors';
import {
  getMessageRowsAndConversation,
  getUnreadMessagesByConversationId,
  MessageDataRowType,
} from '../selectors';
import SudoSettingsSelectors from '../../../entities/sudo-settings/selectors';
import MessageSelectors from '../../../entities/message/selectors';
import {
  ISendingDispatchFromProps,
  mapDispatchSending,
} from '../ConversationCreation/withConversationCreationData';
import { IThunk } from '../../../actions';
import { IMessage, IMessageConversation } from '../../../api';
import MessageConversationSelectors from '../../../entities/message-conversation/selectors';

const MARK_CONVERSATION_TIMEOUT = 3000;

export interface IStateProps extends ChildrenParams {
  sudoSlug: string;
  conversationGuid: string;
}

export interface IStateFromProps {
  sudoGuid: string;
  composition: IMessageComposition;
  isCanWrite: boolean;
  redirectToAll: boolean;
  noMoreMessages: boolean;
  isFetching: boolean;
  isWindowFocused: boolean;
  participants: string;
  participantsAvatarImage: string | string[];
  participantsAvatarText: string;
  participantsAvatarLocked: boolean;
  oldestMessage: number;
  messageData: IMessageDataRow[];
  isGroupConversation: boolean;
  activeMembersWithAvatars: any[];
  phoneAccountGuid: string;
  viewAttachmentModal: any;
  title: string | null;
}

export interface IMessageDataRow {
  type: MessageDataRowType;
  id: string;
  data: any;
}

export interface IDispatchFromProps extends ISendingDispatchFromProps {
  openConversation: (
    phoneAccountGuid: string,
    conversationGuid: string,
  ) => void;
  closeConversation: (phoneAccountGuid: string) => void;
  loadMoreMessages: (conversationGuid: string, until?: number) => void;
  markMessagesAsRead: (conversationGuid: string) => void;
  openAttachment: (id: string, messageGuid: string) => void;
  closeAttachment: () => void;
  editMessage: (id: string) => void;
  deleteMessage: (id: string) => void;
}

export const mapState = (
  state: IRootState,
  props: IStateProps,
): IStateFromProps => {
  const listState = getMessageList(
    listsStateSelector(state),
    props.conversationGuid,
  );
  const composition = state.messages.composition;

  const sudoGuid = SudoSettingsSelectors.getIdBySlug(state, {
    slug: props.sudoSlug,
  });

  const messagesAndConversation = getMessageRowsAndConversation(state, {
    sudoGuid,
    conversationGuid: props.conversationGuid,
  });

  const {
    messageData,
    conversationAndMembers,
    participants,
    oldestMessage,
    activeMembersData,
  } = messagesAndConversation;
  const {
    isGroupConversation,
    conversation,
    conversationAvatarImage,
    conversationAvatarText,
  } = conversationAndMembers;

  return {
    sudoGuid,
    composition,
    isCanWrite:
      conversation &&
      conversation.localParticipant &&
      conversation.localParticipant.active,
    redirectToAll: !conversation,
    noMoreMessages: listState.noMoreItems,
    isFetching: !!listState.fetchRef,
    participants,
    participantsAvatarImage: conversationAvatarImage,
    participantsAvatarText: conversationAvatarText,
    oldestMessage,
    messageData,
    isGroupConversation,
    isWindowFocused: state.app.isWindowFocused,
    activeMembersWithAvatars: activeMembersData,
    phoneAccountGuid: conversation && conversation.parent.id,
    viewAttachmentModal: state.modal.messagesViewAttachment,
    participantsAvatarLocked: !!(conversation && conversation.encrypted),
    title: conversation && conversation.title,
  };
};

// TODO: refactor - no more need for a separate thunk action
export const loadMoreMessages = (
  conversationGuid: string,
  until?: number,
): IThunk<void> => {
  return async (dispatch, getState, app) => {
    dispatch(Actions.fetchMessages(conversationGuid, until));
  };
};

let markMessagesInProgress: string[] = [];
let markConversationsInProgress: string[] = [];

/**
 * Mark all loaded messages as read for a given conversation.
 * If it happens that all messages are marked as read, but conversation itself stays unread,
 * Try and mark the conversation as read too.
 */
export const markMessagesAsRead = (conversationGuid: string): IThunk<void> => {
  return async (dispatch, getState, app) => {
    const state = getState();
    const unreadMessages = getUnreadMessagesByConversationId(state, {
      id: conversationGuid,
    });

    const notInProgress = unreadMessages.filter(mes => {
      if (markMessagesInProgress.includes(mes.id)) {
        return false;
      }
      markMessagesInProgress.push(mes.id);
      return true;
    });

    if (notInProgress.length > 0) {
      const markAsReadMessages = notInProgress.map(mes => {
        return {
          type: 'Message',
          id: mes.id,
          read: true,
        } as IMessage;
      });
      dispatch(Actions.saveEntities(markAsReadMessages)).then(() => {
        markMessagesInProgress = differenceWith(
          markMessagesInProgress,
          notInProgress,
          (inProgressId, notInProgressMessage) => {
            return inProgressId === notInProgressMessage.id;
          },
        );

        const debouncedCheckFn = debouncedCheckUnreadConversationFactory(
          conversationGuid,
        );
        debouncedCheckFn(dispatch, getState());
      });
    } else {
      const debouncedCheckFn = debouncedCheckUnreadConversationFactory(
        conversationGuid,
      );
      debouncedCheckFn(dispatch, state);
    }
  };
};

/**
 * Make preliminary checks using current app state to determine if conversation is marked as unread
 * And proceed to API request checks to mark conversation as unread if necessary
 */
const checkUnreadConversation = (
  dispatch: IDispatcher,
  state: IRootState,
  conversationGuid: string,
) => {
  if (!markConversationsInProgress.includes(conversationGuid)) {
    const conversation = MessageConversationSelectors.getEntityById(state, {
      id: conversationGuid,
    });
    if (conversation && conversation.path && conversation.hasUnread) {
      markConversationsInProgress.push(conversationGuid);
      dispatch(markConversationAsRead(conversation)).then(() => {
        markConversationsInProgress = markConversationsInProgress.filter(
          id => id !== conversationGuid,
        );
      });
    }
  }
};

/**
 * Returns different debounce functions for different conversationGuid params.
 * This ensures that at least one debounce call will be left for each conversation
 * (E.g. user switches to another conversation after debounce call and new debounce call is made with new params)
 */
const debouncedCheckUnreadConversationFactory = memoize(
  (conversationGuid: string) => {
    return debounce(
      (dispatch: IDispatcher, state: IRootState) =>
        checkUnreadConversation(dispatch, state, conversationGuid),
      MARK_CONVERSATION_TIMEOUT,
    );
  },
);

/**
 * Search for unread messages and mark conversation as read if there aren't any
 */
const markConversationAsRead = (
  conversation: IMessageConversation,
): IThunk<void> => {
  return async (dispatch, getState, app) => {
    const unreadMessages = await app.api.search(
      {
        Message: {
          type: 'and',
          children: [
            {
              type: 'simple',
              key: 'path',
              mode: 'contains',
              value: conversation.path,
            },
            {
              type: 'simple',
              key: 'read',
              mode: 'equals',
              value: false,
            },
          ],
        },
      },
      {
        offset: 0,
        pageSize: 1,
      },
    );

    if (unreadMessages.length === 0) {
      const conversationToSave = {
        type: 'MessagingThread',
        id: conversation.id,
        hasUnread: false,
      } as IMessageConversation;

      await dispatch(Actions.saveEntity(conversationToSave));
    }
  };
};

export const openAttachment = (
  id: string,
  messageGuid: string,
): IThunk<void> => {
  return async (dispatch, getState, app) => {
    const state = getState();
    const message = MessageSelectors.getEntityById(state, { id: messageGuid });
    const attachment = message.media.find(a => a.id === id);
    dispatch(Actions.showAttachment(attachment, message.id));
  };
};

export const closeAttachment = (): IThunk<void> => {
  return async dispatch => {
    dispatch(Actions.showAttachment(null));
  };
};

export const editMessage = (id: string): IThunk<void> => {
  return async (dispatch, getState, app) => {
    const state = getState();
    const message = MessageSelectors.getEntityById(state, { id });
    const composition = MessageComposition()
      .set('body', message.body)
      .set('message', message);
    dispatch(Actions.messageCompositionChanged(message.parent.id, composition));
  };
};

export const deleteMessage = (id: string): IThunk<void> => {
  return async (dispatch, getState, app) => {
    const state = getState();
    const message = MessageSelectors.getEntityById(state, { id });
    dispatch(Actions.deleteMessage(message));
  };
};

export const mapDispatch = (dispatch: IDispatcher): IDispatchFromProps => {
  const sendingActions = mapDispatchSending(dispatch);
  return {
    openConversation: (phoneAccountGuid: string, conversationGuid: string) => {
      dispatch(Actions.openConversation(phoneAccountGuid, conversationGuid));
    },
    closeConversation: (phoneAccountGuid: string) => {
      dispatch(Actions.closeConversation(phoneAccountGuid));
    },
    ...bindActionCreators(
      {
        loadMoreMessages,
        markMessagesAsRead,
        openAttachment,
        closeAttachment,
        editMessage,
        deleteMessage,
      },
      dispatch,
    ),
    ...sendingActions,
  };
};

export default connect(
  mapState,
  mapDispatch,
);
