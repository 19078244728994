import { handleRemoteTypingChange } from './messages-reset-is-typing';
import { selectConversation } from './messages-select-conversation';
import { createSidePanelAction } from './toggle-side-panel-action';
import { IThunk } from './actions-base';
import PhoneAccountSelectors from '../entities/phone-account/selectors';

export const CONVERSATION_OPEN = 'CONVERSATION_OPEN_ACTION';

export function openConversation(
  phoneAccountGuid: string,
  conversationGuid: string,
): IThunk<void> {
  return async (dispatch, getState, app) => {
    const state = getState();

    // close previous message details panel
    dispatch(createSidePanelAction('messageDetails', false));

    // // Ensure that the remoteTyping status will eventually get unset in the store, even
    // // if the API never notifies us about this explicitly.
    // dispatch(handleRemoteTypingChange(fatConversation.conversation));

    const phoneAccount = PhoneAccountSelectors.getEntityById(state, {
      id: phoneAccountGuid,
    });

    // Select the item in the conversation list
    dispatch(selectConversation(phoneAccount, conversationGuid));
  };
}

export function closeConversation(phoneAccountGuid: string): IThunk<void> {
  return async (dispatch, getState, app) => {
    const state = getState();
    const phoneAccount = PhoneAccountSelectors.getEntityById(state, {
      id: phoneAccountGuid,
    });

    dispatch(selectConversation(phoneAccount, null));
  };
}
