import { createSelector } from 'reselect';
import { invert } from 'lodash';
import SudoSettingsSelectors from '../../entities/sudo-settings/selectors';
import { IRootState } from '../../state';

export const getIsConnected = (state: IRootState) => {
  return (
    (state.app.apiStatus === 'connected' ||
      state.app.apiStatus === 'connection-unavailable') &&
    state.app.isInitialDataLoaded &&
    state.app.sessionActivated
  );
};

export const getFirstSudoSlug = createSelector(
  SudoSettingsSelectors.getSortedSudoAndSettings,
  SudoSettingsSelectors.getSlugsToIds,
  (sortedSudoAndSettings, slugsToIds): string => {
    if (!sortedSudoAndSettings || sortedSudoAndSettings.length === 0) {
      return null;
    }
    const idsToSlugs = invert(slugsToIds);
    return idsToSlugs[sortedSudoAndSettings[0].sudo.id];
  },
);
