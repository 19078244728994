import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

const Portal = props => ReactDOM.createPortal(props.children, document.body);

Portal.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Portal;
