import { IPhoneAccount } from '../../api';
import createEntitySelectors, {
  EntitiesArraySelector,
} from '../common/createEntitySelectors';
import ENTITY_TYPE from './entityType';
import { createSelector } from 'reselect';

export const createGetDefaultOrFirstAccountBySudoId = (
  getEntitiesBySudoIdSelector: EntitiesArraySelector<IPhoneAccount>,
) =>
  createSelector(
    getEntitiesBySudoIdSelector,
    phoneAccounts => {
      return phoneAccounts[0];
    },
  );

export const createPhoneAccountSelectors = () => {
  const commonSelectors = createEntitySelectors<IPhoneAccount>(ENTITY_TYPE);
  const { getEntitiesBySudoId } = commonSelectors;

  return {
    ...commonSelectors,
    getDefaultOrFirstAccountBySudoId: createGetDefaultOrFirstAccountBySudoId(
      getEntitiesBySudoId,
    ),
  };
};

const PhoneAccountSelectors = createPhoneAccountSelectors();

export default PhoneAccountSelectors;
