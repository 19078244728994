import * as React from 'react';
import Lottie from 'react-lottie';
import styled from 'styled-components';
import defaultAnimation from './animation.json';
import animationWithCheckmark from './animation-checkmark.json';

interface IProps {
  isWithCheckmark?: boolean;
}

const SpinnerContainer = styled.div`
  padding: 10px;
`;

export const Spinner = ({ isWithCheckmark }: IProps) => {
  const options = {
    animationData: isWithCheckmark ? animationWithCheckmark : defaultAnimation,
  };

  return (
    <SpinnerContainer>
      <Lottie width={64} height={64} options={options} />
    </SpinnerContainer>
  );
};
