import { makeTypedFactory, TypedRecord } from 'typed-immutable-record';
import { IMessageAttachment } from '../api';

// ----------------------------------------------------------------------------
// Modal

export type ModalStoreNames = keyof IModalProps;

export interface IModalEntry {
  isOpen: boolean;
}

export interface ISavingModalEntry extends IModalEntry {
  savingText?: string;
}

export interface IViewAttachmentModalEntry extends IModalEntry {
  loading?: boolean;
  attachment?: IMessageAttachment;
}

export interface IModalProps {
  messagesViewAttachment: IViewAttachmentModalEntry;
  messagesDeleteConversation: IModalEntry;
  unpair: IModalEntry;
  settings: IModalEntry;
  saving: ISavingModalEntry;
}

export interface IModal extends TypedRecord<IModal>, IModalProps {}

export const Modal = makeTypedFactory<IModalProps, IModal>({
  messagesViewAttachment: { isOpen: false },
  messagesDeleteConversation: { isOpen: false },
  unpair: { isOpen: false },
  settings: { isOpen: false },
  saving: { isOpen: false },
});
