import { IAction } from './actions-base';

export const API_PAIRING_TOKEN = 'API_PAIRING_TOKEN_ACTION';

export function pairingTokenAction(pairingToken: string): IAction {
  return {
    type: API_PAIRING_TOKEN,
    payload: pairingToken,
  };
}
