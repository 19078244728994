import { IMessageConversation, IPhoneAccount, IQueryParams } from '../api';
import * as Selectors from '../selectors';
import { uuid } from '../utils';
import * as ListActions from './list-actions';
import { processEntities } from './process-entity-action';
import { IThunk } from './actions-base';
import { ISearchPagination } from '../api/message-interfaces';

export const DEFAULT_CONVERSATION_FETCH_COUNT = 50;

/**
 * Fetches the next set of conversation history from device and add the items the appropriate conversation list
 */
export function fetchConversations(
  phoneAccount: IPhoneAccount,
  contentSearch?: string,
  count: number = DEFAULT_CONVERSATION_FETCH_COUNT,
): IThunk<void> {
  return async (dispatch, getState, app) => {
    const fetchRef = uuid();

    const getList = () => {
      return Selectors.getConversationList(getState().lists, phoneAccount);
    };

    // TODO: Called with unused param. Why? Needs refactoring.
    const adviseFetching = (isFetching: boolean) => {
      const list = getList();
      dispatch({
        type: ListActions.LIST_ADVISE_FETCHING,
        payload: {
          listId: list.id,
          ref: fetchRef,
          searchCriteria: contentSearch,
        },
      } as ListActions.IListAdviseFetchingAction);
    };

    const conversationList = getList();

    // notify list that a fetch is in progress
    adviseFetching(true);

    const searchParams: IQueryParams = {
      MessagingThread: {
        type: 'simple',
        key: 'path',
        mode: 'contains',
        value: phoneAccount && phoneAccount.path,
      },
    };
    const searchPagination: ISearchPagination = {
      pageSize: count,
      offset: conversationList.itemIds.size,
    };

    let conversations: IMessageConversation[];
    try {
      conversations = (await app.api.search(
        searchParams,
        searchPagination,
      )) as IMessageConversation[];
    } catch (err) {
      adviseFetching(false);
      return;
    }

    // discard result if a subsequent fetch has started for this list
    const updatedList = getList();
    if (getList().fetchRef !== fetchRef) {
      return;
    }

    // track entity data
    dispatch(processEntities(conversations));

    // update list view information
    dispatch({
      type: ListActions.LIST_APPEND_FETCH_RESULT,
      payload: {
        listId: updatedList.id,
        itemIds: conversations
          .sort((a, b) => b.modified - a.modified)
          .map(c => c.id),
        noMoreItems: conversations.length < count,
      },
    } as ListActions.IListAppendFetchResultAction);
  };
}
