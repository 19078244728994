import * as React from 'react';
import styled from 'styled-components';
import { pure } from 'recompose';

import ReceivedMessage from './ReceivedMessage';
import SentMessage from './SentMessage';
import { IMessageDataRow } from '../../ConversationContent/withConversationContentData';
import { Spinner } from '../../../../components/spinner';
import ReceivedAttachment from './ReceivedAttachment';
import SentAttachment from './SentAttachment';
import TimestampBadge from './TimestampBadge';
import UserActionBadge from './UserActionBadge';

export interface IProps {
  onScroll: (event: React.SyntheticEvent<HTMLElement>) => void;
  data: IMessageDataRow[];
  isFetching: boolean;
  onOpenAttachment: (id: string, messageGuid: string) => void;
  listScrollRef: any;
  onEditMessage: (id: string) => void;
  onDeleteMessage: (id: string) => void;
}

export const MessagesListWrapper = styled.div`
  flex-basis: 0;
  flex-grow: 1;
  overflow-y: auto;
  line-height: 20px;
`;

// To fix Safari bug which ignores margin from the last message
const EmptyBlock = styled.div`
  height: 1px;
`;

export const MessagesList = (props: IProps) => {
  const {
    onScroll,
    data,
    isFetching,
    onOpenAttachment,
    listScrollRef,
    onEditMessage,
    onDeleteMessage,
  } = props;
  return (
    <MessagesListWrapper onScroll={onScroll} ref={listScrollRef}>
      {isFetching && <Spinner />}

      {data &&
        data.map(row => {
          switch (row.type) {
            case 'timestamp':
              return <TimestampBadge key={row.id} label={row.data.label} />;
            case 'info':
              return (
                <UserActionBadge
                  action={row.data.action}
                  displayName={row.data.displayName}
                  key={row.id}
                />
              );
            case 'message':
              if (row.data.isReceived) {
                return (
                  <ReceivedMessage
                    actionId={row.data.messageGuid || row.id}
                    authorName={row.data.authorName}
                    avatarImage={row.data.avatarImage}
                    avatarLocked={row.data.avatarLocked}
                    avatarText={row.data.avatarText}
                    isExternal={row.data.isExternal}
                    key={row.id}
                    message={row.data.message}
                    onDeleteMessage={onDeleteMessage}
                    timeout={row.data.timeout}
                  />
                );
              } else {
                return (
                  <SentMessage
                    key={row.id}
                    actionId={row.data.messageGuid || row.id}
                    status={row.data.status}
                    message={row.data.message}
                    isExternal={row.data.isExternal}
                    onEditMessage={onEditMessage}
                    onDeleteMessage={onDeleteMessage}
                    timeout={row.data.timeout}
                  />
                );
              }
            case 'attachment':
              if (row.data.isReceived) {
                return (
                  <ReceivedAttachment
                    attachmentType={row.data.attachmentType}
                    avatarImage={row.data.avatarImage}
                    avatarLocked={row.data.avatarLocked}
                    avatarText={row.data.avatarText}
                    data={row.data.data}
                    key={row.id}
                    messageGuid={row.data.messageGuid}
                    onDeleteMessage={onDeleteMessage}
                    onOpenAttachment={onOpenAttachment}
                    realId={row.data.realId}
                    thumbnail={row.data.thumbnail}
                    timeout={row.data.timeout}
                  />
                );
              } else {
                return (
                  <SentAttachment
                    attachmentType={row.data.attachmentType}
                    data={row.data.data}
                    key={row.id}
                    status={row.data.status}
                    messageGuid={row.data.messageGuid}
                    onDeleteMessage={onDeleteMessage}
                    onOpenAttachment={onOpenAttachment}
                    realId={row.data.realId}
                    thumbnail={row.data.thumbnail}
                    timeout={row.data.timeout}
                  />
                );
              }
            default:
              throw Error('Invalid message row type: ' + row.type);
          }
        })}
      <EmptyBlock />
    </MessagesListWrapper>
  );
};

export default pure(MessagesList);
