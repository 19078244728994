import { isEqual, uniqBy, map, filter } from 'lodash';
import { createSelector } from 'reselect';
import moment from 'moment';

import {
  IContact,
  IContactAvatar,
  IMessageAttachment,
  IMessageConversation,
  IMessageConversationMember,
  MessageDirection,
  MessageProtocol,
  MessageStatus,
} from '../../api';
import MessageConversationSelectors from '../../entities/message-conversation/selectors';
import ContactSelectors, {
  IContactAndAvatar,
} from '../../entities/contact/selectors';
import MessageSelectors from '../../entities/message/selectors';
import GlobalSettingsSelectors from '../../entities/global-settings/selectors';
import AvatarEmptyIconBase64 from '../../icons/avatar-empty-new-base64';
import { getAvatarText, getContactNameOrData } from '../Contacts/selectors';
import {
  getConversationName,
  getParticipants,
} from './ConversationsList/withConversationsListData';
import {
  attachmentToDataUri,
  formatNumberForDisplay,
  formatRelativeDateShort,
  formatNumberForStorage,
} from '../../utils';
import { MessageStatusType } from './Conversation/MessagesList/SentMessage';
import { IRootState } from '../../state';
import { getContactName as getFullName } from '../Contacts/selectors';
import {
  getMessageList,
  listsStateSelector,
  getContactsByAlias,
} from '../../selectors';

export interface IMemberData {
  [memberString: string]: IContactAndAvatar;
}

export interface IConversationAndMembers {
  isGroupConversation: boolean;
  conversation: IMessageConversation;
  selfMemberKey: string;
  firstActiveMemberKey: string;
  activeMemberKeys: string[];
  allMemberKeys: string[];
  allMemberData: IMemberData;
  conversationAvatarImage: string | string[];
  conversationAvatarText: string;
}

export type MessageDataRowType =
  | 'timestamp'
  | 'info'
  | 'message'
  | 'attachment';

export interface IMessageDataRow {
  type: MessageDataRowType;
  id: string;
  data: any;
}

export interface IAvatarData {
  avatarText: string;
  avatarImage: string | string[];
  avatarLocked: boolean;
}

export interface IConversationListData extends IAvatarData {
  id: string;
  title: string;
  lastMessageText: string;
  isUnread: boolean;
  timestamp: string;
  recipients: string[];
}

export interface IEnterExitData {
  displayName: string;
  action: string;
}

export const getConversationCreationRedirectGuid = (state: IRootState) => {
  return state.conversationCreation.redirectToConversationGuid;
};

export const getConversationCreationParticipants = (state: IRootState) =>
  state.conversationCreation.participants;

export const getMessagesErrors = (state: IRootState) =>
  state.messagesErrors.errors;

export const getFirstActiveMember = (
  conversation: IMessageConversation,
): IMessageConversationMember => {
  return conversation.remoteParticipants.find(
    member => member.active && !isEqual(member, conversation.localParticipant),
  );
};

export const getMemberName = (
  member: IMessageConversationMember,
  contact: IContact,
  countryCode: string,
) => {
  if (contact) {
    const displayNameParts = getContactNameOrData(countryCode, contact);
    return [displayNameParts.secondary, displayNameParts.primary]
      .filter((part: string) => part)
      .join(' ')
      .trim();
  } else {
    if (member) {
      return member.aliasType === 'phone'
        ? formatNumberForDisplay(countryCode, member.alias)
        : member.alias;
    } else {
      return 'Unknown';
    }
  }
};

export const getActiveMembersData = (
  conversationData: IConversationAndMembers,
  countryCode: string,
): any[] => {
  const { conversation, allMemberData } = conversationData;
  const { remoteParticipants = [], localParticipant } =
    conversation || ({} as IMessageConversation);

  return remoteParticipants
    .filter(member => member.active && !isEqual(member, localParticipant))
    .map(member => {
      const { contact = null, avatar = null } =
        allMemberData[memberToString(member)] || {};

      const avatarData = getAvatarImageAndText(contact, avatar);

      return {
        ...avatarData,
        formattedPhone: formatNumberForDisplay(countryCode, member.alias),
        displayName: getMemberName(member, contact, countryCode),
        contactName: getFullName(contact),
        alias: member.alias,
        contact,
      };
    });
};

export const memberToString = (member: IMessageConversationMember) => {
  return member ? member.aliasType + member.alias : '';
};

export const formatDatetimeBadge = (timestamp: number | string) => {
  const today = moment(new Date());
  const yesterday = moment(new Date()).subtract(1, 'day');
  const incoming = moment(timestamp);
  const time = moment(timestamp).format('h:mm A');
  const date = incoming.isSame(today, 'day')
    ? 'Today'
    : incoming.isSame(yesterday, 'day')
    ? 'Yesterday'
    : incoming.format('MMM D, YYYY');

  return `${date} ${time}`;
};

export const formatMessageStatus = (
  status: MessageStatus,
): MessageStatusType => {
  switch (status) {
    case MessageStatus.Stored:
    case MessageStatus.Queued:
    case MessageStatus.Unknown:
    case MessageStatus.Sending:
      return 'sending';
    case MessageStatus.Sent:
      return 'sent';
    case MessageStatus.Delivered:
      return 'delivered';
    case MessageStatus.Viewed:
      return 'read';
    default:
      return 'not_delivered';
  }
};

export const createGetConversationsWithContactsAndAvatarsBySudoId = () =>
  createSelector(
    MessageConversationSelectors.getEntitiesBySudoId,
    ContactSelectors.getEntitiesWithAvatarBySudoIdAndShared,
    GlobalSettingsSelectors.getSettings,
    (
      conversations,
      contactsAndAvatars,
      globalSettings,
    ): IConversationAndMembers[] => {
      return conversations.map(conversation => {
        const allMemberData: IMemberData = {};

        const selfMemberKey = memberToString(conversation.localParticipant);
        const firstActiveMember = getFirstActiveMember(conversation);
        const firstActiveMemberKey = memberToString(firstActiveMember);
        const activeMemberKeys = conversation.remoteParticipants
          .filter(m => m.active)
          .map(memberToString);
        const allMemberKeys = conversation.remoteParticipants.map(
          memberToString,
        );

        const isGroupConversation =
          conversation && conversation.threadType === 'group';

        conversation.remoteParticipants.forEach(member => {
          // Seek for contact only if member type is PHONE (the only type currently supported)
          if (member && member.aliasType === 'phone') {
            const contactAndAvatar = contactsAndAvatars.find(data => {
              return (
                data.contact.phoneNumbers &&
                !!data.contact.phoneNumbers.find(numberData => {
                  return (
                    formatNumberForStorage(
                      globalSettings.countryCode,
                      numberData.number,
                    ) === member.alias
                  );
                })
              );
            });
            if (contactAndAvatar) {
              allMemberData[memberToString(member)] = contactAndAvatar;
            }
          }
        });

        let conversationAvatarImage: string | string[];
        let conversationAvatarText: string;
        if (!isGroupConversation) {
          const { contact, avatar } =
            allMemberData[firstActiveMemberKey] || ({} as IContactAndAvatar);
          conversationAvatarImage = avatar && avatar.avatar;
          conversationAvatarText =
            conversationAvatarImage || !contact ? null : getAvatarText(contact);
          if (!conversationAvatarImage && !conversationAvatarText) {
            conversationAvatarImage = AvatarEmptyIconBase64;
          }
        } else {
          conversationAvatarText = null;
          const activeAvatars = activeMemberKeys.reduce(
            (avatars, memberKey) => {
              if (memberKey !== selfMemberKey) {
                const { avatar } =
                  allMemberData[memberKey] || ({} as IContactAndAvatar);
                if (avatar && avatar.avatar) {
                  avatars.push(avatar.avatar);
                }
              }
              return avatars;
            },
            [],
          );
          if (activeAvatars.length === 0) {
            conversationAvatarImage = AvatarEmptyIconBase64;
          } else {
            conversationAvatarImage = activeAvatars;
          }
        }

        return {
          isGroupConversation,
          conversation,
          selfMemberKey,
          firstActiveMemberKey,
          activeMemberKeys,
          allMemberKeys,
          allMemberData,
          conversationAvatarImage,
          conversationAvatarText,
        };
      });
    },
  );

export const getIsMessageListEmptyBySudoId = createSelector(
  MessageConversationSelectors.getEntitiesBySudoId,
  conversations => !conversations || conversations.length === 0,
);

export const getAvatarImageAndText = (
  contact: IContact,
  avatar: IContactAvatar,
): IAvatarData => {
  let avatarImage = avatar && avatar.avatar;
  const avatarText = avatarImage || !contact ? null : getAvatarText(contact);
  if (!avatarImage && !avatarText) {
    avatarImage = AvatarEmptyIconBase64;
  }
  return {
    avatarImage,
    avatarLocked: false,
    avatarText,
  };
};

export const getConversationData = createSelector(
  (_, { conversationGuid }: any) => conversationGuid,
  createGetConversationsWithContactsAndAvatarsBySudoId(),
  GlobalSettingsSelectors.getSettings,
  (conversationGuid, conversations, globalSettings) => {
    const conversationAndMembers =
      conversations.filter(
        data => data.conversation.id === conversationGuid,
      )[0] || ({} as IConversationAndMembers);

    const {
      conversation,
      allMemberData,
      firstActiveMemberKey,
    } = conversationAndMembers;
    const { countryCode } = globalSettings;

    const localParticipant =
      conversationAndMembers.conversation &&
      conversationAndMembers.conversation.localParticipant;
    const contactAndAvatar =
      allMemberData && allMemberData[firstActiveMemberKey];
    const contact = contactAndAvatar && contactAndAvatar.contact;
    const participants = conversation
      ? getParticipants(conversation, contact, countryCode)
      : '';

    return {
      conversationAndMembers,
      participants,
      localParticipant,
      countryCode,
    };
  },
);

export type ContentType = 'VIDEO' | 'IMAGE' | 'ENTER' | 'EXIT' | 'UNKNOWN';
export const getContentType = (attachment: IMessageAttachment): ContentType => {
  if (attachment && attachment.mimeType) {
    if (attachment.mimeType.startsWith('image')) {
      return 'IMAGE';
    } else if (attachment.mimeType.startsWith('video')) {
      return 'VIDEO';
    } else if (attachment.mimeType.startsWith('enter')) {
      return 'ENTER';
    } else if (attachment.mimeType.startsWith('exit')) {
      return 'EXIT';
    }
  }
  return 'UNKNOWN';
};

const formatEnterExitData = (
  isReceived: boolean,
  contentType: ContentType,
  receivedAuthorName: string = '',
): IEnterExitData => {
  let authorName = receivedAuthorName;
  let has = 'has';
  if (!isReceived) {
    authorName = 'You';
    has = 'have';
  }
  const userActionText = contentType === 'ENTER' ? 'joined' : 'left';
  return {
    displayName: authorName,
    action: `${has} ${userActionText} the conversation`,
  };
};

export const getMessageRowsAndConversation = createSelector(
  (_, { conversationGuid }: any) => conversationGuid,
  MessageSelectors.getEntities,
  getConversationData,
  listsStateSelector,
  (conversationGuid, messages, conversationData, listsState) => {
    const { conversationAndMembers, countryCode } = conversationData;
    const {
      allMemberData,
      isGroupConversation,
      conversation,
    } = conversationAndMembers;
    const messageData: IMessageDataRow[] = [];
    let lastMoment = moment();

    const listState = getMessageList(listsState, conversationGuid);
    const sortedMessages = listState.itemIds
      .toArray()
      .map(id => messages[id])
      .sort((a, b) => a.created - b.created);

    sortedMessages.forEach(message => {
      const currentMoment = moment(message.created);

      if (currentMoment.diff(lastMoment, 'minutes') !== 0) {
        const datetimeFormatted = formatDatetimeBadge(message.created);
        messageData.push({
          type: 'timestamp',
          id: datetimeFormatted,
          data: {
            label: datetimeFormatted,
          },
        });
      }
      lastMoment = moment(message.created);

      let authorAvatar = '';
      let authorAvatarText = '';
      let authorName = '';
      const isReceived = message.direction === MessageDirection.Incoming;
      if (isReceived) {
        const senderData = allMemberData[memberToString(message.sender)];
        authorAvatar =
          senderData && senderData.avatar && senderData.avatar.avatar;

        const senderContact = (senderData && senderData.contact) || null;
        if (!authorAvatar) {
          authorAvatarText = getAvatarText(senderContact);
          if (!authorAvatarText) {
            authorAvatar = AvatarEmptyIconBase64;
          }
        }

        if (isGroupConversation) {
          authorName = getMemberName(
            message.sender,
            senderContact,
            countryCode,
          );
        }
      }

      const expiresIn = message.expiration
        ? moment(message.expiration).diff(moment(), 'seconds')
        : message.timeout;

      if (message.timeout && expiresIn && expiresIn < 1) {
        return;
      }

      if (message.media) {
        message.media.forEach((attachment: IMessageAttachment) => {
          const contentType = getContentType(attachment);
          const attachmentId = `${message.id}:${attachment.id}`;
          switch (contentType) {
            case 'ENTER':
            case 'EXIT':
              const relevantParticipantData = attachment.data;
              const { aliasType, alias } = conversation.localParticipant;
              const isReceivedClarified = relevantParticipantData
                ? `${aliasType}:${alias}` !== relevantParticipantData
                : isReceived;

              if (isReceivedClarified) {
                const sender = conversation.remoteParticipants.find(
                  member =>
                    `${member.aliasType}:${member.alias}` ===
                    relevantParticipantData,
                );

                if (sender) {
                  const senderData = allMemberData[memberToString(sender)];
                  const senderContact =
                    (senderData && senderData.contact) || null;
                  authorName = getMemberName(
                    sender,
                    senderContact,
                    countryCode,
                  );
                }
              }

              const userAction: IMessageDataRow = {
                type: 'info',
                id: attachmentId,
                data: formatEnterExitData(
                  isReceivedClarified,
                  contentType,
                  authorName,
                ),
              };
              messageData.push(userAction);
              break;
            case 'VIDEO':
            case 'IMAGE':
            case 'UNKNOWN':
            default:
              const formattedAttachment: IMessageDataRow = {
                type: 'attachment',
                id: attachmentId,
                data: {
                  messageGuid: message.id,
                  realId: attachment.id,
                  isReceived,
                  thumbnail: attachment.thumbnail
                    ? `data:image;base64,${attachment.thumbnail}`
                    : null,
                  data: attachmentToDataUri(attachment),
                  attachmentType: contentType,
                  status: formatMessageStatus(message.messageStatus),
                  timeout: expiresIn,
                } as any,
              };
              if (isReceived) {
                formattedAttachment.data.avatarImage = authorAvatar;
                formattedAttachment.data.avatarText = authorAvatarText;
              }
              messageData.push(formattedAttachment);
              break;
          }
        });
      }

      if (message.body) {
        const formattedMessage: IMessageDataRow = {
          type: 'message',
          id: message.id,
          data: {
            isReceived,
            isExternal: message.messageType === MessageProtocol.Basic,
            message: message.body,
            status: formatMessageStatus(message.messageStatus),
            timeout: expiresIn,
          },
        };
        if (isReceived) {
          formattedMessage.data.avatarImage = authorAvatar;
          formattedMessage.data.avatarText = authorAvatarText;
          if (isGroupConversation) {
            formattedMessage.data.authorName = authorName;
          }
        }
        messageData.push(formattedMessage);
      }
    });

    const oldestMessage =
      sortedMessages.length > 0 ? sortedMessages[0].created : null;

    return {
      ...conversationData,
      oldestMessage,
      messageData,
      activeMembersData: getActiveMembersData(
        conversationAndMembers,
        countryCode,
      ),
    };
  },
);

export const formatLastMessageText = (
  conversation: IMessageConversation,
  allMemberData: IMemberData,
  countryCode: string,
) => {
  const { subject } = conversation;
  if (subject) {
    if (subject.expiring) {
      return 'Expiring message';
    }
    if (subject.body) {
      const trimmed = subject.body.trim();
      return trimmed.length > 100 ? trimmed.substr(0, 97) + '...' : trimmed;
    }
    const attachmentsCount =
      (subject && subject.media && subject.media.length) || 0;
    if (attachmentsCount > 0) {
      // Format enter/exit message
      if (attachmentsCount === 1) {
        const contentType = getContentType(subject.media[0]);
        if (contentType === 'ENTER' || contentType === 'EXIT') {
          const relevantParticipantData = subject.media[0].data;
          const participantToCheck = relevantParticipantData || subject.sender;

          const { aliasType, alias } = conversation.localParticipant;

          const isReceived = `${aliasType}:${alias}` !== participantToCheck;
          let authorName = '';
          if (isReceived) {
            const sender = conversation.remoteParticipants.find(
              member =>
                `${member.aliasType}:${member.alias}` === participantToCheck,
            );

            if (sender) {
              const senderData = allMemberData[memberToString(sender)];
              const senderContact = (senderData && senderData.contact) || null;
              authorName = getMemberName(sender, senderContact, countryCode);
            }
          }
          const actionData = formatEnterExitData(
            isReceived,
            contentType,
            authorName,
          );
          return `${actionData.displayName} ${actionData.action}`;
        }
      }

      // Check if attachments are of different types
      const typesData = subject.media.reduce(
        (acc, attachment) => {
          if (!acc.isDifferent) {
            const type = getContentType(attachment);
            if (!acc.lastType) {
              acc.lastType = type;
            } else if (acc.lastType !== type) {
              acc.isDifferent = true;
            }
          }
          return acc;
        },
        {
          isDifferent: false,
          lastType: null,
        },
      );

      if (!typesData.isDifferent) {
        switch (typesData.lastType) {
          case 'VIDEO':
            if (attachmentsCount > 1) {
              return `${attachmentsCount} Videos`;
            }
            return `1 Video`;
          case 'IMAGE':
            if (attachmentsCount > 1) {
              return `${attachmentsCount} Images`;
            }
            return `1 Image`;
          default:
            if (attachmentsCount > 1) {
              return `${attachmentsCount} Attachments`;
            }
            return `1 Attachment`;
        }
      } else {
        return `${attachmentsCount} Attachments`;
      }
    }
  }
  return ' ';
};

export const getConversationListData = createSelector(
  createGetConversationsWithContactsAndAvatarsBySudoId(),
  GlobalSettingsSelectors.getSettings,
  (conversationData, globalSettings): IConversationListData[] => {
    return conversationData
      .sort((a, b) => b.conversation.lastReceived - a.conversation.lastReceived)
      .map(
        ({
          conversation,
          firstActiveMemberKey,
          allMemberData,
          conversationAvatarImage,
          conversationAvatarText,
        }) => {
          const contactAndAvatar = allMemberData[firstActiveMemberKey];
          const contact = contactAndAvatar && contactAndAvatar.contact;
          const lastMessageText = formatLastMessageText(
            conversation,
            allMemberData,
            globalSettings.countryCode,
          );
          return {
            recipients: conversation.remoteParticipants.map(participant =>
              formatNumberForStorage(
                globalSettings.countryCode,
                participant.alias,
              ),
            ),
            id: conversation.id,
            title: getConversationName(
              conversation,
              contact,
              globalSettings.countryCode,
            ),
            lastMessageText,
            isUnread: conversation.hasUnread,
            timestamp: formatRelativeDateShort(conversation.lastReceived),
            avatarImage: conversationAvatarImage,
            avatarText: conversationAvatarText,
            avatarLocked: !!conversation.encrypted,
          };
        },
      );
  },
);

export const getMessagesContacts = createSelector(
  getConversationListData,
  getContactsByAlias,
  (conversations, contactsByAlias) => {
    const recipients = conversations.reduce(
      (accumulator, conversation) => [
        ...accumulator,
        ...conversation.recipients,
      ],
      [] as string[],
    );
    const contacts = recipients.reduce(
      (accumulator, recipient) => {
        const contact = contactsByAlias[recipient];
        if (contact) {
          return [...accumulator, contact];
        }
        return accumulator;
      },
      [] as IContact[],
    );

    return contacts;
  },
);

export const getUnreadMessagesByConversationId = createSelector(
  MessageSelectors.getEntitiesByConversationId,
  messages => {
    return messages.filter(mes => !mes.read && mes.id !== mes.localRefId);
  },
);

export const getContacts = createSelector(
  ContactSelectors.getEntitiesWithAvatarBySudoIdAndShared,
  GlobalSettingsSelectors.getSettings,
  (contacts, globalSettings) => {
    let contactsListItems = filter(contacts, (c: any) => {
      return (
        c.contact && c.contact.phoneNumbers && c.contact.phoneNumbers.length
      );
    });
    contactsListItems = map(contactsListItems, (c: any) => {
      const { id, first, last, phoneNumbers } = c.contact;
      return phoneNumbers.map((pn: any) => {
        const displayName = [first, last].filter((i: any) => i).join(' ');
        const avatar = c.avatar && c.avatar.avatar;
        const phoneNumber = formatNumberForStorage(
          globalSettings.countryCode,
          pn.number,
        );
        return {
          avatar,
          displayName,
          first,
          id,
          last,
          phoneNumber,
        };
      });
    });
    contactsListItems = [].concat(...contactsListItems); // flatten array
    contactsListItems = uniqBy(contactsListItems, 'phoneNumber');
    return contactsListItems;
  },
);
