import { Seq } from 'immutable';
import { makeTypedFactory, TypedRecord } from 'typed-immutable-record';

import {
  IMessage,
  IMessageConversation,
  IMessageConversationMember,
} from '../api';

// ----------------------------------------------------------------------------
// Message Composition State

export interface IMessageCompositionProps {
  /** Text content to send */
  body: string;

  /** Base64-encoded attachment data to send in uri data format */
  attachmentData: Seq.Indexed<string>;

  /** Existing message to edit */
  message?: IMessage;
}

export interface IMessageComposition
  extends TypedRecord<IMessageComposition>,
    IMessageCompositionProps {}

export const MessageComposition = makeTypedFactory<
  Partial<IMessageCompositionProps>,
  IMessageComposition
>({
  body: '',
  attachmentData: Seq.Indexed<string>(),
  message: null,
});

// ----------------------------------------------------------------------------
// Message Conversation Participant Props

export interface IDesiredConversationParticipant {
  member: IMessageConversationMember;
  status: 'validating' | 'validated'; // Invalid participants are removed
}

// ----------------------------------------------------------------------------
// Messages State

export interface IMessagesProps {
  // --------------------------------------------------------------------------
  // "Master" view (lists/search)

  /** Falsy if user is not doing a search, otherwise the search query string */
  search: string;

  // --------------------------------------------------------------------------
  // "Details" view (composition/thread)

  /** Phone account selected for sending the current message */
  phoneAccountGuid: string;

  /** Desired participants for the current conversation */
  participants: IDesiredConversationParticipant[];

  /**
   * Represents the conversation entity related to the current participant list.
   * The value must be valid for the currently selected sudo.
   * - Will be a string id if the conversation already exists and we have it in the store.
   * - Will be a conversation entity if this is a newly proposed conversation that has no messages yet.
   * - Will be null if the participants list is empty, or if participants are in the process of being validated.
   */
  conversation: string | IMessageConversation;

  /** Message composer contents (body/attachments) */
  composition: IMessageComposition;

  /**
   * Will be `true` if the user has signaled their intent to send the message and the
   * message hasn't been sent yet. This flag exists so that the UI can wait until all
   * participants have been properly validated before actually sending a message.
   */
  sendRequested: boolean;
}

export interface IMessages extends TypedRecord<IMessages>, IMessagesProps {}

export const Messages = makeTypedFactory<Partial<IMessagesProps>, IMessages>({
  phoneAccountGuid: null,
  conversation: null,
  participants: [],
  composition: MessageComposition(),
  search: '',
  sendRequested: false,
});
