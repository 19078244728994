import * as React from 'react';
import { mapProps } from 'recompose';
import { RouteComponentProps, Redirect } from 'react-router-dom';
import { map, keyBy } from 'lodash';
import styled from 'styled-components';

import {
  formatNumberForDisplay,
  formatNumberForStorage,
  isValidPhoneNumber,
} from '../../../utils';

import ContactsInput from '../../../components/ContactsInput';

import {
  ConversationViewWrapper,
  Layout,
  LayoutConversationView,
  LayoutToolbarTop,
} from '../Conversation/ConversationLayout';
import { MessagesListWrapper } from '../Conversation/MessagesList';
import InputToolbar from '../Conversation/InputToolbar';
import withConversationCreationData, {
  IDispatchFromProps,
  IStateFromProps,
  IStateProps,
} from './withConversationCreationData';

import ContactListItem from './ContactListItem';

export interface IProps
  extends IStateProps,
    IStateFromProps,
    IDispatchFromProps {}

export interface IMatch {
  sudo: string;
}

const Tag = styled.span`
  > span {
    color: ${props => props.color}!important;
  }
`;

export class Component extends React.Component<IProps> {
  public handleSearch = (phoneNumber: string, search: string): boolean => {
    const { contacts, countryCode } = this.props;
    const contactsMap = keyBy(contacts, 'phoneNumber');
    const contact = contactsMap[phoneNumber];

    if (phoneNumber.toLowerCase().indexOf(search) >= 0) {
      return true;
    }

    const phone = formatNumberForStorage(countryCode, phoneNumber) || '';
    if (phone.indexOf(formatNumberForStorage(countryCode, search)) >= 0) {
      return true;
    }

    const name = [contact.first, contact.last]
      .filter((i: any) => i)
      .join(' ')
      .toLowerCase();
    if (name.indexOf(search.toLowerCase()) >= 0) {
      return true;
    }

    return false;
  };

  public handleChange = (changedValues: string[]): void => {
    const {
      countryCode,
      defaultPhoneAccountGuid,
      validateParticipants,
    } = this.props;

    const participants = map(changedValues, (value: string) => {
      return {
        alias: formatNumberForStorage(countryCode, value),
        aliasType: 'phone',
      };
    });

    validateParticipants(defaultPhoneAccountGuid, participants);
  };

  public handleSubmit = (): void => {
    const { onSubmit, sendRequested } = this.props;
    if (!sendRequested) {
      onSubmit();
    }
  };

  public render() {
    const {
      contacts,
      conversationGuid,
      countryCode,
      defaultPhoneAccountGuid,
      isConversationProtocolSms,
      onChange,
      redirectToConversationGuid,
      sudoSlug,
      to,
    } = this.props;

    // TODO: This is really slow to be calculating this on every render.  It should be a selector.
    const values = map(to, 'alias');
    const valuesMap = keyBy(to, 'alias');
    const contactValues = map(contacts, 'phoneNumber');
    const contactsMap = keyBy(contacts, 'phoneNumber');

    return (
      <Layout>
        {!!redirectToConversationGuid && (
          <Redirect
            to={`/${sudoSlug}/messages/${redirectToConversationGuid}`}
          />
        )}
        {!defaultPhoneAccountGuid && <Redirect to={`/${sudoSlug}/messages`} />}
        <LayoutToolbarTop>
          <ContactsInput
            label={'To:'}
            placeholder={values.length ? '' : 'Type name or phone number'}
            value={values}
            onChange={this.handleChange}
            contacts={contactValues}
            tabIndex={0}
            isValid={value => isValidPhoneNumber(countryCode, value)}
            searchFn={this.handleSearch}
            renderTag={(value: string, index: number) => {
              const contact = contactsMap[value];
              const { first, last } = contact || { first: null, last: null };
              const { displayName } = valuesMap[value] || { displayName: null };
              return (
                <Tag
                  color={isConversationProtocolSms ? 'var(--green)' : 'inherit'}
                >
                  {first || last ? (
                    <span>
                      {first}
                      {!!last && <React.Fragment>&nbsp;{last}</React.Fragment>}
                    </span>
                  ) : displayName ? (
                    <span>{displayName}</span>
                  ) : (
                    <span>{formatNumberForDisplay(countryCode, value)}</span>
                  )}
                </Tag>
              );
            }}
            renderContact={(value: string, index: number) => (
              <ContactListItem
                value={value}
                contact={contactsMap[value]}
                countryCode={countryCode}
              />
            )}
          />
        </LayoutToolbarTop>
        <LayoutConversationView>
          <ConversationViewWrapper>
            <MessagesListWrapper />
            <InputToolbar
              autoFocus={false}
              isCanWrite={true}
              inputPlaceholder={`${
                this.props.isConversationProtocolSms ? 'Text' : 'Encrypted'
              } Message`}
              onSubmit={this.handleSubmit}
              onChange={value => onChange(conversationGuid, value)}
              isNoClearOnSubmit={true}
              isNoEmptySubmit={true}
            />
          </ConversationViewWrapper>
        </LayoutConversationView>
      </Layout>
    );
  }
}

export const propsMapper = (props: RouteComponentProps<IMatch>) => ({
  sudoSlug: props.match.params.sudo,
});
const mapRouterProps = mapProps(propsMapper);

export default mapRouterProps(withConversationCreationData(Component));
