import * as React from 'react';
import { IProps } from './types';

import SearchInput from 'src/components/SearchInput';

const Component = (props: IProps) => {
  const handleChange = React.useCallback(
    (value: string) => props.onChange(props.sudoGuid, value),
    [props.sudoGuid, props.onChange],
  );

  return (
    <SearchInput
      placeholder={props.placeholder}
      value={props.value}
      onChange={handleChange}
    />
  );
};

export default Component;
