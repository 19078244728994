// tslint:disable:max-line-length

import React from 'react';

export default function Primary(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M10.285 5.513l5.452.373c.253.018.357.352.16.522l-4.2 3.666 1.346 5.557c.063.259-.208.465-.423.322L8 12.893l-4.62 3.06c-.215.143-.486-.063-.423-.322l1.346-5.557L.102 6.408c-.196-.17-.092-.504.162-.522l5.452-.373L7.739.186a.277.277 0 0 1 .522 0l2.024 5.327z"
      />
    </svg>
  );
}
