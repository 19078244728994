import React from 'react';

export default function Messages(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      {...props}
    >
      <g fill="currentColor" fillRule="evenodd" transform="translate(2 2)">
        <path d="M3.918 17.473l3.03-1.631.294.084c.806.23 1.65.347 2.508.347 4.573 0 8.25-3.325 8.25-7.386C18 4.825 14.323 1.5 9.75 1.5S1.5 4.825 1.5 8.887c0 1.903.81 3.712 2.271 5.088l.246.232-.099 3.266zm-.052 1.732a1 1 0 0 1-1.473-.91l.105-3.467C.894 13.203 0 11.1 0 8.887 0 3.96 4.38 0 9.75 0s9.75 3.961 9.75 8.887c0 4.925-4.38 8.886-9.75 8.886-.892 0-1.772-.109-2.621-.324l-3.263 1.756z" />
      </g>
    </svg>
  );
}
