import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import noop from 'lodash/noop';

import Portal from 'src/components/utils/Portal';
import Overlay from 'src/components/utils/Overlay';
import CancelListener from 'src/components/utils/CancelListener';
import ModalBase from './deps/ModalBase';

export { default as ModalTitle } from './deps/ModalTitle';
export { default as ModalContent } from './deps/ModalContent';
export { default as ModalContentText } from './deps/ModalContentText';
export { default as ModalActions } from './deps/ModalActions';

interface IProps {
  isOpen: boolean;
  children?: any;
  onClose?: (event: React.SyntheticEvent) => void;
  style?: React.CSSProperties;
  className?: string;
  zIndex?: number;
}

const ModalWrapper = styled.div<{ zIndex?: number }>`
  transition: opacity var(--theme-transition-function-default) 0.25s;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  opacity: 0;
  z-index: ${props => props.zIndex};

  &.isOpen {
    & > * {
      pointer-events: all;
    }
    opacity: 1;
  }
`;

const Modal = (props: IProps) => (
  <Portal>
    <div>
      <CancelListener shouldDetect={props.isOpen} onCancel={props.onClose} />
      <Overlay
        isOpen={props.isOpen}
        onClick={props.onClose}
        zIndex={props.zIndex}
      />
      <ModalWrapper
        className={cn(props.isOpen && 'isOpen')}
        zIndex={props.zIndex}
      >
        <ModalBase {...props}>{props.children}</ModalBase>
      </ModalWrapper>
    </div>
  </Portal>
);

Modal.defaultProps = {
  onClose: noop,
  zIndex: 1,
};

export default Modal;
