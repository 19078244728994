import { IMessage, IQueryParams } from '../api';
import * as Selectors from '../selectors';
import { uuid } from '../utils';
import * as ListActions from './list-actions';
import { processEntities } from './process-entity-action';
import { IThunk } from './actions-base';
import {
  ISearchPagination,
  ISimplePredicate,
  SearchPredicate,
} from '../api/message-interfaces';
import MessageConversationSelectors from '../entities/message-conversation/selectors';

const kDefaultFetchCount = 20;

/**
 * Fetches the next set of messages for a conversation and add them to the appropriate conversation's message list
 */
export function fetchMessages(
  conversationGuid: string,
  until: number,
  count: number = kDefaultFetchCount,
): IThunk<void> {
  return async (dispatch, getState, app) => {
    const state = getState();
    const fetchRef = uuid();

    const getList = () => {
      return Selectors.getMessageList(getState().lists, conversationGuid);
    };

    const adviseFetching = (isFetching: boolean) => {
      const list = getList();
      dispatch({
        type: ListActions.LIST_ADVISE_FETCHING,
        payload: {
          listId: list.id,
          ref: isFetching ? fetchRef : null,
        },
      } as ListActions.IListAdviseFetchingAction);
    };

    const messageList = getList();

    // notify list that a fetch is in progress
    adviseFetching(true);

    const conversation = MessageConversationSelectors.getEntityById(state, {
      id: conversationGuid,
    });

    const parentPredicate: ISimplePredicate = {
      type: 'simple',
      key: 'path',
      mode: 'contains',
      value: conversation && conversation.path,
    };
    let searchPredicate: SearchPredicate = parentPredicate;
    if (until) {
      searchPredicate = {
        type: 'and',
        children: [
          parentPredicate,
          {
            type: 'simple',
            key: 'created',
            mode: 'lessThan',
            value: until,
          },
        ],
      };
    }

    const searchParams: IQueryParams = {
      Message: searchPredicate,
    };
    const searchPagination: ISearchPagination = {
      pageSize: count,
      offset: 0,
    };

    let messages: IMessage[];
    try {
      messages = (await app.api.search(
        searchParams,
        searchPagination,
        null,
        null,
        null,
        180000,
      )) as IMessage[];
    } catch (err) {
      adviseFetching(false);
      return;
    }

    // discard result if a subsequent fetch has started for this list
    if (getList().fetchRef !== fetchRef) {
      return;
    }

    // track entity data
    dispatch(processEntities(messages));

    // update list view information
    dispatch({
      type: ListActions.LIST_APPEND_FETCH_RESULT,
      payload: {
        listId: messageList.id,
        itemIds: messages.sort((a, b) => b.created - a.created).map(m => m.id),
        noMoreItems: messages.length < count,
      },
    } as ListActions.IListAppendFetchResultAction);
  };
}
