import styled from 'styled-components';
import { gridContentParentHack } from 'src/utils/grid';

export const LayoutListToolbar = styled.div`
  grid-area: list-toolbar;
  border-bottom: 1px solid var(--theme-divider-color);
  border-right: 1px solid var(--theme-divider-color);
  display: flex;
  align-items: center;
  padding-left: calc(var(--theme-spacing) * 3);
  background-color: var(--gray-9);
`;

export const LayoutList = styled.div`
  ${gridContentParentHack};
  grid-area: list;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 100%;
  background-color: var(--gray-9);
  border-right: solid 1px var(--theme-divider-color);
  display: flex;
  flex-direction: column;
`;

export const LayoutContent = styled.div`
  ${gridContentParentHack};
  grid-area: content;
  display: flex;
  min-width: 0;
  max-height: 100%;
`;

export const Layout = styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
  background: var(--white);
  display: grid;
  grid-template-columns: 375px 1fr;
  grid-template-rows: 56px 1fr;
  grid-template-areas:
    'list-toolbar content'
    'list content';
`;
