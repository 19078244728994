/* tslint:disable max-line-length */
import * as React from 'react';

export default function MyComponent(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={101}
      height={155}
      viewBox="0 0 101 155"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <g fill="#979EA6" transform="rotate(17 -324.322 85.648)">
          <path d="M54.642 7.237L30.37 21.52a5.658 5.658 0 0 1-5.418 0L.68 7.237C1.162 3.169 4.99 0 9.64 0h36.042c4.65 0 8.477 3.169 8.96 7.237zm.051 4.344V35.9c0 4.477-4.034 8.106-9.01 8.106H9.638c-4.976 0-9.01-3.63-9.01-8.106V11.58l22.516 13.251a9.43 9.43 0 0 0 9.031 0l22.517-13.25z" />
        </g>
        <g fill="#979EA6" transform="rotate(-44 124.626 -8.495)">
          <path d="M49.178 6.513L27.333 19.368a5.092 5.092 0 0 1-4.877 0L.612 6.513C1.046 2.852 4.49 0 8.675 0h32.439c4.185 0 7.63 2.852 8.064 6.513zm.046 3.91V32.31c0 4.029-3.631 7.295-8.11 7.295H8.675c-4.478 0-8.11-3.266-8.11-7.295V10.423l20.266 11.925a8.487 8.487 0 0 0 8.128 0l20.265-11.925z" />
        </g>
        <g fill="#979EA6" transform="rotate(15 -79.663 80.692)">
          <path d="M32.785 4.342l-14.563 8.57a3.395 3.395 0 0 1-3.251 0L.408 4.342C.698 1.902 2.994 0 5.784 0h21.625c2.79 0 5.087 1.901 5.376 4.342zm.03 2.606V21.54c0 2.686-2.42 4.864-5.406 4.864H5.784c-2.986 0-5.407-2.178-5.407-4.864V6.948l13.51 7.95a5.658 5.658 0 0 0 5.419 0l13.51-7.95z" />
        </g>
        <g fill="#979EA6" transform="rotate(-30 49.112 -100.874)">
          <path d="M24.589 3.256L13.667 9.684a2.546 2.546 0 0 1-2.439 0L.306 3.256C.523 1.426 2.246 0 4.338 0h16.219c2.092 0 3.815 1.426 4.032 3.256zm.023 1.955v10.944c0 2.014-1.816 3.648-4.055 3.648H4.337c-2.239 0-4.054-1.634-4.054-3.648V5.21l10.132 5.963c1.267.691 2.798.691 4.064 0l10.133-5.963z" />
        </g>
      </g>
    </svg>
  );
}
