/* tslint:disable-next-line:no-var-requires */
import loadImage from 'blueimp-load-image';

/**
 * Processes a file and converts it to base64
 *
 * If this is an image it applies transformations such as stripping EXIF data
 * and ensuring the orientation is correct
 */
export function getDataUrlFromFile(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    // JPEG have the potential of including EXIF data which
    // should be stripped as it contains information which can affect visible orientation
    // as well as leak sensitive information such as GPS location
    if (file.type === 'image/jpeg') {
      loadImage(
        file,
        result => {
          if ((result as Event).type !== 'error') {
            resolve((result as HTMLCanvasElement).toDataURL(file.type));
          } else {
            reject(result);
          }
        },
        { orientation: true },
      );
    } else {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = err => reject(err);
      reader.readAsDataURL(file);
    }
  });
}
