import { IAction } from './actions-base';

/**
 * Advise list that more items are currently being fetched
 */
export const LIST_ADVISE_FETCHING = 'LIST_ADVISE_FETCHING_ACTION';

export interface IListAdviseFetchingAction extends IAction {
  type: typeof LIST_ADVISE_FETCHING;
  payload: {
    listId: string;
    ref: string;
    searchCriteria: string | null;
  };
}

/**
 * Append fetch results to a list
 */
export const LIST_APPEND_FETCH_RESULT = 'LIST_APPEND_FETCH_RESULT_ACTION';

export interface IListAppendFetchResultAction extends IAction {
  type: typeof LIST_APPEND_FETCH_RESULT;
  payload: {
    listId: string;
    itemIds: string[];
    noMoreItems?: boolean;
  };
}

/**
 * Insert an array of ids into a list at a specified pos
 */
export const LIST_INSERT = 'LIST_INSERT_ACTION';

export interface IListInsertAction extends IAction {
  type: typeof LIST_INSERT;
  payload: {
    listId: string;
    itemIds: string[];
    pos?: number; // default 0
  };
}

/**
 * Remove an item form the list
 */
export const LIST_REMOVE = 'LIST_REMOVE_ACTION';

export interface IListRemoveAction extends IAction {
  type: typeof LIST_REMOVE;
  payload: {
    listId: string;
    itemId: string;
  };
}

/**
 * Moves an item to the top of a list.
 * If the item isn't in the list to start with, it
 * will still get put at the top.
 */
export const LIST_MOVE_TO_TOP = 'LIST_MOVE_TO_TOP_ACTION';

export interface IListMoveToTopAction extends IAction {
  type: typeof LIST_MOVE_TO_TOP;
  payload: {
    listId: string;
    itemId: string;
  };
}

/**
 * Changes the selection items in a list.
 */
export const LIST_SELECT = 'LIST_SELECT_ACTION';

export interface IListSelectAction extends IAction {
  type: typeof LIST_SELECT;
  payload: {
    listId: string;
    itemIds: [string];
  };
}

/**
 * Replaces an ID in the list with a new ID.
 * If the old ID isn't found, then the new ID is appended.
 */
export const LIST_REPLACE_OR_APPEND = 'LIST_REPLACE_OR_APPEND_ACTION';

export interface IListReplaceOrAppendAction extends IAction {
  type: typeof LIST_REPLACE_OR_APPEND;
  payload: {
    listId: string;
    oldId: string;
    newId: string;
  };
}

/**
 * Clears all items from a list
 */
export const LIST_CLEAR = 'LIST_CLEAR_ACTION';

export interface IListClearAction extends IAction {
  type: typeof LIST_CLEAR;
  payload: {
    listId: string;
  };
}

/**
 * Deletes a list from state
 */
export const LIST_DISPOSE = 'LIST_DISPOSE_ACTION';

export interface IListDisposeAction extends IAction {
  type: typeof LIST_DISPOSE;
  payload: {
    listId: string;
  };
}
