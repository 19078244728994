import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';

interface IProps extends HTMLAttributes<HTMLInputElement> {
  color?: string;
  onChange: (event: React.SyntheticEvent) => void;
  onFocus?: (event: React.SyntheticEvent) => void;
  placeholder?: string;
  placeholderColor?: string;
  ref?: React.Ref<HTMLInputElement>;
  type: string;
  value: string;
}

const InputElement = styled.input<IProps>`
  flex-grow: 1;
  height: 100%;
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  font-size: 16px;
  line-height: 18px;
  background: transparent;
  color: ${props => props.color};
  caret-color: var(--theme-input-caret-color);
  &::placeholder {
    color: ${props => props.placeholderColor};
  }
`;

InputElement.defaultProps = {
  color: 'inherit',
  placeholderColor: 'inherit',
  type: 'text',
};

export default InputElement;
