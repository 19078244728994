import * as React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { Avatar, Badge, Button, Icon } from 'src/components';
import Timer from 'src/components/Timer';
import Linkify from 'linkifyjs/react';
import VideoPlayIcon from '../../../../../icons/VideoPlay';

export interface IWithActionId {
  actionId: string;
}

export interface IWithOpenAttachmentHandlerData {
  realId: string;
  messageGuid: string;
}

export const RowOptionsWrapper = styled.div`
  display: none;
  position: absolute;
  right: -128px;
  top: 50%;
  margin-top: -24px;
  > :first-child {
    margin-right: 8px;
  }
  &.isSingle {
    right: -72px;
    > :first-child {
      margin-right: initial;
    }
  }
  &.isFlipped {
    right: initial;
    left: -128px;
    &.isSingle {
      left: -72px;
    }
  }
  &.isOpen {
    display: flex;
  }
`;

export const RowOptionsContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 24px;
  width: 48px;
  height: 48px;
  background-color: rgba(0, 0, 0, 0.4);
`;

export const RowOptions = (props: any) => {
  return (
    <RowOptionsWrapper
      className={cn(
        props.isFlipped && 'isFlipped',
        !props.onEditMessage && 'isSingle',
      )}
    >
      {props.onEditMessage && (
        <Button onClick={props.onEditMessage}>
          <RowOptionsContent>
            <Icon name="Edit" color="#fff" />
          </RowOptionsContent>
        </Button>
      )}
      <Button onClick={props.onDeleteMessage}>
        <RowOptionsContent>
          <Icon name="DeleteFill" color="#fff" />
        </RowOptionsContent>
      </Button>
    </RowOptionsWrapper>
  );
};

export const ReceivedAvatar = styled(Avatar)`
  margin: 0 8px 0 0;
  width: 40px;
  height: 40px;
  font-size: 13px;
  line-height: 15px;
`;

export const MessageRowWrapper = styled.div`
  margin: calc(var(--theme-spacing) * 2);
  &:hover ${RowOptionsWrapper} {
    display: flex;
  }
`;

export const MessageReceivedRow = styled.div`
  display: flex;
  align-items: flex-end;
  margin-right: 28%;
  > :nth-child(2) {
    min-width: 0;
  }
`;

export const MessageSentRow = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-left: 28%;
  > :first-child {
    min-width: 0;
  }
`;

export const MessageWrapper = styled.span`
  white-space: pre-line;
  word-wrap: break-word;
`;

export const AttachmentWrapper = styled.div`
  position: relative;
`;

export const AttachmentThumbnailWrapper = styled.div`
  position: relative;
`;

export const AttachmentThumbnailImage = styled.img`
  background-color: #d8d8d8;
  border-radius: 9px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  height: 150px;
  max-width: 300px;
  min-width: 40px;
  object-fit: contain;
  object-position: 50% 50%;
  width: auto;
`;

export const AttachmentThumbnailVideo = styled.video`
  background-color: #d8d8d8;
  border-radius: 9px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  height: 150px;
  max-width: 300px;
  min-width: 40px;
  object-fit: contain;
  object-position: 50% 50%;
  width: auto;
`;

export const StyledVideoPlayIcon = styled(VideoPlayIcon)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
`;

export const TimerBadgeStyled = styled(Badge)`
  position: absolute;
  padding: 2px 6px;
  left: 4px;
  bottom: 8px;
  background-color: rgba(0, 0, 0, 0.8);
`;

export const IconContainer = styled.div`
  align-items: center;
  background-color: #d8d8d8;
  border-radius: 9px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  display: flex;
  flex-flow: column nowrap;
  height: 100px;
  justify-content: center;
  width: 200px;
`;

const AttachmentText = styled.div`
  margin: 10px 0 0;
`;

export const AttachmentThumbnail = (props: any) => (
  <AttachmentThumbnailWrapper>
    {(() => {
      if (
        props.thumbnail &&
        (props.type === 'VIDEO' || props.type === 'IMAGE')
      ) {
        return <AttachmentThumbnailImage src={props.thumbnail} />;
      }

      switch (props.type) {
        case 'VIDEO':
          return <AttachmentThumbnailVideo src={props.data} />;
        case 'IMAGE':
          return <AttachmentThumbnailImage src={props.data} />;
        default:
          return (
            <IconContainer>
              <Icon name="UnknownFile" color="#646c74" />
              <AttachmentText>Unknown File Type</AttachmentText>
            </IconContainer>
          );
      }
    })()}
    {props.type === 'VIDEO' && <StyledVideoPlayIcon />}
  </AttachmentThumbnailWrapper>
);

const createLinkifyOptions = (onNewEmail: (email: string) => void) => ({
  className: 'linkify-inherit',
  attributes: (href: string, type: string) => {
    if (type !== 'email') {
      return { target: 'blank' };
    } else {
      return {
        onClick: (ev: React.MouseEvent<HTMLLinkElement>) => {
          ev.preventDefault();
          ev.stopPropagation();
          const email = href.substr('mailto:'.length);
          onNewEmail(email);
        },
      };
    }
  },
  formatHref: (href: string, type: string) => {
    // Email links shouldn't have an href
    return type === 'email' ? '' : href;
  },
});

const linkifyOptions = createLinkifyOptions(() => null);

export const WithLinkify = (props: any) => {
  return <Linkify options={linkifyOptions as any}>{props.children}</Linkify>;
};

export const TimerBadge = ({
  value,
  paused = false,
}: {
  value: number;
  paused?: boolean;
}) => (
  <TimerBadgeStyled>
    <Timer value={value} paused={paused} />
    {paused ? null : ' left'}
  </TimerBadgeStyled>
);
