import * as React from 'react';
import styled, { css } from 'styled-components';

import { Icon } from 'src/components';
import Card, { IProps as CardProps } from 'src/components/Card';
import { CopyableField } from 'src/components/copyable-field';
import { Link } from 'react-router-dom';
import SudoAvatar from '../SudoAvatar';

interface IProps extends ISudoCardProps, IItemPrimaryProps {
  link: string;
  onClick: () => void;
  image: string;
  count: number;
  sudoLabel: string;
  name: string;
  sudoHandle: string;
  phone: string;
  email: string;
}

interface ISudoCardProps {
  color: string;
  isSelected?: boolean;
}

interface IItemPrimaryProps {
  isPrimary: boolean;
}

const CardWrapper = styled.div`
  margin: 8px 0;
  border-radius: 8px;
  padding: 2px;
  background-image: none;
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.13);
  ${(props: ISudoCardProps) =>
    props.isSelected &&
    css`
      background-image: linear-gradient(237deg, #24a6ff, #8321e1);
    `};
`;

const CardWithoutStyledProps = ({
  isSelected,
  color,
  ...props
}: CardProps & ISudoCardProps) => <Card {...props} />;

const StyledCard = styled(CardWithoutStyledProps)`
  width: 288px;
  height: 156px;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  user-select: none;
`;

const CardComponentCreator = (
  link: string,
  onClick: () => void,
) => (props: {}) => <StyledLink to={link} onClick={onClick} {...props} />;

const CardContent = styled.div`
  color: var(--black);
  font-size: 15px;
  display: flex;
  padding: 16px;
  box-sizing: border-box;
  height: 100%;
`;
const Item = styled.div`
  margin-top: 2px;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
const SudoLabel = styled(Item)`
  margin-top: 0;
  font-size: 20px;
  font-weight: bold;
  overflow-x: hidden;
`;
const SudoLabelWrapper = styled.div`
  display: flex;
`;
const SudoDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 16px;
  min-width: 0;
`;
const SudoAvatarWrapper = styled.div`
  align-self: flex-start;
`;

const PrimaryItem = styled(Item).attrs<IItemPrimaryProps>({})`
  color: #ffc80a;
  margin-left: 8px;
  ${(props: IItemPrimaryProps) =>
    !props.isPrimary &&
    css`
      visibility: hidden;
    `};
`;

const PrimaryIcon = (props: IItemPrimaryProps) => (
  <PrimaryItem isPrimary={props.isPrimary}>
    <Icon name="Primary" />
  </PrimaryItem>
);

interface IItemCopyableProps {
  text: string;
  copyText?: string;
}

const CopyableItem = ({ text, copyText }: IItemCopyableProps) => (
  <CopyableField text={copyText || text} className="copyable-field--hover">
    {text}
  </CopyableField>
);

const SudoCard = (props: IProps) => (
  <CardWrapper color={props.color} isSelected={props.isSelected}>
    <StyledCard
      color={props.color}
      image={props.image}
      count={props.count}
      isWithOverlay={true}
      isSelectable={true}
      isSelected={props.isSelected}
      component={CardComponentCreator(props.link, props.onClick)}
    >
      <CardContent>
        <SudoAvatarWrapper>
          <SudoAvatar
            isSudo={true}
            avatarImage={props.image}
            avatarColor={props.color}
            avatarText={props.sudoLabel}
          />
        </SudoAvatarWrapper>
        <SudoDetailsWrapper>
          <SudoLabelWrapper>
            <SudoLabel>{props.sudoLabel}</SudoLabel>
            <PrimaryIcon isPrimary={props.isPrimary} />
          </SudoLabelWrapper>
          <CopyableItem text={props.name} />
          <CopyableItem text={props.phone} />
          <CopyableItem text={props.email} />
        </SudoDetailsWrapper>
      </CardContent>
    </StyledCard>
  </CardWrapper>
);

export default SudoCard;
