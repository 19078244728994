import { IEntityBase } from '../api';
import { IAction } from './actions-base';

export const PROCESS_ENTITIES_UPDATE = 'PROCESS_ENTITIES_UPDATE_ACTION';

export interface IProcessEntitiesUpdateAction extends IAction {
  type: typeof PROCESS_ENTITIES_UPDATE;
  payload: {
    entities: IEntityBase[];
  };
}

/**
 * Dispatched for any entities that need to be retained in or removed from the main entity store
 */
export function processEntityUpdate(entity: IEntityBase) {
  return processEntitiesUpdate([entity]);
}

export function processEntitiesUpdate(entities: IEntityBase[]) {
  return {
    type: PROCESS_ENTITIES_UPDATE,
    payload: { entities },
  } as IProcessEntitiesUpdateAction;
}
