import { IEmailAccount } from '../../api';
import createEntitySelectors from '../common/createEntitySelectors';
import ENTITY_TYPE from './entityType';

export const createEmailAccountSelectors = () => {
  const commonSelectors = createEntitySelectors<IEmailAccount>(ENTITY_TYPE);

  return {
    ...commonSelectors,
  };
};

const EmailAccountSelectors = createEmailAccountSelectors();

export default EmailAccountSelectors;
