import { IMessage, MessageStatus } from '../api';
import { notifyEntity } from './api-notify-entity';
import { IThunk } from './actions-base';
import { transformToDeleteParams } from './entities-delete-action';

let deleteInProgress: string[] = [];

export function deleteMessage(message: IMessage): IThunk<void> {
  return async (dispatch, getState, app) => {
    if (message.messageStatus === MessageStatus.Failed) {
      return dispatch(
        notifyEntity({
          change: 'DELETE',
          entity: message,
        }),
      );
    } else {
      if (deleteInProgress.includes(message.id)) {
        return;
      }
      deleteInProgress.push(message.id);

      await app.api.delete(transformToDeleteParams([message]));
      deleteInProgress = deleteInProgress.filter(id => id !== message.id);
    }
  };
}
