import React from 'react';

export default function Email(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      {...props}
    >
      <g fill="currentColor" fillRule="evenodd" transform="translate(2 4)">
        <path d="M19.988 2.698l-8.91 4.86a2.25 2.25 0 0 1-2.155 0L.012 2.698C.155 1.187 1.588 0 3.333 0h13.334c1.745 0 3.178 1.187 3.321 2.698zM20 4.4v8.653C20 14.68 18.508 16 16.667 16H3.333C1.493 16 0 14.68 0 13.053V4.4l8.204 4.475a3.75 3.75 0 0 0 3.592 0L20 4.4z" />
      </g>
    </svg>
  );
}
