import * as React from 'react';

type ChildrenParamsCallback = () => void;

export interface ChildrenParams {
  onLoadMore: (callback: ChildrenParamsCallback) => void;
  onScroll: (event: React.SyntheticEvent<HTMLElement>) => void;
}

interface Props {
  children: (childrenParams: ChildrenParams) => React.ReactNode;
  isScrollUp?: boolean;
}

class ScrollLoadMore extends React.Component<Props> {
  private _callback: ChildrenParamsCallback;

  public handleScroll = (event: React.SyntheticEvent<HTMLElement>) => {
    const { scrollHeight, scrollTop, clientHeight } = event.currentTarget;

    if (this.props.isScrollUp) {
      if (scrollTop <= 0) {
        this._callback();
      }
    } else {
      if (scrollHeight <= clientHeight + scrollTop) {
        this._callback();
      }
    }
  };

  public handleLoadMore = (callback: ChildrenParamsCallback) => {
    this._callback = callback;
  };

  public render() {
    return this.props.children({
      onLoadMore: this.handleLoadMore,
      onScroll: this.handleScroll,
    });
  }
}

export default ScrollLoadMore;
