import {
  beginPairingAction,
  toggleStayLoggedIn,
  unpairAction,
} from '../../actions';

import { actions as Redirect } from '../../features/Redirect/modules';

const { redirect } = Redirect;

const handleModalButtonClick = () => (dispatch: any) => {
  dispatch(redirect('/'));
};

const reconnect = () => () => {
  location.reload();
};

export const actions = {
  toggleStayLoggedIn,
  handleModalButtonClick,
  logout: unpairAction,
  reconnect,
};
