import React, { ReactNode, SyntheticEvent } from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import noop from 'lodash/noop';

import CancelListener from 'src/components/utils/CancelListener';
import Overlay from 'src/components/utils/Overlay';

interface ISideBarProps {
  children: ReactNode;
  className?: string;
  isOpen: boolean;
  onClose: (e: SyntheticEvent<HTMLElement, Event>, maybeStage?: string) => void;
  style?: React.CSSProperties;
}

const StyledSideBar = styled.aside`
  transition: right var(--theme-transition-function-default) 0.25s;
  box-shadow: none;
  width: 320px;
  max-width: 320px;
  background: var(--gray-8);
  position: fixed;
  top: calc(var(--theme-spacing) * 8);
  right: -320px;
  z-index: 2;
  bottom: 0;

  &.isOpen {
    box-shadow: var(--theme-sidebar-shadow);
    right: 0;
  }
`;

const SideBar = (props: ISideBarProps) => (
  <>
    <CancelListener onCancel={props.onClose} shouldDetect={props.isOpen} />
    <Overlay isOpen={props.isOpen} onClick={props.onClose} />
    <StyledSideBar
      style={props.style}
      className={cn(props.isOpen && 'isOpen', props.className)}
    >
      {props.children}
    </StyledSideBar>
  </>
);

SideBar.defaultProps = {
  onClose: noop,
};

export default styled(SideBar).attrs({ suppressClassNameWarning: true })``;
