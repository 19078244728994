import { IMessageConversation, IMessage } from '../../api';
import createEntitySelectors, {
  EntitiesArraySelector,
  EntitySelector,
} from '../common/createEntitySelectors';
import ENTITY_TYPE from './entityType';
import { createSelector } from 'reselect';
import MessageConversationSelectors from '../message-conversation/selectors';

const createGetEntitiesByConversationId = (
  getConversationByIdSelector: EntitySelector<IMessageConversation>,
  getEntitiesArraySelector: EntitiesArraySelector<IMessage>,
) =>
  createSelector(
    getConversationByIdSelector,
    getEntitiesArraySelector,
    (conversation, messages) => {
      return !conversation
        ? []
        : messages.filter(message => {
            return message.parent && message.parent.id === conversation.id;
          });
    },
  );

export const createMessageSelectors = () => {
  const commonSelectors = createEntitySelectors<IMessage>(ENTITY_TYPE);
  const { getEntitiesArray } = commonSelectors;
  const { getEntityById: getConversationById } = MessageConversationSelectors;

  return {
    ...commonSelectors,
    getEntitiesByConversationId: createGetEntitiesByConversationId(
      getConversationById,
      getEntitiesArray,
    ),
  };
};

const MessageSelectors = createMessageSelectors();

export default MessageSelectors;
