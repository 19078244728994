import { css } from 'styled-components';

// The following two CSS tweaks are designed to work together. We're using them because Firefox 67 broke
// the way that max-height is respected in a grid cell. It meant that content was not getting properly
// constrained in the cell and the scrolling broke.
//
// This approach isn't ideal, but it fixes the problem for now. Hopefully Firefox will work better in the
// future, or a better approach will be discovered.
//
// More info: https://stackoverflow.com/questions/43352501/css-grid-content-to-use-free-space-but-scroll-when-bigger

/** Apply to CSS grid cells where we want the content to respect the height available in the cell */
export const gridContentParentHack = css`
  position: relative;
`;

/** Apply to the content in a CSS grid cell where we want it to respect the height available in the cell */
export const gridContentChildHack = css`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;
