/* tslint:disable max-line-length */
import * as React from 'react';

export default function VideoPlay(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <circle cx="20" cy="20" r="20" fill="#303030" fillOpacity=".6" />
        <g fill="#FFF" transform="translate(12 9)">
          <path d="M1.406.642l15.167 9.583a.917.917 0 0 1 0 1.55L1.406 21.358A.917.917 0 0 1 0 20.583V1.417A.917.917 0 0 1 1.406.642z" />
        </g>
      </g>
    </svg>
  );
}
