import {
  applyMiddleware,
  compose,
  createStore,
  Middleware,
  Store,
  StoreEnhancer,
} from 'redux';
import { IAction } from './actions/actions-base';
import thunk, { ThunkDispatch, ThunkMiddleware } from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { IApi } from './api';
import { config } from './config';
import { rootReducer } from './reducers/root-reducer';
import { errorTrap } from './containers/error-trap-modal/modules';
import { IRootState, rootStateToJS } from './state';
import { IThunkExtra } from './actions';

declare var module: any;

export interface IStore extends Store<IRootState> {
  dispatch: IDispatcher;
}

export interface IDispatcher
  extends ThunkDispatch<IRootState, IThunkExtra, IAction> {}

function getMiddleware(api: IApi): StoreEnhancer {
  const middleware: Middleware[] = [];

  const extraArgument: IThunkExtra = {
    api,
  };

  middleware.push(errorTrap, thunk.withExtraArgument(
    extraArgument,
  ) as ThunkMiddleware<IRootState, IAction>);

  if (config.enableReduxLogging) {
    middleware.push(
      createLogger({
        collapsed: true,
        stateTransformer: (state: IRootState) => {
          return rootStateToJS(state);
        },
      }),
    );
  }

  return applyMiddleware(...middleware);
}

function getEnhancers() {
  const enhancers: Array<StoreEnhancer<IRootState>> = [];

  const ctx: any = window || this;
  if (ctx.__REDUX_DEVTOOLS_EXTENSION__ && config.enableReduxDevTools) {
    enhancers.push(ctx.__REDUX_DEVTOOLS_EXTENSION__());
  }

  return enhancers;
}

function enableHotLoader(store: IStore) {
  if (module.hot) {
    module.hot.accept('./reducers/root-reducer', () => {
      const nextRootReducer = require('./reducers/root-reducer').rootReducer;
      store.replaceReducer(nextRootReducer);
    });
  }
}

function configureStore(api: IApi): IStore {
  const enhancers = compose(
    getMiddleware(api),
    ...getEnhancers(),
  );
  const store = createStore(rootReducer, enhancers) as IStore;

  enableHotLoader(store);

  return store;
}

export default configureStore;
