import React from 'react';
import CheckBox from 'src/components/CheckBox';
import styled, { css } from 'styled-components';

interface IProps {
  className?: string;
  inputRef?: React.RefObject<any>;
  isIndeterminate?: boolean;
  onChange: (value: boolean) => void;
  position?: string;
  value: boolean;
}

export const StyledCheckBox = styled(CheckBox)<IProps>`
  margin-right: var(--theme-spacing);
  margin-top: auto;
  margin-bottom: auto;
  width: 14px;
  height: 14px;
  ${props =>
    props.position === 'right' &&
    css`
      margin-right: calc(var(--theme-spacing) * 2);
      margin-left: calc(var(--theme-spacing) * 2);
    `}
`;

const handleCheckBoxClick = (event: React.SyntheticEvent) => {
  event.stopPropagation();
};

const ListItemCheckBox = (props: IProps) => (
  <StyledCheckBox {...props} onClick={handleCheckBoxClick} />
);

ListItemCheckBox.defaultProps = {
  position: 'left',
};

export default ListItemCheckBox;
