import { formatNumberForDisplay, isValidPhoneNumber } from '../../../utils';
import { IPhoneNumber } from '../../../api';
import createWithContactAutocomplete, {
  avatarDataFormatter,
  displayNameFormatter,
} from './createWithContactAutocomplete';
import { IContactAndAvatar } from '../../../entities/contact/selectors';

export const formatAutocompleteMember = (
  id: string,
  phone: string,
  formattedPhone: string,
  firstName: string,
  lastName: string,
  displayName: string,
  avatarImage: string,
  avatarText: string,
) => ({
  id,
  key: id + phone,
  secondary: formattedPhone,
  firstName,
  lastName,
  name: displayName,
  avatarImage,
  avatarText,
});

export const contactFormatterSingle = (
  contactData: IContactAndAvatar,
  countryCode: string,
  phone: string,
) => {
  const { contact, avatar } = contactData;
  const { id, first, last } = contact;

  const displayName = displayNameFormatter(countryCode, contact);
  const { avatarImage, avatarText } = avatarDataFormatter(avatar, contact);
  const formatted = formatNumberForDisplay(countryCode, phone) || '';

  return formatAutocompleteMember(
    id,
    phone,
    formatted,
    first,
    last,
    displayName,
    avatarImage,
    avatarText,
  );
};

const contactFormatter = (
  contactData: IContactAndAvatar,
  countryCode: string,
) => {
  const { contact, avatar } = contactData;
  const { id, first, last } = contact;

  const displayName = displayNameFormatter(countryCode, contact);
  const { avatarImage, avatarText } = avatarDataFormatter(avatar, contact);

  return contact.phoneNumbers
    .map((num: IPhoneNumber) => num.number)
    .filter((num: string) => num)
    .map((num: string) => {
      const formatted = formatNumberForDisplay(countryCode, num) || '';
      return formatAutocompleteMember(
        id,
        num,
        formatted,
        first,
        last,
        displayName,
        avatarImage,
        avatarText,
      );
    });
};

const formatInputValue = (value: string, countryCode?: string): string => {
  return formatNumberForDisplay(countryCode, value);
};

const isValidInput = (value: string, countryCode?: string): boolean => {
  return isValidPhoneNumber(countryCode, value);
};

export default createWithContactAutocomplete(
  isValidInput,
  formatInputValue,
  contactFormatter,
);
