import { get, find } from 'lodash';

import { IMessage, IMessageAttachment, IMessageMedia } from '../api';
import { IThunk } from './actions-base';
import { processEntities } from './process-entity-action';
import MessageMediaSelectors from '../entities/message-media/selectors';

export const ATTACHMENT_FETCH_TIMEOUT = 300000;

export function fetchFullsizeAttachment(
  id: string,
  messageId: string,
): IThunk<IMessageAttachment> {
  return async (dispatch, getState, { api }) => {
    const state = getState();
    const messageMedia = MessageMediaSelectors.getEntityById(state, {
      id: messageId,
    });
    const attachment = messageMedia ? find(messageMedia.media, { id }) : null;

    if (attachment && attachment.data) {
      return attachment as IMessageAttachment;
    }

    const entities = (await api.search(
      {
        MessageMedia: {
          type: 'simple',
          key: 'id',
          mode: 'equals',
          value: messageId,
        },
      },
      null,
      null,
      null,
      null,
      ATTACHMENT_FETCH_TIMEOUT,
    )) as IMessageMedia[];

    if (!entities.length) {
      return null;
    }

    dispatch(processEntities(entities));

    const attachments = get(entities, '0.media');
    return find(attachments, { id }) as IMessageAttachment;
  };
}
