import * as React from 'react';
import { EmailAddressType } from '../../../../../api';
import LabelledFieldRowsSection from '../Base/LabelledFieldRowsSection';

export const AVAILABLE_EMAIL_TYPES: EmailAddressType[] = [
  'Sudo',
  'Home',
  'Work',
  'Other',
];

export const EMAIL_LABEL_OPTIONS = AVAILABLE_EMAIL_TYPES.map(t => ({
  label: t,
  description: t,
}));

const EmailsSection = (props: any) => {
  const { fields } = props;
  return (
    <LabelledFieldRowsSection
      fields={fields}
      selectData={(fieldProps: any, i: number) => fieldProps.emails[i]}
      labelOptions={EMAIL_LABEL_OPTIONS}
      placeholder="Enter email"
      onAddItem={() =>
        fields.push({ label: 'Home' as EmailAddressType, value: '' })
      }
      addItemButtonText="Add Email"
    />
  );
};

export default EmailsSection;
