import * as React from 'react';
import CKEditor, { CKEditorComponentEvent } from 'ckeditor4-react';
import { linkifyAtCaret } from './linkify-at-caret';
import './style.css';

CKEditor.editorUrl = '/static/ckeditor/ckeditor.js';

const MOUSE_BUTTON_PRIMARY = 0;
const CK_KEY_ENTER = 13;
const CK_KEY_SPACE = 32;

const readOnlyRemovePlugins = [
  'toolbar',
  'clipboard',
  'pastetext',
  'tableselection',
  'widget',
  'uploadwidget',
  'pastefromword',
  'uploadimage',
  'elementspath',
  'autolink',
  'pastefromexcel',
];

const removePlugins = ['elementspath'];

// Toolbar configuration obtained by CKEditor Toolbar Configurator tool
// in `static/ckeditor/samples/toolbarconfigurator/index.html#basic`
// prettier-ignore
const toolbarConfig = {
  toolbarGroups: [
    { name: 'styles', groups: [ 'styles' ] },
    { name: 'basicstyles', groups: [ 'basicstyles', 'cleanup' ] },
    { name: 'colors', groups: [ 'colors' ] },
    { name: 'clipboard', groups: [ 'clipboard', 'undo' ] },
    { name: 'editing', groups: [ 'find', 'selection', 'spellchecker', 'editing' ] },
    { name: 'paragraph', groups: [ 'align', 'list', 'indent', 'blocks', 'bidi', 'paragraph' ] },
    { name: 'links', groups: [ 'links' ] },
    { name: 'insert', groups: [ 'insert' ] },
    { name: 'forms', groups: [ 'forms' ] },
    { name: 'tools', groups: [ 'tools' ] },
    { name: 'document', groups: [ 'mode', 'document', 'doctools' ] },
    { name: 'others', groups: [ 'others' ] },
    { name: 'about', groups: [ 'about' ] }
  ],

  removeButtons: 'Subscript,Superscript,Cut,Copy,Paste,PasteText,PasteFromWord,Undo,Redo,Image,Anchor,Strike,Styles',
};

// https://ckeditor.com/docs/ckeditor4/latest/api/CKEDITOR_config.html
export function getConfig(readOnly: boolean): CKEDITOR.config {
  const allRemovePlugins = [
    ...removePlugins,
    ...(readOnly ? readOnlyRemovePlugins : []),
  ];

  if (
    window.navigator.userAgent.toLowerCase().indexOf('firefox') > -1 &&
    !allRemovePlugins.includes('pastebase64')
  ) {
    // Firefox supports this natively
    allRemovePlugins.push('pastebase64');
  }

  return {
    ...toolbarConfig,
    allowedContent: true, // disables ACF (we do our own sanitization)
    contentsCss: [
      '/static/ckeditor/contents.css',

      // Put our custom editor styles here to avoid modifying contents.css
      // so that we stay compatible with CKEditor4 MPL licensing.
      '/static/ckeditor/custom-contents.css',
    ],
    disableNativeSpellChecker: false,
    enterMode: 2, // CKEDITOR.ENTER_BR
    resize_enabled: false,
    uiColor: '#FFFFFF',
    removePlugins: allRemovePlugins.join(','),
    startupShowBorders: false,
  };
}

interface IProps {
  readOnly?: boolean;
  data: string;
  onNavigate?: (url: string) => void;
}

export class CustomCkEditor extends React.Component<IProps> {
  private editor: CKEDITOR.editor;

  public render() {
    return (
      <CKEditor
        data={this.props.data}
        readOnly={this.props.readOnly}
        config={getConfig(this.props.readOnly)}
        onInstanceReady={this.handleInstanceReady}
        onConfigLoaded={this.handleConfigLoaded}
        onContentDom={this.handleContentDom}
        onKey={this.onKey}
      />
    );
  }

  private onKey = (ev: CKEditorComponentEvent) => {
    if (ev.data.keyCode === CK_KEY_SPACE || ev.data.keyCode === CK_KEY_ENTER) {
      linkifyAtCaret(ev.editor);
    }
  };

  public focus() {
    if (this.editor) {
      this.editor.focus();
    }
  }

  public getData(): string {
    // If editor hasn't been initialized yet, then we haven't had
    // a chance to edit the data, so return the input data.
    return this.editor ? this.editor.getData() : this.props.data;
  }

  private handleInstanceReady = (ev: CKEditorComponentEvent) => {
    this.editor = ev.editor;
  };

  private handleConfigLoaded = () => {
    // Patch in support for allowing block level elements inside <a> tags. This is
    // something that HTML5 permits, but CKEditor's DTD isn't configured for.
    // For more info see:
    // https://dev.ckeditor.com/ticket/7961
    // https://github.com/ckeditor/ckeditor-dev/issues/610
    CKEDITOR.dtd.a = CKEDITOR.dtd.div;
    CKEDITOR.dtd.$blockLimit.a = 1;
  };

  private handleContentDom = (ev: CKEditorComponentEvent) => {
    this.attachContentClickListener(ev.editor);
  };

  private attachContentClickListener(editor: CKEDITOR.editor) {
    const { onNavigate } = this.props;
    const editable = editor.editable();

    const listener = function(
      this: CKEDITOR.dom.element,
      evt: CKEDITOR.eventInfo,
    ) {
      const targetPath = new CKEDITOR.dom.elementPath(
        evt.data.getTarget(),
        this,
      );

      const link = targetPath.contains('a');
      if (
        link &&
        evt.data.$.button === MOUSE_BUTTON_PRIMARY &&
        link.isReadOnly()
      ) {
        if (onNavigate) {
          onNavigate(link.getAttribute('href'));
        }
      }
    };

    editable.attachListener(editable, 'click', listener, null, null, 15);
  }
}
