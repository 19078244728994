import * as React from 'react';

export interface ChildrenParams {
  onMouseEnter: () => void;
  onMouseDown: () => void;
  isActive: boolean;
}

interface Props {
  id: number;
  currentActiveItem: number;
  setActiveItem: (key: number) => void;
  onAddContact: () => void;
  children: (params: ChildrenParams) => React.ReactNode;
}

class WithAutocompleteListItem extends React.Component<Props> {
  public handleMouseEnter = () => {
    const { id, setActiveItem } = this.props;
    setActiveItem(id);
  };

  public handleMouseDown = () => {
    this.props.onAddContact();
  };

  public render() {
    const { children, id, currentActiveItem } = this.props;
    return children({
      onMouseDown: this.handleMouseDown,
      onMouseEnter: this.handleMouseEnter,
      isActive: currentActiveItem === id,
    });
  }
}

export default WithAutocompleteListItem;
