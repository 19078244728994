import * as Actions from '../actions';
import { getList, IListsState, IListState, ListsState } from '../state/list';
import { createReducer } from './create-reducer';

function listWithMutations(
  mListsState: IListsState,
  id: string,
  mutator: (mList: IListState) => void,
) {
  const list = getList(mListsState, id);
  mListsState.set(id, list.withMutations(mutator));
}

export const listsReducer = createReducer(ListsState(), {
  // --------------------------------------------------------------------------

  [Actions.LIST_ADVISE_FETCHING]: (
    mState,
    action: Actions.IListAdviseFetchingAction,
  ) => {
    const payload = action.payload;

    listWithMutations(mState, payload.listId, mList => {
      mList.fetchRef = payload.ref;
      mList.searchCriteria = payload.searchCriteria;
    });
  },

  // --------------------------------------------------------------------------

  [Actions.LIST_APPEND_FETCH_RESULT]: (
    mState,
    action: Actions.IListAppendFetchResultAction,
  ) => {
    const payload = action.payload;

    listWithMutations(mState, payload.listId, mList => {
      mList.itemIds = mList.itemIds
        .concat(payload.itemIds)
        .toOrderedSet() // removes duplicates
        .toList();
      mList.noMoreItems =
        payload.noMoreItems !== undefined
          ? !!payload.noMoreItems
          : mList.noMoreItems;
      mList.fetchRef = null;
    });
  },

  // --------------------------------------------------------------------------

  [Actions.LIST_INSERT]: (mState, action: Actions.IListInsertAction) => {
    const payload = action.payload;

    listWithMutations(mState, payload.listId, mList => {
      mList.itemIds = mList.itemIds
        .filter(id => payload.itemIds.indexOf(id) === -1)
        .toList()
        .splice(payload.pos || 0, 0, ...payload.itemIds)
        .toList();
    });
  },

  // --------------------------------------------------------------------------

  [Actions.LIST_REMOVE]: (mState, action: Actions.IListRemoveAction) => {
    const payload = action.payload;

    listWithMutations(mState, payload.listId, mList => {
      mList.itemIds = mList.itemIds
        .filter(id => id !== payload.itemId)
        .toList();
      mList.selectedItemIds = mList.selectedItemIds
        .filter(id => id !== payload.itemId)
        .toList();
    });
  },

  // --------------------------------------------------------------------------

  [Actions.LIST_MOVE_TO_TOP]: (
    mState,
    action: Actions.IListMoveToTopAction,
  ) => {
    const payload = action.payload;

    listWithMutations(mState, payload.listId, mList => {
      mList.itemIds = mList.itemIds
        .filter(id => id !== payload.itemId)
        .toList()
        .insert(0, payload.itemId);
    });
  },

  // --------------------------------------------------------------------------

  [Actions.LIST_REPLACE_OR_APPEND]: (
    mState,
    action: Actions.IListReplaceOrAppendAction,
  ) => {
    const payload = action.payload;

    listWithMutations(mState, payload.listId, mList => {
      const index = mList.itemIds.indexOf(payload.oldId);
      if (index > -1) {
        mList.itemIds = mList.itemIds.splice(index, 1, payload.newId).toList();
      } else {
        mList.itemIds = mList.itemIds.splice(0, 0, payload.newId).toList();
      }
    });
  },

  // --------------------------------------------------------------------------

  [Actions.LIST_SELECT]: (mState, action: Actions.IListSelectAction) => {
    const payload = action.payload;

    listWithMutations(mState, payload.listId, mList => {
      mList.selectedItemIds = mList.selectedItemIds
        .clear()
        .concat(payload.itemIds)
        .toList();
    });
  },

  // --------------------------------------------------------------------------

  [Actions.LIST_CLEAR]: (mState, action: Actions.IListClearAction) => {
    const payload = action.payload;

    listWithMutations(mState, payload.listId, mList => {
      mList.itemIds = mList.itemIds.clear().toList();

      mList.selectedItemIds = mList.selectedItemIds.clear().toList();
    });
  },

  // --------------------------------------------------------------------------

  [Actions.LIST_DISPOSE]: (mState, action: Actions.IListDisposeAction) => {
    const payload = action.payload;
    mState.remove(payload.listId);
  },
});
