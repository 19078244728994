import React from 'react';
import Icon from 'src/components/Icon';
import styled from 'styled-components';

export const IconStyled = styled(Icon)`
  height: auto;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
`;

const AvatarSudoState = () => <IconStyled name="SudoRings" />;

export default AvatarSudoState;
