import cn from 'classnames';
import { Avatar } from 'src/components';
import * as React from 'react';
import { branch, renderComponent } from 'recompose';

export interface ISudoAvatarProps {
  avatarColor: string;
  avatarText: string;
  avatarImage?: string;
  className?: string;
  isSudo?: boolean;
}

const AvatarWithImage = (props: ISudoAvatarProps) => (
  <Avatar
    backgroundColor={props.avatarColor}
    image={props.avatarImage}
    className={cn(props.className)}
    isSudo={props.isSudo}
  />
);

const AvatarWithText = (props: ISudoAvatarProps) => (
  <Avatar
    backgroundColor={props.avatarColor}
    className={cn(props.className)}
    isSudo={props.isSudo}
  >
    {props.avatarText}
  </Avatar>
);

const SudoAvatar = branch(
  (props: ISudoAvatarProps) => !!props.avatarImage,
  renderComponent(AvatarWithImage),
)(AvatarWithText);

export default SudoAvatar;
