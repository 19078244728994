import styled from 'styled-components';

const ButtonBase = styled.button`
  font-family: var(--theme-font-family);
  border: none;
  outline: 0;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;

  &:focus,
  &.focus-ring {
    outline-width: 1px;
    outline-style: dotted;
  }
`;

ButtonBase.displayName = 'ButtonBase';

export default ButtonBase;
