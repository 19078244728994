/* tslint:disable max-line-length */
import React from 'react';

export default function Sent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      {...props}
    >
      <g fill="currentColor" fillRule="evenodd" transform="translate(2 2)">
        <path d="M8.439 10.091L1.987 8.543A1.221 1.221 0 0 1 .941 7.482L.258 1.6c-.029-.247.02-.495.14-.712A1.243 1.243 0 0 1 2.042.382L19.077 8.92a1.202 1.202 0 0 1 0 2.16L2.043 19.618c-.214.107-.455.15-.693.125a1.217 1.217 0 0 1-1.092-1.342l.683-5.883c.064-.551.498-.983.998-1.053l6.5-1.374zM1.808 1.942l.6 5.163 9.182 2.2a.85.85 0 0 1-.022 1.658l-9.161 1.935-.6 5.16L17.885 10 1.808 1.942z" />
      </g>
    </svg>
  );
}
