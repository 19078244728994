import { IContact, IContactAvatar } from '../api';
import { saveEntity } from './save-entities-action';
import { IThunk } from './actions-base';
import { processEntity } from './process-entity-action';
import { notifyEntity } from './api-notify-entity';
import ContactSelectors from '../entities/contact/selectors';

export function saveContact(
  contact: Partial<IContact>,
  avatar?: IContactAvatar,
  onComplete: (contactGuid: string) => void = null,
): IThunk<void> {
  return async (dispatch, getState, app) => {
    // TODO: `version` needs to come out as a constant, or perhaps we should have
    // creator functions in entities.ts. It is in theory possible to have different
    // versions for different entity types.
    const base: Partial<IContact> = {
      version: 2,
      created: new Date().valueOf(),
      modified: new Date().valueOf(),
    };

    saveContactEntity();

    /**
     * Inserts or updates a contact, then saves the avatar if possible.
     */
    async function saveContactEntity() {
      const baseContact: Partial<IContact> = {
        ...base,
        type: 'Contact',
      };
      const contactToSave = {
        ...baseContact,
        ...contact,
        isSudoContact: contact.isSudoContact || false,
      } as IContact;

      // Optimistic update
      if (contact.id) {
        dispatch(
          notifyEntity({
            change: 'UPDATE',
            entity: contactToSave,
          }),
        );
      }

      const contactResult = await dispatch(
        saveEntity(contactToSave, { showSaveModal: true }),
      );

      // Optimistic insert in case that we still haven't got notification about this contact
      if (!contact.id) {
        const existingContact = ContactSelectors.getEntityById(getState(), {
          id: contactResult.id,
        });
        if (!existingContact) {
          dispatch(
            notifyEntity({
              change: 'INSERT',
              entity: contactResult,
            }),
          );
        }
      }

      if (avatar && avatar.avatar) {
        saveAvatarEntity(contactResult);
      } else {
        saveComplete(contactResult.id);
      }
    }

    /**
     * Inserts or updates a contact avatar
     */
    async function saveAvatarEntity(contactResult: IContact) {
      // TODO: avatar is saved as part of the Contact
      const avatarData = {
        id: contactResult.id,
        type: 'Contact',
        parent: contactResult.parent,
        avatar: avatar.avatar,
      } as IContact;

      const savedAvatarContact = await dispatch(
        saveEntity(avatarData, { showSaveModal: true }),
      );
      if (savedAvatarContact) {
        const avatarToProcess: IContactAvatar = {
          ...avatar,
          type: 'ContactAvatar',
          id: savedAvatarContact.id,
          parent: savedAvatarContact.parent,
          created: savedAvatarContact.created,
          modified: savedAvatarContact.modified,
        };
        dispatch(processEntity(avatarToProcess));
      }
      saveComplete(contactResult.id);
    }

    /**
     * Final steps once everything is saved
     */
    function saveComplete(contactGuid: string) {
      if (onComplete) {
        onComplete(contactGuid);
      }
    }
  };
}
