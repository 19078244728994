import * as React from 'react';
import { connect } from 'react-redux';

import { ISavingModalEntry } from '../../state';
import { IRootState } from '../../state/root';
import { Modal } from '../OModal';
import { Spinner } from '../spinner';
import './saving-modal.scss';

interface IProps {
  modal: ISavingModalEntry;
}

export class SavingModalComponent extends React.Component<IProps, {}> {
  public render() {
    const { isOpen, savingText } = this.props.modal;

    return (
      <Modal className="saving-modal" isOpen={isOpen}>
        {savingText}
        <Spinner />
      </Modal>
    );
  }
}

function mapStateToProps(state: IRootState) {
  return {
    modal: state.modal.saving,
  };
}

export const SavingModal = connect(mapStateToProps)(SavingModalComponent);
