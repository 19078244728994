import { connect } from 'react-redux';

import { IRootState, getList } from '../../../state';
import { getIsContactListEmpty } from '../selectors';
import SudoSettingsSelectors from '../../../entities/sudo-settings/selectors';
import { getListId } from 'src/features/SearchListInput/container';

export interface IStateFromProps {
  isListEmpty: boolean;
}

export interface IStateProps {
  sudoSlug: string;
}

export const mapState = (
  state: IRootState,
  props: IStateProps,
): IStateFromProps => {
  const sudoGuid = SudoSettingsSelectors.getIdBySlug(state, {
    slug: props.sudoSlug,
  });
  const list = getList(state.lists, getListId('contacts', sudoGuid));
  const isListEmpty =
    getIsContactListEmpty(state, { sudoGuid }) && list && !list.searchCriteria;

  return {
    isListEmpty,
  };
};

export default connect(mapState);
