import { IMessageAttachment, uuid } from '../api';
import { TEMP_GUID_PREFIX } from '../config';

export function dataUriToMessageAttachment(
  messageGuid: string,
  dataUri: string,
) {
  const dataUriRegex = /^(data:)([\-\w\/\+]+);(charset=[\w-]+|base64).*,(.*)/gi;
  const match = dataUriRegex.exec(dataUri);
  if (!match) {
    // Not sure when this could happen but we may want to return an error
    return;
  }

  // Workaround for https://www.pivotaltracker.com/story/show/140961983
  // SudoApp will crash if it is given `x-m4v` mime type, but `m4v` will work.
  const mimeType = match[2];
  const data = match[4];

  return {
    id: TEMP_GUID_PREFIX + uuid(),
    mimeType,
    data,
  } as IMessageAttachment;
}

export function attachmentToDataUri(attachment: IMessageAttachment) {
  if (attachment && attachment.mimeType && attachment.data) {
    let mimeType = attachment.mimeType;

    // Browsers incorrectly work with 'video/quicktime' type
    if (attachment.mimeType === 'video/quicktime') {
      mimeType = 'video/mp4';
    }

    return `data:${mimeType};base64,${attachment.data}`;
  }
  return null;
}
