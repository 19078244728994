import { getWebRtcPreference } from './client/webrtc-preference';

// From webpack:
declare const __ALLOW_CONTINUE_AFTER_ERROR__: boolean;
declare const __ALLOW_KEEP_ME_SIGNED_IN__: boolean;
declare const __ANONYOME_ENVIRONMENTS__: any;
declare const __BRANCH__: string;
declare const __ENABLE_ANALYTICS__: boolean;
declare const __ENABLE_REDUX_DEVTOOLS__: boolean;
declare const __EXPOSE_PAIRING_CODE__: boolean;
declare const __EXTRA_LOGGING__: boolean;
declare const __NEW_API__: boolean;
declare const __SENTRY_RAVEN_DSN__: string;
declare const __SHOW_ERRORS__: boolean;
declare const __VERSION__: string;
declare const __ENABLE_WEBRTC__: boolean;

export const config = {
  apiMessagePrefix: 'MSW',
  appName: 'MySudo for web',
  version: __VERSION__,
  branch: __BRANCH__,
  sentryRavenDsn: __SENTRY_RAVEN_DSN__,
  enableAnalytics: __ENABLE_ANALYTICS__,
  anonyomeEnvironments: __ANONYOME_ENVIRONMENTS__,
  showErrors: __SHOW_ERRORS__,
  allowContinueAfterError: __ALLOW_CONTINUE_AFTER_ERROR__,
  allowKeepMeSignedIn: __ALLOW_KEEP_ME_SIGNED_IN__,
  enableApiLogging: __EXTRA_LOGGING__,
  logEntityNotifications: __EXTRA_LOGGING__,
  logTransferProgress: __EXTRA_LOGGING__,
  enableReduxLogging: __EXTRA_LOGGING__,
  enableReduxDevTools: __ENABLE_REDUX_DEVTOOLS__,
  exposePairingCode: __EXPOSE_PAIRING_CODE__,
  emailAttachmentsTotalSizeLimit: 7 * 1024 * 1024,
  newApi: __NEW_API__,
  enableWebRtc: __ENABLE_WEBRTC__,
  webRtc: __NEW_API__ && getWebRtcPreference(),
};

export const TEMP_GUID_PREFIX = 'SAW'; // should be 3 chars

if (__EXTRA_LOGGING__) {
  // Enable AWS Amplify logging if extra logging is enabled.
  (window as any).LOG_LEVEL = 'DEBUG';
}
