import styled, { css } from 'styled-components';

interface IProps {
  backgroundColor: string;
  count: number;
}

export const TabCounter = styled.div<IProps>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  &:before {
    transition: all .25s var(--theme-transition-function-default);
    color: var(--white);
    content: "${props => (props.count > 9999 ? '9999' : props.count)}";
    width: auto;
    height: 18px;
    background: ${props => props.backgroundColor};
    border-radius: 20px;
    padding: 0 6px 1px;
    font-weight: 500;
    font-size: 12px;
    position: absolute;
    margin-right: -8px;
    right: 90%;
    top: -8px;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transform: scale(0);
    ${props =>
      props.count &&
      css`
        opacity: 1;
        transform: scale(1);
      `}
  }
`;

TabCounter.displayName = 'TabCounter';

export default TabCounter;
