import { noop } from 'lodash';
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';
import { mapProps } from 'recompose';
import { AutoSizer, List } from 'react-virtualized';
import NavListItem from 'src/features/List/NavListItem';
import WithSelection from 'src/features/List/utils/WithSelection';
import { ListEmpty } from 'src/components/List';
import {
  IContactItem,
  IContactsRowContact,
  IContactsRowGroup,
} from '../selectors';
import withContactsListData, {
  IStateFromProps,
  IStateProps,
  IDispatchToProps,
} from './withContactsListData';

export interface IMatch {
  sudo: string;
  tab?: string;
}

interface IProps extends IStateFromProps, IStateProps, IDispatchToProps {}

const getUri = (sudo: string, tab: string, id?: string) =>
  `/${[sudo, 'contacts', tab, id]
    .filter((part?: string) => part)
    .map(encodeURIComponent)
    .join('/')}`;

const StyledDivider = styled.div`
  align-items: center;
  background-color: var(--gray-7);
  color: var(--gray-3);
  display: flex;
  font-size: 13px;
  font-weight: bold;
  line-height: 21px;
  padding: 6px 24px;
  text-transform: uppercase;
`;

const NameBold = styled.span`
  font-weight: bold;
`;

const Name = (props: IContactItem) => (
  <React.Fragment>
    <span>{props.nameSecondary}</span>
    {props.nameSecondary && props.namePrimary && <span>&nbsp;</span>}
    <NameBold>{props.namePrimary}</NameBold>
  </React.Fragment>
);

export class ContactsList extends React.Component<IProps> {
  private readonly listRef: any;

  constructor(props: IProps) {
    super(props);
    this.listRef = (React as any).createRef();
  }

  public componentDidMount() {
    this.loadAvatars();
  }

  public componentDidUpdate(prevProps: IProps) {
    this.listRef.current.recomputeRowHeights();
    this.loadAvatars(prevProps);
  }

  public rowRenderer = ({ key, index, style }: any) => {
    const props = this.props;
    const item = props.contactRows[index];
    let row;

    switch (props.contactRows[index].type) {
      case 'group':
        const groupName = (item as IContactsRowGroup).name;
        row = <StyledDivider>{groupName}</StyledDivider>;
        break;

      case 'contact':
        const contact = (item as IContactsRowContact).contact;
        row = (
          <WithSelection id={contact.id} toggle={noop}>
            {({ onToggle }) => (
              <NavListItem
                primaryText={<Name {...contact} />}
                secondaryText={contact.info}
                avatarImage={contact.avatarImage}
                avatarText={contact.avatarText}
                checkBoxValue={contact.isSelected}
                onCheckBoxChange={onToggle}
                activeClassName="isActive"
                to={getUri(props.sudoSlug, props.tab, contact.id)}
              />
            )}
          </WithSelection>
        );
        break;

      default:
        row = null;
        break;
    }

    return (
      <div key={key} style={style}>
        {row}
      </div>
    );
  };

  private noRowsRenderer = () => {
    return this.props.isSearchEmpty ? <ListEmpty /> : null;
  };

  private loadAvatars(prevProps?: IProps) {
    const ids = this.props.contactRows.reduce(
      (accumulator, item) => {
        if (item.type !== 'contact') {
          return accumulator;
        }
        const contact = (item as IContactsRowContact).contact;
        if (prevProps && prevProps.contactRows.includes(item)) {
          return accumulator;
        }
        return [...accumulator, contact.id];
      },
      [] as string[],
    );
    this.props.fetchAvatarImages(ids);
  }

  public render() {
    const props = this.props;
    return (
      <React.Fragment>
        <AutoSizer disableWidth={true}>
          {({ height }: any) => (
            <List
              ref={this.listRef}
              height={height}
              width={374}
              rowHeight={({ index }) =>
                props.contactRows[index].type === 'contact' ? 72 : 32
              }
              noRowsRenderer={this.noRowsRenderer}
              rowCount={props.contactRows.length}
              overscanRowCount={10}
              rowRenderer={this.rowRenderer}
            />
          )}
        </AutoSizer>
      </React.Fragment>
    );
  }
}

export const propsMapper = (props: RouteComponentProps<IMatch>) => ({
  ...props,
  sudoSlug: props.match.params.sudo,
  tab: props.match.params.tab || 'sudo',
});
const mapRouterProps = mapProps(propsMapper);

export default mapRouterProps(withContactsListData(ContactsList));
